import React from 'react'
const Portugal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#0D663D"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L9,0 L17,4 L22,9 L26,15 L27,18 L27,28 L22,38 L14,44 L11,45 L-1,45 L-1,33 L5,30 L8,26 L8,20 L4,15 L-1,13 L-1,1 Z "
      fill="#EE1B23"
      transform="translate(21,2)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M3,2 L-6,6 L-13,13 L-16,20 L-16,30 L-12,38 L-7,43 L-3,46 L0,47 L14,47 L20,44 L25,40 L30,30 L30,20 L26,12 L21,7 L16,4 L12,2 Z "
      fill="#BBCAD1"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L7,0 L13,5 L14,7 L14,13 L9,19 L7,20 L0,20 L-5,15 L-6,14 L-6,6 L-1,1 Z "
      fill="#A25933"
      transform="translate(16,15)"
    />
    <path
      d="M0,0 L7,0 L13,5 L14,7 L14,13 L9,19 L7,20 L0,20 L-1,18 L4,17 L4,19 L7,17 L7,15 L9,15 L10,12 L12,13 L12,6 L10,6 L6,2 L4,1 L3,3 L-1,1 Z "
      fill="#AAAC33"
      transform="translate(16,15)"
    />
    <path
      d="M0,0 L4,1 L4,8 L0,10 L-3,7 L-3,1 Z "
      fill="#91A1BE"
      transform="translate(19,20)"
    />
    <path
      d="M0,0 L1,0 L1,5 L-1,5 L0,8 L-4,9 L-4,7 L-8,5 L-2,3 Z "
      fill="#C93123"
      transform="translate(24,25)"
    />
    <path
      d="M0,0 L2,0 L3,7 L7,6 L7,9 L7,12 L2,9 L0,4 Z "
      fill="#5E7B3A"
      transform="translate(11,22)"
    />
    <path
      d="M0,0 L2,1 L1,7 L0,5 L-2,5 L0,12 L2,16 L-1,15 L-3,12 L-3,4 Z "
      fill="#C4BE32"
      transform="translate(13,17)"
    />
    <path
      d="M0,0 L3,0 L3,3 L1,3 L1,5 L3,5 L3,7 L5,7 L5,5 L7,5 L5,9 L1,7 L0,6 Z "
      fill="#DFD1D6"
      transform="translate(16,21)"
    />
    <path
      d="M0,0 L5,2 L5,8 L3,8 L3,5 L1,5 Z "
      fill="#D22F27"
      transform="translate(20,16)"
    />
    <path
      d="M0,0 L2,0 L3,7 L0,8 L-1,3 Z "
      fill="#B83424"
      transform="translate(14,21)"
    />
  </svg>
)
export default Portugal
