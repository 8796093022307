import * as React from 'react'
const Us = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L16,0 L26,6 L32,15 L33,17 L33,33 L27,43 L18,49 L16,50 L0,50 L-10,44 L-16,35 L-17,33 L-17,17 L-11,7 L-2,1 Z "
      fill="#EEE8E8"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L16,0 L26,6 L32,15 L33,17 L33,25 L-17,25 L-17,17 L-11,7 L-2,1 Z "
      fill="#2159B5"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L50,0 L50,7 L0,7 Z "
      fill="#EDE6E7"
      transform="translate(0,25)"
    />
    <path
      d="M0,0 L50,0 L47,6 L3,6 Z "
      fill="#D80A24"
      transform="translate(0,32)"
    />
    <path
      d="M0,0 L2,4 L1,9 L5,8 L5,6 L9,5 L10,9 L14,8 L15,6 L15,9 L13,9 L13,12 L15,12 L15,17 L18,19 L-7,19 L-7,11 L-1,1 Z "
      fill="#1D5BB7"
      transform="translate(7,6)"
    />
    <path
      d="M0,0 L22,0 L25,5 L25,7 L0,7 Z "
      fill="#EDE5E5"
      transform="translate(25,12)"
    />
    <path
      d="M0,0 L25,0 L25,6 L0,6 Z "
      fill="#D70D26"
      transform="translate(25,19)"
    />
    <path
      d="M0,0 L5,5 L6,7 L-16,7 L-16,1 Z "
      fill="#D8122A"
      transform="translate(41,5)"
    />
    <path
      d="M0,0 L30,0 L27,3 L23,5 L7,5 L0,1 Z "
      fill="#D7031F"
      transform="translate(10,45)"
    />
    <path
      d="M0,0 L1,2 L3,3 L0,6 L-2,6 L-2,8 L1,7 L1,9 L3,10 L0,13 L-2,13 L-3,10 L-7,10 L-7,12 L-11,13 L-12,9 L-11,8 L-11,3 L-7,1 L-5,5 L-2,2 L0,2 Z "
      fill="#6C90CA"
      transform="translate(19,2)"
    />
    <path
      d="M0,0 L8,0 L16,5 L16,6 L0,6 Z "
      fill="#ECDFE0"
      transform="translate(25,0)"
    />
    <path
      d="M0,0 L1,2 L3,2 L3,7 L10,8 L10,5 L6,3 L9,2 L10,0 L10,2 L12,2 L12,7 L15,9 L-10,9 L-10,8 L1,8 L1,6 L-2,7 L-3,2 L0,2 Z "
      fill="#4A6ABA"
      transform="translate(10,16)"
    />
    <path
      d="M0,0 L4,2 L6,2 L6,4 L-5,4 L-5,2 L-1,1 Z "
      fill="#024DB3"
      transform="translate(5,20)"
    />
    <path
      d="M0,0 L4,0 L6,2 L8,2 L8,4 L0,4 Z "
      fill="#014DB3"
      transform="translate(12,20)"
    />
    <path
      d="M0,0 L1,2 L3,2 L2,6 L-2,7 L-3,2 L0,2 Z "
      fill="#C1C7DD"
      transform="translate(10,16)"
    />
    <path
      d="M0,0 L1,2 L4,3 L2,7 L-1,6 L-1,1 Z "
      fill="#C5CBDF"
      transform="translate(1,16)"
    />
    <path
      d="M0,0 L5,0 L3,1 L4,5 L0,6 L-1,1 Z "
      fill="#1154B5"
      transform="translate(13,2)"
    />
    <path
      d="M0,0 L1,2 L3,3 L0,6 L-2,6 L-3,2 Z "
      fill="#C6CBDF"
      transform="translate(19,9)"
    />
    <path
      d="M0,0 L1,2 L3,3 L0,6 L-2,6 L-3,2 L0,2 Z "
      fill="#C9CEE0"
      transform="translate(10,9)"
    />
    <path
      d="M0,0 L1,2 L3,2 L1,6 L-3,2 L0,2 Z "
      fill="#CED2E1"
      transform="translate(19,16)"
    />
  </svg>
)
export default Us
