import React from 'react'
const ArgentinaPrimera = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    viewBox=" 0 0 150 150"
    {...props}
  >
    <path
      d="M0,0 L97,0 L97,80 L73,93 L50,106 L45,105 L22,92 L0,79 Z "
      fill="#F1D57E"
      transform="translate(26,35)"
    />
    <path
      d="M0,0 L97,0 L97,80 L73,93 L50,106 L45,105 L22,92 L0,79 Z M8,7 L8,74 L21,82 L48,97 L53,95 L80,80 L89,74 L89,7 Z "
      fill="#DAA813"
      transform="translate(26,35)"
    />
    <path
      d="M0,0 L97,0 L97,24 L0,24 Z "
      fill="#0BB9D8"
      transform="translate(26,8)"
    />
    <path
      d="M0,0 L7,0 L7,43 L23,34 L24,6 L20,6 L16,0 L43,0 L43,23 L48,20 L49,0 L67,0 L67,6 L55,6 L55,16 L62,12 L64,12 L64,19 L56,24 L55,62 L49,64 L49,28 L31,38 L30,71 L24,68 L24,42 L2,55 L0,55 Z "
      fill="#0D4D7A"
      transform="translate(41,50)"
    />
    <path
      d="M0,0 L5,0 L5,3 L0,3 L0,5 L4,5 L4,8 L0,8 L5,9 L6,12 L7,2 L10,0 L14,1 L15,2 L47,3 L47,10 L49,10 L49,18 L-48,18 L-47,11 L-15,10 L-13,1 L-7,1 L-5,3 L-5,12 L-3,12 L-3,2 Z "
      fill="#0DBAD9"
      transform="translate(74,14)"
    />
    <path
      d="M0,0 L29,0 L29,7 L0,7 Z "
      fill="#EAF6FA"
      transform="translate(29,17)"
    />
    <path
      d="M0,0 L28,0 L28,7 L0,7 Z "
      fill="#EFF8FB"
      transform="translate(92,17)"
    />
    <path
      d="M0,0 L7,0 L7,21 L2,24 L0,24 Z "
      fill="#E3CC7F"
      transform="translate(71,56)"
    />
    <path
      d="M0,0 L4,1 L5,2 L5,12 L3,12 L3,8 L-1,8 L-1,12 L-3,12 L-3,2 Z "
      fill="#B6E5F0"
      transform="translate(84,14)"
    />
    <path
      d="M0,0 L6,0 L7,1 L7,11 L5,11 L5,7 L1,7 L1,11 L-1,11 L-1,2 Z "
      fill="#EAF6F9"
      transform="translate(61,15)"
    />
    <path
      d="M0,0 L5,0 L5,3 L0,3 L0,5 L4,5 L4,8 L0,8 L0,12 L-3,12 L-3,2 Z "
      fill="#CBEAF3"
      transform="translate(74,14)"
    />
    <path
      d="M0,0 L4,1 L2,2 L1,5 L6,5 L6,6 L1,6 L1,11 L-1,11 L-1,1 Z "
      fill="#EFF8FB"
      transform="translate(82,15)"
    />
    <path
      d="M0,0 L5,1 L5,11 L3,11 L3,7 L-1,6 L2,5 L2,2 Z "
      fill="#ECF6FA"
      transform="translate(63,15)"
    />
    <path
      d="M0,0 L5,2 L5,5 L1,5 Z "
      fill="#80D4E7"
      transform="translate(61,15)"
    />
  </svg>
)
export default ArgentinaPrimera
