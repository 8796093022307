import * as React from 'react'
const Mali = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L16,0 L26,6 L32,15 L33,17 L33,33 L27,43 L18,49 L16,50 L0,50 L-10,44 L-16,35 L-17,33 L-17,17 L-11,7 L-2,1 Z "
      fill="#FCD743"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L8,4 L14,12 L16,16 L16,32 L10,42 L1,48 L0,48 Z "
      fill="#D70026"
      transform="translate(34,1)"
    />
    <path
      d="M0,0 L1,0 L1,48 L-7,44 L-13,36 L-15,32 L-15,16 L-9,6 Z "
      fill="#6CA444"
      transform="translate(15,1)"
    />
  </svg>
)
export default Mali
