import React from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
import Link from 'next/link'
import { withRouter } from 'next/router'
import { ChatIcon, DocumentationIcon, FaqIcon } from '@/icons/index'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="text-[60px] font-bold mt-[96px] text-center ">
            We&apos;re Sorry!
          </div>
          <h2
            className="text-center text-xl m-auto mt-3 md:w-[40%] sm:w-[60%] w-[90%]"
            style={{ color: 'rgba(255, 255, 255, 0.5)' }}
          >
            We&apos;re sorry, but its looks like there is a problem accessing
            the content of this website. Please check your internet connection
            and try again. If the problem persists, please contact us for
            assistance.
          </h2>
          <div className="place-self-center text-center mt-[40px]">
            <button
              onClick={() => {
                this.props.router && this.props.router.reload()
              }}
              className={`  bg-[#FFFFFF]/10 font-medium px-5 py-3 rounded-lg text-lg hover:bg-[#FFFFFF]/20`}
            >
              <ArrowLeftIcon
                className="mr-3 h-5 w-5 inline-flex"
                aria-hidden="true"
              />
              Refresh
            </button>
            <button
              onClick={() => window && window.location.replace('/home')}
              data-for="update-profile"
              className={`bg-[#008000] ml-3 mb-2 font-medium px-5 py-3 rounded-lg text-lg hover:bg-[#008000]/90 ${'cursor-pointer'}`}
            >
              Homepage
            </button>
            <div className="flex md:flex-row flex-col gap-3 mt-[60px] md:px-0 sm:px-20 px-5  place-self-end  justify-center ">
              <div className="lg:w-[25%] md:w-[29%] w-[100%] md:mt-0 mt-3 bg-[#FFFFFF]/10 text-start p-5 rounded-lg">
                <DocumentationIcon className="w-5 h-5" />
                <h3 className="pt-10 text-xl">Documentation</h3>
                <p className="pt-2 text-gray-500">
                  Ready to dive into our product? Check out our documentation or
                  more information.
                </p>
                <Link href="/faqs" className="cursor-auto">
                  <h4 className=" inline-flex pt-4 text-[#008000]">
                    Start learning
                    <ArrowRightIcon
                      className="mr-3 h-5 w-5 self-end ml-5"
                      aria-hidden="true"
                    />
                  </h4>
                </Link>
              </div>
              <div className="lg:w-[25%] md:w-[29%] w-[100%] md:mt-0 mt-3 bg-[#FFFFFF]/10 text-start p-5 rounded-lg">
                <FaqIcon className="w-5 h-5" />
                <h3 className="pt-10 text-xl">FAQ</h3>
                <p className="pt-2 text-gray-500">
                  Need to help? Find answers to common questions here.
                </p>
                <Link href="/faqs" className="cursor-auto">
                  <h4 className=" inline-flex pt-4 text-[#008000]">
                    Explore FAQ
                    <ArrowRightIcon
                      className="mr-3 h-5 w-5 self-end ml-5"
                      aria-hidden="true"
                    />
                  </h4>
                </Link>
              </div>
              <div className="lg:w-[25%] md:w-[29%] w-[100%] md:mt-0 mt-3 bg-[#FFFFFF]/10 text-start p-5 rounded-lg">
                <ChatIcon className="w-5 h-5 text-green-700" />
                <h3 className="pt-10 text-xl">Chat to us</h3>
                <p className="pt-2 text-gray-500">
                  Get immediate assistance with our API by chatting with our
                  team.
                </p>
                <Link href="/help" className="cursor-auto">
                  <h4 className=" inline-flex pt-4 text-[#008000]">
                    Chat to our team
                    <ArrowRightIcon
                      className="mr-3 h-5 w-5 self-end ml-5"
                      aria-hidden="true"
                    />
                  </h4>
                </Link>
              </div>
            </div>
          </div>
        </>
      )
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
