import * as React from 'react'
const Japan = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0 0 C7.74 2.58 12.33 7.88 16 15 C18.14 21.42 18.32 30.1 15.56 36.38 C11.89 42.46 5.84 47.94 -1 50 C-8.98 50.82 -16.38 50.93 -23 46 C-25.69 43.75 -25.69 43.75 -28 41 C-28.47 40.49 -28.93 39.99 -29.41 39.46 C-33.76 33.94 -33.92 27.69 -33.54 20.92 C-32.6 14.06 -30.03 9.47 -24.67 5.1 C-16.76 -0.34 -9.46 -1.24 0 0 Z "
      fill="#EEEDED"
      transform="translate(33,0)"
    />
    <path
      d="M0 0 C2.81 1.79 4.51 3.02 6 6 C6.56 10.63 6.64 13.98 4.12 18 C0.61 21.31 -2.06 21.39 -6.74 21.36 C-10.3 20.79 -11.85 18.75 -14 16 C-14.33 15.67 -14.66 15.34 -15 15 C-15.59 7.93 -15.59 7.93 -13.19 4.12 C-9.06 0.12 -5.64 -0.92 0 0 Z "
      fill="#BD0629"
      transform="translate(29,14)"
    />
  </svg>
)
export default Japan
