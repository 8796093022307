import * as React from 'react'
const CopaAmerica = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250"
    width={250}
    height={250}
    {...props}
  >
    <path
      d="M0,0 L250,0 L250,250 L0,250 Z "
      fill="#FDFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L21,0 L37,5 L47,12 L52,17 L45,15 L31,15 L26,20 L26,29 L29,33 L31,33 L32,23 L35,27 L41,37 L49,44 L55,45 L55,35 L57,30 L58,30 L59,43 L63,47 L62,53 L62,67 L67,70 L66,75 L59,74 L55,73 L37,73 L35,80 L53,81 L62,83 L59,89 L58,90 L29,92 L21,96 L19,104 L35,100 L40,99 L50,99 L45,104 L39,108 L36,108 L35,111 L33,112 L35,117 L30,119 L24,120 L-4,120 L-12,117 L-11,113 L-6,109 L1,106 L3,104 L3,96 L-4,96 L-7,97 L-17,97 L-24,92 L-28,84 L-28,74 L-27,69 L-32,69 L-34,67 L-34,61 L-35,59 L-34,57 L-38,60 L-40,60 L-42,66 L-43,75 L-45,73 L-47,66 L-47,49 L-44,37 L-37,24 L-26,12 L-15,5 L-4,1 Z "
      fill="#DCDDDE"
      transform="translate(119,26)"
    />
    <path
      d="M0,0 L7,4 L8,7 L8,9 L9,18 L11,14 L13,18 L16,16 L18,18 L20,18 L22,15 L24,18 L26,18 L28,15 L29,19 L31,18 L33,19 L34,17 L38,18 L40,14 L41,17 L44,17 L46,15 L48,18 L53,19 L54,17 L58,16 L75,16 L84,18 L81,24 L80,25 L51,27 L43,31 L41,39 L57,35 L62,34 L72,34 L67,39 L61,43 L58,43 L57,46 L55,47 L57,52 L52,54 L46,55 L18,55 L10,52 L11,48 L16,44 L23,41 L25,39 L25,31 L18,31 L15,32 L5,32 L-2,27 L-6,19 L-6,9 L-3,3 Z "
      fill="#DEDCDC"
      transform="translate(97,91)"
    />
    <path
      d="M0,0 L21,0 L37,5 L47,12 L52,17 L45,15 L30,14 L25,12 L20,11 L20,8 L13,7 L5,1 L5,7 L-6,10 L-6,12 L-8,13 L-8,18 L-4,19 L-4,29 L-9,34 L-11,40 L-17,45 L-27,48 L-35,54 L-41,64 L-42,66 L-43,75 L-45,73 L-47,66 L-47,49 L-44,37 L-37,24 L-26,12 L-15,5 L-4,1 Z "
      fill="#0765E2"
      transform="translate(119,26)"
    />
    <path
      d="M0,0 L3,4 L9,14 L17,21 L23,22 L23,12 L25,7 L26,7 L27,20 L31,24 L30,30 L30,44 L35,47 L34,52 L27,51 L23,50 L5,50 L3,55 L1,56 L1,52 L-1,52 L-3,57 L-5,58 L-8,55 L-11,57 L-13,53 L-15,53 L-17,57 L-21,54 L-25,56 L-26,53 L-28,53 L-30,57 L-32,56 L-31,50 L-30,52 L-28,52 L-27,49 L-27,51 L-20,49 L-20,51 L-17,50 L-17,52 L-15,52 L-14,46 L-13,46 L-13,51 L-10,50 L-8,51 L-7,46 L-6,46 L-6,51 L-4,51 L-3,49 L-3,51 L-1,51 L1,45 L2,43 L0,43 L-2,48 L-4,48 L-7,45 L-9,48 L-12,47 L-14,45 L-18,48 L-20,44 L-22,44 L-24,48 L-27,46 L-27,44 L-31,42 L-29,42 L-29,37 L-27,41 L-25,42 L-24,40 L-24,42 L-22,42 L-22,40 L-20,40 L-20,42 L-18,42 L-17,40 L-17,42 L-15,42 L-14,37 L-13,37 L-13,42 L-11,42 L-11,40 L-8,41 L-6,37 L-5,41 L-1,41 L1,37 L2,41 L3,40 L21,40 L26,42 L24,38 L24,33 L10,33 L7,31 L7,26 L11,25 L4,16 L0,7 Z "
      fill="#C0BCBD"
      transform="translate(151,49)"
    />
    <path
      d="M0,0 L2,0 L1,7 L8,9 L9,11 L0,11 L-1,18 L-5,15 L-10,17 L-15,23 L-16,20 L-18,26 L-14,27 L-17,28 L-16,32 L-20,33 L-21,35 L-19,35 L-19,40 L-25,50 L-26,52 L-27,61 L-29,59 L-31,52 L-31,35 L-28,23 L-21,10 L-15,3 L-4,3 Z "
      fill="#0E67E1"
      transform="translate(103,40)"
    />
    <path
      d="M0,0 L6,0 L12,5 L12,11 L8,16 L6,17 L0,17 L-4,14 L-4,12 L-6,12 L-6,17 L-16,17 L-20,12 L-20,5 L-17,1 L-6,1 L-4,3 Z "
      fill="#131313"
      transform="translate(63,177)"
    />
    <path
      d="M0,0 L3,4 L9,14 L17,21 L23,22 L23,12 L25,7 L26,7 L27,20 L31,24 L30,30 L30,48 L27,47 L25,42 L24,33 L10,33 L7,31 L7,26 L11,25 L4,16 L0,7 Z "
      fill="#0B65E0"
      transform="translate(151,49)"
    />
    <path
      d="M0,0 L25,0 L27,2 L27,8 L26,11 L28,16 L24,16 L22,10 L18,10 L18,16 L-1,16 L-1,1 Z "
      fill="#E8E8E8"
      transform="translate(151,177)"
    />
    <path
      d="M0,0 L4,0 L5,10 L5,21 L-1,20 L-6,25 L-6,23 L-11,23 L-13,21 L-13,15 L-14,13 L-13,11 L-18,14 L-16,9 L-8,2 Z "
      fill="#F9F8F9"
      transform="translate(98,72)"
    />
    <path
      d="M0,0 L7,4 L8,7 L8,9 L9,17 L5,12 L2,10 L2,18 L6,25 L9,27 L21,28 L18,31 L15,32 L5,32 L-2,27 L-6,19 L-6,9 L-3,3 Z "
      fill="#ED0807"
      transform="translate(97,91)"
    />
    <path
      d="M0,0 L17,0 L26,2 L23,8 L22,9 L-7,11 L-11,12 L-5,4 L-3,1 Z "
      fill="#EE100E"
      transform="translate(155,107)"
    />
    <path
      d="M0,0 L1,4 L1,7 L6,3 L9,0 L10,3 L14,4 L11,5 L12,9 L8,10 L7,12 L9,12 L9,17 L3,27 L2,29 L1,38 L-1,36 L-3,29 L-3,12 Z "
      fill="#176CE1"
      transform="translate(75,63)"
    />
    <path
      d="M0,0 L11,0 L12,2 L12,11 L11,16 L1,16 L-3,11 L-3,4 Z "
      fill="#EDEDED"
      transform="translate(46,178)"
    />
    <path
      d="M0,0 L5,1 L7,4 L8,9 L12,1 L17,0 L18,1 L18,17 L14,17 L13,9 L10,16 L6,15 L4,9 L3,16 L0,16 Z "
      fill="#1B1B1B"
      transform="translate(129,177)"
    />
    <path
      d="M0,0 L15,0 L20,3 L22,7 L24,8 L24,5 L29,7 L28,12 L21,11 L14,9 L-1,9 Z "
      fill="#EE0807"
      transform="translate(157,89)"
    />
    <path
      d="M0,0 L10,0 L5,5 L-4,10 L-6,11 L-13,11 L-19,8 L-19,4 Z "
      fill="#EC1312"
      transform="translate(159,125)"
    />
    <path
      d="M0,0 L8,0 L14,1 L14,7 L11,6 L11,10 L14,9 L14,11 L8,12 L0,12 L0,9 L-2,9 L-3,11 L-5,11 L-5,1 L-3,1 L-1,3 Z "
      fill="#BDBDBD"
      transform="translate(130,159)"
    />
    <path
      d="M0,0 L4,3 L6,0 L9,3 L11,3 L12,9 L-31,9 L-30,4 L-26,4 L-23,4 L-21,5 L-19,3 L-17,4 L-17,6 L-15,6 L-14,4 L-14,6 L-12,6 L-11,3 L-10,5 L-8,2 L-5,4 L-3,2 L-2,5 L0,4 Z "
      fill="#B0B2B5"
      transform="translate(139,56)"
    />
    <path
      d="M0,0 L2,0 L1,7 L8,9 L9,11 L0,11 L-1,18 L-5,15 L-10,17 L-14,20 L-12,14 L-10,10 L-15,9 L-15,7 L-6,5 Z "
      fill="#E9F2F9"
      transform="translate(103,40)"
    />
    <path
      d="M0,0 L1,0 L2,13 L6,17 L5,23 L5,41 L2,40 L0,35 L-1,26 L-5,25 L-2,25 L-2,17 L-5,15 L-2,15 L-2,5 Z "
      fill="#2171DF"
      transform="translate(176,56)"
    />
    <path
      d="M0,0 L11,0 L13,2 L13,8 L12,11 L14,16 L10,16 L8,10 L4,10 L4,16 L0,16 Z "
      fill="#0A0A0A"
      transform="translate(165,177)"
    />
    <path
      d="M0,0 L9,0 L12,3 L12,8 L8,11 L3,11 L3,17 L-1,17 L-1,1 Z "
      fill="#1F1F1F"
      transform="translate(78,177)"
    />
    <path
      d="M0,0 L4,0 L9,12 L9,16 L-2,14 L-3,17 L-6,17 L-5,11 L-1,1 Z "
      fill="#1B1B1B"
      transform="translate(117,177)"
    />
    <path
      d="M0,0 L4,1 L9,13 L9,17 L5,17 L5,14 L-2,15 L-6,16 L-5,12 L-2,2 Z "
      fill="#161616"
      transform="translate(96,177)"
    />
    <path
      d="M0,0 L5,1 L10,14 L10,16 L6,16 L5,14 L-1,14 L-3,16 L-6,15 L-1,1 Z "
      fill="#3F3F3F"
      transform="translate(208,177)"
    />
    <path
      d="M0,0 L2,0 L0,6 L-1,8 L-6,8 L-7,6 L-8,8 L-13,8 L-14,6 L-15,9 L-21,7 L-25,8 L-30,9 L-31,4 L-29,1 L-27,1 L-24,4 L-22,1 L-20,1 L-17,4 L-13,1 L-9,5 L-7,1 L-4,3 L-4,5 L-2,5 Z "
      fill="#DADADB"
      transform="translate(151,92)"
    />
    <path
      d="M0,0 L18,1 L20,10 L0,7 Z "
      fill="#F5F7FA"
      transform="translate(157,81)"
    />
    <path
      d="M0,0 L11,0 L10,3 L3,3 L3,7 L10,7 L10,9 L3,9 L3,14 L11,14 L11,16 L-1,16 L-1,1 Z "
      fill="#0A0A0A"
      transform="translate(151,177)"
    />
    <path
      d="M0,0 L6,0 L8,2 L8,11 L6,13 L1,13 L-1,11 L-1,1 Z "
      fill="#1364DC"
      transform="translate(143,202)"
    />
    <path
      d="M0,0 L7,0 L7,3 L15,4 L15,5 L-1,7 L-9,8 L-10,7 L-10,2 Z "
      fill="#DDDFE3"
      transform="translate(120,37)"
    />
    <path
      d="M0,0 L14,1 L19,3 L18,7 L12,7 L8,3 L-2,3 L-7,8 L-9,8 L-10,11 L-10,3 L-6,1 Z "
      fill="#A8AAAE"
      transform="translate(125,42)"
    />
    <path
      d="M0,0 L5,1 L10,12 L10,14 L6,14 L5,10 L2,10 L2,13 L-2,13 Z "
      fill="#F11919"
      transform="translate(112,202)"
    />
    <path
      d="M0,0 L10,0 L10,3 L4,3 L2,4 L2,11 L3,12 L10,13 L10,15 L1,16 L-3,11 L-3,4 Z "
      fill="#0B0B0B"
      transform="translate(46,178)"
    />
    <path
      d="M0,0 L8,0 L10,2 L9,3 L1,4 L1,11 L7,13 L7,16 L0,16 L-4,11 L-4,4 Z "
      fill="#0F0F0F"
      transform="translate(193,177)"
    />
    <path
      d="M0,0 L3,1 L4,3 L8,2 L9,3 L9,13 L5,13 L5,10 L-1,10 L-1,1 Z "
      fill="#3276DD"
      transform="translate(165,201)"
    />
    <path
      d="M0,0 L3,0 L3,9 L5,9 L5,11 L-5,11 L-6,10 L-6,2 Z "
      fill="#4F4F4F"
      transform="translate(152,159)"
    />
    <path
      d="M0,0 L4,0 L4,11 L6,11 L6,2 L10,2 L10,11 L8,13 L3,13 L0,9 Z "
      fill="#EF1515"
      transform="translate(87,202)"
    />
    <path
      d="M0,0 L6,5 L18,6 L15,9 L12,10 L2,10 L-5,5 L-5,4 L0,3 Z "
      fill="#EB1010"
      transform="translate(100,113)"
    />
    <path
      d="M0,0 L5,0 L7,2 L7,7 L5,10 L8,11 L7,14 L-2,14 L0,10 L2,5 L-2,5 L-1,1 Z "
      fill="#1B67DD"
      transform="translate(132,201)"
    />
    <path
      d="M0,0 L7,0 L8,4 L4,3 L2,2 L3,5 L8,7 L8,12 L6,14 L1,14 L-1,12 L-1,9 L3,10 L5,11 L4,8 L-1,6 L-1,1 Z "
      fill="#EE0F0F"
      transform="translate(100,202)"
    />
    <path
      d="M0,0 L7,1 L8,5 L5,10 L8,11 L7,14 L-1,14 L0,10 L3,6 L4,2 L2,2 L1,5 L-2,5 L-1,1 Z "
      fill="#1C6ADB"
      transform="translate(155,201)"
    />
    <path
      d="M0,0 L4,4 L2,8 L-6,12 L-9,13 L-16,13 L-12,10 L-5,6 Z "
      fill="#EB0D0D"
      transform="translate(89,114)"
    />
    <path
      d="M0,0 L5,1 L7,3 L6,10 L5,11 L0,11 L-2,9 L-2,2 Z "
      fill="#EDEDED"
      transform="translate(64,180)"
    />
    <path
      d="M0,0 L1,0 L2,13 L4,17 L2,17 L1,20 L0,31 L-1,31 L-1,26 L-5,25 L-2,25 L-2,17 L-5,15 L-2,15 L-2,5 Z "
      fill="#4486DF"
      transform="translate(176,56)"
    />
    <path
      d="M0,0 L2,1 L2,3 L6,4 L3,5 L4,9 L0,10 L-2,14 L-3,14 L-3,8 L-6,8 L-6,6 L-1,1 Z "
      fill="#E5F0F8"
      transform="translate(83,63)"
    />
    <path
      d="M0,0 L4,0 L4,16 L0,16 Z "
      fill="#181818"
      transform="translate(182,177)"
    />
    <path
      d="M0,0 L6,0 L6,6 L3,5 L3,9 L6,8 L6,10 L0,10 L-2,9 L-2,1 Z "
      fill="#E0E0E0"
      transform="translate(138,160)"
    />
    <path
      d="M0,0 L4,0 L9,12 L9,16 L5,15 L5,13 L3,13 L3,5 L1,5 L1,2 L-1,1 Z "
      fill="#131313"
      transform="translate(117,177)"
    />
    <path
      d="M0,0 L5,1 L4,5 L1,5 L-2,15 L-6,16 L-3,7 Z "
      fill="#0A0A0A"
      transform="translate(208,177)"
    />
    <path
      d="M0,0 L4,2 L8,7 L8,11 L5,11 L4,14 L2,13 L1,10 L1,2 Z "
      fill="#F7DADA"
      transform="translate(97,99)"
    />
    <path
      d="M0,0 L4,2 L4,4 L9,0 L12,4 L12,7 L4,8 L3,6 L0,7 L-1,4 Z "
      fill="#D9DADB"
      transform="translate(107,93)"
    />
    <path
      d="M0,0 L5,1 L6,2 L6,7 L4,9 L-1,8 L-2,7 L-2,2 Z "
      fill="#E32A2A"
      transform="translate(87,86)"
    />
    <path
      d="M0,0 L1,0 L1,16 L-6,17 L-3,15 L-5,9 L-3,3 L-1,6 Z "
      fill="#D7D7D7"
      transform="translate(181,177)"
    />
    <path
      d="M0,0 L5,1 L6,2 L6,7 L5,8 L-1,8 L-2,7 L-2,2 Z "
      fill="#0A64DE"
      transform="translate(86,128)"
    />
    <path
      d="M0,0 L5,0 L7,2 L6,7 L5,8 L0,8 L-1,7 L-1,1 Z "
      fill="#1268DE"
      transform="translate(181,63)"
    />
    <path
      d="M0,0 L5,1 L6,6 L4,8 L-1,8 L-2,7 L-2,2 Z "
      fill="#EB1514"
      transform="translate(161,43)"
    />
    <path
      d="M0,0 L1,3 L4,2 L5,0 L7,0 L6,5 L2,12 L1,10 L-1,10 L-1,1 Z "
      fill="#D6E5F4"
      transform="translate(152,203)"
    />
    <path
      d="M0,0 L4,0 L3,9 L1,12 L-1,6 L-6,4 L-6,1 L0,3 Z "
      fill="#F7DADA"
      transform="translate(108,203)"
    />
    <path
      d="M0,0 L4,0 L4,16 L2,16 L2,6 L-2,5 L-1,1 Z "
      fill="#050505"
      transform="translate(142,177)"
    />
    <path
      d="M0,0 L2,0 L4,2 L5,0 L7,0 L7,10 L6,6 L4,6 L2,10 L0,10 Z "
      fill="#1F1F1F"
      transform="translate(125,160)"
    />
    <path
      d="M0,0 L4,0 L4,10 L-1,9 L-1,1 Z "
      fill="#565656"
      transform="translate(112,160)"
    />
    <path
      d="M0,0 L3,0 L3,9 L5,9 L5,11 L0,11 L-1,10 L-1,2 Z "
      fill="#7D7D7D"
      transform="translate(152,159)"
    />
    <path
      d="M0,0 L4,1 L2,13 L-2,13 Z "
      fill="#F20A0A"
      transform="translate(112,202)"
    />
    <path
      d="M0,0 L2,4 L7,6 L7,9 L1,7 L1,10 L-3,11 L-1,1 Z "
      fill="#F6D7D8"
      transform="translate(98,204)"
    />
    <path
      d="M0,0 L2,0 L3,2 L5,0 L5,10 L3,8 L2,10 L0,10 Z "
      fill="#1B1B1B"
      transform="translate(118,160)"
    />
    <path
      d="M0,0 L2,3 L5,8 L7,10 L2,12 L-3,14 L1,10 L-1,5 L-2,2 L0,2 Z "
      fill="#F8E2E1"
      transform="translate(91,110)"
    />
    <path
      d="M0,0 L5,3 L10,8 L3,6 L-11,5 L-11,4 L2,4 L2,2 L0,2 Z "
      fill="#3A7DD8"
      transform="translate(161,35)"
    />
    <path
      d="M0,0 L3,1 L3,10 L1,11 L1,9 L-3,8 L-1,5 Z "
      fill="#E9F3F9"
      transform="translate(139,203)"
    />
    <path
      d="M0,0 L4,0 L4,3 L6,4 L4,5 L2,9 L1,9 L1,3 L-2,3 L-2,1 Z "
      fill="#C9DEF2"
      transform="translate(79,68)"
    />
    <path
      d="M0,0 L5,0 L5,2 L8,3 L5,3 L5,12 L3,12 L3,1 Z "
      fill="#F5D3D3"
      transform="translate(88,201)"
    />
    <path
      d="M0,0 L4,0 L4,6 L1,5 L1,9 L4,8 L4,10 L0,10 Z "
      fill="#262626"
      transform="translate(140,160)"
    />
    <path
      d="M0,0 L6,0 L6,5 L0,5 Z "
      fill="#F00604"
      transform="translate(87,88)"
    />
    <path
      d="M0,0 L6,0 L5,5 L0,5 Z "
      fill="#DADADA"
      transform="translate(169,180)"
    />
    <path
      d="M0,0 L3,1 L3,9 L0,10 Z "
      fill="#015AE1"
      transform="translate(148,204)"
    />
    <path
      d="M0,0 L1,0 L1,6 L3,6 L3,13 L0,13 Z "
      fill="#025EE0"
      transform="translate(176,76)"
    />
    <path
      d="M0,0 L1,0 L1,9 L-7,10 L-6,8 L-3,8 Z "
      fill="#D0D0D0"
      transform="translate(142,184)"
    />
    <path
      d="M0,0 L4,1 L2,1 L2,4 L4,5 L2,5 L2,9 L4,10 L0,10 Z "
      fill="#242424"
      transform="translate(134,160)"
    />
    <path
      d="M0,0 L5,0 L5,5 L0,5 Z "
      fill="#E5E5E5"
      transform="translate(81,180)"
    />
    <path
      d="M0,0 L2,0 L4,5 L4,15 L2,16 L2,9 L0,4 Z "
      fill="#E2A5A5"
      transform="translate(103,94)"
    />
    <path
      d="M0,0 L3,1 L3,8 L1,9 L0,7 Z "
      fill="#C1D7EE"
      transform="translate(145,204)"
    />
    <path
      d="M0,0 L2,1 L2,4 L-1,5 L-8,5 L-4,2 Z "
      fill="#E02222"
      transform="translate(81,122)"
    />
    <path
      d="M0,0 L2,0 L3,8 L6,9 L5,11 L1,10 L1,8 L-1,7 Z "
      fill="#4884D9"
      transform="translate(134,203)"
    />
    <path
      d="M0,0 L2,0 L4,6 L1,5 L1,7 L-1,7 Z "
      fill="#EF3232"
      transform="translate(115,203)"
    />
    <path
      d="M0,0 L2,0 L4,4 L4,8 L1,7 L-1,2 Z "
      fill="#474FA9"
      transform="translate(177,89)"
    />
    <path
      d="M0,0 L2,0 L1,7 L-1,4 L-6,6 L-1,1 Z "
      fill="#CEE2F2"
      transform="translate(103,40)"
    />
    <path
      d="M0,0 L2,0 L4,6 L-1,6 Z "
      fill="#C2C2C2"
      transform="translate(96,182)"
    />
    <path
      d="M0,0 L10,0 L7,3 L0,1 Z "
      fill="#E43233"
      transform="translate(108,119)"
    />
    <path
      d="M0,0 L1,0 L1,11 L-2,9 L-1,2 Z "
      fill="#437DD7"
      transform="translate(176,56)"
    />
    <path
      d="M0,0 L7,0 L9,1 L9,3 L1,2 Z "
      fill="#8D8D8D"
      transform="translate(132,43)"
    />
    <path
      d="M0,0 L4,1 L-1,2 L-1,4 L-7,5 L-5,4 L-5,2 Z "
      fill="#9CBDE3"
      transform="translate(118,34)"
    />
    <path
      d="M0,0 L4,1 L7,5 L2,5 L0,3 Z "
      fill="#EA3232"
      transform="translate(99,211)"
    />
    <path
      d="M0,0 L2,0 L3,6 L-1,6 Z "
      fill="#C6C6C6"
      transform="translate(118,182)"
    />
    <path
      d="M0,0 L2,3 L4,7 L1,8 L-2,4 L-2,2 L0,2 Z "
      fill="#EBB4B3"
      transform="translate(91,110)"
    />
    <path
      d="M0,0 L3,0 L3,7 L0,5 Z "
      fill="#F60000"
      transform="translate(93,204)"
    />
    <path
      d="M0,0 L1,0 L2,5 L6,7 L1,8 L-1,6 Z "
      fill="#C6DAEF"
      transform="translate(179,65)"
    />
    <path
      d="M0,0 L3,1 L3,9 L2,5 L0,5 Z "
      fill="#B0CAE7"
      transform="translate(139,203)"
    />
    <path
      d="M0,0 L2,3 L2,6 L-2,9 L-4,8 L-1,4 Z "
      fill="#E1E1E1"
      transform="translate(75,184)"
    />
    <path
      d="M0,0 L4,1 L-1,4 L-2,9 L-3,9 L-3,2 Z "
      fill="#C2C2C2"
      transform="translate(50,180)"
    />
    <path
      d="M0,0 L5,2 L2,4 L1,7 L-2,6 L0,6 Z "
      fill="#ABC5E6"
      transform="translate(124,27)"
    />
  </svg>
)
export default CopaAmerica
