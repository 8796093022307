import * as React from 'react'
const SelfExecution = (props) => (
  <svg
    width={22}
    height={19}
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 1.468a4.5 4.5 0 0 1 0 8.064m2 5.234c1.512.684 2.872 1.799 4 3.234M1 18c1.946-2.477 4.59-4 7.5-4s5.554 1.523 7.5 4M13 5.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0"
      stroke="green"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SelfExecution
