import * as React from 'react'
const SecretIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    viewBox="0 0 60 60"
    {...props}
  >
    <path
      d="M0,0 L24,0 L26,2 L26,10 L23,10 L23,3 L1,3 L1,12 L11,12 L12,15 L0,15 L-2,13 L-2,2 Z "
      fill="#0A7C0D"
      transform="translate(18,20)"
    />
    <path
      d="M0,0 L5,1 L5,4 L7,4 L8,10 L6,12 L-2,12 L-4,10 L-4,5 Z M1,2 L1,4 L3,4 L3,2 Z M-1,7 L-1,9 L5,9 L5,7 Z "
      fill="#0A7D09"
      transform="translate(35,31)"
    />
    <path
      d="M0,0 L3,0 L3,3 L0,3 Z "
      fill="#0E7A0E"
      transform="translate(22,26)"
    />
    <path
      d="M0,0 L3,1 L2,3 L-1,2 Z "
      fill="#0D7D0A"
      transform="translate(29,26)"
    />
    <path
      d="M0,0 L3,1 L3,3 L0,3 Z "
      fill="#0D7C12"
      transform="translate(35,26)"
    />
  </svg>
)
export default SecretIcon
