import { americanToDecimal } from 'bookmakerjs'

const GRADE = {
  WON: 'win',
  LOSS: 'loss',
  REFUND: 'refund',
}

export const calculateWinPriceMultiBet = (betSlips, price) => {
  const validBetSlips = betSlips.filter(({ grade }) => grade === GRADE.WON)

  const multiBetOdds = validBetSlips.reduce(
    (acc, { price: betSlipPrice }) => acc * americanToDecimal(betSlipPrice),
    1,
  )

  return price * multiBetOdds - price
}

export const calculateWinPriceSingle = (betPrice, price) => {
  let winFactor
  if (Number(price) > 0) {
    winFactor = Number(price) / 100
  } else {
    winFactor = 100 / Math.abs(Number(price))
  }
  return Number(betPrice) * winFactor
}

export const calculateMultiBetWinPrice = (betSlips, price) => {
  const multiBetOdds = betSlips.reduce(
    (acc, { price: betSlipPrice }) => acc * americanToDecimal(betSlipPrice),
    1,
  )

  return price * multiBetOdds - price
}

export const getSinglePossibleWin = ({ betPrice = 0, price = 0 }) => {
  let possibleWin = 0

  if (price > 0) {
    possibleWin = betPrice * (price / 100)
  } else {
    possibleWin = betPrice * (100 / Math.abs(price))
  }

  return isNaN(possibleWin) ? 0 : possibleWin
}

export const calculateCoefficient = (oddData) => {
  if (oddData?.betSlips) {
    return Number(
      oddData?.betSlips?.reduce((acc, curr) => {
        const { price } = curr
        const decimalPrice = americanToDecimal(price)
        return acc * decimalPrice
      }, 1) || 0.0,
    )?.toFixed(2)
  } else {
    return americanToDecimal(oddData?.price)
  }
}
