import React from 'react'

const Mma = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0869 2.70496H6.91312C6.74016 2.70496 6.58016 2.79728 6.49376 2.94704L1.40688 11.7579C1.32032 11.9078 1.32032 12.0923 1.40688 12.2422L6.49376 21.053C6.58032 21.2029 6.74016 21.295 6.91312 21.295H17.0869C17.2598 21.295 17.4198 21.2027 17.5062 21.053L22.5931 12.2422C22.6797 12.0923 22.6797 11.9078 22.5931 11.7579L17.5062 2.9472C17.4197 2.79728 17.2598 2.70512 17.0869 2.70512V2.70496Z"
      fill="black"
      stroke="white"
      strokeMiterlimit={10}
    />
    <path
      d="M14.2117 10.2686C14.1806 10.5288 14.1256 10.7888 14.1227 11.0493C14.1115 12.0579 14.0856 13.068 14.1254 14.0752C14.1635 15.0387 13.4925 14.8394 12.9341 14.893C12.3186 14.952 12.0669 14.7232 12.0875 14.0867C12.1219 13.0261 12.1013 11.9634 12.0899 10.9016C12.0874 10.6619 12.0275 10.4229 11.9941 10.1837C11.9107 10.1813 11.8274 10.1787 11.744 10.1763C11.7085 10.38 11.6442 10.5832 11.6421 10.7872C11.6309 11.8755 11.6323 12.964 11.6358 14.0523C11.6373 14.4875 11.5714 14.9501 11.0176 14.821C10.4925 14.6984 9.59776 15.103 9.584 14.0336C9.57168 13.0781 9.5856 12.1222 9.57584 11.1667C9.57328 10.924 9.52448 10.6816 9.49696 10.439C9.41232 10.4342 9.32784 10.4296 9.2432 10.4248C9.2008 10.6195 9.12464 10.8136 9.12176 11.009C9.10832 11.9114 9.09152 12.8149 9.1224 13.7163C9.14592 14.4046 8.84256 14.5326 8.23632 14.3938C7.07792 14.1285 7.0696 14.1387 7.06976 12.9272C7.06976 11.7858 7.0856 10.644 7.06368 9.50287C7.05408 9.00671 7.20912 8.72991 7.71952 8.58703C10.4274 7.82991 13.1507 7.70575 15.9094 8.26767C16.4064 8.36895 16.6357 8.56943 16.6259 9.10927C16.5981 10.6485 16.6237 12.1885 16.6126 13.7282C16.6077 14.411 16.1011 14.7306 15.1202 14.6947C14.4474 14.6702 14.5893 14.1574 14.584 13.7562C14.5717 12.8272 14.5848 11.8979 14.5734 10.969C14.5706 10.7349 14.5136 10.5014 14.4816 10.2677C14.3918 10.2682 14.3019 10.2685 14.2122 10.269L14.2117 10.2686Z"
      fill="white"
    />
    <path
      d="M16.4736 14.9264L12.4948 15.2584C12.3089 15.274 12.1707 15.4372 12.1863 15.6231L12.2832 16.7853C12.2988 16.9712 12.462 17.1093 12.6479 17.0938L16.6267 16.7618C16.8126 16.7463 16.9507 16.583 16.9352 16.3971L16.8383 15.2349C16.8227 15.049 16.6595 14.9109 16.4736 14.9264Z"
      fill="white"
    />
  </svg>
)
export default Mma
