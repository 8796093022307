import React from 'react'
const Xfl = (props) => (
  <svg
    viewBox="0 0 48 48"
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2_119)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
        fill="white"
      />
      <path
        d="M3.14122 28.659V28.4872L8.29164 23.8154L3 19.0132C3.88413 19.0132 4.7709 19.0156 5.65591 19.0132C5.69099 19.0132 5.72871 19.0171 5.76467 19.0235C6.94965 20.0968 8.10744 21.1947 9.31699 22.2441C9.55995 22.4549 9.88273 22.6658 9.95816 22.9649C10.0748 23.4248 10.0327 23.9339 10.0125 24.4049C10.0029 24.6166 9.97219 24.7319 9.82659 24.8855C8.59863 26.1744 7.04701 27.4425 5.70678 28.6582H3.14122V28.659Z"
        fill="#1B1914"
      />
      <path
        d="M17.7417 28.659H15.2121C13.871 27.4616 12.5667 26.2308 11.2265 25.0351C11.0528 24.8807 10.9563 24.7805 10.9247 24.5657C10.8572 24.1241 10.8669 23.6515 10.8967 23.2052C10.909 23.0095 10.9484 22.8631 11.0923 22.7246C12.4115 21.4557 13.7964 20.2344 15.17 19.0132H17.9101L12.6158 23.8146L17.9575 28.659H17.7426H17.7417Z"
        fill="#1B1914"
      />
      <path
        d="M34.9454 28.6781C34.6604 28.6773 34.3999 28.5763 34.1955 28.4076C33.6955 27.9931 33.1772 27.5062 32.7255 27.0463C32.657 26.9771 32.5509 26.7337 32.5483 26.6461C32.493 24.222 32.5281 21.7906 32.5281 19.364V19.0132H34.656C34.6683 19.0506 34.6928 19.0856 34.6928 19.1246C34.7156 21.727 34.6928 24.3325 34.6928 26.9357H43.9964V28.6773C40.9782 28.6773 37.9618 28.6805 34.9446 28.6773"
        fill="#1B1914"
      />
      <path
        d="M19.2959 28.6781C19.2924 27.4664 19.2477 26.2571 19.3056 25.047C19.3196 24.821 20.3108 24.0239 20.5362 23.8154C20.1651 23.4821 19.7941 23.1471 19.4328 22.8058C19.4082 22.7827 19.3819 22.7565 19.374 22.7254C19.2565 22.2926 19.3143 21.8113 19.3161 21.365C19.317 21.1684 19.3836 20.9465 19.5319 20.8049C20.0994 20.2607 20.6932 19.7348 21.2887 19.2161C21.4387 19.0848 21.6869 19.0164 21.8887 19.014C24.9867 18.9821 28.0873 19.0148 31.1852 19.014V20.7555H21.5291L21.5203 22.953C24.2306 22.953 26.9409 22.9506 29.652 22.953C29.6696 22.953 29.7555 22.9848 29.7731 22.9936V24.6762H21.5194V28.6773H19.2951L19.2959 28.6781Z"
        fill="#1B1914"
      />
      <path
        d="M45.0024 27.1975H45.0542C45.098 27.1975 45.1375 27.1959 45.1375 27.1506C45.1375 27.1124 45.1016 27.1068 45.0682 27.1068H45.0033V27.1975H45.0024ZM44.9533 27.0702H45.0752C45.1498 27.0702 45.1858 27.0973 45.1858 27.1521C45.1858 27.2039 45.1498 27.2253 45.1024 27.2301L45.1928 27.3566H45.1401L45.0542 27.2333H45.0024V27.3566H44.9533V27.0702ZM45.0586 27.4227C45.1831 27.4227 45.2805 27.332 45.2805 27.2126C45.2805 27.0933 45.1831 27.005 45.0586 27.005C44.934 27.005 44.8358 27.0957 44.8358 27.2126C44.8358 27.3296 44.9323 27.4227 45.0586 27.4227ZM45.0586 26.9684C45.2059 26.9684 45.3296 27.0742 45.3296 27.2118C45.3296 27.3495 45.2059 27.4585 45.0586 27.4585C44.9112 27.4585 44.7867 27.3526 44.7867 27.2118C44.7867 27.071 44.9112 26.9684 45.0586 26.9684Z"
        fill="#1B1914"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_119">
        <rect width={48} height={48} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default Xfl
