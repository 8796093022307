import React from 'react'

const FaCup = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={250}
    height={208}
    viewBox="0 0 250 208"
    {...props}
  >
    <path
      d="M0 0 C82.5 0 165 0 250 0 C250 68.64 250 137.28 250 208 C167.5 208 85 208 0 208 C0 139.36 0 70.72 0 0 Z "
      fill="#FEFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0 0 C1.57 0.01 1.57 0.01 3.17 0.02 C17.04 0.32 30.04 3.86 42.31 10.44 C42.99 10.71 43.66 10.99 44.36 11.27 C47.08 12.71 48.51 13.91 50.31 16.44 C51.48 22.6 51.24 28.71 51.09 34.96 C51.08 36.58 51.07 38.21 51.07 39.83 C51.03 45.82 50.92 51.82 50.81 57.81 C50.65 71.22 50.48 84.62 50.31 98.44 C17.97 98.44 -14.37 98.44 -47.69 98.44 C-47.69 71.05 -47.69 43.66 -47.69 15.44 C-31.25 6.05 -31.25 6.05 -22.69 3.44 C-21.44 3.04 -21.44 3.04 -20.17 2.63 C-13.36 0.6 -7.1 -0.07 0 0 Z "
      fill="#121E44"
      transform="translate(124.6875,23.5625)"
    />
    <path
      d="M0 0 C32.34 0 64.68 0 98 0 C98 18.15 98 36.3 98 55 C92.48 57.76 87.64 59.97 81.81 61.62 C80.69 61.94 80.69 61.94 79.55 62.27 C53.08 69.57 25 67.11 0 56 C0 37.52 0 19.04 0 0 Z "
      fill="#DD232E"
      transform="translate(77,125)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C4.12 6.62 4.12 6.62 3 10 C3.99 10.33 4.98 10.66 6 11 C5.67 12.65 5.34 14.3 5 16 C5.76 15.92 6.53 15.84 7.31 15.75 C10 16 10 16 11.88 17.69 C13.3 20.61 12.96 21.95 12 25 C11.67 25.66 11.34 26.32 11 27 C10.75 32.14 11.05 35.25 13 40 C13.56 43.5 13.56 43.5 13 47 C8.57 51.05 3.54 53.27 -1.96 55.59 C-4.26 56.87 -4.26 56.87 -4.79 59.66 C-4.86 60.43 -4.93 61.2 -5 62 C-7.82 59.71 -8.93 58.68 -9.31 55 C-8.79 50.02 -8.79 50.02 -8 48 C-5 46 -5 46 -2 46 C-2 45.34 -2 44.68 -2 44 C0.97 43.5 0.97 43.5 4 43 C4 42.01 4 41.02 4 40 C-2.77 43.86 -8.97 48.07 -15 53 C-14.61 56.57 -14.61 56.57 -12.57 59.37 C-11.64 59.9 -11.64 59.9 -10.69 60.44 C-9.97 60.85 -9.26 61.26 -8.53 61.68 C-4.64 63.71 -0.7 65.62 3.27 67.47 C6.07 69.04 7.94 70.53 10 73 C10.69 76.25 10.69 76.25 10 80 C7.93 82.94 5.56 85.48 3 88 C1.53 85.06 2 82.26 2 79 C1.35 75.92 1.35 75.92 -0.85 74.98 C-1.56 74.66 -2.27 74.33 -3 74 C-4.17 73.12 -5.31 72.2 -6.42 71.25 C-7.03 70.72 -7.65 70.2 -8.28 69.66 C-8.91 69.11 -9.54 68.56 -10.19 68 C-10.83 67.45 -11.47 66.91 -12.13 66.34 C-17.84 61.43 -17.84 61.43 -18.38 56.69 C-17.9 52.01 -16.29 51.21 -13 48 C-12.27 45.99 -11.84 44.06 -11.46 41.96 C-11 40 -11 40 -9.96 38.04 C-8.85 35.69 -8.73 34.08 -8.75 31.5 C-8.74 30.75 -8.74 29.99 -8.73 29.22 C-9.03 26.77 -9.86 25.17 -11 23 C-10.63 19.94 -9.77 18.65 -8 16 C-5.38 15.31 -5.38 15.31 -3 15 C-3.33 13.68 -3.66 12.36 -4 11 C-2.68 10.67 -1.36 10.34 0 10 C-0.19 9.09 -0.37 8.19 -0.56 7.25 C-0.96 4.32 -0.95 2.74 0 0 Z "
      fill="#F4F5F5"
      transform="translate(125,31)"
    />
    <path
      d="M0 0 C2.38 -0.12 2.38 -0.12 5 0 C7 2 7 2 7.14 3.88 C7.08 4.62 7.03 5.37 6.98 6.13 C6.9 7.34 6.9 7.34 6.82 8.57 C6.76 9.42 6.69 10.26 6.62 11.12 C6.57 11.97 6.51 12.82 6.45 13.7 C6.31 15.8 6.16 17.9 6 20 C6.66 20 7.32 20 8 20 C8.54 21.16 9.07 22.31 9.62 23.5 C11.19 26.67 12.96 29.4 15.12 32.19 C19.24 37.53 20.27 41.17 20 48 C19.01 47.34 18.02 46.68 17 46 C16.53 46.83 16.05 47.65 15.56 48.5 C15.05 49.33 14.53 50.15 14 51 C13.67 51 13.34 51 13 51 C12.94 50.25 12.87 49.5 12.81 48.73 C11.94 41.5 10.16 36.88 5.66 31.16 C1.24 25.41 1.37 20.08 2 13 C2.8 10.2 3.86 7.69 5 5 C4.67 4.34 4.34 3.68 4 3 C2.35 3 0.7 3 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
      fill="#EFEFF0"
      transform="translate(141,49)"
    />
    <path
      d="M0 0 C0.78 0.04 1.57 0.08 2.38 0.12 C2.7 0.78 3.03 1.45 3.38 2.12 C1.72 2.78 0.08 3.45 -1.62 4.12 C-1.14 6.85 -0.56 9.29 0.44 11.88 C1.69 16.23 2.52 19.67 1.38 24.12 C-0.75 27.5 -3.02 30.45 -5.56 33.52 C-8.72 37.5 -8.84 40.86 -9.19 45.81 C-9.27 46.99 -9.36 48.17 -9.44 49.39 C-9.5 50.29 -9.56 51.19 -9.62 52.12 C-11.12 50.88 -11.12 50.88 -12.62 49.12 C-12.62 47.81 -12.62 46.48 -12.62 45.12 C-13.28 45.12 -13.94 45.12 -14.62 45.12 C-14.95 46.12 -15.28 47.1 -15.62 48.12 C-15.95 48.12 -16.28 48.12 -16.62 48.12 C-17.39 38.99 -12.98 33.31 -7.38 26.62 C-5.8 24.78 -4.22 22.95 -2.62 21.12 C-3.61 21.45 -4.61 21.78 -5.62 22.12 C-5.52 18.83 -5.38 15.54 -5.25 12.25 C-5.22 11.31 -5.19 10.38 -5.16 9.41 C-5.12 8.51 -5.08 7.62 -5.04 6.7 C-5.01 5.87 -4.98 5.04 -4.94 4.19 C-4.43 0.86 -3.29 0.16 0 0 Z "
      fill="#E3E4E5"
      transform="translate(108.625,48.875)"
    />
    <path
      d="M0 0 C7.59 0 15.18 0 23 0 C22.67 1.98 22.34 3.96 22 6 C22.33 7.32 22.66 8.64 23 10 C19.7 10 16.4 10 13 10 C13 8.68 13 7.36 13 6 C12.34 6 11.68 6 11 6 C11 7.32 11 8.64 11 10 C10.01 10 9.02 10 8 10 C8 7.36 8 4.72 8 2 C7.01 2 6.02 2 5 2 C5 4.64 5 7.28 5 10 C4.01 10 3.02 10 2 10 C2 7.69 2 5.38 2 3 C1.34 3 0.68 3 0 3 C0 2.01 0 1.02 0 0 Z "
      fill="#E89597"
      transform="translate(114,130)"
    />
    <path
      d="M0 0 C2.63 -0.05 5.25 -0.09 7.88 -0.12 C8.62 -0.14 9.37 -0.16 10.14 -0.18 C12.09 -0.19 14.05 -0.1 16 0 C18 2 18 2 18.2 4.6 C18.17 5.6 18.15 6.6 18.12 7.62 C18.11 8.63 18.09 9.63 18.07 10.66 C18.05 11.43 18.02 12.21 18 13 C16.68 12.67 15.36 12.34 14 12 C14 9.03 14 6.06 14 3 C13.01 3 12.02 3 11 3 C11 5.97 11 8.94 11 12 C9.68 12 8.36 12 7 12 C7 9.03 7 6.06 7 3 C6.01 3 5.02 3 4 3 C4.33 6.3 4.66 9.6 5 13 C3.68 13 2.36 13 1 13 C0.67 8.71 0.34 4.42 0 0 Z "
      fill="#F8E6E6"
      transform="translate(99,146)"
    />
    <path
      d="M0 0 C2.46 -0.05 4.92 -0.09 7.38 -0.12 C8.42 -0.15 8.42 -0.15 9.49 -0.18 C11.33 -0.19 13.17 -0.1 15 0 C17.59 2.59 17.37 4.03 17.62 7.62 C17.7 8.63 17.77 9.63 17.85 10.66 C17.93 11.82 17.93 11.82 18 13 C15.69 12.67 13.38 12.34 11 12 C11.66 11.67 12.32 11.34 13 11 C12.67 9.68 12.34 8.36 12 7 C11.34 8.65 10.68 10.3 10 12 C9.34 12 8.68 12 8 12 C7.69 9.81 7.69 9.81 8 7 C10.5 4.19 10.5 4.19 13 2 C12.01 2.5 12.01 2.5 11 3 C8.67 3.04 6.33 3.04 4 3 C4 5.97 4 8.94 4 12 C3.01 12 2.02 12 1 12 C0.67 8.04 0.34 4.08 0 0 Z "
      fill="#F7E3E3"
      transform="translate(124,146)"
    />
    <path
      d="M0 0 C1.98 0 3.96 0 6 0 C10.41 9.41 10.41 9.41 11 14 C9.68 14 8.36 14 7 14 C7 13.34 7 12.68 7 12 C4.03 12.66 1.06 13.32 -2 14 C-1.33 9.33 -0.67 4.67 0 0 Z "
      fill="#F5D6D7"
      transform="translate(108,164)"
    />
    <path
      d="M0 0 C1.61 -0.04 3.22 -0.08 4.88 -0.12 C5.78 -0.15 6.68 -0.17 7.62 -0.2 C10 0 10 0 12 2 C12.25 4.5 12.25 4.5 12 7 C10 9 10 9 7.38 9.12 C6.59 9.08 5.81 9.04 5 9 C5 10.32 5 11.64 5 13 C3.68 13 2.36 13 1 13 C0.67 8.71 0.34 4.42 0 0 Z "
      fill="#F9E6E7"
      transform="translate(144,165)"
    />
    <path
      d="M0 0 C3.63 0 7.26 0 11 0 C11.33 0.66 11.66 1.32 12 2 C9.69 2 7.38 2 5 2 C5 2.99 5 3.98 5 5 C6.32 5 7.64 5 9 5 C9 5.66 9 6.32 9 7 C7.68 7 6.36 7 5 7 C5 8.98 5 10.96 5 13 C7.31 13 9.62 13 12 13 C12 13.99 12 14.98 12 16 C8.37 16 4.74 16 1 16 C0.67 10.72 0.34 5.44 0 0 Z "
      fill="#F7E2E3"
      transform="translate(86,143)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C4 2.97 4 5.94 4 9 C4.99 9 5.98 9 7 9 C7 6.03 7 3.06 7 0 C7.99 0 8.98 0 10 0 C10.05 1.79 10.09 3.58 10.12 5.38 C10.15 6.37 10.17 7.37 10.2 8.4 C10 11 10 11 8 13 C5 13.25 5 13.25 2 13 C0 11 0 11 -0.2 8.4 C-0.17 7.4 -0.15 6.4 -0.12 5.38 C-0.11 4.37 -0.09 3.37 -0.07 2.34 C-0.05 1.57 -0.02 0.79 0 0 Z "
      fill="#F7E2E2"
      transform="translate(133,165)"
    />
    <path
      d="M0 0 C1.36 0.37 1.36 0.37 2.75 0.75 C3.08 2.07 3.41 3.39 3.75 4.75 C0.78 4.25 0.78 4.25 -2.25 3.75 C-2.25 5.73 -2.25 7.71 -2.25 9.75 C-0.27 9.75 1.71 9.75 3.75 9.75 C3.75 10.74 3.75 11.73 3.75 12.75 C0.5 13.5 0.5 13.5 -3.25 13.75 C-5.69 12.19 -5.69 12.19 -7.25 9.75 C-7.87 6.06 -7.71 4.44 -5.62 1.31 C-3.25 -0.25 -3.25 -0.25 0 0 Z "
      fill="#F8E5E5"
      transform="translate(128.25,164.25)"
    />
    <path
      d="M0 0 C2.97 0 5.94 0 9 0 C8.67 2.97 8.34 5.94 8 9 C6.68 9 5.36 9 4 9 C4 10.65 4 12.3 4 14 C2.68 14 1.36 14 0 14 C0 9.38 0 4.76 0 0 Z "
      fill="#F6D9DA"
      transform="translate(98,164)"
    />
    <path
      d="M0 0 C0.95 0.04 1.9 0.08 2.88 0.12 C3.21 1.78 3.53 3.43 3.88 5.12 C3.22 5.58 2.56 6.03 1.88 6.5 C0.88 7.3 0.88 7.3 -0.12 8.12 C-0.12 8.78 -0.12 9.44 -0.12 10.12 C1.19 10.12 2.51 10.12 3.88 10.12 C3.54 11.12 3.22 12.1 2.88 13.12 C0.21 13.34 -1.57 13.43 -3.94 12.12 C-5.91 8.8 -5.43 5.94 -5.12 2.12 C-3.12 0.12 -3.12 0.12 0 0 Z "
      fill="#F8E8E9"
      transform="translate(155.125,145.875)"
    />
    <path
      d="M0 0 C-0.62 4.68 -0.62 4.68 -2 7 C-4.31 8.13 -6.49 8.73 -9 9.3 C-9.66 9.53 -10.32 9.76 -11 10 C-11.33 10.99 -11.66 11.98 -12 13 C-13 12 -13 12 -13.1 10.15 C-13.07 8.1 -13.03 6.05 -13 4 C-11.02 3.67 -9.04 3.34 -7 3 C-7 2.34 -7 1.68 -7 1 C-4 0 -4 0 0 0 Z "
      fill="#E5E5E7"
      transform="translate(136,83)"
    />
    <path
      d="M0 0 C3.3 0 6.6 0 10 0 C10 0.99 10 1.98 10 3 C10.99 3 11.98 3 13 3 C13 2.01 13 1.02 13 0 C13.66 0 14.32 0 15 0 C15 2.64 15 5.28 15 8 C14.34 8 13.68 8 13 8 C13 7.01 13 6.02 13 5 C12.34 5 11.68 5 11 5 C11 6.32 11 7.64 11 9 C10.01 9 9.02 9 8 9 C8 6.36 8 3.72 8 1 C7.01 1 6.02 1 5 1 C5 3.64 5 6.28 5 9 C4.01 9 3.02 9 2 9 C2 6.69 2 4.38 2 2 C1.34 2 0.68 2 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#F5D3D4"
      transform="translate(114,131)"
    />
    <path
      d="M0 0 C1.98 0 3.96 0 6 0 C6 0.66 6 1.32 6 2 C5.01 2.33 4.02 2.66 3 3 C4.32 3.99 5.64 4.98 7 6 C6.67 7.98 6.34 9.96 6 12 C4.02 12.33 2.04 12.66 0 13 C-0.33 12.01 -0.66 11.02 -1 10 C0.32 10 1.64 10 3 10 C3 9.01 3 8.02 3 7 C1.68 6.34 0.36 5.68 -1 5 C-0.67 3.35 -0.34 1.7 0 0 Z "
      fill="#F7E6E6"
      transform="translate(161,146)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2 0.66 2 1.32 2 2 C2.99 2 3.98 2 5 2 C5 2.66 5 3.32 5 4 C4.01 4 3.02 4 2 4 C2.33 7.63 2.66 11.26 3 15 C1.35 14.67 -0.3 14.34 -2 14 C-2.05 12.04 -2.09 10.08 -2.12 8.12 C-2.15 7.03 -2.17 5.94 -2.2 4.82 C-2 2 -2 2 0 0 Z "
      fill="#F6DBDB"
      transform="translate(145,144)"
    />
    <path
      d="M0 0 C3.38 -0.12 3.38 -0.12 7 0 C9 2 9 2 9.25 4.5 C9 7 9 7 7 9 C4.38 9.12 4.38 9.12 2 9 C2.04 7.89 2.08 6.77 2.12 5.62 C2.41 1.96 2.41 1.96 0 0 Z "
      fill="#F3C6C7"
      transform="translate(147,165)"
    />
    <path
      d="M0 0 C1.65 0.33 3.3 0.66 5 1 C6 4 6 4 4.56 7.19 C3.79 8.58 3.79 8.58 3 10 C2.67 9.34 2.34 8.68 2 8 C1.01 8 0.02 8 -1 8 C-2.21 4.38 -1.54 3.36 0 0 Z "
      fill="#1B2042"
      transform="translate(141,51)"
    />
    <path
      d="M0 0 C2.64 0 5.28 0 8 0 C8 0.66 8 1.32 8 2 C7.01 2.33 6.02 2.66 5 3 C4.67 5.97 4.34 8.94 4 12 C3.01 12 2.02 12 1 12 C0.67 8.04 0.34 4.08 0 0 Z "
      fill="#FBF5F4"
      transform="translate(124,146)"
    />
    <path
      d="M0 0 C1.03 3.27 0.96 5.86 0.56 9.25 C0.46 10.14 0.36 11.03 0.25 11.95 C0.17 12.63 0.09 13.3 0 14 C0.99 14 1.98 14 3 14 C3 12.68 3 11.36 3 10 C3.66 10 4.32 10 5 10 C5 12.97 5 15.94 5 19 C4.34 19 3.68 19 3 19 C2.67 19.66 2.34 20.32 2 21 C-1.78 15.34 -1.5 9.65 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
      fill="#CECFD2"
      transform="translate(144,59)"
    />
    <path
      d="M0 0 C-0.33 0.6 -0.66 1.2 -1 1.81 C-2.21 4.05 -2.21 4.05 -2 7 C-3.65 7 -5.3 7 -7 7 C-7.62 4.62 -7.62 4.62 -8 2 C-5.44 -0.56 -3.38 -1.69 0 0 Z "
      fill="#6A6E80"
      transform="translate(114,51)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C4.12 6.62 4.12 6.62 3 10 C3.66 10.33 4.32 10.66 5 11 C2.36 11 -0.28 11 -3 11 C-2.01 10.67 -1.02 10.34 0 10 C-0.28 8.64 -0.28 8.64 -0.56 7.25 C-0.96 4.32 -0.95 2.74 0 0 Z "
      fill="#DADBDD"
      transform="translate(125,31)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.67 2.65 1.34 4.3 1 6 C1.66 6.33 2.32 6.66 3 7 C2.01 7.33 1.02 7.66 0 8 C-0.33 8.99 -0.66 9.98 -1 11 C-1.99 11 -2.98 11 -4 11 C-3.67 8.69 -3.34 6.38 -3 4 C-2.34 4 -1.68 4 -1 4 C-0.67 2.68 -0.34 1.36 0 0 Z "
      fill="#FAEEEE"
      transform="translate(110,167)"
    />
    <path
      d="M0 0 C1.36 0.37 1.36 0.37 2.75 0.75 C3.08 2.07 3.41 3.39 3.75 4.75 C2.8 4.59 1.85 4.42 0.88 4.25 C-2.05 3.7 -2.05 3.7 -4.25 3.75 C-4.91 5.73 -5.57 7.71 -6.25 9.75 C-6.58 9.75 -6.91 9.75 -7.25 9.75 C-7.57 6.19 -7.64 4.33 -5.62 1.31 C-3.25 -0.25 -3.25 -0.25 0 0 Z "
      fill="#F2CECF"
      transform="translate(128.25,164.25)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C3 3.96 3 7.92 3 12 C2.01 12 1.02 12 0 12 C0 8.04 0 4.08 0 0 Z "
      fill="#FCF8F8"
      transform="translate(119,146)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C3 3.63 3 7.26 3 11 C2.01 11.33 1.02 11.66 0 12 C0 8.04 0 4.08 0 0 Z "
      fill="#FCF8F8"
      transform="translate(140,165)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C4.01 0.33 3.02 0.66 2 1 C2 1.66 2 2.32 2 3 C2.99 3 3.98 3 5 3 C5.33 4.65 5.66 6.3 6 8 C4.02 8 2.04 8 0 8 C0 5.36 0 2.72 0 0 Z "
      fill="#FAEEEE"
      transform="translate(131,131)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2 0.99 2 1.98 2 3 C2.66 3 3.32 3 4 3 C4.33 2.01 4.66 1.02 5 0 C4.69 3.67 4.24 4.78 1.44 7.31 C0.63 7.87 -0.17 8.43 -1 9 C-1.81 6.09 -2.22 4.54 -1.06 1.69 C-0.71 1.13 -0.36 0.57 0 0 Z "
      fill="#EBA4A5"
      transform="translate(154,147)"
    />
    <path
      d="M0 0 C0.33 0.99 0.66 1.98 1 3 C1.99 3 2.98 3 4 3 C4 2.01 4 1.02 4 0 C4.66 0 5.32 0 6 0 C6 2.64 6 5.28 6 8 C5.34 8 4.68 8 4 8 C4 7.01 4 6.02 4 5 C3.01 5 2.02 5 1 5 C0.67 5.99 0.34 6.98 0 8 C0 5.36 0 2.72 0 0 Z "
      fill="#FEFCFC"
      transform="translate(123,131)"
    />
    <path
      d="M0 0 C0.33 0.66 0.66 1.32 1 2 C-0.25 3.5 -0.25 3.5 -2 5 C-3.32 5 -4.64 5 -6 5 C-6 5.66 -6 6.32 -6 7 C-8.36 9.36 -9.78 9.49 -13 10 C-11.68 6.04 -10.14 5.74 -6.5 3.88 C-3.03 2.26 -3.03 2.26 0 0 Z "
      fill="#6E7381"
      transform="translate(129,70)"
    />
    <path
      d="M0 0 C2.38 -0.12 2.38 -0.12 5 0 C5.66 0.66 6.32 1.32 7 2 C6.62 5.62 6.62 5.62 6 9 C5.34 9 4.68 9 4 9 C4.19 8.38 4.37 7.76 4.56 7.12 C5.24 4.96 5.24 4.96 4 3 C2.35 3 0.7 3 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
      fill="#D5D6D8"
      transform="translate(141,49)"
    />
    <path
      d="M0 0 C-0.98 2.19 -1.78 3.8 -3.56 5.44 C-5.45 7.49 -5.63 9.28 -6 12 C-6.33 12 -6.66 12 -7 12 C-7.16 4.95 -7.16 4.95 -6 2 C-3 0 -3 0 0 0 Z "
      fill="#DADBDD"
      transform="translate(123,77)"
    />
    <path
      d="M0 0 C1.32 0.33 2.64 0.66 4 1 C3.67 4.63 3.34 8.26 3 12 C2.67 12 2.34 12 2 12 C2 9.03 2 6.06 2 3 C0.68 2.67 -0.64 2.34 -2 2 C-1.34 1.34 -0.68 0.68 0 0 Z "
      fill="#F1CACB"
      transform="translate(104,146)"
    />
    <path
      d="M0 0 C2.31 0 4.62 0 7 0 C6.34 1.32 5.68 2.64 5 4 C5 3.34 5 2.68 5 2 C4.01 2.5 4.01 2.5 3 3 C1 3.04 -1 3.04 -3 3 C-2.01 2.67 -1.02 2.34 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#ECAFB1"
      transform="translate(132,146)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2.33 1.65 2.66 3.3 3 5 C1.68 5.33 0.36 5.66 -1 6 C-1 3 -1 3 0 0 Z "
      fill="#BEC0C4"
      transform="translate(141,52)"
    />
    <path
      d="M0 0 C0.33 0.99 0.66 1.98 1 3 C1.66 3.33 2.32 3.66 3 4 C1.02 4.33 -0.96 4.66 -3 5 C-3.33 4.01 -3.66 3.02 -4 2 C-2.68 2 -1.36 2 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#EDB5B6"
      transform="translate(164,154)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1 2.31 1 4.62 1 7 C-0.32 6.67 -1.64 6.34 -3 6 C-1.12 1.12 -1.12 1.12 0 0 Z "
      fill="#E56B6D"
      transform="translate(136,152)"
    />
  </svg>
)
export default FaCup
