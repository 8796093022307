import * as React from 'react'
const SouthSudan = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={49}
    viewBox="0 0 50 49"
    {...props}
  >
    <path
      d="M0,0 L16,0 L26,6 L32,15 L33,18 L33,31 L28,41 L20,47 L16,49 L0,49 L-10,43 L-16,34 L-17,31 L-17,18 L-12,8 L-4,2 Z "
      fill="#2151AA"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L30,0 L31,3 L31,16 L30,19 L0,19 L3,16 L14,10 L9,6 L0,1 Z "
      fill="#E15C61"
      transform="translate(19,15)"
    />
    <path
      d="M0,0 L32,0 L28,7 L19,13 L17,14 L1,14 L-9,8 L-10,6 Z "
      fill="#0C8A30"
      transform="translate(16,35)"
    />
    <path
      d="M0,0 L16,0 L26,6 L30,11 L30,14 L-1,14 L-11,8 L-6,3 Z "
      fill="#070708"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L3,2 L9,2 L7,5 L8,11 L3,11 L0,13 L0,8 L-4,8 L-4,5 L0,5 Z "
      fill="#E8CE2B"
      transform="translate(4,18)"
    />
    <path
      d="M0,0 L7,0 L5,3 L6,9 L0,9 L-1,6 L3,6 L3,2 L0,2 Z "
      fill="#D6C04A"
      transform="translate(6,20)"
    />
    <path
      d="M0,0 L1,2 L4,2 L4,6 L0,6 Z "
      fill="#FDDE04"
      transform="translate(5,20)"
    />
  </svg>
)
export default SouthSudan
