import React from 'react'
const Scotland = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#B8C2C8"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L14,0 L16,1 L16,3 L21,5 L22,7 L16,5 L12,3 L2,3 L-6,7 L-6,10 L6,18 L10,17 L22,9 L24,9 L28,16 L17,24 L15,24 L15,26 L19,27 L27,33 L28,35 L24,40 L20,39 L8,31 L3,33 L-8,41 L-10,41 L-14,34 L-2,25 L-6,21 L-14,16 L-15,20 L-15,30 L-14,38 L-17,35 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#F8F9FC"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L5,2 L16,10 L14,14 L8,17 L-7,17 L-15,12 L-10,7 Z "
      fill="#0767B6"
      transform="translate(24,30)"
    />
    <path
      d="M0,0 L10,0 L18,4 L20,8 L7,17 L3,16 L-9,8 L-9,5 L-3,1 Z "
      fill="#0B68B6"
      transform="translate(20,2)"
    />
    <path
      d="M0,0 L14,0 L16,1 L16,3 L21,5 L22,7 L16,5 L12,3 L2,3 L-6,7 L-12,14 L-15,20 L-15,30 L-14,38 L-17,35 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#C2C8CA"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L5,2 L13,8 L8,13 L1,18 L-2,14 L-2,4 Z "
      fill="#1167B5"
      transform="translate(4,16)"
    />
    <path
      d="M0,0 L3,4 L3,14 L1,17 L-3,15 L-12,10 L-12,8 Z "
      fill="#1267B6"
      transform="translate(45,16)"
    />
  </svg>
)
export default Scotland
