import * as React from 'react'
const TrophyIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="trophy-01" clipPath="url(#clip0_7996_82641)">
      <path
        id="Icon"
        d="M9.99935 12.4998C7.23793 12.4998 4.99935 10.2613 4.99935 7.49984V2.87021C4.99935 2.52533 4.99935 2.3529 5.04961 2.21482C5.13385 1.98335 5.31619 1.80101 5.54767 1.71676C5.68574 1.6665 5.85818 1.6665 6.20305 1.6665H13.7956C14.1405 1.6665 14.313 1.6665 14.451 1.71676C14.6825 1.80101 14.8648 1.98335 14.9491 2.21482C14.9993 2.3529 14.9993 2.52533 14.9993 2.87021V7.49984C14.9993 10.2613 12.7608 12.4998 9.99935 12.4998ZM9.99935 12.4998V14.9998M14.9993 3.33317H17.0827C17.471 3.33317 17.6651 3.33317 17.8183 3.3966C18.0224 3.48118 18.1847 3.64341 18.2692 3.8476C18.3327 4.00074 18.3327 4.19489 18.3327 4.58317V4.99984C18.3327 5.77481 18.3327 6.1623 18.2475 6.48022C18.0163 7.34295 17.3425 8.01682 16.4797 8.24799C16.1618 8.33317 15.7743 8.33317 14.9993 8.33317M4.99935 3.33317H2.91602C2.52773 3.33317 2.33359 3.33317 2.18045 3.3966C1.97626 3.48118 1.81403 3.64341 1.72945 3.8476C1.66602 4.00074 1.66602 4.19489 1.66602 4.58317V4.99984C1.66602 5.77481 1.66602 6.1623 1.7512 6.48022C1.98237 7.34295 2.65624 8.01682 3.51897 8.24799C3.83688 8.33317 4.22437 8.33317 4.99935 8.33317M6.20305 18.3332H13.7956C14.0002 18.3332 14.166 18.1673 14.166 17.9628C14.166 16.3264 12.8395 14.9998 11.2031 14.9998H8.79564C7.15925 14.9998 5.83268 16.3264 5.83268 17.9628C5.83268 18.1673 5.9985 18.3332 6.20305 18.3332Z"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7996_82641">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default TrophyIcon
