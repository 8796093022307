import * as React from 'react'
const Casino = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.28938 5.00233L5.53464 1.96289C4.1734 2.86209 3.00467 4.03082 2.10547 5.39207L5.14491 7.1468C5.72622 6.31099 6.45357 5.58364 7.28938 5.00233Z"
      fill="white"
    />
    <path
      d="M11.4391 3.51041V0C9.76283 0.0971983 8.17807 0.539521 6.75391 1.25653L8.50911 4.29598C9.41066 3.86868 10.3986 3.5954 11.4391 3.51041Z"
      fill="white"
    />
    <path
      d="M4.43856 8.36653L1.39911 6.61133C0.682099 8.03549 0.239776 9.62025 0.142578 11.2966H3.65299C3.73798 10.256 4.01126 9.26808 4.43856 8.36653Z"
      fill="white"
    />
    <path
      d="M3.65299 12.7051H0.142578C0.239776 14.3814 0.682099 15.9662 1.39911 17.3903L4.43856 15.6351C4.01126 14.7336 3.73798 13.7456 3.65299 12.7051Z"
      fill="white"
    />
    <path
      d="M20.6313 11.2966H24.1417C24.0445 9.62025 23.6022 8.03549 22.8851 6.61133L19.8457 8.36653C20.273 9.26808 20.5463 10.256 20.6313 11.2966Z"
      fill="white"
    />
    <path
      d="M17.5329 1.25653C16.1087 0.539521 14.524 0.0971983 12.8477 0V3.51041C13.8882 3.5954 14.8761 3.86868 15.7777 4.29598L17.5329 1.25653Z"
      fill="white"
    />
    <path
      d="M19.1406 7.1468L22.18 5.39207C21.2808 4.03082 20.1121 2.86209 18.7508 1.96289L16.9961 5.00233C17.8319 5.58364 18.5592 6.31099 19.1406 7.1468Z"
      fill="white"
    />
    <path
      d="M12.8477 20.4887V23.9991C14.524 23.9019 16.1087 23.4596 17.5329 22.7426L15.7777 19.7031C14.8761 20.1304 13.8882 20.4037 12.8477 20.4887Z"
      fill="white"
    />
    <path
      d="M16.9961 18.998L18.7508 22.0374C20.1121 21.1382 21.2808 19.9695 22.18 18.6083L19.1406 16.8535C18.5592 17.6893 17.8319 18.4167 16.9961 18.998Z"
      fill="white"
    />
    <path
      d="M19.8457 15.6351L22.8851 17.3903C23.6022 15.9662 24.0445 14.3814 24.1417 12.7051H20.6313C20.5463 13.7456 20.273 14.7336 19.8457 15.6351Z"
      fill="white"
    />
    <path
      d="M5.14491 16.8535L2.10547 18.6083C3.00467 19.9695 4.1734 21.1382 5.53464 22.0374L7.28938 18.998C6.45357 18.4167 5.72622 17.6893 5.14491 16.8535Z"
      fill="white"
    />
    <path
      d="M6.75391 22.7426C8.17807 23.4596 9.76283 23.9019 11.4391 23.9991V20.4887C10.3986 20.4037 9.41066 20.1304 8.50911 19.7031L6.75391 22.7426Z"
      fill="white"
    />
    <path
      d="M15.0692 18.4791C17.8212 17.2453 19.5343 14.3082 19.2173 11.2958C18.7268 6.46199 13.64 3.52294 9.21475 5.52119C5.39739 7.24545 3.8773 11.9279 5.98561 15.5542C7.79274 18.7019 11.7647 19.9916 15.0692 18.4791ZM9.9651 12.7045H7.75959V11.2958H9.9651C10.1905 10.5994 10.7413 10.0487 11.4376 9.82327V7.61776H12.8463V9.82327C13.5427 10.0487 14.0934 10.5994 14.3188 11.2958H16.5243V12.7045H14.3188C14.0934 13.4008 13.5427 13.9516 12.8463 14.177V16.3825H11.4376V14.177C10.7413 13.9516 10.1905 13.4008 9.9651 12.7045Z"
      fill="white"
    />
    <path
      d="M11.6168 12.7052C12.1878 13.1369 13.0245 12.7227 13.0226 12.0009C13.0227 11.2776 12.1901 10.8655 11.6168 11.2966C11.1486 11.6385 11.1486 12.3633 11.6168 12.7052Z"
      fill="white"
    />
  </svg>
)
export default Casino
