import React from 'react'
const Slovakia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#BCC1C8"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L9,0 L12,2 L16,1 L18,0 L42,0 L40,5 L35,10 L31,13 L26,15 L16,15 L8,11 L1,4 Z "
      fill="#EB1D23"
      transform="translate(4,33)"
    />
    <path
      d="M0,0 L10,0 L19,5 L26,13 L26,14 L6,14 L5,13 L-11,13 L-16,14 L-13,9 L-5,2 Z "
      fill="#FCFDFD"
      transform="translate(20,2)"
    />
    <path
      d="M0,0 L20,0 L22,3 L22,13 L20,16 L-3,16 L-1,12 Z "
      fill="#0659AC"
      transform="translate(26,17)"
    />
    <path
      d="M0,0 L16,0 L16,14 L12,15 L10,15 L9,10 L13,10 L13,8 L9,8 L9,6 L12,6 L12,4 L9,4 L9,2 L7,2 L7,4 L4,4 L4,6 L7,6 L7,8 L3,8 L3,10 L7,10 L6,15 L1,15 L0,13 Z "
      fill="#EC1C1F"
      transform="translate(9,14)"
    />
    <path
      d="M0,0 L2,0 L2,2 L5,2 L5,4 L2,4 L2,6 L6,6 L6,8 L2,8 L3,13 L7,12 L8,16 L3,20 L-2,19 L-5,15 L-5,12 L-1,12 L0,8 L-4,8 L-4,6 L0,6 L0,4 L-3,4 L-3,2 L0,2 Z "
      fill="#1A5CB1"
      transform="translate(16,16)"
    />
    <path
      d="M0,0 L2,2 L2,18 L-4,26 L-10,31 L-15,33 L-29,33 L-29,31 L-35,29 L-36,27 L-27,30 L-17,30 L-9,26 L-4,21 L0,13 Z "
      fill="#B8D2D6"
      transform="translate(47,17)"
    />
    <path
      d="M0,0 L4,0 L5,11 L7,16 L0,16 L-2,13 L-2,3 Z "
      fill="#115AAF"
      transform="translate(4,17)"
    />
    <path
      d="M0,0 L2,0 L2,2 L5,2 L5,4 L2,4 L2,6 L6,6 L6,8 L2,8 L2,11 L0,11 L0,8 L-4,8 L-4,6 L0,6 L0,4 L-3,4 L-3,2 L0,2 Z "
      fill="#F9D7D7"
      transform="translate(16,16)"
    />
    <path
      d="M0,0 L0,3 L-4,6 L-7,5 L-5,3 L-5,1 Z "
      fill="#869AC4"
      transform="translate(23,30)"
    />
  </svg>
)
export default Slovakia
