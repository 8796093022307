import React from 'react'
const Valorant = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={157}
    viewBox="0 0 200 157"
    {...props}
  >
    <path
      d="M0,0 L200,0 L200,157 L0,157 Z "
      fill="#FEFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L12,0 L13,7 L9,4 L9,78 L19,90 L30,104 L43,120 L51,130 L62,144 L68,151 L127,152 L123,146 L111,131 L104,122 L103,117 L101,115 L104,112 L112,112 L112,105 L115,104 L170,103 L172,104 L174,100 L176,94 L182,91 L184,91 L183,88 L187,88 L189,82 L192,83 L193,88 L200,88 L200,157 L0,157 Z "
      fill="#FEFEFE"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L6,5 L17,19 L30,35 L41,49 L54,65 L61,74 L74,90 L85,104 L98,120 L109,134 L119,147 L119,149 L60,149 L51,139 L41,126 L32,115 L21,101 L12,90 L1,76 L0,75 Z "
      fill="#FC4756"
      transform="translate(8,3)"
    />
    <path
      d="M0,0 L2,1 L2,73 L-6,84 L-18,98 L-19,99 L-78,99 L-76,94 L-67,83 L-53,66 L-46,57 L-35,43 L-21,26 L-8,9 Z "
      fill="#FD4655"
      transform="translate(188,3)"
    />
    <path
      d="M0,0 L2,3 L1,7 L-2,4 L-2,78 L-1,80 L-4,80 L-6,76 L-6,2 Z "
      fill="#FDDEE0"
      transform="translate(11,0)"
    />
    <path
      d="M0,0 L1,2 L-1,5 L-1,11 L50,12 L50,7 L53,5 L53,3 L58,4 L52,12 L50,14 L-9,14 L-7,9 Z "
      fill="#E85863"
      transform="translate(119,88)"
    />
    <path
      d="M0,0 L2,1 L2,73 L1,73 L0,29 L0,4 L-3,7 L-7,18 L-12,18 L-15,22 L-17,21 L-7,8 Z "
      fill="#F2747F"
      transform="translate(188,3)"
    />
    <path
      d="M0,0 L3,2 L62,3 L61,0 L63,0 L63,5 L3,6 L0,2 Z "
      fill="#F9CDD2"
      transform="translate(65,149)"
    />
    <path
      d="M0,0 L5,5 L16,19 L16,21 L-43,21 L-43,20 L14,20 L6,10 L3,9 Z "
      fill="#DF5864"
      transform="translate(111,131)"
    />
    <path
      d="M0,0 L0,3 L-8,13 L-10,18 L-13,19 L-17,25 L-19,25 L-17,21 L-3,4 Z "
      fill="#E3606B"
      transform="translate(170,25)"
    />
    <path
      d="M0,0 L1,2 L-4,9 L-5,15 L-10,16 L-11,18 L-13,17 Z "
      fill="#E65C67"
      transform="translate(140,62)"
    />
    <path
      d="M0,0 L1,4 L1,6 L-4,8 L-5,12 L-9,11 Z "
      fill="#E95A66"
      transform="translate(150,50)"
    />
    <path
      d="M0,0 L4,1 L4,3 L8,4 L10,12 L5,7 Z "
      fill="#E85862"
      transform="translate(44,122)"
    />
    <path
      d="M0,0 L2,0 L2,2 L6,2 L7,7 L9,7 L8,11 L0,1 Z "
      fill="#E85A64"
      transform="translate(54,134)"
    />
    <path
      d="M0,0 L5,5 L8,10 L2,8 L0,2 Z "
      fill="#FEE4E6"
      transform="translate(25,99)"
    />
    <path
      d="M0,0 L5,5 L9,10 L4,9 L4,7 L2,7 L1,3 Z "
      fill="#E85B66"
      transform="translate(102,120)"
    />
    <path
      d="M0,0 L5,5 L8,10 L2,8 Z "
      fill="#FDE2E7"
      transform="translate(17,89)"
    />
  </svg>
)
export default Valorant
