import * as React from 'react'
const EersteDivisie = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    width={150}
    height={150}
    {...props}
  >
    <path
      d="M35 3h80l7 4 5 6 5 14 18 55v10l-4 8-9 8-17 12-18 13-18 13-3 1H69l-9-5-16-12-18-13-19-14-5-6-2-5v-9l20-62 4-10 7-6Z"
      fill="#DB0F23"
    />
    <path
      d="M70 73h10l7 6v6h53v7l-10 9-17 12-19 14-16 11h-6l-13-9-18-13-18-13-10-8-3-3v-7h53l1-7Z"
      fill="#FBF4F4"
    />
    <path
      d="M35 3h80l7 4 5 6 5 14 18 55v10l-4 8-9 8-17 12-18 13-18 13-3 1H69l-9-5-16-12-18-13-19-14-5-6-2-5v-9l20-62 4-10 7-6Zm0 5-8 7-6 18-5 16-3 8-2 9-6 16v11l7 8 17 12 19 14 18 13 3 2h11l11-7 19-14 15-11 14-10 5-5 1-10-3-12-4-11-4-13-5-15-6-19-7-6-2-1Z"
      fill="#FDF3F3"
    />
    <path
      d="m8 96 10 2 13 10 14 10 18 13 9 6h6l13-9 18-13 12-9h2l1 5-18 13-19 14-7 4H69l-14-10-18-13-16-12-11-8Z"
      fill="#DB1120"
    />
    <path
      d="M76 26h1v17h5l1-4 1 4h5l-4-8 5-5 2 1-2 3v6l4 3 3 2-7-1-5 1-1 4 4 1h-4v12h-1l-1-10-2-2-9-1v17h-2v-3l-4-1h4l-1-11-5-1h-3l-9-1v13h-2l-1-12h-9v3l5-1 1 2-7 2-1 4-4-6 1-4 5-7h4v-6l3 1 1 4h4l-3-6v-3l3-1 3 10h7l1-2h-6v-2h7l-3-7-5-1v-1h6l3 2 1 12 7-1 4 1Z"
      fill="#DB0C19"
    />
    <path
      d="M75 77h5v5l3 1-1 7-3 3h-5v-4l-4-1v3h-2l-2-4v-5l5-4Z"
      fill="#F6F6F6"
    />
    <path d="M51 49h12l5 1 1 1v11h-4v-9h-3v9h-4v-9h-3v9h-4Z" fill="#F7D8DA" />
    <path d="M71 49h9l3 3v6l-3 4h-4l-1 4h-4Z" fill="#F8DADC" />
    <path
      d="M25 46h4l1 3 3-3 4 1-3 4 1 4 3 5v2l-5-1-2-4-2-1v6h-4Z"
      fill="#F9DFE1"
    />
    <path d="m94 49 6 1 2 2v7l-3 3h-6l-3-4v-6Z" fill="#F9DFE0" />
    <path d="M77 26h4l1 8 2-3h4l-2 5 3 5v2h-5l-1-3-1 3h-5Z" fill="#F8D9DB" />
    <path
      d="M39 27h4l1 3 3-3h3l-2 5 1 4 2 6h-4l-2-4h-2l-1 4h-3Z"
      fill="#FDF2F3"
    />
    <path d="m41 49 7 1 1 2v10h-9l-2-2v-4l6-3h-5v-3Z" fill="#FBEBEB" />
    <path d="M71 30h4v13H65l-1-2V31h4v8h3Z" fill="#F6D2D3" />
    <path d="M55 30h5l3 4v4h-7v2h6l-1 3h-7l-2-3v-7Z" fill="#FAE6E7" />
    <path d="m107 30 5 1 1 1v11h-4v-9h-3v9h-4V31Z" fill="#F8D8DA" />
    <path d="m121 49 5 1 1 1v11h-4v-9h-3v9h-4V50Z" fill="#F1ABB0" />
    <path d="M92 30h5l3 3v5l-6 1 5 1-1 3h-6l-3-3v-6Z" fill="#F9DCDE" />
    <path d="m107 49 5 1 2 2v5l-6 1 5 1-1 3h-6l-3-3v-6Z" fill="#FAE5E5" />
    <path d="M71 49h3v2l3 1-1 1-1 7 2 1h-2v5h-4Z" fill="#FAE5E6" />
    <path d="m49 98 3 1-2 10h-6l-1-1v-6l2-2h4Z" fill="#DA030B" />
    <path d="M101 101h6l1 5-2 1v2h-6v-7Z" fill="#DA0C13" />
    <path d="m75 77 4 1-3 2v7l-3 1-2-1-1-8Z" fill="#111" />
    <path d="m94 49 4 1-2 1 2 2v5l-3 1-2-1-1-4-2 4v-6Z" fill="#F5C7CA" />
    <path d="M63 100h3l1 3 1-2 3 1-3 7h-5Z" fill="#DB171D" />
    <path d="M68 80h2l1 7 4 2-5-1v3h-2l-2-4v-5Z" fill="#D0D0D0" />
    <path d="M82 101h7v2l-2 1 1 4-1 1h-6v-2l2-1Z" fill="#DE2B34" />
    <path d="m77 82 6 1-1 7-3 3h-5v-2l5-1 1-6-3-1Z" fill="#5A5A5A" />
    <path d="M85 50h3v12h-3Z" fill="#FDF6F6" />
    <path d="M116 50h3v12h-3Z" fill="#FCEFF1" />
    <path d="m42 27 2 3 3-3h3l-2 5-1 2h-5Z" fill="#F6D3D4" />
    <path d="m122 51 4 1v10h-3Z" fill="#FCEEEF" />
    <path d="m107 49 5 1-2 1 1 4h-5l-2-4Z" fill="#F2B3B5" />
    <path d="M72 31h3v11h-2l-1-2Z" fill="#FEFAFA" />
    <path d="m41 54 4 1 1 4h-5l-1-1-2 2v-4Z" fill="#EFA3A6" />
    <path d="m45 100 4 1-1 6h-3v-3h-2Z" fill="#E8777D" />
    <path d="m41 49 7 1-1 2-5 1h-3v-3Z" fill="#F6D1D3" />
    <path d="M92 30h5l-3 2 3 2v2h-5l-2-4Z" fill="#EE9EA1" />
    <path d="M55 30h5l1 2-2 1 1 3h-5l1-3-2-2Z" fill="#EFA2A5" />
    <path d="M63 100h3l1 3 1-2 3 1-2 6-1-2h-3v-5Z" fill="#E24B53" />
    <path d="m75 101 3 1-1 7h-3v-6Z" fill="#DD262D" />
    <path d="M52 50h2l1 3-1 8h-2Z" fill="#FEFDFD" />
    <path d="M75 53h4v5h-4Z" fill="#DD232E" />
    <path d="M94 53h4v5h-4Z" fill="#DC1C2B" />
    <path d="M65 51h3v10h-2Z" fill="#FEFEFE" />
    <path d="M92 53h1l1 7 4 1h-6l-1-1v-6Z" fill="#FDF8F8" />
    <path d="m93 101 3 1-1 7h-3Z" fill="#DB0C0F" />
    <path d="M78 77h2l-1 5-4 2 1-5Z" fill="#CFCECE" />
    <path d="M57 40h5l-1 3h-7l-1-2 4 1Z" fill="#F5C5C8" />
    <path d="m86 38 3 3v2h-5v-4Z" fill="#F4C1C4" />
    <path d="M84 31h4l-2 5-1 2-2-6Z" fill="#F7D6D7" />
    <path d="M93 31h5l1 1v5h-2l-1-3Z" fill="#FDF5F6" />
    <path d="m56 49 5 1-3 1v2l-4-1v-2Z" fill="#F6CCCD" />
    <path d="m85 44 4 1v3h-5Z" fill="#F3BCBF" />
    <path d="M103 102h2v3h2l1-3v4h-6Z" fill="#E87E84" />
  </svg>
)
export default EersteDivisie
