import * as React from 'react'
const BestOddsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1216 332"
    width={1216}
    height={332}
    {...props}
  >
    <path
      d="M0,0 L1216,0 L1216,332 L0,332 Z "
      fill="#070808"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L2,0 L2,109 L-342,109 L-337,104 L-328,100 L-325,98 L-319,96 L-314,95 L-318,89 L-317,85 L-301,80 L-281,75 L-277,75 L-277,77 L-270,76 L-270,78 L-265,78 L-265,79 L-260,80 L-261,82 L-263,82 L-263,84 L-267,84 L-267,86 L-257,86 L-251,84 L-249,84 L-249,86 L-244,84 L-240,81 L-235,79 L-224,77 L-217,76 L-213,73 L-204,70 L-195,66 L-190,66 L-188,66 L-184,63 L-179,62 L-177,63 L-176,57 L-170,55 L-164,54 L-163,51 L-159,52 L-160,55 L-158,55 L-158,52 L-151,50 L-148,49 L-146,50 L-146,48 L-144,48 L-142,50 L-139,47 L-131,45 L-124,42 L-123,40 L-117,39 L-117,41 L-113,39 L-106,39 L-102,37 L-99,37 L-99,35 L-101,34 L-100,33 L-94,33 L-94,34 L-89,34 L-83,31 L-73,28 L-64,24 L-60,25 L-65,28 L-67,30 L-68,33 L-77,35 L-81,36 L-81,38 L-76,36 L-73,37 L-74,38 L-65,37 L-65,42 L-58,39 L-51,38 L-50,34 L-53,34 L-54,36 L-58,36 L-58,34 L-54,32 L-53,30 L-48,31 L-49,35 L-44,33 L-41,33 L-41,31 L-38,29 L-33,29 L-33,27 L-31,27 L-30,25 L-27,24 L-25,20 L-15,16 L-12,14 L-4,12 L-4,10 L-2,10 L-2,7 L-10,8 L-10,10 L-18,11 L-18,7 L-11,5 L-11,3 Z "
      fill="#027002"
      transform="translate(1214,223)"
    />
    <path
      d="M0,0 L188,0 L187,4 L184,4 L182,6 L174,7 L175,11 L173,18 L160,22 L156,23 L155,25 L148,28 L142,28 L142,30 L133,31 L132,33 L130,33 L130,35 L124,37 L114,37 L102,41 L100,43 L90,44 L84,48 L74,50 L73,51 L68,51 L69,45 L72,45 L72,43 L67,46 L61,48 L61,46 L56,47 L50,50 L37,54 L25,58 L17,59 L18,57 L28,53 L30,53 L30,51 L25,52 L23,51 L23,54 L20,56 L13,57 L11,59 L4,59 L4,61 L2,61 L2,56 L2,55 L0,55 Z "
      fill="#023C03"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L110,0 L110,1 L105,1 L104,4 L100,6 L93,8 L81,12 L69,15 L60,19 L58,19 L57,21 L69,16 L82,15 L80,17 L71,20 L69,22 L74,22 L77,21 L76,25 L72,27 L67,27 L66,33 L61,38 L60,41 L56,43 L51,42 L48,45 L39,48 L40,45 L49,41 L50,38 L48,38 L47,41 L42,43 L42,39 L44,39 L44,37 L30,42 L23,44 L17,46 L15,45 L15,53 L10,53 L9,51 L7,53 L4,52 L3,51 L0,51 Z "
      fill="#007B00"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L5,0 L6,2 L3,2 L3,4 L0,5 L-1,7 L-4,8 L-11,8 L-12,11 L-15,11 L-15,14 L-12,16 L-12,14 L-9,15 L-11,18 L-14,18 L-14,21 L-9,20 L-8,22 L-5,18 L-2,19 L3,14 L5,14 L7,10 L9,10 L8,14 L12,11 L29,11 L28,15 L20,14 L20,16 L18,17 L34,16 L33,18 L24,20 L23,24 L18,25 L18,27 L27,26 L26,28 L17,30 L-45,30 L-40,25 L-31,21 L-28,19 L-22,17 L-17,16 L-21,10 L-20,6 L-4,1 Z "
      fill="#026302"
      transform="translate(917,302)"
    />
    <path
      d="M0,0 L6,0 L5,3 L12,2 L11,6 L8,6 L8,8 L4,10 L9,12 L9,14 L1,14 L1,16 L6,18 L7,20 L0,25 L-13,28 L-34,29 L-34,27 L-30,26 L-31,24 L-30,21 L-37,22 L-39,22 L-39,20 L-35,18 L-28,18 L-28,14 L-33,14 L-29,11 L-18,8 L-11,5 L0,2 Z "
      fill="#005E01"
      transform="translate(150,0)"
    />
    <path
      d="M0,0 L2,0 L2,33 L-2,38 L-4,38 L-6,44 L-11,49 L-14,50 L-16,56 L-18,56 L-18,59 L-21,59 L-23,63 L-26,64 L-26,62 L-22,58 L-20,50 L-17,49 L-15,43 L-11,37 L-15,39 L-13,35 L-11,35 L-9,31 L-7,28 L-7,24 L-13,25 L-15,24 L-15,27 L-19,27 L-19,29 L-23,30 L-24,26 L-27,26 L-25,20 L-15,16 L-12,14 L-4,12 L-4,10 L-2,10 L-2,7 L-10,8 L-10,10 L-18,11 L-18,7 L-11,5 L-11,3 Z "
      fill="#024D02"
      transform="translate(1214,223)"
    />
    <path
      d="M0,0 L1,0 L1,10 L-3,15 L-5,15 L-7,21 L-12,26 L-15,27 L-17,33 L-19,33 L-19,36 L-22,36 L-24,40 L-27,41 L-27,39 L-23,35 L-21,27 L-18,26 L-16,20 L-9,11 L-8,8 L-4,7 L-2,2 L0,2 Z "
      fill="#033504"
      transform="translate(1215,246)"
    />
    <path
      d="M0,0 L1,0 L1,11 L-10,11 L-5,7 L-1,2 Z "
      fill="#000000"
      transform="translate(1215,321)"
    />
    <path
      d="M0,0 L11,0 L6,4 L2,9 L0,11 Z "
      fill="#000000"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L5,1 L5,4 L8,5 L6,9 L3,12 L1,12 L2,7 L4,7 L4,4 L0,3 Z "
      fill="#18191A"
      transform="translate(563,43)"
    />
    <path
      d="M0,0 L8,0 L5,3 L-1,5 L-7,5 L-2,1 Z "
      fill="#022F03"
      transform="translate(33,43)"
    />
    <path
      d="M0,0 L3,1 L2,4 L-1,4 L-1,7 L-3,7 L-5,4 L-8,3 L-8,1 Z "
      fill="#171819"
      transform="translate(460,64)"
    />
  </svg>
)
export default BestOddsIcon
