import * as React from 'react'
const BoxingRed = (props) => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    className="iconify iconify--emojione"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g fill="#3e4347">
      <path d="M15.6 59s7.3-3.8 14.9-2.3c7.1 1.4 7.3-3 11.1-3s3.2-3.4 6.1-3.2c0 0 1.3.1 1.3 1.4c0 0-2.4.1-3.4 2s-6.6 1.7-8.8 3.9c-2.7 2.7-10.3.9-13.5 1.2c-2.5.3-5.5 3-9.8 3l2.1-3" />
      <path d="M27.3 53.6s4.6-.2 7.3 1.8c2.8 2 4.8 4.5 8.3 3.3c3.5-1.2 6.8-2.1 10.6-.9c0 0 .5-.7.4-1.5c0 0-2.4-1.4-5.2-.8s-5.6 2.2-7.4 1.6s-5.6-4.7-11.7-4.7l-2.3 1.2" />
      <path d="M55.8 57.7c.1-.2.1-.4 0-.4l-3.3-1.2l-.3.7l3.3 1.2s.2-.1.3-.3" />
    </g>
    <path
      d="M14.3 37.6S8.9 19.3 27.4 13.3c16.4-5.2 11 10.1-13.1 24.3"
      fill="#c94747"
    />
    <path
      d="M27.4 10.6C18.8 32.8 12.9 32.2 16.9 40l1.5 19.8s2.7-1.6 9.1-5.3c4.8-2.8 14.3-3.5 20.7-7.3C59.5 40.6 66 30.5 59.5 19.1C53 7.8 34.6-8.1 27.4 10.6"
      fill="#ed4c5c"
    />
    <path
      d="M24.4 42.8c-3.2-5.6-8.2-8.7-11.2-7l-9.1 5.3l11.5 20.4l9.1-5.3c3-1.8 2.9-7.8-.3-13.4"
      fill="#c94747"
    />
    <path
      d="M15.4 48.1c3.2 5.6 3.3 11.6.3 13.4c-3 1.8-8-1.4-11.2-7c-3.2-5.6-3.3-11.6-.3-13.4c3-1.7 8 1.4 11.2 7"
      fill="#3e4347"
    />
    <path
      d="M13.6 49.1c2.3 4.1 2.5 8.4.5 9.6c-2 1.2-5.6-1.1-7.9-5.2c-2.3-4.1-2.5-8.4-.5-9.6c2.1-1.2 5.6 1.2 7.9 5.2"
      fill="#ed4c5c"
    />
    <path
      d="M13.6 49.1c-1.9-3.4-4.7-5.6-6.8-5.5c-.4 1.9.2 4.8 1.8 7.6c1.9 3.4 4.7 5.6 6.8 5.5c.4-1.9-.2-4.8-1.8-7.6"
      fill="#c94747"
    />
    <path
      d="M38.7 6c3-1.8 12.6 5.4 17 13.1c4.4 7.7-2.5 9.2-6.9 1.5c-4.3-7.8-15-11.8-10.1-14.6"
      fill="#ff717f"
    />
    <path
      d="M26.2 13.8s-2 9.4-8.9 17.1c0 0 4.4-7 6-15.9l2.9-1.2"
      fill="#3e4347"
    />
    <path
      d="M30 9.9c3.4-1 .1-3.8 2-6c-1.8 1.4-4.1 3.4-5 6.7c-4.6 17.5-11.3 19.2-11.4 24.8c1.2-.8 3.8-3.3 6.8-2.5c4.3 1.2-4.9-4.5.2-3.8c5.2.6-2.1-3.7 2.6-4c4.7-.3-4-5 2.1-4.9c6.1.1-3.5-4.1 3.5-4.5c7-.6-5-4.6-.8-5.8"
      fill="#c94747"
    />
    <path
      d="M9 39.9c2.7 1.2 5.5 3.9 7.6 7.5c2.1 3.7 2.9 7.4 2.6 10.4l4.9-2.9c2-1.2 2.2-6.2-.8-11.5c-2.5-4.4-6-6.8-8.1-6.8c-.5 0-.8.1-1.2.3l-5 3"
      fill="#ffffff"
    />
    <path
      d="M51 50.7c0-.2-.1-.4-.2-.4l-3.6.7l.1.8l3.5-.6c.1-.1.2-.3.2-.5"
      fill="#3e4347"
    />
  </svg>
)
export default BoxingRed
