import * as React from 'react'
const Israel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L12,0 L20,4 L27,12 L29,17 L29,29 L25,37 L17,44 L12,46 L0,46 L-8,42 L-15,34 L-17,29 L-17,17 L-13,9 L-5,2 Z "
      fill="#E6E7EF"
      transform="translate(19,2)"
    />
    <path
      d="M0,0 L19,0 L20,4 L20,18 L18,22 L-1,22 L1,16 L7,16 L5,13 L6,8 L7,6 L1,6 Z "
      fill="#FBFBFC"
      transform="translate(26,14)"
    />
    <path
      d="M0,0 L40,0 L34,7 L26,11 L14,11 L6,7 Z "
      fill="#062DB3"
      transform="translate(5,37)"
    />
    <path
      d="M0,0 L12,0 L20,4 L26,11 L-14,11 L-8,4 Z "
      fill="#0128B5"
      transform="translate(19,2)"
    />
    <path
      d="M0,0 L2,0 L3,4 L9,4 L8,11 L9,14 L3,14 L2,18 L0,18 L-1,14 L-7,14 L-6,7 L-7,4 L-1,4 Z "
      fill="#5060C5"
      transform="translate(24,16)"
    />
    <path
      d="M0,0 L24,0 L23,3 L18,5 L6,5 L0,2 Z "
      fill="#D1D3DF"
      transform="translate(13,43)"
    />
    <path
      d="M0,0 L12,0 L18,3 L18,5 L-6,5 L-5,2 Z "
      fill="#D0D2DD"
      transform="translate(19,2)"
    />
    <path
      d="M0,0 L3,0 L5,7 L-1,7 L-2,11 L-4,11 L-5,7 L-11,7 L-10,4 L-9,6 L-7,6 L-6,4 L-1,5 Z "
      fill="#4153C1"
      transform="translate(28,23)"
    />
    <path
      d="M0,0 L5,1 L6,5 L4,8 L-1,7 L-2,3 Z "
      fill="#E8EAF4"
      transform="translate(23,21)"
    />
    <path
      d="M0,0 L4,2 L7,6 L0,4 Z "
      fill="#2245A8"
      transform="translate(37,6)"
    />
    <path
      d="M0,0 L2,1 L-1,6 L-5,5 Z "
      fill="#2446A6"
      transform="translate(11,6)"
    />
  </svg>
)
export default Israel
