import React from 'react'
const Ukraine = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#FDE903"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L10,0 L18,4 L25,11 L28,18 L28,23 L-18,23 L-17,15 L-13,9 L-5,2 Z "
      fill="#0558AB"
      transform="translate(20,2)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M2,2 L-6,6 L-13,13 L-16,20 L-16,25 L30,25 L29,18 L26,12 L19,5 L12,2 Z M-15,26 L-14,35 L-7,43 L-3,46 L2,48 L12,48 L20,44 L25,39 L28,34 L29,32 L29,26 Z "
      fill="#BAC4D0"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,29 L29,30 L29,26 L-15,26 L-16,30 L-16,25 L30,25 L27,15 L20,7 L14,4 L13,2 L-2,2 Z "
      fill="#BBC79E"
      transform="translate(18,0)"
    />
  </svg>
)
export default Ukraine
