import * as React from 'react'
const Ranking = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.14258 14.5H6.81258C7.08644 14.5 7.31258 14.7261 7.31258 15V21C7.31258 21.2739 7.08644 21.5 6.81258 21.5H3.14258C2.86872 21.5 2.64258 21.2739 2.64258 21V16C2.64258 15.1761 3.31872 14.5 4.14258 14.5Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M10.8027 10.5H13.4727C14.305 10.5 14.9727 11.1745 14.9727 12V21C14.9727 21.2739 14.7466 21.5 14.4727 21.5H9.80273C9.52888 21.5 9.30273 21.2739 9.30273 21V12C9.30273 11.1761 9.97888 10.5 10.8027 10.5Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M17.4727 17.5H20.1427C20.9665 17.5 21.6427 18.1761 21.6427 19V21C21.6427 21.2739 21.4165 21.5 21.1427 21.5H17.4727C17.1988 21.5 16.9727 21.2739 16.9727 21V18C16.9727 17.7261 17.1988 17.5 17.4727 17.5Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M15.152 4.85047C15.462 4.54047 15.582 4.17047 15.482 3.85047C15.382 3.53047 15.072 3.30047 14.632 3.23047L13.672 3.07047C13.632 3.07047 13.542 3.00047 13.522 2.96047L12.992 1.90047C12.592 1.09047 11.682 1.09047 11.282 1.90047L10.752 2.96047C10.742 3.00047 10.652 3.07047 10.612 3.07047L9.65203 3.23047C9.21203 3.30047 8.91203 3.53047 8.80203 3.85047C8.70203 4.17047 8.82203 4.54047 9.13203 4.85047L9.87203 5.60047C9.91203 5.63047 9.94203 5.75047 9.93203 5.79047L9.72203 6.71047C9.56203 7.40047 9.82203 7.71047 9.99203 7.83047C10.162 7.95047 10.532 8.11047 11.142 7.75047L12.042 7.22047C12.082 7.19047 12.212 7.19047 12.252 7.22047L13.142 7.75047C13.422 7.92047 13.652 7.97047 13.832 7.97047C14.042 7.97047 14.192 7.89047 14.282 7.83047C14.452 7.71047 14.712 7.40047 14.552 6.71047L14.342 5.79047C14.332 5.74047 14.362 5.63047 14.402 5.60047L15.152 4.85047Z"
      fill="white"
    />
  </svg>
)
export default Ranking
