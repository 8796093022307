import React from 'react'
const JapanLeague = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={180}
    viewBox="0 0 150 180"
    {...props}
  >
    <path
      d="M0 0 C49.5 0 99 0 150 0 C150 59.4 150 118.8 150 180 C100.5 180 51 180 0 180 C0 120.6 0 61.2 0 0 Z "
      fill="#FEFEFE"
      transform="translate(0,0)"
    />
    <path
      d="M0 0 C16.17 0 32.34 0 49 0 C49.08 7.33 49.16 14.66 49.21 21.99 C49.23 25.41 49.26 28.82 49.3 32.24 C49.35 36.17 49.37 40.11 49.39 44.04 C49.41 45.24 49.43 46.45 49.45 47.68 C49.46 61.44 45.69 72.58 36.38 82.77 C27.62 91.05 16.69 93.4 5.09 93.21 C-6.08 92.8 -14.63 87.79 -22.25 79.81 C-29.34 71.46 -32.19 62.51 -32.06 51.75 C-32.04 49.19 -32.02 46.63 -32 44 C-21.77 44 -11.54 44 -1 44 C-1.99 46.47 -1.99 46.47 -3 49 C-3.45 54.02 -3.14 57 0 61 C3.02 64.19 4.53 64.94 8.94 65.5 C13.17 65.28 15.74 63.72 19 61 C21.97 56.54 21.63 52.23 21 47 C18.98 43.46 16.92 41.23 13 40 C7.21 39.46 5.54 40.23 0 43 C0 28.81 0 14.62 0 0 Z "
      fill="#1C1815"
      transform="translate(63,30)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.34 1.6 0.68 2.2 0 2.81 C-2.34 4.88 -2.34 4.88 -2 8 C-1.34 8 -0.68 8 0 8 C0.66 6.68 1.32 5.36 2 4 C3.98 4.99 5.96 5.98 8 7 C8 10.3 8 13.6 8 17 C8.99 17 9.98 17 11 17 C11.33 14.69 11.66 12.38 12 10 C11.34 10 10.68 10 10 10 C10 9.01 10 8.02 10 7 C15.28 7 20.56 7 26 7 C26 8.32 26 9.64 26 11 C24.35 10.67 22.7 10.34 21 10 C21 10.66 21 11.32 21 12 C22.32 12.33 23.64 12.66 25 13 C24.67 13.99 24.34 14.98 24 16 C24.99 16 25.98 16 27 16 C27 17.65 27 19.3 27 21 C25.23 21.56 23.46 22.1 21.69 22.62 C20.7 22.93 19.72 23.23 18.7 23.54 C16 24 16 24 13 22 C11.02 21.3 11.02 21.3 8.94 20.81 C7.9 20.54 7.9 20.54 6.84 20.27 C4.91 19.81 4.91 19.81 3 21 C3 20.01 3 19.02 3 18 C2.16 18.5 2.16 18.5 1.31 19 C-1.43 20.19 -3.04 20.44 -6 20 C-8.25 18.44 -8.25 18.44 -10 16 C-10.39 13.68 -10.73 11.34 -11 9 C-11.66 8.67 -12.32 8.34 -13 8 C-12.69 9.44 -12.38 10.88 -12.06 12.31 C-11.89 13.11 -11.71 13.91 -11.54 14.74 C-11 17.15 -11 17.15 -10 20 C-11.65 20 -13.3 20 -15 20 C-15 17.03 -15 14.06 -15 11 C-15.66 11 -16.32 11 -17 11 C-17.33 14.3 -17.66 17.6 -18 21 C-23.18 22.04 -27.63 21.83 -32.87 21.26 C-38.57 20.79 -44.28 20.92 -50 21 C-50 20.34 -50 19.68 -50 19 C-51.65 19.33 -53.3 19.66 -55 20 C-55 19.34 -55 18.68 -55 18 C-54.34 17.67 -53.68 17.34 -53 17 C-53 14.03 -53 11.06 -53 8 C-51.68 8 -50.36 8 -49 8 C-49.33 10.97 -49.66 13.94 -50 17 C-47.69 17.33 -45.38 17.66 -43 18 C-43.33 14.7 -43.66 11.4 -44 8 C-42.35 8 -40.7 8 -39 8 C-39.99 8.5 -39.99 8.5 -41 9 C-41 11.97 -41 14.94 -41 18 C-39.89 17.81 -38.77 17.63 -37.62 17.44 C-34 17 -34 17 -32 18 C-32.49 13.05 -32.49 13.05 -33 8 C-29.7 8 -26.4 8 -23 8 C-23 8.99 -23 9.98 -23 11 C-22.01 11 -21.02 11 -20 11 C-19.67 9.68 -19.34 8.36 -19 7 C-17.02 6.67 -15.04 6.34 -13 6 C-13 5.67 -13 5.34 -13 5 C-15.31 4.67 -17.62 4.34 -20 4 C-20 3.67 -20 3.34 -20 3 C-19.06 2.95 -18.12 2.9 -17.15 2.85 C-15.28 2.74 -15.28 2.74 -13.38 2.62 C-11.53 2.52 -11.53 2.52 -9.65 2.41 C-6.16 2.02 -3.3 1.11 0 0 Z "
      fill="#EAEAEA"
      transform="translate(86,120)"
    />
    <path
      d="M0 0 C3.34 2.06 4.83 3.74 7 7 C7.76 12.4 8.41 18.34 5.79 23.29 C3.23 25.83 1.12 26.85 -2.45 27.33 C-8.17 27.15 -11.63 25.39 -15.72 21.47 C-18.91 17.8 -18.44 13.59 -18.42 8.93 C-17.97 5.79 -17.58 4.81 -15.19 2.81 C-10.11 -0.08 -5.75 -0.75 0 0 Z "
      fill="#D13620"
      transform="translate(96,88)"
    />
    <path
      d="M0 0 C16.17 0 32.34 0 49 0 C49 0.33 49 0.66 49 1 C33.16 1 17.32 1 1 1 C2.06 2.29 2.06 2.29 4.5 2.1 C5.51 2.09 6.52 2.07 7.56 2.06 C8.57 2.05 9.59 2.04 10.63 2.04 C11.41 2.02 12.19 2.01 13 2 C12.67 3.32 12.34 4.64 12 6 C11.01 6 10.02 6 9 6 C9 7.32 9 8.64 9 10 C9.66 10 10.32 10 11 10 C11.33 9.34 11.66 8.68 12 8 C12.33 9.32 12.66 10.64 13 12 C14.32 12 15.64 12 17 12 C17 13.98 17 15.96 17 18 C15.68 18 14.36 18 13 18 C13 19.32 13 20.64 13 22 C11.68 22 10.36 22 9 22 C9 23.32 9 24.64 9 26 C8.34 26 7.68 26 7 26 C7 28.64 7 31.28 7 34 C7.66 34 8.32 34 9 34 C9 35.32 9 36.64 9 38 C10.32 38.33 11.64 38.66 13 39 C12.4 39.12 11.79 39.24 11.17 39.37 C9.97 39.62 9.97 39.62 8.75 39.88 C7.57 40.12 7.57 40.12 6.36 40.37 C3.64 41.01 3.64 41.01 0 43 C0 28.81 0 14.62 0 0 Z "
      fill="#1D1A19"
      transform="translate(63,30)"
    />
    <path
      d="M0 0 C5.28 0 10.56 0 16 0 C16 1.32 16 2.64 16 4 C13.53 3.5 13.53 3.5 11 3 C11 3.66 11 4.32 11 5 C12.32 5.33 13.64 5.66 15 6 C14.67 6.99 14.34 7.98 14 9 C14.99 9 15.98 9 17 9 C17 10.65 17 12.3 17 14 C15.23 14.56 13.46 15.1 11.69 15.62 C10.21 16.08 10.21 16.08 8.7 16.54 C6 17 6 17 3 15 C1.34 14.62 -0.32 14.27 -2 14 C-1.36 13.69 -0.72 13.38 -0.06 13.06 C1.94 12.17 1.94 12.17 3 11 C3.23 9.49 3.41 7.96 3.56 6.44 C3.65 5.61 3.73 4.78 3.82 3.93 C3.88 3.3 3.94 2.66 4 2 C2.68 2.33 1.36 2.66 0 3 C0 2.01 0 1.02 0 0 Z "
      fill="#DEDEDF"
      transform="translate(96,127)"
    />
    <path
      d="M0 0 C16.17 0 32.34 0 49 0 C49 0.33 49 0.66 49 1 C33.16 1 17.32 1 1 1 C1.99 1.33 2.98 1.66 4 2 C4.33 13.88 4.66 25.76 5 38 C7.64 38 10.28 38 13 38 C13 38.33 13 38.66 13 39 C12.4 39.12 11.79 39.24 11.17 39.37 C9.97 39.62 9.97 39.62 8.75 39.88 C7.57 40.12 7.57 40.12 6.36 40.37 C3.64 41.01 3.64 41.01 0 43 C0 28.81 0 14.62 0 0 Z "
      fill="#424140"
      transform="translate(63,30)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.01 1.9 1.01 1.9 0 2.81 C-2.34 4.88 -2.34 4.88 -2 8 C-3.98 8.33 -5.96 8.66 -8 9 C-8.33 11.31 -8.66 13.62 -9 16 C-9.33 16 -9.66 16 -10 16 C-10.19 14.3 -10.19 14.3 -10.38 12.56 C-10.58 11.39 -10.79 10.21 -11 9 C-11.66 8.67 -12.32 8.34 -13 8 C-12.69 9.44 -12.38 10.88 -12.06 12.31 C-11.89 13.11 -11.71 13.91 -11.54 14.74 C-11 17.15 -11 17.15 -10 20 C-11.65 20 -13.3 20 -15 20 C-15 17.03 -15 14.06 -15 11 C-15.66 11 -16.32 11 -17 11 C-17.33 13.97 -17.66 16.94 -18 20 C-19.32 20 -20.64 20 -22 20 C-21.69 19.15 -21.38 18.31 -21.06 17.44 C-19.98 13.95 -19.43 10.62 -19 7 C-17.02 6.67 -15.04 6.34 -13 6 C-13 5.67 -13 5.34 -13 5 C-15.31 4.67 -17.62 4.34 -20 4 C-20 3.67 -20 3.34 -20 3 C-19.06 2.95 -18.12 2.9 -17.15 2.85 C-15.28 2.74 -15.28 2.74 -13.38 2.62 C-12.15 2.56 -10.92 2.49 -9.65 2.41 C-6.16 2.02 -3.3 1.11 0 0 Z "
      fill="#DCDBDC"
      transform="translate(86,120)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C3.67 2.97 3.34 5.94 3 9 C5.31 9.33 7.62 9.66 10 10 C9.67 6.7 9.34 3.4 9 0 C10.65 0 12.3 0 14 0 C13.01 0.5 13.01 0.5 12 1 C12 3.97 12 6.94 12 10 C13.98 9.67 15.96 9.34 18 9 C17.67 10.32 17.34 11.64 17 13 C12.38 13 7.76 13 3 13 C3 12.34 3 11.68 3 11 C1.35 11.33 -0.3 11.66 -2 12 C-2 11.34 -2 10.68 -2 10 C-1.34 9.67 -0.68 9.34 0 9 C0 6.03 0 3.06 0 0 Z "
      fill="#C9C9C9"
      transform="translate(33,128)"
    />
    <path
      d="M0 0 C6.88 -0.16 6.88 -0.16 10 1 C11.61 4.21 11.06 7.44 11 11 C10.34 11 9.68 11 9 11 C8.67 11.66 8.34 12.32 8 13 C8 12.01 8 11.02 8 10 C6.68 10 5.36 10 4 10 C3.88 3.25 3.88 3.25 5 1 C4.01 1.33 3.02 1.66 2 2 C2 4.64 2 7.28 2 10 C1.67 10 1.34 10 1 10 C0.67 6.7 0.34 3.4 0 0 Z "
      fill="#ECECEC"
      transform="translate(32,127)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1.33 20.46 1.66 40.92 2 62 C0.52 62.5 0.52 62.5 -1 63 C-0.67 42.21 -0.34 21.42 0 0 Z "
      fill="#CECDCB"
      transform="translate(112,30)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.67 1.33 1.34 1.66 1 2 C1.99 2.66 2.98 3.32 4 4 C3.01 5.65 2.02 7.3 1 9 C0.01 8.67 -0.98 8.34 -2 8 C-2 7.34 -2 6.68 -2 6 C-2.33 6.66 -2.66 7.32 -3 8 C-3.66 8 -4.32 8 -5 8 C-4.67 6.68 -4.34 5.36 -4 4 C-18.85 3.5 -18.85 3.5 -34 3 C-34 2.67 -34 2.34 -34 2 C-32.51 1.96 -32.51 1.96 -30.99 1.92 C-27.31 1.83 -23.63 1.73 -19.96 1.63 C-18.36 1.58 -16.77 1.54 -15.17 1.5 C-12.89 1.45 -10.6 1.38 -8.32 1.32 C-7.24 1.29 -7.24 1.29 -6.14 1.27 C-2.78 1.45 -2.78 1.45 0 0 Z "
      fill="#E6E6E4"
      transform="translate(65,71)"
    />
    <path
      d="M0 0 C2.97 0 5.94 0 9 0 C9 0.99 9 1.98 9 3 C6.03 2.5 6.03 2.5 3 2 C3 2.99 3 3.98 3 5 C3.99 5 4.98 5 6 5 C6 5.66 6 6.32 6 7 C5.01 7 4.02 7 3 7 C3 7.99 3 8.98 3 10 C4.98 9.67 6.96 9.34 9 9 C9 9.99 9 10.98 9 12 C6.03 12 3.06 12 0 12 C0 8.04 0 4.08 0 0 Z "
      fill="#171717"
      transform="translate(53,128)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C4 2.97 4 5.94 4 9 C4.99 9 5.98 9 7 9 C7 6.03 7 3.06 7 0 C8.32 0.33 9.64 0.66 11 1 C10.89 2.46 10.76 3.92 10.62 5.38 C10.56 6.19 10.49 7 10.41 7.84 C10 10 10 10 8 12 C5.5 12.25 5.5 12.25 3 12 C0.5 9.5 0.64 8.33 0.38 4.88 C0.3 3.96 0.23 3.05 0.15 2.12 C0.1 1.42 0.05 0.72 0 0 Z "
      fill="#1D1D1D"
      transform="translate(90,128)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C5.33 4 6.67 8 8 12 C6.35 12 4.7 12 3 12 C3 9.03 3 6.06 3 3 C2.34 3 1.68 3 1 3 C0.67 5.97 0.34 8.94 0 12 C-1.32 12 -2.64 12 -4 12 C-2.67 8 -1.33 4 0 0 Z "
      fill="#222222"
      transform="translate(68,128)"
    />
    <path
      d="M0 0 C0 0.99 0 1.98 0 3 C-2.31 3 -4.62 3 -7 3 C-7 4.98 -7 6.96 -7 9 C-5.35 9 -3.7 9 -2 9 C-1.67 8.34 -1.34 7.68 -1 7 C-1.66 6.67 -2.32 6.34 -3 6 C-2.01 6 -1.02 6 0 6 C-0.88 10.88 -0.88 10.88 -2 12 C-4.77 12.29 -6.41 12.36 -8.81 10.88 C-10.55 8.13 -10.4 6.18 -10 3 C-7.71 -1.04 -4.22 -0.24 0 0 Z "
      fill="#1F1F1F"
      transform="translate(87,128)"
    />
    <path
      d="M0 0 C2.97 0 5.94 0 9 0 C8.67 0.66 8.34 1.32 8 2 C6.35 1.67 4.7 1.34 3 1 C3 2.32 3 3.64 3 5 C3.99 5 4.98 5 6 5 C6 5.66 6 6.32 6 7 C5.01 7 4.02 7 3 7 C3 7.99 3 8.98 3 10 C4.98 9.67 6.96 9.34 9 9 C9 9.99 9 10.98 9 12 C6.03 12 3.06 12 0 12 C0 11.34 0 10.68 0 10 C0.66 10 1.32 10 2 10 C2 7.03 2 4.06 2 1 C1.34 0.67 0.68 0.34 0 0 Z "
      fill="#111111"
      transform="translate(103,128)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C4.01 0.5 4.01 0.5 3 1 C3 3.97 3 6.94 3 10 C4.98 9.67 6.96 9.34 9 9 C9 9.99 9 10.98 9 12 C6.03 12 3.06 12 0 12 C0 8.04 0 4.08 0 0 Z "
      fill="#282828"
      transform="translate(42,128)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C4.01 0.66 3.02 1.32 2 2 C2.14 3.46 2.29 4.92 2.44 6.38 C2.56 7.59 2.56 7.59 2.68 8.84 C2.89 11.04 2.89 11.04 4 13 C2.68 13.33 1.36 13.66 0 14 C0 13.01 0 12.02 0 11 C-0.66 11 -1.32 11 -2 11 C-2 9.68 -2 8.36 -2 7 C-3.32 7.33 -4.64 7.66 -6 8 C-6 7.34 -6 6.68 -6 6 C-4.35 6 -2.7 6 -1 6 C-0.67 6.66 -0.34 7.32 0 8 C0 5.36 0 2.72 0 0 Z "
      fill="#BEBEBE"
      transform="translate(89,127)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5.33 0.66 5.66 1.32 6 2 C4.35 1.67 2.7 1.34 1 1 C1 1.66 1 2.32 1 3 C2.98 3.5 2.98 3.5 5 4 C4.67 4.99 4.34 5.98 4 7 C4.99 7.33 5.98 7.66 7 8 C4.69 8.33 2.38 8.66 0 9 C0 8.01 0 7.02 0 6 C0.99 6 1.98 6 3 6 C3 5.34 3 4.68 3 4 C2.01 4 1.02 4 0 4 C0 2.68 0 1.36 0 0 Z "
      fill="#C6C6C6"
      transform="translate(106,129)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C3.89 1.44 3.76 2.88 3.62 4.31 C3.56 5.11 3.49 5.91 3.41 6.74 C3 9 3 9 1 12 C0.01 12 -0.98 12 -2 12 C-2 11.34 -2 10.68 -2 10 C-1.34 9.67 -0.68 9.34 0 9 C0 6.03 0 3.06 0 0 Z "
      fill="#191919"
      transform="translate(33,128)"
    />
    <path
      d="M0 0 C2.97 0 5.94 0 9 0 C9 0.99 9 1.98 9 3 C6.03 2.5 6.03 2.5 3 2 C2.67 4.64 2.34 7.28 2 10 C1.67 10 1.34 10 1 10 C0.67 6.7 0.34 3.4 0 0 Z "
      fill="#454545"
      transform="translate(53,128)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2.62 3.38 2.62 3.38 3 7 C2.34 7.66 1.68 8.32 1 9 C0.34 9 -0.32 9 -1 9 C-2.03 6.21 -2.05 5.13 -1.06 2.25 C-0.71 1.51 -0.36 0.76 0 0 Z "
      fill="#DBDBDB"
      transform="translate(69,131)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C4 3.96 4 7.92 4 12 C1 10 1 10 0.49 7.62 C0.4 6.26 0.4 6.26 0.31 4.88 C0.25 3.96 0.18 3.05 0.11 2.12 C0.08 1.42 0.04 0.72 0 0 Z "
      fill="#2F2F2F"
      transform="translate(90,128)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C3.17 5.86 3.17 5.86 3 8 C1.68 9.32 0.36 10.64 -1 12 C-0.67 8.04 -0.34 4.08 0 0 Z "
      fill="#3D100B"
      transform="translate(103,96)"
    />
    <path
      d="M0 0 C0.66 1.32 1.32 2.64 2 4 C0.35 5.32 -1.3 6.64 -3 8 C-3.66 7.67 -4.32 7.34 -5 7 C-3.35 4.69 -1.7 2.38 0 0 Z "
      fill="#E6E2E2"
      transform="translate(106,103)"
    />
    <path
      d="M0 0 C4.95 0.99 4.95 0.99 10 2 C10 2.33 10 2.66 10 3 C8.33 3.22 8.33 3.22 6.62 3.44 C3.18 3.78 3.18 3.78 1 5 C0.67 3.35 0.34 1.7 0 0 Z "
      fill="#E1E0DE"
      transform="translate(54,120)"
    />
    <path
      d="M0 0 C2.31 0.33 4.62 0.66 7 1 C7 1.66 7 2.32 7 3 C4.36 3 1.72 3 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
      fill="#DEDEDB"
      transform="translate(69,93)"
    />
  </svg>
)
export default JapanLeague
