import * as React from 'react'
const Brazil = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#03AE4A"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L8,1 L17,5 L28,12 L36,16 L36,18 L40,19 L45,21 L56,14 L61,11 L65,10 L76,10 L76,8 L72,8 L73,5 L77,5 L78,3 L80,5 L80,8 L92,8 L92,44 L-4,44 L-4,1 L-1,2 Z "
      fill="#03AE4A"
      transform="translate(4,52)"
    />
    <path
      d="M0,0 L6,1 L21,11 L18,12 L21,19 L21,33 L17,35 L12,40 L6,43 L-3,43 L-11,38 L-15,32 L-16,29 L-16,20 L-15,12 L-17,15 L-22,15 L-18,11 L-8,5 L-6,5 L-6,3 Z "
      fill="#E5D535"
      transform="translate(46,23)"
    />
    <path
      d="M0,0 L2,0 L2,17 L6,25 L14,30 L23,30 L31,26 L36,21 L38,21 L37,7 L35,3 L35,0 L43,1 L52,7 L59,12 L57,16 L47,22 L29,33 L20,39 L11,35 L11,33 L6,31 L-5,25 L-21,15 L-21,12 L-7,3 L0,2 Z "
      fill="#EED725"
      transform="translate(29,35)"
    />
    <path
      d="M0,0 L11,0 L24,4 L33,11 L33,15 L28,20 L22,23 L13,23 L5,18 L1,12 L0,9 Z "
      fill="#293462"
      transform="translate(30,43)"
    />
    <path
      d="M0,0 L9,0 L17,4 L23,13 L23,21 L21,21 L16,16 L6,12 L-10,9 L-8,5 Z "
      fill="#263061"
      transform="translate(43,30)"
    />
    <path
      d="M0,0 L8,0 L21,3 L31,9 L34,14 L33,16 L29,14 L20,8 L9,5 L-1,4 Z "
      fill="#C6DCD9"
      transform="translate(32,39)"
    />
    <path
      d="M0,0 L2,0 L2,2 L4,3 L-13,14 L-18,16 L-17,11 L-13,12 L-14,9 L-8,9 L-7,5 L-7,7 L-5,7 L-5,5 L0,4 Z "
      fill="#1EAC35"
      transform="translate(32,27)"
    />
    <path
      d="M0,0 L9,0 L17,4 L17,5 L12,5 L12,3 L-4,3 Z "
      fill="#393D41"
      transform="translate(43,30)"
    />
    <path
      d="M0,0 L2,1 L0,4 L-4,5 L-5,7 L-1,7 L-1,9 L-14,10 L-10,6 Z "
      fill="#16A940"
      transform="translate(81,53)"
    />
    <path
      d="M0,0 L6,1 L14,6 L11,7 L4,3 L-1,3 L-6,5 L-6,3 Z "
      fill="#C2DB38"
      transform="translate(46,23)"
    />
    <path
      d="M0,0 L11,1 L13,3 L11,5 L6,5 L1,3 Z "
      fill="#F5D41A"
      transform="translate(39,66)"
    />
    <path
      d="M0,0 L9,1 L9,5 L12,5 L13,9 L1,2 Z "
      fill="#28AC34"
      transform="translate(55,26)"
    />
    <path
      d="M0,0 L11,0 L11,1 L4,1 L3,6 L0,9 Z "
      fill="#3F465D"
      transform="translate(30,43)"
    />
    <path
      d="M0,0 L4,4 L6,8 L6,16 L4,15 L3,6 L0,6 Z "
      fill="#4C493F"
      transform="translate(60,35)"
    />
    <path
      d="M0,0 L2,0 L2,2 L-1,2 L-2,6 L-1,9 L-6,7 L-6,4 Z "
      fill="#C9E035"
      transform="translate(14,43)"
    />
    <path
      d="M0,0 L2,1 L0,4 L-7,7 L-11,8 L-8,5 Z "
      fill="#37AA26"
      transform="translate(81,53)"
    />
    <path
      d="M0,0 L7,3 L12,7 L4,6 L3,3 L0,2 Z "
      fill="#2AA835"
      transform="translate(16,55)"
    />
    <path
      d="M0,0 L5,1 L12,2 L9,5 L0,1 Z "
      fill="#C3DB3C"
      transform="translate(40,69)"
    />
    <path
      d="M0,0 L4,2 L2,6 L0,6 Z "
      fill="#D8E234"
      transform="translate(84,45)"
    />
    <path
      d="M0,0 L8,4 L4,6 L3,3 L0,2 Z "
      fill="#25AB30"
      transform="translate(28,62)"
    />
  </svg>
)
export default Brazil
