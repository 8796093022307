import React from 'react'
const CrotiaHnl = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={101}
    viewBox="0 0 150 101"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,101 L0,101 Z "
      fill="#FEFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L13,2 L18,4 L21,3 L36,4 L35,8 L40,9 L43,8 L55,10 L53,14 L48,16 L37,16 L34,21 L42,22 L41,25 L36,29 L22,28 L16,33 L13,35 L16,36 L24,36 L26,35 L24,42 L23,44 L54,45 L54,46 L38,46 L38,53 L35,52 L33,58 L26,71 L38,71 L38,73 L22,73 L20,73 L18,75 L55,76 L55,78 L-69,78 L-69,76 L-49,76 L-51,71 L-42,54 L-40,50 L-35,51 L-34,48 L-31,48 L-30,47 L-70,46 L-70,45 L-6,44 L-6,40 L-8,40 L-8,37 L-20,40 L-29,39 L-29,41 L-36,41 L-36,44 L-42,44 L-42,40 L-44,40 L-44,33 L-39,33 L-38,30 L-50,31 L-56,32 L-67,29 L-66,27 L-53,22 L-51,20 L-43,19 L-37,21 L-28,20 L-21,18 L-21,16 L-29,15 L-31,13 L-21,9 L-8,9 L-6,5 L-7,3 Z "
      fill="#F5F5FA"
      transform="translate(82,8)"
    />
    <path
      d="M0,0 L13,2 L18,4 L21,3 L36,4 L35,8 L40,9 L43,8 L55,10 L53,14 L48,16 L37,16 L34,21 L42,22 L41,25 L36,29 L22,28 L16,33 L13,35 L24,38 L18,45 L12,49 L-3,45 L2,40 L8,36 L0,34 L0,31 L6,30 L7,31 L13,31 L17,28 L19,22 L23,20 L25,23 L29,19 L24,18 L23,13 L31,10 L28,10 L20,10 L19,12 L14,13 L13,21 L6,24 L1,20 L3,12 L2,10 L-4,9 L-4,12 L-6,12 L-5,19 L-4,21 L-6,26 L-9,25 L-10,23 L-16,25 L-20,26 L-20,28 L-10,30 L-7,32 L-20,40 L-29,39 L-29,41 L-36,41 L-36,44 L-42,44 L-42,40 L-44,40 L-44,33 L-39,33 L-38,30 L-50,31 L-56,32 L-67,29 L-66,27 L-53,22 L-51,20 L-43,19 L-37,21 L-28,20 L-21,18 L-21,16 L-29,15 L-31,13 L-21,9 L-8,9 L-6,5 L-7,3 Z M-5,14 Z "
      fill="#E9EAE9"
      transform="translate(82,8)"
    />
    <path
      d="M0,0 L2,3 L-6,5 L-6,10 L-1,11 L-2,14 L-7,16 L-7,13 L-11,15 L-13,21 L-17,24 L-23,24 L-27,23 L-30,23 L-30,26 L-21,27 L-25,31 L-32,36 L-18,40 L-18,41 L-27,41 L-27,39 L-100,38 L-100,37 L-36,36 L-36,32 L-38,32 L-38,29 L-46,31 L-48,30 L-39,24 L-47,21 L-50,20 L-50,18 L-42,14 L-39,15 L-36,18 L-35,12 L-36,7 L-36,4 L-34,4 L-34,1 L-28,1 L-26,6 L-28,12 L-23,15 L-18,13 L-17,5 L-15,3 L-10,2 Z M-35,6 Z "
      fill="#F4F0F0"
      transform="translate(112,16)"
    />
    <path
      d="M0,0 L1,2 L-2,9 L29,10 L29,11 L13,11 L13,18 L10,17 L8,23 L1,36 L13,36 L13,38 L-3,38 L-5,37 L-4,32 L-8,37 L-11,37 L-15,28 L-18,19 L-20,19 L-22,25 L-28,36 L-30,37 L-28,30 L-23,21 L-23,14 L-12,13 L-2,4 L-5,2 Z "
      fill="#F4F4FA"
      transform="translate(107,43)"
    />
    <path
      d="M0,0 L10,1 L12,3 L7,8 L18,10 L17,13 L12,17 L-2,16 L-8,21 L-11,23 L0,26 L-6,33 L-12,37 L-27,33 L-22,28 L-16,24 L-24,22 L-24,19 L-18,18 L-17,19 L-11,19 L-7,16 L-5,10 L-1,8 L1,11 L5,7 L0,6 L-1,1 Z "
      fill="#B2252C"
      transform="translate(106,20)"
    />
    <path
      d="M0,0 L10,2 L15,2 L14,5 L24,8 L21,11 L10,17 L16,20 L16,22 L9,22 L9,25 L3,25 L3,21 L1,21 L1,14 L6,14 L7,11 L-5,12 L-11,13 L-22,10 L-21,8 L-8,3 L-6,1 Z "
      fill="#FDFCFC"
      transform="translate(37,27)"
    />
    <path
      d="M0,0 L9,1 L11,3 L3,7 L6,9 L11,9 L14,6 L17,12 L15,17 L12,16 L11,14 L5,16 L1,17 L1,19 L11,21 L14,23 L1,31 L-7,30 L-15,28 L-12,25 L-1,19 L-10,16 L-12,14 L-2,9 L0,9 L0,7 L-8,6 L-10,4 Z "
      fill="#C02027"
      transform="translate(61,17)"
    />
    <path
      d="M0,0 L2,0 L0,5 L-5,15 L-6,19 L-4,20 L-6,22 L-7,26 L12,25 L12,23 L4,22 L-1,23 L0,19 L4,19 L4,17 L6,17 L8,19 L11,19 L12,15 L20,0 L22,0 L20,5 L18,10 L32,9 L37,0 L39,0 L37,6 L29,21 L26,21 L31,12 L16,13 L14,17 L14,26 L94,27 L94,28 L-29,28 L-29,26 L-9,26 L-11,21 L-2,4 Z "
      fill="#D6D5DE"
      transform="translate(42,58)"
    />
    <path
      d="M0,0 L17,1 L16,5 L21,6 L24,5 L36,7 L34,11 L29,13 L18,13 L15,17 L12,17 L13,14 L16,12 L11,10 L11,6 L9,7 L1,7 L0,9 L-5,6 L-7,1 L-1,1 Z "
      fill="#F9E0DF"
      transform="translate(101,11)"
    />
    <path
      d="M0,0 L2,0 L-1,10 L-1,13 L1,9 L1,11 L4,8 L8,0 L10,0 L8,6 L0,21 L12,21 L12,23 L-4,23 L-6,22 L-5,17 L-9,22 L-12,22 L-16,13 L-19,4 L-21,4 L-23,10 L-29,21 L-31,22 L-29,15 L-21,1 L-17,1 L-11,16 L-11,19 L-9,19 L-7,13 Z "
      fill="#2D2776"
      transform="translate(108,58)"
    />
    <path
      d="M0,0 L10,1 L12,3 L7,8 L18,10 L17,13 L12,17 L2,16 L-2,14 L2,10 L5,7 L0,6 L-1,1 Z "
      fill="#C02027"
      transform="translate(106,20)"
    />
    <path
      d="M0,0 L11,2 L12,4 L-2,11 L-10,10 L-17,8 L-16,6 Z "
      fill="#BF1D24"
      transform="translate(32,29)"
    />
    <path
      d="M0,0 L10,2 L15,2 L14,5 L24,8 L21,11 L10,17 L13,19 L8,20 L7,15 L10,13 L15,13 L15,11 L18,11 L18,8 L10,9 L10,7 L8,7 L7,10 L-3,11 L1,8 L6,5 L-5,3 L-7,2 Z "
      fill="#F8D3D2"
      transform="translate(37,27)"
    />
    <path
      d="M0,0 L8,0 L5,1 L5,3 L11,5 L10,9 L6,12 L-9,8 L-4,3 Z "
      fill="#C31C24"
      transform="translate(88,45)"
    />
    <path
      d="M0,0 L2,0 L0,6 L-8,21 L4,21 L4,23 L-12,23 L-14,22 L-13,13 L-10,8 L-9,11 L-8,12 L-7,9 L-7,11 L-4,8 Z "
      fill="#D4D6EC"
      transform="translate(116,58)"
    />
    <path
      d="M0,0 L2,0 L0,5 L-2,10 L12,9 L17,0 L19,0 L17,6 L9,21 L6,21 L11,12 L-4,13 L-9,22 L-11,21 L-3,5 Z "
      fill="#443C8B"
      transform="translate(62,58)"
    />
    <path
      d="M0,0 L9,3 L10,11 L3,14 L-2,10 L0,2 Z "
      fill="#6E6F6E"
      transform="translate(85,18)"
    />
    <path
      d="M0,0 L3,1 L6,4 L7,2 L8,5 L10,6 L10,8 L15,8 L12,9 L12,11 L-2,8 L-8,6 L-8,4 Z "
      fill="#F7E4E3"
      transform="translate(70,30)"
    />
    <path
      d="M0,0 L2,3 L-6,5 L-6,10 L-1,11 L-2,14 L-7,16 L-7,13 L-9,12 L-10,8 L-12,7 L-13,3 Z "
      fill="#E3D6D2"
      transform="translate(112,16)"
    />
    <path
      d="M0,0 L9,1 L19,2 L18,5 L16,6 L8,6 L7,8 L2,5 Z "
      fill="#B8191E"
      transform="translate(94,12)"
    />
    <path
      d="M0,0 L3,1 L2,7 L-5,13 L-8,16 L-11,15 L-11,12 L-5,9 L-2,2 Z "
      fill="#6B6462"
      transform="translate(103,28)"
    />
    <path
      d="M0,0 L8,1 L2,8 L-4,8 L-4,4 L-7,3 Z "
      fill="#8B383D"
      transform="translate(82,8)"
    />
    <path
      d="M0,0 L1,2 L-2,9 L29,10 L29,11 L-9,11 L-4,6 L-2,4 L-5,2 Z "
      fill="#EDDFE0"
      transform="translate(107,43)"
    />
    <path
      d="M0,0 L9,0 L13,2 L8,6 L-1,5 L-3,3 Z "
      fill="#BE2530"
      transform="translate(124,18)"
    />
    <path
      d="M0,0 L2,0 L0,6 L-8,21 L-11,21 L-6,12 L-19,12 L-19,11 L-5,11 L-3,6 Z "
      fill="#251D69"
      transform="translate(79,58)"
    />
    <path
      d="M0,0 L2,0 L0,6 L-8,21 L4,21 L4,22 L-10,22 L-8,16 Z "
      fill="#140E5E"
      transform="translate(116,58)"
    />
    <path
      d="M0,0 L1,0 L2,6 L10,7 L10,10 L-4,9 L-2,3 Z "
      fill="#DDDBF6"
      transform="translate(112,70)"
    />
    <path
      d="M0,0 L2,0 L0,5 L-8,21 L-11,21 L-2,4 Z "
      fill="#251D6A"
      transform="translate(42,58)"
    />
    <path
      d="M0,0 L2,0 L4,2 L6,2 L7,4 L8,6 L0,5 L-5,6 L-4,2 L0,2 Z "
      fill="#C5C4D5"
      transform="translate(46,75)"
    />
    <path
      d="M0,0 L4,5 L2,11 L-1,10 L-3,4 Z "
      fill="#5A5857"
      transform="translate(74,23)"
    />
    <path
      d="M0,0 L4,1 L3,5 L8,5 L8,8 L4,9 L-1,5 Z "
      fill="#5D5D5D"
      transform="translate(84,23)"
    />
    <path
      d="M0,0 L4,0 L4,6 L-3,5 L-6,3 Z "
      fill="#FBC7C5"
      transform="translate(68,21)"
    />
    <path
      d="M0,0 L5,1 L8,2 L8,5 L10,6 L8,6 L7,8 L2,5 Z "
      fill="#635659"
      transform="translate(94,12)"
    />
    <path
      d="M0,0 L4,4 L4,6 L9,6 L6,7 L6,9 L-4,7 L0,6 L-2,2 L0,2 Z "
      fill="#BCAAAC"
      transform="translate(76,32)"
    />
    <path
      d="M0,0 L11,3 L11,5 L2,8 L2,6 L4,5 L0,2 Z "
      fill="#FBD9D5"
      transform="translate(50,21)"
    />
    <path
      d="M0,0 L5,1 L1,6 L-2,6 L-2,4 L-4,3 Z "
      fill="#696467"
      transform="translate(83,10)"
    />
    <path
      d="M0,0 L4,1 L7,2 L7,4 L-3,4 L-3,1 Z "
      fill="#0D0B0A"
      transform="translate(85,38)"
    />
    <path
      d="M0,0 L2,3 L3,7 L-2,9 L-2,4 L-4,3 L-4,1 Z M3,5 Z "
      fill="#CDBDBD"
      transform="translate(74,17)"
    />
    <path
      d="M0,0 L7,0 L10,1 L10,3 L1,3 L-4,4 L-4,2 L0,2 Z "
      fill="#FBD5D8"
      transform="translate(60,15)"
    />
    <path
      d="M0,0 L8,1 L4,5 L1,6 L1,4 L-1,3 L1,2 Z "
      fill="#E9D2CD"
      transform="translate(83,42)"
    />
    <path
      d="M0,0 L7,2 L4,4 L-3,4 L-3,1 Z "
      fill="#D9D0D1"
      transform="translate(90,9)"
    />
    <path
      d="M0,0 L2,0 L0,6 L-3,12 L-5,10 Z "
      fill="#342F76"
      transform="translate(116,58)"
    />
    <path
      d="M0,0 L2,0 L4,5 L1,5 L0,7 L-3,6 Z "
      fill="#DBDCF3"
      transform="translate(87,62)"
    />
    <path
      d="M0,0 L3,2 L-3,6 L-6,6 L-6,3 Z "
      fill="#B0A3A7"
      transform="translate(98,37)"
    />
    <path
      d="M0,0 L11,1 L12,3 L-2,3 Z "
      fill="#F2C9C8"
      transform="translate(101,11)"
    />
    <path
      d="M0,0 L2,3 L-5,4 L-9,3 L-10,5 L-10,2 Z "
      fill="#E8AEB1"
      transform="translate(112,16)"
    />
    <path
      d="M0,0 L2,2 L4,9 L1,10 L-1,2 Z "
      fill="#413984"
      transform="translate(93,67)"
    />
    <path
      d="M0,0 L15,0 L14,2 L2,2 L-1,3 Z "
      fill="#CDCDEE"
      transform="translate(58,70)"
    />
    <path
      d="M0,0 L2,0 L0,9 L-1,9 L-1,4 L-6,3 L-4,1 L-1,2 Z "
      fill="#D7D7D8"
      transform="translate(100,27)"
    />
    <path
      d="M0,0 L7,0 L7,2 L3,3 L1,5 Z "
      fill="#D9D9EB"
      transform="translate(84,57)"
    />
    <path
      d="M0,0 L3,1 L-1,7 L-3,6 Z "
      fill="#362F81"
      transform="translate(71,73)"
    />
    <path
      d="M0,0 L2,0 L0,5 L-1,8 L-4,7 Z "
      fill="#393176"
      transform="translate(42,58)"
    />
    <path
      d="M0,0 L6,0 L8,1 L8,3 L1,2 Z "
      fill="#FEE1E0"
      transform="translate(94,42)"
    />
    <path
      d="M0,0 L4,0 L3,3 L-2,5 Z "
      fill="#FCDCDC"
      transform="translate(107,27)"
    />
    <path
      d="M0,0 L2,0 L2,6 L-2,5 Z "
      fill="#FDF5F3"
      transform="translate(70,21)"
    />
  </svg>
)
export default CrotiaHnl
