import * as React from 'react'
const Nigeria = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0 0 C8.6 2.87 13.08 9.17 17 17 C17.8 25.72 18.18 33.51 12.93 40.94 C7.89 46.32 2.03 50.08 -5.36 50.39 C-13.58 50.56 -19.71 49.88 -26 44 C-32.57 35.95 -33.91 29.16 -33.26 18.99 C-32.48 13.05 -29.19 8.79 -24.67 5.1 C-16.76 -0.34 -9.46 -1.24 0 0 Z "
      fill="#F4F7F5"
      transform="translate(33,0)"
    />
    <path
      d="M0 0 C0 14.52 0 29.04 0 44 C-6.9 42.62 -9.96 38.57 -14 33 C-16.8 27.17 -16.73 21.34 -16 15 C-13.81 8.79 -6.93 0 0 0 Z "
      fill="#0B8754"
      transform="translate(17,3)"
    />
    <path
      d="M0 0 C5.14 2.05 9.1 4.08 13 8 C14.31 11.11 14.75 12.77 15 16 C15.1 17.01 15.21 18.01 15.31 19.05 C15.77 27.2 14.34 32.74 9 39 C3.54 44 3.54 44 0 44 C0 29.48 0 14.96 0 0 Z "
      fill="#078752"
      transform="translate(34,3)"
    />
    <path
      d="M0 0 C0.89 0.3 0.89 0.3 1.79 0.61 C0.94 0.94 0.1 1.27 -0.77 1.61 C-6.15 4.74 -10.95 9.71 -13.21 15.61 C-14.12 23.6 -13.52 29.83 -9.21 36.61 C-8.87 37.32 -8.54 38.03 -8.19 38.76 C-6.91 41.19 -5.74 41.6 -3.21 42.61 C-2.3 42.98 -1.4 43.36 -0.46 43.74 C0.65 44.17 0.65 44.17 1.79 44.61 C-0.66 45.52 -1.75 45.82 -4.18 44.71 C-9.4 40.8 -13.28 36.97 -15.21 30.61 C-16.39 21.88 -16.31 14.06 -11 6.61 C-4.41 -0.77 -4.41 -0.77 0 0 Z "
      fill="#D9E4DD"
      transform="translate(15.2109375,2.38671875)"
    />
  </svg>
)
export default Nigeria
