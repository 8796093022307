import { LilbitLogoSecond } from '@/icons/index'
import { Dialog, Transition } from '@headlessui/react'
import React from 'react'

export default function SplashLoader({ show: isOpen }) {
  return (
    <Transition appear show={isOpen}>
      <Dialog as="div" className="relative z-20 bg-white" onClose={() => {}}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 backdrop-blur-md bg-black/40`} />
        </Transition.Child>
        <div className="fixed inset-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="w-full h-screen flex items-center justify-center">
              <div className="animate-pulse">
                <LilbitLogoSecond className="w-24 h-24" />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
