import React from 'react'
const Serbia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#B0BCC5"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L4,50 L0,47 L-8,42 L-13,36 L-14,33 L-6,33 L-5,36 L4,37 L7,36 L8,32 L29,32 L29,20 L25,12 L17,5 L14,4 L-1,4 L-7,8 L-13,15 L-15,20 L-15,32 L-17,31 L-18,28 L-18,18 L-14,10 L-7,3 Z "
      fill="#FEFCFD"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L15,0 L22,4 L28,10 L29,14 L9,14 L7,18 L5,23 L3,23 L4,16 L3,17 L1,17 L0,16 L0,19 L-3,18 L-4,16 L-5,29 L-6,29 L-6,14 L-13,14 L-11,9 L-4,2 Z "
      fill="#DF3238"
      transform="translate(17,3)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L4,50 L2,47 L13,46 L21,42 L26,36 L27,34 L8,33 L8,32 L29,32 L29,20 L25,12 L17,5 L14,4 L-1,4 L-7,8 L-13,15 L-15,20 L-15,32 L-17,31 L-18,28 L-18,18 L-14,10 L-7,3 Z "
      fill="#BCC8CC"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L20,0 L22,3 L22,13 L21,15 L0,15 Z "
      fill="#1B5793"
      transform="translate(26,17)"
    />
    <path
      d="M0,0 L4,1 L-1,4 L7,5 L11,7 L8,16 L6,15 L5,16 L3,16 L2,15 L2,18 L-1,17 L-2,15 L-3,28 L-4,28 L-4,13 L-11,13 L-9,8 L-2,1 Z "
      fill="#E4232C"
      transform="translate(15,4)"
    />
    <path
      d="M0,0 L4,4 L3,0 L5,0 L5,2 L7,2 L7,0 L9,1 L7,8 L9,8 L10,3 L12,0 L13,1 L13,14 L8,13 L9,9 L7,9 L8,19 L4,19 L5,9 L3,9 L4,13 L-1,14 L-1,1 Z "
      fill="#ECCDCC"
      transform="translate(13,18)"
    />
    <path
      d="M0,0 L7,0 L7,15 L-1,15 L-2,13 L-2,3 Z "
      fill="#175795"
      transform="translate(4,17)"
    />
    <path
      d="M0,0 L7,0 L11,2 L8,11 L6,10 L6,9 L-1,7 L-3,2 Z "
      fill="#DAA537"
      transform="translate(15,9)"
    />
    <path
      d="M0,0 L2,0 L1,10 L5,10 L4,0 L6,0 L7,4 L10,5 L9,9 L6,11 L0,11 L-4,7 L-3,4 L0,4 Z "
      fill="#D8573C"
      transform="translate(16,27)"
    />
    <path
      d="M0,0 L1,4 L-2,10 L-7,13 L-5,8 L-4,6 L-23,5 L-23,4 L-1,3 Z "
      fill="#9DB1C3"
      transform="translate(49,28)"
    />
    <path
      d="M0,0 L7,0 L11,2 L9,6 L7,2 L5,2 L5,4 L3,4 L3,2 L1,2 L0,4 L-3,4 L-2,1 Z "
      fill="#D89B71"
      transform="translate(15,9)"
    />
    <path
      d="M0,0 L4,4 L3,21 L2,21 L2,5 L-3,5 Z "
      fill="#C9434D"
      transform="translate(9,11)"
    />
    <path
      d="M0,0 L2,1 L2,14 L0,14 L-1,8 Z "
      fill="#F8E8E5"
      transform="translate(24,18)"
    />
    <path
      d="M0,0 L2,0 L2,2 L4,2 L4,0 L6,0 L7,4 L-1,4 L-1,1 Z "
      fill="#D35632"
      transform="translate(16,11)"
    />
    <path
      d="M0,0 L3,1 L3,6 L-1,6 L-1,1 Z "
      fill="#EEE6E8"
      transform="translate(18,31)"
    />
    <path
      d="M0,0 L7,1 L6,4 L4,4 L3,3 L3,6 L0,5 Z "
      fill="#D94A37"
      transform="translate(14,16)"
    />
  </svg>
)
export default Serbia
