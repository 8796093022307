import React from 'react'
const CzechFnl = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={208}
    height={233}
    viewBox="0 0 208 233"
    {...props}
  >
    <path
      d="M0,0 L208,0 L208,233 L0,233 Z "
      fill="#FCFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L14,1 L20,2 L22,6 L26,8 L31,13 L34,15 L35,20 L41,25 L44,32 L46,33 L49,39 L52,42 L57,55 L60,60 L65,75 L66,76 L66,81 L61,76 L55,68 L53,63 L49,61 L47,60 L45,61 L41,69 L36,71 L35,73 L39,74 L44,76 L47,79 L51,81 L56,86 L66,94 L66,96 L62,95 L55,91 L48,90 L44,87 L35,85 L34,84 L26,83 L26,81 L-1,82 L-12,84 L-17,84 L-17,86 L-24,85 L-34,81 L-37,72 L-37,63 L-28,52 L-19,53 L-3,55 L0,57 L12,60 L13,50 L22,43 L23,40 L13,36 L-2,32 L-2,30 L-7,29 L-16,25 L-15,10 L-6,4 Z "
      fill="#DBDCE1"
      transform="translate(87,41)"
    />
    <path
      d="M0,0 L14,1 L20,2 L22,6 L26,8 L31,13 L34,15 L35,20 L41,25 L44,32 L46,33 L49,39 L52,42 L57,55 L60,60 L65,75 L66,76 L66,81 L61,76 L55,68 L53,63 L49,61 L46,60 L48,48 L45,45 L34,39 L29,38 L24,41 L13,36 L-2,32 L-2,30 L-7,29 L-16,25 L-15,10 L-6,4 Z "
      fill="#DFE2E5"
      transform="translate(87,41)"
    />
    <path
      d="M0,0 L6,2 L14,8 L18,13 L20,20 L22,27 L24,39 L24,78 L23,87 L21,87 L21,94 L20,96 L18,96 L15,72 L13,63 L8,51 L6,46 L2,39 L-1,36 L-1,33 L-5,31 L-5,29 L-8,28 L-9,23 L-13,22 L-15,18 L-14,5 L-6,2 Z "
      fill="#DDDDDF"
      transform="translate(149,38)"
    />
    <path
      d="M0,0 L5,1 L17,8 L20,11 L18,21 L12,32 L7,34 L-12,34 L-15,27 L-16,24 L-16,13 L-7,6 Z "
      fill="#083769"
      transform="translate(116,78)"
    />
    <path
      d="M0,0 L9,1 L19,4 L23,17 L22,22 L14,31 L11,34 L4,33 L-6,29 L-9,20 L-9,11 Z "
      fill="#0A0E3F"
      transform="translate(59,93)"
    />
    <path
      d="M0,0 L14,1 L19,2 L19,18 L2,30 L-2,31 L-15,26 L-16,25 L-15,10 L-6,4 Z "
      fill="#025D9B"
      transform="translate(87,41)"
    />
    <path
      d="M0,0 L6,2 L14,8 L17,11 L17,24 L15,27 L2,32 L-2,30 L-9,24 L-13,22 L-15,18 L-14,5 L-6,2 Z "
      fill="#4D5781"
      transform="translate(149,38)"
    />
    <path
      d="M0,0 L9,1 L19,4 L23,17 L22,22 L11,21 L7,19 L7,15 L3,14 L1,13 L0,6 L-2,10 L-8,11 L-2,3 Z "
      fill="#093669"
      transform="translate(59,93)"
    />
    <path
      d="M0,0 L5,1 L16,7 L12,10 L8,11 L8,13 L2,15 L2,17 L-2,20 L-4,20 L-4,22 L-12,23 L-15,27 L-16,24 L-16,13 L-7,6 Z "
      fill="#467DB0"
      transform="translate(116,78)"
    />
    <path
      d="M0,0 L12,0 L13,5 L15,5 L17,1 L18,0 L24,0 L27,3 L27,14 L23,18 L18,18 L13,16 L13,9 L10,8 L10,10 L4,11 L3,18 L0,19 Z "
      fill="#B5B5B5"
      transform="translate(39,145)"
    />
    <path
      d="M0,0 L6,0 L10,4 L10,8 L10,11 L14,11 L18,1 L22,1 L25,11 L27,15 L27,18 L24,18 L23,15 L17,15 L16,18 L12,18 L11,15 L9,19 L5,20 L5,18 L-1,17 L-3,15 L-3,3 Z "
      fill="#EDEDED"
      transform="translate(107,196)"
    />
    <path
      d="M0,0 L6,2 L14,8 L17,11 L17,24 L15,27 L4,31 L2,31 L4,27 L10,19 L10,15 L12,15 L12,13 L8,14 L8,13 L2,11 L-7,5 L-4,4 L-3,1 Z "
      fill="#474970"
      transform="translate(149,38)"
    />
    <path
      d="M0,0 L3,0 L3,11 L-8,26 L-10,26 L-13,22 L-12,15 L-7,5 Z "
      fill="#062053"
      transform="translate(54,59)"
    />
    <path
      d="M0,0 L14,1 L19,2 L19,7 L17,6 L17,4 L15,5 L3,7 L-1,11 L-9,11 L-9,16 L-13,18 L-14,24 L-16,24 L-15,10 L-6,4 Z "
      fill="#0C4D81"
      transform="translate(87,41)"
    />
    <path
      d="M0,0 L18,0 L27,5 L24,7 L15,10 L6,9 L-4,6 L-4,4 L-2,4 Z "
      fill="#232145"
      transform="translate(106,16)"
    />
    <path
      d="M0,0 L1,0 L1,11 L-16,23 L-20,24 L-28,21 L-28,20 L-18,20 L-17,14 L-12,11 L-11,8 L-7,7 L-7,5 L0,4 Z "
      fill="#1077AF"
      transform="translate(105,48)"
    />
    <path
      d="M0,0 L10,0 L12,2 L12,16 L11,17 L0,17 Z "
      fill="#474747"
      transform="translate(85,145)"
    />
    <path
      d="M0,0 L8,0 L12,3 L12,14 L9,17 L0,17 Z "
      fill="#353435"
      transform="translate(122,172)"
    />
    <path
      d="M0,0 L7,1 L8,2 L8,15 L6,17 L-1,17 L-4,14 L-4,4 Z "
      fill="#3C3C3C"
      transform="translate(136,145)"
    />
    <path
      d="M0,0 L6,0 L9,3 L9,13 L6,17 L0,17 L-3,13 L-3,3 Z "
      fill="#E8E8E8"
      transform="translate(108,172)"
    />
    <path
      d="M0,0 L2,1 L-2,8 L-16,16 L-22,19 L-26,18 L-19,10 L-10,4 Z "
      fill="#121542"
      transform="translate(86,21)"
    />
    <path
      d="M0,0 L7,0 L12,3 L11,8 L10,12 L12,17 L9,17 L6,12 L6,10 L3,11 L2,17 L0,17 Z "
      fill="#4B4B4B"
      transform="translate(89,172)"
    />
    <path
      d="M0,0 L4,2 L8,8 L8,10 L10,10 L10,0 L13,0 L13,17 L9,16 L4,8 L2,7 L2,17 L0,17 Z "
      fill="#4C4C4C"
      transform="translate(54,172)"
    />
    <path
      d="M0,0 L4,1 L9,9 L10,9 L11,0 L13,0 L13,17 L10,17 L5,8 L3,7 L3,17 L0,17 Z "
      fill="#4E4C4D"
      transform="translate(139,172)"
    />
    <path
      d="M0,0 L4,0 L7,10 L9,14 L9,17 L6,17 L5,14 L-1,14 L-5,16 L-3,7 Z "
      fill="#424141"
      transform="translate(125,197)"
    />
    <path
      d="M0,0 L1,0 L1,12 L-1,15 L-12,19 L-14,19 L-12,15 L-1,1 Z "
      fill="#0C0D2A"
      transform="translate(165,50)"
    />
    <path
      d="M0,0 L3,0 L9,16 L6,17 L5,15 L-2,15 L-3,17 L-5,17 L-3,8 Z "
      fill="#2C2C2C"
      transform="translate(76,172)"
    />
    <path
      d="M0,0 L3,1 L6,11 L8,17 L5,17 L4,15 L-2,15 L-6,17 L-3,6 Z "
      fill="#393939"
      transform="translate(168,145)"
    />
    <path
      d="M0,0 L2,0 L5,6 L7,16 L5,16 L4,14 L-2,14 L-6,16 L-5,10 L-1,1 Z "
      fill="#3A3A3A"
      transform="translate(107,146)"
    />
    <path
      d="M0,0 L6,0 L9,3 L9,5 L5,3 L1,4 L1,14 L6,14 L6,11 L4,9 L9,9 L9,15 L7,17 L-1,17 L-3,15 L-3,3 Z "
      fill="#444444"
      transform="translate(107,196)"
    />
    <path
      d="M0,0 L6,0 L9,3 L9,13 L6,17 L0,17 L-3,13 L-3,3 Z M1,2 L-1,4 L-1,13 L1,15 L6,14 L7,13 L7,4 L5,2 Z "
      fill="#2E2E2E"
      transform="translate(108,172)"
    />
    <path
      d="M0,0 L5,0 L7,2 L7,11 L5,13 L0,13 Z "
      fill="#EEEDED"
      transform="translate(125,174)"
    />
    <path
      d="M0,0 L4,1 L6,9 L8,10 L11,0 L14,0 L11,11 L9,17 L6,17 L1,3 Z "
      fill="#474747"
      transform="translate(147,145)"
    />
    <path
      d="M0,0 L4,1 L9,4 L9,5 L-11,7 L-14,6 L-14,3 L-7,2 L-5,3 L-4,1 L0,3 Z "
      fill="#120E40"
      transform="translate(124,17)"
    />
    <path
      d="M0,0 L12,0 L12,3 L8,3 L8,18 L5,18 L5,3 L0,3 Z "
      fill="#414141"
      transform="translate(69,145)"
    />
    <path
      d="M0,0 L5,0 L6,1 L6,11 L5,12 L0,12 L-1,11 L-1,1 Z "
      fill="#F4F4F4"
      transform="translate(136,148)"
    />
    <path
      d="M0,0 L5,0 L6,1 L6,11 L1,12 L-1,10 L-1,2 Z "
      fill="#FCFCFC"
      transform="translate(57,148)"
    />
    <path
      d="M0,0 L1,3 L1,5 L5,6 L2,15 L5,14 L5,16 L0,15 L-3,15 L-3,5 L-2,2 L0,2 Z "
      fill="#E6E6E6"
      transform="translate(99,147)"
    />
    <path
      d="M0,0 L2,0 L2,15 L11,15 L10,18 L0,18 Z "
      fill="#232323"
      transform="translate(82,196)"
    />
    <path
      d="M0,0 L7,0 L11,2 L10,6 L8,3 L3,3 L2,17 L0,17 Z "
      fill="#232323"
      transform="translate(89,172)"
    />
    <path
      d="M0,0 L10,0 L10,1 L3,2 L3,6 L9,7 L9,9 L3,10 L2,16 L0,16 Z "
      fill="#242424"
      transform="translate(40,146)"
    />
    <path
      d="M0,0 L2,1 L2,15 L-2,16 L-6,14 L-1,13 L-1,10 L-7,10 L-6,6 L-1,6 L-2,1 Z "
      fill="#313131"
      transform="translate(95,146)"
    />
    <path
      d="M0,0 L4,2 L6,5 L6,8 L2,7 L2,17 L0,17 Z "
      fill="#252525"
      transform="translate(54,172)"
    />
    <path
      d="M0,0 L3,0 L3,3 L8,3 L9,0 L12,7 L9,8 L8,6 L2,6 L-2,8 Z "
      fill="#2C2C2C"
      transform="translate(164,154)"
    />
    <path
      d="M0,0 L6,0 L6,3 L10,4 L9,9 L12,8 L12,10 L8,10 L7,7 L5,11 L1,12 L1,10 L-3,9 L4,7 L5,1 L0,1 Z "
      fill="#C9C7C8"
      transform="translate(111,204)"
    />
    <path
      d="M0,0 L2,0 L1,8 L5,9 L-1,12 L-4,13 L-2,4 Z "
      fill="#373535"
      transform="translate(124,200)"
    />
    <path
      d="M0,0 L2,0 L2,17 L-1,17 L-3,11 L0,11 Z "
      fill="#252424"
      transform="translate(150,172)"
    />
    <path
      d="M0,0 L1,2 L21,1 L18,3 L12,5 L3,4 L-4,2 Z "
      fill="#3F3D61"
      transform="translate(109,21)"
    />
    <path
      d="M0,0 L8,0 L5,2 L-2,4 L-3,12 L-5,12 L-6,6 L-4,1 Z "
      fill="#E4E9F2"
      transform="translate(138,39)"
    />
    <path
      d="M0,0 L2,0 L2,2 L7,0 L7,2 L14,3 L14,6 L7,5 L-2,2 Z "
      fill="#202033"
      transform="translate(56,121)"
    />
    <path
      d="M0,0 L2,0 L3,14 L10,15 L10,16 L0,16 Z "
      fill="#212121"
      transform="translate(119,146)"
    />
    <path
      d="M0,0 L5,0 L6,5 L5,6 L0,6 L-1,1 Z "
      fill="#E7E7E7"
      transform="translate(92,174)"
    />
    <path
      d="M0,0 L6,0 L9,3 L7,13 L6,13 L5,4 L-2,3 Z "
      fill="#5A5A5A"
      transform="translate(57,145)"
    />
    <path
      d="M0,0 L2,0 L6,13 L5,15 L1,15 L1,11 L3,11 L0,2 Z "
      fill="#26517C"
      transform="translate(76,97)"
    />
    <path
      d="M0,0 L4,1 L0,4 L-2,4 L-2,6 L-10,7 L-13,11 L-13,6 L-10,4 L-1,2 Z "
      fill="#276298"
      transform="translate(114,94)"
    />
    <path
      d="M0,0 L5,1 L6,5 L5,6 L0,6 L-1,1 Z "
      fill="#DCDCDC"
      transform="translate(89,147)"
    />
    <path
      d="M0,0 L4,0 L6,4 L5,6 L3,5 L2,8 L0,11 Z "
      fill="#D6D6D6"
      transform="translate(144,148)"
    />
    <path
      d="M0,0 L1,2 L-4,5 L-1,6 L-1,8 L-8,8 L-11,4 Z "
      fill="#C6C9CF"
      transform="translate(164,64)"
    />
    <path
      d="M0,0 L2,1 L1,3 L3,3 L3,10 L1,10 L1,5 L-6,8 L-7,6 L-5,6 L-5,4 L-1,2 Z "
      fill="#E4ECF3"
      transform="translate(56,54)"
    />
    <path
      d="M0,0 L1,4 L1,10 L5,11 L3,14 L1,14 L-2,10 L-1,3 Z "
      fill="#30355B"
      transform="translate(43,71)"
    />
    <path
      d="M0,0 L16,0 L17,3 L4,2 Z "
      fill="#C4DCE8"
      transform="translate(89,40)"
    />
    <path
      d="M0,0 L5,0 L6,4 L5,5 L-1,5 Z "
      fill="#EEEEEE"
      transform="translate(89,155)"
    />
    <path
      d="M0,0 L1,4 L-1,5 L-2,10 L-8,11 L-2,3 Z "
      fill="#263861"
      transform="translate(59,93)"
    />
    <path
      d="M0,0 L1,0 L1,12 L10,10 L9,13 L4,14 L0,13 Z "
      fill="#4A4748"
      transform="translate(124,175)"
    />
    <path
      d="M0,0 L1,0 L1,9 L10,11 L10,13 L3,13 L0,10 Z "
      fill="#535353"
      transform="translate(132,149)"
    />
    <path
      d="M0,0 L14,0 L14,2 L18,3 L16,5 L14,5 L14,3 L10,2 L0,1 Z "
      fill="#B9BED1"
      transform="translate(108,112)"
    />
    <path
      d="M0,0 L5,0 L6,2 L2,4 L0,10 L-1,10 L-1,2 Z "
      fill="#DEDEDE"
      transform="translate(57,148)"
    />
    <path
      d="M0,0 L9,0 L8,3 L-2,3 L0,2 Z "
      fill="#676767"
      transform="translate(84,211)"
    />
    <path
      d="M0,0 L3,1 L7,9 L5,9 L5,7 L0,6 Z "
      fill="#E7E4E5"
      transform="translate(142,179)"
    />
    <path
      d="M0,0 L3,3 L4,9 L2,9 L1,7 L-4,6 L-3,4 L0,4 Z "
      fill="#232323"
      transform="translate(110,153)"
    />
    <path
      d="M0,0 L3,0 L3,11 L2,11 L1,4 L-5,4 Z "
      fill="#364B6E"
      transform="translate(54,59)"
    />
    <path
      d="M0,0 L2,1 L-2,8 L-4,5 L-8,3 Z "
      fill="#322F50"
      transform="translate(86,21)"
    />
    <path
      d="M0,0 L5,0 L5,6 L3,8 L-3,8 L1,6 L2,2 Z "
      fill="#2A2829"
      transform="translate(111,205)"
    />
    <path
      d="M0,0 L2,1 L2,10 L1,12 L-2,12 Z "
      fill="#252525"
      transform="translate(64,149)"
    />
    <path
      d="M0,0 L3,1 L-1,3 L-1,12 L-2,12 L-2,5 L-4,5 L-5,2 L-3,1 L0,2 Z "
      fill="#CCE1EE"
      transform="translate(101,88)"
    />
    <path
      d="M0,0 L3,0 L2,11 L0,11 Z "
      fill="#252525"
      transform="translate(133,147)"
    />
    <path
      d="M0,0 L1,2 L0,9 L-4,8 L-2,2 Z "
      fill="#1E2A5A"
      transform="translate(48,63)"
    />
    <path
      d="M0,0 L10,0 L11,5 L8,5 L7,3 L0,2 Z "
      fill="#323232"
      transform="translate(74,184)"
    />
    <path
      d="M0,0 L3,1 L5,5 L2,5 L1,10 L0,10 Z "
      fill="#C6C6C6"
      transform="translate(56,179)"
    />
    <path
      d="M0,0 L1,0 L0,11 L-2,11 L-2,3 L0,3 Z "
      fill="#BBD2E0"
      transform="translate(71,55)"
    />
    <path
      d="M0,0 L5,5 L6,7 L2,8 L0,4 L-2,2 L0,2 Z "
      fill="#3B393A"
      transform="translate(144,176)"
    />
    <path
      d="M0,0 L3,2 L3,6 L-1,6 L-1,1 Z "
      fill="#E2E2E2"
      transform="translate(77,178)"
    />
    <path
      d="M0,0 L3,2 L3,6 L-1,6 L-1,1 Z "
      fill="#E3E3E3"
      transform="translate(107,151)"
    />
    <path
      d="M0,0 L3,0 L2,9 L0,9 Z "
      fill="#252525"
      transform="translate(54,148)"
    />
    <path
      d="M0,0 L4,1 L3,3 L1,7 L-2,6 Z "
      fill="#464646"
      transform="translate(75,176)"
    />
    <path
      d="M0,0 L4,1 L3,9 L0,8 L2,8 Z "
      fill="#CBCBCB"
      transform="translate(112,154)"
    />
    <path
      d="M0,0 L5,1 L3,3 L3,1 L1,1 L1,4 L-5,4 Z "
      fill="#1C5081"
      transform="translate(129,86)"
    />
    <path
      d="M0,0 L3,1 L3,5 L-1,5 Z "
      fill="#E8E6E6"
      transform="translate(126,203)"
    />
    <path
      d="M0,0 L3,1 L3,5 L-1,5 Z "
      fill="#E2E2E2"
      transform="translate(168,152)"
    />
    <path
      d="M0,0 L2,0 L2,2 L6,0 L7,4 L1,3 L-2,1 Z "
      fill="#313044"
      transform="translate(56,121)"
    />
    <path
      d="M0,0 L1,4 L-1,5 L-8,5 L-5,3 Z "
      fill="#E3E8F3"
      transform="translate(72,35)"
    />
    <path
      d="M0,0 L3,0 L1,7 L-1,8 Z "
      fill="#414141"
      transform="translate(158,145)"
    />
    <path
      d="M0,0 L2,2 L6,3 L-3,5 L-1,1 Z "
      fill="#2A2C52"
      transform="translate(154,64)"
    />
  </svg>
)
export default CzechFnl
