import * as React from 'react'
const Wales = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#C3102F"
    />
    <path
      d="M94 50h6v11l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-7-7 2-2 9-1 4 1h2v-2l-6-1 1-4 6-7h2l2 3 1-3 8-3h4l1 3 8 4-3 4-6 1-6-3v2l4 1v1l-6 1 1 3 4-1h11l6 2v-2l-6-1 4-3 7-6 5 1-2-3-7-3 1-5h4l2 1h7l1-2-5 2v-2h-3l-1-5 8 3 7 1-1-3 10-3Z"
      fill="#04A737"
    />
    <path
      d="m88 17 5 5 6 13 1 4v11l-8 2-10 4 2 2-4 1-11-3v2l3 1h4l-1 3h-7l-5-2-1 5 7 3 1 3h-5l-7 6-2 1 4 2v2h-5l-1-1H43l-5 1v-3l-2-2 7-1-3-1v-2l4 1 5 1 5-3-4-2-4-3v-3l-4-1v-6h5l-3 1v5l3-2v-6l-2-5-3 2 1-5h3l2 2 2 4 1 3h6l-7-8v-3l-1-2 8 1-6-3 1-5 2-1-1-2 5-3 3 1v4l-2 1 13 3h3l3-7 5-4-1-5 5-5Z"
      fill="#BC102E"
    />
    <path
      d="M39 0h22l11 4 10 6 6 7-14 6-4-1-1-5-4 1-12 8-8 7-1 10-9 2v-4l-1-5 3-1-2-1v-3l3-1-2-3 2-1-4-3v-3l2-1v-3l-6 2-11 1-6 2-4-1 7-8 8-6 11-5Z"
      fill="#FBFBFB"
    />
    <path
      d="m36 15 1 4-3 1 2 4 3 3-2 1 2 4-4-1v3l3 1-1 2-3-1 1 3 2 2-2 1-1 3-4-1-2-7-6-1-1 3h-2v4h-2l-1 7H7l-1-8 1-4-2-3v-2H3l1-5 4-7 10-2 1-1 11-1Z"
      fill="#BD152E"
    />
    <path
      d="m2 32 3 1 2 4 2-1-2 9v5h9l1-7h2v-4h2l1-4 7 2 2 7h4v2l-8 5-1 2 4 1 1 4-2 1v-4h-4l-1 2-1-2-10 1v2l-4-1-2-5-2 1 2 4 6 7 2 4 1-3 6-1-2 9-3 3h-6l-6-2-4-9-1-4V39Z"
      fill="#A00B26"
    />
    <path
      d="M94 50h6v11l-4 11-6 10-7-1-12 2 2-3-4-1v-2l2-1-1-3 5 1 1 1h6l1-3v-7l-1-1-11-1 4-2 1-2-5 2v-2h-3l-1-5 8 3 7 1-1-3 10-3Z"
      fill="#BA112E"
    />
    <path
      d="m88 17 5 5 2 4-1 4-4 3 5 1-1 2-7 1v2l4 1-1 3-5-1v-2l-2-1v4l1 1 7 1v2l-2 1H79l-10-1 1-3 7-2-1-1v-6l5-5 2-2-1-5 5-5Z"
      fill="#F1EEEE"
    />
    <path
      d="m95 28 3 4 2 7v11l-8 2-10 4 2 2-3 1-1-4h-2v-2h-2v-2l-4-1v-2l-3-1h22v-2h-7l-2-2v-5h3v2l1 2h4l1-2-4-1-1-3 8-1-5-1 5-5Z"
      fill="#C7112B"
    />
    <path d="M94 50h6v11l-4 11-4 7-4-1 1-15h3l-11-7 4-2Z" fill="#08A638" />
    <path
      d="m2 50 2 2 3 5 6 7 2 4 1-3 6-1-2 9-3 3h-6l-6-2-4-9-1-4v-7Z"
      fill="#06A537"
    />
    <path
      d="M6 29h9l1 1v5h2v-2h2l1-3h3v2h-2l-1 7h-2v4h-2l-1 7H7l-1-8 1-4-2-3v-2H3v-3Z"
      fill="#F4F1F1"
    />
    <path
      d="m36 15 1 4-3 1v2h-4v6l-4 2-3-3H7l-1-3 2-3 10-2 1-1 11-1Z"
      fill="#C4122E"
    />
    <path
      d="m2 32 3 1 2 4 2-1-2 9v5h9l1-2v7l-4 2-2 1-4-4v-2l-3 1-2-2-2 3V39Z"
      fill="#C00E2B"
    />
    <path d="m21 36 4 1-1 6-2 1 2 1v8h-6l-1-1v-9h2v-4h2Z" fill="#CB1433" />
    <path d="m70 73 5 1 6 2-4 1v3l4 1-10 2 2-3-4-1v-2l2-1Z" fill="#992B29" />
    <path d="m53 45 9 3 5 2-4 2-5 2Z" fill="#D00F33" />
    <path d="m44 33 2 3h2l1-2-1 5h2l-2 5h-3l-1 2h-7v-2l7-1Z" fill="#BE2A42" />
    <path d="m98 35 2 4v11h-5l2-14Z" fill="#F2F1F1" />
    <path d="m81 28 3 1-18 5h-4v-3Z" fill="#A90B27" />
    <path d="M6 24h8l4 1 4-1 1 3H7Z" fill="#DDD9D9" />
    <path d="M16 48h1v7l-5-1-3-1-1-3h8Z" fill="#2E983F" />
    <path d="M2 34h1v5l2 1-1 2H2l1 5-3 2V39Z" fill="#CE6F7F" />
    <path d="m86 17 2 1-6 4-5 2-4 1 1-3Z" fill="#A92A36" />
    <path d="M19 18h11v1l-7 1v3l-10-2v-1Z" fill="#A9303D" />
    <path d="m84 36 3 1v2l4 1-1 3-5-1v-2l-2-1Z" fill="#D38692" />
    <path d="M18 75h3v4l-2 1h-7l1-3h4Zm-3 3" fill="#D20A33" />
    <path d="m2 32 3 1 2 4 2-1-1 5-1-2H3Z" fill="#AC333D" />
    <path d="M14 55h3l4 9-4 1 1-2-1-3-2-1Z" fill="#A7142A" />
    <path d="m53 78 7 2v2h-5l-5-2Z" fill="#9D302C" />
    <path d="m42 71 4 2v2l-9-1 1-2Z" fill="#407D2E" />
    <path d="M16 30h5l-1 3h-2v2h-2Z" fill="#C02F43" />
  </svg>
)
export default Wales
