import * as React from 'react'
const Macedonia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#D20200"
    />
    <path
      d="m60 0 10 3 9 5 10 9 6 9 4 9 1 4v22l-4 11-6 10-8 8-10 6-11 4-2-3-6-27v-3h-6v-2l9-2 6-5 2-4v-8l-4-6-6-4-7-1 1-2h5l1-7Z"
      fill="#D20200"
    />
    <path
      d="M39 0h21l-2 12-5 21-5 1 8 2 7 6 2 4v8l-4 6-5 4-2 1h-8l-6-4-4-5-1-2v-8l2-5-5 1L6 29l-1-3 6-9 7-7 10-6Z"
      fill="#D20200"
    />
    <path
      d="M46 35h8l6 4 4 5 1 2v8l-4 6-5 4-2 1h-8l-6-4-4-5-1-2v-8l4-6 5-4Z"
      fill="#FDE000"
    />
    <path d="M47 67h6l6 24 1 9H40l2-13Z" fill="#FCDC00" />
    <path d="M40 0h20l-2 12-5 21h-6L40 4Z" fill="#FCDE00" />
    <path d="M34 47h1l2 9 1 3h-6L6 72H4L0 61v-6l29-3h4Z" fill="#D10100" />
    <path d="m65 47 2 1v4l33 3-1 10-3 7-4-1-24-12-5-1Z" fill="#D10000" />
    <path d="M89 45h11v10H89l-20-2-2-1v-4l2-1Z" fill="#F8D000" />
    <path
      d="M0 45h9l20 2 4 1v4l-14 2-10 1H0Zm65 13 5 1 22 11 3 2-2 6-3 2-24-16-2-2Z"
      fill="#FAD700"
    />
    <path
      d="m32 58 4 1-1 4-19 13-7 4-4-6 1-3Zm58-38 4 4 1 4-23 12-4 2-4-1 1-4 16-11ZM9 20l7 4 16 11 4 3-1 4-5-1L6 29l-1-3Z"
      fill="#FAD800"
    />
  </svg>
)
export default Macedonia
