import * as React from 'react'
const Norway = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#00206A"
    />
    <path
      d="M41 56h59l-1 9-7 14-9 10-9 6-9 4-4 1H41Zm0-56h20l11 4 10 6 8 8 6 10 4 11v5H41Z"
      fill="#FF0018"
    />
    <path
      d="M0 56h29v40l-8-4-10-9-6-9-4-9ZM28 4h1v40H0l1-9 7-14 9-10 9-6Z"
      fill="#F8EFF1"
    />
    <path d="M41 56h59l-1 7H48v37h-7Zm0-56h7v37h51l1 7H41Z" fill="#F8EFF0" />
    <path
      d="M1 63h21v29l-6-4-9-10-6-13ZM21 8h1v29H1l3-9 6-10 8-8Z"
      fill="#FF0016"
    />
  </svg>
)
export default Norway
