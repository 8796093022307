import React from 'react'
const FormulaRed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={84}
    viewBox="0 0 150 84"
    {...props}
  >
    <path
      d="M 45.267 24.054 C 42.939 24.514, 39.339 25.685, 37.267 26.657 C 33.659 28.349, 2 58.425, 2 60.161 C 2 60.622, 6.573 61, 12.162 61 L 22.325 61 32.997 50.593 C 40.860 42.925, 44.700 39.899, 47.585 39.098 C 49.980 38.433, 62.571 38.009, 80.027 38.006 L 108.554 38 115.277 31.225 C 118.975 27.499, 122 24.124, 122 23.725 C 122 22.718, 50.480 23.026, 45.267 24.054 M 107.240 41.260 C 97.208 51.303, 89 59.853, 89 60.260 C 89 60.667, 94.067 61, 100.260 61 L 111.520 61 129.760 42.740 C 139.792 32.697, 148 24.147, 148 23.740 C 148 23.333, 142.933 23, 136.740 23 L 125.480 23 107.240 41.260 M 48.148 40.145 C 44.953 41.065, 26 58.181, 26 60.147 C 26 60.616, 30.337 61, 35.638 61 L 45.276 61 49.616 57 L 53.955 53 73.757 53 L 93.558 53 99.779 46.723 C 103.200 43.271, 106 40.121, 106 39.723 C 106 38.637, 52.019 39.031, 48.148 40.145"
      stroke="none"
      fill="#ff1c04"
      fillRule="evenodd"
    />
    <path d="" stroke="none" fill="#fc1c04" fillRule="evenodd" />
  </svg>
)
export default FormulaRed
