import React from 'react'
const League1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={127}
    viewBox="0 0 150 127"
    {...props}
  >
    <path
      d="M0 0 C49.5 0 99 0 150 0 C150 41.91 150 83.82 150 127 C100.5 127 51 127 0 127 C0 85.09 0 43.18 0 0 Z "
      fill="#FDFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0 0 C-0.79 1.46 -1.58 2.92 -2.38 4.38 C-2.82 5.19 -3.26 6 -3.71 6.84 C-5 9 -5 9 -7 11 C-9.06 10.98 -11.12 10.95 -13.17 10.87 C-20.29 10.52 -20.29 10.52 -26.6 13.27 C-29.49 16.79 -31.23 20.82 -33 25 C-33.66 25.99 -34.32 26.98 -35 28 C-34.62 33.06 -31.93 36.49 -29.18 40.6 C-28 43 -28 43 -28.16 45.04 C-29.11 47.27 -30.24 49.22 -31.56 51.25 C-32.02 51.96 -32.47 52.66 -32.94 53.39 C-33.29 53.92 -33.64 54.45 -34 55 C-37.77 51.62 -40.11 47.83 -42.62 43.5 C-43.02 42.84 -43.41 42.17 -43.82 41.49 C-44.9 39.67 -45.95 37.84 -47 36 C-47.42 35.29 -47.84 34.58 -48.27 33.84 C-49.27 31.32 -49.34 29.7 -49 27 C-47.07 22.74 -44.62 18.85 -42.12 14.91 C-41.03 13.05 -40.18 11.18 -39.33 9.2 C-32.23 -5.2 -13.62 -1.05 0 0 Z "
      fill="#0F295F"
      transform="translate(87,18)"
    />
    <path
      d="M0 0 C6.43 6.06 12.24 13.5 15 22 C14.79 27.34 12.5 31.31 9.88 35.81 C9.47 36.52 9.06 37.22 8.65 37.94 C0.37 52.06 0.37 52.06 -5.04 54.21 C-9.87 55.4 -14.74 55.29 -19.69 55.25 C-21.09 55.26 -21.09 55.26 -22.53 55.27 C-23.43 55.27 -24.33 55.27 -25.26 55.27 C-26.48 55.26 -26.48 55.26 -27.73 55.26 C-30.21 54.98 -31.85 54.24 -34 53 C-32.57 49.31 -31.01 46.61 -28 44 C-23.78 43.14 -19.87 42.97 -15.58 43 C-12.04 43.19 -12.04 43.19 -9 42 C-6.93 39.36 -6.93 39.36 -4.94 36.19 C-4.25 35.13 -3.55 34.07 -2.84 32.98 C-1.93 31.51 -1.93 31.51 -1 30 C-0.57 29.32 -0.14 28.63 0.31 27.93 C1.28 25.23 0.87 23.72 0 21 C-1.49 18.38 -3.14 15.91 -4.82 13.4 C-6 11 -6 11 -5.84 8.93 C-4.91 6.8 -3.83 5.1 -2.44 3.25 C-1.98 2.64 -1.53 2.02 -1.06 1.39 C-0.53 0.7 -0.53 0.7 0 0 Z "
      fill="#0F295F"
      transform="translate(92,28)"
    />
    <path
      d="M0 0 C3.99 0.3 6.19 1.73 9 4.62 C10.66 7.95 10.38 11.28 9.38 14.81 C7.3 17.55 5.2 18.46 2 19.62 C-2.36 19.71 -3.66 18.94 -6.88 15.94 C-9.23 12.26 -9.89 10.88 -9 6.62 C-6.59 2.56 -4.79 0.25 0 0 Z "
      fill="#0E295F"
      transform="translate(72,40.375)"
    />
    <path
      d="M0 0 C1.67 0.06 1.67 0.06 3.38 0.12 C3.38 0.78 3.38 1.44 3.38 2.12 C1.39 2.45 -0.59 2.78 -2.62 3.12 C-2.62 4.44 -2.62 5.77 -2.62 7.12 C-1.63 7.12 -0.64 7.12 0.38 7.12 C0.7 6.47 1.03 5.81 1.38 5.12 C2.03 5.12 2.69 5.12 3.38 5.12 C3.38 6.78 3.38 8.42 3.38 10.12 C1.19 10.88 1.19 10.88 -1.62 11.12 C-4.44 9.19 -4.44 9.19 -6.62 7.12 C-5.84 2.16 -5.49 0.19 0 0 Z "
      fill="#173065"
      transform="translate(63.625,95.875)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C3.33 2.31 3.66 4.62 4 7 C4.99 7 5.98 7 7 7 C7.33 4.69 7.66 2.38 8 0 C8.66 0 9.32 0 10 0 C10.12 7.75 10.12 7.75 9 10 C5.62 10.19 5.62 10.19 2 10 C-0.43 6.36 -0.16 4.29 0 0 Z "
      fill="#122C61"
      transform="translate(70,96)"
    />
    <path
      d="M0 0 C2.31 0 4.62 0 7 0 C7 0.66 7 1.32 7 2 C5.68 2 4.36 2 3 2 C3 2.66 3 3.32 3 4 C3.99 4 4.98 4 6 4 C6 4.66 6 5.32 6 6 C5.01 6 4.02 6 3 6 C3 6.66 3 7.32 3 8 C4.65 8.33 6.3 8.66 8 9 C8 9.33 8 9.66 8 10 C5.36 10 2.72 10 0 10 C0 6.7 0 3.4 0 0 Z "
      fill="#142C62"
      transform="translate(84,96)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1 1.98 1 3.96 1 6 C0.34 6 -0.32 6 -1 6 C-1.33 6.99 -1.66 7.98 -2 9 C-2 7.35 -2 5.7 -2 4 C-2.66 4 -3.32 4 -4 4 C-4.33 4.99 -4.66 5.98 -5 7 C-6.32 6.67 -7.64 6.34 -9 6 C-8.67 4.35 -8.34 2.7 -8 1 C-6.87 1.02 -5.73 1.04 -4.56 1.06 C-1.18 1.42 -1.18 1.42 0 0 Z "
      fill="#E0DAE6"
      transform="translate(69,97)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C3.08 1.11 3.16 2.23 3.25 3.38 C3.58 7.06 3.58 7.06 6.06 8.44 C6.7 8.62 7.34 8.81 8 9 C8 9.33 8 9.66 8 10 C5.36 10 2.72 10 0 10 C0 6.7 0 3.4 0 0 Z "
      fill="#132B61"
      transform="translate(42,96)"
    />
    <path
      d="M0 0 C0 3.3 0 6.6 0 10 C-0.99 9.67 -1.98 9.34 -3 9 C-3 7.35 -3 5.7 -3 4 C-3.66 4 -4.32 4 -5 4 C-5 3.01 -5 2.02 -5 1 C-3 0 -3 0 0 0 Z "
      fill="#152F64"
      transform="translate(103,96)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2.33 3.3 2.66 6.6 3 10 C2.01 10 1.02 10 0 10 C0 6.7 0 3.4 0 0 Z "
      fill="#132D62"
      transform="translate(52,96)"
    />
  </svg>
)
export default League1
