import * as React from 'react'
const Chips = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={31.68}
    height={32}
    viewBox="0 0 31.68 32"
    {...props}
  >
    <defs>
      <linearGradient
        id="GradientFill_1"
        data-name="GradientFill 1"
        x1={28.97}
        y1={24.86}
        x2={2.84}
        y2={7.23}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#7f0000" />
        <stop offset={1} stopColor="#ed1c24" />
      </linearGradient>
      <radialGradient
        id="GradientFill_2"
        data-name="GradientFill 2"
        cx={22.71}
        cy={29.12}
        fx={22.71}
        fy={29.12}
        r={36.49}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ed1c24" />
        <stop offset={0.22} stopColor="#e21921" />
        <stop offset={0.6} stopColor="#c8141a" />
        <stop offset={1} stopColor="#a50d12" />
      </radialGradient>
      <linearGradient
        id="GradientFill_3"
        data-name="GradientFill 3"
        x1={5.25}
        y1={4.24}
        x2={22.97}
        y2={23.92}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#e7e8e9" />
        <stop offset={0.11} stopColor="#ebeced" />
        <stop offset={0.6} stopColor="#f9fafa" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <radialGradient
        id="GradientFill_2-2"
        data-name="GradientFill 2"
        cx={20.45}
        cy={24.79}
        fx={20.45}
        fy={24.79}
        r={24.46}
        xlinkHref="#GradientFill_2"
      />
      <radialGradient
        id="GradientFill_4"
        data-name="GradientFill 4"
        cx={20.6}
        cy={23.06}
        fx={20.6}
        fy={23.06}
        r={20.9}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ed1c24" />
        <stop offset={0.67} stopColor="#ce161d" />
        <stop offset={1} stopColor="#bd141a" />
      </radialGradient>
      <linearGradient
        id="GradientFill_5"
        data-name="GradientFill 5"
        x1={24.22}
        y1={28.91}
        x2={7.54}
        y2={3.21}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#808285" />
        <stop offset={0.06} stopColor="#8a8c8f" />
        <stop offset={0.37} stopColor="#bcbdbf" />
        <stop offset={0.64} stopColor="#e0e1e1" />
        <stop offset={0.86} stopColor="#f6f7f7" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="GradientFill_6"
        data-name="GradientFill 6"
        x1={3.84}
        y1={4.81}
        x2={27.63}
        y2={26.99}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#e7e8e9" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <radialGradient
        id="GradientFill_7"
        data-name="GradientFill 7"
        cx={17.79}
        cy={18.6}
        fx={17.79}
        fy={18.6}
        r={14.72}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={0.28} stopColor="#f8f8f8" />
        <stop offset={0.68} stopColor="#e5e6e7" />
        <stop offset={1} stopColor="#d1d3d4" />
      </radialGradient>
      <radialGradient
        id="GradientFill_2-3"
        data-name="GradientFill 2"
        cx={15.8}
        cy={15.5}
        fx={15.8}
        fy={15.5}
        r={2.85}
        xlinkHref="#GradientFill_2"
      />
      <radialGradient
        id="GradientFill_2-4"
        data-name="GradientFill 2"
        cx={15.8}
        cy={12.41}
        fx={15.8}
        fy={12.41}
        r={0.29}
        xlinkHref="#GradientFill_2"
      />
      <radialGradient
        id="GradientFill_2-5"
        data-name="GradientFill 2"
        cx={17.9}
        cy={13.4}
        fx={17.9}
        fy={13.4}
        r={0.29}
        xlinkHref="#GradientFill_2"
      />
      <radialGradient
        id="GradientFill_2-6"
        data-name="GradientFill 2"
        cx={19.14}
        cy={14.56}
        fx={19.14}
        fy={14.56}
        r={0.29}
        xlinkHref="#GradientFill_2"
      />
      <radialGradient
        id="GradientFill_2-7"
        data-name="GradientFill 2"
        cx={13.7}
        cy={13.4}
        fx={13.7}
        fy={13.4}
        r={0.29}
        xlinkHref="#GradientFill_2"
      />
      <radialGradient
        id="GradientFill_2-8"
        data-name="GradientFill 2"
        cx={12.46}
        cy={14.56}
        fx={12.46}
        fy={14.56}
        r={0.29}
        xlinkHref="#GradientFill_2"
      />
      <radialGradient
        id="radial-gradient"
        cx={22.55}
        cy={27.48}
        fx={22.55}
        fy={27.48}
        r={28.44}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.31} stopColor="#fff" />
        <stop offset={0.37} stopColor="#d7d7d7" />
        <stop offset={0.58} stopColor="#646464" />
        <stop offset={0.72} stopColor="#1c1c1c" />
        <stop offset={0.78} stopColor="#000" />
      </radialGradient>
    </defs>
    <g isolation="isolate" className="cls-20">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <path
              className="cls-12"
              strokeWidth="0px"
              fillRule="evenodd"
              fill="url(#GradientFill_1)"
              d="M15.84.16c8.75,0,15.84,7.09,15.84,15.84s-7.09,15.84-15.84,15.84S0,24.75,0,16,7.09.16,15.84.16Z"
            />
            <path
              className="cls-3"
              fillRule="evenodd"
              strokeWidth="0px"
              fill=" url(#GradientFill_2)"
              d="M15.84,31.62c8.6,0,15.62-7.02,15.62-15.62S24.44.38,15.84.38.22,7.4.22,16s7.01,15.62,15.62,15.62Z"
            />
            <path
              className="cls-5"
              strokeWidth="0px"
              fill="#5b0000"
              d="M15.98,5.3c5.99,0,10.84,4.86,10.84,10.84s-4.85,10.84-10.84,10.84-10.84-4.85-10.84-10.84S10,5.3,15.98,5.3Z"
            />
            <path
              className="cls-2"
              strokeWidth="0px"
              fillRule="evenodd"
              fill="url(#GradientFill_3)"
              d="M15.84,5.16c5.99,0,10.84,4.85,10.84,10.84s-4.85,10.84-10.84,10.84-10.84-4.85-10.84-10.84S9.85,5.16,15.84,5.16Z"
            />
            <path
              className="cls-5"
              strokeWidth="0px"
              fillRule="evenodd"
              fill="#5b0000"
              d="M14.06,6.89l-.22-1.23c.6-.11,1.21-.16,1.82-.16s1.22.05,1.82.16l-.22,1.23c-.52-.09-1.05-.14-1.6-.14s-1.08.05-1.6.14h0ZM18.82,7.3l.43-1.18c.57.21,1.13.47,1.65.77.53.3,1.03.65,1.49,1.05l-.81.96c-.81-.68-1.75-1.23-2.77-1.6h0ZM22.72,10.04l.97-.82c.39.47.74.97,1.04,1.49.31.53.56,1.08.77,1.65l-1.19.43c-.37-1.02-.92-1.95-1.6-2.76h0ZM24.74,14.35l1.25-.22c.1.6.16,1.21.16,1.82,0,.61-.05,1.22-.16,1.82l-1.25-.22c.09-.51.14-1.04.14-1.58s-.05-1.09-.14-1.62h0ZM24.34,19.1l1.19.43c-.21.57-.47,1.13-.77,1.65-.3.53-.65,1.03-1.04,1.49l-.97-.81c.68-.82,1.22-1.75,1.59-2.77h0ZM21.62,23.01l.81.97c-.46.39-.97.74-1.49,1.05-.53.31-1.08.56-1.65.77l-.43-1.18c1.02-.38,1.95-.93,2.76-1.61h0ZM17.3,25.04l.22,1.24c-.6.11-1.21.16-1.82.16s-1.22-.05-1.82-.16l.22-1.22c.51.09,1.03.13,1.56.13.56,0,1.11-.05,1.64-.15h0ZM12.54,24.65l-.43,1.17c-.57-.21-1.13-.47-1.65-.77-.53-.3-1.03-.66-1.49-1.05l.79-.95c.82.68,1.76,1.23,2.78,1.59h0ZM8.62,21.92l-.95.8c-.39-.46-.74-.97-1.05-1.5-.31-.53-.56-1.08-.77-1.65l1.15-.42c.37,1.02.93,1.96,1.61,2.77h0ZM6.59,17.59l-1.21.22c-.11-.6-.16-1.21-.16-1.82s.05-1.22.16-1.82l1.21.21c-.09.52-.14,1.05-.14,1.59s.05,1.1.14,1.62h0ZM6.99,12.83l-1.16-.42c.21-.57.47-1.13.77-1.65.3-.53.66-1.03,1.05-1.49l.95.79c-.69.82-1.23,1.75-1.6,2.78h0ZM9.73,8.91l-.8-.95c.47-.39.97-.74,1.49-1.05.53-.31,1.08-.56,1.65-.77l.42,1.17c-1.02.37-1.96.92-2.77,1.61Z"
            />
            <path
              className="cls-10"
              strokeWidth="0px"
              fillRule="evenodd"
              fill="url(#GradientFill_2-2)"
              d="M14.22,6.92l-.22-1.23c.6-.11,1.21-.16,1.82-.16s1.22.05,1.82.16l-.22,1.23c-.52-.09-1.05-.14-1.6-.14s-1.08.05-1.6.14h0ZM18.97,7.34l.43-1.18c.57.21,1.13.47,1.65.77.53.3,1.03.65,1.49,1.05l-.81.96c-.81-.68-1.75-1.23-2.77-1.6h0ZM22.88,10.07l.97-.82c.39.47.74.97,1.04,1.49.31.53.56,1.08.77,1.65l-1.19.43c-.37-1.02-.92-1.95-1.6-2.76h0ZM24.9,14.38l1.25-.22c.1.6.16,1.21.16,1.82,0,.61-.05,1.22-.16,1.82l-1.25-.22c.09-.51.14-1.04.14-1.58s-.05-1.09-.14-1.62h0ZM24.49,19.13l1.19.43c-.21.57-.47,1.13-.77,1.65-.3.53-.65,1.03-1.04,1.49l-.97-.81c.68-.82,1.22-1.75,1.59-2.77h0ZM21.77,23.04l.81.97c-.46.39-.97.74-1.49,1.04-.53.31-1.08.56-1.65.77l-.43-1.18c1.02-.38,1.95-.93,2.76-1.61h0ZM17.46,25.08l.22,1.24c-.6.11-1.21.16-1.82.16s-1.22-.05-1.82-.16l.22-1.22c.51.09,1.03.13,1.56.13.56,0,1.11-.05,1.64-.15h0ZM12.7,24.68l-.43,1.17c-.57-.21-1.13-.47-1.65-.77-.53-.3-1.03-.66-1.49-1.05l.79-.95c.82.68,1.76,1.23,2.78,1.59h0ZM8.78,21.95l-.95.8c-.39-.47-.74-.97-1.05-1.5-.31-.53-.56-1.08-.77-1.65l1.15-.42c.37,1.02.93,1.96,1.61,2.77h0ZM6.74,17.62l-1.21.22c-.11-.6-.16-1.21-.16-1.82s.05-1.22.16-1.82l1.21.21c-.09.52-.14,1.05-.14,1.59s.05,1.1.14,1.62h0ZM7.15,12.86l-1.16-.42c.21-.57.47-1.13.77-1.65.3-.53.66-1.03,1.05-1.49l.95.79c-.69.82-1.23,1.75-1.6,2.78h0ZM9.89,8.94l-.8-.95c.47-.39.97-.74,1.49-1.05.53-.31,1.08-.56,1.65-.77l.42,1.17c-1.02.37-1.96.92-2.77,1.61Z"
            />
            <path
              className="cls-18"
              strokeWidth="0px"
              fillRule="evenodd"
              fill="url(#GradientFill_4)"
              d="M15.84,24.82c4.86,0,8.82-3.96,8.82-8.82s-3.96-8.82-8.82-8.82-8.82,3.96-8.82,8.82,3.96,8.82,8.82,8.82Z"
            />
            <g>
              <g>
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M23.06,3.8c.35.21.48.65.27,1.01-.2.35-.66.47-1.01.27-.35-.2-.48-.66-.27-1.01.2-.35.66-.47,1.01-.27Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M30.17,16.12c0,.41-.33.74-.74.74s-.74-.33-.74-.74.33-.74.74-.74.74.33.74.74Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M23.06,28.43c-.35.2-.81.08-1.01-.27-.2-.35-.08-.8.27-1.01.35-.2.81-.08,1.01.27.2.35.08.81-.27,1.01Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M8.85,28.43c-.36-.21-.48-.66-.27-1.01.2-.35.66-.48,1.01-.27.35.21.47.66.27,1.01-.2.36-.65.48-1.01.27Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M1.74,16.12c0-.41.33-.74.74-.74s.74.33.74.74-.33.74-.74.74-.74-.33-.74-.74Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M8.85,3.8c.35-.2.81-.08,1.01.27.2.35.08.81-.27,1.01-.35.2-.81.09-1.01-.27-.2-.36-.08-.81.27-1.01Z"
                />
              </g>
              <g>
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M15.95,4.35c-.69,0-1.03.04-1.8.14-.82.11-1.39-.43-1.55-1.12l-.7-2.89c1.26-.32,2.58-.49,3.94-.49,1.44,0,2.83.19,4.15.55l-.69,2.83c-.17.69-.73,1.23-1.55,1.12-.77-.1-1.1-.14-1.8-.14Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M26.14,10.23c-.35-.6-.55-.87-1.03-1.49-.51-.66-.32-1.42.19-1.9l2.04-1.95c1.86,1.93,3.24,4.31,3.95,6.98l-2.64.78c-.68.2-1.43-.01-1.75-.78-.29-.72-.43-1.03-.77-1.63Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M26.14,22c.35-.6.48-.91.77-1.63.32-.77,1.07-.98,1.75-.78l2.58.76c-.74,2.63-2.14,4.99-4.01,6.89l-1.93-1.84c-.51-.49-.7-1.24-.19-1.9.47-.61.68-.88,1.03-1.48Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M15.95,27.88c.7,0,1.03-.04,1.8-.14.82-.11,1.39.43,1.55,1.12l.63,2.61c-1.31.35-2.68.53-4.1.53-1.34,0-2.64-.17-3.88-.48l.65-2.67c.17-.69.73-1.23,1.55-1.12.77.1,1.1.14,1.8.14Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M5.77,22c.35.6.55.87,1.02,1.48.51.66.32,1.42-.19,1.9l-2.05,1.96c-1.91-1.9-3.34-4.28-4.1-6.94l2.79-.82c.68-.2,1.43.01,1.75.78.29.72.42,1.03.77,1.63Z"
                />
                <path
                  className="cls-16"
                  strokeWidth="0px"
                  fill="#790000"
                  d="M5.77,10.23c-.35.6-.48.91-.77,1.63-.32.77-1.07.98-1.75.78l-2.85-.84c.73-2.69,2.14-5.1,4.04-7.03l2.17,2.06c.51.49.7,1.25.19,1.9-.47.61-.68.88-1.02,1.49Z"
                />
              </g>
            </g>
            <g>
              <path
                className="cls-1"
                fill="#fff"
                strokeWidth="0px"
                fillRule="evenodd"
                d="M22.95,3.69c.35.2.47.66.27,1.01-.2.35-.66.48-1.01.27-.35-.2-.47-.66-.27-1.01.2-.35.66-.48,1.01-.27Z"
              />
              <path
                className="cls-1"
                fill="#fff"
                strokeWidth="0px"
                fillRule="evenodd"
                d="M30.06,16c0,.41-.33.74-.74.74s-.74-.33-.74-.74.33-.74.74-.74.74.33.74.74Z"
              />
              <path
                className="cls-1"
                fill="#fff"
                strokeWidth="0px"
                fillRule="evenodd"
                d="M22.95,28.31c-.35.2-.81.08-1.01-.27-.2-.35-.08-.81.27-1.01.35-.2.81-.08,1.01.27.2.35.08.81-.27,1.01Z"
              />
              <path
                className="cls-1"
                fill="#fff"
                strokeWidth="0px"
                fillRule="evenodd"
                d="M8.73,28.31c-.35-.2-.47-.66-.27-1.01.21-.35.66-.47,1.01-.27.36.21.48.66.27,1.01-.21.35-.66.47-1.01.27Z"
              />
              <path
                className="cls-1"
                fill="#fff"
                strokeWidth="0px"
                fillRule="evenodd"
                d="M1.62,16c0-.41.33-.74.74-.74s.74.33.74.74-.33.74-.74.74-.74-.33-.74-.74Z"
              />
              <path
                className="cls-1"
                fill="#fff"
                strokeWidth="0px"
                fillRule="evenodd"
                d="M8.73,3.69c.35-.2.81-.08,1.01.27.21.35.09.8-.27,1.01-.35.21-.81.08-1.01-.27-.2-.35-.08-.81.27-1.01Z"
              />
            </g>
            <path
              className="cls-4"
              strokeWidth="0px"
              fillRule="evenodd"
              fill="url(#GradientFill_5)"
              d="M15.84,4.24c-.69,0-1.03.04-1.8.14-.82.11-1.38-.43-1.55-1.12l-.67-2.75c1.28-.33,2.63-.51,4.02-.51s2.73.18,4.02.51l-.67,2.75c-.17.69-.73,1.23-1.55,1.12-.77-.1-1.1-.14-1.8-.14h0ZM26.03,10.12c-.35-.6-.55-.87-1.03-1.48-.51-.66-.32-1.42.2-1.9l2.05-1.95c1.88,1.91,3.29,4.3,4.02,6.96l-2.72.8c-.67.2-1.43-.01-1.75-.78-.29-.72-.43-1.03-.77-1.63h0ZM26.03,21.88c.35-.6.48-.91.77-1.63.32-.77,1.07-.98,1.75-.78l2.72.8c-.74,2.66-2.14,5.04-4.02,6.96l-2.05-1.95c-.51-.49-.7-1.25-.2-1.91.48-.61.68-.88,1.03-1.49h0ZM15.84,27.76c.7,0,1.03-.04,1.8-.14.83-.11,1.39.43,1.55,1.12l.67,2.75c-1.28.33-2.63.51-4.02.51s-2.73-.18-4.02-.51l.67-2.75c.17-.69.73-1.23,1.55-1.12.77.1,1.1.14,1.8.14h0ZM5.65,21.88c.35.6.55.87,1.02,1.49.51.66.32,1.42-.19,1.91l-2.05,1.95c-1.88-1.91-3.29-4.3-4.02-6.96l2.72-.8c.68-.2,1.43.01,1.75.78.29.72.43,1.03.77,1.63h0ZM5.65,10.12c-.35.6-.48.91-.77,1.63-.31.77-1.07.98-1.75.78l-2.72-.8c.73-2.66,2.14-5.05,4.02-6.96l2.05,1.95c.51.49.7,1.25.19,1.9-.47.61-.68.88-1.02,1.48Z"
            />
            <path
              className="cls-13"
              strokeWidth="0px"
              fillRule="evenodd"
              fill="url(#GradientFill_6)"
              d="M26.03,10.12c-.35-.6-.55-.87-1.03-1.48-.51-.66-.32-1.42.2-1.9l1.86-1.78c1.85,1.88,3.24,4.23,3.96,6.85l-2.47.73c-.67.2-1.43-.01-1.75-.78-.29-.72-.43-1.03-.77-1.63h0ZM26.03,21.88c.35-.6.48-.91.77-1.63.32-.77,1.07-.98,1.75-.78l2.47.73c-.72,2.62-2.11,4.97-3.96,6.85l-1.86-1.77c-.51-.49-.7-1.25-.2-1.91.48-.61.68-.88,1.03-1.49h0ZM15.84,27.76c.7,0,1.03-.04,1.8-.14.83-.11,1.39.43,1.55,1.12l.61,2.5c-1.27.33-2.59.5-3.96.5s-2.69-.17-3.96-.5l.61-2.5c.17-.69.73-1.23,1.55-1.12.77.1,1.1.14,1.8.14h0ZM5.65,21.88c.35.6.55.87,1.02,1.49.51.66.32,1.42-.19,1.91l-1.86,1.77c-1.85-1.88-3.24-4.23-3.96-6.85l2.47-.73c.68-.2,1.43.01,1.75.78.29.72.43,1.03.77,1.63h0ZM5.65,10.12c-.35.6-.48.91-.77,1.63-.31.77-1.07.98-1.75.78l-2.47-.73c.72-2.62,2.11-4.97,3.96-6.85l1.86,1.78c.51.49.7,1.25.19,1.9-.47.61-.68.88-1.02,1.48h0ZM15.84,4.24c-.69,0-1.03.04-1.8.14-.82.11-1.38-.43-1.55-1.12l-.61-2.5c1.26-.33,2.59-.5,3.96-.5s2.69.17,3.96.5l-.61,2.5c-.17.69-.73,1.23-1.55,1.12-.77-.1-1.1-.14-1.8-.14Z"
            />
            <path
              className="cls-15"
              strokeWidth="0px"
              fillRule="evenodd"
              fill="url(#GradientFill_7)"
              d="M15.84,24.13c4.48,0,8.13-3.65,8.13-8.13s-3.65-8.13-8.13-8.13-8.13,3.65-8.13,8.13,3.65,8.13,8.13,8.13Z"
            />
            <path
              className="cls-16"
              strokeWidth="0px"
              fillRule="evenodd"
              fill="#790000"
              d="M15.84,7.18c2.68,0,5.08,1.2,6.7,3.09-1.62-1.82-3.97-2.97-6.6-2.97-4.87,0-8.82,3.95-8.82,8.82,0,2.19.8,4.19,2.12,5.73-1.38-1.56-2.22-3.6-2.22-5.85,0-4.87,3.95-8.82,8.82-8.82Z"
            />
            <g>
              <g>
                <path
                  className="cls-11"
                  strokeWidth="0px"
                  fillRule="evenodd"
                  fill="#000"
                  d="M15.72,12.78l.6,1.89,1.28-.95-.23,1.7,1.39-.62c-.41.85-.88,1.89-.89,3.26h-4.31c0-1.37-.48-2.41-.89-3.26l1.38.62-.23-1.7,1.28.95.6-1.89Z"
                />
                <g>
                  <path
                    className="cls-11"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="#000"
                    d="M15.72,12.62c.16,0,.29-.13.29-.29s-.13-.29-.29-.29-.29.13-.29.29.13.29.29.29Z"
                  />
                  <path
                    className="cls-11"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="#000"
                    d="M17.82,13.61c.16,0,.29-.13.29-.29s-.13-.28-.29-.28-.29.13-.29.28.13.29.29.29Z"
                  />
                  <path
                    className="cls-11"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="#000"
                    d="M19.06,14.77c.16,0,.29-.13.29-.29s-.13-.29-.29-.29-.29.13-.29.29.13.29.29.29Z"
                  />
                  <path
                    className="cls-11"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="#000"
                    d="M13.62,13.61c.16,0,.29-.13.29-.29s-.13-.28-.29-.28-.29.13-.29.28.13.29.29.29Z"
                  />
                  <path
                    className="cls-11"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="#000"
                    d="M12.38,14.77c.16,0,.29-.13.29-.29s-.13-.29-.29-.29c-.16,0-.28.13-.28.29s.13.29.28.29Z"
                  />
                </g>
              </g>
              <g>
                <path
                  className="cls-8"
                  strokeWidth="0px"
                  fillRule="evenodd"
                  fill="url(#GradientFill_2-3)"
                  d="M15.8,12.86l.6,1.89,1.28-.95-.23,1.7,1.39-.62c-.41.85-.88,1.89-.89,3.26h-4.31c0-1.37-.48-2.41-.89-3.26l1.38.62-.23-1.7,1.28.95.6-1.89Z"
                />
                <g>
                  <path
                    className="cls-19"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="url(#GradientFill_2-4)"
                    d="M15.8,12.7c.16,0,.29-.13.29-.29s-.13-.29-.29-.29-.29.13-.29.29.13.29.29.29Z"
                  />
                  <path
                    className="cls-6"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="url(#GradientFill_2-5)"
                    d="M17.9,13.69c.16,0,.29-.13.29-.29s-.13-.28-.29-.28-.29.13-.29.28.13.29.29.29Z"
                  />
                  <path
                    className="cls-9"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="url(#GradientFill_2-6)"
                    d="M19.14,14.85c.16,0,.29-.13.29-.29s-.13-.29-.29-.29-.29.13-.29.29.13.29.29.29Z"
                  />
                  <path
                    className="cls-17"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="url(#GradientFill_2-7)"
                    d="M13.7,13.69c.16,0,.29-.13.29-.29s-.13-.28-.29-.28-.29.13-.29.28.13.29.29.29Z"
                  />
                  <path
                    className="cls-14"
                    strokeWidth="0px"
                    fillRule="evenodd"
                    fill="url(#GradientFill_2-8)"
                    d="M12.46,14.85c.16,0,.29-.13.29-.29s-.13-.29-.29-.29c-.16,0-.28.13-.28.29s.13.29.28.29Z"
                  />
                </g>
              </g>
            </g>
            <path
              className="cls-7"
              strokeWidth="0px"
              fill="url(#radial-gradient)"
              style={{ mixBlendMode: 'screen' }}
              d="M25.39,13.7c2.03,0,4,.25,5.89.71C30.47,6.59,23.85.49,15.81.49,7.21.49.25,7.46.25,16.05c0,3.82,1.38,7.31,3.66,10.02,4.31-7.39,12.31-12.37,21.48-12.37Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default Chips
