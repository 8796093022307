import * as React from 'react'
const SecondWinner = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={32}
    height={32}
    xmlSpace="preserve"
    stroke="currentColor"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M311.652 211.478V100.174H200.348v33.391h77.913v44.522h-77.913v100.174h111.304V244.87h-77.913v-33.392zM166.957 0h178.087v33.391H166.957zm0 345.043h178.087v33.391H166.957zM100.174 33.391v66.783h33.391V66.783h33.392V33.391zm-33.391 66.783h33.391v178.087H66.783zm278.26-66.783v33.392h33.392v33.391h33.391V33.391zm66.783 66.783h33.391v178.087h-33.391zM166.957 345.043v-33.391h-33.392v-33.391h-33.391V512h66.783v-33.391h-33.392V345.043z"
    />
    <path
      stroke="currentColor"
      d="M378.435 278.261v33.391h-33.392v33.391h33.392v133.566h-33.392V512h66.783V278.261zM166.957 445.217h33.391v33.391h-33.391zm111.304 33.392h33.391V512h-33.391zm-77.913 0h33.391V512h-33.391zm33.391-33.392h44.522v33.391h-44.522zm77.913 0h33.391v33.391h-33.391z"
    />
  </svg>
)
export default SecondWinner
