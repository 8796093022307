import * as React from 'react'
const Latvia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#9E3139"
    />
    <path
      d="M0 60h100l-3 10-5 9-9 10-9 6-9 4-4 1H39l-11-4-10-6-8-8-6-10-4-11Z"
      fill="#9E3139"
    />
    <path d="M0 40h100v20H0Z" fill="#FEFEFE" />
  </svg>
)
export default Latvia
