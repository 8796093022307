import * as React from 'react'
const Ireland = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#FDFDFC"
    />
    <path
      d="m76 7 4 2 8 7 6 8 5 11 1 4v22l-4 11-6 10-8 8-4 3h-2Z"
      fill="#FF873B"
    />
    <path
      d="M22 7h2v86l-4-2-8-7-6-8-5-11-1-4V39l4-11 6-10 8-8Z"
      fill="#149A61"
    />
  </svg>
)
export default Ireland
