import * as React from 'react'
const FaroeIslands = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#E92B3D"
    />
    <path
      d="M48 63h51l-3 9-6 10-8 8-10 6-11 4H48Zm0-63h13l11 4 10 6 8 8 6 10 3 9H48Z"
      fill="#FAFBFD"
    />
    <path
      d="M0 57h28v38l-6-2-12-11-6-10-4-11ZM26 5h2v38H0l1-8 7-14 9-10Z"
      fill="#0E63BA"
    />
    <path d="M42 57h58l-1 6H48v37h-6Zm0-57h6v37h51l1 6H42Z" fill="#0063BC" />
    <path
      d="M1 63h20v28l-5-3-9-10-6-13ZM20 9h1v28H1l3-9 6-10 8-8Z"
      fill="#F7F9FC"
    />
  </svg>
)
export default FaroeIslands
