import * as React from 'react'
const DoubleCard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={100}
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M18 29H8c-2.2 0-4-1.8-4-4V7c0-2.2 1.8-4 4-4h10c2.2 0 4 1.8 4 4v18c0 2.2-1.8 4-4 4m4-22.6 5 3c1.9 1.1 2.5 3.6 1.3 5.5L22 25.5"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="m28 16 .5.7c1.3 1.8.8 4.3-1 5.6l-5.7 4M8 7v2m10 14v2m-7.6-8.4c.5.5 1.3.5 1.8 0l.8-.8.8.8c.5.5 1.3.5 1.8 0s.5-1.4 0-1.9l-.8-.8L13 12l-1.8 1.9-.8.8c-.5.5-.5 1.4 0 1.9M12 20h2m-1-3v2"
    />
  </svg>
)
export default DoubleCard
