import * as React from 'react'
const Chile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#C02828"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,48 L0,48 Z "
      fill="#344195"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L47,0 L47,48 L0,48 Z "
      fill="#FEFEFE"
      transform="translate(49,0)"
    />
    <path
      d="M0,0 L1,0 L1,47 L2,44 L2,6 L3,6 L4,24 L6,28 L4,44 L8,45 L47,46 L47,48 L0,48 Z "
      fill="#E1D4E2"
      transform="translate(49,0)"
    />
    <path
      d="M0,0 L2,3 L2,5 L8,5 L5,8 L5,14 L-1,13 L-5,15 L-4,9 L-8,5 L-1,5 Z "
      fill="#E0E9F6"
      transform="translate(33,23)"
    />
  </svg>
)
export default Chile
