import * as React from 'react'
const Chip025 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={305}
    height={309}
    viewBox="0 0 305 309"
    {...props}
  >
    <path
      d="M0,0 L35,0 L52,3 L58,5 L58,7 L65,8 L85,16 L99,24 L113,34 L123,43 L125,45 L130,47 L139,58 L149,72 L157,86 L165,106 L167,115 L168,124 L170,138 L170,169 L167,188 L165,202 L157,221 L152,231 L142,246 L133,257 L129,262 L124,264 L117,271 L102,282 L87,291 L65,300 L57,303 L52,305 L34,308 L1,308 L-17,305 L-22,303 L-30,300 L-50,292 L-64,284 L-78,274 L-88,265 L-93,263 L-102,253 L-115,235 L-123,219 L-129,205 L-131,199 L-132,189 L-135,171 L-135,137 L-132,119 L-131,109 L-124,90 L-115,73 L-103,56 L-98,50 L-94,46 L-87,42 L-75,31 L-59,21 L-43,13 L-27,7 L-23,6 L-22,4 L-8,1 Z "
      fill="#F0C050"
      transform="translate(135,1)"
    />
    <path
      d="M0,0 L18,0 L30,2 L43,7 L55,14 L66,23 L74,33 L82,47 L86,59 L88,71 L88,85 L84,102 L79,115 L70,128 L66,133 L55,142 L41,150 L30,154 L18,156 L1,156 L-15,153 L-26,148 L-36,142 L-44,136 L-44,134 L-47,133 L-55,123 L-63,109 L-67,97 L-69,85 L-69,70 L-66,55 L-61,43 L-53,30 L-44,20 L-32,11 L-18,4 Z "
      fill="#FAF9F9"
      transform="translate(143,77)"
    />
    <path
      d="M0,0 L19,0 L35,3 L51,10 L62,17 L74,28 L83,40 L91,56 L95,72 L95,96 L91,112 L87,122 L81,133 L70,146 L58,156 L42,164 L31,168 L21,170 L0,170 L-17,166 L-33,158 L-42,152 L-52,143 L-59,135 L-67,121 L-72,108 L-74,99 L-74,72 L-70,56 L-62,40 L-54,29 L-44,19 L-34,12 L-20,5 L-7,1 Z M1,7 L-17,11 L-31,18 L-40,25 L-49,33 L-56,43 L-64,59 L-67,71 L-68,77 L-68,92 L-65,107 L-60,120 L-51,134 L-45,141 L-43,141 L-43,143 L-29,153 L-19,158 L-10,161 L2,163 L19,163 L35,160 L51,152 L60,146 L67,140 L77,127 L83,115 L87,102 L89,92 L89,78 L87,66 L81,50 L72,36 L67,30 L56,21 L42,13 L27,8 L19,7 Z "
      fill="#F2B629"
      transform="translate(142,70)"
    />
    <path
      d="M0,0 L10,0 L16,5 L25,24 L35,38 L37,42 L37,50 L33,56 L19,70 L14,74 L12,76 L3,66 L-10,48 L-18,32 L-24,18 L-26,12 L-26,7 L-15,4 Z "
      fill="#F9F8F8"
      transform="translate(30,188)"
    />
    <path
      d="M0,0 L4,1 L13,12 L23,26 L31,40 L39,60 L41,69 L39,73 L38,69 L27,71 L14,75 L4,75 L-3,69 L-10,53 L-18,41 L-22,36 L-23,33 L-23,24 L-16,16 L-2,2 L0,2 Z "
      fill="#FCFBFB"
      transform="translate(261,47)"
    />
    <path
      d="M0,0 L10,0 L32,6 L36,7 L35,15 L27,34 L22,44 L12,59 L3,70 L-1,75 L-4,75 L-9,70 L-26,53 L-27,50 L-27,41 L-18,28 L-12,18 L-7,6 Z "
      fill="#FEFDFC"
      transform="translate(265,188)"
    />
    <path
      d="M0,0 L3,0 L8,5 L24,21 L26,25 L26,33 L22,40 L16,48 L8,63 L4,71 L-1,75 L-11,75 L-35,68 L-37,67 L-36,60 L-30,44 L-21,27 L-9,10 L-4,4 Z "
      fill="#F8F6F6"
      transform="translate(41,47)"
    />
    <path
      d="M0,0 L8,1 L14,6 L17,14 L22,36 L22,39 L8,42 L0,43 L-33,43 L-51,40 L-56,38 L-57,36 L-55,36 L-51,19 L-49,10 L-45,4 L-41,1 L-8,1 Z "
      fill="#FDFCFB"
      transform="translate(169,266)"
    />
    <path
      d="M0,0 L35,0 L52,3 L57,4 L50,32 L47,38 L44,41 L41,42 L-7,42 L-13,37 L-16,30 L-21,9 L-21,7 L-23,6 L-22,4 L-8,1 Z "
      fill="#F8F7F7"
      transform="translate(135,1)"
    />
    <path
      d="M0,0 L13,0 L19,4 L24,10 L25,13 L25,28 L21,35 L16,39 L14,40 L1,40 L-5,36 L-9,30 L-11,24 L-11,13 L-7,5 Z "
      fill="#F5D282"
      transform="translate(101,149)"
    />
    <path
      d="M0,0 L5,1 L4,7 L8,17 L16,12 L17,15 L16,23 L25,20 L22,29 L20,36 L19,44 L-14,44 L-16,32 L-20,20 L-14,22 L-10,24 L-12,12 L-15,11 L-15,7 L-11,7 L-9,13 L-2,18 L1,7 Z "
      fill="#EFC971"
      transform="translate(149,90)"
    />
    <path
      d="M0,0 L26,0 L28,1 L28,10 L10,10 L10,14 L12,13 L19,13 L26,17 L29,20 L30,23 L30,31 L27,36 L24,39 L19,41 L9,41 L-1,36 L-2,32 L2,27 L7,29 L9,30 L16,30 L18,28 L18,24 L10,24 L6,25 L0,22 Z "
      fill="#E4A81C"
      transform="translate(183,149)"
    />
    <path
      d="M0,0 L13,0 L20,5 L22,8 L22,19 L16,26 L12,29 L21,30 L23,31 L23,40 L-7,40 L-7,38 L-9,38 L-9,30 L5,20 L10,15 L11,10 L3,11 L-2,14 L-9,8 L-7,4 Z "
      fill="#F1B21F"
      transform="translate(155,149)"
    />
    <path
      d="M0,0 L11,2 L27,9 L38,16 L50,27 L59,39 L67,55 L68,62 L61,63 L57,51 L50,39 L43,31 L43,29 L39,27 L31,20 L16,12 L3,8 L-19,6 L-19,5 L-7,4 Z "
      fill="#EFBE4C"
      transform="translate(166,71)"
    />
    <path
      d="M0,0 L19,0 L24,1 L19,5 L17,6 L1,8 L-17,12 L-31,19 L-40,26 L-47,32 L-52,35 L-60,43 L-62,43 L-61,38 L-53,28 L-44,19 L-34,12 L-20,5 L-7,1 Z "
      fill="#E6AD25"
      transform="translate(142,70)"
    />
    <path
      d="M0,0 L5,0 L6,8 L6,28 L5,35 L-6,34 L-9,33 L-9,2 Z "
      fill="#ECAE1C"
      transform="translate(247,137)"
    />
    <path
      d="M0,0 L5,1 L13,4 L10,13 L2,27 L-3,34 L-8,33 L-15,27 L-11,20 L-5,10 Z "
      fill="#EFB11D"
      transform="translate(234,185)"
    />
    <path
      d="M0,0 L13,2 L13,34 L-1,35 L-2,26 L-2,6 Z "
      fill="#EBB638"
      transform="translate(52,137)"
    />
    <path
      d="M0,0 L4,1 L11,11 L19,26 L20,31 L11,34 L6,33 L-2,17 L-8,9 L-6,5 Z "
      fill="#F1CB72"
      transform="translate(227,90)"
    />
    <path
      d="M0,0 L4,1 L11,7 L9,12 L2,22 L-4,34 L-12,33 L-16,31 L-15,24 L-6,8 Z "
      fill="#E3A71A"
      transform="translate(73,90)"
    />
    <path
      d="M0,0 L4,1 L10,8 L10,10 L0,17 L-11,23 L-17,26 L-22,26 L-25,18 L-25,14 L-9,6 Z "
      fill="#F3B41F"
      transform="translate(207,223)"
    />
    <path
      d="M0,0 L3,0 L7,10 L7,12 L-9,20 L-18,26 L-22,27 L-29,18 L-24,13 L-11,5 Z "
      fill="#E3A719"
      transform="translate(115,60)"
    />
    <path
      d="M0,0 L5,0 L14,17 L20,25 L18,29 L11,34 L6,29 L2,24 L-6,9 L-7,3 Z "
      fill="#EFC666"
      transform="translate(64,186)"
    />
    <path
      d="M0,0 L9,3 L21,9 L31,16 L30,21 L25,27 L21,26 L11,19 L-4,12 L-1,2 Z "
      fill="#E9B538"
      transform="translate(185,60)"
    />
    <path
      d="M0,0 L21,0 L28,1 L28,9 L27,13 L-6,13 L-8,1 Z "
      fill="#E4A818"
      transform="translate(141,54)"
    />
    <path
      d="M0,0 L4,1 L18,10 L29,15 L26,26 L19,25 L3,17 L-6,10 L-5,6 Z "
      fill="#EEB01E"
      transform="translate(93,223)"
    />
    <path
      d="M0,0 L33,0 L35,8 L35,12 L31,13 L1,13 L-1,12 L-1,5 Z "
      fill="#F3B421"
      transform="translate(135,242)"
    />
    <path
      d="M0,0 L1,0 L5,17 L10,29 L17,43 L12,48 L8,46 L0,31 L-5,21 L-9,18 L-17,17 L-17,16 L-11,16 L-9,10 L-7,9 L-6,11 L-2,5 Z M-8,11 Z "
      fill="#F1C253"
      transform="translate(49,171)"
    />
    <path
      d="M0,0 L1,0 L1,13 L-3,20 L-8,24 L-10,25 L-23,25 L-28,21 L-28,19 L-22,15 L-14,14 L-11,9 L-6,4 Z "
      fill="#EDAE1E"
      transform="translate(125,164)"
    />
    <path
      d="M0,0 L11,1 L20,14 L27,28 L28,35 L21,36 L17,24 L10,12 L3,4 L3,2 L0,1 Z "
      fill="#F6D587"
      transform="translate(206,98)"
    />
    <path
      d="M0,0 L1,0 L1,25 L-4,46 L-13,65 L-23,79 L-35,91 L-49,101 L-63,108 L-74,112 L-79,112 L-76,110 L-58,103 L-45,95 L-35,87 L-24,76 L-14,61 L-7,45 L-3,32 L-1,19 Z "
      fill="#C99515"
      transform="translate(257,145)"
    />
    <path
      d="M0,0 L4,1 L13,12 L23,26 L31,40 L39,60 L41,69 L39,73 L31,50 L23,34 L14,20 L7,11 L0,3 Z "
      fill="#F0EDEC"
      transform="translate(261,47)"
    />
    <path
      d="M0,0 L3,0 L2,8 L-6,27 L-11,37 L-21,52 L-30,63 L-34,68 L-37,68 L-36,64 L-27,54 L-15,36 L-7,20 Z "
      fill="#DED7D9"
      transform="translate(298,195)"
    />
    <path
      d="M0,0 L1,2 L7,3 L23,5 L54,5 L72,2 L77,2 L77,5 L63,8 L55,9 L22,9 L4,6 L-1,4 L-2,2 L0,2 Z "
      fill="#DED8DA"
      transform="translate(114,300)"
    />
    <path
      d="M0,0 L2,0 L5,15 L10,28 L11,33 L6,37 L-1,22 L-5,7 L-5,4 Z "
      fill="#F2CA6D"
      transform="translate(73,162)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,14 L7,19 L6,20 L0,20 L-3,17 L-3,4 Z "
      fill="#FAF9F9"
      transform="translate(105,159)"
    />
    <path
      d="M0,0 L3,0 L4,5 L0,27 L0,51 L1,67 L0,72 L-1,72 L-3,58 L-3,24 L0,6 Z "
      fill="#F6BF3F"
      transform="translate(3,114)"
    />
    <path
      d="M0,0 L6,0 L10,4 L10,10 L6,14 L0,14 L-4,10 L-4,4 Z "
      fill="#FEFCF7"
      transform="translate(279,148)"
    />
    <path
      d="M0,0 L7,0 L11,4 L10,11 L7,14 L1,14 L-3,10 L-3,3 Z "
      fill="#FDFBF7"
      transform="translate(19,148)"
    />
    <path
      d="M0,0 L8,1 L10,4 L10,11 L7,14 L0,14 L-3,11 L-4,5 Z "
      fill="#FEFCF7"
      transform="translate(214,260)"
    />
    <path
      d="M0,0 L7,1 L10,5 L9,11 L6,14 L-1,14 L-4,11 L-4,3 Z "
      fill="#FEFBF7"
      transform="translate(85,260)"
    />
    <path
      d="M0,0 L5,5 L8,9 L6,13 L-1,18 L-6,13 L-8,11 L-7,7 Z "
      fill="#EBAD1C"
      transform="translate(76,202)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,10 L7,13 L1,14 L-4,9 L-3,2 Z "
      fill="#FDFBF6"
      transform="translate(214,36)"
    />
    <path
      d="M0,0 L7,0 L11,5 L10,11 L6,14 L-1,13 L-3,10 L-3,3 Z "
      fill="#FDFBF6"
      transform="translate(84,36)"
    />
    <path
      d="M0,0 L2,1 L-3,5 L-8,6 L-12,10 L-19,12 L-24,17 L-28,20 L-32,21 L-46,36 L-48,35 L-47,31 L-39,24 L-29,16 L-16,8 Z "
      fill="#F7C347"
      transform="translate(92,14)"
    />
    <path
      d="M0,0 L6,0 L10,4 L10,10 L8,12 L1,12 L-3,8 L-2,1 Z "
      fill="#EEB020"
      transform="translate(133,177)"
    />
    <path
      d="M0,0 L2,1 L3,4 L3,12 L0,17 L-3,20 L-8,22 L-18,22 L-23,20 L-19,19 L-10,19 L-3,16 L0,12 Z "
      fill="#3D3625"
      transform="translate(210,168)"
    />
    <path
      d="M0,0 L2,0 L2,9 L-28,9 L-28,7 L0,7 Z "
      fill="#4A3E21"
      transform="translate(176,180)"
    />
    <path
      d="M0,0 L2,1 L-4,7 L3,9 L3,10 L-4,11 L-7,16 L-8,23 L-9,23 L-9,12 L-12,12 Z M-13,13 Z M-14,14 Z M-15,15 Z M-16,16 Z M-17,17 Z M-18,18 Z M-19,19 Z "
      fill="#C1AA75"
      transform="translate(109,149)"
    />
    <path
      d="M0,0 L1,0 L1,13 L-3,20 L-8,24 L-10,25 L-21,25 L-21,24 L-10,22 L-4,17 L-1,9 Z "
      fill="#221C0F"
      transform="translate(125,164)"
    />
    <path
      d="M0,0 L2,0 L1,30 L0,30 L-1,25 L-1,5 Z "
      fill="#D0A337"
      transform="translate(51,138)"
    />
    <path
      d="M0,0 L2,1 L2,12 L-4,19 L-10,23 L-13,22 L-3,14 L0,8 Z "
      fill="#221D11"
      transform="translate(175,156)"
    />
    <path
      d="M0,0 L1,0 L1,9 L-3,16 L-13,25 L-18,29 L-16,25 L-3,12 L-1,8 Z "
      fill="#D69D10"
      transform="translate(67,230)"
    />
    <path
      d="M0,0 L2,1 L0,8 L-11,8 L-7,5 Z "
      fill="#E3BD65"
      transform="translate(164,102)"
    />
    <path
      d="M0,0 L5,1 L4,5 L-1,19 L-2,16 L1,7 Z "
      fill="#B49B62"
      transform="translate(149,90)"
    />
    <path
      d="M0,0 L4,1 L-4,5 L-8,5 L-9,7 L-14,6 L-12,4 Z "
      fill="#F7C858"
      transform="translate(108,8)"
    />
    <path
      d="M0,0 L1,2 L0,6 L-24,6 L-24,5 L-2,4 Z "
      fill="#EDBF56"
      transform="translate(168,61)"
    />
    <path
      d="M0,0 L7,0 L8,2 L-3,4 L-8,2 L-4,1 Z "
      fill="#493C1F"
      transform="translate(192,170)"
    />
    <path
      d="M0,0 L6,0 L7,2 L0,3 L-5,6 L-5,3 Z "
      fill="#342C18"
      transform="translate(158,157)"
    />
    <path
      d="M0,0 L2,0 L0,5 L-8,5 L-7,3 Z "
      fill="#C9B075"
      transform="translate(172,110)"
    />
    <path
      d="M0,0 L6,2 L7,6 L1,4 L-2,1 Z "
      fill="#EABB48"
      transform="translate(80,287)"
    />
    <path
      d="M0,0 L2,1 L2,7 L0,9 L-6,9 L-3,7 L-1,6 Z "
      fill="#2C2413"
      transform="translate(141,180)"
    />
    <path
      d="M0,0 L2,2 L3,7 L1,11 L-1,6 Z "
      fill="#E8D7B1"
      transform="translate(299,109)"
    />
    <path
      d="M0,0 L5,1 L4,4 L8,5 L6,6 L-1,3 Z "
      fill="#EBBD4E"
      transform="translate(96,294)"
    />
    <path
      d="M0,0 L2,0 L2,6 L-1,9 Z "
      fill="#D89C06"
      transform="translate(301,175)"
    />
    <path
      d="M0,0 L4,1 L4,5 L0,4 Z "
      fill="#D3B46C"
      transform="translate(165,97)"
    />
    <path
      d="M0,0 L0,3 L-7,10 L-8,7 Z "
      fill="#FACD62"
      transform="translate(52,39)"
    />
    <path
      d="M0,0 L4,2 L3,5 L-1,4 Z "
      fill="#D5B15D"
      transform="translate(126,105)"
    />
    <path
      d="M0,0 L4,2 L3,5 L-1,4 Z "
      fill="#DCBE79"
      transform="translate(175,105)"
    />
    <path
      d="M0,0 L1,2 L3,2 L2,11 L1,11 Z "
      fill="#F7CC67"
      transform="translate(1,175)"
    />
    <path
      d="M0,0 L1,2 L8,3 L6,5 L0,4 Z "
      fill="#EFC152"
      transform="translate(192,6)"
    />
    <path
      d="M0,0 L4,2 L-1,5 L-3,4 L-2,1 Z "
      fill="#E1B64A"
      transform="translate(223,287)"
    />
    <path
      d="M0,0 L2,1 L1,5 L-5,5 Z "
      fill="#BCA46A"
      transform="translate(164,102)"
    />
    <path
      d="M0,0 L2,1 L-5,8 L-7,6 Z "
      fill="#FAD275"
      transform="translate(60,32)"
    />
  </svg>
)
export default Chip025
