import * as React from 'react'
const Cyprus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={99}
    {...props}
  >
    <path
      d="M44 1h13l13 4 10 6 6 5 7 10 5 12 1 6v12l-3 12-5 9-9 10-10 7-11 4-7 1h-8l-16-4-11-7-7-7-6-10-4-11-1-13 3-14 5-10 9-10 8-6 12-5Z"
      fill="#FDFDFD"
    />
    <path
      d="m82 26 2 1-12 8-1 2-4 2 1 4 2 4-5 1-5-1-1 5-7 3-7 1-2 3-14-3-2-2v-3h-2l-1-6 5-1 2-3h9l1-6h20l1-1 7-1 4-3 7-3Z"
      fill="#D57C2A"
    />
    <path d="M72 59v6h-2v3l-6 3-4 1-1-4 5-3 4-2 1-2Z" fill="#A6AC9D" />
    <path d="m28 60 5 1v2h2l1 3-2 2h-4v-3h-2Z" fill="#A9B0A2" />
    <path d="m44 69 4 2-1 3-6-1v-3Z" fill="#A8AEA2" />
    <path d="m55 69 4 2-2 3h-4v-4Z" fill="#A0A79A" />
  </svg>
)
export default Cyprus
