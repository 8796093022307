import * as React from 'react'
const BallPlinko = (props) => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Layer 2" id="Layer_2">
      <path
        fill="#fff"
        d="M16,4A11,11,0,1,0,27,15,11,11,0,0,0,16,4Zm0,6a5,5,0,0,0-5,5,1,1,0,0,1-2,0,7,7,0,0,1,7-7,1,1,0,0,1,0,2Z"
      />
    </g>
  </svg>
)
export default BallPlinko
