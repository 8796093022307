import * as React from 'react'
const CostaRica = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#CA2027"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,32 L0,32 Z "
      fill="#FDFBFB"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,16 L0,16 Z "
      fill="#263777"
      transform="translate(0,80)"
    />
    <path
      d="M0,0 L96,0 L96,16 L0,16 Z "
      fill="#FDFCFD"
      transform="translate(0,64)"
    />
    <path
      d="M0,0 L96,0 L96,16 L0,16 Z "
      fill="#263777"
      transform="translate(0,0)"
    />
  </svg>
)
export default CostaRica
