import * as React from 'react'
const Finland = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#01337F"
    />
    <path
      d="M49 63h50l-3 9-6 10-8 8-10 6-11 4H49Zm0-63h12l11 4 10 6 8 8 6 10 3 9H49Z"
      fill="#FBFBFC"
    />
    <path
      d="M1 63h21v29l-6-4-9-10-6-13ZM21 8h1v29H1l3-9 6-10 8-8Z"
      fill="#F5F6F8"
    />
  </svg>
)
export default Finland
