import { createContext, useContext, useState } from 'react'
import { IntlProvider } from 'react-intl'
import es from '../../lang/es.json'
import en from '../../lang/en.json'
import it from '../../lang/it.json'
import fr from '../../lang/fr.json'
import zh from '../../lang/zh.json'
import tr from '../../lang/tr.json'
import de from '../../lang/de.json'

const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en')
  const messages = {
    es,
    en,
    it,
    fr,
    zh,
    tr,
    de,
  }

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <IntlProvider locale={language} messages={messages[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => useContext(LanguageContext)
