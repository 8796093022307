import apiConfig from '@/config/relysiaApi'
import { SERVICE_ID } from '@/constants/environment'
import { envMODE } from '../config/envMode'

let config

if (envMODE === 'DEV') {
  config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: 'lilbit-1699.firebaseapp.com',
    databaseURL: 'https://lilbit-1699-default-rtdb.firebaseio.com',
    projectId: 'lilbit-1699',
    storageBucket: 'lilbit-1699.appspot.com',
    messagingSenderId: '247193851168',
    appId: '1:247193851168:web:1883a0ef400cada203b345',
    measurementId: 'G-5YYSBTFXPS',
  }
  apiConfig.defaults.headers.common['serviceId'] = SERVICE_ID
} else {
  config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: 'lilbit-1699.firebaseapp.com',
    databaseURL: 'https://lilbit-1699-default-rtdb.firebaseio.com',
    projectId: 'lilbit-1699',
    storageBucket: 'lilbit-1699.appspot.com',
    messagingSenderId: '247193851168',
    appId: '1:247193851168:web:1883a0ef400cada203b345',
    measurementId: 'G-5YYSBTFXPS',
  }

  apiConfig.defaults.headers.common['serviceId'] = SERVICE_ID
}

export default config
