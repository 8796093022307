import * as React from 'react'
const Peru = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#FEFCFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L29,0 L29,96 L0,96 Z "
      fill="#B82134"
      transform="translate(67,0)"
    />
    <path
      d="M0,0 L29,0 L29,96 L0,96 Z "
      fill="#B72134"
      transform="translate(0,0)"
    />
  </svg>
)
export default Peru
