import React from 'react'
const Spain = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#FCC70C"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L40,0 L39,4 L34,9 L30,12 L25,14 L15,14 L7,10 L2,5 L0,3 Z "
      fill="#C61F27"
      transform="translate(5,34)"
    />
    <path
      d="M0,0 L10,0 L18,4 L23,9 L26,14 L-16,14 L-13,9 L-5,2 Z "
      fill="#C61F26"
      transform="translate(20,2)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M2,2 L-6,6 L-13,13 L-16,20 L-16,30 L-12,38 L-7,43 L-1,47 L2,48 L12,48 L20,44 L25,40 L29,32 L29,18 L26,12 L21,7 L17,4 L12,2 Z "
      fill="#B5CBD4"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L42,0 L42,1 L14,2 L14,6 L17,6 L17,13 L16,14 L16,16 L12,17 L12,18 L0,18 L-2,14 L-2,4 Z "
      fill="#F5C811"
      transform="translate(4,16)"
    />
    <path
      d="M0,0 L5,1 L5,4 L8,3 L8,10 L7,11 L7,13 L-5,13 L-5,3 L-3,3 L-3,1 Z "
      fill="#A87A32"
      transform="translate(13,19)"
    />
    <path
      d="M0,0 L2,0 L3,3 L6,5 L6,7 L8,7 L8,9 L0,9 L-1,8 L-1,1 Z "
      fill="#925536"
      transform="translate(12,23)"
    />
    <path
      d="M0,0 L3,3 L3,12 L0,18 L-2,14 L-1,12 Z "
      fill="#BEC1B3"
      transform="translate(47,20)"
    />
    <path
      d="M0,0 L2,0 L2,7 L-4,5 L-5,1 Z "
      fill="#B89275"
      transform="translate(19,22)"
    />
    <path
      d="M0,0 L1,4 L-2,7 L-5,6 Z "
      fill="#B5D7DB"
      transform="translate(45,36)"
    />
  </svg>
)
export default Spain
