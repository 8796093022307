import * as React from 'react'
const MultiTicket = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7583 12.2437C15.7583 13.3187 16.6416 14.1937 17.7166 14.1937C17.7166 17.3187 16.9333 18.1021 13.8083 18.1021H5.99159C2.86659 18.1021 2.08325 17.3187 2.08325 14.1937V13.8104C3.15825 13.8104 4.04159 12.9271 4.04159 11.8521C4.04159 10.7771 3.15825 9.89375 2.08325 9.89375V9.51041C2.09159 6.38541 2.86659 5.60208 5.99159 5.60208H13.7999C16.9249 5.60208 17.7083 6.38541 17.7083 9.51041V10.2937C16.6333 10.2937 15.7583 11.1604 15.7583 12.2437Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5093 5.60208H5.93433L8.37599 3.16041C10.3677 1.16875 11.3677 1.16875 13.3593 3.16041L13.8593 3.66041C13.3343 4.18541 13.2093 4.96041 13.5093 5.60208Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.23242 5.60221L8.23242 18.1022"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="5 5"
    />
  </svg>
)
export default MultiTicket
