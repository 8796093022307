import React from 'react'
const PremierLeague = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={169}
    viewBox="0 0 150 169"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,169 L0,169 Z "
      fill="#FEFDFE"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L14,0 L19,4 L18,1 L26,3 L30,8 L31,13 L24,8 L21,7 L21,10 L16,8 L13,6 L6,7 L0,9 L1,12 L-4,17 L-1,17 L2,22 L4,23 L17,23 L13,19 L4,17 L4,14 L5,13 L12,14 L16,15 L19,20 L21,22 L31,22 L25,17 L26,15 L31,15 L33,21 L36,22 L40,31 L39,35 L35,38 L34,27 L29,29 L31,36 L32,39 L35,42 L35,46 L32,46 L30,41 L24,41 L20,44 L17,44 L19,50 L26,47 L26,52 L28,52 L30,47 L36,48 L36,41 L40,40 L41,44 L41,54 L39,56 L36,53 L35,61 L29,71 L23,76 L20,72 L18,74 L6,77 L-2,77 L-9,75 L-9,52 L-11,52 L-13,56 L-20,64 L-22,64 L-24,55 L-24,40 L-30,41 L-29,35 L-27,28 L-23,22 L-26,22 L-24,17 L-17,9 L-15,8 L-14,15 L-13,16 L-12,8 L-5,6 L-8,3 Z "
      fill="#35204C"
      transform="translate(70,25)"
    />
    <path
      d="M0,0 L12,0 L17,1 L20,4 L19,7 L26,8 L28,8 L38,8 L40,8 L50,8 L51,10 L59,8 L60,6 L62,6 L63,3 L67,4 L66,8 L67,9 L67,23 L57,23 L55,22 L53,26 L48,24 L48,26 L44,26 L44,29 L39,29 L43,30 L47,31 L61,32 L61,47 L58,51 L47,52 L47,47 L39,47 L33,46 L31,48 L30,47 L24,47 L21,48 L17,47 L11,49 L1,48 L0,44 L2,2 L0,2 Z "
      fill="#F7F3F8"
      transform="translate(29,108)"
    />
    <path
      d="M0,0 L7,1 L9,2 L9,8 L0,14 L-2,14 L1,23 L8,20 L8,25 L10,25 L12,20 L18,21 L18,14 L22,13 L23,17 L23,27 L21,29 L18,26 L17,34 L11,44 L5,49 L2,45 L0,47 L-12,50 L-20,50 L-27,48 L-27,29 L-28,20 L-13,20 L-11,23 L-8,24 L-8,15 L-7,17 L-5,15 L-6,9 L-7,5 Z "
      fill="#381E52"
      transform="translate(88,52)"
    />
    <path
      d="M0,0 L12,0 L17,1 L20,4 L19,7 L26,8 L28,8 L38,8 L40,8 L50,8 L51,10 L59,8 L60,6 L62,6 L63,3 L67,4 L66,8 L67,9 L67,23 L57,23 L56,13 L53,12 L53,23 L49,22 L49,13 L45,13 L45,23 L41,23 L40,12 L39,17 L30,17 L31,19 L38,19 L38,22 L36,23 L30,23 L26,18 L26,13 L22,13 L22,23 L17,23 L17,15 L7,16 L7,40 L15,40 L15,36 L17,36 L19,32 L26,32 L25,34 L21,35 L20,37 L26,37 L27,35 L29,35 L29,39 L20,39 L21,41 L27,41 L28,44 L26,45 L20,45 L16,44 L15,45 L2,45 L2,2 L0,2 Z "
      fill="#DDD6E2"
      transform="translate(29,108)"
    />
    <path
      d="M0,0 L8,0 L13,1 L13,5 L10,5 L11,12 L9,12 L9,20 L12,27 L12,31 L3,31 L4,33 L10,33 L10,36 L9,37 L3,37 L-2,34 L-7,37 L-14,36 L-16,33 L-16,23 L-12,23 L-11,33 L-8,33 L-7,23 L-1,20 L0,17 L-9,17 L-11,15 L-11,5 L-6,1 Z "
      fill="#EAE3EE"
      transform="translate(108,116)"
    />
    <path
      d="M0,0 L3,0 L4,4 L4,14 L2,16 L-1,13 L-2,21 L-8,31 L-14,36 L-15,36 L-15,31 L-20,32 L-26,31 L-33,31 L-33,24 L-31,23 L-31,25 L-27,25 L-26,21 L-23,21 L-23,16 L-16,14 L-20,13 L-23,8 L-23,6 L-20,5 L-18,10 L-11,7 L-11,12 L-9,12 L-7,7 L-1,8 L-1,1 Z "
      fill="#351E4D"
      transform="translate(107,65)"
    />
    <path
      d="M0,0 L4,0 L6,7 L8,9 L13,10 L13,12 L16,12 L14,18 L11,21 L6,21 L5,18 L-3,18 L-5,23 L-7,23 L-7,27 L-15,27 L-15,3 L-5,2 L-5,10 L0,10 Z "
      fill="#F9F7FA"
      transform="translate(51,121)"
    />
    <path
      d="M0,0 L9,0 L14,3 L14,10 L10,14 L5,14 L5,38 L13,38 L13,34 L15,34 L17,30 L24,30 L23,32 L19,33 L18,35 L24,35 L25,33 L27,33 L27,37 L18,37 L19,39 L25,39 L26,42 L24,43 L18,43 L14,42 L13,43 L0,43 Z "
      fill="#897D95"
      transform="translate(31,110)"
    />
    <path
      d="M0,0 L6,1 L12,5 L12,2 L17,3 L22,7 L24,5 L25,11 L29,13 L30,24 L28,20 L25,17 L22,15 L22,10 L17,11 L22,16 L23,18 L12,18 L6,11 L1,9 L-4,9 L-5,11 L4,13 L9,19 L-5,19 L-10,14 L-10,12 L-13,12 L-11,8 L-9,6 L-9,4 Z "
      fill="#EDE6F0"
      transform="translate(79,30)"
    />
    <path
      d="M0,0 L4,1 L3,5 L4,6 L4,20 L-6,20 L-7,10 L-10,9 L-10,20 L-14,19 L-14,10 L-18,10 L-18,20 L-22,20 L-22,6 L-13,6 L-9,6 L-4,5 L-3,3 L-1,3 Z "
      fill="#3F3554"
      transform="translate(92,111)"
    />
    <path
      d="M0,0 L1,0 L1,8 L6,5 L5,13 L-11,13 L-21,15 L-26,17 L-30,10 L-30,8 L-26,10 L-24,11 L-25,3 L-15,9 L-14,3 L-12,1 L-8,6 L-8,8 L-3,4 Z "
      fill="#322446"
      transform="translate(85,10)"
    />
    <path
      d="M0,0 L2,0 L4,9 L6,7 L9,6 L10,9 L10,22 L9,22 L8,13 L5,14 L4,21 L-2,20 L-4,25 L-6,25 L-7,21 L-10,23 L-13,23 L-15,17 L-9,14 L-8,13 L-2,13 L0,15 L0,19 L3,19 L2,14 L-1,12 L-3,8 L-3,2 Z "
      fill="#3E2C5A"
      transform="translate(102,52)"
    />
    <path
      d="M0,0 L12,0 L12,15 L9,19 L0,19 L0,15 L7,15 L9,12 L2,13 L-2,9 L-2,3 Z "
      fill="#342648"
      transform="translate(78,140)"
    />
    <path
      d="M0,0 L1,0 L2,7 L4,9 L-1,9 L-1,11 L-5,9 L-6,8 L-20,8 L-27,10 L-24,14 L-31,15 L-32,24 L-35,22 L-36,14 L-33,13 L-37,12 L-34,10 L-36,4 L-33,3 L-31,8 L-24,5 L-16,4 L-4,4 L0,5 Z "
      fill="#F1E7F2"
      transform="translate(90,18)"
    />
    <path
      d="M0,0 L11,0 L11,13 L1,13 L-3,8 L-3,3 Z "
      fill="#332644"
      transform="translate(63,140)"
    />
    <path
      d="M0,0 L7,1 L9,2 L9,8 L1,13 L-4,13 L-7,8 L-6,4 Z "
      fill="#FAF4F8"
      transform="translate(88,52)"
    />
    <path
      d="M0,0 L5,0 L8,3 L8,8 L-1,8 L0,10 L7,10 L5,14 L-1,14 L-5,10 L-5,4 Z "
      fill="#5A4E6E"
      transform="translate(102,117)"
    />
    <path
      d="M0,0 L7,1 L9,3 L9,8 L0,8 L1,10 L8,10 L8,13 L6,14 L0,14 L-4,9 L-3,3 Z "
      fill="#6B5C80"
      transform="translate(59,117)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,7 L1,7 L2,9 L8,9 L8,12 L7,13 L1,13 L-3,10 L-3,3 Z "
      fill="#625676"
      transform="translate(110,140)"
    />
    <path
      d="M0,0 L8,2 L7,11 L8,14 L17,14 L15,18 L8,18 L7,22 L6,22 L6,17 L-2,17 L-5,15 L1,14 L4,15 L4,2 L0,3 L-6,2 L-6,1 Z "
      fill="#E7DFEC"
      transform="translate(70,138)"
    />
    <path
      d="M0,0 L4,1 L3,5 L4,6 L4,20 L-1,20 L-1,9 L-5,7 L-3,3 L-1,3 Z "
      fill="#ABA4B4"
      transform="translate(92,111)"
    />
    <path
      d="M0,0 L6,0 L6,2 L8,3 L8,11 L6,11 L5,4 L-1,11 L-3,11 L-7,5 L-10,9 L-11,3 L-12,1 L-6,1 L-6,3 L-1,2 Z "
      fill="#F9F7F9"
      transform="translate(80,7)"
    />
    <path
      d="M0,0 L6,0 L7,1 L7,7 L4,12 L2,12 L1,8 L-2,10 L-5,10 L-7,4 L-1,1 Z "
      fill="#E7DEEC"
      transform="translate(94,65)"
    />
    <path
      d="M0,0 L4,0 L5,10 L9,9 L10,1 L13,1 L13,10 L9,14 L2,13 L0,10 Z "
      fill="#241838"
      transform="translate(92,139)"
    />
    <path
      d="M0,0 L3,0 L3,16 L12,16 L12,20 L0,20 Z "
      fill="#2C1F40"
      transform="translate(32,133)"
    />
    <path
      d="M0,0 L7,0 L6,2 L2,3 L1,5 L7,5 L8,3 L10,3 L10,7 L1,7 L2,9 L8,9 L9,12 L7,13 L1,13 L-2,10 L-3,4 Z "
      fill="#261A38"
      transform="translate(48,140)"
    />
    <path
      d="M0,0 L3,0 L5,12 L8,15 L12,17 L5,20 L3,23 L-1,21 L1,18 L2,3 L-8,3 L-8,2 Z "
      fill="#EEE9F1"
      transform="translate(88,137)"
    />
    <path
      d="M0,0 L8,0 L9,2 L13,4 L13,11 L10,10 L9,12 L7,11 L2,11 L1,8 L10,8 L7,6 L1,6 L2,3 L6,2 L0,1 Z "
      fill="#CFC2D9"
      transform="translate(48,139)"
    />
    <path
      d="M0,0 L11,0 L11,13 L7,12 L7,4 L2,4 L-1,7 L-2,2 Z "
      fill="#2C1F43"
      transform="translate(63,140)"
    />
    <path
      d="M0,0 L8,1 L8,4 L4,4 L4,14 L0,14 Z "
      fill="#433751"
      transform="translate(112,117)"
    />
    <path
      d="M0,0 L7,1 L9,2 L9,8 L6,9 L7,4 L2,2 L2,4 L-1,5 L-5,9 L-7,8 L-6,4 Z "
      fill="#E7DBEB"
      transform="translate(88,52)"
    />
    <path
      d="M0,0 L10,0 L11,1 L11,7 L8,4 L3,3 L3,9 L2,9 L1,19 L0,19 Z "
      fill="#24123C"
      transform="translate(32,111)"
    />
    <path
      d="M0,0 L3,0 L3,15 L0,19 L-9,19 L-9,15 L-2,15 L0,12 L2,12 L2,2 L0,2 Z "
      fill="#2F263D"
      transform="translate(87,140)"
    />
    <path
      d="M0,0 L2,0 L3,21 L-4,18 L-4,14 L-3,12 L1,12 Z "
      fill="#E1DDE6"
      transform="translate(44,65)"
    />
    <path
      d="M0,0 L4,1 L3,7 L-1,11 L-3,11 L-2,7 L-8,7 L-9,4 L0,4 Z "
      fill="#EDE8ED"
      transform="translate(120,143)"
    />
    <path
      d="M0,0 L2,0 L3,2 L3,10 L2,12 L0,12 L-3,8 L-3,2 Z "
      fill="#C8BAD1"
      transform="translate(102,52)"
    />
    <path
      d="M0,0 L1,0 L1,12 L-1,14 L-4,11 L-2,9 L-9,9 L-10,6 L-1,6 L-1,1 Z "
      fill="#F2E8F8"
      transform="translate(69,119)"
    />
    <path
      d="M0,0 L1,0 L2,7 L4,9 L-1,9 L-1,11 L-5,9 L-6,8 L-18,7 L-18,6 L-6,6 L-6,4 L0,5 Z "
      fill="#C9BDD1"
      transform="translate(90,18)"
    />
    <path
      d="M0,0 L5,0 L5,9 L-1,7 L-4,6 L-3,1 Z "
      fill="#EAE6ED"
      transform="translate(56,12)"
    />
    <path
      d="M0,0 L7,1 L7,4 L4,5 L3,13 L0,13 Z "
      fill="#2A1F39"
      transform="translate(47,117)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,7 L1,7 L1,5 L6,5 L5,3 L-1,4 L-1,1 Z "
      fill="#20163A"
      transform="translate(110,140)"
    />
    <path
      d="M0,0 L7,1 L9,3 L9,8 L1,8 L0,6 L6,6 L5,4 L-2,3 Z "
      fill="#251A36"
      transform="translate(59,117)"
    />
    <path
      d="M0,0 L5,2 L6,4 L2,7 L1,6 L-5,6 L-8,7 L-12,4 L-11,2 L-9,3 L-3,3 Z "
      fill="#EBE6ED"
      transform="translate(58,149)"
    />
    <path
      d="M0,0 L4,0 L5,11 L4,13 L1,12 L0,10 Z "
      fill="#2D223A"
      transform="translate(92,139)"
    />
    <path
      d="M0,0 L1,0 L1,6 L8,7 L16,8 L16,9 L7,10 L4,11 L0,10 L-1,2 Z "
      fill="#E5E1E8"
      transform="translate(60,94)"
    />
    <path
      d="M0,0 L5,1 L5,10 L3,10 L2,3 L-4,10 L-6,10 L-6,5 L-1,5 Z "
      fill="#DCD5E1"
      transform="translate(83,8)"
    />
    <path
      d="M0,0 L5,0 L6,5 L4,7 L-1,6 L-1,1 Z "
      fill="#E5DAED"
      transform="translate(81,143)"
    />
    <path
      d="M0,0 L5,0 L6,5 L4,7 L0,6 L-1,1 Z "
      fill="#F3E9F5"
      transform="translate(65,143)"
    />
    <path
      d="M0,0 L3,1 L2,8 L2,12 L-4,14 L-1,5 Z M-1,9 Z "
      fill="#DBD4E3"
      transform="translate(106,78)"
    />
    <path
      d="M0,0 L2,0 L3,4 L3,15 L2,15 L1,7 L-2,8 L-4,6 Z M-3,8 Z "
      fill="#D7CDDE"
      transform="translate(59,77)"
    />
    <path
      d="M0,0 L1,2 L-4,8 L-6,12 L-11,12 L-11,9 L-9,6 L-8,8 L-2,3 Z "
      fill="#E9E4EB"
      transform="translate(101,92)"
    />
    <path
      d="M0,0 L11,0 L14,4 L12,7 L5,5 L8,4 L8,1 L0,1 Z "
      fill="#1E1034"
      transform="translate(73,25)"
    />
    <path
      d="M0,0 L5,1 L3,7 L0,11 L-1,3 L-3,4 L-3,1 Z "
      fill="#F8F2FB"
      transform="translate(91,13)"
    />
    <path
      d="M0,0 L3,0 L3,12 L0,12 Z "
      fill="#1B0E2D"
      transform="translate(92,118)"
    />
    <path
      d="M0,0 L4,0 L6,4 L5,8 L1,11 Z "
      fill="#1F1132"
      transform="translate(104,52)"
    />
    <path
      d="M0,0 L8,0 L10,4 L8,7 L7,6 L1,6 L2,3 L6,2 L0,1 Z "
      fill="#8B7D98"
      transform="translate(48,139)"
    />
    <path
      d="M0,0 L1,2 L11,2 L11,5 L9,6 L3,6 L0,3 Z "
      fill="#2F253E"
      transform="translate(56,125)"
    />
    <path
      d="M0,0 L1,2 L10,2 L8,6 L2,6 L-1,3 Z "
      fill="#2B2339"
      transform="translate(99,125)"
    />
    <path
      d="M0,0 L5,0 L6,5 L5,6 L0,6 Z "
      fill="#E8DEF0"
      transform="translate(35,114)"
    />
    <path
      d="M0,0 L11,0 L9,1 L9,3 L16,3 L16,4 L4,4 Z "
      fill="#CEC3D5"
      transform="translate(70,45)"
    />
    <path
      d="M0,0 L3,1 L3,3 L7,4 L6,6 L5,5 L-2,5 L-5,7 L-5,2 L-2,1 L0,2 Z "
      fill="#CAC2D2"
      transform="translate(112,136)"
    />
    <path
      d="M0,0 L3,0 L3,15 L0,18 L-1,14 L0,12 L2,12 L2,2 L0,2 Z "
      fill="#1F152D"
      transform="translate(87,140)"
    />
    <path
      d="M0,0 L3,0 L3,16 L2,16 L2,3 L-8,3 L-8,2 Z "
      fill="#CEC6D6"
      transform="translate(88,137)"
    />
    <path
      d="M0,0 L4,1 L5,2 L5,7 L-4,6 L-4,5 L2,5 Z "
      fill="#1D152D"
      transform="translate(105,118)"
    />
    <path
      d="M0,0 L1,0 L2,16 L1,19 L-1,19 Z "
      fill="#1E1234"
      transform="translate(62,80)"
    />
    <path
      d="M0,0 L5,1 L5,15 L4,15 L4,2 L0,3 L-6,2 L-6,1 Z "
      fill="#B5AAC3"
      transform="translate(70,138)"
    />
    <path
      d="M0,0 L1,0 L1,5 L-4,7 L-9,6 L-9,4 L0,4 Z "
      fill="#CFC7DB"
      transform="translate(120,143)"
    />
    <path
      d="M0,0 L1,4 L-4,6 L-9,5 L-9,3 L0,3 Z "
      fill="#CAC1D7"
      transform="translate(110,122)"
    />
    <path
      d="M0,0 L1,4 L-4,6 L-9,5 L-9,3 L0,3 Z "
      fill="#CBC0D8"
      transform="translate(68,122)"
    />
    <path
      d="M0,0 L1,0 L0,7 L-1,11 L3,12 L-2,12 L-2,1 Z "
      fill="#D5CCD9"
      transform="translate(41,55)"
    />
    <path
      d="M0,0 L8,0 L13,1 L12,5 L12,2 L2,2 L1,4 L-1,1 Z "
      fill="#D3CED4"
      transform="translate(108,116)"
    />
    <path
      d="M0,0 L1,2 L3,2 L4,8 L2,10 L-1,4 L-2,2 L0,2 Z "
      fill="#E5E2E7"
      transform="translate(98,26)"
    />
    <path
      d="M0,0 L4,1 L4,3 L-4,5 L-6,3 Z "
      fill="#D2C3D9"
      transform="translate(66,23)"
    />
    <path
      d="M0,0 L3,1 L1,4 L-5,4 L-6,2 Z "
      fill="#292237"
      transform="translate(116,149)"
    />
    <path
      d="M0,0 L7,0 L7,1 L1,3 L-2,6 L-3,2 L-5,1 Z "
      fill="#C6BDCF"
      transform="translate(57,116)"
    />
    <path
      d="M0,0 L4,1 L4,6 L0,6 Z "
      fill="#FBF1FC"
      transform="translate(100,55)"
    />
    <path
      d="M0,0 L4,1 L8,3 L6,3 L6,6 L1,4 Z "
      fill="#463B5A"
      transform="translate(85,29)"
    />
    <path
      d="M0,0 L4,0 L5,11 L4,13 L1,12 L3,1 Z "
      fill="#5A4F6A"
      transform="translate(92,139)"
    />
    <path
      d="M0,0 L1,0 L1,10 L4,10 L4,12 L2,12 L1,16 L0,12 Z "
      fill="#150B34"
      transform="translate(46,68)"
    />
    <path
      d="M0,0 L2,2 L-5,10 L-6,6 L-4,6 L-3,2 Z "
      fill="#D7D0DD"
      transform="translate(52,32)"
    />
    <path
      d="M0,0 L2,0 L2,3 L4,3 L4,10 L1,8 Z "
      fill="#C9BED4"
      transform="translate(54,32)"
    />
    <path
      d="M0,0 L1,2 L3,2 L3,4 L5,5 L-5,5 L-5,4 L0,4 L-3,2 Z "
      fill="#423256"
      transform="translate(79,17)"
    />
    <path
      d="M0,0 L6,0 L5,4 L2,8 L1,2 Z "
      fill="#DDDADF"
      transform="translate(68,8)"
    />
    <path
      d="M0,0 L2,0 L1,5 L-3,6 L-3,3 L-1,3 Z "
      fill="#E0D5EA"
      transform="translate(69,144)"
    />
    <path
      d="M0,0 L4,1 L4,3 L13,2 L14,4 L3,4 Z "
      fill="#C8BFCF"
      transform="translate(88,44)"
    />
    <path
      d="M0,0 L5,1 L3,5 L3,2 L-3,4 L-3,1 Z "
      fill="#D4CED9"
      transform="translate(91,13)"
    />
    <path
      d="M0,0 L3,0 L3,3 L-4,3 L-4,1 Z "
      fill="#21173B"
      transform="translate(39,120)"
    />
  </svg>
)
export default PremierLeague
