import * as React from 'react'
const Montenegro = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#C30508"
    />
    <path
      d="M49 18h2l1 4 4 1 2 2-1 4h-2v2h6l3 3h-3v3h-3l-1 5 2 2 1 5h3l-2-3 1-5 5-6 2-9h2v2h3v4h2l1 8 1 4-3 9-2 3h-2l-2 4-9 2 5 5 4-5h2l1-3h2l2 10-3 2h-8l-8-3-2-2 3 7 1 2-1 3-4 1v2l-5 1-3-1v-2l-4-1-1-4 3-4 2-6-3 4-7 2-2 7h-3l-3-4-2-10-4-11 1-3-1-8 1-4 1-5h2v-4l5-2 2 9 6 8v3h-2v3h3l2-6 1-2-1-4-3-1 1-1-3-1v-2l2-1h6v-2l-3-1 1-4 2-2h3Z"
      fill="#CC9733"
    />
    <path
      d="M43 45h14l1 1 1 11 4 1 1-2v3l1-2 5 1-1 2-9 2 5 5 4-5h2l1-3h2l2 10-3 2h-8l-8-3-2-2 3 7 1 2-1 3-4 1v2l-5 1-3-1v-2l-4-1-1-4 3-4 2-6-4 2v-2l-3-1 2-3 1-14Z"
      fill="#CC9F35"
    />
    <path
      d="M29 26h2l2 9 6 8v3h-2v3h3l1 2v6l-4 1h-8v-2h-2l-2-7h3l-4-2 2-4-3-3 1-8h2v-4Z"
      fill="#CFA136"
    />
    <path d="M43 45h14l1 1 1 11 2 1-5 3-5 3-6-2-3-3V46Z" fill="#BB9445" />
    <path
      d="M49 18h2l1 4 4 1 2 2-1 4h-2v2l2 1h-2l-1 4h-2l-1 6h-2l-1-6h-2l-1-4-2-1h2v-2l-3-1 1-4 2-2h3Z"
      fill="#C67E2D"
    />
    <path
      d="M28 95h44l-4 3-7 2H39l-11-4ZM39 0h22l11 4v1H28l4-3Z"
      fill="#D2AC3A"
    />
    <path
      d="M43 45h14l1 1v11l-3-4-5-1v-4h-2v3l-2 1-1 4-3 1V46Z"
      fill="#948261"
    />
    <path d="M72 59h2l2 10-3 2h-8l-5-2 1-3 3-1 1 2 4-5h2Z" fill="#C99333" />
    <path d="M25 53h2v3h2l2 3 10 2-4 4-1 3h-6l-5-13Z" fill="#C3150C" />
    <path d="M42 58h16l-3 4-6 2-6-4Z" fill="#728A3C" />
    <path d="M46 32h8l1 2-1 2h-2l-1 6h-2l-1-6h-2l-1-3Z" fill="#C3160C" />
    <path
      d="M43 45h14l1 3-2 2v-3l-3 1-1 2 3 1h-4l-1-4h-3v3h-2v-3l-3 1Z"
      fill="#285E8C"
    />
    <path
      d="m55 74 3 1v3l-4 1v2l-5 1-3-1v-2l-2-1h4l1-3h2v5h2v-2h2Z"
      fill="#C77C2B"
    />
    <path d="M28 27h1v7l-4 5-2 1 1-8h2v-4Z" fill="#C77126" />
    <path d="M48 48h2v4h5l1 3-6-1h-4l-1-3h3Z" fill="#CEA335" />
    <path d="m55 41 4 3-1 4-1-2-14-1v-1l11-1Z" fill="#B78845" />
    <path d="m50 53 4 1v3h-7v-3Z" fill="#687170" />
    <path d="M46 63v3l-3 7-1-4-2-1 3-1Z" fill="#C23014" />
    <path d="M69 62h2v6l-5 1 1-5Z" fill="#8D7259" />
    <path d="M72 59h2l2 10-4-1Z" fill="#A86E41" />
    <path d="m43 50 1 2h2l-1 4-3 1v-6Z" fill="#265F8C" />
    <path d="m36 32 1 2 4 1-2 2-5-1 1-3Z" fill="#C1451F" />
    <path d="m32 71 3 1-1 5h-3l-2-4Z" fill="#C47027" />
  </svg>
)
export default Montenegro
