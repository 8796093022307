import React from 'react'
const Cs2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={167}
    height={165}
    viewBox="0 0 167 165"
    {...props}
  >
    <path
      d="M0,0 L167,0 L167,165 L0,165 Z "
      fill="#FDFCF7"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L70,0 L70,153 L-1,153 L-11,149 L-12,143 L-9,141 L3,111 L3,103 L-9,81 L-12,77 L-12,69 L-9,69 L-7,64 L-3,56 L-3,50 L1,49 L4,51 L11,51 L14,47 L15,43 L17,43 L17,47 L26,45 L25,34 L27,28 L52,28 L52,23 L61,23 L61,21 L51,21 L50,18 L49,19 L7,19 L9,17 L10,11 L4,3 Z "
      fill="#27387D"
      transform="translate(90,6)"
    />
    <path
      d="M0,0 L72,0 L68,4 L68,15 L68,19 L61,20 L57,22 L55,26 L47,42 L42,42 L39,54 L38,60 L41,62 L39,68 L36,72 L33,83 L29,83 L27,102 L25,107 L19,112 L12,128 L8,138 L6,148 L3,153 L0,153 Z "
      fill="#FAAC1D"
      transform="translate(7,6)"
    />
    <path
      d="M0,0 L72,0 L76,2 L76,3 L1,3 L1,158 L8,156 L10,152 L11,143 L13,143 L13,140 L16,140 L16,138 L18,137 L19,133 L23,134 L24,135 L24,140 L22,140 L22,144 L20,145 L20,158 L60,158 L63,137 L64,132 L67,132 L68,128 L70,127 L71,122 L74,123 L74,121 L76,121 L76,118 L74,118 L74,112 L76,110 L77,106 L83,106 L84,107 L84,113 L87,113 L86,108 L88,106 L89,113 L87,119 L86,119 L82,135 L79,142 L76,142 L76,145 L73,145 L75,156 L74,157 L83,158 L159,159 L158,157 L157,3 L90,3 L91,1 L96,0 L160,0 L161,1 L161,161 L160,162 L0,162 L-2,159 L-2,2 Z "
      fill="#FDFDFC"
      transform="translate(4,2)"
    />
    <path
      d="M0,0 L3,0 L11,13 L15,18 L17,19 L16,23 L10,35 L6,45 L5,62 L4,65 L-35,65 L-35,57 L-27,46 L-16,32 L-11,25 L-6,12 Z "
      fill="#FAAC1C"
      transform="translate(59,95)"
    />
    <path
      d="M0,0 L167,0 L167,165 L0,165 Z M7,1 L2,4 L2,161 L4,164 L164,164 L165,163 L165,3 L163,1 Z "
      fill="#E5E5E5"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L2,2 L3,155 L-79,155 L-83,153 L-82,150 L-83,148 L-83,141 L-80,141 L-80,138 L-78,138 L-79,142 L-82,143 L-80,149 L-71,152 L0,153 Z "
      fill="#CACFE0"
      transform="translate(160,6)"
    />
    <path
      d="M0,0 L4,1 L4,4 L7,4 L8,9 L10,9 L10,18 L12,19 L11,21 L8,22 L8,29 L6,26 L4,30 L1,30 L0,39 L-2,56 L-3,57 L-43,57 L-43,56 L-4,56 L-3,36 L5,17 L9,10 L5,8 L0,1 Z "
      fill="#FDF5D3"
      transform="translate(67,104)"
    />
    <path
      d="M0,0 L3,1 L43,1 L45,0 L45,4 L55,4 L55,6 L46,6 L46,11 L21,11 L20,13 L19,10 L23,10 L24,8 L39,8 L44,9 L42,4 L3,4 L0,3 Z "
      fill="#D8DFF0"
      transform="translate(96,23)"
    />
    <path
      d="M0,0 L1,0 L0,7 L-3,7 L-3,10 L-8,15 L-9,20 L27,20 L28,15 L30,15 L29,22 L-10,22 L-10,14 L-2,3 Z "
      fill="#EEBA55"
      transform="translate(34,138)"
    />
    <path
      d="M0,0 L4,2 L2,8 L-1,12 L-4,23 L-8,23 L-10,42 L-12,47 L-16,49 L-17,46 L-13,46 L-13,41 L-11,41 L-11,23 L-10,22 L-5,22 L-5,14 L-3,14 L-4,10 L-2,10 L-2,6 L0,6 L2,2 L0,2 Z "
      fill="#EEAE34"
      transform="translate(44,66)"
    />
    <path
      d="M0,0 L3,3 L6,9 L6,17 L0,32 L-1,28 L1,20 L3,18 L4,12 L4,8 L2,8 L2,5 L-3,5 L0,4 Z "
      fill="#DCE3F3"
      transform="translate(88,100)"
    />
    <path
      d="M0,0 L2,0 L1,4 L-2,5 L0,11 L9,14 L8,16 L1,17 L-3,15 L-2,12 L-3,10 L-3,3 L0,3 Z "
      fill="#E3E8F3"
      transform="translate(80,144)"
    />
    <path
      d="M0,0 L4,1 L4,15 L-5,17 L-4,14 L0,12 L1,9 L0,7 L2,7 L0,4 Z "
      fill="#EDF2FB"
      transform="translate(112,36)"
    />
    <path
      d="M0,0 L1,4 L0,8 L-2,8 L-2,13 L-4,13 L-4,22 L-7,27 L-9,27 L-7,22 L-5,12 Z "
      fill="#FEF2C9"
      transform="translate(19,132)"
    />
    <path
      d="M0,0 L1,0 L1,6 L-1,8 L-3,15 L-9,17 L-7,13 L-2,6 Z "
      fill="#EFB23B"
      transform="translate(50,114)"
    />
    <path
      d="M0,0 L2,0 L1,4 L-1,3 L-1,11 L1,11 L1,16 L-2,13 L-2,2 Z "
      fill="#FBEDC1"
      transform="translate(76,8)"
    />
    <path
      d="M0,0 L2,0 L0,7 L-1,8 L-6,8 L-5,6 L-2,6 L-3,2 L-1,2 Z "
      fill="#EFB23E"
      transform="translate(55,40)"
    />
    <path
      d="M0,0 L4,1 L4,11 L2,8 L0,4 Z "
      fill="#D8DBEB"
      transform="translate(112,36)"
    />
    <path
      d="M0,0 L3,0 L1,9 L-3,9 L-4,11 L-4,8 L0,8 Z "
      fill="#F1BF56"
      transform="translate(39,80)"
    />
    <path
      d="M0,0 L1,4 L-1,8 L-3,8 L-4,10 L-3,5 Z "
      fill="#FDECBB"
      transform="translate(46,72)"
    />
    <path
      d="M0,0 L1,0 L3,10 L-1,6 L-2,2 L0,2 Z "
      fill="#FEF4D1"
      transform="translate(64,93)"
    />
    <path
      d="M0,0 L1,4 L-2,7 L-5,6 Z "
      fill="#EEB642"
      transform="translate(40,131)"
    />
  </svg>
)
export default Cs2
