import * as React from 'react'
const Iraq = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={45}
    viewBox="0 0 50 45"
    {...props}
  >
    <path
      d="M0 0 C4.69 3.57 8.65 7.49 10.12 13.38 C10.9 20.21 10.27 25.75 6.12 31.38 C1.29 36.42 -2.48 38.51 -9.56 38.75 C-15.63 38.7 -19.34 37.37 -23.88 33.38 C-28.75 28.24 -30.3 23.61 -30.13 16.65 C-29.5 11.05 -26.81 6.31 -22.88 2.38 C-16.26 -2.42 -7.44 -3.86 0 0 Z "
      fill="#F6F3F3"
      transform="translate(34.875,3.625)"
    />
    <path
      d="M0 0 C11.88 0 23.76 0 36 0 C34.98 5.11 34.82 5.69 31.12 8.75 C30.43 9.34 29.73 9.94 29.01 10.55 C24.06 14.12 17.83 13.66 12 13 C6.86 11.01 2.95 7.67 0 3 C0 2.01 0 1.02 0 0 Z "
      fill="#111211"
      transform="translate(7,29)"
    />
    <path
      d="M0 0 C5.12 2.72 9.06 6.81 10.9 12.32 C-1.64 12.32 -14.18 12.32 -27.1 12.32 C-22.59 4.42 -22.59 4.42 -19.54 2.44 C-18.96 2.05 -18.38 1.66 -17.79 1.26 C-12.61 -1.64 -5.59 -1.82 0 0 Z "
      fill="#CC252A"
      transform="translate(33.1015625,2.68359375)"
    />
    <path
      d="M0 0 C0.33 0.66 0.66 1.32 1 2 C1.99 2 2.98 2 4 2 C4.33 1.34 4.66 0.68 5 0 C5.33 1.98 5.66 3.96 6 6 C-0.43 6.5 -0.43 6.5 -7 7 C-7 6.01 -7 5.02 -7 4 C-5.82 3.57 -5.82 3.57 -4.62 3.12 C-1.9 2.15 -1.9 2.15 0 0 Z "
      fill="#74A180"
      transform="translate(21,19)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5.33 1.98 5.66 3.96 6 6 C3.36 6 0.72 6 -2 6 C-1.34 4.02 -0.68 2.04 0 0 Z "
      fill="#98B9A2"
      transform="translate(31,19)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C1.44 3.82 -1.04 6.19 -4 9 C-4.66 8.67 -5.32 8.34 -6 8 C-5.07 6.66 -4.13 5.33 -3.19 4 C-2.67 3.26 -2.14 2.52 -1.61 1.75 C-0.81 0.88 -0.81 0.88 0 0 Z "
      fill="#CA4346"
      transform="translate(12,6)"
    />
    <path
      d="M0 0 C0.33 0.66 0.66 1.32 1 2 C1.99 2 2.98 2 4 2 C4.33 1.34 4.66 0.68 5 0 C5.33 1.98 5.66 3.96 6 6 C5.01 6 4.02 6 3 6 C3 5.01 3 4.02 3 3 C1.68 3.33 0.36 3.66 -1 4 C-0.67 2.68 -0.34 1.36 0 0 Z "
      fill="#ABC5B2"
      transform="translate(21,19)"
    />
  </svg>
)
export default Iraq
