import React from 'react'
const Albania = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#EB1C23"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L4,2 L9,4 L9,6 L11,6 L7,2 L7,0 L11,0 L12,4 L14,4 L15,0 L19,0 L18,3 L22,2 L24,2 L24,0 L26,0 L26,7 L25,12 L22,12 L25,13 L26,20 L21,21 L17,19 L16,16 L14,16 L18,20 L17,25 L14,28 L12,28 L8,23 L8,20 L9,19 L5,21 L3,20 L3,22 L1,22 L0,18 L1,10 L0,2 Z "
      fill="#201E1F"
      transform="translate(12,11)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M0,3 L-9,9 L-14,15 L-16,20 L-16,30 L-11,40 L-3,46 L1,48 L13,48 L22,43 L28,35 L30,30 L30,20 L25,11 L18,5 L13,3 Z "
      fill="#B4CDD2"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L4,2 L5,3 L5,13 L10,14 L9,17 L7,17 L7,21 L3,20 L3,22 L1,22 L0,18 L1,10 L0,2 Z "
      fill="#7E1D21"
      transform="translate(12,11)"
    />
    <path
      d="M0,0 L4,1 L5,8 L0,9 L-4,7 L-5,2 Z "
      fill="#7A1D21"
      transform="translate(33,23)"
    />
    <path
      d="M0,0 L1,2 L5,2 L6,0 L8,1 L7,6 L4,9 L2,9 L-2,4 L-2,1 Z "
      fill="#5B1E21"
      transform="translate(22,30)"
    />
    <path
      d="M0,0 L2,0 L2,7 L1,12 L-3,11 L-3,3 L0,2 Z "
      fill="#6D1E21"
      transform="translate(36,11)"
    />
  </svg>
)
export default Albania
