import * as React from 'react'
const AnonUser = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.3343 14.8672C15.0313 14.8672 13.8948 15.5917 13.3052 16.6591C12.9725 16.5653 12.5288 16.4858 11.9806 16.4858C11.4501 16.4858 11.018 16.5604 10.6891 16.6499C10.0983 15.5877 8.96465 14.8672 7.66534 14.8672C5.75848 14.8672 4.20703 16.4186 4.20703 18.3257C4.20703 20.2327 5.75848 21.784 7.66534 21.784C9.57238 21.784 11.1236 20.2327 11.1236 18.3257C11.1236 18.2138 11.118 18.1034 11.1075 17.9944C11.3227 17.9399 11.6149 17.8923 11.9806 17.8923C12.3686 17.8923 12.6733 17.9459 12.8913 18.0043C12.8816 18.1103 12.876 18.2173 12.876 18.3257C12.876 20.2327 14.4274 21.7842 16.3343 21.7842C18.2411 21.7842 19.7926 20.2327 19.7926 18.3257C19.7926 16.4188 18.2411 14.8672 16.3343 14.8672Z"
      fill="#101828"
    />
    <path
      d="M22.1398 10.4257H19.8405C19.7896 10.1409 19.7232 9.76996 19.6488 9.35742H4.35113C4.27679 9.76996 4.21032 10.1409 4.15942 10.4257H1.86016C0.834593 10.4257 0 11.2601 0 12.2858V12.3018C0 13.3275 0.834593 14.1619 1.86016 14.1619H22.1398C23.1655 14.1619 24 13.3275 24 12.3018V12.2858C24 11.2601 23.1654 10.4257 22.1398 10.4257Z"
      fill="#101828"
    />
    <path
      d="M19.0281 5.97319C18.7216 4.36919 18.3774 3.32 17.525 2.7044C16.5924 2.03057 15.1413 2.11535 14.2216 2.89758C14.1643 2.94628 14.105 2.99755 14.0438 3.05047C13.4762 3.54082 12.7699 4.15093 11.9998 4.15093C11.2297 4.15093 10.5234 3.54082 9.9558 3.05047C9.89464 2.99755 9.83532 2.94628 9.778 2.89758C8.85827 2.11535 7.40734 2.03057 6.47461 2.7044C5.62225 3.32 5.27801 4.36919 4.9715 5.97319C4.8717 6.49559 4.73895 7.21648 4.60547 7.94945H19.3941C19.2607 7.21648 19.1279 6.49559 19.0281 5.97319Z"
      fill="#101828"
    />
  </svg>
)
export default AnonUser
