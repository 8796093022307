import React from 'react'
const Georgia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#B4C3C9"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L6,0 L6,20 L26,20 L26,26 L6,26 L6,46 L0,46 L0,26 L-20,26 L-20,20 L0,20 Z "
      fill="#EE2E31"
      transform="translate(22,2)"
    />
    <path
      d="M0,0 L1,0 L1,20 L-19,20 L-16,11 L-9,4 L-4,1 Z "
      fill="#FCEEEF"
      transform="translate(21,2)"
    />
    <path
      d="M0,0 L20,0 L16,10 L11,15 L6,18 L0,19 Z "
      fill="#FCEFEF"
      transform="translate(28,28)"
    />
    <path
      d="M0,0 L19,0 L19,20 L10,17 L2,9 L0,4 Z "
      fill="#FCEFEF"
      transform="translate(3,28)"
    />
    <path
      d="M0,0 L6,1 L12,5 L18,12 L20,19 L0,19 Z "
      fill="#FCEFEF"
      transform="translate(28,3)"
    />
  </svg>
)
export default Georgia
