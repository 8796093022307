import * as React from 'react'
const Chip50 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={306}
    height={309}
    viewBox="0 0 306 309"
    {...props}
  >
    <path
      d="M0,0 L37,0 L54,3 L59,5 L69,9 L86,16 L102,25 L114,34 L125,43 L126,45 L130,46 L132,50 L134,50 L147,67 L158,86 L166,106 L168,115 L169,125 L171,138 L171,170 L168,188 L166,202 L157,224 L146,242 L136,255 L129,262 L124,265 L113,275 L103,282 L86,292 L66,300 L59,302 L58,304 L43,307 L35,308 L2,308 L-12,306 L-21,304 L-22,302 L-40,296 L-56,288 L-68,281 L-82,270 L-88,265 L-88,263 L-93,262 L-102,252 L-114,235 L-123,218 L-130,199 L-132,185 L-134,172 L-134,136 L-131,119 L-131,112 L-124,92 L-113,71 L-101,55 L-92,45 L-88,43 L-78,34 L-62,23 L-43,13 L-23,6 L-21,6 L-21,4 L-8,1 Z "
      fill="#878889"
      transform="translate(135,1)"
    />
    <path
      d="M0,0 L23,0 L34,2 L50,9 L61,16 L70,24 L79,36 L87,52 L90,65 L90,93 L83,113 L76,124 L67,135 L55,144 L39,152 L28,155 L21,156 L2,156 L-14,152 L-30,144 L-39,137 L-44,133 L-56,117 L-61,106 L-64,98 L-66,88 L-66,71 L-63,55 L-58,42 L-50,30 L-41,20 L-29,11 L-15,4 Z "
      fill="#EFEFF0"
      transform="translate(141,77)"
    />
    <path
      d="M0,0 L10,3 L31,12 L45,20 L57,29 L68,38 L70,42 L54,58 L49,63 L47,68 L46,74 L47,76 L35,75 L23,65 L10,57 L-9,49 L-22,46 L-54,46 L-71,50 L-83,55 L-96,62 L-110,73 L-117,80 L-125,91 L-133,105 L-139,121 L-142,136 L-143,164 L-148,177 L-152,181 L-166,184 L-180,188 L-184,188 L-187,175 L-189,162 L-189,136 L-185,112 L-184,109 L-168,113 L-162,115 L-152,115 L-147,112 L-142,102 L-136,90 L-126,76 L-125,73 L-125,66 L-129,60 L-143,46 L-145,45 L-144,41 L-134,32 L-120,22 L-104,13 L-90,7 L-81,4 L-76,5 L-72,19 L-68,32 L-63,36 L-15,36 L-10,33 L-6,22 Z "
      fill="#141415"
      transform="translate(192,6)"
    />
    <path
      d="M0,0 L37,0 L54,3 L58,5 L50,34 L46,40 L42,42 L-6,42 L-12,37 L-16,24 L-20,10 L-27,11 L-43,17 L-60,26 L-74,36 L-87,47 L-88,50 L-84,52 L-69,67 L-67,71 L-67,78 L-70,84 L-79,97 L-86,111 L-89,117 L-92,120 L-95,121 L-105,121 L-127,114 L-129,127 L-131,141 L-131,167 L-127,193 L-112,189 L-104,187 L-94,187 L-88,192 L-79,211 L-68,227 L-67,229 L-67,236 L-70,242 L-82,254 L-90,261 L-92,263 L-102,252 L-114,235 L-123,218 L-130,199 L-132,185 L-134,172 L-134,136 L-131,119 L-131,112 L-124,92 L-113,71 L-101,55 L-92,45 L-88,43 L-78,34 L-62,23 L-43,13 L-23,6 L-21,6 L-21,4 L-8,1 Z "
      fill="#EAEAEB"
      transform="translate(135,1)"
    />
    <path
      d="M0,0 L5,0 L8,9 L8,37 L1,57 L-6,68 L-15,79 L-27,88 L-43,96 L-54,99 L-61,100 L-80,100 L-96,96 L-112,88 L-121,81 L-126,77 L-136,64 L-135,60 L-128,53 L-127,51 L-124,50 L-122,48 L-119,45 L-116,45 L-115,42 L-103,35 L-97,38 L-93,37 L-85,37 L-84,38 L-84,43 L-87,45 L-93,45 L-98,42 L-101,43 L-104,48 L-99,52 L-93,54 L-85,54 L-76,50 L-74,47 L-74,36 L-77,31 L-82,28 L-92,28 L-92,24 L-74,24 L-74,19 L-63,15 L-61,16 L-66,22 L-68,27 L-68,41 L-62,50 L-57,53 L-45,53 L-39,50 L-35,43 L-34,41 L-34,28 L-38,21 L-40,17 L-49,14 L-55,13 L-53,11 L-31,5 L-9,1 Z "
      fill="#F5F5F6"
      transform="translate(223,133)"
    />
    <path
      d="M0,0 L24,0 L39,4 L53,10 L67,20 L77,30 L87,45 L93,59 L96,75 L96,95 L94,106 L90,118 L82,133 L70,147 L60,155 L50,161 L31,168 L21,170 L2,170 L-16,166 L-33,158 L-45,149 L-56,138 L-64,125 L-69,114 L-72,105 L-73,99 L-73,71 L-69,55 L-61,39 L-53,29 L-42,18 L-28,9 L-14,3 Z M-1,7 L-19,12 L-33,20 L-43,28 L-52,38 L-59,49 L-64,62 L-67,78 L-67,95 L-64,107 L-59,120 L-53,130 L-44,141 L-30,152 L-13,160 L1,163 L20,163 L36,160 L52,152 L61,146 L68,140 L78,127 L84,115 L89,100 L89,72 L85,56 L78,43 L71,34 L65,27 L51,17 L38,11 L29,8 L22,7 Z "
      fill="#646567"
      transform="translate(142,70)"
    />
    <path
      d="M0,0 L4,3 L4,5 L6,5 L19,22 L30,41 L38,61 L39,68 L26,72 L12,76 L3,76 L-3,71 L-11,54 L-21,39 L-24,34 L-24,25 L-20,19 L-10,10 Z "
      fill="#F6F6F7"
      transform="translate(263,46)"
    />
    <path
      d="M0,0 L10,0 L27,5 L33,7 L31,17 L23,36 L14,51 L5,63 L-4,73 L-9,70 L-25,54 L-27,50 L-27,41 L-16,25 L-9,11 L-6,5 Z "
      fill="#FBFBFB"
      transform="translate(266,188)"
    />
    <path
      d="M0,0 L10,0 L16,5 L25,24 L36,40 L37,42 L37,49 L34,55 L22,67 L14,73 L6,65 L-5,50 L-14,34 L-21,17 L-23,7 L-12,3 Z "
      fill="#F0F1F1"
      transform="translate(31,188)"
    />
    <path
      d="M0,0 L49,0 L54,4 L57,10 L62,31 L62,35 L55,37 L41,39 L9,39 L-11,36 L-13,35 L-12,26 L-8,9 L-5,4 Z "
      fill="#F8F9F9"
      transform="translate(129,267)"
    />
    <path
      d="M0,0 L2,0 L1,4 L-6,14 L-14,31 L-8,32 L-1,34 L1,28 L6,27 L0,45 L-1,51 L-1,79 L2,91 L7,103 L9,109 L8,111 L6,111 L-1,97 L-1,95 L-12,99 L-11,105 L-2,122 L2,127 L-1,128 L-8,118 L-14,106 L-20,88 L-22,76 L-22,52 L-18,34 L-12,19 L-4,5 Z "
      fill="#EEEEEF"
      transform="translate(71,90)"
    />
    <path
      d="M0,0 L13,0 L19,3 L25,11 L26,14 L26,27 L22,35 L17,39 L14,40 L2,40 L-4,36 L-10,27 L-10,13 L-6,5 Z "
      fill="#424344"
      transform="translate(164,147)"
    />
    <path
      d="M0,0 L1,0 L3,9 L10,18 L19,34 L24,44 L28,47 L37,47 L59,41 L60,41 L64,60 L64,65 L54,64 L36,61 L18,59 L17,61 L13,45 L5,28 L-5,14 L-10,9 L-10,7 L-2,7 L0,6 Z "
      fill="#808081"
      transform="translate(238,74)"
    />
    <path
      d="M0,0 L24,0 L39,4 L49,8 L51,13 L45,13 L45,11 L29,9 L22,8 L-1,8 L-19,13 L-33,21 L-43,29 L-51,38 L-58,49 L-63,62 L-67,77 L-70,82 L-71,84 L-72,95 L-73,95 L-73,71 L-69,55 L-61,39 L-53,29 L-42,18 L-28,9 L-14,3 Z "
      fill="#2B2B2B"
      transform="translate(142,70)"
    />
    <path
      d="M0,0 L2,0 L7,11 L15,6 L15,17 L24,14 L21,23 L19,29 L18,38 L-15,38 L-17,25 L-21,14 L-15,16 L-11,18 L-13,6 L-16,5 L-16,1 L-12,2 L-10,7 L-3,12 Z "
      fill="#868687"
      transform="translate(151,96)"
    />
    <path
      d="M0,0 L2,0 L2,3 L-15,3 L-15,7 L-13,6 L-5,6 L1,10 L4,15 L4,26 L0,31 L-8,34 L-16,34 L-25,30 L-28,26 L-23,20 L-18,22 L-16,23 L-10,23 L-8,22 L-8,17 L-16,17 L-20,18 L-24,16 L-23,12 L-18,9 L-17,2 L-3,1 Z "
      fill="#444546"
      transform="translate(146,154)"
    />
    <path
      d="M0,0 L14,1 L46,2 L46,1 L54,1 L49,6 L45,12 L43,21 L44,23 L32,22 L20,12 L7,4 L0,1 Z "
      fill="#2E2E2F"
      transform="translate(195,59)"
    />
    <path
      d="M0,0 L9,5 L19,13 L27,21 L37,36 L43,50 L43,54 L37,54 L36,56 L34,47 L27,34 L20,25 L15,19 L1,9 L-12,3 L-15,1 L-4,1 L-5,4 L-3,2 L0,2 Z "
      fill="#5F5F60"
      transform="translate(192,79)"
    />
    <path
      d="M0,0 L7,0 L8,4 L8,29 L7,35 L-3,34 L-7,33 L-7,2 Z "
      fill="#4C4D4E"
      transform="translate(246,137)"
    />
    <path
      d="M0,0 L8,0 L14,1 L14,33 L9,34 L0,34 L-1,28 L-1,5 Z "
      fill="#3C3C3D"
      transform="translate(52,138)"
    />
    <path
      d="M0,0 L3,1 L1,14 L-1,28 L-1,54 L3,80 L5,81 L3,81 L9,99 L17,116 L27,131 L36,142 L40,146 L38,150 L28,139 L16,122 L7,105 L0,86 L-2,72 L-4,59 L-4,23 L-1,6 Z "
      fill="#D6D6D7"
      transform="translate(5,114)"
    />
    <path
      d="M0,0 L9,2 L13,4 L10,13 L2,27 L-4,34 L-8,33 L-15,27 L-11,20 L-5,10 Z "
      fill="#5F6062"
      transform="translate(235,185)"
    />
    <path
      d="M0,0 L4,1 L13,14 L20,29 L20,31 L11,34 L6,33 L-2,17 L-8,9 L-6,5 Z "
      fill="#838384"
      transform="translate(228,90)"
    />
    <path
      d="M0,0 L2,0 L5,7 L5,12 L-3,16 L-3,26 L0,30 L-5,28 L2,37 L2,38 L-15,38 L-17,25 L-21,14 L-15,16 L-11,18 L-13,6 L-16,5 L-16,1 L-12,2 L-10,7 L-3,12 Z "
      fill="#818282"
      transform="translate(151,96)"
    />
    <path
      d="M0,0 L6,3 L11,7 L9,11 L1,24 L-4,34 L-11,33 L-17,31 L-14,22 L-5,6 Z "
      fill="#212122"
      transform="translate(74,90)"
    />
    <path
      d="M0,0 L4,0 L7,7 L7,12 L-12,22 L-18,27 L-22,26 L-28,17 L-16,8 Z "
      fill="#1E1E1F"
      transform="translate(115,60)"
    />
    <path
      d="M0,0 L4,1 L10,8 L10,10 L0,17 L-17,26 L-22,26 L-25,18 L-25,14 L-9,6 Z "
      fill="#737477"
      transform="translate(208,223)"
    />
    <path
      d="M0,0 L3,0 L11,16 L18,26 L16,30 L11,34 L6,33 L0,25 L-8,10 L-9,4 Z "
      fill="#828384"
      transform="translate(67,185)"
    />
    <path
      d="M0,0 L33,0 L35,8 L35,13 L28,14 L8,14 L-1,12 Z "
      fill="#6B6D6F"
      transform="translate(136,242)"
    />
    <path
      d="M0,0 L5,1 L21,9 L32,17 L26,25 L21,26 L11,19 L-1,13 L-4,12 L-3,6 Z "
      fill="#4C4C4D"
      transform="translate(186,60)"
    />
    <path
      d="M0,0 L4,1 L18,10 L29,15 L26,25 L21,26 L5,18 L-6,10 L-5,6 Z "
      fill="#535556"
      transform="translate(94,223)"
    />
    <path
      d="M0,0 L29,0 L33,2 L32,13 L-1,13 L-3,5 L-3,1 Z "
      fill="#212122"
      transform="translate(137,54)"
    />
    <path
      d="M0,0 L26,0 L28,6 L27,10 L27,7 L22,9 L8,9 L8,16 L2,20 L0,23 Z "
      fill="#A2A3A3"
      transform="translate(121,147)"
    />
    <path
      d="M0,0 L6,0 L13,2 L20,3 L23,5 L30,7 L41,12 L41,13 L34,13 L34,15 L13,13 L4,12 L3,14 Z "
      fill="#949495"
      transform="translate(252,121)"
    />
    <path
      d="M0,0 L3,0 L3,7 L6,25 L6,57 L3,75 L1,81 L-1,81 L0,70 L2,54 L2,27 L-2,6 L-2,1 Z "
      fill="#3A3A3A"
      transform="translate(300,114)"
    />
    <path
      d="M0,0 L1,0 L1,17 L-4,30 L-8,34 L-20,36 L-21,33 L-14,28 L-7,14 L-5,14 L-3,8 L-1,5 Z "
      fill="#333435"
      transform="translate(48,153)"
    />
    <path
      d="M0,0 L1,4 L5,5 L7,7 L7,17 L4,21 L-5,21 L-9,16 L-9,6 L-3,2 L0,2 Z "
      fill="#9B9C9D"
      transform="translate(156,106)"
    />
    <path
      d="M0,0 L4,3 L4,5 L6,5 L19,22 L30,41 L38,61 L39,68 L36,69 L30,51 L22,35 L12,19 L1,6 L-2,2 Z "
      fill="#D1D2D2"
      transform="translate(263,46)"
    />
    <path
      d="M0,0 L2,1 L0,6 L-10,17 L-20,32 L-31,55 L-35,68 L-39,69 L-37,60 L-29,41 L-21,26 L-9,10 Z "
      fill="#F7F7F7"
      transform="translate(43,46)"
    />
    <path
      d="M0,0 L3,0 L2,8 L-7,30 L-18,48 L-28,61 L-35,68 L-38,67 L-29,56 L-20,44 L-11,29 L-3,10 Z "
      fill="#9B9D9F"
      transform="translate(299,195)"
    />
    <path
      d="M0,0 L1,3 L23,6 L55,6 L72,3 L76,3 L76,5 L78,6 L63,9 L55,10 L22,10 L8,8 L-1,6 L-2,3 L0,3 Z "
      fill="#A9AAAC"
      transform="translate(115,299)"
    />
    <path
      d="M0,0 L7,0 L10,4 L10,14 L7,19 L5,20 L-1,20 L-4,15 L-4,5 Z "
      fill="#F7F7F7"
      transform="translate(169,157)"
    />
    <path
      d="M0,0 L5,5 L13,18 L18,30 L18,34 L12,34 L11,36 L9,27 L2,14 L-5,5 L-6,3 L-1,3 Z "
      fill="#A3A3A4"
      transform="translate(217,99)"
    />
    <path
      d="M0,0 L1,0 L3,9 L10,18 L14,25 L14,28 L7,28 L2,23 L-7,12 L-10,9 L-10,7 L-2,7 L0,6 Z "
      fill="#606061"
      transform="translate(238,74)"
    />
    <path
      d="M0,0 L7,1 L6,3 L-1,4 L-17,10 L-34,19 L-48,29 L-61,40 L-63,42 L-64,38 L-52,27 L-36,16 L-17,6 Z "
      fill="#88898B"
      transform="translate(109,8)"
    />
    <path
      d="M0,0 L8,1 L7,4 L4,4 L3,7 L-8,12 L-16,13 L-16,15 L-20,15 L-23,19 L-30,22 L-34,26 L-36,25 L-25,14 L-11,5 Z "
      fill="#1D1D1E"
      transform="translate(125,74)"
    />
    <path
      d="M0,0 L3,0 L3,32 L-2,33 L-9,33 L-9,24 L-3,16 L-1,10 L-3,11 Z "
      fill="#4A4B4C"
      transform="translate(63,139)"
    />
    <path
      d="M0,0 L1,4 L-2,14 L-11,30 L-21,43 L-31,53 L-45,63 L-66,73 L-75,74 L-73,72 L-57,66 L-42,58 L-31,49 L-20,38 L-10,23 L-3,7 Z "
      fill="#191919"
      transform="translate(254,183)"
    />
    <path
      d="M0,0 L13,0 L27,1 L25,11 L26,13 L14,12 L2,2 Z "
      fill="#404041"
      transform="translate(213,69)"
    />
    <path
      d="M0,0 L3,1 L1,14 L-1,28 L-1,54 L3,80 L5,81 L1,81 L-2,72 L-4,59 L-4,23 L-1,6 Z "
      fill="#848688"
      transform="translate(5,114)"
    />
    <path
      d="M0,0 L2,3 L9,20 L14,29 L9,34 L5,32 L-2,18 L0,18 L2,11 L-1,10 Z M-2,11 Z "
      fill="#6C6C6D"
      transform="translate(53,185)"
    />
    <path
      d="M0,0 L2,1 L-2,11 L-6,26 L-9,31 L-10,33 L-11,44 L-12,44 L-12,20 L-8,4 L-7,7 L-2,2 Z "
      fill="#404041"
      transform="translate(81,121)"
    />
    <path
      d="M0,0 L5,0 L9,4 L9,11 L4,15 L-2,14 L-5,10 L-4,3 Z "
      fill="#F2F2F2"
      transform="translate(216,35)"
    />
    <path
      d="M0,0 L6,0 L10,4 L10,11 L7,14 L0,14 L-4,10 L-4,4 Z "
      fill="#F8F8F8"
      transform="translate(215,260)"
    />
    <path
      d="M0,0 L7,0 L11,4 L10,11 L6,14 L-1,13 L-3,11 L-3,3 Z "
      fill="#F7F7F7"
      transform="translate(20,148)"
    />
    <path
      d="M0,0 L7,1 L9,3 L9,11 L6,14 L-1,14 L-5,9 L-4,3 Z "
      fill="#F8F8F8"
      transform="translate(86,260)"
    />
    <path
      d="M0,0 L6,0 L10,4 L10,10 L6,14 L-1,13 L-4,10 L-4,4 Z "
      fill="#F9F9F9"
      transform="translate(280,148)"
    />
    <path
      d="M0,0 L5,5 L8,9 L6,13 L1,17 L-4,16 L-8,11 L-7,7 Z "
      fill="#404142"
      transform="translate(77,202)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,10 L6,14 L1,14 L-4,9 L-3,3 Z "
      fill="#F5F5F5"
      transform="translate(85,36)"
    />
    <path
      d="M0,0 L4,1 L6,6 L9,11 L6,20 L5,20 L5,35 L2,36 L3,30 L1,30 L-5,13 L1,15 L5,17 L3,5 L0,4 Z "
      fill="#666767"
      transform="translate(135,97)"
    />
    <path
      d="M0,0 L9,2 L19,6 L21,11 L15,11 L15,9 L-1,7 L-8,6 L-26,5 L-26,4 L-3,3 Z "
      fill="#49494A"
      transform="translate(172,72)"
    />
    <path
      d="M0,0 L3,4 L5,11 L-4,14 L-9,13 L-11,2 L0,2 Z "
      fill="#969798"
      transform="translate(243,110)"
    />
    <path
      d="M0,0 L4,1 L8,6 L8,10 L6,11 L-3,11 L-2,15 L-7,10 L-7,6 Z "
      fill="#707172"
      transform="translate(228,90)"
    />
    <path
      d="M0,0 L1,0 L3,8 L8,21 L10,26 L4,30 L-2,17 L0,17 Z "
      fill="#868687"
      transform="translate(76,169)"
    />
    <path
      d="M0,0 L4,0 L4,2 L6,3 L-9,6 L-17,7 L-42,7 L-42,6 L-11,2 Z "
      fill="#9C9EA0"
      transform="translate(187,302)"
    />
    <path
      d="M0,0 L5,1 L13,5 L13,9 L-3,11 L-2,4 Z "
      fill="#343535"
      transform="translate(186,60)"
    />
    <path
      d="M0,0 L4,0 L3,11 L2,18 L3,22 L0,19 L-6,6 L-5,2 Z "
      fill="#6C6C6D"
      transform="translate(64,186)"
    />
    <path
      d="M0,0 L1,0 L2,24 L-8,25 L-6,16 L-1,10 Z "
      fill="#555657"
      transform="translate(64,147)"
    />
    <path
      d="M0,0 L5,5 L10,13 L10,16 L3,15 L-5,5 L-6,3 L-1,3 Z "
      fill="#8B8C8D"
      transform="translate(217,99)"
    />
    <path
      d="M0,0 L7,6 L10,9 L11,13 L6,14 L-7,4 L-7,3 L0,2 Z "
      fill="#747575"
      transform="translate(207,89)"
    />
    <path
      d="M0,0 L1,0 L4,17 L3,23 L4,22 L6,25 L3,31 L0,30 L-5,19 L-2,18 L0,19 L1,10 L1,7 L-1,7 Z M1,24 Z "
      fill="#585859"
      transform="translate(50,172)"
    />
    <path
      d="M0,0 L2,2 L2,8 L-1,14 L-8,21 L-10,21 L-12,25 L-19,33 L-24,34 L-22,30 L-6,15 L-2,10 Z "
      fill="#1B1B1C"
      transform="translate(68,230)"
    />
    <path
      d="M0,0 L3,0 L3,7 L5,17 L5,24 L2,27 L-2,6 L-2,1 Z "
      fill="#3B3B3B"
      transform="translate(300,114)"
    />
    <path
      d="M0,0 L2,0 L2,3 L-13,14 L-23,20 L-25,20 L-25,17 L-15,11 L-4,3 Z "
      fill="#363636"
      transform="translate(251,269)"
    />
    <path
      d="M0,0 L3,4 L3,27 L1,34 L0,34 Z "
      fill="#1E1E1E"
      transform="translate(303,135)"
    />
    <path
      d="M0,0 L2,1 L-2,5 L-16,12 L-24,18 L-27,18 L-26,15 L-12,6 Z "
      fill="#D9D9DA"
      transform="translate(115,57)"
    />
    <path
      d="M0,0 L2,0 L1,4 L-6,14 L-14,30 L-16,31 L-15,26 L-7,10 Z "
      fill="#D1D2D2"
      transform="translate(71,90)"
    />
    <path
      d="M0,0 L5,3 L9,9 L15,11 L20,15 L21,20 L12,14 L0,4 L0,2 L-2,1 Z "
      fill="#696A6B"
      transform="translate(47,262)"
    />
    <path
      d="M0,0 L1,0 L2,16 L1,21 L-1,21 L-5,10 L-4,6 L-1,4 Z "
      fill="#6F6F70"
      transform="translate(75,165)"
    />
    <path
      d="M0,0 L5,2 L21,13 L25,17 L21,17 L21,15 L17,14 L5,6 L0,3 Z "
      fill="#616263"
      transform="translate(227,21)"
    />
    <path
      d="M0,0 L1,0 L5,26 L7,27 L3,27 L0,18 L-1,13 L-1,1 Z "
      fill="#7F8082"
      transform="translate(3,168)"
    />
    <path
      d="M0,0 L19,7 L21,9 L18,10 L0,4 Z "
      fill="#777879"
      transform="translate(193,6)"
    />
    <path
      d="M0,0 L1,0 L1,26 L-1,37 L-3,37 L-2,25 Z "
      fill="#171718"
      transform="translate(258,145)"
    />
    <path
      d="M0,0 L4,1 L-2,5 L-10,8 L-13,8 L-12,5 Z "
      fill="#303030"
      transform="translate(216,291)"
    />
    <path
      d="M0,0 L2,1 L-2,5 L-4,9 L-9,13 L-11,15 L-12,11 Z "
      fill="#A0A1A3"
      transform="translate(57,35)"
    />
    <path
      d="M0,0 L2,0 L4,4 L4,9 L-2,11 L-1,4 Z "
      fill="#6E6E6F"
      transform="translate(151,96)"
    />
    <path
      d="M0,0 L4,1 L2,3 L-18,3 L-18,2 Z "
      fill="#8B8C8F"
      transform="translate(163,306)"
    />
    <path
      d="M0,0 L5,0 L2,2 L-3,7 L-4,17 L-6,16 L-6,7 L-2,1 Z "
      fill="#161616"
      transform="translate(169,155)"
    />
    <path
      d="M0,0 L2,0 L2,3 L-15,3 L-14,8 L-17,9 L-17,2 L-3,1 Z "
      fill="#686869"
      transform="translate(146,154)"
    />
    <path
      d="M0,0 L1,0 L1,10 L-3,15 L-9,17 L-11,15 L-4,12 L-1,7 Z "
      fill="#161616"
      transform="translate(149,170)"
    />
    <path
      d="M0,0 L2,3 L2,7 L-4,7 L-5,9 L-6,2 L0,3 Z "
      fill="#B6B6B7"
      transform="translate(233,126)"
    />
    <path
      d="M0,0 L8,6 L10,10 L7,10 L-1,3 Z "
      fill="#58595A"
      transform="translate(252,38)"
    />
    <path
      d="M0,0 L1,0 L3,13 L5,14 L1,14 L-1,9 L-1,1 Z "
      fill="#6C6D6F"
      transform="translate(5,181)"
    />
    <path
      d="M0,0 L1,0 L2,24 L-2,24 L-1,21 Z "
      fill="#858586"
      transform="translate(64,147)"
    />
    <path
      d="M0,0 L1,0 L1,8 L-2,8 L-6,5 Z "
      fill="#717172"
      transform="translate(165,102)"
    />
    <path
      d="M0,0 L1,4 L-2,14 L-5,14 L-2,4 Z "
      fill="#232324"
      transform="translate(254,183)"
    />
    <path
      d="M0,0 L2,2 L1,8 L-4,12 L-10,11 L-10,10 L-1,8 Z "
      fill="#1B1C1C"
      transform="translate(290,152)"
    />
    <path
      d="M0,0 L5,0 L2,1 L1,13 L-1,14 L-1,5 Z "
      fill="#CBCBCC"
      transform="translate(50,137)"
    />
    <path
      d="M0,0 L3,1 L2,9 L0,10 L-2,13 L-1,6 Z "
      fill="#8E8F91"
      transform="translate(5,114)"
    />
    <path
      d="M0,0 L6,2 L12,5 L11,7 L5,5 L0,2 Z "
      fill="#757577"
      transform="translate(215,15)"
    />
    <path
      d="M0,0 L3,1 L-3,8 L-6,6 Z "
      fill="#292929"
      transform="translate(259,261)"
    />
    <path
      d="M0,0 L5,0 L4,3 L2,3 L1,13 L0,13 Z "
      fill="#1E1E1E"
      transform="translate(52,138)"
    />
    <path
      d="M0,0 L1,0 L1,7 L-4,11 L-9,11 L-6,9 L-1,4 Z "
      fill="#212121"
      transform="translate(96,265)"
    />
    <path
      d="M0,0 L2,0 L0,4 L-2,9 L0,10 L-1,12 L-5,9 L-4,5 Z "
      fill="#2A2A2B"
      transform="translate(93,224)"
    />
    <path
      d="M0,0 L6,3 L6,5 L10,5 L10,7 L0,4 Z "
      fill="#666769"
      transform="translate(193,6)"
    />
    <path
      d="M0,0 L9,0 L5,2 L-4,4 L-3,1 Z "
      fill="#444545"
      transform="translate(132,77)"
    />
    <path
      d="M0,0 L2,3 L2,9 L1,11 L-1,11 Z "
      fill="#1F1F1F"
      transform="translate(300,184)"
    />
    <path
      d="M0,0 L2,0 L1,3 L-2,4 L-12,4 L-12,3 L-1,1 Z "
      fill="#0B0B0B"
      transform="translate(180,183)"
    />
    <path
      d="M0,0 L4,2 L4,5 L-1,4 Z "
      fill="#676768"
      transform="translate(151,89)"
    />
    <path
      d="M0,0 L6,0 L3,2 L-1,4 L-5,3 L-2,1 Z "
      fill="#1B1B1B"
      transform="translate(130,168)"
    />
    <path
      d="M0,0 L2,1 L-1,11 L-3,8 L-1,2 Z "
      fill="#515252"
      transform="translate(79,125)"
    />
    <path
      d="M0,0 L4,2 L3,5 L-1,4 Z "
      fill="#69696A"
      transform="translate(127,105)"
    />
    <path
      d="M0,0 L4,2 L3,5 L-1,4 Z "
      fill="#838484"
      transform="translate(176,105)"
    />
    <path
      d="M0,0 L2,1 L-1,4 L-6,4 L-6,2 Z "
      fill="#282828"
      transform="translate(198,298)"
    />
    <path
      d="M0,0 L4,1 L4,5 L0,4 Z "
      fill="#727373"
      transform="translate(166,97)"
    />
    <path
      d="M0,0 L1,4 L-2,9 L-4,8 Z "
      fill="#0F0F0F"
      transform="translate(188,172)"
    />
    <path
      d="M0,0 L5,2 L7,4 L4,5 L0,3 Z "
      fill="#767778"
      transform="translate(227,21)"
    />
  </svg>
)
export default Chip50
