import React from 'react'

const SerieA = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={173}
    viewBox="0 0 150 173"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,173 L0,173 Z "
      fill="#FDFEFE"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L25,0 L30,10 L35,23 L40,33 L45,46 L49,55 L57,73 L57,75 L43,84 L40,89 L38,89 L38,94 L36,94 L35,98 L32,98 L32,102 L28,102 L28,110 L34,111 L34,115 L26,115 L26,119 L33,119 L32,123 L26,123 L26,126 L36,125 L39,131 L41,131 L42,126 L47,112 L51,112 L57,128 L57,130 L52,129 L44,131 L44,133 L24,133 L24,139 L28,138 L58,137 L58,146 L27,146 L-1,145 L-2,146 L-32,146 L-32,139 L1,139 L1,133 L-3,137 L-7,136 L-4,132 L-4,130 L-9,131 L-9,132 L-25,132 L-27,131 L-27,133 L-32,133 L-32,127 L-28,126 L-23,126 L-23,123 L-30,121 L-31,120 L-31,114 L-28,112 L-19,113 L-18,116 L-19,120 L-16,120 L-16,112 L-4,112 L-4,115 L-12,115 L-12,119 L-2,119 L-2,112 L9,112 L14,113 L14,111 L16,110 L15,106 L12,105 L17,104 L17,101 L11,102 L-6,91 L-28,77 L-31,75 L-30,70 L-18,42 L-10,23 L-5,12 Z "
      fill="#17A4FC"
      transform="translate(64,18)"
    />
    <path
      d="M0,0 L0,3 L-1,5 L-3,5 L-3,10 L-5,10 L-6,14 L-9,14 L-9,18 L-13,18 L-13,26 L-7,27 L-7,31 L-15,31 L-15,35 L-8,35 L-9,39 L-15,39 L-15,42 L-5,41 L-2,47 L0,47 L1,42 L6,28 L10,28 L16,44 L16,46 L11,45 L3,47 L3,49 L-17,49 L-17,55 L-13,54 L17,53 L17,62 L-14,62 L-42,61 L-43,62 L-73,62 L-73,55 L-40,55 L-40,49 L-44,53 L-48,52 L-45,48 L-45,46 L-50,47 L-50,48 L-66,48 L-68,47 L-68,49 L-73,49 L-73,43 L-69,42 L-64,42 L-64,39 L-71,37 L-72,36 L-72,30 L-69,28 L-60,29 L-59,32 L-60,36 L-57,36 L-57,28 L-45,28 L-45,31 L-53,31 L-53,35 L-43,35 L-43,28 L-32,28 L-27,29 L-27,27 L-25,26 L-26,22 L-29,21 L-24,20 L-25,16 L-11,7 Z "
      fill="#F6F7FB"
      transform="translate(105,102)"
    />
    <path
      d="M0,0 L2,1 L2,3 L5,3 L5,8 L7,8 L8,12 L8,21 L14,21 L14,23 L16,23 L16,25 L26,27 L26,29 L30,30 L30,32 L37,33 L38,35 L40,35 L41,40 L43,40 L44,44 L34,51 L13,64 L3,71 L-2,70 L-21,58 L-40,46 L-41,44 L-16,44 L-4,64 L3,67 L4,60 L7,60 L7,46 L8,44 L-6,44 L-16,43 L-9,23 L-5,12 Z "
      fill="#0C7CF5"
      transform="translate(76,49)"
    />
    <path
      d="M0,0 L4,2 L5,4 L12,6 L12,3 L14,3 L14,5 L20,5 L22,6 L22,2 L27,2 L26,6 L25,4 L20,15 L15,29 L9,45 L32,47 L32,61 L31,63 L28,63 L27,70 L22,69 L18,65 L8,49 L8,47 L-18,48 L-19,44 L-9,21 L-2,4 Z "
      fill="#0981FB"
      transform="translate(52,46)"
    />
    <path
      d="M0,0 L25,0 L30,10 L34,20 L34,26 L31,26 L31,24 L26,23 L18,21 L18,19 L8,18 L10,17 L10,14 L3,14 L3,12 L1,12 L1,10 L-4,10 Z "
      fill="#25CDFB"
      transform="translate(64,18)"
    />
    <path
      d="M0,0 L3,3 L7,15 L7,17 L-2,17 L-3,23 L-8,35 L15,35 L17,40 L17,43 L-16,43 L-9,23 L-5,12 Z "
      fill="#12145E"
      transform="translate(76,49)"
    />
    <path
      d="M0,0 L9,1 L10,4 L9,8 L12,8 L12,0 L24,0 L24,3 L16,3 L16,7 L23,7 L23,10 L16,10 L16,15 L24,15 L24,18 L19,19 L19,20 L3,20 L1,19 L1,21 L-4,21 L-4,15 L0,14 L5,14 L5,11 L-2,9 L-3,8 L-3,2 Z "
      fill="#F6F7FC"
      transform="translate(36,130)"
    />
    <path
      d="M0,0 L10,0 L24,1 L24,15 L23,17 L20,17 L19,24 L14,23 L10,19 L0,3 Z "
      fill="#20ABF7"
      transform="translate(60,92)"
    />
    <path
      d="M0,0 L25,0 L39,23 L41,26 L36,24 L20,14 L1,2 Z "
      fill="#161659"
      transform="translate(35,93)"
    />
    <path
      d="M0,0 L25,0 L21,4 L3,15 L-13,25 L-15,24 L-6,9 Z "
      fill="#161559"
      transform="translate(94,93)"
    />
    <path
      d="M0,0 L32,0 L33,1 L56,2 L55,4 L57,5 L57,3 L59,3 L59,6 L31,6 L30,7 L0,7 Z "
      fill="#B3D7BE"
      transform="translate(32,157)"
    />
    <path
      d="M0,0 L1,2 L27,2 L27,3 L1,4 L-10,22 L-13,28 L-14,30 L-18,29 L-20,24 L-14,26 L-13,19 L-10,19 L-10,5 L-9,3 L-23,3 L-23,2 L0,2 Z "
      fill="#0973F3"
      transform="translate(93,90)"
    />
    <path
      d="M0,0 L10,0 L13,3 L13,8 L11,12 L13,18 L9,18 L7,11 L3,11 L3,18 L0,18 Z "
      fill="#170F4A"
      transform="translate(63,130)"
    />
    <path
      d="M0,0 L29,0 L29,6 L0,6 Z "
      fill="#BD2538"
      transform="translate(92,157)"
    />
    <path
      d="M0,0 L12,0 L12,14 L11,16 L8,16 L7,23 L2,22 L3,3 L4,1 Z "
      fill="#0D8BFC"
      transform="translate(72,93)"
    />
    <path
      d="M0,0 L8,0 L8,9 L-23,9 L-23,5 L-25,5 L-25,7 L-49,7 L-49,6 L-27,5 L-49,4 L-50,2 Z M-22,2 L-22,8 L7,8 L7,2 Z "
      fill="#F1D6D9"
      transform="translate(114,155)"
    />
    <path
      d="M0,0 L4,0 L10,16 L10,18 L6,17 L6,14 L-2,14 L-2,18 L-6,18 L-3,8 Z "
      fill="#241D55"
      transform="translate(111,130)"
    />
    <path
      d="M0,0 L12,0 L12,3 L4,3 L4,7 L11,7 L11,10 L4,10 L4,15 L12,15 L12,18 L0,18 Z "
      fill="#272158"
      transform="translate(48,130)"
    />
    <path
      d="M0,0 L28,0 L28,5 L0,5 Z "
      fill="#0C9B40"
      transform="translate(33,158)"
    />
    <path
      d="M0,0 L12,0 L12,3 L4,3 L4,7 L11,7 L11,10 L3,10 L3,15 L12,15 L12,18 L0,18 Z "
      fill="#19134C"
      transform="translate(86,130)"
    />
    <path
      d="M0,0 L6,0 L8,1 L8,3 L0,3 L1,6 L7,8 L9,10 L9,16 L6,18 L-1,18 L-4,15 L0,14 L5,14 L5,11 L-2,9 L-3,8 L-3,2 Z "
      fill="#1E194E"
      transform="translate(36,130)"
    />
    <path
      d="M0,0 L0,3 L-4,15 L-12,36 L-13,36 L-13,30 L-10,21 L-12,23 L-14,27 L-15,27 L-15,21 L-14,20 L-8,20 L-8,13 L-6,13 L-6,10 L-4,10 L-3,5 Z "
      fill="#1573E1"
      transform="translate(73,55)"
    />
    <path
      d="M0,0 L6,0 L8,2 L10,0 L10,5 L8,9 L10,15 L6,15 L4,8 L0,7 L3,6 L0,5 Z "
      fill="#29245B"
      transform="translate(66,133)"
    />
    <path
      d="M0,0 L26,0 L26,3 L0,3 Z "
      fill="#D60622"
      transform="translate(93,159)"
    />
    <path
      d="M0,0 L4,0 L4,18 L0,18 Z "
      fill="#3A3665"
      transform="translate(79,130)"
    />
    <path
      d="M0,0 L1,0 L0,7 L24,7 L24,9 L-2,10 L-3,6 Z "
      fill="#1E66D4"
      transform="translate(36,84)"
    />
    <path
      d="M0,0 L2,3 L1,7 L4,7 L4,14 L1,14 L-1,8 L-8,5 L-8,2 L0,2 Z "
      fill="#DBDBEB"
      transform="translate(44,131)"
    />
    <path
      d="M0,0 L1,2 L27,2 L27,3 L1,4 L-1,6 L-1,3 L-23,3 L-23,2 L0,2 Z "
      fill="#235ABC"
      transform="translate(93,90)"
    />
    <path
      d="M0,0 L2,0 L4,4 L6,3 L9,13 L5,12 L5,8 L-1,7 Z "
      fill="#3A3561"
      transform="translate(112,135)"
    />
    <path
      d="M0,0 L2,3 L10,21 L9,23 L8,19 L6,19 L5,14 L2,15 L1,10 L2,6 L0,6 Z "
      fill="#268AE9"
      transform="translate(111,70)"
    />
    <path
      d="M0,0 L2,0 L0,5 L-4,13 L-5,15 L-9,14 L-11,9 L-5,11 L-4,4 L-1,4 Z "
      fill="#1F7AD9"
      transform="translate(84,105)"
    />
    <path
      d="M0,0 L1,3 L-7,3 L-7,7 L0,7 L-1,10 L-1,8 L-10,8 L-10,1 L0,2 Z "
      fill="#4C4678"
      transform="translate(97,130)"
    />
    <path
      d="M0,0 L1,4 L0,8 L-2,8 L-2,15 L-4,16 L-6,22 L-8,22 L-7,17 Z "
      fill="#2678E4"
      transform="translate(45,62)"
    />
    <path
      d="M0,0 L7,1 L8,5 L7,6 L-2,6 L-2,3 L1,3 Z "
      fill="#E0DFEE"
      transform="translate(34,139)"
    />
    <path
      d="M0,0 L1,4 L8,4 L8,7 L1,7 L1,12 L0,12 L-1,4 Z "
      fill="#413C70"
      transform="translate(51,133)"
    />
    <path
      d="M0,0 L3,1 L5,6 L9,5 L9,9 L5,9 L4,12 L0,2 Z "
      fill="#E9E9F2"
      transform="translate(115,131)"
    />
    <path
      d="M0,0 L7,0 L7,1 L1,1 L1,4 L11,3 L12,7 L9,8 L9,5 L0,5 Z "
      fill="#D8D7ED"
      transform="translate(89,140)"
    />
    <path
      d="M0,0 L6,0 L7,4 L6,5 L0,5 Z "
      fill="#DFDCEE"
      transform="translate(66,133)"
    />
    <path
      d="M0,0 L10,0 L6,2 L4,2 L5,9 L1,4 Z "
      fill="#3DAFE5"
      transform="translate(60,92)"
    />
    <path
      d="M0,0 L4,0 L6,6 L0,7 Z "
      fill="#E2E1ED"
      transform="translate(66,141)"
    />
    <path
      d="M0,0 L8,0 L7,3 L1,4 Z "
      fill="#DCDAEB"
      transform="translate(109,144)"
    />
    <path
      d="M0,0 L3,0 L5,5 L4,10 L2,7 L2,4 L0,3 Z "
      fill="#120B47"
      transform="translate(112,130)"
    />
    <path
      d="M0,0 L4,0 L4,3 L-6,3 L-6,1 Z "
      fill="#241E57"
      transform="translate(56,130)"
    />
    <path
      d="M0,0 L2,0 L0,7 L-2,12 L-3,9 L-2,3 L0,3 Z "
      fill="#D7F3FC"
      transform="translate(55,34)"
    />
    <path
      d="M0,0 L1,3 L0,6 L2,7 L0,9 L-3,3 L-3,1 Z "
      fill="#CBF5FE"
      transform="translate(99,33)"
    />
    <path
      d="M0,0 L2,0 L3,6 L-1,6 Z "
      fill="#D8D7EA"
      transform="translate(112,135)"
    />
    <path
      d="M0,0 L2,0 L1,5 L-1,10 L-2,10 L-2,4 Z "
      fill="#1860D6"
      transform="translate(62,81)"
    />
  </svg>
)
export default SerieA
