import * as React from 'react'
const Jamaica = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#F2CF0E"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,13 L85,19 L62,32 L50,39 L45,38 L37,34 L20,24 L9,18 L0,13 Z "
      fill="#059A49"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L7,1 L31,15 L42,21 L51,26 L51,38 L-45,38 L-45,26 L-26,15 L-8,5 Z "
      fill="#059A49"
      transform="translate(45,58)"
    />
    <path
      d="M0,0 L9,0 L10,3 L26,4 L26,6 L34,6 L34,13 L40,15 L50,21 L50,25 L54,24 L54,27 L52,27 L51,31 L48,30 L47,27 L47,30 L42,29 L42,26 L40,26 L40,29 L37,29 L37,27 L33,27 L29,20 L29,18 L23,20 L20,19 L20,17 L16,17 L14,22 L-2,31 L-13,37 L-27,45 L-30,46 L-30,28 L-24,28 L-23,26 L-16,25 L-15,21 L-3,15 L0,15 L1,12 L3,12 L2,7 L-1,7 L-1,1 Z M30,7 Z "
      fill="#FACF08"
      transform="translate(30,38)"
    />
    <path
      d="M0,0 L6,2 L17,9 L27,14 L40,22 L45,24 L50,25 L52,26 L50,28 L45,28 L39,26 L30,26 L29,32 L32,32 L33,37 L30,40 L21,44 L16,48 L14,48 L15,51 L10,51 L6,53 L0,53 Z "
      fill="#F3CE0F"
      transform="translate(0,13)"
    />
    <path
      d="M0,0 L2,0 L2,35 L-3,33 L-27,20 L-30,17 L-9,5 Z "
      fill="#090601"
      transform="translate(94,31)"
    />
    <path
      d="M0,0 L6,2 L27,14 L32,18 L5,33 L0,35 Z "
      fill="#070401"
      transform="translate(0,31)"
    />
    <path
      d="M0,0 L10,2 L10,5 L13,5 L13,7 L20,8 L21,10 L30,12 L30,9 L38,8 L35,11 L28,15 L23,14 L15,10 L0,1 Z "
      fill="#25982D"
      transform="translate(22,24)"
    />
    <path
      d="M0,0 L2,0 L2,2 L0,4 L-3,4 L-3,6 L-7,6 L-6,10 L-10,9 L-15,10 L-16,12 L-18,10 L-7,4 Z "
      fill="#D4D122"
      transform="translate(94,13)"
    />
    <path
      d="M0,0 L5,2 L21,11 L18,12 L10,11 L10,7 L4,4 L0,2 Z "
      fill="#24982D"
      transform="translate(50,59)"
    />
    <path
      d="M0,0 L2,0 L2,2 L-3,5 L-8,6 L-9,10 L-19,12 L-16,9 L-6,3 Z "
      fill="#2F992A"
      transform="translate(30,67)"
    />
    <path
      d="M0,0 L4,1 L12,6 L17,8 L22,9 L24,10 L22,12 L17,12 L14,9 L8,8 L3,3 Z "
      fill="#C9D327"
      transform="translate(28,29)"
    />
    <path
      d="M0,0 L4,1 L-12,10 L-14,11 L-16,7 L-11,7 L-8,4 L-4,4 L-4,1 Z "
      fill="#D7D424"
      transform="translate(40,59)"
    />
    <path
      d="M0,0 L5,0 L2,1 L3,6 L-3,5 L-5,7 L-13,9 L-10,6 L-1,1 Z "
      fill="#2A9A2A"
      transform="translate(45,58)"
    />
    <path
      d="M0,0 L3,0 L3,2 L7,2 L7,4 L12,3 L13,8 L7,6 L0,2 Z "
      fill="#E4C427"
      transform="translate(0,29)"
    />
    <path
      d="M0,0 L2,0 L2,2 L-1,2 L-1,6 L-7,6 L-7,8 L-9,8 L-8,4 Z "
      fill="#229832"
      transform="translate(9,79)"
    />
    <path
      d="M0,0 L2,1 L0,3 L-1,7 L-10,7 L-7,4 Z "
      fill="#D1D025"
      transform="translate(74,24)"
    />
    <path
      d="M0,0 L6,2 L11,6 L3,5 L3,2 L0,2 Z "
      fill="#22952D"
      transform="translate(85,79)"
    />
    <path
      d="M0,0 L5,0 L0,4 L-4,5 L-4,2 L0,2 Z "
      fill="#1F952E"
      transform="translate(73,22)"
    />
    <path
      d="M0,0 L4,0 L4,2 L8,3 L8,5 L2,3 L0,2 Z "
      fill="#1F9433"
      transform="translate(0,11)"
    />
    <path
      d="M0,0 L4,0 L4,2 L9,3 L9,5 L3,3 Z "
      fill="#DBC02A"
      transform="translate(13,37)"
    />
  </svg>
)
export default Jamaica
