import React from 'react'
const Poland = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#FDFDFD"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L46,0 L45,7 L41,15 L33,21 L28,23 L18,23 L10,19 L5,14 L2,10 L0,4 Z "
      fill="#ED1E25"
      transform="translate(2,25)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M2,2 L-6,6 L-13,13 L-16,20 L-16,29 L-12,38 L-7,43 L-1,47 L2,48 L12,48 L20,44 L25,40 L29,32 L30,29 L30,20 L26,12 L18,5 L11,2 Z "
      fill="#B4C7CD"
      transform="translate(18,0)"
    />
  </svg>
)
export default Poland
