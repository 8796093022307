import * as React from 'react'
const Andorra = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#FBDB06"
    />
    <path
      d="M25 7h1v39l3-1 2 5h2v4h-3l1 10 3 2 9 1 2 4 6 1 4-1 1-3 12-3 1-1 1-10h-3v-4h2v-2l4-2 1-39h1l1 87-11 5-4 1H39l-11-4-4-3Z"
      fill="#FDDE00"
    />
    <path
      d="m76 7 4 2 8 7 6 8 5 11 1 4v22l-4 11-6 10-8 8-4 3h-2Z"
      fill="#CF0E3A"
    />
    <path
      d="M22 7h2v86l-4-2-8-7-6-8-5-11-1-4V39l4-11 6-10 8-8Z"
      fill="#0016A8"
    />
    <path
      d="M44 27h12v6h3v-3h6l3 2-1 4-1 2 5 3v6l-2 3h-2v4h3v10l-4 3-10 1-1 4-6 1-5-2-1-3-9-1-4-3V54h3v-4h-2l-2-3v-6l5-4-2-2 1-4 1-1h6l1 3h3Z"
      fill="#BAA16B"
    />
    <path
      d="m36 34 26 2v11l-13 1v12H39l-4-3 1-8h-2v-4h2l-1-4Z"
      fill="#C28F66"
    />
    <path
      d="M34 30h6l1 3 3 1h-5l-1-3-3 1 1 10v5h-2v2h2v8l1 5 7 1v2l-7-1-3-2v2l9 2v1h-9l-4-3V54h3v-4h-2l-2-3v-6l5-4-2-2 1-4Z"
      fill="#B49A68"
    />
    <path d="M50 36h12v11H50Z" fill="#E88F14" />
    <path d="m37 48 12 1v11H39l-2-2Z" fill="#E9910D" />
    <path d="M51 49h11v10l-1 1H51Z" fill="#F3D219" />
    <path d="m43 36 4 3v5h2v2l-6 1-5-1v-2l2-1 1-5Z" fill="#F1C9B4" />
    <path d="M55 49h7v10l-1 1H51v-6l1 3 8 2v-9h-5Z" fill="#F7D50B" />
    <path d="m37 48 3 1v11l-3-2Z" fill="#DF8529" />
    <path d="m43 36 4 4-2 4h-3l-1-4Z" fill="#FBE16D" />
    <path d="M53 50h7v4h-5Z" fill="#DC7621" />
    <path d="M53 55h7v4l-7-2Z" fill="#DC7520" />
    <path d="M44 36h5v7h-2l-2-5Z" fill="#DD530A" />
    <path d="M38 36h4l-2 7h-2Z" fill="#D41612" />
  </svg>
)
export default Andorra
