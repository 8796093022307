import * as React from 'react'
const Argentina = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96px"
    height="96px"
    viewBox="0 0 96 96"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#5f9cd2"
        d="M -0.5,-0.5 C 31.5,-0.5 63.5,-0.5 95.5,-0.5C 95.5,8.83333 95.5,18.1667 95.5,27.5C 63.5,27.5 31.5,27.5 -0.5,27.5C -0.5,18.1667 -0.5,8.83333 -0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#669ad0"
        d="M -0.5,27.5 C 31.5,27.5 63.5,27.5 95.5,27.5C 95.5,28.5 95.5,29.5 95.5,30.5C 63.5,30.5 31.5,30.5 -0.5,30.5C -0.5,29.5 -0.5,28.5 -0.5,27.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fbfdfe"
        d="M -0.5,30.5 C 31.5,30.5 63.5,30.5 95.5,30.5C 95.5,41.8333 95.5,53.1667 95.5,64.5C 63.5,64.5 31.5,64.5 -0.5,64.5C -0.5,53.1667 -0.5,41.8333 -0.5,30.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e6e1c3"
        d="M 47.5,35.5 C 48.6828,35.1558 49.5161,35.8225 50,37.5C 50.9745,35.1224 51.6411,35.4557 52,38.5C 52.4186,37.3279 53.0853,36.3279 54,35.5C 55.6804,36.8398 56.5137,38.1732 56.5,39.5C 57.0998,40.8999 58.0998,41.8999 59.5,42.5C 58.1667,42.8333 56.8333,43.1667 55.5,43.5C 55.6716,42.5084 55.3382,41.8417 54.5,41.5C 53.1667,40.8333 51.8333,40.1667 50.5,39.5C 48.4821,38.9528 46.6487,37.9528 45,36.5C 43.5993,38.1922 41.7659,38.8588 39.5,38.5C 40.7282,37.9751 41.5616,36.6418 42,34.5C 42.3333,35.1667 42.6667,35.8333 43,36.5C 44.3561,35.6198 45.8561,35.2865 47.5,35.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#f3f0e9"
        d="M 47.5,35.5 C 45.8561,35.2865 44.3561,35.6198 43,36.5C 42.6667,35.8333 42.3333,35.1667 42,34.5C 41.5616,36.6418 40.7282,37.9751 39.5,38.5C 37.9867,40.6512 36.3201,42.9845 34.5,45.5C 30.6893,45.4042 30.6893,44.7376 34.5,43.5C 32.621,40.753 33.121,38.0863 36,35.5C 36.6667,36.1667 37.3333,36.8333 38,37.5C 38.6839,35.641 39.6839,33.9743 41,32.5C 41.3333,32.8333 41.6667,33.1667 42,33.5C 43.4443,31.8446 45.2776,31.178 47.5,31.5C 47.5,32.8333 47.5,34.1667 47.5,35.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e1da8a"
        d="M 50.5,39.5 C 51.8333,40.1667 53.1667,40.8333 54.5,41.5C 54.6521,44.822 54.3188,48.1554 53.5,51.5C 53.6633,49.1432 53.4966,46.8098 53,44.5C 52.6232,43.0577 51.7899,42.0577 50.5,41.5C 49.3784,40.8442 48.0451,40.5108 46.5,40.5C 43.9503,40.6174 41.9503,41.6174 40.5,43.5C 40.33,42.1779 40.6634,41.0113 41.5,40C 44.4816,39.502 47.4816,39.3354 50.5,39.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e0dc5b"
        d="M 50.5,41.5 C 48.3453,41.372 46.3453,41.872 44.5,43C 45.6537,43.9138 45.487,44.7471 44,45.5C 43.6667,44.8333 43.3333,44.1667 43,43.5C 41.6814,45.2495 41.1814,47.2495 41.5,49.5C 40.5386,47.7354 40.2053,45.7354 40.5,43.5C 41.9503,41.6174 43.9503,40.6174 46.5,40.5C 48.0451,40.5108 49.3784,40.8442 50.5,41.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e9e35d"
        d="M 50.5,41.5 C 51.7899,42.0577 52.6232,43.0577 53,44.5C 53.4966,46.8098 53.6633,49.1432 53.5,51.5C 52.8333,51.5 52.1667,51.5 51.5,51.5C 51.6576,48.0977 51.3242,44.7644 50.5,41.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e8e046"
        d="M 50.5,41.5 C 51.3242,44.7644 51.6576,48.0977 51.5,51.5C 49.8984,53.0739 47.8984,53.7406 45.5,53.5C 44.5,53.5 43.5,53.5 42.5,53.5C 41.7659,52.2921 41.4326,50.9587 41.5,49.5C 41.1814,47.2495 41.6814,45.2495 43,43.5C 43.3333,44.1667 43.6667,44.8333 44,45.5C 45.487,44.7471 45.6537,43.9138 44.5,43C 46.3453,41.872 48.3453,41.372 50.5,41.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#eeebb4"
        d="M 54.5,41.5 C 55.3382,41.8417 55.6716,42.5084 55.5,43.5C 55.665,46.8499 55.4983,50.1832 55,53.5C 53.5557,55.1554 51.7224,55.822 49.5,55.5C 47.4727,55.6621 45.4727,55.4955 43.5,55C 42.944,54.6174 42.6107,54.1174 42.5,53.5C 43.5,53.5 44.5,53.5 45.5,53.5C 47.6282,54.6291 49.9615,54.7958 52.5,54C 53.2524,53.3292 53.5858,52.4959 53.5,51.5C 54.3188,48.1554 54.6521,44.822 54.5,41.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#ebe29b"
        d="M 40.5,43.5 C 40.2053,45.7354 40.5386,47.7354 41.5,49.5C 41.4326,50.9587 41.7659,52.2921 42.5,53.5C 40.2239,53.2435 39.0572,51.9102 39,49.5C 38.8333,48.5 38.6667,47.5 38.5,46.5C 38.6399,44.9704 39.3065,43.9704 40.5,43.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#faf8ed"
        d="M 50.5,59.5 C 52.2074,57.9853 54.2074,56.652 56.5,55.5C 57.0292,52.7428 58.0292,50.2428 59.5,48C 58.2475,46.1722 58.2475,44.3388 59.5,42.5C 58.0998,41.8999 57.0998,40.8999 56.5,39.5C 58.6945,38.8767 60.8611,38.5434 63,38.5C 63.8446,41.1303 63.3446,43.4636 61.5,45.5C 61.7093,46.086 62.0426,46.586 62.5,47C 61.8592,52.284 60.8592,57.4507 59.5,62.5C 56.3563,61.8279 53.3563,60.8279 50.5,59.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e5e18d"
        d="M 51.5,51.5 C 52.1667,51.5 52.8333,51.5 53.5,51.5C 53.5858,52.4959 53.2524,53.3292 52.5,54C 49.9615,54.7958 47.6282,54.6291 45.5,53.5C 47.8984,53.7406 49.8984,53.0739 51.5,51.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#f3f2d5"
        d="M 59.5,42.5 C 58.2475,44.3388 58.2475,46.1722 59.5,48C 58.0292,50.2428 57.0292,52.7428 56.5,55.5C 54.2074,56.652 52.2074,57.9853 50.5,59.5C 50.1667,59.5 49.8333,59.5 49.5,59.5C 49.6309,58.6236 49.2975,57.9569 48.5,57.5C 47.6852,59.2759 46.5185,59.9426 45,59.5C 44.5172,58.552 44.3505,57.552 44.5,56.5C 46.4147,56.7845 48.0813,56.4511 49.5,55.5C 51.7224,55.822 53.5557,55.1554 55,53.5C 55.4983,50.1832 55.665,46.8499 55.5,43.5C 56.8333,43.1667 58.1667,42.8333 59.5,42.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#efedc5"
        d="M 50.5,39.5 C 47.4816,39.3354 44.4816,39.502 41.5,40C 40.6634,41.0113 40.33,42.1779 40.5,43.5C 39.3065,43.9704 38.6399,44.9704 38.5,46.5C 38.6667,47.5 38.8333,48.5 39,49.5C 39.0572,51.9102 40.2239,53.2435 42.5,53.5C 42.6107,54.1174 42.944,54.6174 43.5,55C 45.4727,55.4955 47.4727,55.6621 49.5,55.5C 48.0813,56.4511 46.4147,56.7845 44.5,56.5C 43.1779,56.33 42.0113,56.6634 41,57.5C 39.5993,55.8078 37.7659,55.1412 35.5,55.5C 35.7394,53.5328 35.4061,51.6995 34.5,50C 36.6031,49.1466 36.6031,47.98 34.5,46.5C 34.5,46.1667 34.5,45.8333 34.5,45.5C 36.3201,42.9845 37.9867,40.6512 39.5,38.5C 41.7659,38.8588 43.5993,38.1922 45,36.5C 46.6487,37.9528 48.4821,38.9528 50.5,39.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#f7f6ec"
        d="M 34.5,46.5 C 36.6031,47.98 36.6031,49.1466 34.5,50C 35.4061,51.6995 35.7394,53.5328 35.5,55.5C 37.7659,55.1412 39.5993,55.8078 41,57.5C 42.0113,56.6634 43.1779,56.33 44.5,56.5C 44.3505,57.552 44.5172,58.552 45,59.5C 46.5185,59.9426 47.6852,59.2759 48.5,57.5C 49.2975,57.9569 49.6309,58.6236 49.5,59.5C 48.596,60.209 48.2627,61.209 48.5,62.5C 45.8333,62.5 43.1667,62.5 40.5,62.5C 40.6399,60.8762 39.9733,59.7096 38.5,59C 39.4506,58.2825 39.6172,57.4491 39,56.5C 37.4117,58.9677 35.5784,59.1343 33.5,57C 32.2878,54.5774 30.9544,52.2441 29.5,50C 31.0585,48.7985 32.7251,47.6318 34.5,46.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#6897c3"
        d="M -0.5,64.5 C 31.5,64.5 63.5,64.5 95.5,64.5C 95.5,65.1667 95.5,65.8333 95.5,66.5C 63.5,66.5 31.5,66.5 -0.5,66.5C -0.5,65.8333 -0.5,65.1667 -0.5,64.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#5c9dd7"
        d="M -0.5,66.5 C 31.5,66.5 63.5,66.5 95.5,66.5C 95.5,67.5 95.5,68.5 95.5,69.5C 63.5,69.5 31.5,69.5 -0.5,69.5C -0.5,68.5 -0.5,67.5 -0.5,66.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#5f9dd2"
        d="M -0.5,69.5 C 31.5,69.5 63.5,69.5 95.5,69.5C 95.5,78.1667 95.5,86.8333 95.5,95.5C 63.5,95.5 31.5,95.5 -0.5,95.5C -0.5,86.8333 -0.5,78.1667 -0.5,69.5 Z"
      />
    </g>
  </svg>
)
export default Argentina
