import * as React from 'react'
const PredictionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={256}
    height={173}
    viewBox="0 0 256 173"
    {...props}
  >
    <path
      d="M0,0 L45,0 L56,3 L65,10 L75,24 L91,48 L92,51 L140,51 L146,55 L149,61 L148,70 L143,76 L141,77 L81,77 L75,73 L61,53 L58,49 L57,112 L-12,112 L-13,49 L-25,66 L-32,75 L-36,77 L-96,77 L-102,72 L-104,68 L-104,60 L-100,54 L-95,51 L-48,50 L-32,26 L-22,12 L-13,4 L-6,1 Z "
      fill="#FFFFFF"
      transform="translate(106,60)"
    />
    <path
      d="M0,0 L11,0 L21,4 L27,9 L32,17 L34,25 L34,32 L31,42 L22,52 L14,56 L10,57 L1,57 L-8,54 L-16,48 L-21,41 L-23,35 L-23,22 L-19,13 L-12,5 L-4,1 Z "
      fill="#FEFEFE"
      transform="translate(123,1)"
    />
    <path
      d="M0,0 L24,0 L25,1 L25,23 L41,23 L41,26 L32,37 L21,50 L13,60 L9,58 L0,47 L-11,34 L-18,25 L-17,23 L-1,23 L-1,1 Z "
      fill="#FFFFFF"
      transform="translate(208,41)"
    />
    <path
      d="M0,0 L4,2 L13,13 L24,26 L31,35 L30,37 L14,37 L14,59 L13,60 L-11,60 L-12,59 L-12,37 L-28,37 L-28,34 L-19,23 L-8,10 Z "
      fill="#FEFEFE"
      transform="translate(35,41)"
    />
  </svg>
)
export default PredictionIcon
