export const APP_URL =
  process.env.NEXT_PUBLIC_MAIN_URL ?? 'https://lilbit.vaionex.cloud'

export const VERIFY_EMAIl_ENDPOINT =
  process.env.REACT_APP_VERIFY_EMAIl_ENDPOINT ||
  `${APP_URL}/emailVerified?verifyToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZGF0YSI6IkFkbWluMSIsImlhdCI6MTY2NTk4OTIxMCwiZXhwIjoxNjY2MDA3MjEwfQ.uEp8SNgGU7fB3BaaUir8P_1EK8sbVOedt4lYNX3uFZM`

export const RELYSIA_API_URL = 'https://api.relysia.com'
export const RELYSIA_API_WSS = 'api.relysia.com'
export const SERVICE_ID = '118bed16-31ed-4a26-8883-8661f1c1967e'

export const casinoEnabled = process.env.NEXT_PUBLIC_CASINO_ENABLED === 'true'

export const NEXT_SERVER =
  process.env.NEXT_PUBLIC_SERVER_URL || 'https://lilbit.vaionex.cloud/api/'

export const RESET_PASSWORD_ENDPOINT =
  process.env.NEXT_PUBLIC_RESET_PASSWORD_ENDPOINT ||
  `${APP_URL}/forget-password`

export const SERVER_ENDPOINT = 'https://api.lilb.it/api/'
// export const SERVER_ENDPOINT = 'http://localhost:5050/api/'

export const PUBLIC_NOVU_APPLICATION_IDENTIFIER =
  process.env.PUBLIC_NOVU_APPLICATION_IDENTIFIER || 'oHDpByUSs-qf'

export const growthbookClientKey = process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY
export const DEFAULT_WALLET = 'relysia'
export const HANDCASH_WALLET = 'handcash'

export const BLACKJACK_ENDPOINT = 'casino/blackjack'
export const PLINKO_ENDPOINT = 'casino/plinko'
export const VIDEO_POKER_ENDPOINT = 'casino/video-poker'
