import * as React from 'react'
const ProvidersIcon = (props) => (
  <svg
    width={22}
    height={20}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 9h4.8c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C20 10.52 20 11.08 20 12.2V19m-8 0V4.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C10.48 1 9.92 1 8.8 1H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 2.52 2 3.08 2 4.2V19m19 0H1M5.5 5h3m-3 4h3m-3 4h3"
      stroke="green"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ProvidersIcon
