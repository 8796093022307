import React from 'react'

const D = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 34.86 34.86"
      width={34.86}
      height={34.86}
      {...props}
    >
      <defs>
        <linearGradient
          id="linear-d-gradient"
          x1={5.11}
          y1={5.11}
          x2={29.53}
          y2={29.53}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#eaeaea" />
          <stop offset={0.24} stopColor="#5e5e5e" />
          <stop offset={0.24} stopColor="#606060" />
          <stop offset={0.28} stopColor="#909090" />
          <stop offset={0.33} stopColor="#b7b7b7" />
          <stop offset={0.37} stopColor="#d3d3d3" />
          <stop offset={0.4} stopColor="#e4e4e4" />
          <stop offset={0.43} stopColor="#eaeaea" />
          <stop offset={0.63} stopColor="#eaeaea" />
          <stop offset={0.65} stopColor="#e3e3e3" />
          <stop offset={0.68} stopColor="#d2d2d2" />
          <stop offset={0.72} stopColor="#b5b5b5" />
          <stop offset={0.76} stopColor="#8e8e8e" />
          <stop offset={0.78} stopColor="#767676" />
          <stop offset={0.87} stopColor="#454545" />
          <stop offset={1} stopColor="#eaeaea" />
        </linearGradient>
        <linearGradient
          id="linear-d-gradient-2"
          x1={0.14}
          y1={33.61}
          x2={40.71}
          y2={-4.35}
          xlinkHref="#linear-d-gradient"
        />
        <linearGradient
          id="linear-d-gradient-3"
          x1={7.52}
          y1={7.52}
          x2={27.5}
          y2={27.5}
          xlinkHref="#linear-d-gradient"
        />
        <radialGradient
          id="radial-d-gradient"
          cx={6.28}
          cy={5.66}
          fx={6.28}
          fy={5.66}
          r={27.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#8e8e8e" />
          <stop offset={0.04} stopColor="#888" />
          <stop offset={0.61} stopColor="#3a3a3a" />
          <stop offset={0.87} stopColor="#1c1c1c" />
        </radialGradient>
        <linearGradient
          id="linear-d-gradient-4"
          x1={39.51}
          y1={-4.73}
          x2={-1.59}
          y2={53.34}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.09} stopColor="#f8f8f8" />
          <stop offset={0.22} stopColor="#e6e6e6" />
          <stop offset={0.36} stopColor="#c8c8c8" />
          <stop offset={0.53} stopColor="#9e9e9e" />
          <stop offset={0.71} stopColor="#696969" />
          <stop offset={0.89} stopColor="#282828" />
          <stop offset={1} stopColor="#000" />
        </linearGradient>
        <radialGradient
          id="radial-d-gradient-2"
          cx={13.94}
          cy={16.81}
          fx={13.94}
          fy={16.81}
          r={14.78}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.29} stopColor="#fcfcfc" />
          <stop offset={0.48} stopColor="#f3f3f3" />
          <stop offset={0.64} stopColor="#e4e4e4" />
          <stop offset={0.78} stopColor="#cfcfcf" />
          <stop offset={0.91} stopColor="#b4b4b4" />
          <stop offset={1} stopColor="#9e9e9e" />
        </radialGradient>
      </defs>
      <g style={{ isolation: 'isolate' }} className="cls-8">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <rect
                className="cls-1"
                strokeWidth={0}
                fill="url(#linear-d-gradient)"
                x={0}
                y={0}
                width={34.86}
                height={34.86}
                rx={17.43}
                ry={17.43}
              />
              <rect
                className="cls-3"
                fill="url(#linear-d-gradient-2)"
                strokeWidth={0}
                x={0.45}
                y={0.45}
                width={33.96}
                height={33.96}
                rx={16.98}
                ry={16.98}
              />
              <rect
                className="cls-4"
                fill="url(#linear-d-gradient-3)"
                strokeWidth={0}
                x={3.59}
                y={3.59}
                width={27.69}
                height={27.69}
                rx={13.84}
                ry={13.84}
              />
              <rect
                className="cls-6"
                fill="url(#radial-d-gradient)"
                strokeWidth={0}
                x={4}
                y={4}
                width={26.86}
                height={26.86}
                rx={13.43}
                ry={13.43}
              />
              <g opacity={0.8} className="cls-9">
                <path
                  className="cls-7"
                  fill="#fff"
                  strokeWidth={0}
                  d="M21.84,19.61c-.44.8-1.08,1.42-1.9,1.87-.83.45-1.8.67-2.92.67h-3.97v-10.52h3.97c1.13,0,2.11.22,2.93.65.82.44,1.46,1.05,1.9,1.84.44.79.66,1.71.66,2.74s-.22,1.95-.66,2.75ZM19.1,19.08c.55-.53.83-1.26.83-2.22s-.28-1.69-.83-2.22-1.32-.79-2.31-.79h-1.21v6.01h1.21c.99,0,1.76-.26,2.31-.79Z"
                />
              </g>
              <path
                className="cls-2"
                opacity={0.5}
                style={{ mixBlendMode: 'multiply' }}
                fill="url(#linear-d-gradient-4)"
                strokeWidth={0}
                d="M30.86,17.43c0,3.71-1.5,7.07-3.93,9.5-2.43,2.43-5.79,3.93-9.5,3.93-2.5,0-4.85-.69-6.85-1.88,1.58.66,3.31,1.01,5.13,1.01,3.71,0,7.07-1.5,9.5-3.93s3.93-5.79,3.93-9.5-1.5-7.07-3.93-9.5c-.79-.79-1.68-1.49-2.65-2.06,1.65.68,3.14,1.68,4.38,2.92,2.43,2.43,3.93,5.79,3.93,9.5Z"
              />
              <path
                className="cls-5"
                fill="url(#radial-d-gradient-2)"
                style={{ mixBlendMode: 'color-burn' }}
                strokeWidth={0}
                d="M20.86,4.44c-7.42,5.16-12.2,14.15-13.23,22.17-2.25-2.4-3.63-5.63-3.63-9.18s1.5-7.07,3.93-9.5c2.43-2.43,5.79-3.93,9.5-3.93,1.18,0,2.33.16,3.43.44Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default D
