import * as React from 'react'
const BsvIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4556_5268)">
      <path
        d="M23.9999 12C23.9999 18.6259 18.6269 23.9981 11.9971 23.999C5.37205 23.999 -0.000959637 18.6259 5.14574e-07 11.999C-0.00191979 5.37216 5.37109 0 11.999 0C18.6269 0 23.9999 5.37312 23.9999 12Z"
        fill="#EAB300"
      />
      <path
        d="M16.7216 9.06054C16.567 7.4535 15.1796 6.91493 13.4264 6.76133V4.53125H12.0697V6.70181C11.7135 6.70181 11.3486 6.70853 10.9866 6.71621V4.53125H9.62996L9.629 6.75941C9.33519 6.76517 9.04619 6.77093 8.76487 6.77093V6.76421L6.89354 6.76325V8.21286C6.89354 8.21286 7.89593 8.19366 7.87865 8.2119C8.42882 8.2119 8.6074 8.53062 8.65925 8.80614V11.3463C8.69766 11.3463 8.74662 11.3482 8.80231 11.3559H8.65925L8.65829 14.9146C8.63429 15.0874 8.53251 15.363 8.14845 15.3639C8.16573 15.3793 7.16238 15.3639 7.16238 15.3639L6.89258 16.9844H8.65925C8.98762 16.9844 9.31119 16.9902 9.62804 16.9921L9.629 19.2471H10.9847V17.0161C11.3563 17.0238 11.7164 17.0266 12.0687 17.0266L12.0678 19.2471H13.4245V16.9969C15.7058 16.8663 17.3044 16.2913 17.5022 14.1486C17.6626 12.4234 16.8512 11.6525 15.556 11.3415C16.3443 10.9421 16.8368 10.2365 16.7216 9.06054ZM14.8224 13.8817C14.8224 15.5665 11.9372 15.3754 11.0164 15.3754V12.3869C11.9372 12.3889 14.8224 12.1249 14.8224 13.8817ZM14.1907 9.6663C14.1907 11.1994 11.7826 11.0199 11.0164 11.0209V8.31174C11.7836 8.31174 14.1916 8.0679 14.1907 9.6663Z"
        fill="white"
      />
      <path
        d="M8.66211 11.2869H8.86374V11.4357H8.66211V11.2869Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4556_5268">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default BsvIcon
