import * as React from 'react'
const Australia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0 0 C8.6 2.87 13.08 9.17 17 17 C17.8 25.72 18.18 33.51 12.93 40.94 C7.89 46.32 2.03 50.08 -5.36 50.39 C-13.58 50.56 -19.71 49.88 -26 44 C-32.57 35.95 -33.91 29.16 -33.26 18.99 C-32.48 13.05 -29.19 8.79 -24.67 5.1 C-16.76 -0.34 -9.46 -1.24 0 0 Z "
      fill="#0955B5"
      transform="translate(33,0)"
    />
    <path
      d="M0 0 C0 4.29 0 8.58 0 13 C-2.02 12.97 -4.04 12.93 -6.05 12.9 C-8.08 12.87 -8.08 12.87 -10 14 C-9.28 14.71 -8.56 15.42 -7.81 16.15 C-6.42 17.53 -6.42 17.53 -5 18.94 C-4.07 19.85 -3.14 20.77 -2.19 21.71 C0 24 0 24 0 25 C-2.12 25.38 -2.12 25.38 -5 25 C-7.24 23.21 -9.12 21.17 -11 19 C-11.33 20.98 -11.66 22.96 -12 25 C-15.3 25 -18.6 25 -22 25 C-22.86 11.05 -22.86 11.05 -20.06 7.44 C-13.5 2.21 -8.52 -0.77 0 0 Z "
      fill="#D92438"
      transform="translate(25,0)"
    />
    <path
      d="M0 0 C4.95 0 9.9 0 15 0 C15 0.99 15 1.98 15 3 C12.98 2.97 10.96 2.93 8.95 2.9 C6.92 2.87 6.92 2.87 5 4 C5.72 4.71 6.44 5.42 7.19 6.15 C8.58 7.53 8.58 7.53 10 8.94 C11.39 10.31 11.39 10.31 12.81 11.71 C15 14 15 14 15 15 C12.88 15.38 12.88 15.38 10 15 C7.76 13.21 5.88 11.17 4 9 C3.67 10.98 3.34 12.96 3 15 C2.01 15 1.02 15 0 15 C0 10.05 0 5.1 0 0 Z "
      fill="#EDE9EA"
      transform="translate(10,10)"
    />
    <path
      d="M0 0 C0 0.99 0 1.98 0 3 C-0.33 3.33 -0.66 3.66 -1 4 C-1.16 5.34 -1.25 6.69 -1.32 8.04 C-1.36 8.84 -1.4 9.65 -1.44 10.49 C-1.48 11.34 -1.52 12.19 -1.56 13.06 C-1.61 13.92 -1.65 14.77 -1.69 15.65 C-1.8 17.77 -1.9 19.88 -2 22 C-3.98 22 -5.96 22 -8 22 C-8.86 8.03 -8.86 8.03 -5.69 4.25 C-3 2 -3 2 0 0 Z "
      fill="#D7001B"
      transform="translate(11,3)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2 0.66 2 1.32 2 2 C2.99 2 3.98 2 5 2 C5.33 3.65 5.66 5.3 6 7 C5.34 7 4.68 7 4 7 C4 7.99 4 8.98 4 10 C2.02 10 0.04 10 -2 10 C-2 9.01 -2 8.02 -2 7 C-2.66 7 -3.32 7 -4 7 C-3.67 5.35 -3.34 3.7 -3 2 C-2.01 2 -1.02 2 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#DBDDE7"
      transform="translate(14,30)"
    />
    <path
      d="M0 0 C1.79 1.18 1.79 1.18 3.62 2.88 C4.24 3.43 4.85 3.98 5.48 4.55 C6.33 5.36 7.17 6.17 8 7 C8.04 8.67 8.04 10.33 8 12 C3.86 8.62 0.19 5.33 -3 1 C-2.01 0.67 -1.02 0.34 0 0 Z "
      fill="#D2656F"
      transform="translate(17,12)"
    />
    <path
      d="M0 0 C0 1.32 0 2.64 0 4 C-3.96 4 -7.92 4 -12 4 C-12 3.34 -12 2.68 -12 2 C-8.1 -0.6 -4.53 -0.15 0 0 Z "
      fill="#EAD1D3"
      transform="translate(25,0)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1 3.96 1 7.92 1 12 C0.01 12 -0.98 12 -2 12 C-2.29 3.43 -2.29 3.43 0 0 Z "
      fill="#EFF0F0"
      transform="translate(2,13)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C0.79 2.08 0.79 2.08 -0.44 3.19 C-4.47 7.89 -3.9 14.09 -4 20 C-4.33 20 -4.66 20 -5 20 C-5.86 6.03 -5.86 6.03 -2.5 2.12 C-1.26 1.07 -1.26 1.07 0 0 Z "
      fill="#DB4154"
      transform="translate(8,5)"
    />
    <path
      d="M0 0 C2.31 1.98 4.62 3.96 7 6 C6.34 6.66 5.68 7.32 5 8 C4.01 7.01 3.02 6.02 2 5 C1.34 5.66 0.68 6.32 0 7 C0 4.69 0 2.38 0 0 Z "
      fill="#3A66B9"
      transform="translate(13,18)"
    />
    <path
      d="M0 0 C0.99 0.33 1.98 0.66 3 1 C2.67 2.32 2.34 3.64 2 5 C0.68 4.67 -0.64 4.34 -2 4 C-2 3.01 -2 2.02 -2 1 C-1.34 0.67 -0.68 0.34 0 0 Z "
      fill="#CACEE0"
      transform="translate(37,35)"
    />
    <path
      d="M0 0 C1.32 0.33 2.64 0.66 4 1 C4 2.32 4 3.64 4 5 C2.68 5 1.36 5 0 5 C0 3.35 0 1.7 0 0 Z "
      fill="#C3C9DE"
      transform="translate(29,20)"
    />
    <path
      d="M0 0 C0.99 0.33 1.98 0.66 3 1 C2.67 2.32 2.34 3.64 2 5 C0.68 4.67 -0.64 4.34 -2 4 C-2 3.01 -2 2.02 -2 1 C-1.34 0.67 -0.68 0.34 0 0 Z "
      fill="#CBCFE0"
      transform="translate(37,11)"
    />
  </svg>
)
export default Australia
