import React from 'react'

const Fold = ({ customClass, onClick, text }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={180}
      height={79}
      viewBox="0 0 180 79"
      className={customClass}
      onClick={onClick}
    >
      <defs>
        <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
          <feOffset dx={12} dy={12} />
          <feGaussianBlur result="blur" stdDeviation={5} />
          <feFlood floodColor="#000" floodOpacity={0.75} />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="linear-fold-gradient"
          x1={28.45}
          y1={-22.05}
          x2={152.13}
          y2={101.63}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#eaeaea" />
          <stop offset={0.24} stopColor="#5e5e5e" />
          <stop offset={0.24} stopColor="#606060" />
          <stop offset={0.28} stopColor="#909090" />
          <stop offset={0.33} stopColor="#b7b7b7" />
          <stop offset={0.37} stopColor="#d3d3d3" />
          <stop offset={0.4} stopColor="#e4e4e4" />
          <stop offset={0.43} stopColor="#eaeaea" />
          <stop offset={0.63} stopColor="#eaeaea" />
          <stop offset={0.65} stopColor="#e3e3e3" />
          <stop offset={0.68} stopColor="#d2d2d2" />
          <stop offset={0.72} stopColor="#b5b5b5" />
          <stop offset={0.76} stopColor="#8e8e8e" />
          <stop offset={0.78} stopColor="#767676" />
          <stop offset={0.87} stopColor="#454545" />
          <stop offset={1} stopColor="#eaeaea" />
        </linearGradient>
        <linearGradient
          id="linear-fold-gradient-2"
          x1={148.18}
          y1={-16.96}
          x2={28.66}
          y2={102.56}
          xlinkHref="#linear-fold-gradient"
        />
        <linearGradient
          id="linear-fold-gradient-3"
          x1={35.35}
          y1={-15.15}
          x2={147.27}
          y2={96.77}
          xlinkHref="#linear-fold-gradient"
        />
        <radialGradient
          id="radial-fold-gradient"
          cx={23.1}
          cy={13.11}
          fx={23.1}
          fy={13.11}
          r={124.66}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.3} stopColor="#c22b2b" />
          <stop offset={1} stopColor="#c33" />
        </radialGradient>
        <radialGradient
          id="radial-fold-gradient-2"
          cx={105.5}
          cy={44.51}
          fx={105.5}
          fy={44.51}
          r={88.95}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.29} stopColor="#fcfcfc" />
          <stop offset={0.48} stopColor="#f3f3f3" />
          <stop offset={0.64} stopColor="#e4e4e4" />
          <stop offset={0.78} stopColor="#cfcfcf" />
          <stop offset={0.91} stopColor="#b4b4b4" />
          <stop offset={1} stopColor="#9e9e9e" />
        </radialGradient>
        <linearGradient
          id="linear-fold-gradient-4"
          x1={9.26}
          y1={40.36}
          x2={165.08}
          y2={40.36}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#000" />
          <stop offset={0.17} stopColor="#030303" />
          <stop offset={0.32} stopColor="#0f0f0f" />
          <stop offset={0.47} stopColor="#222" />
          <stop offset={0.62} stopColor="#3c3c3c" />
          <stop offset={0.77} stopColor="#5f5f5f" />
          <stop offset={0.91} stopColor="#888" />
          <stop offset={1} stopColor="#a8a8a8" />
        </linearGradient>
        <linearGradient
          id="linear-fold-gradient-5"
          x1={99.7}
          y1={49.42}
          x2={32}
          y2={-18.29}
          xlinkHref="#linear-fold-gradient-4"
        />
        <linearGradient
          id="linear-fold-gradient-6"
          x1={62.09}
          y1={12.41}
          x2={198.11}
          y2={144.57}
          gradientTransform="translate(1.23 1.23)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.09} stopColor="#f8f8f8" />
          <stop offset={0.22} stopColor="#e6e6e6" />
          <stop offset={0.36} stopColor="#c8c8c8" />
          <stop offset={0.53} stopColor="#9e9e9e" />
          <stop offset={0.71} stopColor="#696969" />
          <stop offset={0.89} stopColor="#282828" />
          <stop offset={1} stopColor="#000" />
        </linearGradient>
      </defs>
      <g style={{ isolation: 'isolate' }} className="cls-9">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g filter="url(#drop-shadow-1)" className="cls-10">
              <g>
                <rect
                  className="cls-1"
                  strokeWidth={0}
                  fill="url(#linear-fold-gradient)"
                  width={181.72}
                  height={80.72}
                  rx={10.92}
                  ry={10.92}
                />
                <rect
                  className="cls-4"
                  fill="url(#linear-fold-gradient-2)"
                  strokeWidth={0}
                  x={1.04}
                  y={1.04}
                  width={179.64}
                  height={78.64}
                  rx={10.19}
                  ry={10.19}
                />
                <rect
                  className="cls-5"
                  fill="url(#linear-fold-gradient-3)"
                  strokeWidth={0}
                  x={8.31}
                  y={8.31}
                  width={165.11}
                  height={64.11}
                  rx={8.41}
                  ry={8.41}
                />
                <rect
                  className="cls-7"
                  fill="url(#radial-fold-gradient)"
                  strokeWidth={0}
                  x={9.26}
                  y={9.26}
                  width={163.2}
                  height={62.2}
                  rx={7.89}
                  ry={7.89}
                />
                <rect
                  className="cls-6"
                  fill="url(#radial-fold-gradient-2)"
                  style={{ mixBlendMode: 'color-burn' }}
                  strokeWidth={0}
                  x={9.26}
                  y={9.26}
                  width={163.2}
                  height={62.2}
                  rx={7.89}
                  ry={7.89}
                />
                <path
                  className="cls-2"
                  style={{ mixBlendMode: 'screen' }}
                  fill="url(#linear-fold-gradient-4)"
                  strokeWidth={0}
                  d="M21.9,70.98c-.85-22.5,116.1-52.71,142.16-61.18.34-.18.68-.35,1.02-.52-.17-.01-.34-.03-.51-.03H17.15c-4.36,0-7.89,3.53-7.89,7.89v46.41c0,4.36,3.53,7.89,7.89,7.89h4.81c-.02-.16-.04-.32-.06-.48Z"
                />
                <path
                  className="cls-8"
                  fill="url(#linear-fold-gradient-5)"
                  style={{ mixBlendMode: 'screen' }}
                  strokeWidth={0}
                  d="M15.02,66.68c-.54-1-.84-2.15-.84-3.36V21.32c0-3.94,3.2-7.14,7.14-7.14h142.99c1.22,0,2.36.31,3.36.84-1.2-2.25-104.57-3.78-107.3-3.78H18.39c-3.94,0-7.14,3.2-7.14,7.14v41.99c0,2.73,1.53,5.1,3.78,6.3Z"
                />
                <path
                  className="cls-3"
                  style={{ mixBlendMode: 'overlay' }}
                  fill="url(#linear-fold-gradient-6)"
                  strokeWidth={0}
                  d="M166.95,14.3c.54,1,.84,2.15.84,3.36v41.99c0,3.94-104.2,7.14-108.14,7.14H17.66c-1.22,0-2.36-.31-3.36-.84,1.2,2.25,3.57,3.78,6.3,3.78h41.99c3.94,0,108.14-3.2,108.14-7.14V20.6c0-2.73-1.53-5.1-3.78-6.3Z"
                />
                <text
                  className="uppercase"
                  fill="#FEFEFE"
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  fontSize="20"
                  fontWeight="700"
                  dy=".33em"
                >
                  {text}
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Fold
