import React from 'react'
const Turkey = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#ED1D24"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M2,2 L-6,6 L-13,13 L-16,20 L-16,30 L-12,38 L-7,43 L-1,47 L2,48 L12,48 L20,44 L25,40 L30,30 L30,20 L26,12 L21,7 L17,4 L12,2 Z "
      fill="#B3CFD4"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L10,0 L12,3 L3,3 L0,6 L1,13 L3,15 L12,15 L10,18 L2,19 L-4,14 L-5,12 L-5,6 Z "
      fill="#FBDADA"
      transform="translate(17,16)"
    />
    <path
      d="M0,0 L5,0 L4,4 L5,6 L0,6 L0,4 L-3,4 L-3,2 L0,2 Z "
      fill="#FBDBDA"
      transform="translate(33,22)"
    />
  </svg>
)
export default Turkey
