import React from 'react'
const Nhl = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    viewBox="0 0 150 150"
    {...props}
  >
    <path
      d="M0,0 L5,2 L13,8 L17,9 L25,7 L27,3 L31,1 L40,8 L48,8 L59,0 L62,0 L67,13 L73,17 L80,19 L77,29 L75,36 L75,46 L79,60 L79,72 L76,82 L70,91 L62,98 L53,102 L37,106 L31,112 L26,110 L23,106 L6,102 L-2,98 L-10,91 L-16,82 L-19,71 L-19,62 L-15,51 L-14,38 L-17,28 L-19,24 L-18,19 L-8,15 L-4,7 L-1,1 Z "
      fill="#838484"
      transform="translate(43,17)"
    />
    <path
      d="M0,0 L5,2 L13,8 L17,9 L25,7 L27,3 L31,1 L40,8 L48,8 L59,0 L62,0 L67,13 L73,17 L80,19 L77,29 L75,36 L75,46 L79,60 L79,72 L76,82 L70,91 L62,98 L53,102 L37,106 L31,112 L26,110 L23,106 L6,102 L-2,98 L-10,91 L-16,82 L-19,71 L-19,62 L-15,51 L-14,38 L-17,28 L-19,24 L-18,19 L-8,15 L-4,7 L-1,1 Z M3,10 L-2,19 L-10,24 L-7,33 L-7,49 L-11,62 L-11,74 L-5,85 L4,93 L13,96 L26,99 L30,102 L34,100 L43,97 L55,94 L64,88 L70,79 L72,72 L72,61 L69,52 L68,46 L68,34 L71,24 L63,18 L58,10 L49,15 L38,15 L30,10 L25,14 L21,15 L11,15 Z "
      fill="#B9BEC2"
      transform="translate(43,17)"
    />
    <path
      d="M0,0 L5,2 L13,8 L17,9 L25,7 L27,3 L31,1 L40,8 L48,8 L59,0 L62,0 L67,13 L73,17 L80,19 L77,29 L75,36 L75,46 L79,60 L79,72 L76,82 L70,91 L62,98 L53,102 L37,106 L31,112 L26,110 L23,106 L6,102 L-2,98 L-10,91 L-16,82 L-19,71 L-19,62 L-15,51 L-14,38 L-17,28 L-19,24 L-18,19 L-8,15 L-4,7 L-1,1 Z M1,3 L-5,16 L-11,19 L-16,21 L-11,34 L-11,47 L-15,58 L-15,75 L-9,87 L-1,95 L7,99 L22,102 L28,106 L29,108 L33,107 L39,102 L54,99 L63,94 L68,90 L74,80 L76,74 L76,58 L73,51 L72,45 L72,37 L75,27 L77,21 L68,17 L64,12 L61,4 L57,5 L48,11 L41,11 L35,8 L32,4 L28,6 L23,10 L21,11 L13,11 L5,5 Z "
      fill="#5E5E5F"
      transform="translate(43,17)"
    />
    <path
      d="M0,0 L2,1 L5,10 L5,18 L0,28 L-7,34 L-23,38 L-28,40 L-34,39 L-47,36 L-43,32 L-27,20 L-6,4 Z "
      fill="#030303"
      transform="translate(104,70)"
    />
    <path
      d="M0,0 L4,1 L10,3 L17,4 L12,9 L1,17 L-20,33 L-31,41 L-32,39 L-29,28 L-29,14 L-30,8 L-24,1 L-18,3 L-8,3 L-1,1 Z "
      fill="#030303"
      transform="translate(72,36)"
    />
    <path
      d="M0,0 Z M-25,-8 L-22,-8 L-22,9 L-24,16 L-5,2 L0,1 L0,4 L-1,7 L-20,21 L-25,25 L-24,32 L-19,40 L-16,42 L-12,41 L4,29 L25,13 L35,6 L38,7 L36,13 L15,29 L3,38 L-6,45 L-6,46 L3,48 L5,52 L-12,50 L-20,46 L-27,38 L-31,29 L-31,18 L-26,3 Z "
      fill="#D7D8D8"
      transform="translate(65,60)"
    />
    <path
      d="M0,0 L8,5 L18,5 L25,2 L28,0 L35,5 L46,5 L55,0 L61,9 L68,14 L65,24 L65,36 L67,45 L69,51 L69,62 L65,73 L59,80 L50,85 L32,89 L29,92 L24,90 L21,88 L5,85 L-4,79 L-10,72 L-14,64 L-14,52 L-10,39 L-10,23 L-13,14 L-5,9 Z M1,4 L-5,12 L-9,15 L-8,23 L-8,40 L-12,51 L-12,62 L-6,74 L2,81 L7,83 L23,87 L29,89 L31,87 L38,85 L48,83 L56,79 L64,70 L67,63 L67,50 L63,38 L63,24 L65,15 L59,11 L55,4 L50,6 L47,7 L36,7 L29,4 L25,5 L21,7 L8,7 L3,4 Z "
      fill="#0F1010"
      transform="translate(46,27)"
    />
    <path
      d="M0,0 L2,0 L2,22 L-2,25 L-4,25 L-8,19 L-9,30 L-12,33 L-14,33 L-15,15 L-15,12 L-10,8 L-6,10 L-4,13 L-3,3 Z "
      fill="#DFE0DF"
      transform="translate(64,68)"
    />
    <path
      d="M0,0 L2,0 L2,22 L-2,25 L-4,25 L-5,18 L-9,20 L-9,30 L-14,32 L-15,15 L-16,12 L-11,8 L-9,8 L-8,14 L-5,11 L-4,3 Z "
      fill="#D2D3D3"
      transform="translate(83,54)"
    />
    <path
      d="M0,0 L1,0 L1,13 L-4,23 L-11,30 L-20,34 L-31,36 L-36,38 L-39,39 L-49,35 L-45,34 L-43,34 L-44,31 L-36,32 L-31,30 L-15,26 L-8,20 L-4,11 L-3,4 L-2,4 L-2,9 L0,9 Z "
      fill="#929DA7"
      transform="translate(112,77)"
    />
    <path
      d="M0,0 L5,3 L4,12 L3,24 L0,26 L-1,24 L1,24 L1,18 L-7,23 L-8,6 Z "
      fill="#020202"
      transform="translate(100,40)"
    />
    <path
      d="M0,0 L2,0 L3,14 L8,10 L10,10 L10,16 L-1,24 L-4,25 L-5,7 L-5,4 Z "
      fill="#D5D6D6"
      transform="translate(91,48)"
    />
    <path
      d="M0,0 L5,2 L7,3 L17,4 L17,5 L11,6 L11,7 L17,8 L17,9 L7,9 L1,6 L-1,10 L-4,13 L-3,19 L-3,33 L-4,33 L-4,21 L-7,21 L-7,32 L-8,32 L-9,19 L-10,11 L-3,5 Z "
      fill="#929BA1"
      transform="translate(47,31)"
    />
    <path
      d="M0,0 L3,3 L3,21 L8,19 L5,23 L0,22 L-5,16 L-8,9 L-7,4 Z "
      fill="#020202"
      transform="translate(47,80)"
    />
    <path
      d="M0,0 L2,1 L-1,4 L-1,7 L-9,12 L-21,15 L-28,17 L-33,22 L-35,22 L-35,17 L-30,12 L-12,8 L-2,2 Z "
      fill="#D7D9D9"
      transform="translate(108,103)"
    />
    <path
      d="M0,0 L5,0 L5,16 L1,26 L-5,34 L-8,32 L-5,29 L-5,26 L-1,18 L0,14 Z "
      fill="#A2ABB2"
      transform="translate(114,75)"
    />
    <path
      d="M0,0 L5,1 L10,3 L19,4 L19,5 L10,6 L10,8 L4,7 L0,6 L-3,8 L-17,8 L-16,5 L-5,3 Z "
      fill="#C7CBCE"
      transform="translate(72,31)"
    />
    <path
      d="M0,0 L10,0 L9,3 L-4,7 L-8,9 L-18,5 L-14,4 L-12,4 L-13,1 L-5,2 Z "
      fill="#B0B7BC"
      transform="translate(81,107)"
    />
    <path
      d="M0,0 L10,1 L17,3 L22,8 L21,13 L15,8 L2,5 L-2,3 L0,3 Z "
      fill="#8E9AA3"
      transform="translate(52,112)"
    />
    <path
      d="M0,0 L2,0 L6,10 L12,15 L18,17 L15,19 L9,19 L2,13 L0,10 Z "
      fill="#DADCDC"
      transform="translate(102,21)"
    />
    <path
      d="M0,0 L2,1 L4,14 L6,18 L6,26 L2,22 L-1,15 L-1,2 Z "
      fill="#DBDCDD"
      transform="translate(29,77)"
    />
    <path
      d="M0,0 L2,1 L0,7 L-5,11 L-9,12 L-19,12 L-15,10 L-16,8 L-7,6 Z "
      fill="#9AA4AC"
      transform="translate(73,21)"
    />
    <path
      d="M0,0 L1,0 L1,5 L-1,5 L-1,10 L1,10 L1,20 L-3,22 L-3,3 Z "
      fill="#E5E7E6"
      transform="translate(72,64)"
    />
    <path
      d="M0,0 L2,3 L7,9 L18,13 L16,20 L14,20 L13,23 L12,21 L14,15 L7,13 L1,6 Z "
      fill="#656766"
      transform="translate(105,23)"
    />
    <path
      d="M0,0 L3,4 L7,14 L7,27 L5,32 L4,32 L4,16 L2,7 L0,4 Z "
      fill="#444545"
      transform="translate(26,37)"
    />
    <path
      d="M0,0 L2,2 L3,6 L3,22 L2,22 L2,14 L-1,15 L-3,2 L0,2 Z "
      fill="#A6AFB6"
      transform="translate(34,44)"
    />
    <path
      d="M0,0 L5,3 L15,10 L14,12 L7,11 L2,6 Z "
      fill="#CFD2D4"
      transform="translate(44,20)"
    />
    <path
      d="M0,0 L7,5 L12,7 L13,10 L5,10 L0,6 Z "
      fill="#D2D5D6"
      transform="translate(74,22)"
    />
    <path
      d="M0,0 L2,0 L1,5 L-2,15 L-5,12 L-6,8 L-4,1 Z "
      fill="#9EA7AE"
      transform="translate(117,39)"
    />
    <path
      d="M0,0 L2,0 L4,5 L-2,15 L-5,11 Z "
      fill="#99A3AB"
      transform="translate(43,22)"
    />
    <path
      d="M0,0 L3,3 L3,11 L1,11 L5,24 L4,26 L0,17 L-1,12 L-1,3 Z "
      fill="#828383"
      transform="translate(25,76)"
    />
    <path
      d="M0,0 L9,3 L19,3 L24,8 L8,5 L0,1 Z "
      fill="#757676"
      transform="translate(41,114)"
    />
    <path
      d="M0,0 L2,0 L5,10 L10,18 L10,20 L8,20 L4,16 L0,5 Z "
      fill="#424243"
      transform="translate(26,87)"
    />
    <path
      d="M0,0 L2,0 L2,3 L-2,7 L-2,25 L-4,25 L-5,7 L-5,4 Z "
      fill="#CACBCA"
      transform="translate(54,76)"
    />
    <path
      d="M0,0 L2,0 L0,11 L-5,16 L-7,14 L-4,11 L-4,8 Z "
      fill="#B5BBC0"
      transform="translate(113,93)"
    />
    <path
      d="M0,0 L2,1 L-2,3 Z M-4,3 L-2,4 L-6,6 Z M-8,6 L-6,7 L-6,9 L-16,16 L-18,14 Z "
      fill="#1C1C1C"
      transform="translate(99,39)"
    />
    <path
      d="M0,0 L1,0 L1,13 L-1,16 L-4,15 L-3,4 L-2,4 L-2,9 L0,9 Z "
      fill="#B1BAC0"
      transform="translate(112,77)"
    />
    <path
      d="M0,0 L1,2 L-2,7 L-8,10 L-12,9 L-7,6 Z "
      fill="#4D4D4E"
      transform="translate(40,27)"
    />
    <path
      d="M0,0 L5,2 L8,4 L8,7 L2,6 L-2,3 L0,3 Z "
      fill="#A8B1B7"
      transform="translate(44,108)"
    />
    <path
      d="M0,0 L2,1 L0,3 L1,8 L3,14 L3,20 L2,20 L-1,10 L-3,6 L-2,1 Z "
      fill="#7F7F7E"
      transform="translate(27,35)"
    />
    <path
      d="M0,0 L4,0 L4,2 L8,3 L1,6 L-3,5 L-1,5 L-1,3 L-5,1 Z "
      fill="#8C979F"
      transform="translate(91,105)"
    />
    <path
      d="M0,0 L9,0 L10,3 L7,4 L-3,4 L1,2 Z "
      fill="#ADB5BB"
      transform="translate(57,29)"
    />
    <path
      d="M0,0 L15,1 L15,2 L6,3 L6,5 L0,4 Z "
      fill="#B3B4B5"
      transform="translate(76,34)"
    />
    <path
      d="M0,0 L1,0 L1,12 L-2,12 L-2,2 Z "
      fill="#E9EAEA"
      transform="translate(106,52)"
    />
    <path
      d="M0,0 L6,0 L12,4 L14,8 L10,6 L0,1 Z "
      fill="#3E3E3E"
      transform="translate(59,118)"
    />
    <path
      d="M0,0 L1,0 L1,10 L-5,5 Z "
      fill="#040404"
      transform="translate(59,71)"
    />
    <path
      d="M0,0 L2,3 L1,9 L0,9 L-1,15 L-2,15 L-2,3 L0,3 Z "
      fill="#BABDC0"
      transform="translate(112,48)"
    />
    <path
      d="M0,0 L1,0 L1,10 L-2,10 L-2,1 Z "
      fill="#E7E8E9"
      transform="translate(113,55)"
    />
    <path
      d="M0,0 L1,0 L1,16 L0,16 L0,8 L-3,9 L-3,5 L0,5 Z "
      fill="#8A9399"
      transform="translate(36,50)"
    />
    <path
      d="M0,0 L2,3 L8,11 L10,13 L6,12 L1,6 Z "
      fill="#404141"
      transform="translate(105,23)"
    />
    <path
      d="M0,0 L7,2 L3,4 L3,6 L-5,4 L-1,3 L1,3 Z "
      fill="#A6A9AA"
      transform="translate(68,108)"
    />
    <path
      d="M0,0 L2,2 L2,9 L5,10 L1,14 L-1,12 Z "
      fill="#3C3C3C"
      transform="translate(55,86)"
    />
    <path
      d="M0,0 Z M-2,1 L0,2 L-1,4 L0,11 L-2,12 L-2,5 L-7,7 L-6,4 Z "
      fill="#2C2C2C"
      transform="translate(76,57)"
    />
    <path
      d="M0,0 L1,0 L2,9 L1,14 L-1,11 L-1,3 Z "
      fill="#494A4A"
      transform="translate(115,51)"
    />
    <path
      d="M0,0 L3,0 L7,10 L6,12 L2,8 L2,4 L0,3 L2,3 L2,1 Z "
      fill="#7D7E7E"
      transform="translate(102,17)"
    />
    <path
      d="M0,0 L1,3 L-1,9 L-2,16 L-3,16 L-3,4 Z "
      fill="#404040"
      transform="translate(29,70)"
    />
    <path
      d="M0,0 L4,2 L4,4 L7,3 L9,9 L5,7 Z "
      fill="#767676"
      transform="translate(30,103)"
    />
    <path
      d="M0,0 L2,0 L3,5 L0,8 L-2,4 Z "
      fill="#ACB5BA"
      transform="translate(40,29)"
    />
    <path
      d="M0,0 L6,1 L3,3 L-6,5 L-5,3 L0,2 Z "
      fill="#6F706F"
      transform="translate(93,116)"
    />
    <path
      d="M0,0 L1,0 L1,13 L0,15 L-3,14 L-2,12 L0,12 Z "
      fill="#979DA2"
      transform="translate(112,77)"
    />
    <path
      d="M0,0 L1,2 L3,2 L5,8 L2,8 L0,4 Z "
      fill="#292929"
      transform="translate(32,87)"
    />
    <path
      d="M0,0 L2,0 L1,6 L-1,6 L-2,9 L-3,7 Z "
      fill="#4E4E4F"
      transform="translate(120,37)"
    />
    <path
      d="M0,0 L5,2 L1,4 L-4,5 Z "
      fill="#6E6F70"
      transform="translate(72,18)"
    />
    <path
      d="M0,0 L2,0 L2,6 L1,4 L-4,4 Z "
      fill="#ADAEAE"
      transform="translate(99,58)"
    />
    <path
      d="M0,0 Z M-2,1 L0,2 L0,4 L-6,6 L-7,3 Z "
      fill="#212223"
      transform="translate(71,29)"
    />
    <path
      d="M0,0 L6,0 L6,3 L8,4 L2,5 L4,4 L4,2 Z "
      fill="#7B8389"
      transform="translate(86,106)"
    />
  </svg>
)
export default Nhl
