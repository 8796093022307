import React from 'react'

const EuropaConference = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={163}
    viewBox="0 0 150 163"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,163 L0,163 Z "
      fill="#FDFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L5,0 L5,1 L15,1 L15,2 L16,2 L16,3 L19,3 L19,4 L20,4 L20,5 L19,5 L19,8 L20,8 L20,6 L21,6 L21,5 L22,5 L22,3 L23,3 L23,2 L25,2 L25,1 L31,1 L31,2 L33,2 L33,3 L34,3 L34,4 L35,4 L35,6 L36,6 L36,17 L37,17 L37,16 L38,16 L38,1 L47,1 L47,2 L49,2 L49,3 L50,3 L50,4 L51,4 L51,7 L53,7 L53,6 L54,6 L54,4 L55,4 L55,2 L56,2 L56,1 L60,1 L60,4 L61,4 L61,5 L62,5 L62,8 L63,8 L63,11 L64,11 L64,13 L65,13 L65,16 L66,16 L66,18 L63,18 L63,19 L62,19 L62,20 L68,20 L68,21 L67,21 L67,24 L62,24 L62,25 L66,25 L66,27 L62,27 L62,29 L66,29 L66,31 L59,31 L59,30 L58,30 L58,32 L56,32 L56,31 L52,31 L52,30 L49,30 L49,33 L50,33 L50,35 L49,35 L49,36 L48,36 L48,37 L43,37 L43,38 L47,38 L47,41 L43,41 L43,42 L47,42 L47,44 L40,44 L40,36 L39,36 L39,42 L38,42 L38,43 L37,43 L37,44 L23,44 L23,43 L22,43 L22,42 L21,42 L21,37 L22,37 L22,36 L23,36 L23,35 L29,35 L29,33 L30,33 L30,32 L28,32 L28,31 L25,31 L25,32 L24,32 L24,31 L22,31 L22,29 L21,29 L21,33 L20,33 L20,31 L13,31 L13,29 L12,29 L12,30 L11,30 L11,29 L10,29 L10,28 L12,28 L12,27 L7,27 L7,31 L5,31 L5,22 L4,22 L4,31 L1,31 L1,29 L0,29 L0,27 L-1,27 L-1,26 L-2,26 L-2,31 L-4,31 L-4,34 L-3,34 L-3,42 L2,42 L2,35 L10,35 L10,37 L11,37 L11,36 L12,36 L12,35 L13,35 L13,37 L14,37 L14,35 L16,35 L16,36 L17,36 L17,38 L18,38 L18,41 L19,41 L19,43 L20,43 L20,44 L18,44 L18,42 L13,42 L13,44 L12,44 L12,45 L10,45 L10,39 L9,39 L9,41 L5,41 L5,42 L9,42 L9,45 L-6,45 L-6,33 L-8,33 L-8,31 L-12,31 L-12,30 L-15,30 L-15,31 L-16,31 L-16,30 L-18,30 L-18,31 L-22,31 L-22,30 L-23,30 L-23,29 L-24,29 L-24,28 L-25,28 L-25,24 L-24,24 L-24,23 L-23,23 L-23,22 L-22,22 L-22,21 L-21,21 L-21,19 L-19,19 L-19,18 L-12,18 L-12,13 L-10,13 L-10,1 L-7,1 L-7,13 L-6,13 L-6,14 L-1,14 L-1,13 L0,13 Z "
      fill="#EBEBEB"
      transform="translate(54,107)"
    />
    <path
      d="M0,0 L6,0 L6,6 L7,6 L7,13 L8,13 L8,0 L14,0 L14,3 L15,3 L15,1 L18,1 L18,2 L21,2 L21,3 L22,3 L22,5 L21,5 L21,6 L23,6 L23,7 L22,7 L22,9 L25,9 L25,10 L26,10 L26,11 L28,11 L28,14 L29,14 L29,15 L30,15 L30,16 L31,16 L31,17 L32,17 L32,19 L33,19 L33,20 L34,20 L34,23 L35,23 L35,26 L36,26 L36,27 L37,27 L37,40 L36,40 L36,44 L35,44 L35,46 L34,46 L34,49 L33,49 L33,50 L32,50 L32,52 L31,52 L31,53 L30,53 L30,54 L29,54 L29,55 L28,55 L28,56 L27,56 L27,57 L26,57 L26,58 L25,58 L25,59 L24,59 L24,60 L23,60 L23,61 L18,61 L18,64 L-4,64 L-4,60 L-5,60 L-5,61 L-9,61 L-9,59 L-11,59 L-11,58 L-12,58 L-12,57 L-13,57 L-13,56 L-14,56 L-14,55 L-15,55 L-15,54 L-16,54 L-16,53 L-17,53 L-17,52 L-18,52 L-18,50 L-19,50 L-19,48 L-20,48 L-20,46 L-21,46 L-21,44 L-22,44 L-22,40 L-23,40 L-23,28 L-22,28 L-22,26 L-21,26 L-21,24 L-20,24 L-20,21 L-19,21 L-19,19 L-18,19 L-18,18 L-17,18 L-17,16 L-16,16 L-16,15 L-15,15 L-15,14 L-14,14 L-14,13 L-13,13 L-13,12 L-12,12 L-12,11 L-10,11 L-10,10 L-8,10 L-8,16 L-9,16 L-9,17 L-10,17 L-10,18 L-11,18 L-11,19 L-12,19 L-12,20 L-13,20 L-13,21 L-14,21 L-14,23 L-15,23 L-15,24 L-16,24 L-16,27 L-17,27 L-17,41 L-16,41 L-16,44 L-15,44 L-15,47 L-14,47 L-14,48 L-11,48 L-11,49 L-8,49 L-8,52 L-3,52 L-3,53 L-5,53 L-5,54 L-4,54 L-4,56 L-2,56 L-2,55 L0,55 L0,50 L1,50 L1,32 L2,32 L2,37 L3,37 L3,40 L5,40 L5,35 L4,35 L4,30 L3,30 L3,26 L2,26 L2,24 L1,24 L1,20 L0,20 L0,17 L-1,17 L-1,14 L-2,14 L-2,11 L-3,11 L-3,9 L-4,9 L-4,7 L-5,7 L-5,5 L-6,5 L-6,2 L-4,2 L-4,1 L0,1 Z "
      fill="#EAEDEA"
      transform="translate(68,12)"
    />
    <path
      d="M0,0 L5,0 L5,1 L15,1 L15,2 L16,2 L16,3 L19,3 L19,4 L20,4 L20,5 L19,5 L19,8 L20,8 L20,6 L21,6 L21,5 L22,5 L22,3 L23,3 L23,2 L25,2 L25,1 L31,1 L31,2 L33,2 L33,3 L34,3 L34,4 L35,4 L35,6 L36,6 L36,11 L35,11 L35,14 L34,14 L34,15 L33,15 L33,16 L32,16 L32,17 L24,17 L24,16 L20,16 L20,19 L16,19 L16,20 L15,20 L15,21 L21,21 L21,24 L16,24 L16,25 L20,25 L20,27 L16,27 L16,29 L20,29 L20,31 L13,31 L13,29 L12,29 L12,30 L11,30 L11,29 L10,29 L10,28 L12,28 L12,27 L7,27 L7,31 L5,31 L5,22 L4,22 L4,31 L1,31 L1,29 L0,29 L0,27 L-1,27 L-1,26 L-2,26 L-2,31 L-4,31 L-4,34 L-3,34 L-3,42 L2,42 L2,35 L10,35 L10,37 L11,37 L11,36 L12,36 L12,35 L13,35 L13,37 L14,37 L14,35 L16,35 L16,36 L17,36 L17,38 L18,38 L18,41 L19,41 L19,43 L20,43 L20,44 L18,44 L18,42 L13,42 L13,44 L12,44 L12,45 L10,45 L10,39 L9,39 L9,41 L5,41 L5,42 L9,42 L9,45 L-6,45 L-6,33 L-8,33 L-8,31 L-12,31 L-12,30 L-15,30 L-15,31 L-16,31 L-16,30 L-18,30 L-18,31 L-22,31 L-22,30 L-23,30 L-23,29 L-24,29 L-24,28 L-25,28 L-25,24 L-24,24 L-24,23 L-23,23 L-23,22 L-22,22 L-22,21 L-21,21 L-21,19 L-19,19 L-19,18 L-12,18 L-12,13 L-10,13 L-10,1 L-7,1 L-7,13 L-6,13 L-6,14 L-1,14 L-1,13 L0,13 Z "
      fill="#ECECEC"
      transform="translate(54,107)"
    />
    <path
      d="M0,0 L10,0 L10,1 L14,1 L14,2 L16,2 L16,3 L20,3 L20,5 L22,5 L22,6 L23,6 L23,7 L25,7 L25,8 L26,8 L26,9 L27,9 L27,11 L26,11 L26,10 L25,10 L25,9 L23,9 L23,12 L22,12 L22,13 L21,13 L21,18 L20,18 L20,21 L17,21 L17,19 L15,19 L15,18 L14,18 L14,17 L12,17 L12,16 L10,16 L10,15 L0,15 L0,16 L-3,16 L-3,17 L-4,17 L-4,18 L-6,18 L-6,19 L-7,19 L-7,20 L-8,20 L-8,21 L-9,21 L-9,19 L-8,19 L-8,17 L-11,17 L-11,16 L-12,16 L-12,15 L-13,15 L-13,14 L-14,14 L-14,12 L-13,12 L-13,13 L-12,13 L-12,14 L-11,14 L-11,15 L-8,15 L-8,11 L-9,11 L-9,10 L-11,10 L-11,8 L-12,8 L-12,9 L-15,9 L-15,10 L-16,10 L-16,11 L-17,11 L-17,9 L-16,9 L-16,8 L-15,8 L-15,7 L-14,7 L-14,6 L-12,6 L-12,5 L-11,5 L-11,4 L-9,4 L-9,3 L-7,3 L-7,2 L-4,2 L-4,1 L0,1 Z "
      fill="#A8A8A8"
      transform="translate(70,83)"
    />
    <path
      d="M0,0 L11,0 L11,1 L12,1 L12,3 L13,3 L13,5 L14,5 L14,6 L15,6 L15,0 L17,0 L17,9 L14,9 L14,7 L13,7 L13,6 L12,6 L12,5 L11,5 L11,10 L9,10 L9,2 L2,2 L2,4 L7,4 L7,6 L3,6 L3,8 L7,8 L7,10 L6,10 L6,11 L5,11 L5,12 L6,12 L6,13 L16,13 L16,16 L12,16 L12,17 L16,17 L16,20 L12,20 L12,21 L16,21 L16,23 L9,23 L9,15 L8,15 L8,21 L7,21 L7,22 L6,22 L6,23 L-8,23 L-8,22 L-9,22 L-9,21 L-10,21 L-10,16 L-9,16 L-9,15 L-8,15 L-8,14 L-2,14 L-2,12 L-1,12 L-1,11 L-3,11 L-3,10 L-6,10 L-6,11 L-7,11 L-7,10 L-8,10 L-8,1 L-2,1 L-2,2 L-1,2 L-1,3 L0,3 Z "
      fill="#9C9C9C"
      transform="translate(85,128)"
    />
    <path
      d="M0,0 L4,0 L4,1 L5,1 L5,2 L6,2 L6,4 L7,4 L7,0 L10,0 L10,1 L11,1 L11,3 L12,3 L12,5 L13,5 L13,6 L14,6 L14,0 L16,0 L16,10 L13,10 L13,8 L12,8 L12,6 L11,6 L11,5 L10,5 L10,10 L8,10 L8,13 L9,13 L9,21 L14,21 L14,14 L22,14 L22,16 L23,16 L23,15 L24,15 L24,14 L25,14 L25,16 L26,16 L26,14 L28,14 L28,15 L29,15 L29,17 L30,17 L30,20 L31,20 L31,22 L32,22 L32,23 L30,23 L30,21 L25,21 L25,23 L24,23 L24,24 L22,24 L22,18 L21,18 L21,20 L17,20 L17,21 L21,21 L21,24 L6,24 L6,12 L4,12 L4,10 L0,10 L0,9 L-1,9 L-1,8 L-2,8 L-2,7 L-3,7 L-3,2 L-2,2 L-2,1 L0,1 Z "
      fill="#787878"
      transform="translate(42,128)"
    />
    <path
      d="M0,0 L4,0 L4,3 L5,3 L5,4 L6,4 L6,7 L7,7 L7,10 L8,10 L8,12 L9,12 L9,15 L10,15 L10,17 L7,17 L7,18 L6,18 L6,19 L12,19 L12,20 L11,20 L11,23 L6,23 L6,24 L10,24 L10,26 L6,26 L6,28 L10,28 L10,30 L3,30 L3,29 L2,29 L2,31 L0,31 L0,30 L-4,30 L-4,29 L-5,29 L-5,28 L-6,28 L-6,22 L-5,22 L-5,21 L-4,21 L-4,20 L2,20 L2,17 L3,17 L3,16 L4,16 L4,15 L5,15 L5,14 L1,14 L1,15 L0,15 L0,14 L-2,14 L-2,15 L-3,15 L-3,16 L-6,16 L-6,13 L-5,13 L-5,11 L-4,11 L-4,8 L-3,8 L-3,5 L-2,5 L-2,3 L-1,3 L-1,1 L0,1 Z "
      fill="#D2D2D2"
      transform="translate(110,108)"
    />
    <path
      d="M0,0 L3,0 L3,4 L4,4 L4,8 L5,8 L5,13 L6,13 L6,19 L7,19 L7,24 L8,24 L8,28 L9,28 L9,32 L10,32 L10,37 L11,37 L11,40 L13,40 L13,37 L14,37 L14,39 L16,39 L16,43 L17,43 L17,47 L18,47 L18,52 L19,52 L19,55 L3,55 L3,54 L4,54 L4,49 L5,49 L5,31 L6,31 L6,36 L7,36 L7,39 L9,39 L9,34 L8,34 L8,29 L7,29 L7,25 L6,25 L6,23 L5,23 L5,19 L4,19 L4,16 L3,16 L3,13 L2,13 L2,10 L1,10 L1,8 L0,8 L0,6 L-1,6 L-1,4 L-2,4 L-2,1 L0,1 Z "
      fill="#030303"
      transform="translate(64,13)"
    />
    <path
      d="M0,0 L2,0 L2,6 L1,6 L1,7 L0,7 L0,8 L-1,8 L-1,9 L-2,9 L-2,10 L-3,10 L-3,11 L-4,11 L-4,13 L-5,13 L-5,14 L-6,14 L-6,17 L-7,17 L-7,31 L-6,31 L-6,34 L-5,34 L-5,37 L-4,37 L-4,38 L-3,38 L-3,39 L-2,39 L-2,41 L-1,41 L-1,42 L0,42 L0,43 L1,43 L1,44 L3,44 L3,45 L4,45 L4,48 L3,48 L3,50 L1,50 L1,49 L-1,49 L-1,48 L-2,48 L-2,47 L-3,47 L-3,46 L-4,46 L-4,45 L-5,45 L-5,44 L-6,44 L-6,43 L-7,43 L-7,42 L-8,42 L-8,40 L-9,40 L-9,38 L-10,38 L-10,36 L-11,36 L-11,34 L-12,34 L-12,30 L-13,30 L-13,18 L-12,18 L-12,16 L-11,16 L-11,14 L-10,14 L-10,11 L-9,11 L-9,9 L-8,9 L-8,8 L-7,8 L-7,6 L-6,6 L-6,5 L-5,5 L-5,4 L-4,4 L-4,3 L-3,3 L-3,2 L-2,2 L-2,1 L0,1 Z "
      fill="#1CB920"
      transform="translate(58,22)"
    />
    <path
      d="M0,0 L1,0 L1,1 L2,1 L2,2 L4,2 L4,3 L5,3 L5,4 L6,4 L6,5 L7,5 L7,6 L8,6 L8,7 L9,7 L9,9 L10,9 L10,10 L11,10 L11,13 L12,13 L12,16 L13,16 L13,17 L14,17 L14,30 L13,30 L13,34 L12,34 L12,36 L11,36 L11,39 L10,39 L10,40 L9,40 L9,42 L8,42 L8,43 L7,43 L7,44 L6,44 L6,45 L5,45 L5,46 L4,46 L4,47 L3,47 L3,48 L2,48 L2,49 L1,49 L1,50 L-1,50 L-1,49 L-2,49 L-2,47 L-3,47 L-3,45 L-2,45 L-2,44 L0,44 L0,43 L1,43 L1,42 L2,42 L2,41 L3,41 L3,40 L4,40 L4,38 L5,38 L5,37 L6,37 L6,34 L7,34 L7,32 L8,32 L8,30 L9,30 L9,20 L8,20 L8,17 L7,17 L7,14 L6,14 L6,13 L5,13 L5,11 L4,11 L4,10 L3,10 L3,9 L2,9 L2,8 L1,8 L1,7 L0,7 L0,6 L-1,6 L-1,2 L0,2 Z "
      fill="#19B81E"
      transform="translate(91,22)"
    />
    <path
      d="M0,0 L10,0 L10,1 L14,1 L14,2 L16,2 L16,3 L20,3 L20,5 L18,5 L18,4 L15,4 L15,7 L13,7 L13,6 L10,6 L10,7 L11,7 L11,8 L13,8 L13,9 L9,9 L9,12 L8,12 L8,8 L9,8 L9,5 L10,5 L10,4 L14,4 L14,3 L13,3 L13,2 L0,2 L0,3 L2,3 L2,5 L1,5 L1,6 L0,6 L0,7 L2,7 L2,8 L1,8 L1,9 L-1,9 L-1,11 L2,11 L2,12 L1,12 L1,13 L8,13 L8,14 L-2,14 L-2,15 L-3,15 L-3,17 L-4,17 L-4,18 L-6,18 L-6,19 L-7,19 L-7,20 L-8,20 L-8,21 L-9,21 L-9,19 L-8,19 L-8,17 L-11,17 L-11,16 L-12,16 L-12,15 L-13,15 L-13,14 L-14,14 L-14,12 L-13,12 L-13,13 L-12,13 L-12,14 L-11,14 L-11,15 L-8,15 L-8,11 L-9,11 L-9,10 L-11,10 L-11,8 L-12,8 L-12,9 L-15,9 L-15,10 L-16,10 L-16,11 L-17,11 L-17,9 L-16,9 L-16,8 L-15,8 L-15,7 L-14,7 L-14,6 L-12,6 L-12,5 L-11,5 L-11,4 L-9,4 L-9,3 L-7,3 L-7,2 L-4,2 L-4,1 L0,1 Z "
      fill="#EBEBEB"
      transform="translate(70,83)"
    />
    <path
      d="M0,0 L6,0 L6,1 L8,1 L8,2 L9,2 L9,3 L10,3 L10,5 L11,5 L11,10 L10,10 L10,13 L9,13 L9,14 L8,14 L8,15 L7,15 L7,16 L-1,16 L-1,15 L-2,15 L-2,14 L-3,14 L-3,12 L-4,12 L-4,4 L-3,4 L-3,2 L-2,2 L-2,1 L0,1 Z "
      fill="#1A1A1A"
      transform="translate(79,108)"
    />
    <path
      d="M0,0 L5,0 L5,6 L6,6 L6,13 L7,13 L7,0 L12,0 L12,6 L11,6 L11,14 L10,14 L10,28 L9,28 L9,23 L8,23 L8,20 L7,20 L7,31 L8,31 L8,37 L7,37 L7,33 L6,33 L6,26 L5,26 L5,22 L4,22 L4,18 L3,18 L3,12 L2,12 L2,8 L1,8 L1,4 L0,4 Z "
      fill="#595959"
      transform="translate(69,12)"
    />
    <path
      d="M0,0 L11,0 L11,1 L12,1 L12,3 L13,3 L13,5 L14,5 L14,6 L15,6 L15,0 L17,0 L17,9 L14,9 L14,7 L13,7 L13,6 L12,6 L12,5 L11,5 L11,10 L9,10 L9,2 L2,2 L2,4 L7,4 L7,6 L3,6 L3,8 L7,8 L7,10 L0,10 L0,6 L-2,6 L-2,9 L-4,9 L-4,7 L-5,7 L-5,6 L-7,6 L-7,10 L-8,10 L-8,1 L-2,1 L-2,2 L-1,2 L-1,3 L0,3 Z "
      fill="#A5A5A5"
      transform="translate(85,128)"
    />
    <path
      d="M0,0 L9,0 L9,1 L10,1 L10,2 L11,2 L11,3 L12,3 L12,7 L11,7 L11,9 L10,9 L10,12 L11,12 L11,14 L12,14 L12,16 L9,16 L9,15 L8,15 L8,13 L7,13 L7,12 L6,12 L6,10 L3,10 L3,16 L0,16 Z "
      fill="#131313"
      transform="translate(60,108)"
    />
    <path
      d="M0,0 L4,0 L4,3 L5,3 L5,4 L6,4 L6,7 L7,7 L7,10 L8,10 L8,12 L9,12 L9,15 L10,15 L10,16 L6,16 L6,13 L-2,13 L-2,15 L-3,15 L-3,16 L-6,16 L-6,13 L-5,13 L-5,11 L-4,11 L-4,8 L-3,8 L-3,5 L-2,5 L-2,3 L-1,3 L-1,1 L0,1 Z "
      fill="#090909"
      transform="translate(110,108)"
    />
    <path
      d="M0,0 L3,0 L3,4 L4,4 L4,8 L5,8 L5,13 L6,13 L6,19 L7,19 L7,24 L8,24 L8,28 L9,28 L9,32 L10,32 L10,37 L11,37 L11,40 L13,40 L13,37 L14,37 L14,39 L16,39 L16,40 L14,40 L14,42 L12,42 L12,41 L11,41 L11,42 L10,42 L10,43 L7,43 L7,41 L6,41 L6,42 L5,42 L5,31 L6,31 L6,36 L7,36 L7,39 L9,39 L9,34 L8,34 L8,29 L7,29 L7,25 L6,25 L6,23 L5,23 L5,19 L4,19 L4,16 L3,16 L3,13 L2,13 L2,10 L1,10 L1,8 L0,8 L0,6 L-1,6 L-1,4 L-2,4 L-2,1 L0,1 Z "
      fill="#0F0F0F"
      transform="translate(64,13)"
    />
    <path
      d="M0,0 L2,0 L2,1 L3,1 L3,3 L5,3 L5,0 L7,0 L7,4 L11,4 L11,6 L10,6 L10,7 L7,7 L7,15 L8,15 L8,16 L9,16 L9,17 L-1,17 L-1,16 L-2,16 L-2,15 L-3,15 L-3,10 L-2,10 L-2,9 L-1,9 L-1,8 L5,8 L5,6 L6,6 L6,5 L4,5 L4,4 L1,4 L1,5 L0,5 Z "
      fill="#E4E4E4"
      transform="translate(78,134)"
    />
    <path
      d="M0,0 L8,0 L8,1 L10,1 L10,2 L11,2 L11,9 L10,9 L10,10 L3,10 L3,16 L0,16 Z "
      fill="#101010"
      transform="translate(93,108)"
    />
    <path
      d="M0,0 L16,0 L16,3 L11,3 L11,4 L15,4 L15,6 L11,6 L11,8 L15,8 L15,10 L8,10 L8,8 L7,8 L7,9 L6,9 L6,8 L5,8 L5,7 L7,7 L7,6 L2,6 L2,10 L0,10 Z "
      fill="#969696"
      transform="translate(59,128)"
    />
    <path
      d="M0,0 L11,0 L11,3 L3,3 L3,7 L10,7 L10,10 L3,10 L3,13 L11,13 L11,16 L0,16 Z "
      fill="#0F0F0F"
      transform="translate(30,108)"
    />
    <path
      d="M0,0 L1,0 L1,2 L2,2 L2,0 L3,0 L3,12 L1,12 L1,17 L-11,17 L-11,16 L0,16 L0,13 L-8,13 L-8,10 L-1,10 L-1,7 L-8,7 L-8,3 L0,3 Z "
      fill="#D3D3D3"
      transform="translate(41,108)"
    />
    <path
      d="M0,0 L3,0 L3,12 L4,12 L4,13 L9,13 L9,12 L10,12 L10,0 L13,0 L13,12 L12,12 L12,14 L11,14 L11,15 L9,15 L9,16 L3,16 L3,15 L2,15 L2,14 L1,14 L1,13 L0,13 Z "
      fill="#070707"
      transform="translate(44,108)"
    />
    <path
      d="M0,0 L13,0 L13,1 L14,1 L14,2 L10,2 L10,3 L9,3 L9,6 L8,6 L8,10 L11,10 L11,12 L8,12 L8,11 L1,11 L1,10 L2,10 L2,9 L-1,9 L-1,7 L1,7 L1,6 L2,6 L2,5 L0,5 L0,4 L1,4 L1,3 L2,3 L2,1 L0,1 Z "
      fill="#EFEFEF"
      transform="translate(70,85)"
    />
    <path
      d="M0,0 L18,0 L18,2 L19,2 L19,4 L20,4 L20,5 L-2,5 L-2,4 L-1,4 L-1,1 L0,1 Z "
      fill="#040404"
      transform="translate(66,71)"
    />
    <path
      d="M0,0 L1,0 L1,2 L2,2 L2,1 L3,1 L3,0 L4,0 L4,2 L5,2 L5,0 L7,0 L7,1 L8,1 L8,3 L9,3 L9,6 L10,6 L10,8 L11,8 L11,9 L9,9 L9,7 L4,7 L4,9 L3,9 L3,10 L1,10 L1,4 L0,4 L0,6 L-4,6 L-4,7 L0,7 L0,8 L-5,8 L-5,5 L-1,5 L-1,4 L-5,4 L-5,1 L0,1 Z "
      fill="#C6C6C6"
      transform="translate(63,142)"
    />
    <path
      d="M0,0 L10,0 L10,1 L14,1 L14,2 L16,2 L16,3 L20,3 L20,5 L18,5 L18,4 L15,4 L15,7 L13,7 L13,6 L10,6 L10,7 L11,7 L11,8 L13,8 L13,9 L9,9 L9,12 L8,12 L8,8 L9,8 L9,5 L10,5 L10,4 L14,4 L14,3 L13,3 L13,2 L-2,2 L-2,3 L-6,3 L-6,4 L-8,4 L-8,5 L-10,5 L-10,6 L-11,6 L-11,7 L-13,7 L-13,8 L-14,8 L-14,9 L-15,9 L-15,10 L-16,10 L-16,11 L-17,11 L-17,9 L-16,9 L-16,8 L-15,8 L-15,7 L-14,7 L-14,6 L-12,6 L-12,5 L-11,5 L-11,4 L-9,4 L-9,3 L-7,3 L-7,2 L-4,2 L-4,1 L0,1 Z "
      fill="#636363"
      transform="translate(70,83)"
    />
    <path
      d="M0,0 L2,0 L2,7 L8,7 L8,0 L15,0 L15,1 L10,1 L10,4 L14,4 L14,5 L10,5 L10,8 L15,8 L15,10 L0,10 Z "
      fill="#5E5E5E"
      transform="translate(48,142)"
    />
    <path
      d="M0,0 L4,0 L4,1 L5,1 L5,2 L6,2 L6,9 L5,9 L5,10 L0,10 L0,9 L-1,9 L-1,8 L-2,8 L-2,2 L-1,2 L-1,1 L0,1 Z "
      fill="#F3F3F3"
      transform="translate(80,111)"
    />
    <path
      d="M0,0 L4,0 L4,6 L3,6 L3,14 L2,14 L2,28 L1,28 L1,19 L0,19 Z "
      fill="#050505"
      transform="translate(77,12)"
    />
    <path
      d="M0,0 L3,0 L3,1 L5,1 L5,4 L4,4 L4,7 L3,7 L3,10 L2,10 L2,13 L1,13 L1,16 L0,16 L0,20 L-1,20 L-1,5 L0,5 Z "
      fill="#0F0F0F"
      transform="translate(83,13)"
    />
    <path
      d="M0,0 L1,0 L1,12 L-1,12 L-1,13 L-3,13 L-3,14 L-4,14 L-4,15 L-5,15 L-5,17 L-6,17 L-6,19 L-8,19 L-8,20 L-12,20 L-12,19 L-11,19 L-11,18 L-10,18 L-10,17 L-9,17 L-9,16 L-8,16 L-8,15 L-7,15 L-7,14 L-6,14 L-6,13 L-5,13 L-5,12 L-4,12 L-4,10 L-3,10 L-3,9 L-2,9 L-2,6 L-1,6 L-1,4 L0,4 Z "
      fill="#D5FAD7"
      transform="translate(104,52)"
    />
    <path
      d="M0,0 L1,0 L1,4 L2,4 L2,6 L3,6 L3,8 L4,8 L4,10 L5,10 L5,12 L6,12 L6,13 L7,13 L7,14 L8,14 L8,15 L9,15 L9,16 L10,16 L10,17 L11,17 L11,18 L12,18 L12,19 L14,19 L14,20 L10,20 L10,19 L8,19 L8,16 L5,16 L5,15 L4,15 L4,17 L3,17 L3,14 L4,14 L4,12 L0,12 L0,10 L1,10 L1,9 L-1,9 L-1,7 L1,7 L1,5 L0,5 Z "
      fill="#DAFADA"
      transform="translate(45,52)"
    />
    <path
      d="M0,0 L1,0 L1,6 L6,6 L6,0 L7,0 L7,12 L6,12 L6,13 L1,13 L1,12 L0,12 Z "
      fill="#E1E1E1"
      transform="translate(47,108)"
    />
    <path
      d="M0,0 L1,0 L1,4 L0,4 L0,5 L-1,5 L-1,6 L-2,6 L-2,8 L-3,8 L-3,21 L-2,21 L-2,22 L-3,22 L-3,24 L-2,24 L-2,27 L0,27 L0,28 L-1,28 L-1,29 L-2,29 L-2,28 L-3,28 L-3,25 L-4,25 L-4,22 L-5,22 L-5,8 L-4,8 L-4,5 L-3,5 L-3,4 L-2,4 L-2,2 L-1,2 L-1,1 L0,1 Z M-2,20 L-1,20 L-1,21 L-2,21 Z "
      fill="#E1FDE5"
      transform="translate(56,31)"
    />
    <path
      d="M0,0 L1,0 L1,3 L2,3 L2,5 L3,5 L3,7 L4,7 L4,9 L5,9 L5,12 L2,12 L2,11 L3,11 L3,9 L2,9 L2,8 L1,8 L1,10 L0,10 L0,15 L-2,15 L-2,14 L-1,14 L-1,8 L-3,8 L-3,9 L-5,9 L-5,10 L-6,10 L-6,5 L-5,5 L-5,7 L-3,7 L-3,6 L-1,6 L-1,3 L0,3 Z M-5,4 L-4,4 L-4,5 L-5,5 Z "
      fill="#DCE9DE"
      transform="translate(61,14)"
    />
    <path
      d="M0,0 L5,0 L5,14 L4,14 L4,16 L3,16 L3,12 L2,12 L2,8 L1,8 L1,4 L0,4 Z "
      fill="#070707"
      transform="translate(69,12)"
    />
    <path
      d="M0,0 L3,0 L3,1 L4,1 L4,2 L-1,2 L-1,7 L3,7 L3,8 L2,8 L2,9 L-1,9 L-1,15 L-4,15 L-4,3 L-2,3 L-2,1 L0,1 Z "
      fill="#000000"
      transform="translate(97,109)"
    />
    <path
      d="M0,0 L1,0 L1,1 L6,1 L6,3 L3,3 L3,8 L2,8 L2,9 L5,9 L5,10 L3,10 L3,16 L0,16 Z "
      fill="#020202"
      transform="translate(60,108)"
    />
    <path
      d="M0,0 L1,0 L1,5 L2,5 L2,8 L4,8 L4,3 L3,3 L3,2 L4,2 L4,1 L5,1 L5,6 L6,6 L6,9 L8,9 L8,6 L9,6 L9,8 L11,8 L11,9 L9,9 L9,11 L7,11 L7,10 L6,10 L6,11 L5,11 L5,12 L2,12 L2,10 L1,10 L1,11 L0,11 Z "
      fill="#2F2F2F"
      transform="translate(69,44)"
    />
    <path
      d="M0,0 L1,0 L1,1 L2,1 L2,2 L3,2 L3,3 L4,3 L4,5 L5,5 L5,6 L6,6 L6,9 L7,9 L7,12 L8,12 L8,22 L7,22 L7,13 L6,13 L6,12 L5,12 L5,7 L3,7 L3,6 L1,6 L1,9 L0,9 L0,8 L-2,8 L-2,5 L0,5 Z "
      fill="#CEF7D3"
      transform="translate(92,30)"
    />
    <path
      d="M0,0 L2,0 L2,9 L-1,9 L-1,7 L-2,7 L-2,6 L-3,6 L-3,5 L-4,5 L-4,10 L-6,10 L-6,1 L-3,1 L-3,3 L-2,3 L-2,5 L-1,5 L-1,6 L0,6 Z "
      fill="#202020"
      transform="translate(100,128)"
    />
    <path
      d="M0,0 L6,0 L6,2 L2,2 L2,3 L6,3 L6,6 L2,6 L2,7 L6,7 L6,9 L0,9 Z "
      fill="#4D4D4D"
      transform="translate(95,142)"
    />
    <path
      d="M0,0 L4,0 L4,1 L6,1 L6,3 L4,3 L4,2 L0,2 L0,7 L4,7 L4,6 L2,6 L2,4 L6,4 L6,8 L5,8 L5,9 L0,9 L0,8 L-1,8 L-1,7 L-2,7 L-2,2 L-1,2 L-1,1 L0,1 Z "
      fill="#3F3F3F"
      transform="translate(77,142)"
    />
    <path
      d="M0,0 L3,0 L3,1 L5,1 L5,2 L6,2 L6,3 L7,3 L7,5 L6,5 L6,6 L4,6 L4,7 L3,7 L3,8 L-2,8 L-2,7 L-1,7 L-1,4 L0,4 L0,3 L1,3 L1,2 L0,2 Z "
      fill="#F7F7F7"
      transform="translate(85,87)"
    />
    <path
      d="M0,0 L2,0 L2,10 L-1,10 L-1,8 L-2,8 L-2,6 L-3,6 L-3,4 L-5,4 L-5,9 L-6,9 L-6,1 L-3,1 L-3,3 L-2,3 L-2,5 L-1,5 L-1,6 L0,6 Z "
      fill="#222222"
      transform="translate(56,128)"
    />
    <path
      d="M0,0 L1,0 L1,1 L2,1 L2,2 L3,2 L3,4 L4,4 L4,5 L5,5 L5,8 L7,8 L7,9 L6,9 L6,10 L4,10 L4,11 L3,11 L3,12 L2,12 L2,13 L1,13 L1,11 L2,11 L2,9 L-1,9 L-1,8 L-2,8 L-2,7 L-3,7 L-3,6 L-4,6 L-4,4 L-3,4 L-3,5 L-2,5 L-2,6 L-1,6 L-1,7 L2,7 L2,3 L1,3 L1,2 L0,2 Z "
      fill="#3A3A3A"
      transform="translate(60,91)"
    />
    <path
      d="M0,0 L2,0 L2,1 L3,1 L3,3 L4,3 L4,6 L5,6 L5,8 L6,8 L6,9 L4,9 L4,7 L-1,7 L-1,9 L-3,9 L-3,6 L-2,6 L-2,4 L-1,4 L-1,2 L0,2 Z "
      fill="#2C2C2C"
      transform="translate(68,142)"
    />
    <path
      d="M0,0 L1,0 L1,3 L3,3 L3,5 L2,5 L2,8 L1,8 L1,10 L0,10 L0,13 L3,13 L3,12 L4,12 L4,14 L-1,14 L-1,9 L0,9 L0,8 L-7,8 L-7,13 L-8,13 L-8,7 L-1,7 L-1,6 L0,6 Z "
      fill="#C4C4C4"
      transform="translate(104,111)"
    />
    <path
      d="M0,0 L2,0 L2,1 L3,1 L3,2 L5,2 L5,3 L6,3 L6,4 L7,4 L7,6 L6,6 L6,5 L5,5 L5,4 L3,4 L3,7 L2,7 L2,8 L1,8 L1,13 L-1,13 L-1,9 L0,9 L0,7 L-1,7 L-1,8 L-3,8 L-3,9 L-5,9 L-5,8 L-4,8 L-4,7 L-2,7 L-2,6 L-1,6 L-1,5 L1,5 L1,4 L2,4 L2,2 L1,2 L1,1 L0,1 Z "
      fill="#5A5A5A"
      transform="translate(90,88)"
    />
    <path
      d="M0,0 L1,0 L1,2 L3,2 L3,6 L4,6 L4,8 L-1,8 L-1,6 L-2,6 L-2,7 L-3,7 L-3,8 L-4,8 L-4,6 L-3,6 L-3,3 L-2,3 L-2,1 L0,1 Z "
      fill="#555555"
      transform="translate(110,110)"
    />
    <path
      d="M0,0 L4,0 L4,1 L5,1 L5,2 L6,2 L6,3 L4,3 L4,2 L0,2 L0,3 L-1,3 L-1,7 L0,7 L0,8 L3,8 L3,7 L4,7 L4,6 L6,6 L6,8 L5,8 L5,9 L4,9 L4,10 L0,10 L0,9 L-1,9 L-1,8 L-2,8 L-2,7 L-3,7 L-3,3 L-2,3 L-2,2 L-1,2 L-1,1 L0,1 Z "
      fill="#353535"
      transform="translate(32,128)"
    />
    <path
      d="M0,0 L6,0 L6,4 L5,4 L5,7 L6,7 L6,8 L4,8 L4,6 L3,6 L3,5 L1,5 L1,9 L0,9 Z "
      fill="#3E3E3E"
      transform="translate(77,129)"
    />
    <path
      d="M0,0 L6,0 L6,1 L2,1 L2,3 L6,3 L6,5 L2,5 L2,7 L6,7 L6,9 L0,9 Z "
      fill="#262626"
      transform="translate(68,129)"
    />
    <path
      d="M0,0 L6,0 L6,1 L2,1 L2,3 L6,3 L6,5 L2,5 L2,7 L6,7 L6,9 L0,9 Z "
      fill="#2C2C2C"
      transform="translate(114,129)"
    />
    <path
      d="M0,0 L1,0 L1,3 L22,3 L22,4 L4,4 L4,5 L3,5 L3,8 L2,8 L2,5 L1,5 L1,6 L-3,6 L-3,5 L-1,5 L-1,3 L0,3 Z "
      fill="#E0EAE1"
      transform="translate(62,67)"
    />
    <path
      d="M0,0 L3,0 L3,1 L2,1 L2,3 L1,3 L1,4 L0,4 L0,6 L-1,6 L-1,9 L-2,9 L-2,11 L-3,11 L-3,9 L-4,9 L-4,6 L-5,6 L-5,1 L-3,1 L-3,6 L-2,6 L-2,2 L-1,2 L-1,1 L0,1 Z "
      fill="#DFFAE3"
      transform="translate(49,27)"
    />
    <path
      d="M0,0 L6,0 L6,1 L1,1 L1,10 L0,10 L0,8 L-1,8 L-1,12 L-2,12 L-2,8 L-6,8 L-6,6 L-2,6 L-2,4 L0,4 Z "
      fill="#CBCBCB"
      transform="translate(76,128)"
    />
    <path
      d="M0,0 L1,0 L1,1 L2,1 L2,3 L1,3 L1,4 L3,4 L3,5 L2,5 L2,7 L5,7 L5,8 L6,8 L6,9 L8,9 L8,11 L7,11 L7,10 L5,10 L5,9 L4,9 L4,8 L3,8 L3,10 L2,10 L2,8 L1,8 L1,6 L0,6 L0,7 L-1,7 L-1,8 L0,8 L0,9 L-1,9 L-1,10 L-2,10 L-2,11 L-3,11 L-3,9 L-2,9 L-2,6 L-1,6 L-1,3 L0,3 Z "
      fill="#D3E1D1"
      transform="translate(88,14)"
    />
    <path
      d="M0,0 L4,0 L4,1 L5,1 L5,2 L6,2 L6,3 L4,3 L4,2 L0,2 L0,7 L1,7 L1,8 L4,8 L4,7 L6,7 L6,8 L5,8 L5,9 L-1,9 L-1,8 L-2,8 L-2,2 L-1,2 L-1,1 L0,1 Z "
      fill="#252525"
      transform="translate(106,128)"
    />
    <path
      d="M0,0 L4,0 L4,1 L7,1 L7,4 L4,4 L4,6 L1,6 L1,5 L0,5 Z "
      fill="#F0EFF0"
      transform="translate(106,130)"
    />
    <path
      d="M0,0 L1,0 L1,4 L2,4 L2,9 L3,9 L3,14 L1,14 L1,11 L0,11 L0,6 L-2,6 L-2,2 L-1,2 L-1,4 L0,4 Z "
      fill="#D5D5D5"
      transform="translate(70,38)"
    />
    <path
      d="M0,0 L6,0 L6,1 L1,1 L1,3 L6,3 L6,5 L2,5 L2,7 L6,7 L6,8 L0,8 Z "
      fill="#222222"
      transform="translate(86,129)"
    />
    <path
      d="M0,0 L4,0 L4,5 L3,5 L3,6 L0,6 L0,5 L-1,5 L-1,1 L0,1 Z "
      fill="#E8E8E8"
      transform="translate(42,130)"
    />
    <path
      d="M0,0 L5,0 L5,1 L4,1 L4,2 L3,2 L3,3 L5,3 L5,4 L4,4 L4,5 L2,5 L2,7 L5,7 L5,8 L1,8 L1,5 L0,5 Z "
      fill="#5B5B5B"
      transform="translate(67,87)"
    />
    <path
      d="M0,0 L5,0 L5,4 L4,4 L4,5 L0,5 Z "
      fill="#D8D8D8"
      transform="translate(96,111)"
    />
    <path
      d="M0,0 L1,0 L1,2 L0,2 L0,6 L1,6 L1,9 L-2,9 L-2,6 L-3,6 L-3,3 L-2,3 L-2,1 L0,1 Z "
      fill="#D8D8D8"
      transform="translate(89,95)"
    />
    <path
      d="M0,0 L1,0 L1,4 L2,4 L2,5 L1,5 L1,6 L-5,6 L-5,5 L-6,5 L-6,4 L-5,4 L-5,3 L0,3 Z "
      fill="#FCFBFC"
      transform="translate(96,135)"
    />
    <path
      d="M0,0 L3,0 L3,2 L4,2 L4,3 L5,3 L5,5 L4,5 L4,4 L2,4 L2,5 L1,5 L1,7 L-3,7 L-3,6 L0,6 Z "
      fill="#CCCCCC"
      transform="translate(63,118)"
    />
    <path
      d="M0,0 L5,0 L5,1 L6,1 L6,4 L0,4 Z "
      fill="#D7D7D7"
      transform="translate(63,111)"
    />
    <path
      d="M0,0 L9,0 L9,5 L8,5 L8,4 L7,4 L7,2 L0,2 Z "
      fill="#FDFCFD"
      transform="translate(34,112)"
    />
    <path
      d="M0,0 L1,0 L1,4 L0,4 L0,5 L-1,5 L-1,6 L-2,6 L-2,8 L-3,8 L-3,9 L-4,9 L-4,10 L-5,10 L-5,8 L-4,8 L-4,5 L-3,5 L-3,4 L-2,4 L-2,2 L-1,2 L-1,1 L0,1 Z "
      fill="#C8F9CC"
      transform="translate(56,31)"
    />
    <path
      d="M0,0 L2,0 L2,1 L5,1 L5,5 L7,5 L7,7 L6,7 L6,6 L4,6 L4,5 L3,5 L3,4 L2,4 L2,3 L1,3 L1,1 L0,1 Z "
      fill="#CAF9D2"
      transform="translate(55,60)"
    />
    <path
      d="M0,0 L1,0 L1,4 L0,4 L0,7 L-1,7 L-1,11 L-2,11 L-2,2 L-1,2 L-1,1 L0,1 Z "
      fill="#030303"
      transform="translate(84,22)"
    />
    <path
      d="M0,0 L1,0 L1,3 L3,3 L3,4 L2,4 L2,5 L0,5 L0,6 L-1,6 L-1,7 L-2,7 L-2,8 L-3,8 L-3,6 L-2,6 L-2,3 L-1,3 L-1,2 L0,2 Z "
      fill="#8C8C8C"
      transform="translate(64,96)"
    />
    <path
      d="M0,0 L4,0 L4,1 L6,1 L6,0 L7,0 L7,4 L4,4 L4,3 L3,3 L3,2 L2,2 L2,1 L1,1 L1,2 L0,2 Z "
      fill="#E3E3E3"
      transform="translate(55,94)"
    />
    <path
      d="M0,0 L1,0 L1,1 L2,1 L2,2 L3,2 L3,3 L9,3 L9,2 L11,2 L11,3 L10,3 L10,4 L1,4 L1,3 L0,3 Z "
      fill="#D9D9D9"
      transform="translate(44,121)"
    />
    <path
      d="M0,0 L1,0 L1,2 L2,2 L2,3 L1,3 L1,4 L-6,4 L-6,3 L-5,3 L-5,2 L-1,2 L-1,1 L0,1 Z "
      fill="#060606"
      transform="translate(85,120)"
    />
  </svg>
)
export default EuropaConference
