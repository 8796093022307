import * as React from 'react'
const Mnemonic = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3327 9.16667V6.83333C18.3327 5.89991 18.3327 5.4332 18.151 5.07668C17.9912 4.76308 17.7363 4.50811 17.4227 4.34832C17.0661 4.16667 16.5994 4.16667 15.666 4.16667H4.33268C3.39926 4.16667 2.93255 4.16667 2.57603 4.34832C2.26243 4.50811 2.00746 4.76308 1.84767 5.07668C1.66602 5.4332 1.66602 5.89991 1.66602 6.83333V9.83333C1.66602 10.7668 1.66602 11.2335 1.84767 11.59C2.00746 11.9036 2.26243 12.1586 2.57603 12.3183C2.93255 12.5 3.39926 12.5 4.33268 12.5H9.16602M9.99935 8.33333H10.0035M14.166 8.33333H14.1702M5.83268 8.33333H5.83685M16.041 14.1667V12.7083C16.041 11.9029 15.3881 11.25 14.5827 11.25C13.7773 11.25 13.1243 11.9029 13.1243 12.7083V14.1667M10.2077 8.33333C10.2077 8.44839 10.1144 8.54167 9.99935 8.54167C9.88429 8.54167 9.79102 8.44839 9.79102 8.33333C9.79102 8.21827 9.88429 8.125 9.99935 8.125C10.1144 8.125 10.2077 8.21827 10.2077 8.33333ZM14.3743 8.33333C14.3743 8.44839 14.2811 8.54167 14.166 8.54167C14.051 8.54167 13.9577 8.44839 13.9577 8.33333C13.9577 8.21827 14.051 8.125 14.166 8.125C14.2811 8.125 14.3743 8.21827 14.3743 8.33333ZM6.04102 8.33333C6.04102 8.44839 5.94774 8.54167 5.83268 8.54167C5.71762 8.54167 5.62435 8.44839 5.62435 8.33333C5.62435 8.21827 5.71762 8.125 5.83268 8.125C5.94774 8.125 6.04102 8.21827 6.04102 8.33333ZM12.9993 17.5H16.166C16.6327 17.5 16.8661 17.5 17.0443 17.4092C17.2011 17.3293 17.3286 17.2018 17.4085 17.045C17.4993 16.8667 17.4993 16.6334 17.4993 16.1667V15.5C17.4993 15.0333 17.4993 14.7999 17.4085 14.6217C17.3286 14.4649 17.2011 14.3374 17.0443 14.2575C16.8661 14.1667 16.6327 14.1667 16.166 14.1667H12.9993C12.5326 14.1667 12.2993 14.1667 12.121 14.2575C11.9642 14.3374 11.8367 14.4649 11.7568 14.6217C11.666 14.7999 11.666 15.0333 11.666 15.5V16.1667C11.666 16.6334 11.666 16.8667 11.7568 17.045C11.8367 17.2018 11.9642 17.3293 12.121 17.4092C12.2993 17.5 12.5326 17.5 12.9993 17.5Z"
      stroke="white"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Mnemonic
