import * as React from 'react'
const Colombia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#B52136"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,48 L0,48 Z "
      fill="#F2CE37"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,24 L0,24 Z "
      fill="#24418A"
      transform="translate(0,48)"
    />
  </svg>
)
export default Colombia
