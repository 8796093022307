import React from 'react'
const DenmarkSuperliga = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={148}
    viewBox="0 0 150 148"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,148 L0,148 Z "
      fill="#FDFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L2,0 L2,1 L3,1 L3,2 L5,2 L5,3 L6,3 L6,4 L8,4 L8,5 L10,5 L10,6 L11,6 L11,7 L13,7 L13,8 L14,8 L14,9 L16,9 L16,10 L18,10 L18,11 L19,11 L19,12 L21,12 L21,13 L22,13 L22,14 L24,14 L24,15 L26,15 L26,16 L27,16 L27,17 L30,17 L30,18 L31,18 L31,19 L32,19 L32,20 L34,20 L34,21 L35,21 L35,22 L37,22 L37,23 L39,23 L39,24 L40,24 L40,25 L42,25 L42,26 L44,26 L44,27 L46,27 L46,28 L47,28 L47,29 L50,29 L50,30 L51,30 L51,31 L52,31 L52,32 L54,32 L54,33 L56,33 L56,34 L57,34 L57,35 L59,35 L59,36 L61,36 L61,37 L62,37 L62,38 L64,38 L64,39 L65,39 L65,40 L67,40 L67,41 L68,41 L68,42 L70,42 L70,43 L72,43 L72,44 L74,44 L74,45 L75,45 L75,46 L77,46 L77,47 L78,47 L78,48 L80,48 L80,49 L82,49 L82,51 L76,51 L76,52 L69,52 L69,53 L63,53 L63,54 L56,54 L56,55 L52,55 L52,56 L49,56 L49,57 L40,57 L40,58 L34,58 L34,59 L25,59 L25,60 L18,60 L18,61 L12,61 L12,62 L8,62 L8,63 L5,63 L5,62 L7,62 L7,61 L10,61 L10,60 L12,60 L12,59 L14,59 L14,58 L17,58 L17,57 L19,57 L19,56 L21,56 L21,55 L24,55 L24,54 L26,54 L26,53 L28,53 L28,52 L31,52 L31,51 L33,51 L33,50 L35,50 L35,49 L38,49 L38,48 L39,48 L39,47 L38,47 L38,46 L37,46 L37,45 L36,45 L36,44 L35,44 L35,42 L34,42 L34,41 L33,41 L33,40 L32,40 L32,39 L31,39 L31,38 L30,38 L30,36 L29,36 L29,35 L28,35 L28,34 L27,34 L27,32 L25,32 L25,30 L24,30 L24,29 L23,29 L23,28 L22,28 L22,27 L21,27 L21,25 L20,25 L20,24 L19,24 L19,23 L18,23 L18,21 L17,21 L17,20 L16,20 L16,19 L15,19 L15,18 L14,18 L14,16 L13,16 L13,15 L12,15 L12,14 L11,14 L11,13 L10,13 L10,12 L9,12 L9,11 L8,11 L8,9 L7,9 L7,8 L6,8 L6,7 L5,7 L5,5 L4,5 L4,4 L3,4 L3,3 L2,3 L2,2 L1,2 L1,1 L0,1 Z "
      fill="#212121"
      transform="translate(34,31)"
    />
    <path
      d="M0,0 L2,0 L2,5 L1,5 L1,11 L3,11 L3,9 L4,9 L4,7 L5,7 L5,5 L6,5 L6,3 L9,3 L9,4 L10,4 L10,6 L11,6 L11,8 L12,8 L12,10 L13,10 L13,12 L11,12 L11,11 L5,11 L5,13 L-2,13 L-2,12 L-4,12 L-4,13 L-9,13 L-9,14 L-10,14 L-10,15 L-12,15 L-12,17 L-14,17 L-14,19 L-15,19 L-15,15 L-14,15 L-14,13 L-15,13 L-15,3 L-12,3 L-12,11 L-11,11 L-11,6 L-10,6 L-10,5 L-9,5 L-9,4 L-8,4 L-8,3 L-7,3 L-7,2 L0,2 Z "
      fill="#D4D4D4"
      transform="translate(108,99)"
    />
    <path
      d="M0,0 L1,0 L1,2 L0,2 L0,6 L-1,6 L-1,10 L-2,10 L-2,13 L-3,13 L-3,17 L-4,17 L-4,21 L-5,21 L-5,25 L-7,25 L-7,23 L-8,23 L-8,22 L-9,22 L-9,21 L-10,21 L-10,20 L-11,20 L-11,18 L-12,18 L-12,17 L-13,17 L-13,16 L-14,16 L-14,15 L-15,15 L-15,14 L-16,14 L-16,12 L-17,12 L-17,11 L-18,11 L-18,10 L-19,10 L-19,9 L-20,9 L-20,8 L-17,8 L-17,7 L-15,7 L-15,6 L-13,6 L-13,5 L-10,5 L-10,4 L-7,4 L-7,3 L-5,3 L-5,2 L-2,2 L-2,1 L0,1 Z "
      fill="#1F1F1F"
      transform="translate(79,15)"
    />
    <path
      d="M0,0 L4,0 L4,9 L9,9 L9,10 L10,10 L10,12 L3,12 L3,13 L-2,13 L-2,12 L-4,12 L-4,11 L-5,11 L-5,9 L-6,9 L-6,8 L-8,8 L-8,6 L-4,6 L-4,3 L-9,3 L-9,11 L-12,11 L-12,12 L-20,12 L-20,2 L-13,2 L-13,3 L-19,3 L-19,6 L-13,6 L-13,7 L-12,7 L-12,8 L-11,8 L-11,2 L-2,2 L-2,8 L-1,8 L-1,1 L0,1 Z "
      fill="#909090"
      transform="translate(82,100)"
    />
    <path
      d="M0,0 L1,0 L1,1 L2,1 L2,2 L9,2 L9,4 L10,4 L10,8 L9,8 L9,9 L4,9 L4,8 L8,8 L8,4 L3,4 L3,13 L1,13 L1,3 L0,3 L0,11 L-2,11 L-2,12 L-4,12 L-4,13 L-8,13 L-8,12 L-11,12 L-11,13 L-20,13 L-20,11 L-13,11 L-13,9 L-14,9 L-14,8 L-19,8 L-19,7 L-20,7 L-20,4 L-19,4 L-19,3 L-12,3 L-12,4 L-18,4 L-18,7 L-11,7 L-11,3 L-8,3 L-8,11 L-3,11 L-3,1 L0,1 Z "
      fill="#7B7B7B"
      transform="translate(50,99)"
    />
    <path
      d="M0,0 L2,0 L2,1 L9,1 L9,0 L11,0 L11,1 L19,1 L19,2 L12,2 L12,8 L11,8 L11,7 L10,7 L10,6 L4,6 L4,3 L10,3 L10,2 L3,2 L3,12 L1,12 L1,11 L0,11 L0,15 L-1,15 L-1,10 L-3,10 L-3,9 L-6,9 L-6,3 L-1,3 L-1,7 L-5,7 L-5,8 L0,8 L0,7 L1,7 L1,3 L0,3 Z "
      fill="#D3D3D3"
      transform="translate(59,100)"
    />
    <path
      d="M0,0 L3,0 L3,8 L4,8 L4,3 L5,3 L5,2 L6,2 L6,1 L7,1 L7,0 L15,0 L15,2 L8,2 L8,3 L7,3 L7,7 L8,7 L8,8 L11,8 L11,10 L6,10 L6,11 L5,11 L5,12 L3,12 L3,14 L1,14 L1,16 L0,16 L0,12 L1,12 L1,10 L0,10 Z "
      fill="#929192"
      transform="translate(93,102)"
    />
    <path
      d="M0,0 L4,0 L4,9 L9,9 L9,10 L10,10 L10,12 L3,12 L3,13 L-2,13 L-2,10 L-3,10 L-3,9 L-1,9 L-1,1 L0,1 Z "
      fill="#959595"
      transform="translate(82,100)"
    />
    <path
      d="M0,0 L1,0 L1,1 L2,1 L2,2 L9,2 L9,4 L10,4 L10,8 L9,8 L9,9 L4,9 L4,8 L8,8 L8,4 L3,4 L3,13 L1,13 L1,3 L0,3 L0,11 L-1,11 L-1,3 L-2,3 L-2,10 L-3,10 L-3,1 L0,1 Z "
      fill="#8E8E8E"
      transform="translate(50,99)"
    />
    <path
      d="M0,0 L3,0 L3,1 L4,1 L4,3 L5,3 L5,5 L6,5 L6,7 L7,7 L7,9 L5,9 L5,8 L-1,8 L-1,9 L-4,9 L-4,8 L-3,8 L-3,6 L-2,6 L-2,4 L-1,4 L-1,2 L0,2 Z "
      fill="#3A3A3A"
      transform="translate(114,102)"
    />
    <path
      d="M0,0 L2,0 L2,11 L3,11 L3,12 L1,12 L1,11 L0,11 L0,15 L-1,15 L-1,10 L-3,10 L-3,9 L-6,9 L-6,3 L-1,3 L-1,7 L-5,7 L-5,8 L0,8 L0,7 L1,7 L1,3 L0,3 Z "
      fill="#F4F4F4"
      transform="translate(59,100)"
    />
    <path
      d="M0,0 L9,0 L9,6 L10,6 L10,7 L8,7 L8,8 L9,8 L9,10 L7,10 L7,9 L6,9 L6,7 L5,7 L5,6 L3,6 L3,4 L7,4 L7,1 L2,1 L2,9 L0,9 Z "
      fill="#696969"
      transform="translate(71,102)"
    />
    <path
      d="M0,0 L8,0 L8,2 L1,2 L1,3 L0,3 L0,7 L1,7 L1,8 L4,8 L4,9 L-1,9 L-1,8 L-2,8 L-2,6 L-3,6 L-3,3 L-2,3 L-2,2 L-1,2 L-1,1 L0,1 Z "
      fill="#2E2E2E"
      transform="translate(100,102)"
    />
    <path
      d="M0,0 L3,0 L3,1 L8,1 L8,6 L4,6 L4,5 L1,5 L1,4 L0,4 Z "
      fill="#828282"
      transform="translate(100,105)"
    />
    <path
      d="M0,0 L5,0 L5,4 L1,4 L1,5 L5,5 L5,6 L0,6 Z "
      fill="#D8D8D8"
      transform="translate(53,103)"
    />
    <path
      d="M0,0 L2,0 L2,1 L1,1 L1,5 L2,5 L2,6 L6,6 L6,7 L0,7 L0,4 L-1,4 L-1,5 L-2,5 L-2,4 L-5,4 L-5,3 L-4,3 L-4,2 L-2,2 L-2,1 L0,1 Z "
      fill="#2E2E2E"
      transform="translate(62,83)"
    />
    <path
      d="M0,0 L3,0 L3,2 L2,2 L2,6 L0,6 L0,7 L-4,7 L-4,6 L-9,6 L-9,5 L-3,5 L-3,6 L-1,6 L-1,5 L1,5 L1,3 L0,3 Z "
      fill="#DDDCDD"
      transform="translate(122,105)"
    />
    <path
      d="M0,0 L1,0 L1,1 L2,1 L2,2 L3,2 L3,3 L4,3 L4,5 L5,5 L5,6 L6,6 L6,7 L5,7 L5,8 L4,8 L4,6 L3,6 L3,4 L2,4 L2,7 L1,7 L1,3 L0,3 L0,4 L-1,4 L-1,5 L-2,5 L-2,2 L0,2 Z "
      fill="#E0DFE0"
      transform="translate(59,24)"
    />
    <path
      d="M0,0 L1,0 L1,2 L2,2 L2,3 L4,3 L4,4 L3,4 L3,5 L2,5 L2,6 L1,6 L1,11 L0,11 L0,3 L-3,3 L-3,2 L-1,2 L-1,1 L0,1 Z "
      fill="#E9E9E9"
      transform="translate(96,99)"
    />
    <path
      d="M0,0 L1,0 L1,1 L2,1 L2,0 L4,0 L4,2 L2,2 L2,3 L4,3 L4,5 L5,5 L5,6 L3,6 L3,5 L1,5 L1,4 L0,4 L0,3 L-2,3 L-2,2 L-3,2 L-3,1 L-1,1 L-1,2 L0,2 Z "
      fill="#CECECE"
      transform="translate(68,48)"
    />
    <path
      d="M0,0 L5,0 L5,1 L6,1 L6,3 L-1,3 L-1,2 L-2,2 L-2,1 L0,1 Z "
      fill="#E6E6E6"
      transform="translate(31,107)"
    />
    <path
      d="M0,0 L4,0 L4,1 L3,1 L3,2 L5,2 L5,4 L6,4 L6,6 L5,6 L5,5 L3,5 L3,4 L2,4 L2,3 L0,3 L0,2 L-1,2 L-1,1 L0,1 Z "
      fill="#DADADA"
      transform="translate(99,68)"
    />
    <path
      d="M0,0 L1,0 L1,1 L3,1 L3,2 L4,2 L4,3 L6,3 L6,4 L7,4 L7,5 L2,5 L2,4 L1,4 L1,3 L0,3 Z "
      fill="#121212"
      transform="translate(108,76)"
    />
    <path
      d="M0,0 L6,0 L6,2 L5,2 L5,3 L0,3 Z "
      fill="#DEDEDE"
      transform="translate(32,103)"
    />
    <path
      d="M0,0 L3,0 L3,1 L4,1 L4,3 L5,3 L5,1 L6,1 L6,2 L7,2 L7,3 L6,3 L6,4 L7,4 L7,5 L5,5 L5,4 L3,4 L3,3 L2,3 L2,2 L0,2 Z "
      fill="#E3E3E3"
      transform="translate(109,75)"
    />
    <path
      d="M0,0 L3,0 L3,1 L1,1 L1,2 L-2,2 L-2,3 L-4,3 L-4,4 L-7,4 L-7,5 L-8,5 L-8,4 L-9,4 L-9,3 L-8,3 L-8,2 L-7,2 L-7,3 L-5,3 L-5,2 L-3,2 L-3,1 L0,1 Z "
      fill="#CBCBCB"
      transform="translate(76,15)"
    />
  </svg>
)
export default DenmarkSuperliga
