import * as React from 'react'
const BetSlipIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={40}
    height={40}
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{
      enableBackground: 'new 0 0 512 512',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <g id="Chat_07">
        <g id="Chat_7">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth={35}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M465.7,292.4c0,48.7-39.5,88.2-88.2,88.2h-73L256,464.7l-48.5-84.1h-72.9c-48.7,0-88.2-39.5-88.2-88.2v-147 c0-48.7,39.5-88.2,88.2-88.2l0,0h243c48.7,0,88.2,39.5,88.2,88.2l0,0L465.7,292.4z"
          />
        </g>
      </g>
      <line
        fill="none"
        stroke="#fff"
        strokeWidth={35}
        strokeLinecap="round"
        strokeMiterlimit={10}
        x1={134.3}
        y1={161.7}
        x2={381.7}
        y2={161.7}
      />
      <line
        fill="none"
        stroke="#fff"
        strokeWidth={35}
        strokeLinecap="round"
        strokeMiterlimit={10}
        x1={134.3}
        y1={226}
        x2={237.5}
        y2={226}
      />
    </g>
  </svg>
)
export default BetSlipIcon
