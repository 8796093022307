import * as React from 'react'
const LinkChain = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.2076 18.3639L11.7933 19.7781C9.84072 21.7308 6.6749 21.7308 4.72228 19.7781C2.76966 17.8255 2.76966 14.6597 4.72228 12.7071L6.13649 11.2929M18.8644 12.7071L20.2786 11.2929C22.2312 9.34024 22.2312 6.17441 20.2786 4.22179C18.326 2.26917 15.1602 2.26917 13.2076 4.22179L11.7933 5.636M9.00045 15.4999L16.0005 8.49994"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default LinkChain
