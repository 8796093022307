import React from 'react'

const Basketball = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2943_12888)">
      <path
        d="M20.8205 3.87917C20.6568 4.83873 20.5715 5.83771 20.5715 6.86238C20.5715 7.38437 20.5951 7.89994 20.6384 8.4052C21.5349 8.35462 22.4645 8.32935 23.4211 8.32678C22.8811 6.65496 21.9845 5.14214 20.8205 3.87917Z"
        fill="white"
      />
      <path
        d="M20.0885 3.14972C17.9533 1.19801 15.1137 0.00534821 12.0002 0.00534821C11.3059 0.00534821 10.6266 0.0679252 9.96448 0.181501C13.0656 1.83663 15.5508 4.891 17.0975 8.74251C17.9602 8.62635 18.8542 8.53251 19.7816 8.46351C19.7375 7.93979 19.7144 7.40538 19.7144 6.86281C19.7144 5.57925 19.8434 4.33343 20.0885 3.14972Z"
        fill="white"
      />
      <path
        d="M16.5464 9.68359C15.2547 9.87817 14.0397 10.1242 12.8967 10.4108C13.7183 12.2318 14.1863 14.3052 14.1863 16.505C14.1863 19.3618 13.5486 21.9293 12.3461 23.9946C13.3618 23.965 14.3689 23.8103 15.3452 23.527C16.0163 23.3325 16.6686 23.0783 17.2956 22.7698C17.3598 22.7381 17.4228 22.7046 17.4867 22.6716L17.5857 22.1668C17.7893 20.8888 17.9997 19.5671 17.9997 18.2193C17.9997 15.1512 17.4819 12.246 16.5464 9.68359Z"
        fill="white"
      />
      <path
        d="M23.6618 9.1835C22.6526 9.18134 21.6759 9.20576 20.7347 9.25807C21.111 12.0249 22.1199 14.3772 23.4699 15.5335C23.814 14.4176 24 13.2326 24 12.0052C24 11.0332 23.8808 10.0891 23.6618 9.1835Z"
        fill="white"
      />
      <path
        d="M3.04368 4.291C2.97384 4.291 2.90484 4.28716 2.83495 4.285C1.46824 5.90971 0.526256 7.88578 0.165419 10.0295C0.164153 10.0385 0.162419 10.0471 0.160685 10.0561C0.11142 10.3526 0.0754206 10.6526 0.048421 10.9548C0.0441555 11.004 0.0406868 11.0542 0.037265 11.1035C0.0145779 11.4021 0 11.7021 0 12.0051C0 13.2458 0.189419 14.4432 0.540412 15.5699C2.9931 13.4999 6.59088 11.283 11.6634 9.85375C9.82698 6.50881 6.65046 4.291 3.04368 4.291Z"
        fill="white"
      />
      <path
        d="M8.62573 0.49176C8.61373 0.495182 8.60173 0.498182 8.59015 0.502026C8.30089 0.587759 8.01716 0.684179 7.73816 0.790443C7.69701 0.806286 7.65632 0.82302 7.61517 0.838863C7.33744 0.948127 7.0636 1.0647 6.79745 1.19328C6.79018 1.1967 6.78329 1.20054 6.77603 1.20444C6.5223 1.32743 6.27546 1.46159 6.03288 1.60169C5.98273 1.63085 5.93215 1.65869 5.88204 1.68827C5.64505 1.82927 5.41363 1.97842 5.18779 2.13442C5.14279 2.16568 5.09864 2.19784 5.05406 2.22957C4.5698 2.57504 4.11254 2.95345 3.68697 3.36402C3.67282 3.37776 3.65871 3.39102 3.64413 3.40476C3.62871 3.41976 3.6154 3.43602 3.59998 3.45102C7.36148 3.66917 10.6366 6.08671 12.512 9.62665C13.6759 9.32881 14.9137 9.0725 16.2289 8.86808C14.599 4.91242 11.9347 1.87647 8.62573 0.49176Z"
        fill="white"
      />
      <path
        d="M19.8729 9.31507C19.0235 9.37765 18.2019 9.46122 17.4087 9.56407C18.3417 12.1783 18.8572 15.12 18.8572 18.2198C18.8572 19.5663 18.6614 20.8571 18.4625 22.1085C20.5757 20.7521 22.2364 18.7529 23.1677 16.3876C21.504 15.1461 20.2904 12.4933 19.8729 9.31507Z"
        fill="white"
      />
      <path
        d="M12.0594 10.632C6.86952 12.0682 3.25673 14.3468 0.851196 16.4335C2.54017 20.6711 6.5751 23.7281 11.3446 23.9865C12.6402 21.9885 13.3297 19.4116 13.3297 16.5051C13.3297 14.3772 12.8681 12.3759 12.0594 10.632ZM4.5544 19.1969C4.47514 19.2603 4.3804 19.2912 4.28656 19.2912C4.16098 19.2912 4.03672 19.2363 3.95183 19.1304C3.85541 19.0108 3.74999 18.8849 3.64068 18.755C3.27684 18.3218 2.86454 17.831 2.6177 17.3408C2.51097 17.1295 2.59628 16.8719 2.80754 16.7652C3.0188 16.658 3.27637 16.7438 3.38311 16.955C3.58495 17.3562 3.96336 17.8062 4.29683 18.2034C4.4104 18.3384 4.52009 18.4696 4.6204 18.5943C4.76871 18.779 4.73871 19.0486 4.5544 19.1969ZM9.40574 22.001C9.3449 22.1792 9.17859 22.2915 8.9999 22.2915C8.95406 22.2915 8.90775 22.2838 8.86191 22.2684C7.98766 21.9705 7.11509 21.5346 6.19495 20.9364C5.99695 20.8066 5.9408 20.5412 6.06937 20.3428C6.19837 20.1444 6.46363 20.0883 6.66252 20.2172C7.52224 20.7765 8.33223 21.1815 9.13832 21.4566C9.36248 21.533 9.48205 21.7768 9.40574 22.001Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2943_12888">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default Basketball
