import * as React from 'react'
const Bosnia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#022295"
    />
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-4 2-70-70 2-4 10-6Z"
      fill="#FCCA01"
    />
    <path
      d="M59 65h2l1 4 12 2 13 13-5 6-4 3-7-1-4 2v-7l-3-2v-2h6l-6-2-5-2-4 2v-6l-3-4h6Z"
      fill="#0B2396"
    />
    <path
      d="m15 13 7 6 13 13-3 1v2l-4 2v7l-4-2-4 1-3 1v-7l-4-3-1-4H8l-3 1 1-7 7-9Z"
      fill="#0C2196"
    />
    <path
      d="M34 40h2l1 4 7 1 7 3 10 10-4 1-4 3v7l-4-2-4 1-3 1v-7l-3-2v-2h6l-6-2-5-2-4 2v-6l-3-4h6Z"
      fill="#0E2597"
    />
    <path d="m72 77 2 3v3h7v2l-3 2v6l-7-1-4 2v-7l-3-2v-2h7Z" fill="#E6E7EF" />
    <path
      d="m47 52 2 3v3h7v2l-3 2v7l-4-2-4 1-3 1v-7l-3-2v-2h7ZM22 27l2 3v3h7v2l-3 2v7l-4-2-4 1-3 1v-7l-3-2v-2h7Z"
      fill="#E6E6EF"
    />
    <path
      d="M59 65h2l1 6h6l-3 4 1 7-5-3-6 2v-6l-3-4h6ZM34 40h2l1 6h6l-3 4 1 7-5-3-6 2v-6l-3-4h6Z"
      fill="#ECEDF3"
    />
    <path d="M10 18h2v3h6l-3 4v6l-6-2-4 2 1-7Z" fill="#EEEFF5" />
  </svg>
)
export default Bosnia
