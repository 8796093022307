import * as React from 'react'
const HomeHashtag = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.8428 16.8798H13.5428C14.6928 16.8798 15.6328 15.9398 15.6328 14.7898V14.0898H12.8428V16.8798Z"
      fill="white"
    />
    <path
      d="M8.65283 14.7898C8.65283 15.9398 9.59283 16.8798 10.7428 16.8798H11.4428V14.0898H8.65283V14.7898Z"
      fill="white"
    />
    <path
      d="M8.65283 12.0002V12.7002H11.4428V9.91016H10.7428C9.59283 9.91016 8.65283 10.8502 8.65283 12.0002Z"
      fill="white"
    />
    <path
      d="M20.1728 6.81969L14.4228 2.78969C12.8528 1.68969 10.4528 1.74969 8.94282 2.91969L3.93282 6.82969C2.92282 7.60969 2.14282 9.20969 2.14282 10.4697V17.3697C2.14282 19.9197 4.21282 21.9997 6.75282 21.9997H17.5228C20.0628 21.9997 22.1328 19.9297 22.1328 17.3797V10.5997C22.1428 9.24969 21.2728 7.58969 20.1728 6.81969ZM17.0228 14.7897C17.0228 16.7097 15.4528 18.2797 13.5328 18.2797H10.7428C8.82282 18.2797 7.25282 16.7197 7.25282 14.7897V11.9997C7.25282 10.0797 8.82282 8.50969 10.7428 8.50969H13.5328C15.4528 8.50969 17.0228 10.0697 17.0228 11.9997V14.7897Z"
      fill="white"
    />
    <path
      d="M13.5428 9.91016H12.8428V12.7002H15.6328V12.0002C15.6328 10.8502 14.6928 9.91016 13.5428 9.91016Z"
      fill="white"
    />
  </svg>
)
export default HomeHashtag
