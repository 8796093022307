import * as React from 'react'
const SingleTicket = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.2501 10.4167C16.2501 9.26667 17.1834 8.33334 18.3334 8.33334V7.5C18.3334 4.16667 17.5001 3.33334 14.1667 3.33334H5.83341C2.50008 3.33334 1.66675 4.16667 1.66675 7.5V7.91667C2.81675 7.91667 3.75008 8.85 3.75008 10C3.75008 11.15 2.81675 12.0833 1.66675 12.0833V12.5C1.66675 15.8333 2.50008 16.6667 5.83341 16.6667H14.1667C17.5001 16.6667 18.3334 15.8333 18.3334 12.5C17.1834 12.5 16.2501 11.5667 16.2501 10.4167Z"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33325 3.33334L8.33325 16.6667"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="5 5"
    />
  </svg>
)
export default SingleTicket
