import React from 'react'

const Hockey = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2943_12918)">
      <path
        d="M9.92511 17.2109C9.85753 17.1035 9.74489 17.036 9.6214 17.0291C9.49871 17.0231 9.37843 17.0754 9.29918 17.1739C8.80681 17.7886 8.64309 17.83 7.73196 17.8329H6.43344L6.84415 19.8442H6.04847L5.63776 17.8329H3.7097L4.13087 19.8904C3.86336 19.95 3.61878 20.0682 3.40438 20.2287L2.91401 17.8329H1.43408C0.833091 17.8329 0.405482 18.073 0.163318 18.5457C-0.0591352 18.9813 -0.0539057 19.5324 0.177397 20.0208L0.862859 21.4661C1.11709 21.9915 1.46344 22.2578 1.89346 22.2578H2.63203C2.81305 23.1745 3.59907 23.8668 4.53877 23.8668H8.43109C9.50394 23.8668 10.3773 22.9646 10.3773 21.8555C10.3773 21.6769 10.3475 21.5068 10.3048 21.3418C10.6954 21.0626 10.8624 20.845 11.1754 20.3486L11.4002 19.991C11.4843 19.8579 11.4839 19.6857 11.4002 19.5521L9.92511 17.2109Z"
        fill="white"
      />
      <path
        d="M20.8551 5.26526L18.8108 3.729L16.9467 6.36747L19.1877 7.91177L20.8551 5.26526Z"
        fill="white"
      />
      <path
        d="M22.8954 2.02661C23.214 1.52097 23.1524 0.865675 22.7498 0.469443C22.5297 0.252621 22.2433 0.133148 21.9428 0.133148C21.5687 0.133148 21.2151 0.320604 20.9991 0.631556L19.2698 3.07894L21.2799 4.58985L22.8954 2.02661Z"
        fill="white"
      />
      <path
        d="M13.6621 15.0685C13.7369 15.1739 13.8146 15.2724 13.8934 15.3682C13.9678 15.4579 14.076 15.5094 14.1895 15.5094C14.1979 15.5094 14.2064 15.509 14.2144 15.5086C14.3371 15.5001 14.4485 15.4329 14.5153 15.3267L18.762 8.58637L16.4864 7.01834L12.3982 12.8053C12.2984 12.9469 12.2984 13.1384 12.3982 13.28L13.6621 15.0685Z"
        fill="white"
      />
      <path
        d="M6.10072 9.90822L8.27658 8.27261L6.29864 5.47324L4.43494 7.26372L6.10072 9.90822Z"
        fill="white"
      />
      <path
        d="M18.9612 17.8329L17.5339 22.2578H19.4379L20.8651 17.8329H18.9612Z"
        fill="white"
      />
      <path
        d="M23.8278 18.6052C23.6162 18.1877 23.2425 17.9113 22.8036 17.8481C22.7349 17.8381 22.6641 17.8329 22.5925 17.8329H21.6862L20.2589 22.2578H21.9617C22.4227 22.2578 22.9183 21.9247 23.1395 21.4669L23.8411 20.0264C24.0579 19.5811 24.0531 19.0493 23.8278 18.6052Z"
        fill="white"
      />
      <path
        d="M16.2958 17.8329C15.3831 17.83 14.7853 17.4857 14.163 16.6047L8.73561 8.92267L6.52637 10.5832L12.5652 20.1684C13.3894 21.477 14.7837 22.2578 16.2946 22.2578H16.7134L18.1406 17.8329H16.2958Z"
        fill="white"
      />
      <path
        d="M4.00371 6.57947L5.83644 4.81915L4.30059 2.64571C4.08256 2.33194 3.72897 2.14449 3.35486 2.14449C3.05477 2.14449 2.76836 2.26356 2.54832 2.48038C2.14524 2.87701 2.0841 3.5323 2.4027 4.03835L4.00371 6.57947Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2943_12918">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default Hockey
