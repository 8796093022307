import * as React from 'react'
const Canada = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#FEFBFB"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L2,1 L2,6 L7,8 L18,17 L18,22 L31,21 L31,36 L34,36 L34,23 L40,23 L42,25 L46,26 L45,23 L50,24 L50,26 L56,26 L56,23 L53,22 L57,22 L57,17 L54,16 L58,14 L60,11 L61,6 L62,6 L63,11 L65,10 L65,54 L-1,54 L-1,1 Z "
      fill="#FEFEFE"
      transform="translate(16,42)"
    />
    <path
      d="M0,0 L2,0 L6,7 L6,9 L12,7 L9,23 L14,18 L18,15 L18,19 L27,18 L26,25 L25,28 L28,29 L23,34 L16,40 L15,45 L2,44 L2,59 L0,59 L0,44 L-5,45 L-13,45 L-14,40 L-25,31 L-26,28 L-23,28 L-25,18 L-16,19 L-16,16 L-12,18 L-10,21 L-7,22 L-10,7 L-4,9 L-2,4 Z "
      fill="#E72329"
      transform="translate(47,19)"
    />
    <path
      d="M0,0 L12,0 L12,96 L0,96 Z "
      fill="#E82127"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L12,0 L12,96 L0,96 Z "
      fill="#E82127"
      transform="translate(84,0)"
    />
    <path
      d="M0,0 L4,2 L4,4 L-6,4 L-6,13 L-3,15 L-3,17 L3,20 L3,23 L5,24 L5,27 L8,26 L10,25 L10,23 L19,23 L21,26 L25,28 L18,28 L18,43 L16,43 L16,28 L11,29 L3,29 L2,24 L-9,15 L-10,12 L-7,12 L-9,2 L0,3 Z "
      fill="#D43439"
      transform="translate(31,35)"
    />
    <path
      d="M0,0 L4,2 L2,2 L0,7 L-2,11 L-7,10 L-5,19 L-5,24 L-9,23 L-14,18 L-14,15 L-11,15 L-11,6 L-9,6 L-9,8 L-7,8 L-7,6 L-3,5 L-3,1 L-1,1 L0,2 Z "
      fill="#FCEBEC"
      transform="translate(45,17)"
    />
    <path
      d="M0,0 L8,6 L13,10 L13,15 L26,14 L26,29 L28,30 L25,30 L25,16 L19,16 L19,19 L11,19 L10,13 L11,10 L3,9 L3,7 L0,6 Z "
      fill="#FDE6E5"
      transform="translate(21,49)"
    />
    <path
      d="M0,0 L1,3 L-1,10 L-8,9 L-8,8 L-13,7 L-13,20 L-14,20 L-14,5 L-1,6 L-1,1 Z "
      fill="#FBD9D6"
      transform="translate(63,58)"
    />
    <path
      d="M0,0 L6,0 L11,1 L11,3 L2,2 L4,12 L1,12 L0,14 L1,6 Z "
      fill="#FEE4DF"
      transform="translate(20,35)"
    />
    <path
      d="M0,0 L1,3 L4,3 L3,11 L0,10 L1,7 L-1,6 Z "
      fill="#FBD4D4"
      transform="translate(73,41)"
    />
    <path
      d="M0,0 L2,4 L2,9 L-2,8 L-2,4 Z "
      fill="#FCCEC5"
      transform="translate(38,32)"
    />
    <path
      d="M0,0 L2,4 L4,5 L2,9 L-1,10 Z "
      fill="#FDC8BB"
      transform="translate(57,32)"
    />
    <path
      d="M0,0 L2,0 L2,2 L4,2 L3,8 L1,4 L-3,5 L-2,1 Z "
      fill="#FAD9D9"
      transform="translate(56,23)"
    />
    <path
      d="M0,0 L2,3 L3,10 L0,10 L-1,12 L0,4 Z "
      fill="#F8C6C5"
      transform="translate(21,37)"
    />
  </svg>
)
export default Canada
