import * as React from 'react'
const TermCondition = (props) => (
  <svg
    width={19}
    height={22}
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 6v-.2c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C14.72 1 13.88 1 12.2 1H5.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C1 3.28 1 4.12 1 5.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C3.28 21 4.12 21 5.8 21h3.7m0-11H5m3.5 4H5m8-8H5m10 11v-5.5a1.5 1.5 0 0 1 3 0V17a3 3 0 1 1-6 0v-4"
      stroke="green"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default TermCondition
