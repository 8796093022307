import React from 'react'
const ConmebolLibertadores = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={122}
    viewBox="0 0 150 122"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,122 L0,122 Z "
      fill="#FDFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L6,0 L7,45 L8,16 L12,2 L15,3 L15,5 L19,6 L18,10 L16,15 L15,21 L15,38 L18,47 L22,54 L25,57 L17,56 L17,60 L12,62 L11,64 L15,66 L21,65 L21,72 L23,72 L23,74 L14,75 L14,77 L11,77 L17,80 L18,81 L18,87 L14,90 L8,92 L4,90 L4,87 L1,86 L3,86 L1,83 L-2,90 L-4,90 L-3,82 L-6,81 L-6,90 L-9,90 L-9,81 L-12,81 L-14,86 L-13,90 L-15,90 L-19,86 L-19,90 L-21,90 L-21,78 L-14,78 L-14,76 L-16,75 L-16,66 L-13,66 L-14,71 L-12,71 L-11,66 L-8,66 L-8,70 L-6,70 L-6,65 L-1,64 L1,65 L2,60 L-3,60 L-4,57 L-10,57 L-10,53 L-14,57 L-19,57 L-13,49 L-9,36 L-9,21 L-12,11 L-13,7 L-11,7 L-8,10 L-9,3 L-5,3 L-1,4 Z "
      fill="#F8F4EA"
      transform="translate(72,16)"
    />
    <path
      d="M0,0 L6,0 L7,45 L8,16 L12,2 L15,3 L15,5 L19,6 L18,10 L16,15 L15,21 L15,38 L18,47 L22,54 L25,57 L20,57 L19,54 L17,54 L16,57 L11,57 L9,53 L8,57 L6,57 L4,54 L5,48 L1,46 L2,54 L-2,56 L-1,44 Z "
      fill="#F1C859"
      transform="translate(72,16)"
    />
    <path
      d="M0,0 L2,1 L1,6 L3,6 L4,1 L7,1 L7,5 L9,5 L10,1 L13,1 L13,8 L7,8 L3,9 L3,13 L20,13 L21,10 L18,9 L16,7 L14,8 L14,1 L16,3 L18,0 L28,0 L30,1 L36,0 L36,7 L38,7 L38,9 L29,10 L29,12 L26,12 L32,15 L33,16 L33,22 L29,25 L23,27 L19,25 L19,22 L16,21 L18,21 L16,18 L13,25 L11,25 L12,17 L9,16 L9,25 L6,25 L6,16 L3,16 L1,21 L2,25 L0,25 L-4,21 L-4,25 L-6,25 L-6,13 L1,13 L1,11 L-1,10 L-1,1 Z "
      fill="#F7F7F6"
      transform="translate(57,81)"
    />
    <path
      d="M0,0 L2,0 L5,11 L6,18 L6,42 L3,55 L-2,55 L-2,51 L-6,55 L-11,55 L-5,47 L-1,34 L-1,19 L-4,9 L-5,5 L-3,5 L0,8 L-1,1 Z "
      fill="#F8EFD9"
      transform="translate(64,18)"
    />
    <path
      d="M0,0 L1,2 L5,3 L4,7 L2,12 L1,18 L1,35 L4,44 L8,51 L11,54 L6,54 L5,51 L1,52 L-3,41 L-4,33 L-4,16 Z "
      fill="#E6D9C4"
      transform="translate(86,19)"
    />
    <path
      d="M0,0 L2,1 L1,6 L3,6 L4,1 L7,1 L7,5 L9,5 L10,1 L13,1 L13,8 L7,8 L3,9 L2,14 L12,14 L9,15 L9,25 L6,25 L6,16 L3,16 L1,21 L2,25 L0,25 L-4,21 L-4,25 L-6,25 L-6,13 L1,13 L1,11 L-1,10 L-1,1 Z "
      fill="#828282"
      transform="translate(57,81)"
    />
    <path
      d="M0,0 L10,0 L12,1 L18,0 L18,7 L20,7 L20,9 L11,10 L11,12 L5,13 L4,10 L0,9 L-2,7 L-4,8 L-4,1 L-2,3 Z "
      fill="#EFEFEF"
      transform="translate(75,81)"
    />
    <path
      d="M0,0 L9,1 L9,3 L4,3 L4,6 L9,6 L9,8 L4,8 L4,11 L10,11 L10,13 L-2,14 L-8,13 L-8,2 L-1,1 Z "
      fill="#C9C9C9"
      transform="translate(39,93)"
    />
    <path
      d="M0,0 L2,0 L5,11 L6,18 L6,42 L3,55 L-2,55 L1,44 L2,40 L2,14 L-1,4 Z "
      fill="#A58147"
      transform="translate(64,18)"
    />
    <path
      d="M0,0 L8,0 L8,3 L3,3 L3,5 L8,5 L9,2 L11,1 L17,1 L16,3 L12,2 L12,5 L17,6 L18,10 L15,12 L0,12 Z "
      fill="#D5D5D5"
      transform="translate(115,94)"
    />
    <path
      d="M0,0 L2,1 L1,8 L-1,17 L-1,34 L1,46 L4,55 L-1,55 L-4,40 L-4,14 Z "
      fill="#B18B39"
      transform="translate(84,18)"
    />
    <path
      d="M0,0 L5,0 L8,3 L8,9 L5,12 L0,12 L-3,9 L-3,3 Z "
      fill="#141414"
      transform="translate(95,94)"
    />
    <path
      d="M0,0 L6,0 L10,3 L10,9 L6,12 L0,12 Z "
      fill="#292929"
      transform="translate(80,94)"
    />
    <path
      d="M0,0 L4,2 L4,14 L-8,14 L-8,2 L-5,2 L-5,11 L-1,11 L-1,2 Z "
      fill="#DCDCDC"
      transform="translate(25,92)"
    />
    <path
      d="M0,0 L1,0 L3,10 L2,14 L1,11 L0,15 L-2,15 L-4,12 L-3,6 L-7,4 L-6,12 L-10,14 L-9,2 L-8,1 L-2,1 L-1,3 Z "
      fill="#A69385"
      transform="translate(80,58)"
    />
    <path
      d="M0,0 L2,0 L6,11 L6,34 L1,47 L-1,50 L-6,50 L0,42 L4,29 L4,14 L1,4 Z "
      fill="#594634"
      transform="translate(59,23)"
    />
    <path
      d="M0,0 L1,3 L-1,8 L-2,14 L-2,31 L1,40 L5,47 L8,50 L3,50 L-3,39 L-4,35 L-4,10 L-1,1 Z "
      fill="#6B5941"
      transform="translate(89,23)"
    />
    <path
      d="M0,0 L5,0 L5,18 L0,18 Z "
      fill="#A58140"
      transform="translate(72,16)"
    />
    <path
      d="M0,0 L7,0 L8,4 L8,10 L6,11 L0,11 Z "
      fill="#757575"
      transform="translate(31,95)"
    />
    <path
      d="M0,0 L1,0 L1,43 L-5,43 L-5,18 L0,18 Z M-4,20 L-4,42 L0,42 L0,21 Z "
      fill="#C8A960"
      transform="translate(77,16)"
    />
    <path
      d="M0,0 L7,0 L8,5 L7,7 L8,11 L6,11 L2,7 L2,11 L0,11 Z "
      fill="#404040"
      transform="translate(51,95)"
    />
    <path
      d="M0,0 L7,0 L8,5 L7,7 L8,11 L6,11 L2,7 L2,11 L0,11 Z "
      fill="#707070"
      transform="translate(105,95)"
    />
    <path
      d="M0,0 L8,0 L8,3 L3,3 L3,5 L7,5 L7,7 L2,7 L2,10 L8,10 L8,12 L0,12 Z "
      fill="#494949"
      transform="translate(115,94)"
    />
    <path
      d="M0,0 L3,0 L3,11 L-5,9 L-5,6 L0,6 L0,4 L-5,4 L-5,1 Z "
      fill="#CECECE"
      transform="translate(48,95)"
    />
    <path
      d="M0,0 L6,0 L6,2 L4,2 L5,5 L4,7 L6,7 L6,9 L0,9 L-2,7 L-4,8 L-4,1 L-2,3 Z "
      fill="#7C7D7C"
      transform="translate(75,81)"
    />
    <path
      d="M0,0 L2,0 L6,13 L7,17 L2,17 L0,9 Z "
      fill="#4F3921"
      transform="translate(81,56)"
    />
    <path
      d="M0,0 L2,4 L0,6 L1,7 L2,10 L-6,13 L-6,11 L-10,10 L-8,8 L-8,10 L-6,10 L-5,8 L-5,10 L-3,10 L-3,3 L-6,4 L-6,1 L0,2 Z "
      fill="#E2E2E0"
      transform="translate(73,79)"
    />
    <path
      d="M0,0 L2,0 L2,7 L4,7 L4,9 L-3,9 L-4,8 L-4,1 Z "
      fill="#929291"
      transform="translate(91,81)"
    />
    <path
      d="M0,0 L1,0 L1,11 L0,17 L-5,17 L-2,6 Z "
      fill="#503A24"
      transform="translate(67,56)"
    />
    <path
      d="M0,0 L3,0 L7,9 L7,11 L4,11 L4,8 L1,7 L3,7 L1,4 L-2,11 L-4,11 L-1,2 Z "
      fill="#292929"
      transform="translate(72,95)"
    />
    <path
      d="M0,0 L6,0 L6,5 L1,3 L2,11 L-2,13 L-1,1 Z "
      fill="#876941"
      transform="translate(72,59)"
    />
    <path
      d="M0,0 L2,2 L3,11 L2,27 L1,27 L0,9 L-2,1 Z "
      fill="#E6D5B2"
      transform="translate(64,25)"
    />
    <path
      d="M0,0 L4,1 L5,6 L3,8 L-1,7 L-2,2 Z "
      fill="#E7E7E7"
      transform="translate(96,96)"
    />
    <path
      d="M0,0 L3,1 L2,14 L0,14 L-2,8 L-1,3 L-8,2 L-8,1 Z "
      fill="#F0F0F0"
      transform="translate(113,93)"
    />
    <path
      d="M0,0 L5,1 L6,6 L4,8 L0,8 Z "
      fill="#A6A6A6"
      transform="translate(82,96)"
    />
    <path
      d="M0,0 L6,0 L5,2 L1,1 L1,4 L6,5 L7,9 L4,11 L-1,10 L0,8 L5,9 L4,7 L-1,5 Z "
      fill="#141414"
      transform="translate(126,95)"
    />
    <path
      d="M0,0 L7,0 L7,1 L2,1 L2,4 L7,4 L7,6 L2,6 L2,9 L7,9 L7,10 L0,10 Z "
      fill="#080808"
      transform="translate(41,95)"
    />
    <path
      d="M0,0 L3,0 L3,4 L5,4 L6,0 L9,0 L9,7 L2,7 L2,1 Z "
      fill="#6C6C6A"
      transform="translate(61,82)"
    />
    <path
      d="M0,0 L3,0 L3,12 L0,12 Z "
      fill="#5D5D5D"
      transform="translate(26,94)"
    />
    <path
      d="M0,0 L4,5 L7,10 L10,13 L5,13 L0,3 Z "
      fill="#422A2A"
      transform="translate(87,60)"
    />
    <path
      d="M0,0 L4,1 L4,4 L5,10 L-1,10 L0,4 L-1,2 Z "
      fill="#F1E6DF"
      transform="translate(73,62)"
    />
    <path
      d="M0,0 L7,0 L8,3 L2,1 L2,5 L4,6 L2,6 L2,11 L0,11 Z "
      fill="#0B0B0B"
      transform="translate(105,95)"
    />
    <path
      d="M0,0 L2,0 L4,7 L4,13 L2,12 L-1,4 Z "
      fill="#5C4327"
      transform="translate(64,18)"
    />
    <path
      d="M0,0 L1,0 L3,6 L4,8 L3,10 L0,10 L-2,8 L-5,7 L-2,5 Z "
      fill="#DEDEDE"
      transform="translate(91,98)"
    />
    <path
      d="M0,0 L2,0 L2,9 L7,9 L7,11 L0,11 Z "
      fill="#181818"
      transform="translate(17,95)"
    />
    <path
      d="M0,0 L6,0 L2,1 L2,4 L6,4 L6,6 L2,6 L2,10 L0,10 Z "
      fill="#0E0E0E"
      transform="translate(31,95)"
    />
    <path
      d="M0,0 L3,0 L3,10 L-1,10 Z "
      fill="#E4B746"
      transform="translate(74,48)"
    />
    <path
      d="M0,0 L4,0 L4,8 L0,8 Z "
      fill="#492D0D"
      transform="translate(73,16)"
    />
    <path
      d="M0,0 L4,0 L4,7 L0,8 Z "
      fill="#868688"
      transform="translate(82,82)"
    />
    <path
      d="M0,0 L2,1 L1,8 L-1,14 L-2,14 L-2,6 Z "
      fill="#59371A"
      transform="translate(84,18)"
    />
    <path
      d="M0,0 L15,0 L12,1 L11,6 L6,2 L0,1 Z "
      fill="#BABAB9"
      transform="translate(60,94)"
    />
    <path
      d="M0,0 L2,1 L3,11 L1,11 L0,13 Z "
      fill="#88632B"
      transform="translate(80,45)"
    />
    <path
      d="M0,0 L2,4 L1,9 L-1,12 L-1,10 L-3,10 Z "
      fill="#DFCFC5"
      transform="translate(63,59)"
    />
    <path
      d="M0,0 L1,0 L1,7 L-6,6 L-6,3 L0,3 Z "
      fill="#E4E4E4"
      transform="translate(49,98)"
    />
    <path
      d="M0,0 L5,0 L8,4 L1,3 L-3,5 L-1,1 Z "
      fill="#272727"
      transform="translate(95,94)"
    />
    <path
      d="M0,0 L4,0 L4,4 L2,4 L2,6 L0,6 Z "
      fill="#FAFAFA"
      transform="translate(83,97)"
    />
    <path
      d="M0,0 L3,1 L1,6 L-1,3 L-8,2 L-8,1 Z "
      fill="#D3D3D3"
      transform="translate(113,93)"
    />
    <path
      d="M0,0 L4,0 L4,4 L2,4 L2,6 L0,6 Z "
      fill="#895B20"
      transform="translate(73,24)"
    />
    <path
      d="M0,0 L3,1 L2,6 L-1,6 Z "
      fill="#5B3731"
      transform="translate(71,65)"
    />
    <path
      d="M0,0 L2,1 L3,6 L-2,6 Z "
      fill="#BABABA"
      transform="translate(73,98)"
    />
    <path
      d="M0,0 L1,2 L3,2 L3,4 L0,6 L-2,9 L-1,2 Z "
      fill="#E0D7D4"
      transform="translate(89,26)"
    />
  </svg>
)
export default ConmebolLibertadores
