import * as React from 'react'
const NationsLeague = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 91"
    width={150}
    height={91}
    {...props}
  >
    <path
      d="M18 10h7l11 5 9 1 9-3 2 12 1 4v24l4 11v2l-10 3h-8l-8-3-8 1-11 6-4 1H5l-1-1V16Z"
      fill="#35886A"
    />
    <path
      d="M18 10h7l11 5 9 1 9-3 2 12 1 9-5-2-5 1-2 3h-6l-10-5-1 3-5-1-4-1h-4v3l-6 2H4V16Z"
      fill="#C7A07F"
    />
    <path
      d="m115 35 6 1 3 3 1-4 4 2 4 3 1-5 5 1h2l6-1 3 2v2l-2 2 2 2v6l-4 1v5l-7 2 1 9h-11V51h15l-8-1-5-4-1 4h-5l-1-3-3 3h-8l-4-4v-6l3-4Zm0 5-1 5 5 1 1-4-1-2Zm24 1v6l4-3Zm4 4"
      fill="#606A83"
    />
    <path
      d="M77 51h10l1 4-2 6h3l5-10h2l3 7 4-6 7-1 4 2v4l1-6h5v11h3V51h5v12l-3 3h-7l-3-2-3 2H77Zm29 5-1 5 5 1-3-1v-4l3-1Zm-5 7"
      fill="#606A83"
    />
    <path
      d="m23 11 7 1 10 4 8-1 6-2 2 14-6 3h-8l-14-6h-7l-9 4 2-4 3-6h2l2-5Z"
      fill="#E3B317"
    />
    <path
      d="M22 52h3l-2 4-2 6 4 2 4-1 3-4 4 1 4 5 4-2 5-1v2l5-1 1-3 4-1 2 7-10 3h-8l-8-3-8 1-11 6-4 1H5l-1-1V59h6l-2 4-2 2h6l6-2v-2l-5-2v-2Z"
      fill="#D99716"
    />
    <path
      d="M14 31h8l2 2h4l1-2 4 1 6 3h6l3-4 5 1 4 2v6l-4 2-5-1-4-2-8 1-4-2h-4l4 5-2 1h-6l-4 1 3-5 2-3-15 7H4v-7l11-2Z"
      fill="#3477A2"
    />
    <path
      d="M22 37h3l-2 4-1 3 2-1h7l-4-6 7 1 6 1 6-1 3 3 8-1-1 7-2 1-2 4-5-1v-2h-4l-4-6-4 1-7 6-6-1-9-4-4 2-5 5v-8l8-2Z"
      fill="#B0A5A6"
    />
    <path
      d="m65 35 4 2 4 3 2-5h4l1 11 6-11 2 1 6 14H77l-6-5-1 5h-5Zm21 9"
      fill="#606A83"
    />
    <path d="M18 10h7l-5 6-1 2h-2l-2 6-5 5-6 1V16Z" fill="#3A627D" />
    <path d="M90 35h18v15h-5V40l-3 1v9h-5l-1-1v-8l-4-2Z" fill="#606A84" />
    <path d="M2 15h1l1 63H1l-1-1V17Z" fill="#5F6982" />
    <path
      d="M22 52h3l-2 4-2 6-4 5-5 7H5l-1-1V59h6l-2 4-2 2h6l6-2v-2l-5-2v-2Z"
      fill="#E16647"
    />
    <path
      d="M15 33h6l9 3-2 2 4 5-2 1h-6l-4 1 3-5 2-3-15 7H4v-7l11-2Z"
      fill="#217AB7"
    />
    <path d="m11 46 7 1-2 5-2 4v3l4 2v2l-6 3H5l5-7H4v-7Z" fill="#287342" />
    <path
      d="m36 42 4 1 3 4v2h4l1 2h4l2-4h2v6l-3 2H41l-9-4-4-2Z"
      fill="#3B4460"
    />
    <path
      d="M55 53h2l1 7h-3l-1 4h-5v-2l-5 2-4 2-3-3v-4l4 1-1-5 13-1Z"
      fill="#8DA7AD"
    />
    <path d="m53 13 1 3 2 11-6 3h-8l3-2-4-5 4-4v-3Z" fill="#B18A2C" />
    <path d="M65 20h6v9h2l1-9h4v12l-4 3-6-1-3-5Z" fill="#5F6983" />
    <path d="M79 20h11l-1 9 1 5H79Z" fill="#606A83" />
    <path d="M19 24h5l-5 8h-4v3l-6 2H4v-7l13-5Z" fill="#E78788" />
    <path
      d="m105 20 3 1 5 11-1 3-8-1h-5l2-6Zm-14 0h12l-3 5v5h-4v4h-5Z"
      fill="#606A83"
    />
    <path
      d="m44 38 4 2 5 1 4-1-1 7-2 1-2 4-5-1v-2l-2-1 4-5h-7l-3-3Z"
      fill="#2271AC"
    />
    <path d="m66 51 5 1v9l5 1v4H65V52Z" fill="#606A83" />
    <path
      d="m38 53 3 2 1 6-5-2-1 2-5-2-2 3h-5l-1-5 3-3h5l2 2 4-1Z"
      fill="#77445A"
    />
    <path d="M22 37h3l-2 4-4 5-8-1-6 7H4v-8l8-2Z" fill="#E9E9EE" />
    <path d="M14 31h8l2 2h4l1-2 4 1 10 4-3 4-6-1-13-5-7-2Z" fill="#2E3977" />
    <path d="M18 10h7l-5 6-1 2-8-2-6 7H4v-7Z" fill="#E1E5E3" />
    <path d="m11 46 7 1-2 5-3 5-5-1-3-3 2-4Z" fill="#D82B19" />
    <path d="M15 33h2l-2 6-5 5H4v-7l11-2Z" fill="#188F54" />
    <path d="m30 19 4 1 5 5v-2l4 2 4 4h-7l-14-6Z" fill="#782136" />
    <path d="m23 11 7 1 6 3-3 3-14-1 3-5Z" fill="#B0B6B2" />
    <path d="m19 63 6 1 2 1v2l-11 6h-3l2-4Z" fill="#F3CE0A" />
    <path d="M54 27h2l1 7-5-2-5 1-2 3-3-1-3-4v-2h11Z" fill="#DBDDDF" />
    <path d="m24 24 4 2 3 3v2h-2l-1 3-5-1-4-3Z" fill="#68A7C7" />
    <path d="M4 59h6l-2 4-2 4 6 5v2H5l-1-1Z" fill="#F8C00E" />
    <path d="M55 53h2l1 7h-3l-1 4h-5v-5l3-2-1-2Z" fill="#EAD993" />
    <path d="m36 42 4 1 1 5-5-1-2-1 3 6-9-2 4-5Z" fill="#0B7038" />
    <path d="M22 52h3l-2 4-3 5-6-1-1-3Z" fill="#EAEBE7" />
    <path d="m53 13 1 3v4h-4l1 3-6 1 1-4 2-5Z" fill="#CCC1C3" />
    <path d="m26 52 3 1-5 4v4h5l2-3 2 1-5 6-5-1-3-2 2-6Z" fill="#644467" />
    <path d="m26 54 4 1v6l-1 1h-5l-1-5Z" fill="#ECB517" />
    <path d="M19 24h5l-5 8-5-1 1-5Z" fill="#F8EDEE" />
    <path d="m17 18 7 4-3 2-9 4 2-4Z" fill="#E0191B" />
    <path d="M27 51h8l3 2-2 4-4-1Z" fill="#E1DFDE" />
    <path d="M54 47h2v6l-3 2-5-1-4-5h3l1 2h4Z" fill="#4B6C3F" />
    <path d="m25 38 4 1 3 5h-8l-4 1 3-5Z" fill="#902440" />
    <path d="M11 17h3v6l-2 2-5-1 2-5Z" fill="#F0DC92" />
    <path d="m36 16 10 1-2 5-4 1Z" fill="#DAD2D2" />
    <path d="m37 59 4 1h3v4l-4 2-3-3Z" fill="#778CAB" />
    <path d="M21 23h7l5 2 1 6h-3l-5-6-5-1Z" fill="#CBBDBF" />
    <path d="m34 16 4 2 2 5-1 2-4-2-3-5Z" fill="#B35819" />
    <path d="m11 46 7 1-2 5-1-3-7 3-3-1Z" fill="#C1434F" />
    <path d="m12 42 1 2-5 5-4 3v-8Z" fill="#C3C7CF" />
    <path d="M23 11h2l4 6H19l3-5Z" fill="#24716B" />
    <path d="M41 43h8l-2 4-4 2-2-3Z" fill="#CBC9CC" />
    <path d="m58 59 2 3 1 4-7 2 1-4Z" fill="#F6BF0F" />
    <path d="M24 43h6l-1 4-5 1-2-4Z" fill="#F0EBE2" />
    <path d="m9 14 3 1-7 8H4v-7Z" fill="#CBCDD3" />
    <path d="M14 31h8l2 2h4l1-2h2l-2 4-4 1-4-2-7-2Z" fill="#C91727" />
    <path d="M30 44h2l-2 4-2 2-6-1-3-2v-2l4 1 5 1Z" fill="#E4C012" />
    <path d="m36 42 4 1 1 5-5-1-2-1Z" fill="#AC6B15" />
    <path d="m34 33 9 3-3 4h-3l-3-5Z" fill="#741213" />
    <path d="M55 53h2l1 7-4-1v-2l-3-1Z" fill="#C9D4DE" />
    <path d="m43 50 4 2 2 3h-8v-3Z" fill="#038B3E" />
    <path d="M4 59h6l-2 4-4 3Z" fill="#E0E3E0" />
    <path d="M21 30h10l-2 1-1 3-5-1Z" fill="#324488" />
    <path d="M13 64h3l-2 5H9l-1-3Z" fill="#EEE0DF" />
    <path d="M13 49h2l-1 4-1 1H8v-3Z" fill="#F9B50B" />
    <path d="m11 31 4 1v3l-7 2 1-4h2Z" fill="#EFE1E3" />
    <path d="m4 24 6 3v2l-6 1Z" fill="#AB1B20" />
    <path d="M14 18h1v6l-3 4-5-2v-3l5 1Z" fill="#419C54" />
    <path d="m26 54 4 1v3l-7 1 2-4Z" fill="#F5EAE2" />
    <path d="m55 40 2 1-1 6-5-3v-2Z" fill="#C4C6CB" />
    <path d="m4 38 6 4v2H4Z" fill="#10438F" />
    <path d="m41 24 6 4v1h-7l-2-2Z" fill="#B40E12" />
    <path d="m54 21 2 4v2l-6 2Z" fill="#A31027" />
    <path d="M55 34h2v6l-5 2 2-5Z" fill="#174C8E" />
    <path d="M12 35h2l-1 4-4 1v-4Z" fill="#1A4F8F" />
    <path d="m48 33 5 1v2l-6 1Z" fill="#CB1729" />
    <path d="M4 31h4l-1 5-3 1Z" fill="#EFE5E5" />
    <path d="m46 20 5 1v2l-6 1Z" fill="#E3E8E1" />
    <path d="M44 49h3l1 2h5l-2 4-5-3Z" fill="#B7342A" />
    <path d="M54 35v3l-2 4-4-1-1-2h5Z" fill="#058958" />
    <path d="M54 27h2l1 7-4-2h2l-3-4Z" fill="#CBC8CD" />
    <path d="M45 16h2l1 3-3 5-2 2-2-3 4-4Z" fill="#C55741" />
    <path d="M51 48h2l-1 4-5-1v-2Z" fill="#E5DEE2" />
    <path d="m34 46 6 2 1 3-4-1-3-2Z" fill="#14568B" />
    <path d="M27 37h5l1 4h-3Z" fill="#D6D2D3" />
    <path d="m36 16 5 1v6l-4-5Z" fill="#BFB9BB" />
    <path d="m33 51 5 2-2 4-3-4Z" fill="#B5B8B8" />
    <path d="m16 50 3 4-4 3-2-1Z" fill="#1E9344" />
    <path d="m23 27 5 1 1 2h-7Z" fill="#E7CA2A" />
    <path d="M21 17h7l-1 3-5-1Z" fill="#E54F43" />
  </svg>
)
export default NationsLeague
