import React from 'react'
const England = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#B4C5CB"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L8,0 L9,1 L9,18 L26,18 L27,19 L27,27 L26,28 L9,28 L9,45 L8,46 L0,46 L-1,45 L-1,28 L-18,28 L-19,27 L-19,19 L-18,18 L-1,18 L-1,1 Z "
      fill="#EC2126"
      transform="translate(21,2)"
    />
    <path
      d="M0,0 L2,0 L2,17 L-15,17 L-13,10 L-5,2 Z "
      fill="#FDFDFE"
      transform="translate(18,3)"
    />
    <path
      d="M0,0 L17,0 L15,7 L11,12 L4,16 L0,17 Z "
      fill="#FDFEFE"
      transform="translate(30,30)"
    />
    <path
      d="M0,0 L17,0 L17,17 L10,15 L2,7 Z "
      fill="#FDFEFE"
      transform="translate(3,30)"
    />
    <path
      d="M0,0 L6,2 L14,9 L17,15 L17,17 L0,17 Z "
      fill="#FDFEFE"
      transform="translate(30,3)"
    />
    <path
      d="M0,0 L8,0 L9,1 L9,18 L8,18 L8,2 L2,2 L2,18 L-1,18 L-1,1 Z "
      fill="#EF4244"
      transform="translate(21,2)"
    />
  </svg>
)
export default England
