import React from 'react'
const Bundesliga2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={137}
    viewBox="0 0 150 137"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,137 L0,137 Z "
      fill="#FBF6F6"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L129,0 L129,41 L99,53 L59,69 L29,81 L1,92 L0,92 Z "
      fill="#CA0917"
      transform="translate(12,3)"
    />
    <path
      d="M0,0 L3,0 L3,59 L-126,59 L-126,50 L-110,44 L-95,38 L-82,33 L-68,27 L-52,21 L-38,15 L-36,15 L-36,19 L-29,17 L-16,17 L-15,18 L-15,24 L-20,27 L-30,30 L-35,34 L-36,36 L-37,52 L-5,52 L-5,43 L-26,43 L-26,38 L-23,36 L-10,32 L-6,28 L-5,26 L-5,15 L-10,10 L-15,8 L-15,6 Z "
      fill="#939393"
      transform="translate(138,48)"
    />
    <path
      d="M0,0 L125,0 L124,2 L122,1 L122,3 L124,3 L125,11 L125,37 L124,35 L121,37 L121,40 L118,41 L117,43 L109,46 L102,46 L102,43 L94,44 L95,40 L97,40 L97,32 L95,30 L88,31 L87,32 L87,40 L74,40 L74,38 L67,38 L65,36 L57,35 L51,32 L49,33 L46,31 L46,29 L49,29 L49,21 L47,21 L47,15 L50,15 L50,13 L47,13 L46,8 L44,7 L44,5 L49,5 L49,3 L10,3 L11,10 L16,11 L16,13 L12,13 L11,19 L8,20 L6,23 L6,21 L2,21 L1,2 Z "
      fill="#D20515"
      transform="translate(14,5)"
    />
    <path
      d="M0,0 L144,0 L143,40 L142,40 L142,3 L11,3 L11,96 L18,94 L19,96 L16,98 L12,98 L12,107 L24,107 L24,112 L26,112 L27,117 L26,118 L25,127 L23,127 L22,121 L20,120 L11,119 L11,133 L24,133 L24,137 L0,137 Z "
      fill="#FEFCFC"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L7,1 L9,3 L8,11 L6,14 L3,15 L-1,25 L8,26 L19,29 L28,31 L36,34 L49,36 L48,40 L42,43 L37,43 L37,39 L22,39 L20,38 L-1,39 L-1,55 L-10,61 L-23,66 L-28,71 L-31,70 L-30,64 L-27,59 L-20,59 L-12,52 L-10,52 L-14,43 L-16,30 L-15,19 L-13,13 L-19,15 L-24,19 L-27,23 L-31,23 L-31,19 L-25,14 L-19,7 L-17,6 L-3,6 L-2,2 Z "
      fill="#FDF6F4"
      transform="translate(50,11)"
    />
    <path
      d="M0,0 L5,0 L8,3 L8,9 L5,12 L4,15 L12,14 L12,17 L17,17 L15,19 L-18,32 L-38,40 L-46,40 L-46,38 L-48,38 L-48,40 L-50,40 L-50,43 L-57,43 L-58,37 L-57,36 L-52,36 L-52,29 L-48,29 L-48,22 L-47,18 L-36,17 L-36,15 L-43,15 L-43,14 L-34,14 L-32,15 L-17,16 L-17,20 L-11,18 L-6,16 L-5,13 L-14,13 L-24,10 L-28,7 L-24,6 L-23,8 L-16,9 L-16,11 L-3,11 L-4,9 L-4,3 Z "
      fill="#D00618"
      transform="translate(104,34)"
    />
    <path
      d="M0,0 L11,3 L16,8 L17,9 L17,20 L14,25 L6,29 L-5,32 L-5,37 L16,37 L16,46 L-16,46 L-16,30 L-11,24 L-3,21 L5,18 L5,12 L-13,12 L-14,9 L-12,5 L-7,3 L-1,2 Z "
      fill="#FAFAFA"
      transform="translate(117,54)"
    />
    <path
      d="M0,0 L10,0 L11,3 L5,3 L5,7 L10,7 L10,9 L5,9 L5,13 L11,13 L12,19 L-8,19 L-9,17 L-6,16 L-14,15 L-14,1 L-5,1 L-2,1 Z M23,-2 L25,-1 L25,5 L26,1 L30,1 L30,13 L36,13 L36,15 L26,15 L25,12 L24,17 L18,18 L12,16 L14,15 L14,13 L20,12 L20,10 L14,8 L14,3 L17,0 Z M12,17 Z "
      fill="#E7E7E7"
      transform="translate(70,118)"
    />
    <path
      d="M0,0 L5,0 L8,3 L8,9 L5,12 L4,15 L12,14 L12,17 L17,17 L15,19 L-6,27 L-8,27 L-8,22 L0,23 L-3,20 L-3,18 L-11,19 L-9,17 L-6,16 L-5,13 L-14,13 L-24,10 L-28,7 L-24,6 L-23,8 L-16,9 L-16,11 L-3,11 L-4,9 L-4,3 Z "
      fill="#C5111C"
      transform="translate(104,34)"
    />
    <path
      d="M0,0 L18,0 L20,1 L20,3 L25,0 L29,0 L34,12 L34,14 L30,14 L30,11 L23,11 L23,14 L20,14 L19,9 L18,14 L10,14 L7,11 L7,2 L4,2 L4,14 L0,14 Z "
      fill="#D2D2D2"
      transform="translate(108,119)"
    />
    <path
      d="M0,0 L9,0 L12,2 L13,6 L12,8 L14,8 L14,0 L18,0 L18,8 L21,9 L23,11 L23,0 L26,0 L26,12 L22,18 L13,18 L13,15 L0,14 Z "
      fill="#F5F5F5"
      transform="translate(11,119)"
    />
    <path
      d="M0,0 L129,0 L129,1 L1,1 L2,87 L7,87 L7,86 L13,86 L13,80 L8,80 L12,75 L15,74 L20,78 L16,84 L19,85 L1,92 L0,92 Z "
      fill="#BB2A33"
      transform="translate(12,3)"
    />
    <path
      d="M0,0 L1,2 L4,3 L4,17 L-1,17 L-4,11 L-4,9 L-6,9 L-6,17 L-10,17 L-10,3 L-1,1 Z "
      fill="#BBBBBB"
      transform="translate(50,116)"
    />
    <path
      d="M0,0 L7,1 L16,3 L24,6 L37,8 L36,12 L30,15 L25,15 L25,11 L10,11 L10,10 L19,9 L18,6 L15,6 L15,4 L5,4 L5,2 L0,2 Z "
      fill="#FEE6E6"
      transform="translate(62,39)"
    />
    <path
      d="M0,0 L9,0 L11,1 L11,6 L9,6 L9,8 L11,8 L11,13 L10,14 L0,14 Z "
      fill="#383838"
      transform="translate(11,119)"
    />
    <path
      d="M0,0 L10,0 L12,2 L12,10 L10,12 L0,12 Z "
      fill="#0F0F0F"
      transform="translate(57,120)"
    />
    <path
      d="M0,0 L17,1 L17,2 L10,4 L10,10 L16,12 L16,8 L13,8 L13,6 L18,6 L18,14 L10,14 L7,11 L7,2 L4,2 L4,14 L0,14 Z "
      fill="#1B1B1B"
      transform="translate(108,119)"
    />
    <path
      d="M0,0 L5,0 L8,3 L8,9 L5,12 L-1,12 L-4,9 L-4,3 Z "
      fill="#FCE9E8"
      transform="translate(104,34)"
    />
    <path
      d="M0,0 L1,2 L3,2 L4,7 L10,9 L9,12 L3,12 L1,17 L0,17 L0,12 L-6,12 L-6,8 L-1,8 L-1,6 L-6,6 L-6,2 L0,2 Z "
      fill="#EEEEEE"
      transform="translate(81,119)"
    />
    <path
      d="M0,0 L9,0 L9,2 L3,2 L3,6 L8,6 L8,8 L3,8 L3,12 L9,12 L9,14 L0,14 Z "
      fill="#2F2F2F"
      transform="translate(72,119)"
    />
    <path
      d="M0,0 L3,0 L3,12 L0,14 L-6,14 L-8,12 L-8,1 L-6,1 L-5,11 L-1,11 Z "
      fill="#181818"
      transform="translate(34,119)"
    />
    <path
      d="M0,0 L4,0 L8,7 L8,9 L10,9 L10,0 L12,0 L12,12 L7,11 L4,5 L4,3 L2,3 L2,12 L0,12 Z "
      fill="#060606"
      transform="translate(41,120)"
    />
    <path
      d="M0,0 L4,0 L4,12 L10,12 L10,14 L0,14 Z "
      fill="#4F4F4F"
      transform="translate(96,119)"
    />
    <path
      d="M0,0 L6,0 L7,2 L1,2 L1,6 L7,7 L7,13 L5,14 L-2,14 L-2,12 L4,11 L4,9 L-2,7 L-2,2 Z "
      fill="#222222"
      transform="translate(86,119)"
    />
    <path
      d="M0,0 L9,1 L7,3 L4,2 L4,14 L0,14 Z "
      fill="#676767"
      transform="translate(108,119)"
    />
    <path
      d="M0,0 L1,0 L2,25 L4,27 L2,27 L1,30 L-1,23 L-2,9 Z "
      fill="#FACFD1"
      transform="translate(36,26)"
    />
    <path
      d="M0,0 L2,1 L6,11 L6,13 L2,13 L2,10 L-5,10 L-5,12 L-7,12 L-5,4 L-4,8 L1,8 Z "
      fill="#040404"
      transform="translate(136,120)"
    />
    <path
      d="M0,0 L18,1 L18,2 L5,3 L-1,8 L-6,14 L-7,10 L-3,9 L-4,5 L-2,5 L-2,3 L2,3 L2,1 Z "
      fill="#C62630"
      transform="translate(28,15)"
    />
    <path
      d="M0,0 L5,1 L6,2 L6,8 L4,10 L0,10 Z "
      fill="#F0F0F0"
      transform="translate(60,121)"
    />
    <path
      d="M0,0 L4,0 L4,2 L1,3 L-3,13 L2,13 L2,14 L-4,14 L-5,13 L-5,5 L-3,5 L-1,1 Z "
      fill="#F9DCDE"
      transform="translate(52,23)"
    />
    <path
      d="M0,0 L1,2 L4,3 L3,5 L6,5 L6,9 L1,9 L-1,7 L-1,1 Z "
      fill="#D7D7D7"
      transform="translate(118,122)"
    />
    <path
      d="M0,0 L4,1 L4,9 L-1,9 L-1,1 Z "
      fill="#393939"
      transform="translate(133,119)"
    />
    <path
      d="M0,0 L9,0 L11,1 L26,2 L26,3 L7,4 L7,1 L0,1 Z "
      fill="#D44046"
      transform="translate(61,48)"
    />
    <path
      d="M0,0 L4,0 L4,8 L7,9 L8,12 L3,11 L3,1 L1,1 L1,11 L0,11 Z "
      fill="#BBBBBB"
      transform="translate(25,119)"
    />
    <path
      d="M0,0 L2,2 L2,11 L9,11 L9,13 L0,13 Z "
      fill="#191919"
      transform="translate(72,120)"
    />
    <path
      d="M0,0 L2,4 L1,6 L3,6 L4,12 L-2,13 L0,6 L-2,6 L-2,4 L0,4 Z "
      fill="#E3E3E3"
      transform="translate(22,121)"
    />
    <path
      d="M0,0 L1,3 L10,3 L10,5 L3,5 L0,8 L-2,8 L-3,3 Z "
      fill="#F3C9C5"
      transform="translate(29,20)"
    />
    <path
      d="M0,0 L7,1 L9,3 L8,6 L4,2 L1,1 L1,3 L-1,4 L-2,7 L-4,6 L-1,1 Z "
      fill="#FDC7C9"
      transform="translate(50,11)"
    />
    <path
      d="M0,0 L2,1 L0,3 L0,9 L-5,10 L-5,6 Z "
      fill="#FBD8D6"
      transform="translate(35,65)"
    />
    <path
      d="M0,0 L15,0 L15,1 L9,1 L7,6 L6,6 L6,1 L0,1 Z "
      fill="#D5D5D5"
      transform="translate(75,130)"
    />
    <path
      d="M0,0 L2,0 L2,8 L-3,9 Z "
      fill="#323232"
      transform="translate(67,122)"
    />
    <path
      d="M0,0 L6,0 L6,4 L0,4 Z "
      fill="#DCDCDC"
      transform="translate(14,127)"
    />
    <path
      d="M0,0 L3,0 L3,2 L11,2 L11,3 L-3,4 L-3,2 L0,2 Z "
      fill="#EAEBE7"
      transform="translate(109,62)"
    />
    <path
      d="M0,0 L15,2 L15,3 L4,4 Z "
      fill="#FBBDB6"
      transform="translate(84,45)"
    />
    <path
      d="M0,0 L4,0 L5,4 L3,12 L0,11 L2,11 L2,3 Z "
      fill="#C32033"
      transform="translate(109,34)"
    />
    <path
      d="M0,0 L2,0 L4,5 L0,5 L0,2 L-6,2 L-6,1 Z "
      fill="#474747"
      transform="translate(138,128)"
    />
    <path
      d="M0,0 L5,0 L5,4 L0,4 Z "
      fill="#E2E2E2"
      transform="translate(14,121)"
    />
    <path
      d="M0,0 L5,1 L6,2 L6,8 L5,8 L5,3 L1,2 L0,4 Z "
      fill="#CBCBCB"
      transform="translate(60,121)"
    />
    <path
      d="M0,0 L2,1 L3,10 L-1,8 L1,8 L-1,1 Z "
      fill="#FDE4E2"
      transform="translate(39,55)"
    />
    <path
      d="M0,0 L2,1 L4,8 L1,8 Z "
      fill="#222222"
      transform="translate(136,120)"
    />
    <path
      d="M0,0 L2,0 L3,6 L-1,6 Z "
      fill="#D9D9D9"
      transform="translate(134,122)"
    />
  </svg>
)
export default Bundesliga2
