import React from 'react'

const Football = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2943_11545)">
        <path
          d="M3.01997 15.62C2.93997 18.04 3.33997 19.96 3.68997 20.31C4.03997 20.66 5.96997 21.07 8.37997 20.98L3.01997 15.62Z"
          fill="white"
        />
        <path
          d="M13.08 3.28C10.75 3.7 8.29003 4.62 6.46003 6.46C4.63003 8.3 3.70003 10.75 3.28003 13.08L10.91 20.71C13.25 20.3 15.7 19.37 17.53 17.53C19.36 15.69 20.29 13.24 20.71 10.91L13.08 3.28ZM9.90003 15.5L8.50003 14.1L14.1 8.5L15.5 9.9L9.90003 15.5Z"
          fill="white"
        />
        <path
          d="M20.98 8.38C21.06 5.96 20.66 4.04 20.31 3.69C19.96 3.34 18.03 2.93 15.62 3.02L20.98 8.38Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2943_11545">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Football
