import * as React from 'react'
const TwitterX = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    viewBox="0 0 150 150"
    {...props}
  >
    <path
      d="M0,0 L26,0 L34,12 L46,30 L48,31 L50,27 L59,17 L68,6 L71,3 L72,0 L82,0 L80,4 L67,19 L55,33 L52,36 L54,41 L69,63 L80,79 L84,85 L84,87 L58,87 L44,67 L37,57 L37,55 L35,55 L33,59 L23,70 L16,78 L9,87 L0,87 L2,83 L9,75 L18,64 L27,54 L31,48 L20,32 L7,13 L0,3 Z "
      fill="#fff"
      transform="translate(33,31)"
    />
    <path
      d="M0,0 L11,0 L25,20 L39,40 L55,63 L62,74 L51,74 L40,58 L28,41 L14,21 L0,1 Z "
      fill="#050505"
      transform="translate(44,38)"
    />
    <path d="M0,0 Z " fill="#fff" transform="translate(63,79)" />
  </svg>
)
export default TwitterX
