import * as React from 'react'
const Venezuela = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96px"
    height="96px"
    viewBox="0 0 96 96"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#f2ce3a"
        d="M -0.5,-0.5 C 31.5,-0.5 63.5,-0.5 95.5,-0.5C 95.5,9.83333 95.5,20.1667 95.5,30.5C 63.5,30.5 31.5,30.5 -0.5,30.5C -0.5,20.1667 -0.5,9.83333 -0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#504d6a"
        d="M -0.5,30.5 C 31.5,30.5 63.5,30.5 95.5,30.5C 95.5,31.1667 95.5,31.8333 95.5,32.5C 63.5,32.5 31.5,32.5 -0.5,32.5C -0.5,31.8333 -0.5,31.1667 -0.5,30.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#344196"
        d="M -0.5,32.5 C 31.5,32.5 63.5,32.5 95.5,32.5C 95.5,42.8333 95.5,53.1667 95.5,63.5C 63.5,63.5 31.5,63.5 -0.5,63.5C -0.5,53.1667 -0.5,42.8333 -0.5,32.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#4e5d9f"
        d="M 37.5,40.5 C 38.5676,39.5655 39.901,39.2322 41.5,39.5C 41.9321,40.7095 42.5987,41.7095 43.5,42.5C 43.44,43.0431 43.1067,43.3764 42.5,43.5C 41.8333,42.8333 41.1667,42.1667 40.5,41.5C 39.9005,44.0669 38.4005,45.7336 36,46.5C 34.2768,43.8282 33.1101,44.1615 32.5,47.5C 31.8333,46.8333 31.1667,46.1667 30.5,45.5C 31.5451,44.1224 32.8784,43.1224 34.5,42.5C 35.1355,46.1594 36.4689,46.4927 38.5,43.5C 38.7373,42.209 38.404,41.209 37.5,40.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#cbdbf6"
        d="M 45.5,38.5 C 46.1157,39.2022 46.1157,40.2022 45.5,41.5C 45.1583,42.3382 44.4916,42.6716 43.5,42.5C 42.5987,41.7095 41.9321,40.7095 41.5,39.5C 42.4716,37.5069 43.8049,37.1736 45.5,38.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#cfe1f9"
        d="M 52.5,37.5 C 52.5,37.8333 52.5,38.1667 52.5,38.5C 52.9175,39.2216 53.5842,39.7216 54.5,40C 53.0997,41.4381 51.433,42.2714 49.5,42.5C 49.67,41.1779 49.3366,40.0113 48.5,39C 49.9007,38.5899 51.234,38.0899 52.5,37.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#8d9fd5"
        d="M 37.5,40.5 C 38.404,41.209 38.7373,42.209 38.5,43.5C 37.5284,41.5069 36.1951,41.1736 34.5,42.5C 34.8212,40.9519 35.8212,40.2852 37.5,40.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#8d9fd8"
        d="M 57.5,40.5 C 59.1788,40.2852 60.1788,40.9519 60.5,42.5C 58.8049,41.1736 57.4716,41.5069 56.5,43.5C 56.2627,42.209 56.596,41.209 57.5,40.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e5f8fe"
        d="M 38.5,43.5 C 36.4689,46.4927 35.1355,46.1594 34.5,42.5C 36.1951,41.1736 37.5284,41.5069 38.5,43.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e3f7fe"
        d="M 60.5,42.5 C 59.8645,46.1594 58.5311,46.4927 56.5,43.5C 57.4716,41.5069 58.8049,41.1736 60.5,42.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e6f2ff"
        d="M 63.5,50.5 C 63.5,50.1667 63.5,49.8333 63.5,49.5C 62.4991,48.2113 62.6658,46.8779 64,45.5C 67.3135,47.4608 67.1468,49.1275 63.5,50.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e1eafa"
        d="M 30.5,45.5 C 31.1667,46.1667 31.8333,46.8333 32.5,47.5C 31.8116,52.6481 30.4783,52.8147 28.5,48C 29.0556,46.9889 29.7222,46.1556 30.5,45.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3e4b93"
        d="M 52.5,37.5 C 51.234,38.0899 49.9007,38.5899 48.5,39C 49.3366,40.0113 49.67,41.1779 49.5,42.5C 51.433,42.2714 53.0997,41.4381 54.5,40C 53.5842,39.7216 52.9175,39.2216 52.5,38.5C 54.7812,38.0773 56.4479,38.744 57.5,40.5C 56.596,41.209 56.2627,42.209 56.5,43.5C 58.5311,46.4927 59.8645,46.1594 60.5,42.5C 61.7498,42.5774 62.5831,43.244 63,44.5C 65.124,43.7728 67.2906,43.1062 69.5,42.5C 70.452,43.533 70.452,44.6996 69.5,46C 70.9607,48.3185 71.9607,50.8185 72.5,53.5C 72.3764,54.1067 72.0431,54.44 71.5,54.5C 70.1037,52.6275 68.437,52.2942 66.5,53.5C 65.9002,52.1001 64.9002,51.1001 63.5,50.5C 67.1468,49.1275 67.3135,47.4608 64,45.5C 62.6658,46.8779 62.4991,48.2113 63.5,49.5C 61.0842,48.0318 58.4175,47.3651 55.5,47.5C 55.7852,45.712 55.4519,44.0453 54.5,42.5C 53.6721,43.4147 52.6721,44.0814 51.5,44.5C 49.6682,43.1786 47.6682,42.1786 45.5,41.5C 46.1157,40.2022 46.1157,39.2022 45.5,38.5C 46.1522,37.2749 47.3189,36.6083 49,36.5C 50.3847,36.5143 51.5514,36.8476 52.5,37.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#9ca5dc"
        d="M 27.5,53.5 C 26.8333,53.5 26.1667,53.5 25.5,53.5C 26.1667,50.8333 26.8333,50.8333 27.5,53.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#c6d2f3"
        d="M 25.5,53.5 C 26.1667,53.5 26.8333,53.5 27.5,53.5C 28.2389,53.369 28.9056,53.5357 29.5,54C 28.9288,55.2051 28.2621,56.3717 27.5,57.5C 26.5745,56.3595 25.7412,56.3595 25,57.5C 24.6905,56.2341 24.1905,55.0674 23.5,54C 24.0944,53.5357 24.7611,53.369 25.5,53.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#c4d0f4"
        d="M 71.5,54.5 C 70.7321,55.2625 70.2321,56.2625 70,57.5C 69.2588,56.3595 68.4255,56.3595 67.5,57.5C 66.7379,56.3717 66.0712,55.2051 65.5,54C 65.7284,53.6012 66.0618,53.4346 66.5,53.5C 68.437,52.2942 70.1037,52.6275 71.5,54.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#a42747"
        d="M -0.5,63.5 C 31.5,63.5 63.5,63.5 95.5,63.5C 95.5,64.1667 95.5,64.8333 95.5,65.5C 63.5143,66.4392 31.5143,66.7726 -0.5,66.5C -0.5,65.5 -0.5,64.5 -0.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b92034"
        d="M 95.5,65.5 C 95.5,75.5 95.5,85.5 95.5,95.5C 63.5,95.5 31.5,95.5 -0.5,95.5C -0.5,85.8333 -0.5,76.1667 -0.5,66.5C 31.5143,66.7726 63.5143,66.4392 95.5,65.5 Z"
      />
    </g>
  </svg>
)
export default Venezuela
