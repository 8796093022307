import * as React from 'react'
const Ecuador = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96px"
    height="96px"
    viewBox="0 0 96 96"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#f2ce38"
        d="M -0.5,-0.5 C 31.5,-0.5 63.5,-0.5 95.5,-0.5C 95.5,15.1667 95.5,30.8333 95.5,46.5C 85.1667,46.5 74.8333,46.5 64.5,46.5C 64.5,44.1667 64.5,41.8333 64.5,39.5C 65.5,39.5 66.5,39.5 67.5,39.5C 67.6495,38.448 67.4828,37.448 67,36.5C 65.1804,35.511 63.3471,34.511 61.5,33.5C 58.4361,35.2982 55.1028,36.2982 51.5,36.5C 51.5,35.5 51.5,34.5 51.5,33.5C 53.1667,32.8333 54.8333,32.1667 56.5,31.5C 65.1319,31.2407 65.4652,29.9074 57.5,27.5C 57.5,27.1667 57.5,26.8333 57.5,26.5C 53.3402,25.3117 49.1736,24.6451 45,24.5C 42.2048,25.1852 39.3715,25.6852 36.5,26C 35.944,26.3826 35.6107,26.8826 35.5,27.5C 33.5956,27.7376 32.2623,28.7376 31.5,30.5C 29.5337,31.2545 29.2004,32.4212 30.5,34C 29.2068,34.49 27.8734,34.6567 26.5,34.5C 26.2155,36.4147 26.5489,38.0813 27.5,39.5C 28.5,39.5 29.5,39.5 30.5,39.5C 30.3398,41.1992 30.5065,42.8659 31,44.5C 31.3951,41.7934 31.8951,39.1267 32.5,36.5C 33.29,37.6085 33.79,38.9419 34,40.5C 34.4992,45.1548 34.6658,49.8215 34.5,54.5C 33.1314,52.3085 32.1314,49.9752 31.5,47.5C 20.8333,47.5 10.1667,47.5 -0.5,47.5C -0.5,31.5 -0.5,15.5 -0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e6c856"
        d="M 57.5,26.5 C 54.8333,26.5 52.1667,26.5 49.5,26.5C 49.5,27.5 49.5,28.5 49.5,29.5C 48.1667,29.5 46.8333,29.5 45.5,29.5C 45.5,28.5 45.5,27.5 45.5,26.5C 42.6146,26.1936 39.9479,26.5269 37.5,27.5C 36.8333,27.5 36.1667,27.5 35.5,27.5C 35.6107,26.8826 35.944,26.3826 36.5,26C 39.3715,25.6852 42.2048,25.1852 45,24.5C 49.1736,24.6451 53.3402,25.3117 57.5,26.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#2d2414"
        d="M 57.5,27.5 C 65.4652,29.9074 65.1319,31.2407 56.5,31.5C 54.0225,31.9492 52.0225,31.2825 50.5,29.5C 50.7093,28.914 51.0426,28.414 51.5,28C 53.4727,27.5045 55.4727,27.3379 57.5,27.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#473c1a"
        d="M 57.5,26.5 C 57.5,26.8333 57.5,27.1667 57.5,27.5C 55.4727,27.3379 53.4727,27.5045 51.5,28C 51.0426,28.414 50.7093,28.914 50.5,29.5C 52.0225,31.2825 54.0225,31.9492 56.5,31.5C 54.8333,32.1667 53.1667,32.8333 51.5,33.5C 50.5,33.8333 49.8333,34.5 49.5,35.5C 46.8915,35.4003 44.5582,36.0669 42.5,37.5C 40.3023,37.5912 39.9689,36.9245 41.5,35.5C 42.1667,35.5 42.5,35.1667 42.5,34.5C 44.0447,33.4196 45.5447,33.4196 47,34.5C 50.0264,33.4616 50.1931,32.1282 47.5,30.5C 45.8045,31.1744 44.1379,31.8411 42.5,32.5C 38.7423,32.152 35.0756,31.4853 31.5,30.5C 32.2623,28.7376 33.5956,27.7376 35.5,27.5C 36.1667,27.5 36.8333,27.5 37.5,27.5C 39.9479,26.5269 42.6146,26.1936 45.5,26.5C 45.5,27.5 45.5,28.5 45.5,29.5C 46.8333,29.5 48.1667,29.5 49.5,29.5C 49.5,28.5 49.5,27.5 49.5,26.5C 52.1667,26.5 54.8333,26.5 57.5,26.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#32312e"
        d="M 37.5,27.5 C 39.8333,27.5 42.1667,27.5 44.5,27.5C 44.5,28.5 44.5,29.5 44.5,30.5C 41.4816,30.6646 38.4816,30.498 35.5,30C 36.3805,29.2917 37.0472,28.4584 37.5,27.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#bab29a"
        d="M 42.5,34.5 C 42.5,33.8333 42.5,33.1667 42.5,32.5C 44.1379,31.8411 45.8045,31.1744 47.5,30.5C 50.1931,32.1282 50.0264,33.4616 47,34.5C 45.5447,33.4196 44.0447,33.4196 42.5,34.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e2c64d"
        d="M 31.5,30.5 C 35.0756,31.4853 38.7423,32.152 42.5,32.5C 42.5,33.1667 42.5,33.8333 42.5,34.5C 42.5,35.1667 42.1667,35.5 41.5,35.5C 40.8501,35.1961 40.1835,34.8627 39.5,34.5C 38.8243,35.6016 38.1577,36.6016 37.5,37.5C 36.2153,35.9378 34.7153,34.6045 33,33.5C 31.4134,34.6699 29.7467,35.6699 28,36.5C 27.5172,37.448 27.3505,38.448 27.5,39.5C 26.5489,38.0813 26.2155,36.4147 26.5,34.5C 27.8734,34.6567 29.2068,34.49 30.5,34C 29.2004,32.4212 29.5337,31.2545 31.5,30.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#c3b156"
        d="M 51.5,33.5 C 51.5,34.5 51.5,35.5 51.5,36.5C 55.1028,36.2982 58.4361,35.2982 61.5,33.5C 63.3471,34.511 65.1804,35.511 67,36.5C 67.4828,37.448 67.6495,38.448 67.5,39.5C 66.5,39.5 65.5,39.5 64.5,39.5C 64.7148,37.8212 64.0481,36.8212 62.5,36.5C 61.5826,36.4936 61.0826,37.4936 61,39.5C 60.5007,44.4889 60.3341,49.4889 60.5,54.5C 60.5,55.1667 60.1667,55.5 59.5,55.5C 59.5,50.8333 59.5,46.1667 59.5,41.5C 59.5,40.1667 59.5,38.8333 59.5,37.5C 57.2821,37.6138 55.2821,38.2804 53.5,39.5C 51.2901,39.106 49.9568,37.7727 49.5,35.5C 49.8333,34.5 50.5,33.8333 51.5,33.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#766e33"
        d="M 41.5,35.5 C 39.9689,36.9245 40.3023,37.5912 42.5,37.5C 42.3318,40.0514 41.3318,40.7181 39.5,39.5C 39.2648,38.9033 38.7648,38.5699 38,38.5C 36.9889,39.0556 36.1556,39.7222 35.5,40.5C 35.2852,38.8212 35.9519,37.8212 37.5,37.5C 38.1577,36.6016 38.8243,35.6016 39.5,34.5C 40.1835,34.8627 40.8501,35.1961 41.5,35.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#886d07"
        d="M 32.5,36.5 C 31.8951,39.1267 31.3951,41.7934 31,44.5C 30.5065,42.8659 30.3398,41.1992 30.5,39.5C 30.427,37.9731 31.0937,36.9731 32.5,36.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#71716b"
        d="M 49.5,35.5 C 49.9568,37.7727 51.2901,39.106 53.5,39.5C 53.5,40.1667 53.5,40.8333 53.5,41.5C 52.1667,40.8333 50.8333,40.1667 49.5,39.5C 47.5168,39.1568 46.1834,39.8235 45.5,41.5C 43.847,41.6596 42.5137,42.3263 41.5,43.5C 40.5985,47.2407 41.9319,49.2407 45.5,49.5C 44.8592,50.4822 44.1925,51.4822 43.5,52.5C 46.447,53.08 49.1137,54.08 51.5,55.5C 50.3052,55.6775 49.1385,56.0109 48,56.5C 46.1997,55.6805 44.3664,55.0138 42.5,54.5C 41.8999,53.0998 40.8999,52.0998 39.5,51.5C 39.8064,48.6146 39.4731,45.9479 38.5,43.5C 38.2322,41.901 38.5655,40.5676 39.5,39.5C 41.3318,40.7181 42.3318,40.0514 42.5,37.5C 44.5582,36.0669 46.8915,35.4003 49.5,35.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#2e2e34"
        d="M 39.5,39.5 C 38.5655,40.5676 38.2322,41.901 38.5,43.5C 38.1667,43.5 37.8333,43.5 37.5,43.5C 36.8333,43.5 36.1667,43.5 35.5,43.5C 35.5,42.5 35.5,41.5 35.5,40.5C 36.1556,39.7222 36.9889,39.0556 38,38.5C 38.7648,38.5699 39.2648,38.9033 39.5,39.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#464726"
        d="M 59.5,41.5 C 58.1667,41.5 57.5,42.1667 57.5,43.5C 56.8333,43.5 56.1667,43.5 55.5,43.5C 55.1667,42.5 54.5,41.8333 53.5,41.5C 53.5,40.8333 53.5,40.1667 53.5,39.5C 55.2821,38.2804 57.2821,37.6138 59.5,37.5C 59.5,38.8333 59.5,40.1667 59.5,41.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b9a26d"
        d="M 49.5,39.5 C 48.8166,41.1765 47.4832,41.8432 45.5,41.5C 46.1834,39.8235 47.5168,39.1568 49.5,39.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e7c641"
        d="M 62.5,36.5 C 62.8951,42.7616 62.2284,48.7616 60.5,54.5C 60.3341,49.4889 60.5007,44.4889 61,39.5C 61.0826,37.4936 61.5826,36.4936 62.5,36.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#647ca9"
        d="M 49.5,39.5 C 50.8333,40.1667 52.1667,40.8333 53.5,41.5C 53.5,43.5 53.5,45.5 53.5,47.5C 52.5,47.5 51.5,47.5 50.5,47.5C 47.9785,46.9885 45.6451,45.9885 43.5,44.5C 43.1583,43.6618 42.4916,43.3284 41.5,43.5C 42.5137,42.3263 43.847,41.6596 45.5,41.5C 47.4832,41.8432 48.8166,41.1765 49.5,39.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#d4e1e6"
        d="M 43.5,44.5 C 45.6451,45.9885 47.9785,46.9885 50.5,47.5C 49.4324,48.4345 48.099,48.7678 46.5,48.5C 45.1851,47.5244 43.8518,46.5244 42.5,45.5C 42.6236,44.8933 42.9569,44.56 43.5,44.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4b53"
        d="M 59.5,41.5 C 59.5,46.1667 59.5,50.8333 59.5,55.5C 59.1667,55.5 58.8333,55.5 58.5,55.5C 58.8168,51.2984 58.4834,47.2984 57.5,43.5C 57.5,42.1667 58.1667,41.5 59.5,41.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#394057"
        d="M -0.5,48.5 C -0.5,48.1667 -0.5,47.8333 -0.5,47.5C 10.1667,47.5 20.8333,47.5 31.5,47.5C 31.5,49.8333 31.5,52.1667 31.5,54.5C 31.5,54.8333 31.5,55.1667 31.5,55.5C 30.5318,53.3926 30.1984,51.0593 30.5,48.5C 20.1667,48.5 9.83333,48.5 -0.5,48.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#c1a858"
        d="M 37.5,37.5 C 35.9519,37.8212 35.2852,38.8212 35.5,40.5C 35.5,41.5 35.5,42.5 35.5,43.5C 35.5,47.1667 35.5,50.8333 35.5,54.5C 36.5025,56.0109 37.1692,57.6776 37.5,59.5C 36.391,59.4431 35.391,59.1098 34.5,58.5C 34.5,57.1667 34.5,55.8333 34.5,54.5C 34.6658,49.8215 34.4992,45.1548 34,40.5C 33.79,38.9419 33.29,37.6085 32.5,36.5C 31.0937,36.9731 30.427,37.9731 30.5,39.5C 29.5,39.5 28.5,39.5 27.5,39.5C 27.3505,38.448 27.5172,37.448 28,36.5C 29.7467,35.6699 31.4134,34.6699 33,33.5C 34.7153,34.6045 36.2153,35.9378 37.5,37.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#24428d"
        d="M -0.5,48.5 C 9.83333,48.5 20.1667,48.5 30.5,48.5C 30.1984,51.0593 30.5318,53.3926 31.5,55.5C 31.7036,57.6854 32.3702,59.6854 33.5,61.5C 33.9704,62.6935 34.9704,63.3601 36.5,63.5C 38.2073,63.2716 39.874,62.9383 41.5,62.5C 41.5,62.8333 41.5,63.1667 41.5,63.5C 42.0144,65.6984 43.0144,67.6984 44.5,69.5C 29.5148,70.4993 14.5148,70.8327 -0.5,70.5C -0.5,63.1667 -0.5,55.8333 -0.5,48.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#9aad94"
        d="M 41.5,43.5 C 42.4916,43.3284 43.1583,43.6618 43.5,44.5C 42.9569,44.56 42.6236,44.8933 42.5,45.5C 43.8518,46.5244 45.1851,47.5244 46.5,48.5C 46.5,49.1667 46.1667,49.5 45.5,49.5C 41.9319,49.2407 40.5985,47.2407 41.5,43.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#5c646a"
        d="M 53.5,41.5 C 54.5,41.8333 55.1667,42.5 55.5,43.5C 55.5,46.1667 55.5,48.8333 55.5,51.5C 54.1667,52.8333 52.8333,54.1667 51.5,55.5C 49.1137,54.08 46.447,53.08 43.5,52.5C 44.1925,51.4822 44.8592,50.4822 45.5,49.5C 46.1667,49.5 46.5,49.1667 46.5,48.5C 48.099,48.7678 49.4324,48.4345 50.5,47.5C 51.5,47.5 52.5,47.5 53.5,47.5C 53.5,45.5 53.5,43.5 53.5,41.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#393b68"
        d="M 35.5,43.5 C 36.1667,43.5 36.8333,43.5 37.5,43.5C 37.2367,47.6002 37.5701,51.6002 38.5,55.5C 37.391,55.4431 36.391,55.1098 35.5,54.5C 35.5,50.8333 35.5,47.1667 35.5,43.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#77325d"
        d="M 55.5,43.5 C 56.1667,43.5 56.8333,43.5 57.5,43.5C 57.8023,47.7905 57.1357,51.7905 55.5,55.5C 55.5,54.1667 55.5,52.8333 55.5,51.5C 55.5,48.8333 55.5,46.1667 55.5,43.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#773151"
        d="M 37.5,43.5 C 37.8333,43.5 38.1667,43.5 38.5,43.5C 39.4731,45.9479 39.8064,48.6146 39.5,51.5C 39.5,52.8333 39.5,54.1667 39.5,55.5C 39.1667,55.5 38.8333,55.5 38.5,55.5C 37.5701,51.6002 37.2367,47.6002 37.5,43.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#8a333d"
        d="M 55.5,51.5 C 55.5,52.8333 55.5,54.1667 55.5,55.5C 55.3284,56.4916 55.6618,57.1583 56.5,57.5C 55.7095,58.4013 54.7095,59.0679 53.5,59.5C 53.1193,58.0689 52.1193,57.2355 50.5,57C 51.056,56.6174 51.3893,56.1174 51.5,55.5C 52.8333,54.1667 54.1667,52.8333 55.5,51.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#cf8a3a"
        d="M 31.5,47.5 C 32.1314,49.9752 33.1314,52.3085 34.5,54.5C 34.5,55.8333 34.5,57.1667 34.5,58.5C 33.1429,57.4097 32.1429,56.0764 31.5,54.5C 31.5,52.1667 31.5,49.8333 31.5,47.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#a3967e"
        d="M 35.5,54.5 C 36.391,55.1098 37.391,55.4431 38.5,55.5C 38.8333,55.5 39.1667,55.5 39.5,55.5C 40.3382,55.8417 40.6716,56.5084 40.5,57.5C 40.5,58.1667 40.5,58.8333 40.5,59.5C 39.5,59.5 38.5,59.5 37.5,59.5C 37.1692,57.6776 36.5025,56.0109 35.5,54.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#8a3934"
        d="M 39.5,51.5 C 40.8999,52.0998 41.8999,53.0998 42.5,54.5C 43.9143,56.5983 43.2476,57.5983 40.5,57.5C 40.6716,56.5084 40.3382,55.8417 39.5,55.5C 39.5,54.1667 39.5,52.8333 39.5,51.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#433462"
        d="M 57.5,43.5 C 58.4834,47.2984 58.8168,51.2984 58.5,55.5C 57.6618,55.8417 57.3284,56.5084 57.5,57.5C 57.1667,57.5 56.8333,57.5 56.5,57.5C 55.6618,57.1583 55.3284,56.4916 55.5,55.5C 57.1357,51.7905 57.8023,47.7905 57.5,43.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#cea742"
        d="M 62.5,36.5 C 64.0481,36.8212 64.7148,37.8212 64.5,39.5C 64.5,41.8333 64.5,44.1667 64.5,46.5C 74.8333,46.5 85.1667,46.5 95.5,46.5C 95.5,46.8333 95.5,47.1667 95.5,47.5C 84.8333,47.5 74.1667,47.5 63.5,47.5C 63.5,48.8333 63.5,50.1667 63.5,51.5C 62.6981,52.9516 62.1981,54.6183 62,56.5C 60.4878,57.2567 59.1545,58.2567 58,59.5C 57.5357,58.9056 57.369,58.2389 57.5,57.5C 57.3284,56.5084 57.6618,55.8417 58.5,55.5C 58.8333,55.5 59.1667,55.5 59.5,55.5C 60.1667,55.5 60.5,55.1667 60.5,54.5C 62.2284,48.7616 62.8951,42.7616 62.5,36.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#795a3c"
        d="M 42.5,54.5 C 44.3664,55.0138 46.1997,55.6805 48,56.5C 49.1385,56.0109 50.3052,55.6775 51.5,55.5C 51.3893,56.1174 51.056,56.6174 50.5,57C 52.1193,57.2355 53.1193,58.0689 53.5,59.5C 53.5,60.1667 53.5,60.8333 53.5,61.5C 53.5,62.1667 53.1667,62.5 52.5,62.5C 51.0119,59.8136 50.3453,60.1469 50.5,63.5C 50.1667,63.5 49.8333,63.5 49.5,63.5C 50.1614,59.4828 48.8281,58.1495 45.5,59.5C 43.8333,59.5 42.1667,59.5 40.5,59.5C 40.5,58.8333 40.5,58.1667 40.5,57.5C 43.2476,57.5983 43.9143,56.5983 42.5,54.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#c46f43"
        d="M 63.5,51.5 C 63.5987,54.2372 63.2653,56.9039 62.5,59.5C 59.8375,61.2681 56.8375,61.9347 53.5,61.5C 53.5,60.8333 53.5,60.1667 53.5,59.5C 54.7095,59.0679 55.7095,58.4013 56.5,57.5C 56.8333,57.5 57.1667,57.5 57.5,57.5C 57.369,58.2389 57.5357,58.9056 58,59.5C 59.1545,58.2567 60.4878,57.2567 62,56.5C 62.1981,54.6183 62.6981,52.9516 63.5,51.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#a56e3f"
        d="M 31.5,54.5 C 32.1429,56.0764 33.1429,57.4097 34.5,58.5C 35.391,59.1098 36.391,59.4431 37.5,59.5C 38.5,59.5 39.5,59.5 40.5,59.5C 42.1667,59.5 43.8333,59.5 45.5,59.5C 45.5674,60.9587 45.2341,62.2921 44.5,63.5C 43.5,63.5 42.5,63.5 41.5,63.5C 41.5,63.1667 41.5,62.8333 41.5,62.5C 39.0521,61.5269 36.3854,61.1936 33.5,61.5C 32.3702,59.6854 31.7036,57.6854 31.5,55.5C 31.5,55.1667 31.5,54.8333 31.5,54.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#493256"
        d="M 33.5,61.5 C 36.3854,61.1936 39.0521,61.5269 41.5,62.5C 39.874,62.9383 38.2073,63.2716 36.5,63.5C 34.9704,63.3601 33.9704,62.6935 33.5,61.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#d4bf86"
        d="M 52.5,62.5 C 52.1583,63.3382 51.4916,63.6716 50.5,63.5C 50.3453,60.1469 51.0119,59.8136 52.5,62.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#782f48"
        d="M 49.5,63.5 C 49.5,64.8333 49.5,66.1667 49.5,67.5C 48.1667,67.5 46.8333,67.5 45.5,67.5C 45.7678,65.901 45.4345,64.5676 44.5,63.5C 45.2341,62.2921 45.5674,60.9587 45.5,59.5C 48.8281,58.1495 50.1614,59.4828 49.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#473d5e"
        d="M 62.5,59.5 C 62.5858,60.4959 62.2524,61.3292 61.5,62C 56.9088,62.6727 52.9088,64.5061 49.5,67.5C 49.5,66.1667 49.5,64.8333 49.5,63.5C 49.8333,63.5 50.1667,63.5 50.5,63.5C 51.4916,63.6716 52.1583,63.3382 52.5,62.5C 53.1667,62.5 53.5,62.1667 53.5,61.5C 56.8375,61.9347 59.8375,61.2681 62.5,59.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#27428a"
        d="M 95.5,47.5 C 95.5,55.1667 95.5,62.8333 95.5,70.5C 80.1667,70.5 64.8333,70.5 49.5,70.5C 49.5,69.5 49.5,68.5 49.5,67.5C 52.9088,64.5061 56.9088,62.6727 61.5,62C 62.2524,61.3292 62.5858,60.4959 62.5,59.5C 63.2653,56.9039 63.5987,54.2372 63.5,51.5C 63.5,50.1667 63.5,48.8333 63.5,47.5C 74.1667,47.5 84.8333,47.5 95.5,47.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#413775"
        d="M 41.5,63.5 C 42.5,63.5 43.5,63.5 44.5,63.5C 45.4345,64.5676 45.7678,65.901 45.5,67.5C 46.8333,67.5 48.1667,67.5 49.5,67.5C 49.5,68.5 49.5,69.5 49.5,70.5C 64.8333,70.5 80.1667,70.5 95.5,70.5C 95.5,71.1667 95.5,71.8333 95.5,72.5C 63.5,72.5 31.5,72.5 -0.5,72.5C -0.5,71.8333 -0.5,71.1667 -0.5,70.5C 14.5148,70.8327 29.5148,70.4993 44.5,69.5C 43.0144,67.6984 42.0144,65.6984 41.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#9d2948"
        d="M -0.5,72.5 C 31.5,72.5 63.5,72.5 95.5,72.5C 95.5,73.1667 95.5,73.8333 95.5,74.5C 63.5,74.5 31.5,74.5 -0.5,74.5C -0.5,73.8333 -0.5,73.1667 -0.5,72.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#ba1d37"
        d="M -0.5,74.5 C 31.5,74.5 63.5,74.5 95.5,74.5C 95.5,75.5 95.5,76.5 95.5,77.5C 63.5,77.5 31.5,77.5 -0.5,77.5C -0.5,76.5 -0.5,75.5 -0.5,74.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b92033"
        d="M -0.5,77.5 C 31.5,77.5 63.5,77.5 95.5,77.5C 95.5,83.5 95.5,89.5 95.5,95.5C 63.5,95.5 31.5,95.5 -0.5,95.5C -0.5,89.5 -0.5,83.5 -0.5,77.5 Z"
      />
    </g>
  </svg>
)
export default Ecuador
