import * as React from 'react'
const Mls = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={153}
    viewBox="0 0 150 153"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,153 L0,153 Z "
      fill="#FEFEFE"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L104,0 L104,47 L101,63 L96,75 L89,86 L80,96 L67,105 L56,110 L49,110 L39,106 L28,99 L19,91 L15,92 L11,97 L4,102 L2,99 L14,87 L12,82 L5,68 L1,55 L0,47 Z "
      fill="#D72129"
      transform="translate(23,23)"
    />
    <path
      d="M0,0 L2,0 L2,41 L-2,58 L-9,71 L-16,80 L-26,89 L-38,96 L-44,98 L-51,97 L-63,91 L-73,83 L-77,79 L-77,77 L-71,72 L-66,67 L-62,62 L-57,57 L-52,52 L-47,48 L-43,43 L-41,43 L-40,40 L-38,40 L-36,36 Z "
      fill="#FBFCFD"
      transform="translate(121,31)"
    />
    <path
      d="M0,0 L104,0 L104,47 L101,63 L96,75 L89,86 L80,96 L67,105 L56,110 L49,110 L39,106 L28,99 L19,91 L15,92 L11,97 L4,102 L2,99 L14,87 L12,82 L5,68 L1,55 L0,47 Z M5,5 L5,55 L7,62 L9,62 L10,70 L14,79 L16,79 L17,82 L21,81 L21,79 L23,79 L26,74 L29,73 L29,71 L31,71 L34,66 L37,65 L41,61 L42,58 L49,52 L50,50 L53,49 L56,44 L61,40 L68,32 L81,19 L81,17 L86,15 L92,8 L92,6 L89,5 Z M98,8 L60,46 L60,48 L57,49 L57,51 L53,53 L48,58 L44,63 L39,68 L34,72 L29,77 L23,84 L21,85 L23,89 L32,97 L44,104 L49,106 L57,105 L69,99 L79,91 L87,82 L93,72 L97,62 L100,49 L100,8 Z "
      fill="#232A51"
      transform="translate(23,23)"
    />
    <path
      d="M0,0 L29,0 L32,1 L30,5 L25,11 L21,12 L19,16 L2,33 L-3,38 L-6,41 L-7,44 L-10,45 L-16,52 L-17,52 L-17,46 L-12,46 L-13,44 L-16,42 L-19,39 L-18,35 L-21,34 L-21,31 L-23,31 L-23,33 L-30,35 L-34,35 L-35,33 L-40,33 L-40,31 L-42,31 L-42,34 L-46,33 L-48,32 L-48,29 L-51,28 L-51,43 L-54,43 L-54,1 L-36,1 L-36,3 L-40,3 L-37,4 L-38,9 L-40,14 L-42,14 L-46,5 L-51,4 L-51,25 L-46,25 L-46,16 L-43,20 L-43,22 L-39,22 L-37,15 L-36,15 L-36,25 L-31,25 L-31,4 L-30,4 L-29,24 L-15,25 L-15,20 L-24,20 L-24,4 L-19,3 L-29,3 L-28,1 L-11,1 L-11,3 L-15,4 L-15,7 L-13,7 L-11,14 L-3,17 L-3,20 L-12,20 L-14,23 L-10,24 L-2,24 L1,22 L1,15 L-1,13 L-8,11 L-7,8 L0,8 L0,4 L-8,3 L-6,1 Z "
      fill="#AC1E26"
      transform="translate(83,28)"
    />
    <path
      d="M0,0 L55,0 L55,1 L49,2 L56,3 L56,8 L52,9 L48,9 L48,11 L55,13 L57,15 L57,22 L53,25 L45,25 L41,23 L43,19 L52,20 L52,17 L45,16 L42,13 L42,7 L40,7 L39,3 L44,3 L44,1 L31,2 L38,2 L34,4 L32,4 L31,20 L40,20 L40,25 L26,25 L24,25 L19,25 L18,17 L16,22 L12,22 L10,18 L9,25 L4,25 L4,4 L9,4 L13,12 L13,14 L15,14 L18,4 L15,5 L15,3 L19,3 L19,1 L1,1 L2,48 L3,55 L5,56 L5,60 L7,60 L8,69 L6,68 L4,62 L4,57 L1,56 L0,50 Z "
      fill="#B72D36"
      transform="translate(28,28)"
    />
    <path
      d="M0,0 L2,1 L3,14 L4,5 L5,5 L6,55 L7,61 L9,62 L12,73 L14,78 L17,81 L21,81 L21,79 L23,79 L25,74 L29,73 L29,71 L31,71 L33,66 L37,65 L39,61 L41,61 L42,57 L48,52 L50,49 L53,49 L54,45 L60,40 L67,32 L80,19 L83,16 L85,16 L86,21 L61,46 L57,51 L52,56 L47,60 L42,65 L38,70 L33,75 L28,80 L24,82 L23,85 L21,85 L23,94 L19,91 L15,92 L11,97 L4,102 L2,99 L14,87 L12,82 L5,68 L1,55 L0,47 Z "
      fill="#35365A"
      transform="translate(23,23)"
    />
    <path
      d="M0,0 L2,0 L3,5 L-22,30 L-26,35 L-31,40 L-36,44 L-41,49 L-45,54 L-50,59 L-55,64 L-59,66 L-60,69 L-63,66 L-62,63 L-60,63 L-58,58 L-54,57 L-54,55 L-52,55 L-50,50 L-46,49 L-44,45 L-42,45 L-41,41 L-35,36 L-33,33 L-30,33 L-29,29 L-23,24 L-16,16 L-3,3 Z "
      fill="#3D7DAC"
      transform="translate(106,39)"
    />
    <path
      d="M0,0 L1,3 L-1,3 L-1,5 L2,5 L2,8 L-3,9 L-4,12 L-6,13 L-5,16 L-6,18 L-1,17 L1,18 L0,14 L2,14 L2,17 L5,18 L5,24 L0,31 L-2,31 L-2,33 L-5,34 L-8,39 L-10,39 L-10,41 L-14,42 L-15,39 L-18,38 L-17,34 L-13,38 L-13,40 L-11,40 L-11,37 L-14,37 L-14,33 L-10,32 L-11,19 L-12,19 L-12,14 L-10,11 L-12,11 L-13,7 L-13,9 L-18,7 L-17,4 L-16,6 L-11,4 L-7,4 L-7,1 L-5,1 L-5,3 Z "
      fill="#C52026"
      transform="translate(54,63)"
    />
    <path
      d="M0,0 L7,1 L8,4 L14,2 L19,2 L19,0 L21,0 L21,3 L25,4 L24,8 L24,10 L27,9 L27,11 L30,13 L30,15 L25,15 L25,21 L23,25 L19,29 L17,28 L17,22 L15,21 L15,18 L13,18 L14,22 L7,22 L6,16 L9,16 L10,12 L15,12 L15,9 L12,9 L12,7 L14,7 L13,5 L8,7 L8,5 L6,5 L6,8 L4,8 L3,10 L-2,9 L-4,3 L0,3 Z "
      fill="#BA212A"
      transform="translate(41,59)"
    />
    <path
      d="M0,0 L5,0 L9,8 L9,10 L11,10 L14,1 L15,0 L20,0 L20,21 L15,21 L14,13 L12,18 L8,18 L6,14 L5,21 L0,21 Z "
      fill="#FDE0DE"
      transform="translate(32,32)"
    />
    <path
      d="M0,0 L102,0 L102,1 L85,2 L4,3 L3,49 L2,49 L1,14 Z "
      fill="#161848"
      transform="translate(24,23)"
    />
    <path
      d="M0,0 L9,0 L11,1 L10,5 L3,5 L3,7 L10,9 L12,11 L12,18 L8,21 L0,21 L-4,19 L-2,15 L7,16 L7,13 L0,12 L-3,9 L-3,3 Z "
      fill="#F9E2E1"
      transform="translate(73,32)"
    />
    <path
      d="M0,0 L102,1 L105,3 L0,3 Z "
      fill="#E3E0F8"
      transform="translate(22,20)"
    />
    <path
      d="M0,0 L2,0 L2,41 L-2,58 L-6,66 L-8,65 L-9,61 L-6,61 L-7,57 L-13,57 L-13,49 L-5,49 L-5,45 L-2,44 L0,39 Z "
      fill="#F6F9FA"
      transform="translate(121,31)"
    />
    <path
      d="M0,0 L2,0 L3,5 L-17,25 L-20,22 L-22,26 L-26,27 L-20,20 L-3,3 Z "
      fill="#485883"
      transform="translate(106,39)"
    />
    <path
      d="M0,0 L5,0 L5,16 L14,16 L14,21 L0,21 Z "
      fill="#FCDFE0"
      transform="translate(54,32)"
    />
    <path
      d="M0,0 L4,1 L16,11 L18,13 L16,13 L15,15 L13,14 L13,12 L7,10 L7,7 L5,6 L4,8 L0,8 L-2,6 L-4,9 L-5,11 L-7,11 L-8,13 L-13,13 L-12,11 L-5,6 Z "
      fill="#EEF2F6"
      transform="translate(39,113)"
    />
    <path
      d="M0,0 L2,1 L0,3 L-1,9 L-6,14 L-11,19 L-15,21 L-16,24 L-19,21 L-18,18 L-16,18 L-14,13 L-10,12 L-10,10 L-8,10 L-6,5 L-2,4 Z "
      fill="#2F547B"
      transform="translate(62,84)"
    />
    <path
      d="M0,0 L90,0 L88,5 L82,10 L84,6 L87,2 L0,1 Z "
      fill="#6F334B"
      transform="translate(28,27)"
    />
    <path
      d="M0,0 L2,0 L2,41 L-1,54 L-3,50 L-6,50 L-5,45 L-2,44 L0,39 Z "
      fill="#C4CADF"
      transform="translate(121,31)"
    />
    <path
      d="M0,0 L4,0 L4,20 L1,20 L1,9 L-2,9 L-2,3 Z "
      fill="#FEF3F5"
      transform="translate(47,32)"
    />
    <path
      d="M0,0 L4,5 L5,9 L-7,21 L-9,20 L-9,18 L-6,18 L-5,14 L-1,9 Z "
      fill="#E2E6EE"
      transform="translate(33,101)"
    />
    <path
      d="M0,0 L2,0 L1,4 L-4,9 L-8,14 L-14,19 L-14,14 Z "
      fill="#4086B7"
      transform="translate(75,73)"
    />
    <path
      d="M0,0 L1,0 L1,21 L-4,21 L-5,13 L-7,18 L-11,17 L-8,17 L-8,12 L-10,12 L-10,10 L-8,10 L-7,5 L-6,9 L-3,9 L-3,20 L0,20 Z "
      fill="#F1BBB7"
      transform="translate(51,32)"
    />
    <path
      d="M0,0 L2,4 L-4,11 L-11,16 L-13,13 Z "
      fill="#1B1B49"
      transform="translate(38,109)"
    />
    <path
      d="M0,0 L1,3 L-1,8 L-4,8 L-4,10 L-9,13 L-12,14 L-12,18 L-18,19 L-17,17 L-15,17 L-13,13 Z "
      fill="#D8E9F2"
      transform="translate(98,54)"
    />
    <path
      d="M0,0 L2,0 L3,5 L-4,11 L-4,8 L-10,13 L-12,12 L-3,3 Z "
      fill="#43315C"
      transform="translate(106,39)"
    />
    <path
      d="M0,0 L6,0 L8,1 L6,6 L4,6 L4,8 L0,9 L-1,6 L-4,5 L-3,1 L1,5 L1,7 L3,7 L3,4 L0,4 Z "
      fill="#BB283E"
      transform="translate(40,96)"
    />
    <path
      d="M0,0 L2,0 L2,3 L6,4 L4,10 L-2,9 L-4,3 L0,3 Z "
      fill="#FAC9C4"
      transform="translate(41,59)"
    />
    <path
      d="M0,0 L2,2 L6,12 L5,14 L3,13 L2,16 L0,16 Z "
      fill="#E7ECF1"
      transform="translate(26,86)"
    />
    <path
      d="M0,0 L2,4 L5,5 L4,10 L-3,10 L-4,4 L-1,4 Z "
      fill="#F8CBCB"
      transform="translate(51,71)"
    />
    <path
      d="M0,0 L2,0 L1,4 L-1,7 L-5,7 L-5,9 L-13,10 L-8,6 Z "
      fill="#EAEBF4"
      transform="translate(101,119)"
    />
    <path
      d="M0,0 L2,0 L2,3 L6,4 L4,9 L-2,9 L-4,3 L0,3 Z "
      fill="#F9D4CD"
      transform="translate(60,59)"
    />
    <path
      d="M0,0 L1,3 L-1,6 L-1,9 L-2,7 L-10,14 L-15,18 L-14,14 L-8,9 L-6,6 L-3,6 L-2,2 Z "
      fill="#696B94"
      transform="translate(79,66)"
    />
    <path
      d="M0,0 L13,3 L13,5 L16,6 L16,10 L12,10 L11,6 L7,4 L0,2 Z "
      fill="#FCFEFF"
      transform="translate(64,130)"
    />
    <path
      d="M0,0 L2,1 L0,3 L-1,9 L-6,14 L-9,13 L-9,11 L-7,11 L-5,6 L-4,4 L-2,4 Z "
      fill="#4C6D9D"
      transform="translate(62,84)"
    />
    <path
      d="M0,0 L2,1 L3,8 L5,9 L5,13 L7,13 L8,22 L6,21 L4,15 L4,10 L1,9 Z "
      fill="#A03245"
      transform="translate(28,75)"
    />
    <path
      d="M0,0 L0,3 L-2,7 L-3,9 L-5,9 L-6,11 L-11,11 L-10,9 L-3,4 Z "
      fill="#D8DBE4"
      transform="translate(37,115)"
    />
    <path
      d="M0,0 L1,2 L-2,7 L-4,7 L-2,11 L-5,11 L-7,10 L-9,13 L-11,12 L-8,8 L-5,8 L-5,6 L-3,6 L-1,1 Z "
      fill="#64577F"
      transform="translate(57,88)"
    />
    <path
      d="M0,0 L2,4 L1,14 L-2,16 Z "
      fill="#D4D9E6"
      transform="translate(126,70)"
    />
    <path
      d="M0,0 L4,2 L-3,8 L-3,6 L-6,7 L-6,5 L-1,5 Z "
      fill="#E2EBF1"
      transform="translate(101,109)"
    />
    <path
      d="M0,0 L6,0 L4,4 L2,4 L2,6 L0,6 Z "
      fill="#B52A3E"
      transform="translate(50,90)"
    />
    <path
      d="M0,0 L0,3 L-2,8 L-7,8 L-1,1 Z "
      fill="#DCDDEA"
      transform="translate(113,39)"
    />
    <path
      d="M0,0 L2,0 L1,5 L-2,7 L-2,5 L-4,4 Z "
      fill="#274672"
      transform="translate(100,47)"
    />
    <path
      d="M0,0 L5,4 L5,6 L8,7 L4,8 L3,5 L0,4 Z "
      fill="#8B344E"
      transform="translate(36,97)"
    />
    <path
      d="M0,0 L4,1 L3,5 L-4,4 L0,3 Z "
      fill="#FAD9E2"
      transform="translate(80,32)"
    />
    <path
      d="M0,0 L6,1 L3,4 L1,4 L1,2 L-5,3 L-3,1 Z "
      fill="#E2E6EF"
      transform="translate(86,129)"
    />
    <path
      d="M0,0 L5,1 L6,7 L0,3 Z "
      fill="#3A4872"
      transform="translate(40,110)"
    />
    <path
      d="M0,0 L4,2 L4,10 L1,6 Z "
      fill="#1B2751"
      transform="translate(27,85)"
    />
    <path
      d="M0,0 L6,0 L6,2 L2,6 L0,5 Z "
      fill="#AB2C48"
      transform="translate(58,82)"
    />
    <path
      d="M0,0 L13,3 L13,4 L1,3 Z "
      fill="#D2D5E3"
      transform="translate(64,130)"
    />
    <path
      d="M0,0 L3,2 L0,6 L-3,5 L-3,3 L-1,3 Z "
      fill="#2D6890"
      transform="translate(56,92)"
    />
  </svg>
)
export default Mls
