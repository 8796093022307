import * as React from 'react'
const Malta = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#FEFEFE"
    />
    <path d="m75 6 7 4 8 8 6 10 4 11v22l-4 11-6 10-8 8-7 4Z" fill="#CF162C" />
    <path
      d="m20 9 4 4 8 1v7l-1 1-7 1-1 1-1 7-1 1h-7l-1-8-4-4 7-8Z"
      fill="#B4A4A3"
    />
    <path d="m16 21 3 1 3 2v7l-1 1h-7l1-9Z" fill="#CACBCB" />
    <path d="M23 15h7v5h-7Z" fill="#CBCDCD" />
    <path
      d="M20 24h2v7l-1 1h-7l1-2h5Zm11-10h1v7l-1 1h-7v-2h6v-5Z"
      fill="#DBB5B6"
    />
    <path d="m17 14 4 4v2l-5-1-1-4Z" fill="#7C726C" />
    <path d="m11 17 4 1-1 4-5-1Z" fill="#C3BCBC" />
  </svg>
)
export default Malta
