import * as React from 'react'
const CookieIcon = (props) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 11h-2m2 0c0 5.523-4.477 10-10 10m10-10c0-5.523-4.477-10-10-10m7.071 17.071-1.414-1.414M3 11H1m0 0c0 5.523 4.477 10 10 10M1 11C1 5.477 5.477 1 11 1M5.343 5.343 3.93 3.93M11 3V1m5.657 4.343L18.07 3.93M11 21v-2m-7.071-.929 1.414-1.414M11 7l4 4-4 4-4-4z"
      stroke="green"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default CookieIcon
