import * as React from 'react'
const Olympics = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 200 200"
    {...props}
  >
    <path
      d="M0,0 L70,0 L74,3 L75,8 L72,13 L67,14 L64,23 L58,31 L53,36 L47,99 L23,99 L17,36 L12,31 L6,23 L3,14 L-2,13 L-5,8 L-3,2 Z "
      fill="#FEFEFE"
      transform="translate(65,101)"
    />
    <path
      d="M0,0 L7,1 L17,10 L25,25 L29,40 L29,60 L25,71 L17,81 L5,87 L-9,87 L-17,83 L-25,74 L-27,69 L-27,53 L-23,41 L-17,33 L-12,28 L-5,24 L-4,24 L-4,41 L0,37 L4,29 L5,24 L5,16 L2,6 L0,3 Z "
      fill="#FEFEFE"
      transform="translate(99,4)"
    />
    <path
      d="M0,0 L5,1 L6,2 L6,12 L3,20 L1,22 L-4,22 L-6,20 L-8,44 L-13,64 L-21,80 L-29,93 L-30,95 L-18,93 L-8,93 L-7,94 L-7,99 L-16,107 L-30,114 L-38,115 L-41,113 L-43,109 L-49,116 L-45,118 L-45,128 L-48,132 L-56,132 L-62,128 L-63,123 L-60,119 L-62,115 L-61,109 L-49,99 L-55,95 L-56,90 L-50,72 L-47,68 L-43,69 L-39,77 L-37,87 L-28,75 L-35,73 L-36,71 L-36,52 L-34,48 L-30,49 L-24,56 L-21,64 L-16,51 L-16,49 L-21,49 L-24,45 L-27,34 L-27,28 L-24,26 L-18,30 L-13,36 L-11,27 L-11,22 L-17,22 L-21,17 L-24,11 L-24,6 L-22,4 L-16,5 L-10,9 L-9,6 L-6,7 Z M-15,47 Z M-39,88 Z M-48,97 Z M-42,107 Z "
      fill="#FDFDFD"
      transform="translate(189,68)"
    />
    <path
      d="M0,0 L5,1 L10,7 L13,6 L14,9 L20,5 L26,4 L28,6 L27,13 L22,21 L21,22 L15,22 L16,34 L17,36 L24,28 L29,26 L31,28 L30,38 L27,47 L25,49 L20,49 L23,59 L25,64 L29,55 L35,48 L39,49 L40,52 L40,71 L37,74 L32,75 L41,87 L44,74 L48,68 L52,69 L56,77 L60,90 L58,96 L53,99 L65,109 L66,115 L64,119 L67,123 L66,128 L60,132 L52,132 L49,128 L49,118 L53,116 L47,109 L44,114 L37,115 L25,110 L15,103 L11,99 L11,94 L12,93 L22,93 L35,95 L29,87 L20,71 L16,61 L12,44 L10,20 L8,22 L3,22 L0,19 L-2,12 L-2,2 Z M18,47 Z M42,88 Z M45,107 Z "
      fill="#FDFDFD"
      transform="translate(7,68)"
    />
    <path
      d="M0,0 L7,0 L6,7 L-3,18 L-11,24 L-15,25 L-19,22 L-19,14 L-15,8 L-7,3 Z "
      fill="#FDFDFD"
      transform="translate(190,129)"
    />
    <path
      d="M0,0 L7,0 L21,7 L25,12 L26,14 L26,22 L22,25 L16,23 L7,15 L1,7 Z "
      fill="#FDFDFD"
      transform="translate(3,129)"
    />
    <path
      d="M0,0 L4,1 L3,10 L-3,21 L-7,25 L-12,25 L-15,20 L-13,11 L-2,1 Z "
      fill="#FCFCFC"
      transform="translate(196,96)"
    />
    <path
      d="M0,0 L5,1 L16,11 L18,20 L15,25 L10,25 L4,18 L-1,7 L-1,1 Z "
      fill="#FCFCFC"
      transform="translate(1,96)"
    />
    <path
      d="M0,0 L4,0 L5,8 L2,20 L-1,23 L-7,23 L-9,20 L-9,13 L-3,3 Z "
      fill="#FCFCFC"
      transform="translate(26,46)"
    />
    <path
      d="M0,0 L5,1 L11,9 L13,13 L13,20 L11,23 L5,23 L1,18 L-1,8 Z "
      fill="#FDFDFD"
      transform="translate(170,46)"
    />
    <path
      d="M0,0 L4,0 L5,7 L8,12 L7,19 L5,20 L-2,20 L-6,16 L-6,8 L-2,2 Z "
      fill="#FDFDFD"
      transform="translate(90,0)"
    />
    <path
      d="M0,0 L5,1 L12,7 L12,12 L6,17 L-1,16 L-3,13 L-3,3 Z "
      fill="#FCFCFC"
      transform="translate(119,160)"
    />
    <path
      d="M0,0 L4,1 L6,3 L6,13 L2,17 L-5,16 L-9,12 L-9,7 L-2,1 Z "
      fill="#FCFCFC"
      transform="translate(78,160)"
    />
  </svg>
)
export default Olympics
