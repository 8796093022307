import React from 'react'
const SuperLig = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={130}
    viewBox="0 0 150 130"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,130 L0,130 Z "
      fill="#FDFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L2,0 L2,11 L-1,26 L-7,38 L-15,46 L-23,50 L-32,51 L-33,50 L-33,45 L-21,43 L-11,37 L-5,27 L-2,15 L-2,9 L-13,13 L-29,13 L-37,10 L-41,8 L-37,23 L-34,29 L-34,35 L-37,31 L-40,26 L-44,9 L-44,1 L-39,3 L-30,8 L-25,9 L-17,9 L-8,6 Z "
      fill="#999999"
      transform="translate(97,21)"
    />
    <path
      d="M0,0 L8,0 L9,2 L6,2 L6,16 L14,15 L13,18 L-5,19 L-11,19 L-11,17 L-9,17 L-9,9 L-12,12 L-19,12 L-15,11 L-16,9 L-20,7 L-16,6 L-14,7 L-14,9 L-8,6 L0,6 L1,2 L-1,1 Z "
      fill="#EDEDED"
      transform="translate(91,82)"
    />
    <path
      d="M0,0 L4,1 L8,3 L6,3 L5,8 L4,9 L-1,9 L0,11 L8,12 L7,17 L4,19 L-3,18 L-4,14 L-10,13 L-11,1 L-7,1 L-7,11 L-5,11 L-4,12 L-4,4 Z "
      fill="#4E4E4E"
      transform="translate(118,88)"
    />
    <path
      d="M0,0 L2,0 L2,2 L4,2 L4,4 L7,4 L7,9 L8,11 L4,11 L3,15 L0,16 L-1,12 L-5,11 L-5,2 Z "
      fill="#FCEDED"
      transform="translate(81,7)"
    />
    <path
      d="M0,0 L8,0 L9,6 L9,15 L7,18 L-2,18 L-1,16 L6,17 L6,12 L2,10 L-2,7 L-2,2 Z "
      fill="#EAEAEA"
      transform="translate(27,82)"
    />
    <path
      d="M0,0 L9,0 L9,2 L7,2 L7,4 L9,4 L9,16 L0,16 L-1,15 L-1,5 L1,5 L2,14 L6,14 L5,5 L6,3 L3,3 L2,5 L2,3 L0,3 Z "
      fill="#757575"
      transform="translate(41,84)"
    />
    <path
      d="M0,0 L3,0 L4,9 L8,9 L8,2 L6,0 L11,1 L12,2 L11,9 L7,11 L6,15 L4,15 L4,17 L1,17 Z "
      fill="#C0C0C0"
      transform="translate(54,89)"
    />
    <path
      d="M0,0 L4,0 L4,10 L6,10 L6,12 L10,11 L17,12 L16,16 L10,16 L5,13 L1,12 Z "
      fill="#E6E6E6"
      transform="translate(107,89)"
    />
    <path
      d="M0,0 L5,1 L5,3 L11,0 L17,0 L17,3 L14,4 L13,12 L10,12 L10,3 L7,6 L0,6 L4,5 L3,3 L-1,1 Z "
      fill="#484848"
      transform="translate(72,88)"
    />
    <path
      d="M0,0 L3,0 L3,16 L10,15 L12,14 L11,17 L-2,17 L0,16 Z "
      fill="#383838"
      transform="translate(93,83)"
    />
    <path
      d="M0,0 L6,2 L7,3 L7,10 L2,11 L1,10 Z "
      fill="#DBDBDB"
      transform="translate(56,88)"
    />
    <path
      d="M0,0 L4,1 L8,3 L6,3 L5,8 L0,9 L-1,7 L-1,1 Z "
      fill="#A5A5A5"
      transform="translate(118,88)"
    />
    <path
      d="M0,0 L2,1 L2,5 L9,10 L9,16 L8,17 L-1,17 L0,15 L7,16 L7,11 L3,9 L-1,6 L-1,1 Z "
      fill="#2E2E2E"
      transform="translate(26,83)"
    />
    <path
      d="M0,0 L7,1 L7,6 L8,8 L3,10 L1,11 L1,7 L-2,6 Z "
      fill="#CA2240"
      transform="translate(81,10)"
    />
    <path
      d="M0,0 L1,2 L0,6 L8,5 L8,9 L7,13 L2,11 L-1,8 L-1,1 Z "
      fill="#E5E5E5"
      transform="translate(70,90)"
    />
    <path
      d="M0,0 L3,0 L4,9 L6,11 L3,11 L4,17 L1,17 Z "
      fill="#282828"
      transform="translate(54,89)"
    />
    <path
      d="M0,0 L4,0 L4,11 L1,11 Z "
      fill="#515151"
      transform="translate(107,89)"
    />
    <path
      d="M0,0 L5,1 L5,3 L8,1 L11,2 L7,6 L0,6 L4,5 L3,3 L-1,1 Z "
      fill="#9C9C9C"
      transform="translate(72,88)"
    />
    <path
      d="M0,0 L1,0 L2,7 L-1,10 L-2,10 L-2,2 Z "
      fill="#797979"
      transform="translate(116,90)"
    />
    <path
      d="M0,0 L2,0 L3,9 L4,11 L0,10 Z "
      fill="#161616"
      transform="translate(40,89)"
    />
    <path
      d="M0,0 L4,2 L5,5 L7,4 L5,8 L3,8 L0,3 Z "
      fill="#F7DADC"
      transform="translate(78,15)"
    />
    <path
      d="M0,0 L1,2 L2,7 L5,10 L0,9 L-1,8 L-1,1 Z "
      fill="#171717"
      transform="translate(70,90)"
    />
    <path
      d="M0,0 L5,1 L6,2 L6,7 L4,8 L2,2 Z "
      fill="#252525"
      transform="translate(60,89)"
    />
    <path
      d="M0,0 L5,1 L5,3 L1,4 L-1,6 Z "
      fill="#D4D4D4"
      transform="translate(71,90)"
    />
    <path
      d="M0,0 L4,1 L4,6 L2,6 L2,4 L0,4 Z "
      fill="#FDFDFD"
      transform="translate(59,92)"
    />
    <path
      d="M0,0 L6,0 L6,3 L2,4 Z "
      fill="#989898"
      transform="translate(83,88)"
    />
    <path
      d="M0,0 L8,0 L8,5 L4,2 L0,1 Z "
      fill="#9C9C9C"
      transform="translate(27,82)"
    />
  </svg>
)
export default SuperLig
