import React from 'react'
const EuropeanChampionship = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    viewBox="0 0 150 150"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,150 L0,150 Z "
      fill="#FDFDFE"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L4,0 L4,4 L2,4 L3,9 L7,6 L15,6 L21,9 L21,4 L20,0 L25,1 L33,7 L37,13 L39,19 L38,24 L28,28 L27,32 L31,31 L40,31 L40,44 L38,50 L34,49 L23,42 L25,48 L22,49 L24,55 L28,64 L23,67 L20,67 L19,69 L5,69 L-3,65 L-10,58 L-15,47 L-15,44 L-3,39 L-3,38 L-7,39 L-7,38 L-16,37 L-16,20 L-11,10 L-5,3 Z "
      fill="#CFE2EB"
      transform="translate(63,16)"
    />
    <path
      d="M0,0 L4,0 L4,4 L2,4 L3,9 L7,6 L15,6 L21,9 L21,4 L20,0 L25,1 L33,7 L37,13 L39,19 L38,24 L28,28 L27,32 L31,31 L40,31 L40,44 L38,50 L34,49 L23,42 L25,48 L22,49 L24,55 L28,64 L25,66 L21,65 L18,59 L18,52 L22,41 L24,31 L23,23 L22,20 L20,20 L20,18 L15,18 L15,13 L14,15 L10,15 L10,13 L8,13 L9,18 L3,18 L3,20 L1,20 L1,16 L-1,16 L-1,24 L-5,22 L-13,16 L-12,11 L-5,3 Z "
      fill="#B9CFCE"
      transform="translate(63,16)"
    />
    <path
      d="M0,0 L7,2 L12,2 L14,1 L20,27 L21,29 L21,35 L18,42 L12,41 L5,34 L0,23 L0,20 L12,15 L12,14 L8,15 L8,14 L-1,13 L-1,1 Z "
      fill="#B2CAD8"
      transform="translate(48,40)"
    />
    <path
      d="M0,0 L14,0 L22,4 L26,7 L26,9 L24,9 L24,7 L22,7 L19,18 L15,16 L11,14 L1,14 L-4,17 L-6,16 L-5,14 L-9,13 L-12,11 L-12,9 L-14,9 L-14,7 L-7,2 L-1,1 L3,3 L1,4 L1,6 L4,7 L1,7 L1,9 L3,9 L3,11 L8,10 L9,4 L12,2 L0,1 Z "
      fill="#ACB5F0"
      transform="translate(69,90)"
    />
    <path
      d="M0,0 L1,0 L1,5 L5,6 L5,8 L7,8 L10,14 L9,24 L5,38 L2,47 L1,47 L1,38 L3,30 L2,23 L0,24 L-6,24 L-8,19 L6,19 L4,8 L-1,7 L-1,11 L-5,11 L-6,8 L-10,10 L-12,18 L-15,18 L-15,12 L-12,6 L-7,5 L-7,1 L-5,1 L-5,3 L-1,3 Z "
      fill="#DFEDF2"
      transform="translate(78,28)"
    />
    <path
      d="M0,0 L5,0 L8,2 L10,0 L15,0 L17,2 L16,7 L12,12 L17,12 L17,13 L0,13 L-2,11 L-2,2 Z "
      fill="#8E98EC"
      transform="translate(96,111)"
    />
    <path
      d="M0,0 L8,1 L12,12 L15,18 L12,18 L11,20 L-2,20 L0,11 Z "
      fill="#BCDCE5"
      transform="translate(71,65)"
    />
    <path
      d="M0,0 L5,2 L14,8 L13,13 L9,20 L5,22 L-2,9 L0,5 L0,4 Z "
      fill="#D2E2E4"
      transform="translate(86,58)"
    />
    <path
      d="M0,0 L3,1 L6,7 L6,13 L3,20 L-3,19 L-10,12 L-4,5 Z "
      fill="#F9BE04"
      transform="translate(63,62)"
    />
    <path
      d="M0,0 L4,4 L6,8 L4,21 L0,19 L-8,13 L-7,8 Z "
      fill="#F4BF0B"
      transform="translate(58,19)"
    />
    <path
      d="M0,0 L3,0 L5,7 L0,12 L-7,18 L-12,8 L-12,5 Z "
      fill="#09AF5A"
      transform="translate(60,55)"
    />
    <path
      d="M0,0 L17,0 L18,2 L16,2 L18,6 L18,12 L11,9 L3,9 L-2,12 L-2,6 L0,6 L0,2 L-2,1 Z "
      fill="#D5E5E8"
      transform="translate(67,14)"
    />
    <path
      d="M0,0 L3,1 L6,9 L5,14 L-5,18 L-7,18 L-8,8 Z "
      fill="#1F41CD"
      transform="translate(96,26)"
    />
    <path
      d="M0,0 L5,1 L13,7 L15,10 L7,17 L5,18 L4,15 L2,14 L2,5 L0,3 Z "
      fill="#F9C009"
      transform="translate(83,16)"
    />
    <path
      d="M0,0 L4,0 L4,4 L2,4 L3,9 L7,8 L9,10 L7,13 L3,13 L3,15 L7,15 L7,17 L3,18 L3,20 L1,20 L0,11 L-4,3 Z "
      fill="#538BB3"
      transform="translate(63,16)"
    />
    <path
      d="M0,0 L2,3 L2,19 L1,19 L1,9 L-12,10 L-11,5 Z "
      fill="#C7D8DC"
      transform="translate(102,38)"
    />
    <path
      d="M0,0 L8,0 L11,3 L11,9 L8,13 L5,13 L5,10 L7,9 L7,4 L2,3 L1,4 L1,9 L3,10 L3,13 L-1,12 L-3,9 L-3,3 Z "
      fill="#1437DB"
      transform="translate(71,111)"
    />
    <path
      d="M0,0 L3,1 L2,6 L-4,17 L-10,12 L-4,5 Z "
      fill="#F70606"
      transform="translate(63,62)"
    />
    <path
      d="M0,0 L9,0 L11,2 L10,8 L8,8 L10,13 L6,12 L5,7 L7,6 L8,3 L3,3 L3,13 L0,13 Z "
      fill="#1937DB"
      transform="translate(55,111)"
    />
    <path
      d="M0,0 L3,1 L9,14 L6,16 L2,15 L-1,9 L-1,2 Z "
      fill="#12B764"
      transform="translate(82,66)"
    />
    <path
      d="M0,0 L5,2 L14,8 L13,13 L12,15 L8,13 L0,4 Z "
      fill="#F9CA1E"
      transform="translate(86,58)"
    />
    <path
      d="M0,0 L3,0 L3,10 L8,10 L8,0 L11,0 L11,10 L8,13 L2,13 L0,11 Z "
      fill="#253DDC"
      transform="translate(41,111)"
    />
    <path
      d="M0,0 L4,1 L4,3 L-1,8 L-8,14 L-11,9 L-11,7 Z "
      fill="#211F29"
      transform="translate(61,59)"
    />
    <path
      d="M0,0 L10,2 L15,5 L14,11 L10,10 L0,3 Z "
      fill="#2845D4"
      transform="translate(87,55)"
    />
    <path
      d="M0,0 L4,2 L10,9 L10,14 L6,12 L-2,6 L-1,1 Z "
      fill="#FA120F"
      transform="translate(52,26)"
    />
    <path
      d="M0,0 L9,0 L9,2 L3,2 L3,5 L9,5 L9,8 L3,8 L3,11 L9,11 L9,13 L0,13 Z "
      fill="#2D4ADD"
      transform="translate(28,111)"
    />
    <path
      d="M0,0 L1,0 L1,10 L11,10 L13,10 L15,9 L14,13 L1,18 L0,13 Z "
      fill="#D0E1E5"
      transform="translate(46,43)"
    />
    <path
      d="M0,0 L12,0 L15,1 L15,8 L6,5 L0,3 Z "
      fill="#F51011"
      transform="translate(88,52)"
    />
    <path
      d="M0,0 L3,0 L2,9 L-2,20 L-4,27 L-5,27 L-5,18 Z "
      fill="#F4F9FB"
      transform="translate(84,48)"
    />
    <path
      d="M0,0 L2,0 L3,6 L-8,11 L-10,11 L-9,6 Z "
      fill="#1C1D23"
      transform="translate(99,33)"
    />
    <path
      d="M0,0 L1,0 L1,6 L0,8 L-5,8 L-6,6 L-15,4 L-17,2 L-15,1 L-7,1 L-1,4 Z "
      fill="#659AC5"
      transform="translate(85,21)"
    />
    <path
      d="M0,0 L9,3 L13,4 L13,8 L3,7 L-1,6 L-1,1 Z "
      fill="#2144D7"
      transform="translate(48,40)"
    />
    <path
      d="M0,0 L17,0 L18,2 L16,2 L16,5 L11,4 L4,4 L0,5 L0,2 L-2,1 Z "
      fill="#D1DFCB"
      transform="translate(67,14)"
    />
    <path
      d="M0,0 L5,1 L6,2 L6,7 L3,8 L2,12 L-1,11 L1,11 L1,8 L-2,7 L-2,2 Z "
      fill="#E3E5F8"
      transform="translate(73,113)"
    />
    <path
      d="M0,0 L9,0 L9,6 L-5,4 L-5,1 Z "
      fill="#11B961"
      transform="translate(94,47)"
    />
    <path
      d="M0,0 L2,0 L3,4 L4,1 L7,2 L8,6 L10,6 L10,1 L12,3 L11,9 L7,9 L7,7 L4,7 L3,9 L0,9 L-1,1 Z "
      fill="#BDC4F2"
      transform="translate(78,128)"
    />
    <path
      d="M0,0 L8,0 L9,1 L9,7 L11,8 L7,7 L7,3 L6,5 L2,5 L2,3 L0,3 L1,8 L-3,7 L-1,7 L-1,5 L-5,5 L-5,3 L-1,3 Z "
      fill="#C3DAE5"
      transform="translate(71,26)"
    />
    <path
      d="M0,0 L14,0 L22,4 L23,6 L18,4 L15,3 L14,8 L9,9 L9,4 L12,2 L0,1 Z "
      fill="#5B6DE3"
      transform="translate(69,90)"
    />
    <path
      d="M0,0 L3,0 L3,8 L5,8 L5,10 L3,10 L3,13 L1,13 L0,4 L-2,7 L-1,10 L-5,10 L-4,5 Z "
      fill="#1635DB"
      transform="translate(119,111)"
    />
    <path
      d="M0,0 L8,0 L12,1 L12,4 L7,5 L0,5 Z "
      fill="#F9060D"
      transform="translate(49,47)"
    />
    <path
      d="M0,0 L5,0 L7,2 L6,8 L2,10 L2,12 L7,12 L7,13 L-1,13 L1,9 L4,6 L5,2 L1,2 L1,4 L-1,4 Z "
      fill="#1C2EDB"
      transform="translate(85,111)"
    />
    <path
      d="M0,0 L5,0 L7,2 L6,7 L2,12 L7,12 L7,13 L-2,13 L-2,11 L4,6 L5,2 L0,2 L0,4 L-2,4 Z "
      fill="#1728DB"
      transform="translate(106,111)"
    />
    <path
      d="M0,0 L1,4 L7,4 L7,2 L9,2 L10,6 L9,7 L-2,7 L-1,2 Z "
      fill="#9DCDDD"
      transform="translate(71,78)"
    />
    <path
      d="M0,0 L5,1 L8,4 L5,9 L4,13 L2,13 L2,5 L0,3 Z "
      fill="#FB6A65"
      transform="translate(83,16)"
    />
    <path
      d="M0,0 L5,1 L5,8 L0,9 Z "
      fill="#ECEEFA"
      transform="translate(96,113)"
    />
    <path
      d="M0,0 L4,0 L4,4 L2,4 L1,11 L-2,7 L-4,3 Z "
      fill="#414248"
      transform="translate(63,16)"
    />
    <path
      d="M0,0 L2,1 L2,7 L-1,8 L1,9 L1,12 L-9,12 L-8,9 L-8,11 L-5,10 L-5,8 L-1,4 Z "
      fill="#A9B0ED"
      transform="translate(92,113)"
    />
    <path
      d="M0,0 L4,1 L2,1 L2,6 L15,4 L15,6 L11,9 L9,8 L0,7 Z "
      fill="#E18894"
      transform="translate(47,46)"
    />
    <path
      d="M0,0 L5,1 L5,3 L0,3 L1,4 L5,4 L4,8 L-1,8 L-2,7 L-2,2 Z "
      fill="#ACB3ED"
      transform="translate(51,128)"
    />
    <path
      d="M0,0 L1,0 L3,10 L2,15 L0,11 L-2,1 Z "
      fill="#FAFCFD"
      transform="translate(65,47)"
    />
    <path
      d="M0,0 L3,1 L5,7 L0,7 L0,4 L-5,6 Z "
      fill="#3456D8"
      transform="translate(96,26)"
    />
    <path
      d="M0,0 L2,0 L2,2 L-1,4 L-2,9 L-5,9 L-6,3 Z "
      fill="#1CBC6D"
      transform="translate(54,57)"
    />
    <path
      d="M0,0 L4,1 L8,6 L4,8 L0,3 Z "
      fill="#F4D565"
      transform="translate(90,20)"
    />
    <path
      d="M0,0 L5,0 L5,2 L1,2 L1,4 L4,5 L1,5 L1,7 L5,8 L-1,8 L-1,1 Z "
      fill="#A9B1EC"
      transform="translate(58,128)"
    />
    <path
      d="M0,0 L2,1 L2,4 L6,5 L2,5 L1,7 L-4,6 L-2,2 Z "
      fill="#CDD2F4"
      transform="translate(112,117)"
    />
    <path
      d="M0,0 L5,0 L6,4 L-3,4 L-2,1 Z "
      fill="#D9E9F0"
      transform="translate(73,41)"
    />
    <path
      d="M0,0 L2,0 L0,10 L-3,10 L-3,4 Z "
      fill="#FCFDFE"
      transform="translate(66,36)"
    />
    <path
      d="M0,0 L3,1 L4,5 L6,5 L6,0 L8,2 L7,8 L3,8 L1,3 Z "
      fill="#9AA4EB"
      transform="translate(82,129)"
    />
    <path
      d="M0,0 L1,0 L1,7 L-2,15 L-6,13 L-3,13 L-2,8 Z "
      fill="#BACCC1"
      transform="translate(69,69)"
    />
    <path
      d="M0,0 L2,0 L2,2 L6,2 L6,4 L2,5 L2,7 L0,7 L-1,1 Z "
      fill="#8F9580"
      transform="translate(64,29)"
    />
    <path
      d="M0,0 L3,1 L3,5 L-1,7 L-2,3 Z "
      fill="#F24E4F"
      transform="translate(52,26)"
    />
    <path
      d="M0,0 L3,1 L3,3 L6,2 L5,5 L3,5 L2,7 L0,7 Z "
      fill="#A0A9EB"
      transform="translate(71,129)"
    />
    <path
      d="M0,0 L2,0 L2,3 L8,3 L8,5 L-1,5 Z "
      fill="#0222D9"
      transform="translate(29,119)"
    />
    <path
      d="M0,0 L9,0 L9,2 L3,2 L3,5 L1,5 Z "
      fill="#0126DA"
      transform="translate(28,111)"
    />
    <path
      d="M0,0 L2,1 L0,5 L-4,8 L-5,6 Z "
      fill="#F9D86E"
      transform="translate(58,19)"
    />
    <path
      d="M0,0 L8,0 L8,4 L0,2 Z "
      fill="#56CE90"
      transform="translate(75,15)"
    />
    <path
      d="M0,0 L5,0 L5,3 L0,2 L0,9 L-1,9 L-2,2 Z "
      fill="#2F41DC"
      transform="translate(96,111)"
    />
    <path
      d="M0,0 L5,2 L6,5 L0,4 Z "
      fill="#F8CD44"
      transform="translate(60,77)"
    />
    <path
      d="M0,0 L5,0 L5,6 L0,4 Z "
      fill="#C2887E"
      transform="translate(80,29)"
    />
    <path
      d="M0,0 L2,0 L3,6 L0,6 L-2,1 Z "
      fill="#2E2E38"
      transform="translate(99,33)"
    />
    <path
      d="M0,0 L5,2 L3,10 L1,10 L1,2 Z "
      fill="#ED170D"
      transform="translate(84,19)"
    />
    <path
      d="M0,0 L2,1 L2,7 L-4,9 L-1,4 Z "
      fill="#D0D4F4"
      transform="translate(92,113)"
    />
    <path
      d="M0,0 L1,0 L1,10 L-1,7 L-1,5 L-3,4 Z "
      fill="#E2E5F8"
      transform="translate(119,114)"
    />
    <path
      d="M0,0 L2,0 L1,5 L-2,7 L-2,1 Z "
      fill="#E2ECF1"
      transform="translate(67,19)"
    />
    <path
      d="M0,0 L6,0 L6,2 L-2,4 L-2,1 Z "
      fill="#F9DB64"
      transform="translate(69,15)"
    />
    <path
      d="M0,0 L4,1 L2,1 L2,3 L5,4 L2,4 L2,6 L4,7 L1,7 Z "
      fill="#3145DD"
      transform="translate(68,93)"
    />
    <path
      d="M0,0 L2,4 L0,8 L-3,3 Z "
      fill="#DADEF7"
      transform="translate(87,100)"
    />
    <path
      d="M0,0 L6,5 L4,7 L-1,1 Z "
      fill="#EE3A36"
      transform="translate(54,72)"
    />
    <path
      d="M0,0 L2,0 L2,7 L-3,6 L0,5 Z "
      fill="#E55666"
      transform="translate(101,53)"
    />
  </svg>
)
export default EuropeanChampionship
