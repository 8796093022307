import * as React from 'react'
const Lithuania = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#066A43"
    />
    <path
      d="M2 67h96l-6 12-9 10-9 6-9 4-4 1H39l-11-4-10-6-8-8-6-10Z"
      fill="#C1262C"
    />
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 2 5H2l6-12 9-10 9-6 9-4Z"
      fill="#FDB912"
    />
  </svg>
)
export default Lithuania
