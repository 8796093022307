import * as React from 'react'
const Mexico = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96px"
    height="96px"
    viewBox="0 0 96 96"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#026847"
        d="M -0.5,-0.5 C 6.16667,-0.5 12.8333,-0.5 19.5,-0.5C 19.5,31.5 19.5,63.5 19.5,95.5C 12.8333,95.5 6.16667,95.5 -0.5,95.5C -0.5,63.5 -0.5,31.5 -0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfc"
        d="M 19.5,-0.5 C 38.1667,-0.5 56.8333,-0.5 75.5,-0.5C 75.5,31.5 75.5,63.5 75.5,95.5C 56.8333,95.5 38.1667,95.5 19.5,95.5C 19.5,63.5 19.5,31.5 19.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#c92128"
        d="M 75.5,-0.5 C 82.1667,-0.5 88.8333,-0.5 95.5,-0.5C 95.5,31.5 95.5,63.5 95.5,95.5C 88.8333,95.5 82.1667,95.5 75.5,95.5C 75.5,63.5 75.5,31.5 75.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#ac8155"
        d="M 52.5,29.5 C 51.0783,29.7086 49.745,30.2086 48.5,31C 50.3632,32.4178 50.8632,34.2511 50,36.5C 49.5848,34.7091 48.5848,33.3757 47,32.5C 46.5078,33.549 46.6744,34.549 47.5,35.5C 45.483,36.4447 45.1497,37.9447 46.5,40C 46.0457,41.9386 45.379,43.772 44.5,45.5C 43.8333,44.8333 43.1667,44.1667 42.5,43.5C 42.5751,40.7938 42.9084,38.1272 43.5,35.5C 42.5,34.8333 41.5,34.1667 40.5,33.5C 40.8333,32.8333 41.1667,32.1667 41.5,31.5C 43.7159,31.7254 45.3826,30.8921 46.5,29C 48.7116,28.2109 50.7116,28.3775 52.5,29.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#6e452c"
        d="M 52.5,29.5 C 56.0204,30.3528 58.6871,32.3528 60.5,35.5C 61.9232,37.7561 62.5899,40.2561 62.5,43C 62.6255,45.0669 62.2922,46.2336 61.5,46.5C 61.6567,45.1266 61.49,43.7932 61,42.5C 58.637,43.2322 56.137,43.2322 53.5,42.5C 52.2641,42.5886 52.2641,43.5886 53.5,45.5C 55.5398,47.177 57.5398,48.8437 59.5,50.5C 57.604,51.4656 55.604,51.7989 53.5,51.5C 52.3684,50.6457 51.035,50.6457 49.5,51.5C 49.1667,50.8333 48.8333,50.1667 48.5,49.5C 47.9094,48.8485 47.2427,48.1818 46.5,47.5C 47.7598,46.6184 48.9264,45.6184 50,44.5C 50.6877,43.6683 50.521,43.0016 49.5,42.5C 48.0953,43.9937 46.4287,44.9937 44.5,45.5C 45.379,43.772 46.0457,41.9386 46.5,40C 45.1497,37.9447 45.483,36.4447 47.5,35.5C 46.6744,34.549 46.5078,33.549 47,32.5C 48.5848,33.3757 49.5848,34.7091 50,36.5C 50.8632,34.2511 50.3632,32.4178 48.5,31C 49.745,30.2086 51.0783,29.7086 52.5,29.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fcf1d6"
        d="M 43.5,35.5 C 42.9084,38.1272 42.5751,40.7938 42.5,43.5C 40.8967,43.6882 39.5634,43.1882 38.5,42C 40.365,39.8064 42.0317,37.6397 43.5,35.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#c5b476"
        d="M 40.5,33.5 C 41.5,34.1667 42.5,34.8333 43.5,35.5C 42.0317,37.6397 40.365,39.8064 38.5,42C 39.5634,43.1882 40.8967,43.6882 42.5,43.5C 43.1667,44.1667 43.8333,44.8333 44.5,45.5C 44.4431,46.609 44.1098,47.609 43.5,48.5C 41.1667,48.5 38.8333,48.5 36.5,48.5C 36.3505,47.448 36.5172,46.448 37,45.5C 37.7175,46.4506 38.5509,46.6172 39.5,46C 38.1059,45.044 37.4393,43.7106 37.5,42C 38.6912,39.1618 39.6912,36.3285 40.5,33.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#f0f3e4"
        d="M 41.5,31.5 C 41.1667,32.1667 40.8333,32.8333 40.5,33.5C 39.6912,36.3285 38.6912,39.1618 37.5,42C 37.4393,43.7106 38.1059,45.044 39.5,46C 38.5509,46.6172 37.7175,46.4506 37,45.5C 36.5172,46.448 36.3505,47.448 36.5,48.5C 35.5,48.5 34.5,48.5 33.5,48.5C 33.281,49.675 33.6143,50.675 34.5,51.5C 37.9212,53.8899 41.7545,55.2233 46,55.5C 47.3756,55.6842 48.2089,56.3509 48.5,57.5C 46.7163,56.2801 45.0496,56.2801 43.5,57.5C 42.2233,55.086 40.5566,54.9193 38.5,57C 37.7476,57.6708 37.4142,58.5041 37.5,59.5C 32.904,55.8954 29.904,51.2287 28.5,45.5C 27.7132,46.2421 27.0465,47.0754 26.5,48C 27.464,49.7255 28.1307,51.5588 28.5,53.5C 27.5326,54.8062 26.1993,55.4729 24.5,55.5C 24.2436,53.2075 24.5769,51.0408 25.5,49C 23.8934,47.951 23.2267,46.451 23.5,44.5C 27.1981,44.5524 30.8648,44.8857 34.5,45.5C 34.822,43.2776 34.1554,41.4443 32.5,40C 34.8062,38.0838 36.1395,35.5838 36.5,32.5C 38.0453,31.5481 39.712,31.2148 41.5,31.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#8f9477"
        d="M 66.5,49.5 C 65.596,48.791 65.2627,47.791 65.5,46.5C 66.8671,45.1474 67.8671,45.4807 68.5,47.5C 68.1445,48.5223 67.4778,49.189 66.5,49.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#a87f60"
        d="M 48.5,49.5 C 46.5853,49.7845 44.9187,49.4511 43.5,48.5C 44.1098,47.609 44.4431,46.609 44.5,45.5C 46.4287,44.9937 48.0953,43.9937 49.5,42.5C 50.521,43.0016 50.6877,43.6683 50,44.5C 48.9264,45.6184 47.7598,46.6184 46.5,47.5C 47.2427,48.1818 47.9094,48.8485 48.5,49.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e7e8df"
        d="M 36.5,48.5 C 38.8333,48.5 41.1667,48.5 43.5,48.5C 44.9187,49.4511 46.5853,49.7845 48.5,49.5C 48.8333,50.1667 49.1667,50.8333 49.5,51.5C 49.5,52.1667 49.1667,52.5 48.5,52.5C 45.3424,52.0538 42.1757,51.7204 39,51.5C 37.6898,50.8699 36.8565,49.8699 36.5,48.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#e2e6d7"
        d="M 60.5,35.5 C 61.4959,35.4142 62.3292,35.7476 63,36.5C 63.3333,40.1667 63.6667,43.8333 64,47.5C 64.3826,46.944 64.8826,46.6107 65.5,46.5C 65.2627,47.791 65.596,48.791 66.5,49.5C 66.7221,53.8424 65.3888,57.5091 62.5,60.5C 61.8166,58.8235 60.4832,58.1568 58.5,58.5C 58.5,59.5 58.5,60.5 58.5,61.5C 58.1583,62.3382 57.4916,62.6716 56.5,62.5C 55.7354,62.0766 54.9021,61.7432 54,61.5C 50.9969,62.9746 47.8303,63.9746 44.5,64.5C 43.8658,63.0532 42.6991,62.3866 41,62.5C 39.9302,62.528 39.0968,62.8614 38.5,63.5C 36.9731,63.573 35.9731,62.9063 35.5,61.5C 36.8333,61.5 37.5,60.8333 37.5,59.5C 39.5648,59.5172 41.5648,59.8505 43.5,60.5C 45.9479,61.4731 48.6146,61.8064 51.5,61.5C 51.7678,59.901 51.4345,58.5676 50.5,57.5C 50.811,56.5222 51.4777,55.8555 52.5,55.5C 54.9007,57.0622 56.9007,56.7288 58.5,54.5C 57.3112,53.5713 55.9778,53.238 54.5,53.5C 54.1667,52.8333 53.8333,52.1667 53.5,51.5C 55.604,51.7989 57.604,51.4656 59.5,50.5C 57.5398,48.8437 55.5398,47.177 53.5,45.5C 52.2641,43.5886 52.2641,42.5886 53.5,42.5C 55.4818,45.1367 58.1485,46.47 61.5,46.5C 62.2922,46.2336 62.6255,45.0669 62.5,43C 62.5899,40.2561 61.9232,37.7561 60.5,35.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#ae9387"
        d="M 53.5,42.5 C 56.137,43.2322 58.637,43.2322 61,42.5C 61.49,43.7932 61.6567,45.1266 61.5,46.5C 58.1485,46.47 55.4818,45.1367 53.5,42.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#bcb775"
        d="M 53.5,51.5 C 53.8333,52.1667 54.1667,52.8333 54.5,53.5C 52.2646,53.7947 50.2646,53.4614 48.5,52.5C 49.1667,52.5 49.5,52.1667 49.5,51.5C 51.035,50.6457 52.3684,50.6457 53.5,51.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#467f81"
        d="M 36.5,48.5 C 36.8565,49.8699 37.6898,50.8699 39,51.5C 42.1757,51.7204 45.3424,52.0538 48.5,52.5C 50.2646,53.4614 52.2646,53.7947 54.5,53.5C 55.9778,53.238 57.3112,53.5713 58.5,54.5C 56.9007,56.7288 54.9007,57.0622 52.5,55.5C 51.4777,55.8555 50.811,56.5222 50.5,57.5C 49.8333,57.5 49.1667,57.5 48.5,57.5C 48.2089,56.3509 47.3756,55.6842 46,55.5C 41.7545,55.2233 37.9212,53.8899 34.5,51.5C 33.6143,50.675 33.281,49.675 33.5,48.5C 34.5,48.5 35.5,48.5 36.5,48.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b1b194"
        d="M 37.5,59.5 C 37.5,60.8333 36.8333,61.5 35.5,61.5C 33.194,58.946 30.8606,56.2793 28.5,53.5C 28.1307,51.5588 27.464,49.7255 26.5,48C 27.0465,47.0754 27.7132,46.2421 28.5,45.5C 29.904,51.2287 32.904,55.8954 37.5,59.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#72bdba"
        d="M 43.5,57.5 C 43.5,58.5 43.5,59.5 43.5,60.5C 41.5648,59.8505 39.5648,59.5172 37.5,59.5C 37.4142,58.5041 37.7476,57.6708 38.5,57C 40.5566,54.9193 42.2233,55.086 43.5,57.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#c39d6a"
        d="M 48.5,57.5 C 49.1667,57.5 49.8333,57.5 50.5,57.5C 51.4345,58.5676 51.7678,59.901 51.5,61.5C 48.6146,61.8064 45.9479,61.4731 43.5,60.5C 43.5,59.5 43.5,58.5 43.5,57.5C 45.0496,56.2801 46.7163,56.2801 48.5,57.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#949c7f"
        d="M 62.5,60.5 C 61.2921,61.2341 59.9587,61.5674 58.5,61.5C 58.5,60.5 58.5,59.5 58.5,58.5C 60.4832,58.1568 61.8166,58.8235 62.5,60.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#94906c"
        d="M 38.5,63.5 C 39.0968,62.8614 39.9302,62.528 41,62.5C 42.6991,62.3866 43.8658,63.0532 44.5,64.5C 42.3792,64.6747 40.3792,64.3414 38.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b5ac9c"
        d="M 56.5,62.5 C 55.6081,65.1128 53.9414,65.7795 51.5,64.5C 48.9167,67.0822 46.5833,67.0822 44.5,64.5C 47.8303,63.9746 50.9969,62.9746 54,61.5C 54.9021,61.7432 55.7354,62.0766 56.5,62.5 Z"
      />
    </g>
  </svg>
)
export default Mexico
