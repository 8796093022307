import * as React from 'react'
const Chip5 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={306}
    height={309}
    viewBox="0 0 306 309"
    {...props}
  >
    <path
      d="M0,0 L35,0 L52,3 L58,5 L58,7 L65,8 L85,16 L101,25 L113,34 L125,45 L130,47 L139,58 L150,73 L158,89 L164,103 L167,115 L168,125 L170,137 L170,170 L166,194 L165,202 L156,224 L148,238 L139,250 L130,261 L124,264 L117,271 L102,282 L89,290 L70,298 L58,302 L56,304 L42,307 L34,308 L1,308 L-17,305 L-22,303 L-32,299 L-45,294 L-61,286 L-73,278 L-86,267 L-91,264 L-96,260 L-96,258 L-98,258 L-106,248 L-118,230 L-127,211 L-131,199 L-132,189 L-135,172 L-135,136 L-132,119 L-131,109 L-125,93 L-117,76 L-103,56 L-94,46 L-90,46 L-88,42 L-77,33 L-66,25 L-48,15 L-33,9 L-23,6 L-22,4 L-8,1 Z "
      fill="#639C38"
      transform="translate(135,1)"
    />
    <path
      d="M0,0 L22,0 L39,5 L54,13 L67,24 L76,36 L84,52 L88,68 L88,88 L84,103 L79,115 L70,128 L66,133 L64,133 L63,136 L49,146 L34,153 L18,156 L1,156 L-15,153 L-32,145 L-42,137 L-45,135 L-45,133 L-47,133 L-59,117 L-64,106 L-68,92 L-69,84 L-69,68 L-65,52 L-57,36 L-48,24 L-38,15 L-23,6 L-10,2 Z "
      fill="#FAFAFA"
      transform="translate(143,77)"
    />
    <path
      d="M0,0 L4,1 L13,12 L24,27 L32,43 L38,57 L41,69 L39,73 L39,68 L36,68 L39,77 L40,93 L28,91 L11,88 L-5,87 L-5,124 L-9,140 L-14,153 L-22,167 L-30,177 L-42,189 L-52,196 L-62,202 L-80,209 L-89,211 L-98,212 L-121,212 L-137,209 L-155,202 L-168,194 L-178,186 L-183,181 L-191,173 L-201,158 L-208,141 L-212,125 L-213,118 L-213,99 L-211,86 L-207,72 L-201,58 L-193,46 L-182,33 L-169,22 L-153,13 L-141,8 L-129,5 L-121,4 L-93,4 L-76,8 L-60,15 L-46,24 L-40,29 L-34,30 L-35,33 L-28,33 L-25,32 L-22,22 L-18,17 L-10,10 L-2,2 L0,2 Z M-118,30 L-136,34 L-150,41 L-159,48 L-168,56 L-177,69 L-183,82 L-187,98 L-187,114 L-184,130 L-179,143 L-173,153 L-165,163 L-163,163 L-163,165 L-150,175 L-133,183 L-117,186 L-100,186 L-84,183 L-67,175 L-54,165 L-54,163 L-51,162 L-41,148 L-35,136 L-32,127 L-30,118 L-30,98 L-35,79 L-42,66 L-49,57 L-54,51 L-67,41 L-82,34 L-96,30 Z "
      fill="#F4F7F1"
      transform="translate(261,47)"
    />
    <path
      d="M0,0 L13,0 L33,6 L37,7 L36,15 L27,37 L19,51 L10,63 L1,74 L-3,75 L-8,70 L-24,54 L-26,50 L-26,41 L-14,23 L-9,13 L-4,3 Z "
      fill="#FDFDFC"
      transform="translate(264,188)"
    />
    <path
      d="M0,0 L10,0 L16,5 L25,24 L34,37 L37,42 L37,49 L34,55 L21,68 L14,74 L12,76 L9,73 L9,71 L7,71 L-1,61 L-13,43 L-22,24 L-26,12 L-26,7 Z "
      fill="#F8F7F8"
      transform="translate(30,188)"
    />
    <path
      d="M0,0 L4,1 L13,12 L24,27 L32,43 L38,57 L41,69 L39,73 L39,68 L20,73 L14,75 L5,75 L-1,71 L-9,55 L-18,41 L-22,36 L-23,33 L-23,24 L-18,17 L-10,10 L-2,2 L0,2 Z "
      fill="#FBFBFB"
      transform="translate(261,47)"
    />
    <path
      d="M0,0 L3,0 L8,6 L16,13 L24,21 L26,25 L26,33 L20,42 L12,55 L4,71 L1,74 L-2,75 L-11,75 L-35,68 L-37,67 L-36,59 L-27,38 L-21,27 L-9,10 Z "
      fill="#F6F5F7"
      transform="translate(41,47)"
    />
    <path
      d="M0,0 L49,0 L54,4 L57,10 L62,30 L63,38 L49,41 L41,42 L8,42 L-10,39 L-15,37 L-16,35 L-14,35 L-10,17 L-7,7 L-2,1 Z "
      fill="#FCFCFC"
      transform="translate(128,267)"
    />
    <path
      d="M0,0 L35,0 L52,3 L57,4 L51,27 L49,35 L44,41 L41,42 L-6,42 L-11,39 L-14,35 L-21,8 L-23,6 L-22,4 L-8,1 Z "
      fill="#F7F6F7"
      transform="translate(135,1)"
    />
    <path
      d="M0,0 L5,1 L2,6 L-1,6 L-2,9 L-17,18 L-28,28 L-37,41 L-43,54 L-47,70 L-47,86 L-44,102 L-39,115 L-33,125 L-26,133 L-26,135 L-24,135 L-24,137 L-20,139 L-9,147 L6,154 L22,157 L39,157 L55,154 L72,146 L84,137 L93,126 L101,113 L106,99 L108,90 L108,70 L106,58 L113,58 L115,70 L115,91 L112,105 L104,123 L96,134 L87,144 L76,152 L60,160 L46,164 L40,165 L20,165 L3,161 L-14,153 L-26,144 L-36,134 L-45,120 L-50,109 L-54,93 L-54,65 L-49,48 L-41,33 L-33,23 L-24,14 L-14,7 Z "
      fill="#7AAC3E"
      transform="translate(122,75)"
    />
    <path
      d="M0,0 L2,1 L6,11 L13,6 L15,6 L14,17 L23,14 L20,23 L18,29 L17,38 L-16,38 L-18,25 L-22,16 L-22,14 L-16,16 L-12,18 L-14,6 L-17,5 L-17,1 L-13,1 L-11,7 L-6,11 L-4,11 L-1,1 Z "
      fill="#9DBF82"
      transform="translate(151,96)"
    />
    <path
      d="M0,0 L1,0 L1,29 L3,50 L3,55 L5,54 L6,60 L2,63 L0,63 L-5,55 L-8,53 L-18,53 L-25,55 L-29,54 L-25,47 L-25,44 L-23,40 L-20,37 L-19,33 L-17,33 L-17,24 L-15,24 L-13,20 L-10,16 L-8,16 L-6,9 L-2,3 Z M3,51 Z "
      fill="#468545"
      transform="translate(48,136)"
    />
    <path
      d="M0,0 L19,0 L35,3 L51,10 L62,17 L74,28 L83,40 L91,56 L92,63 L85,64 L82,54 L76,43 L69,34 L65,29 L52,19 L37,12 L23,8 L1,8 L-17,12 L-21,13 L-21,11 L-18,11 L-16,6 L-18,4 L-7,1 Z "
      fill="#6FA348"
      transform="translate(142,70)"
    />
    <path
      d="M0,0 L9,0 L10,1 L10,10 L-8,10 L-8,14 L-6,13 L1,13 L7,16 L11,21 L12,23 L12,30 L9,36 L6,39 L1,41 L-9,41 L-19,36 L-20,32 L-16,27 L-11,29 L-9,30 L-3,30 L0,28 L0,24 L-8,24 L-12,25 L-18,22 L-18,9 L-2,1 Z "
      fill="#558F38"
      transform="translate(156,149)"
    />
    <path
      d="M0,0 L5,1 L2,6 L-1,6 L-2,9 L-17,18 L-28,28 L-37,41 L-43,54 L-47,70 L-47,99 L-49,104 L-51,104 L-54,93 L-54,65 L-49,48 L-41,33 L-33,23 L-24,14 L-14,7 Z "
      fill="#438340"
      transform="translate(122,75)"
    />
    <path
      d="M0,0 L7,4 L8,7 L18,10 L27,9 L44,4 L48,4 L50,11 L51,27 L39,25 L22,22 L6,21 L4,22 L0,3 Z "
      fill="#C5DD8D"
      transform="translate(250,113)"
    />
    <path
      d="M0,0 L4,1 L7,1 L6,2 L19,2 L19,3 L13,5 L6,6 L5,7 L24,8 L33,6 L33,8 L36,9 L32,15 L29,24 L24,25 L19,24 L19,22 L13,20 L1,11 L-11,5 L-9,3 L-2,2 Z "
      fill="#90BC4D"
      transform="translate(207,56)"
    />
    <path
      d="M0,0 L1,0 L3,9 L12,21 L20,37 L25,45 L29,47 L38,47 L59,41 L60,43 L37,50 L28,49 L21,46 L16,41 L14,40 L14,45 L12,43 L4,27 L-3,17 L-10,9 L-10,7 L-14,5 L-15,3 L-10,3 L-11,6 L-4,6 L-1,5 Z "
      fill="#ACCD76"
      transform="translate(237,74)"
    />
    <path
      d="M0,0 L6,0 L7,5 L7,30 L6,35 L-5,34 L-8,33 L-8,2 Z "
      fill="#94C03C"
      transform="translate(246,137)"
    />
    <path
      d="M0,0 L5,1 L13,4 L10,13 L4,24 L-3,34 L-8,33 L-15,27 L-11,20 L-5,10 Z "
      fill="#8BBA3A"
      transform="translate(234,185)"
    />
    <path
      d="M0,0 L13,1 L13,33 L-1,34 L-2,27 L-2,8 L-1,1 Z "
      fill="#488552"
      transform="translate(52,138)"
    />
    <path
      d="M0,0 L3,0 L6,7 L6,12 L-13,22 L-19,27 L-23,26 L-29,18 L-24,13 L-11,5 Z "
      fill="#468639"
      transform="translate(115,60)"
    />
    <path
      d="M0,0 L6,3 L11,7 L9,12 L2,22 L-4,34 L-12,33 L-16,31 L-15,24 L-8,11 Z "
      fill="#317737"
      transform="translate(73,90)"
    />
    <path
      d="M0,0 L33,0 L35,7 L35,12 L26,14 L7,14 L-1,12 Z "
      fill="#689F3F"
      transform="translate(135,242)"
    />
    <path
      d="M0,0 L4,1 L10,8 L10,10 L0,17 L-17,26 L-22,26 L-25,18 L-25,14 L-9,6 Z "
      fill="#7AAD39"
      transform="translate(207,223)"
    />
    <path
      d="M0,0 L6,0 L15,17 L21,25 L19,29 L14,33 L9,32 L3,24 L-5,8 L-6,3 Z "
      fill="#74A37A"
      transform="translate(63,186)"
    />
    <path
      d="M0,0 L9,3 L21,9 L32,17 L29,22 L25,27 L21,26 L11,19 L-4,12 L-3,7 Z "
      fill="#8DB953"
      transform="translate(185,60)"
    />
    <path
      d="M0,0 L26,0 L32,1 L32,11 L31,13 L-2,13 L-4,1 Z "
      fill="#679E3F"
      transform="translate(137,54)"
    />
    <path
      d="M0,0 L4,1 L18,10 L29,15 L26,26 L19,25 L3,17 L-6,10 L-5,6 Z "
      fill="#498839"
      transform="translate(93,223)"
    />
    <path
      d="M0,0 L4,1 L12,9 L22,23 L29,39 L29,43 L22,44 L19,34 L13,23 L6,14 L2,9 L-6,3 L-6,2 Z "
      fill="#CEE39D"
      transform="translate(205,90)"
    />
    <path
      d="M0,0 L1,0 L0,9 L-4,14 L-6,20 L-7,24 L-8,29 L-16,45 L-17,48 L-20,50 L-21,53 L-25,55 L-29,54 L-25,47 L-25,44 L-23,40 L-20,37 L-19,33 L-17,33 L-17,24 L-15,24 L-13,20 L-10,16 L-8,16 L-6,9 L-2,3 Z M-7,23 Z "
      fill="#387B3A"
      transform="translate(48,136)"
    />
    <path
      d="M0,0 L1,0 L2,7 L-1,14 L-3,14 L-4,19 L-8,26 L-13,33 L-16,32 L-21,17 L-22,14 L-16,16 L-12,18 L-14,6 L-17,5 L-17,1 L-13,1 L-11,7 L-6,11 L-4,11 L-1,1 Z "
      fill="#7EA574"
      transform="translate(151,96)"
    />
    <path
      d="M0,0 L19,0 L35,3 L51,10 L62,17 L64,20 L62,22 L56,22 L46,16 L34,11 L26,9 L30,7 L32,5 L24,3 L0,1 Z "
      fill="#92BB59"
      transform="translate(142,70)"
    />
    <path
      d="M0,0 L2,1 L-1,14 L-1,43 L-3,48 L-5,48 L-8,37 L-8,9 L-6,3 L-4,6 L-1,2 Z "
      fill="#4D8858"
      transform="translate(76,131)"
    />
    <path
      d="M0,0 L2,2 L-2,13 L-3,22 L-21,22 L-19,18 L-14,9 L-10,7 L-10,5 L-5,2 Z "
      fill="#BAD591"
      transform="translate(171,112)"
    />
    <path
      d="M0,0 L1,0 L1,23 L-3,41 L-9,56 L-15,67 L-25,80 L-35,90 L-49,100 L-63,107 L-74,111 L-79,111 L-76,109 L-56,101 L-42,92 L-30,81 L-21,71 L-13,58 L-7,45 L-3,31 L-1,18 Z "
      fill="#1E6B33"
      transform="translate(257,146)"
    />
    <path
      d="M0,0 L4,1 L13,12 L24,27 L32,43 L38,57 L41,69 L39,73 L39,68 L37,68 L33,55 L26,39 L16,22 L3,6 L0,3 Z "
      fill="#ECEBED"
      transform="translate(261,47)"
    />
    <path
      d="M0,0 L3,0 L2,8 L-7,30 L-15,44 L-24,56 L-33,67 L-37,68 L-36,64 L-27,54 L-18,41 L-11,29 L-2,8 Z "
      fill="#DED8D9"
      transform="translate(298,195)"
    />
    <path
      d="M0,0 L1,2 L7,3 L23,5 L54,5 L72,2 L76,2 L77,5 L63,8 L55,9 L22,9 L4,6 L-1,4 L-2,2 L0,2 Z "
      fill="#DAD7DB"
      transform="translate(114,300)"
    />
    <path
      d="M0,0 L2,3 L7,18 L14,32 L16,36 L14,36 L12,40 L9,42 L0,26 L-2,21 L3,17 L2,13 L0,15 Z M1,9 Z "
      fill="#77A673"
      transform="translate(50,178)"
    />
    <path
      d="M0,0 L6,0 L7,2 L-4,5 L-8,5 L-9,7 L-27,15 L-44,26 L-54,34 L-60,40 L-62,40 L-63,42 L-64,39 L-59,34 L-48,24 L-32,14 L-16,6 Z "
      fill="#C1D759"
      transform="translate(108,8)"
    />
    <path
      d="M0,0 L10,1 L8,14 L5,17 L3,24 L0,31 L-2,27 L-2,8 L-1,1 Z "
      fill="#387A46"
      transform="translate(52,138)"
    />
    <path
      d="M0,0 L1,0 L3,21 L3,26 L5,25 L6,31 L2,34 L0,34 L-5,26 L-8,24 L-16,23 L-16,22 L-9,20 L-6,17 L-4,10 L-1,5 Z M3,22 Z "
      fill="#5C9359"
      transform="translate(48,165)"
    />
    <path
      d="M0,0 L3,1 L-1,28 L-1,52 L2,72 L3,79 L5,80 L0,79 L-3,65 L-4,58 L-4,22 L-1,5 Z "
      fill="#B5CF4E"
      transform="translate(4,115)"
    />
    <path
      d="M0,0 L3,4 L8,15 L8,17 L-1,20 L-4,20 L-9,9 L-9,6 L-3,5 L-5,3 L-1,2 Z "
      fill="#C8DF92"
      transform="translate(239,104)"
    />
    <path
      d="M0,0 L11,7 L12,9 L5,18 L1,17 L-4,13 L-8,9 L-7,6 L-2,3 Z "
      fill="#9CC35E"
      transform="translate(205,69)"
    />
    <path
      d="M0,0 L6,0 L10,4 L10,11 L7,14 L0,14 L-4,10 L-4,4 Z "
      fill="#F8FAF5"
      transform="translate(214,260)"
    />
    <path
      d="M0,0 L7,0 L11,4 L11,10 L7,14 L0,13 L-3,10 L-3,4 Z "
      fill="#F9FBF7"
      transform="translate(278,148)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,10 L6,14 L-1,13 L-4,9 L-3,2 Z "
      fill="#F8FAF7"
      transform="translate(214,36)"
    />
    <path
      d="M0,0 L6,0 L10,4 L10,10 L5,14 L-2,13 L-4,10 L-4,3 Z "
      fill="#F8FAF8"
      transform="translate(20,148)"
    />
    <path
      d="M0,0 L5,5 L8,9 L6,13 L1,17 L-4,16 L-8,11 L-7,7 Z "
      fill="#367A38"
      transform="translate(76,202)"
    />
    <path
      d="M0,0 L2,4 L7,19 L10,26 L4,30 L-3,15 L-4,10 L-2,10 L-1,5 Z "
      fill="#7FAA84"
      transform="translate(75,169)"
    />
    <path
      d="M0,0 L7,0 L11,5 L10,11 L6,14 L1,14 L-3,10 L-3,3 Z "
      fill="#F7F9F8"
      transform="translate(84,36)"
    />
    <path
      d="M0,0 L7,1 L9,3 L9,11 L6,14 L-1,14 L-4,11 L-4,4 Z "
      fill="#FAFCFA"
      transform="translate(85,260)"
    />
    <path
      d="M0,0 L4,1 L12,9 L16,14 L16,18 L10,18 L2,9 L-6,3 L-6,2 Z "
      fill="#B8D57D"
      transform="translate(205,90)"
    />
    <path
      d="M0,0 L3,0 L1,4 L-6,11 L-21,22 L-29,27 L-33,27 L-33,25 L-17,15 L-4,4 Z "
      fill="#367B4A"
      transform="translate(258,261)"
    />
    <path
      d="M0,0 L1,0 L2,25 L-7,26 L-6,16 L-2,8 L-1,7 Z "
      fill="#4D8955"
      transform="translate(63,146)"
    />
    <path
      d="M0,0 L8,4 L14,9 L12,12 L6,12 L-4,6 L-8,3 L-3,1 L0,3 Z M-5,3 Z "
      fill="#A5C969"
      transform="translate(192,80)"
    />
    <path
      d="M0,0 L2,2 L3,11 L0,17 L-18,35 L-22,36 L-20,32 L-7,20 L-1,13 L0,10 Z "
      fill="#256F33"
      transform="translate(66,227)"
    />
    <path
      d="M0,0 L2,0 L2,3 L-13,10 L-25,14 L-30,14 L-30,12 L-8,4 Z "
      fill="#387D44"
      transform="translate(221,288)"
    />
    <path
      d="M0,0 L3,1 L3,3 L-13,11 L-22,17 L-26,18 L-24,14 L-10,5 Z "
      fill="#E3EAE3"
      transform="translate(112,58)"
    />
    <path
      d="M0,0 L5,2 L6,0 L10,6 L14,8 L23,8 L44,2 L45,4 L22,11 L13,10 L6,7 L1,2 Z "
      fill="#9FC190"
      transform="translate(252,113)"
    />
    <path
      d="M0,0 L2,1 L2,14 L0,19 L-2,19 L-5,8 L-4,4 L-2,4 Z "
      fill="#64986E"
      transform="translate(73,160)"
    />
    <path
      d="M0,0 L18,0 L14,3 L0,9 Z "
      fill="#B3CEA6"
      transform="translate(138,149)"
    />
    <path
      d="M0,0 L2,1 L-6,17 L-10,25 L-15,29 L-24,30 L-20,28 L-14,25 L-10,19 L-2,3 Z "
      fill="#1F6C32"
      transform="translate(57,94)"
    />
    <path
      d="M0,0 L7,2 L7,6 L1,11 L-3,11 L-2,4 Z "
      fill="#71A546"
      transform="translate(185,60)"
    />
    <path
      d="M0,0 L3,1 L3,29 L1,33 L0,29 Z "
      fill="#267134"
      transform="translate(302,137)"
    />
    <path
      d="M0,0 L1,4 L-7,15 L-13,28 L-15,32 L-16,27 L-8,11 Z "
      fill="#DFE7E0"
      transform="translate(71,89)"
    />
    <path
      d="M0,0 L2,1 L2,8 L-1,14 L-4,17 L-9,19 L-19,19 L-20,17 L-7,15 L-2,11 L-1,9 Z "
      fill="#292F25"
      transform="translate(166,171)"
    />
    <path
      d="M0,0 L0,3 L-5,8 L0,8 L2,12 L0,16 L1,20 L-3,16 L-8,9 L-6,5 Z "
      fill="#93BB7C"
      transform="translate(227,90)"
    />
    <path
      d="M0,0 L2,0 L2,8 L-16,8 L-16,13 L-17,13 L-17,6 L0,6 Z "
      fill="#1F2F17"
      transform="translate(164,151)"
    />
    <path
      d="M0,0 L1,0 L2,25 L-6,26 L-5,23 L-3,23 L-1,17 Z "
      fill="#6E9E73"
      transform="translate(63,146)"
    />
    <path
      d="M0,0 L9,6 L14,11 L10,12 L1,5 Z "
      fill="#7CAB4E"
      transform="translate(238,28)"
    />
    <path
      d="M0,0 L11,6 L14,9 L11,11 L1,5 Z "
      fill="#7EAC47"
      transform="translate(223,19)"
    />
    <path
      d="M0,0 L3,0 L3,5 L5,5 L6,11 L6,20 L4,20 L1,4 Z "
      fill="#377D39"
      transform="translate(297,115)"
    />
    <path
      d="M0,0 L2,2 L2,8 L-4,18 L-7,20 L-6,16 L-1,8 Z "
      fill="#1F6B30"
      transform="translate(67,72)"
    />
    <path
      d="M0,0 L1,0 L1,10 L-1,16 L-3,17 L-1,1 Z "
      fill="#267138"
      transform="translate(302,174)"
    />
    <path
      d="M0,0 L7,6 L9,10 L4,9 L-1,3 Z "
      fill="#74A548"
      transform="translate(252,39)"
    />
    <path
      d="M0,0 L5,0 L8,3 L8,5 L10,5 L9,9 L2,3 L0,3 Z "
      fill="#81AD55"
      transform="translate(43,262)"
    />
    <path
      d="M0,0 L2,1 L4,10 L2,14 L2,9 L0,9 L-1,1 Z "
      fill="#CAD6CE"
      transform="translate(298,106)"
    />
    <path
      d="M0,0 L9,3 L11,5 L7,6 L1,4 Z "
      fill="#8FB758"
      transform="translate(211,14)"
    />
    <path
      d="M0,0 L6,0 L7,2 L-6,5 L-3,1 Z "
      fill="#B2CE4A"
      transform="translate(108,8)"
    />
    <path
      d="M0,0 L10,3 L8,5 L1,4 Z "
      fill="#93B958"
      transform="translate(201,10)"
    />
    <path
      d="M0,0 L1,0 L1,7 L-4,11 L-9,11 L-6,9 L-1,4 Z "
      fill="#277232"
      transform="translate(95,265)"
    />
    <path
      d="M0,0 L5,3 L5,5 L3,5 L4,13 L2,10 Z "
      fill="#AAC99A"
      transform="translate(251,119)"
    />
    <path
      d="M0,0 L6,0 L3,2 L-3,4 L-8,2 L-5,1 Z "
      fill="#212B1B"
      transform="translate(147,170)"
    />
    <path
      d="M0,0 L8,4 L4,6 L1,5 Z "
      fill="#90B760"
      transform="translate(223,19)"
    />
    <path
      d="M0,0 L3,1 L0,4 L-5,4 L-5,2 Z "
      fill="#317838"
      transform="translate(196,298)"
    />
    <path
      d="M0,0 L2,0 L2,3 L-1,5 L-5,5 L-5,3 Z "
      fill="#2A733D"
      transform="translate(230,283)"
    />
    <path
      d="M0,0 L1,0 L0,13 L-1,13 L-2,4 Z "
      fill="#558F66"
      transform="translate(304,166)"
    />
    <path
      d="M0,0 L4,2 L3,5 L-1,4 Z "
      fill="#9CB688"
      transform="translate(175,105)"
    />
    <path
      d="M0,0 L4,0 L4,5 L0,4 Z "
      fill="#8EAD79"
      transform="translate(165,97)"
    />
    <path
      d="M0,0 L2,0 L0,4 L-3,8 L-5,8 L-3,4 Z "
      fill="#236E36"
      transform="translate(92,224)"
    />
    <path
      d="M0,0 L5,1 L5,4 L0,3 Z "
      fill="#7FA265"
      transform="translate(149,90)"
    />
    <path
      d="M0,0 L1,2 L8,3 L6,5 L0,4 Z "
      fill="#9CBF65"
      transform="translate(192,6)"
    />
    <path
      d="M0,0 L3,1 L3,5 L-1,4 Z "
      fill="#7FA268"
      transform="translate(126,105)"
    />
  </svg>
)
export default Chip5
