import React from 'react'
const Lol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 200 200"
    {...props}
  >
    <path
      d="M0,0 L200,0 L200,200 L0,200 Z "
      fill="#FEFEFE"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L38,0 L39,1 L39,121 L41,121 L40,6 L43,6 L43,8 L56,8 L71,12 L87,20 L96,27 L105,36 L116,51 L120,60 L123,72 L124,77 L124,96 L122,104 L120,104 L119,110 L115,119 L112,126 L110,129 L108,141 L104,152 L101,164 L4,164 L3,155 L16,130 L17,129 L18,30 L16,30 L16,127 L10,138 L9,138 L8,21 L7,143 L2,138 L-6,130 L-14,119 L-20,108 L-24,97 L-25,92 L-25,68 L-21,54 L-13,39 L-4,29 L4,22 L4,11 L0,3 Z "
      fill="#C59F5A"
      transform="translate(52,20)"
    />
    <path
      d="M0,0 L5,1 L12,3 L21,4 L27,7 L27,9 L38,17 L45,24 L48,28 L48,30 L50,30 L55,41 L59,54 L58,75 L56,82 L51,94 L44,104 L39,110 L-9,110 L-9,2 L0,2 Z "
      fill="#01263D"
      transform="translate(103,37)"
    />
    <path
      d="M0,0 L37,0 L38,1 L38,121 L40,121 L39,6 L42,6 L42,8 L55,8 L70,12 L86,20 L95,27 L104,36 L115,51 L119,60 L122,72 L123,77 L123,96 L121,104 L120,104 L120,75 L118,68 L118,90 L114,103 L112,111 L106,122 L103,126 L104,129 L102,135 L100,135 L100,130 L98,134 L92,134 L92,136 L88,138 L87,141 L91,143 L91,148 L87,147 L84,141 L82,143 L78,140 L76,139 L74,138 L70,139 L67,139 L68,137 L63,136 L63,138 L59,139 L59,136 L62,136 L59,133 L47,132 L47,131 L38,130 L37,124 L36,124 L35,105 L31,103 L33,100 L35,100 L35,92 L32,91 L33,86 L33,76 L35,73 L30,72 L29,68 L28,71 L25,71 L25,69 L27,69 L27,64 L31,63 L32,67 L33,63 L33,59 L31,59 L30,61 L28,61 L29,57 L21,60 L21,58 L21,57 L20,57 L20,63 L18,65 L18,27 L16,27 L8,12 L4,4 L4,2 L29,2 L0,1 Z M50,17 L50,19 L41,19 L41,127 L89,127 L98,116 L103,107 L107,98 L108,92 L109,71 L103,53 L100,47 L98,47 L96,43 L89,35 L79,27 L77,25 L73,22 L68,21 L56,19 L54,17 Z "
      fill="#B39E6F"
      transform="translate(53,20)"
    />
    <path
      d="M0,0 L3,3 L4,8 L4,30 L2,41 L-2,51 L-6,59 L-8,62 L-10,74 L-14,85 L-17,97 L-114,97 L-115,88 L-113,86 L-110,84 L-108,80 L-107,81 L-107,79 L-105,79 L-105,74 L-103,74 L-103,72 L-97,71 L-97,69 L-95,67 L-99,65 L-98,57 L-97,53 L-93,49 L-84,52 L-86,53 L-86,57 L-82,57 L-81,65 L-80,64 L-80,56 L-78,59 L-79,63 L-43,63 L-43,64 L-58,65 L-55,67 L-55,69 L-58,69 L-58,72 L-54,71 L-54,69 L-48,69 L-48,72 L-46,70 L-41,70 L-39,72 L-38,74 L-34,74 L-32,73 L-29,80 L-27,79 L-27,76 L-31,74 L-29,73 L-30,70 L-25,69 L-25,67 L-19,66 L-18,64 L-39,64 L-39,63 L-17,63 L-17,68 L-15,68 L-14,62 L-13,60 L-23,60 L-23,59 L-14,59 L-12,55 L-5,42 L-3,34 L0,23 Z "
      fill="#A58140"
      transform="translate(170,87)"
    />
    <path
      d="M0,0 L6,2 L10,8 L13,8 L15,8 L17,14 L19,14 L19,29 L18,38 L15,39 L15,42 L21,42 L21,49 L22,48 L29,48 L30,50 L33,42 L35,42 L35,40 L38,39 L38,48 L36,55 L31,67 L24,77 L19,83 L-29,83 L-29,38 L-27,38 L-27,30 L-20,36 L-17,38 L-7,39 L5,40 L7,37 L7,22 L10,22 L10,16 L10,12 L6,6 L0,2 Z "
      fill="#023852"
      transform="translate(123,64)"
    />
    <path
      d="M0,0 L2,4 L2,113 L-3,108 L-11,100 L-19,89 L-24,81 L-27,67 L-28,62 L-28,40 L-24,39 L-23,40 L-16,19 L-6,6 L-2,1 Z "
      fill="#226E7A"
      transform="translate(57,50)"
    />
    <path
      d="M0,0 L37,0 L38,2 L37,50 L37,124 L36,124 L35,105 L31,103 L33,100 L35,100 L35,92 L32,91 L33,86 L33,76 L35,73 L30,72 L29,68 L28,71 L25,71 L25,69 L27,69 L27,64 L31,63 L32,67 L33,63 L33,59 L31,59 L30,61 L28,61 L29,57 L21,60 L21,58 L21,57 L20,57 L20,63 L18,65 L18,27 L16,27 L8,12 L4,4 L4,2 L29,2 L0,1 Z "
      fill="#EFDD9A"
      transform="translate(53,20)"
    />
    <path
      d="M0,0 L3,3 L4,8 L4,30 L2,41 L-2,51 L-6,59 L-8,62 L-10,74 L-14,85 L-17,97 L-114,97 L-115,88 L-113,86 L-113,89 L-24,89 L-22,77 L-18,64 L-39,64 L-39,63 L-17,63 L-17,68 L-15,68 L-14,62 L-13,60 L-23,60 L-23,59 L-14,59 L-12,55 L-5,42 L-3,34 L0,23 Z "
      fill="#5F6060"
      transform="translate(170,87)"
    />
    <path
      d="M0,0 L2,0 L2,81 L1,81 L1,55 L-3,54 L-7,48 L-6,56 L-2,58 L-4,62 L-6,62 L-9,69 L-11,76 L-14,75 L-18,64 L-20,55 L-21,40 L-18,31 L-16,23 L-12,15 L-11,11 L-6,7 Z "
      fill="#014F6B"
      transform="translate(57,54)"
    />
    <path
      d="M0,0 L5,2 L10,7 L15,6 L18,5 L18,7 L24,5 L35,3 L39,1 L42,0 L40,4 L32,10 L28,10 L28,7 L19,10 L17,12 L19,13 L30,14 L29,16 L18,18 L18,20 L20,20 L20,23 L10,25 L15,26 L15,28 L22,28 L24,29 L24,32 L40,29 L49,28 L45,30 L36,34 L30,39 L29,41 L38,40 L42,41 L42,44 L26,45 L0,45 Z "
      fill="#0A7990"
      transform="translate(94,102)"
    />
    <path
      d="M0,0 L1,0 L2,117 L7,107 L8,10 L10,10 L10,109 L-2,131 L-5,137 L-2,138 L-4,139 L-4,144 L93,144 L95,132 L100,118 L101,109 L104,105 L107,98 L108,102 L105,109 L102,110 L103,117 L100,125 L97,137 L94,147 L-5,147 L-8,145 L-8,143 L-13,144 L-13,136 L-10,129 L-7,128 L-7,124 L-5,124 L-9,120 L-10,114 L-3,120 L-2,121 L-1,1 Z "
      fill="#F2EDDC"
      transform="translate(60,40)"
    />
    <path
      d="M0,0 L1,0 L1,119 L3,119 L2,4 L5,4 L5,6 L18,6 L33,10 L45,16 L43,17 L31,12 L24,10 L5,8 L5,14 L12,13 L22,13 L39,19 L44,20 L44,22 L41,22 L52,30 L60,38 L63,43 L68,48 L73,61 L75,70 L75,86 L73,95 L70,105 L64,116 L56,122 L54,126 L2,126 L0,121 L-1,47 Z M13,15 L13,17 L4,17 L4,125 L52,125 L61,114 L66,105 L70,96 L71,90 L72,69 L66,51 L63,45 L61,45 L59,41 L52,33 L42,25 L40,23 L36,20 L31,19 L19,17 L17,15 Z "
      fill="#76938C"
      transform="translate(90,22)"
    />
    <path
      d="M0,0 L6,2 L10,6 L10,8 L7,8 L7,10 L14,9 L22,10 L23,19 L22,21 L25,18 L24,16 L27,15 L30,24 L33,23 L32,28 L46,28 L46,29 L-16,29 L-15,25 L-13,20 L-11,21 L-11,19 L-9,19 L-9,14 L-7,14 L-7,12 L-1,11 L-1,9 L1,7 L-3,5 L-3,3 Z "
      fill="#946F32"
      transform="translate(74,147)"
    />
    <path
      d="M0,0 L5,0 L5,3 L9,3 L9,6 L16,4 L14,9 L6,15 L3,15 L4,17 L11,17 L11,19 L34,19 L38,21 L30,32 L25,38 L3,38 L3,37 L19,37 L19,34 L6,34 L8,30 L16,24 L20,22 L9,25 L1,25 L1,22 L-8,21 L-8,19 L-14,19 L-13,17 L-3,16 L-3,13 L-5,13 L-5,11 L6,8 L-4,7 L-7,4 Z "
      fill="#055D77"
      transform="translate(117,109)"
    />
    <path
      d="M0,0 L7,2 L9,1 L11,2 L12,5 L19,9 L19,92 L18,92 L17,73 L13,71 L15,68 L17,68 L17,60 L14,59 L15,54 L15,44 L17,41 L12,40 L11,36 L10,39 L7,39 L7,37 L9,37 L9,32 L13,31 L14,35 L15,31 L15,27 L13,27 L12,29 L10,29 L11,25 L3,28 L3,26 L3,25 L2,25 L2,31 L0,33 Z "
      fill="#DABF7C"
      transform="translate(71,52)"
    />
    <path
      d="M0,0 L47,0 L48,1 L55,2 L55,4 L63,4 L64,8 L67,9 L68,12 L71,12 L70,15 L59,13 L46,12 L46,10 L43,10 L44,20 L44,125 L42,125 L41,5 L3,4 L6,13 L1,12 L0,9 L1,6 L-1,5 Z "
      fill="#FAF9F6"
      transform="translate(49,16)"
    />
    <path
      d="M0,0 L11,0 L22,2 L36,7 L47,14 L57,23 L66,35 L71,45 L74,54 L74,62 L69,62 L65,47 L60,37 L55,32 L48,24 L38,16 L35,13 L39,14 L39,12 L32,11 L17,6 L0,6 Z "
      fill="#B0A27C"
      transform="translate(95,30)"
    />
    <path
      d="M0,0 L1,2 L-2,11 L-3,19 L-5,23 L-31,23 L-32,15 L-31,14 L-34,12 L-32,8 L-38,7 L-37,5 L-35,5 L-35,3 L-29,3 L-29,6 L-27,4 L-22,4 L-20,6 L-19,8 L-15,8 L-13,7 L-10,14 L-8,13 L-8,10 L-12,8 L-10,7 L-11,4 L-6,3 L-6,1 Z "
      fill="#B89049"
      transform="translate(151,153)"
    />
    <path
      d="M0,0 L1,0 L1,17 L-4,22 L-14,34 L-21,53 L-22,59 L-26,56 L-28,66 L-26,67 L-28,67 L-26,84 L-24,91 L-25,95 L-29,84 L-30,79 L-30,55 L-26,41 L-18,26 L-9,16 L-1,9 Z "
      fill="#DFD0A6"
      transform="translate(57,33)"
    />
    <path
      d="M0,0 L3,1 L4,20 L7,32 L11,42 L17,52 L22,57 L22,59 L28,61 L28,74 L23,69 L15,61 L7,50 L2,42 L-1,28 L-2,23 L-2,1 Z "
      fill="#B69C66"
      transform="translate(31,89)"
    />
    <path
      d="M0,0 L1,0 L1,82 L-4,91 L-5,91 L-5,15 L-3,15 L-2,10 L0,10 Z "
      fill="#9C7535"
      transform="translate(67,65)"
    />
    <path
      d="M0,0 L1,0 L1,81 L0,81 L-1,55 L-2,42 L-3,40 L-4,42 L-8,40 L-9,36 L-9,29 L-12,29 L-13,34 L-17,40 L-18,38 L-15,29 L-13,29 L-12,23 L-9,22 L-9,18 L-11,18 L-10,14 L-7,10 L-5,10 L-4,5 L-3,7 L-1,7 Z "
      fill="#042B43"
      transform="translate(58,54)"
    />
    <path
      d="M0,0 L65,0 L64,7 L62,7 L62,2 L60,6 L54,6 L54,8 L50,10 L49,13 L53,15 L53,20 L49,19 L46,13 L44,15 L40,12 L38,11 L36,10 L32,11 L29,11 L30,9 L25,8 L25,10 L21,11 L21,8 L24,8 L21,5 L9,4 L9,3 L0,2 Z "
      fill="#D5AF65"
      transform="translate(91,148)"
    />
    <path
      d="M0,0 L7,4 L17,13 L23,21 L28,26 L33,39 L35,48 L35,64 L33,73 L30,83 L24,94 L16,100 L13,103 L13,100 L20,92 L25,83 L29,74 L30,68 L31,47 L25,29 L23,26 L23,23 L21,23 L14,14 L6,7 L-1,2 Z "
      fill="#72ACA9"
      transform="translate(130,44)"
    />
    <path
      d="M0,0 L1,2 L0,7 L-3,7 L-4,10 L-9,15 L-10,18 L-8,18 L-8,22 L-10,23 L-12,29 L-14,30 L-16,39 L-12,33 L-11,29 L-8,29 L-6,40 L-3,42 L-2,39 L0,42 L0,55 L-3,54 L-6,45 L-10,40 L-11,46 L-20,46 L-21,40 L-18,31 L-16,23 L-12,15 L-11,11 L-6,7 Z "
      fill="#063C55"
      transform="translate(57,54)"
    />
    <path
      d="M0,0 L50,0 L83,1 L84,4 L-7,4 L-7,1 Z "
      fill="#4E4D4D"
      transform="translate(66,178)"
    />
    <path
      d="M0,0 L11,0 L22,2 L36,7 L47,14 L53,19 L53,24 L49,22 L39,14 L39,12 L32,11 L17,6 L0,6 Z "
      fill="#E5DAA3"
      transform="translate(95,30)"
    />
    <path
      d="M0,0 L2,0 L2,5 L5,6 L5,10 L3,10 L3,8 L0,8 L1,18 L1,123 L-1,123 L-1,4 Z "
      fill="#C3DBDB"
      transform="translate(92,18)"
    />
    <path
      d="M0,0 L6,4 L9,7 L14,6 L17,5 L17,7 L23,5 L34,3 L38,1 L41,0 L39,4 L31,10 L27,10 L27,7 L18,10 L16,12 L18,13 L29,14 L28,16 L19,17 L19,15 L15,14 L8,14 L6,15 L14,17 L14,18 L5,17 L3,16 L3,18 L13,20 L15,22 L6,21 L0,18 L0,12 L6,12 L5,8 L2,5 Z "
      fill="#15A5B7"
      transform="translate(95,102)"
    />
    <path
      d="M0,0 L2,1 L4,10 L5,14 L5,19 L8,24 L7,37 L5,38 L4,42 L2,42 L3,119 L1,120 L0,3 Z "
      fill="#D6BB86"
      transform="translate(60,38)"
    />
    <path
      d="M0,0 L22,0 L23,9 L18,28 L-73,28 L-76,27 L-74,23 L-74,26 L15,26 L17,14 L21,1 L0,1 Z "
      fill="#918674"
      transform="translate(131,150)"
    />
    <path
      d="M0,0 L2,0 L2,99 L-2,106 L-5,106 L-3,100 L-1,97 Z "
      fill="#EFDAA3"
      transform="translate(68,50)"
    />
    <path
      d="M0,0 L3,3 L4,8 L4,30 L2,41 L-2,51 L-5,54 L-7,46 L-5,42 L-3,34 L0,23 Z "
      fill="#656666"
      transform="translate(170,87)"
    />
    <path
      d="M0,0 L5,2 L13,8 L22,17 L33,32 L37,41 L40,53 L41,58 L41,77 L39,85 L38,85 L38,56 L36,49 L34,52 L32,40 L24,24 L15,13 L6,5 L0,1 Z "
      fill="#96928B"
      transform="translate(135,39)"
    />
    <path
      d="M0,0 L1,0 L1,29 L-2,45 L-3,50 L-1,51 L-4,55 L-5,53 L-5,15 L-3,15 L-2,10 L0,10 Z "
      fill="#B58D4B"
      transform="translate(67,65)"
    />
    <path
      d="M0,0 L7,6 L10,8 L20,9 L32,10 L37,6 L40,6 L40,4 L42,4 L41,9 L34,11 L30,13 L20,15 L16,15 L16,13 L11,16 L7,15 L0,9 Z "
      fill="#034A67"
      transform="translate(96,94)"
    />
    <path
      d="M0,0 L1,4 L-2,11 L-5,12 L-4,19 L-7,27 L-10,39 L-13,47 L-111,47 L-111,46 L-14,46 L-12,34 L-7,20 L-6,11 L-3,7 Z "
      fill="#C0BFBF"
      transform="translate(167,138)"
    />
    <path
      d="M0,0 L45,1 L43,2 L44,5 L41,5 L3,4 L6,13 L1,12 L0,9 L1,6 L-1,5 Z "
      fill="#ECE6D4"
      transform="translate(49,16)"
    />
    <path
      d="M0,0 L0,3 L-2,6 L-4,18 L-8,29 L-11,41 L-108,41 L-109,35 L-107,35 L-107,40 L-12,40 L-12,33 L-10,33 L-9,25 L-6,16 L-5,6 Z "
      fill="#929292"
      transform="translate(164,143)"
    />
    <path
      d="M0,0 L1,0 L1,81 L0,81 L-1,55 L-2,42 L-2,7 Z "
      fill="#323A3B"
      transform="translate(58,54)"
    />
    <path
      d="M0,0 L1,2 L-4,18 L-7,19 L-8,19 L-11,21 L-16,16 L-15,11 L-17,11 L-16,9 L-13,7 L-10,14 L-8,13 L-8,10 L-12,8 L-10,7 L-11,4 L-6,3 L-6,1 Z "
      fill="#C9A358"
      transform="translate(151,153)"
    />
    <path
      d="M0,0 L4,5 L8,11 L14,11 L17,14 L17,16 L23,18 L23,31 L18,26 L10,18 L2,7 L0,3 Z "
      fill="#8C754B"
      transform="translate(36,132)"
    />
    <path
      d="M0,0 L1,0 L1,74 L0,74 L-1,55 L-5,53 L-3,50 L-1,50 L-1,42 L-4,41 L-3,36 L-3,26 L-2,25 L-1,18 L-1,3 Z "
      fill="#DAC68A"
      transform="translate(89,70)"
    />
    <path
      d="M0,0 L1,4 L-9,15 L-11,15 L-12,21 L-16,29 L-19,41 L-20,43 L-22,43 L-22,59 L-23,59 L-23,42 L-21,33 L-15,17 L-2,1 Z "
      fill="#4A8F91"
      transform="translate(57,50)"
    />
    <path
      d="M0,0 L65,0 L64,7 L62,7 L62,2 L38,3 L38,5 L36,5 L36,2 L0,2 Z "
      fill="#DBDABD"
      transform="translate(91,148)"
    />
    <path
      d="M0,0 L2,1 L4,10 L11,10 L11,18 L6,19 L5,31 L2,40 L1,46 L-2,46 L-4,52 L-5,50 L-1,38 L1,38 L2,30 L2,11 Z "
      fill="#F1F1F0"
      transform="translate(173,86)"
    />
    <path
      d="M0,0 L4,5 L4,7 L7,8 L7,12 L1,12 L2,27 L4,27 L4,29 L10,30 L10,33 L15,33 L15,34 L1,35 L2,45 L0,45 Z "
      fill="#046A82"
      transform="translate(94,102)"
    />
    <path
      d="M0,0 L7,0 L6,23 L2,23 L2,21 L0,21 L1,28 L-13,28 L-13,26 L-15,26 L-16,23 L-14,23 L-14,21 L-11,21 L-9,24 L-7,22 L-6,19 L-3,19 L-3,21 L-1,21 L1,17 L3,15 L5,16 L5,1 L0,1 Z "
      fill="#D6BE7F"
      transform="translate(84,21)"
    />
    <path
      d="M0,0 L2,2 L0,12 L-4,22 L-5,28 L-9,25 L-10,27 L-11,25 L-8,13 L-3,3 Z "
      fill="#DAC17B"
      transform="translate(40,64)"
    />
    <path
      d="M0,0 L3,0 L3,2 L-6,5 L-7,7 L2,7 L8,5 L5,10 L3,11 L-14,11 L-14,2 L-11,1 Z "
      fill="#11A2B4"
      transform="translate(109,135)"
    />
    <path
      d="M0,0 L7,4 L17,13 L24,22 L30,34 L33,44 L32,64 L31,64 L31,47 L25,29 L23,26 L23,23 L21,23 L14,14 L6,7 L-1,2 Z "
      fill="#3A7F8A"
      transform="translate(130,44)"
    />
    <path
      d="M0,0 L1,0 L4,16 L9,29 L13,34 L19,37 L17,34 L22,34 L22,47 L15,41 L8,32 L3,21 L0,10 Z "
      fill="#33ACB8"
      transform="translate(36,102)"
    />
    <path
      d="M0,0 L6,2 L18,11 L17,8 L20,8 L27,16 L28,19 L24,23 L20,19 L13,11 L3,3 Z "
      fill="#CCBB88"
      transform="translate(130,43)"
    />
    <path
      d="M0,0 L6,4 L5,8 L-1,10 L-3,9 L-3,11 L-5,11 L-5,16 L-7,16 L-6,21 L-8,19 L-12,26 L-13,26 L-12,19 L-3,2 Z "
      fill="#B48B46"
      transform="translate(70,150)"
    />
    <path
      d="M0,0 L1,4 L-5,12 L-6,13 L-12,13 L-16,11 L-14,6 L-1,1 Z "
      fill="#014460"
      transform="translate(151,130)"
    />
    <path
      d="M0,0 L3,4 L3,11 L6,16 L5,29 L3,30 L2,34 L0,34 L0,24 L0,23 L0,18 L1,21 L3,21 L1,14 L0,14 Z "
      fill="#D0A860"
      transform="translate(62,46)"
    />
    <path
      d="M0,0 L3,0 L3,20 L2,20 L2,13 L-6,13 L-5,7 L-1,1 Z "
      fill="#0D5068"
      transform="translate(55,115)"
    />
    <path
      d="M0,0 L22,0 L23,9 L18,28 L16,28 L17,14 L21,1 L0,1 Z "
      fill="#92957E"
      transform="translate(131,150)"
    />
    <path
      d="M0,0 L0,3 L-4,10 L-6,13 L-6,16 L-18,15 L-16,10 L-9,5 L-9,7 L-6,8 L-2,1 Z "
      fill="#979E89"
      transform="translate(162,133)"
    />
    <path
      d="M0,0 L1,4 L-2,11 L-5,12 L-4,19 L-7,27 L-10,39 L-12,42 L-12,34 L-7,20 L-6,11 L-3,7 Z "
      fill="#D6D6D6"
      transform="translate(167,138)"
    />
    <path
      d="M0,0 L4,5 L10,14 L17,21 L23,25 L23,31 L18,26 L10,18 L2,7 L0,3 Z "
      fill="#6E5A42"
      transform="translate(36,132)"
    />
    <path
      d="M0,0 L7,6 L8,7 L7,14 L4,20 L5,22 L8,24 L6,25 L6,30 L5,26 L0,25 L2,19 L5,13 L4,8 L1,6 Z "
      fill="#C6BDB0"
      transform="translate(50,154)"
    />
    <path
      d="M0,0 L1,0 L1,19 L-2,30 L-5,27 L-7,27 L-5,2 L-4,2 L-4,18 L-5,23 L-2,20 L-1,17 Z "
      fill="#8C886C"
      transform="translate(170,91)"
    />
    <path
      d="M0,0 L2,0 L1,5 L-6,7 L-10,9 L-20,11 L-24,11 L-25,8 L-21,6 L-8,6 L-3,2 L0,2 Z "
      fill="#076C86"
      transform="translate(136,98)"
    />
    <path
      d="M0,0 L5,1 L12,3 L21,4 L27,7 L25,9 L17,7 L11,6 L11,4 L-8,4 L-8,2 L0,2 Z "
      fill="#0A4053"
      transform="translate(103,37)"
    />
    <path
      d="M0,0 L11,0 L26,4 L38,10 L36,11 L24,6 L17,4 L-2,2 Z "
      fill="#928466"
      transform="translate(97,28)"
    />
    <path
      d="M0,0 L4,0 L5,7 L1,9 L-8,8 L-6,4 L2,3 L2,1 Z "
      fill="#018399"
      transform="translate(110,134)"
    />
    <path
      d="M0,0 L1,0 L2,9 L7,9 L8,7 L6,7 L6,5 L8,5 L8,3 L10,3 L11,11 L8,12 L8,15 L0,10 L-3,5 L-1,4 Z "
      fill="#0D8FA2"
      transform="translate(47,125)"
    />
    <path
      d="M0,0 L2,1 L3,10 L3,23 L2,32 L-1,33 L0,23 Z "
      fill="#4A5255"
      transform="translate(170,87)"
    />
    <path
      d="M0,0 L0,3 L-9,10 L-13,10 L-13,7 L-19,8 L-19,6 L-6,3 L-2,1 Z "
      fill="#178CA4"
      transform="translate(135,102)"
    />
    <path
      d="M0,0 L3,4 L3,7 L5,8 L6,21 L10,21 L9,26 L6,25 L2,14 L0,5 Z "
      fill="#056F87"
      transform="translate(37,104)"
    />
    <path
      d="M0,0 L6,2 L16,10 L23,18 L24,23 L20,19 L13,11 L3,3 Z "
      fill="#98B7A1"
      transform="translate(130,43)"
    />
    <path
      d="M0,0 L2,1 L-3,5 L-1,6 L10,7 L9,9 L0,10 L0,8 L-10,6 L-10,2 L-4,2 Z "
      fill="#23BACA"
      transform="translate(114,109)"
    />
    <path
      d="M0,0 L2,4 L2,16 L-1,15 L-3,10 L-2,1 Z "
      fill="#EEE1B2"
      transform="translate(88,45)"
    />
    <path
      d="M0,0 L2,0 L2,5 L5,6 L5,10 L3,10 L3,8 L0,8 L1,23 L-1,24 L-1,4 Z "
      fill="#A7B2B5"
      transform="translate(92,18)"
    />
    <path
      d="M0,0 L4,0 L3,5 L2,6 L-6,6 L-6,3 Z "
      fill="#004460"
      transform="translate(117,109)"
    />
    <path
      d="M0,0 L1,0 L1,8 L-1,16 L-4,16 L-6,22 L-7,20 L-3,8 L-1,8 Z "
      fill="#CCCCCC"
      transform="translate(175,116)"
    />
    <path
      d="M0,0 L1,3 L-3,13 L-4,19 L-5,19 L-7,9 L-4,9 L-3,5 L-2,1 Z "
      fill="#BFBD88"
      transform="translate(39,73)"
    />
    <path
      d="M0,0 L1,4 L-8,14 L-8,11 L-7,7 L-2,1 Z "
      fill="#68A5A2"
      transform="translate(57,50)"
    />
    <path
      d="M0,0 L4,0 L4,9 L7,10 L4,13 L-2,1 Z "
      fill="#EBD187"
      transform="translate(63,30)"
    />
    <path
      d="M0,0 L2,0 L7,11 L6,15 L4,18 L2,6 Z "
      fill="#6B7374"
      transform="translate(165,73)"
    />
    <path
      d="M0,0 L2,0 L1,7 L-1,7 L-1,2 L-22,2 L-22,1 Z "
      fill="#CECFB7"
      transform="translate(154,148)"
    />
    <path
      d="M0,0 L4,4 L8,9 L9,16 L7,15 L6,10 L3,10 L0,4 Z "
      fill="#8F9393"
      transform="translate(161,63)"
    />
    <path
      d="M0,0 L1,3 L5,3 L5,6 L7,7 L3,8 L-8,7 L-8,6 L-1,4 Z "
      fill="#06758B"
      transform="translate(121,109)"
    />
    <path
      d="M0,0 L7,2 L11,11 L10,13 L2,3 Z "
      fill="#E5E6E3"
      transform="translate(151,50)"
    />
    <path
      d="M0,0 L10,1 L15,3 L14,5 L2,3 Z "
      fill="#38868C"
      transform="translate(107,37)"
    />
    <path
      d="M0,0 L11,0 L15,1 L15,3 L-2,2 Z "
      fill="#C3AB81"
      transform="translate(97,28)"
    />
    <path
      d="M0,0 L2,0 L1,8 L0,11 L-3,10 L-2,4 Z "
      fill="#4D5253"
      transform="translate(168,123)"
    />
    <path
      d="M0,0 L1,0 L0,11 L-1,15 L-3,15 L-2,5 Z "
      fill="#88AAB7"
      transform="translate(150,163)"
    />
    <path
      d="M0,0 L1,4 L-1,4 L-2,11 L-6,16 L-5,9 Z "
      fill="#D0A05E"
      transform="translate(63,160)"
    />
    <path
      d="M0,0 L7,6 L7,11 L1,6 Z "
      fill="#D6D1D0"
      transform="translate(50,154)"
    />
    <path
      d="M0,0 L3,0 L3,2 L5,3 L3,3 L3,9 L0,10 Z "
      fill="#89968E"
      transform="translate(92,26)"
    />
    <path
      d="M0,0 L5,2 L5,8 L-1,11 L1,7 L3,3 L0,2 Z "
      fill="#208293"
      transform="translate(53,107)"
    />
    <path
      d="M0,0 L4,0 L2,5 L-4,5 Z "
      fill="#997E53"
      transform="translate(151,141)"
    />
    <path
      d="M0,0 L3,3 L6,8 L6,10 L3,10 L3,8 L1,7 Z "
      fill="#E5DCD6"
      transform="translate(35,134)"
    />
    <path
      d="M0,0 L4,5 L6,9 L6,13 L4,13 L1,5 Z "
      fill="#A97025"
      transform="translate(62,39)"
    />
    <path
      d="M0,0 L3,3 L2,4 L1,19 L-1,17 Z "
      fill="#A38C61"
      transform="translate(58,35)"
    />
    <path
      d="M0,0 L5,5 L7,8 L2,8 Z "
      fill="#CEC9C4"
      transform="translate(43,146)"
    />
    <path
      d="M0,0 L3,3 L3,5 L1,5 L1,8 L-1,8 L-1,2 Z "
      fill="#055B74"
      transform="translate(41,77)"
    />
    <path
      d="M0,0 L5,5 L6,9 L1,4 Z "
      fill="#50C8D0"
      transform="translate(43,130)"
    />
    <path
      d="M0,0 L10,2 L7,4 L0,2 Z "
      fill="#0298A9"
      transform="translate(98,118)"
    />
    <path
      d="M0,0 L1,3 L-2,10 L-3,7 L-2,1 Z "
      fill="#9CB190"
      transform="translate(39,73)"
    />
  </svg>
)
export default Lol
