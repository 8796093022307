import * as React from 'react'
const Paraguay = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#FBFBFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,32 L0,32 Z "
      fill="#B92235"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,31 L0,31 Z "
      fill="#324297"
      transform="translate(0,65)"
    />
    <path
      d="M0,0 L8,0 L14,4 L17,9 L17,18 L14,23 L12,23 L11,27 L2,27 L-6,23 L-9,19 L-9,9 L-5,3 Z "
      fill="#F6F8F6"
      transform="translate(44,35)"
    />
    <path
      d="M0,0 L4,2 L7,9 L7,3 L9,5 L9,14 L6,19 L0,21 L-8,21 L-10,20 L-10,17 L-1,15 L2,10 L1,5 L1,6 L1,11 L-3,14 L-7,13 L-9,11 L-8,6 L-3,5 L-3,3 L0,2 Z "
      fill="#DADED2"
      transform="translate(52,39)"
    />
    <path
      d="M0,0 L2,0 L2,2 L-1,4 L0,9 L4,10 L7,8 L7,3 L6,1 L9,2 L10,7 L7,12 L0,14 L-3,14 L-3,17 L7,17 L11,16 L10,20 L1,20 L-7,16 L-6,13 L-3,12 L-4,3 Z "
      fill="#C1C6C1"
      transform="translate(45,42)"
    />
    <path
      d="M0,0 L5,1 L5,4 L1,6 L-7,6 L-9,5 L-9,2 Z "
      fill="#F8FAF8"
      transform="translate(51,54)"
    />
    <path
      d="M0,0 L2,0 L0,4 L-1,5 L-2,12 L-5,12 L-4,17 L-7,15 L-7,5 L-5,3 L-1,3 Z "
      fill="#C2C2C3"
      transform="translate(42,39)"
    />
    <path
      d="M0,0 L8,0 L14,4 L14,7 L9,3 L6,2 L6,5 L1,4 L-1,3 L-7,7 L-5,3 Z "
      fill="#BAB8B9"
      transform="translate(44,35)"
    />
    <path
      d="M0,0 L3,1 L1,1 L2,5 L6,5 L6,3 L9,3 L7,7 L2,8 L-1,5 Z "
      fill="#E7F2DD"
      transform="translate(44,45)"
    />
    <path
      d="M0,0 L2,0 L2,2 L-1,4 L0,9 L2,12 L-2,11 L-4,8 L-4,3 Z "
      fill="#546F50"
      transform="translate(45,42)"
    />
    <path
      d="M0,0 L3,1 L4,6 L1,11 L-1,11 L1,7 L1,2 Z "
      fill="#5D7459"
      transform="translate(51,43)"
    />
    <path
      d="M0,0 L4,2 L6,5 L6,11 L4,9 L2,4 Z "
      fill="#BEBFBE"
      transform="translate(52,39)"
    />
    <path
      d="M0,0 L2,0 L1,4 L-8,4 L-8,3 L-2,3 L-2,1 Z "
      fill="#DBDDDB"
      transform="translate(54,58)"
    />
    <path
      d="M0,0 L5,1 L5,4 L1,4 Z "
      fill="#EBD679"
      transform="translate(45,46)"
    />
  </svg>
)
export default Paraguay
