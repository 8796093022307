import React from 'react'
const SwitzerlandLeague = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    viewBox="0 0 150 150"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,150 L0,150 Z "
      fill="#F8F7F6"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L150,0 L150,150 L0,150 Z M48,9 L42,12 L33,24 L21,46 L13,63 L8,75 L7,80 L7,90 L13,98 L27,112 L38,121 L53,133 L64,140 L74,140 L83,137 L108,126 L127,116 L138,109 L143,103 L143,84 L138,58 L133,38 L128,25 L122,20 L112,16 L92,12 L68,9 Z "
      fill="#FEFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L20,0 L44,3 L64,7 L74,11 L80,16 L85,29 L91,53 L95,75 L95,94 L89,101 L64,115 L45,124 L34,129 L26,131 L16,131 L4,123 L-6,115 L-17,106 L-29,95 L-38,86 L-41,81 L-41,71 L-38,61 L-29,41 L-18,20 L-10,8 L-5,2 Z M4,6 L-3,8 L-7,13 L-11,17 L-19,31 L-22,38 L-24,44 L-32,58 L-36,74 L-35,83 L-27,90 L-15,102 L-4,111 L6,119 L13,121 L16,122 L16,124 L19,125 L26,125 L36,121 L41,118 L41,115 L45,116 L54,113 L58,109 L56,108 L56,106 L48,106 L48,95 L56,95 L58,106 L65,106 L64,95 L63,93 L43,93 L43,100 L44,100 L44,95 L46,96 L46,105 L39,104 L38,103 L37,95 L40,95 L39,89 L35,89 L35,97 L29,97 L28,98 L28,103 L33,104 L35,100 L34,104 L28,104 L27,98 L29,96 L34,95 L34,93 L29,93 L29,77 L27,79 L27,92 L25,92 L25,96 L23,96 L24,104 L21,98 L19,99 L18,106 L15,105 L6,105 L6,95 L0,95 L0,103 L5,103 L5,106 L-4,106 L-5,102 L-3,101 L-3,95 L-1,95 L-2,89 L6,89 L6,87 L-2,84 L-2,79 L0,76 L-4,75 L-3,71 L-3,64 L-4,60 L-7,59 L-8,62 L-10,62 L-11,67 L-23,70 L-17,67 L-14,58 L-6,42 L-5,38 L-7,35 L-6,31 L-5,30 L0,30 L2,36 L1,40 L7,42 L14,42 L22,39 L31,37 L35,34 L54,29 L53,31 L43,34 L36,38 L24,47 L25,51 L33,55 L44,58 L47,62 L58,63 L58,70 L55,71 L59,71 L60,64 L63,67 L64,69 L66,69 L66,64 L68,64 L68,75 L71,74 L70,69 L72,69 L73,76 L75,77 L74,84 L72,88 L75,93 L67,92 L68,95 L71,94 L71,102 L73,101 L73,99 L83,98 L84,95 L88,94 L88,72 L84,56 L83,55 L81,39 L77,25 L72,16 L61,13 L58,11 L46,9 L28,6 Z M74,77 Z M59,98 L60,100 L58,99 Z "
      fill="#FBF1ED"
      transform="translate(48,9)"
    />
    <path
      d="M0,0 L20,0 L44,3 L64,7 L74,11 L80,16 L85,29 L91,53 L95,75 L95,94 L89,101 L64,115 L45,124 L34,129 L26,131 L16,131 L4,123 L-6,115 L-17,106 L-29,95 L-38,86 L-41,81 L-41,71 L-38,61 L-29,41 L-18,20 L-10,8 L-5,2 Z M4,6 L-3,8 L-7,13 L-11,17 L-19,31 L-22,38 L-24,44 L-32,58 L-36,74 L-35,83 L-27,90 L-15,102 L-4,111 L6,119 L13,121 L16,122 L16,124 L19,125 L26,125 L36,121 L41,118 L41,115 L45,116 L57,113 L66,108 L75,103 L85,98 L90,94 L90,77 L85,51 L82,40 L79,32 L76,22 L72,16 L61,13 L58,11 L46,9 L28,6 Z "
      fill="#D75E25"
      transform="translate(48,9)"
    />
    <path
      d="M0,0 L18,0 L18,2 L22,0 L36,0 L36,5 L27,5 L27,7 L34,7 L34,10 L26,10 L26,13 L35,13 L35,17 L20,17 L21,19 L24,20 L24,30 L16,29 L14,27 L14,19 L17,19 L16,13 L12,13 L13,17 L12,21 L6,22 L6,27 L10,27 L11,25 L8,24 L12,24 L11,29 L5,29 L3,26 L4,21 L6,19 L11,19 L11,17 L6,17 L5,3 L4,16 L2,16 L2,20 L0,20 L2,29 L-1,26 L-3,23 L-5,30 L-17,29 L-17,19 L-23,19 L-23,27 L-18,27 L-18,30 L-27,30 L-28,27 L-30,25 L-26,25 L-26,19 L-24,19 L-26,13 L-23,12 L-17,13 L-17,11 L-25,9 L-26,8 L-26,3 L-24,1 L-15,1 L-15,5 L-22,4 L-21,6 L-15,8 L-12,11 L-11,1 L-7,1 L-6,11 L-2,12 L-1,11 Z "
      fill="#BFBFBF"
      transform="translate(71,85)"
    />
    <path
      d="M0,0 L20,0 L36,2 L34,4 L2,5 L-3,7 L-10,17 L-18,31 L-21,38 L-23,44 L-31,58 L-35,74 L-35,83 L-31,85 L-15,101 L-4,110 L6,118 L18,126 L28,125 L36,122 L46,117 L54,114 L59,114 L58,118 L35,128 L26,131 L16,131 L4,123 L-6,115 L-17,106 L-29,95 L-38,86 L-41,81 L-41,71 L-38,61 L-29,41 L-18,20 L-10,8 L-5,2 Z "
      fill="#BC1721"
      transform="translate(48,9)"
    />
    <path
      d="M0,0 L2,2 L8,31 L9,37 L9,54 L3,59 L-6,63 L-15,68 L-24,73 L-27,72 L-24,70 L-26,68 L-25,66 L-30,67 L-33,66 L-33,55 L-25,55 L-23,60 L-23,66 L-17,65 L-18,53 L-23,53 L-23,49 L-32,49 L-32,46 L-24,46 L-24,43 L-31,43 L-31,41 L-22,41 L-23,34 L-48,34 L-49,27 L-50,30 L-52,30 L-52,34 L-55,34 L-55,24 L-54,23 L-43,24 L-46,25 L-46,28 L-43,29 L-46,29 L-46,32 L-40,32 L-40,24 L-38,24 L-37,31 L-34,30 L-36,28 L-35,24 L-31,25 L-34,25 L-34,28 L-30,30 L-29,23 L-23,23 L-22,27 L-22,29 L-21,24 L-18,25 L-17,29 L-15,29 L-15,24 L-13,24 L-12,33 L-13,35 L-11,34 L-11,29 L-9,29 L-9,33 L-5,34 L-8,34 L-8,36 L-5,37 L-6,44 L-8,48 L-6,53 L-14,52 L-12,54 L-10,54 L-9,61 L-8,59 L2,57 L3,55 L6,54 L6,32 L2,16 L1,15 Z M-7,37 Z M-22,58 L-21,60 L-23,59 Z "
      fill="#F7F7F8"
      transform="translate(129,49)"
    />
    <path
      d="M0,0 L5,0 L8,5 L7,10 L12,12 L19,12 L27,9 L36,7 L40,4 L55,0 L59,0 L58,3 L48,6 L41,10 L30,18 L31,22 L38,25 L49,28 L49,30 L29,29 L15,24 L0,22 L-5,28 L-11,40 L-16,42 L-19,41 L-12,37 L-8,24 L-2,13 L-1,9 L-3,6 L-2,2 Z "
      fill="#DB7645"
      transform="translate(43,38)"
    />
    <path
      d="M0,0 L1,4 L1,14 L4,17 L11,17 L14,16 L30,16 L31,13 L32,17 L40,20 L40,22 L32,22 L33,28 L31,28 L31,34 L29,35 L31,39 L31,42 L37,44 L40,44 L42,52 L37,50 L23,38 L11,27 L1,17 L-1,16 L-2,7 Z "
      fill="#F3F4F4"
      transform="translate(14,76)"
    />
    <path
      d="M0,0 L11,1 L8,2 L8,5 L11,6 L8,6 L8,9 L14,9 L14,1 L16,1 L17,8 L20,7 L18,5 L19,1 L23,2 L20,2 L20,5 L24,7 L25,0 L31,0 L32,4 L32,6 L33,1 L36,2 L37,6 L39,6 L39,1 L41,1 L42,10 L41,12 L43,11 L43,6 L45,6 L45,10 L49,11 L46,11 L46,13 L49,14 L48,17 L45,15 L34,14 L34,29 L38,30 L31,30 L31,26 L22,26 L22,23 L30,23 L30,20 L23,20 L23,18 L32,18 L31,11 L6,11 L5,4 L4,7 L2,7 L2,11 L-1,11 L-1,1 Z M47,14 Z "
      fill="#F0E2E3"
      transform="translate(75,72)"
    />
    <path
      d="M0,0 L9,0 L9,4 L2,3 L3,5 L9,7 L14,12 L17,15 L24,15 L28,11 L28,15 L26,15 L26,19 L24,19 L26,28 L23,25 L21,22 L19,29 L7,28 L7,18 L1,18 L1,26 L6,26 L6,29 L-3,29 L-4,26 L-6,24 L-2,24 L-2,18 L0,18 L-2,12 L1,11 L7,12 L7,10 L-1,8 L-2,7 L-2,2 Z "
      fill="#EEEFEF"
      transform="translate(47,86)"
    />
    <path
      d="M0,0 L24,4 L34,7 L42,12 L45,16 L46,20 L44,23 L45,37 L43,34 L40,23 L36,16 L36,14 L27,13 L22,10 L10,8 L-8,5 L-32,5 L-38,6 L-43,12 L-43,9 L-39,4 L-34,2 L-2,1 Z "
      fill="#D56956"
      transform="translate(84,11)"
    />
    <path
      d="M0,0 L6,0 L8,4 L9,1 L14,1 L19,2 L19,12 L14,12 L10,10 L10,14 L14,13 L20,13 L20,15 L24,14 L25,15 L25,25 L23,27 L19,26 L18,25 L18,15 L14,15 L14,25 L12,27 L9,22 L3,20 L3,18 L10,18 L10,16 L2,15 L4,13 L3,9 L2,12 L0,12 Z "
      fill="#EADDDE"
      transform="translate(46,71)"
    />
    <path
      d="M0,0 L4,0 L3,3 L-7,6 L-14,10 L-25,18 L-24,22 L-17,25 L-6,28 L-6,30 L-26,29 L-31,27 L-34,24 L-34,20 L-36,19 L-34,19 L-34,17 L-31,14 L-34,13 L-27,10 L-18,6 L-12,3 Z "
      fill="#E1431C"
      transform="translate(98,38)"
    />
    <path
      d="M0,0 L2,0 L2,19 L-2,20 L-6,18 L-6,21 L-8,21 L-8,18 L-10,18 L-11,24 L-16,26 L-17,34 L-19,34 L-20,38 L-27,53 L-29,53 L-30,58 L-31,54 L-23,38 L-21,36 L-19,30 L-15,20 L-7,7 L-2,3 Z "
      fill="#F2F4F1"
      transform="translate(46,16)"
    />
    <path
      d="M0,0 L1,4 L-4,12 L-9,22 L-12,35 L-12,44 L-10,45 L-16,45 L-18,42 L-18,32 L-15,22 L-6,2 L-1,3 Z "
      fill="#BB404C"
      transform="translate(25,48)"
    />
    <path
      d="M0,0 L1,4 L1,14 L6,18 L13,26 L17,28 L20,28 L20,32 L24,32 L28,32 L28,30 L31,29 L31,34 L29,35 L31,39 L31,42 L37,44 L40,44 L42,52 L37,50 L23,38 L11,27 L1,17 L-1,16 L-2,7 Z "
      fill="#DEC9C9"
      transform="translate(14,76)"
    />
    <path
      d="M0,0 L11,1 L8,2 L8,5 L11,6 L8,6 L8,9 L14,9 L14,1 L16,1 L17,8 L20,7 L18,5 L19,1 L23,2 L20,2 L20,5 L24,7 L25,1 L30,1 L27,5 L30,6 L27,6 L27,9 L31,9 L31,11 L6,11 L5,4 L4,7 L2,7 L2,11 L-1,11 L-1,1 Z "
      fill="#D48E94"
      transform="translate(75,72)"
    />
    <path
      d="M0,0 L6,0 L9,4 L8,10 L6,12 L-2,12 L-4,11 L-4,13 L-7,12 L-7,10 L-26,15 L-28,15 L-29,12 L-24,13 L-24,11 L-5,8 L-4,2 Z "
      fill="#F6DAD9"
      transform="translate(109,28)"
    />
    <path
      d="M0,0 L11,0 L14,3 L13,8 L12,11 L14,16 L9,16 L7,12 L4,11 L4,15 L0,15 Z "
      fill="#2E2D2F"
      transform="translate(109,86)"
    />
    <path
      d="M0,0 L3,0 L4,10 L6,10 L7,16 L6,18 L8,18 L9,25 L10,25 L11,34 L14,34 L14,36 L12,36 L13,44 L16,43 L16,50 L15,55 L16,57 L16,77 L13,78 L13,57 L8,31 L3,11 L0,3 Z "
      fill="#FEE8D3"
      transform="translate(129,27)"
    />
    <path
      d="M0,0 L5,0 L4,4 L-19,14 L-24,16 L-36,16 L-37,14 L-26,13 L-26,11 L-18,8 L-8,3 Z "
      fill="#D3271E"
      transform="translate(102,123)"
    />
    <path
      d="M0,0 L5,1 L13,6 L16,10 L17,14 L15,17 L16,31 L14,28 L11,17 L7,10 L7,8 L-2,7 L-7,3 L1,3 Z "
      fill="#E59A7E"
      transform="translate(113,17)"
    />
    <path
      d="M0,0 L2,1 L-3,5 L-4,8 L-6,8 L-5,15 L-2,17 L-5,18 L-16,14 L-20,11 L-18,11 L-19,3 L-8,3 Z "
      fill="#DF5C1C"
      transform="translate(70,47)"
    />
    <path
      d="M0,0 L8,0 L10,5 L10,11 L23,9 L18,13 L9,18 L6,17 L9,15 L7,13 L8,11 L3,12 L0,11 Z M11,3 L12,5 L10,4 Z "
      fill="#E6E1DE"
      transform="translate(96,104)"
    />
    <path
      d="M0,0 L10,0 L13,3 L13,8 L10,11 L4,11 L4,15 L0,15 Z "
      fill="#353637"
      transform="translate(78,86)"
    />
    <path
      d="M0,0 L4,0 L5,10 L9,11 L10,10 L11,0 L15,0 L15,11 L11,16 L4,16 L0,12 Z "
      fill="#3B3D3D"
      transform="translate(60,86)"
    />
    <path
      d="M0,0 L9,0 L9,4 L2,3 L3,5 L9,7 L11,9 L10,14 L8,16 L-1,15 L-1,11 L7,12 L7,10 L-1,8 L-2,7 L-2,2 Z "
      fill="#3C3C3D"
      transform="translate(47,86)"
    />
    <path
      d="M0,0 L12,0 L12,3 L3,3 L3,6 L11,6 L11,9 L3,9 L3,12 L12,12 L12,15 L0,15 Z "
      fill="#3C3D3D"
      transform="translate(94,86)"
    />
    <path
      d="M0,0 L8,0 L8,2 L2,2 L2,4 L13,3 L13,1 L17,3 L19,10 L16,7 L14,4 L12,11 L0,10 Z "
      fill="#9B9C9A"
      transform="translate(54,104)"
    />
    <path
      d="M0,0 L2,2 L8,31 L9,37 L9,54 L3,59 L-3,61 L-1,58 L2,57 L3,55 L6,54 L6,32 L2,16 L1,15 Z "
      fill="#D3BDB6"
      transform="translate(129,49)"
    />
    <path
      d="M0,0 L4,0 L3,3 L-7,6 L-14,10 L-22,15 L-21,10 L-26,10 L-22,8 L-12,3 Z "
      fill="#C32226"
      transform="translate(98,38)"
    />
    <path
      d="M0,0 L1,0 L1,8 L20,7 L26,8 L26,10 L13,11 L9,12 L9,10 L-9,10 L-8,6 L-6,6 L-6,8 L-3,8 L-3,4 L-1,4 Z "
      fill="#FEF7F5"
      transform="translate(80,75)"
    />
    <path
      d="M0,0 L10,2 L14,7 L-5,6 L-26,2 L-26,1 L-8,2 L-2,3 L-2,1 Z "
      fill="#D7866A"
      transform="translate(102,14)"
    />
    <path
      d="M0,0 L2,1 L-1,2 L-2,5 L-6,6 L-14,6 L-15,10 L-21,10 L-22,7 L-24,7 L-24,9 L-27,9 L-28,5 L-28,3 L-27,1 L-22,4 L-12,4 L-3,1 Z M-29,7 Z "
      fill="#FEF7F6"
      transform="translate(86,135)"
    />
    <path
      d="M0,0 L2,1 L0,3 L-10,7 L-18,11 L-26,13 L-32,13 L-42,6 L-38,5 L-34,8 L-34,10 L-24,10 L-14,6 L-10,4 L-9,1 Z "
      fill="#C98B8B"
      transform="translate(98,123)"
    />
    <path
      d="M0,0 L1,3 L-2,9 L-4,9 L-6,14 L-10,27 L-12,27 L-13,31 L-20,46 L-22,46 L-23,51 L-24,47 L-16,31 L-14,29 L-12,23 L-8,13 Z "
      fill="#DBCAC9"
      transform="translate(39,23)"
    />
    <path
      d="M0,0 L20,0 L36,2 L34,4 L12,4 L0,1 Z "
      fill="#D83823"
      transform="translate(48,9)"
    />
    <path
      d="M0,0 L2,0 L2,2 L-4,5 L-6,5 L-6,8 L-11,8 L-12,15 L-16,15 L-16,13 L-28,14 L-26,12 L-6,3 Z "
      fill="#FEEDE8"
      transform="translate(116,121)"
    />
    <path
      d="M0,0 L5,0 L10,1 L10,11 L5,11 L1,9 L1,11 L-1,11 Z "
      fill="#E1ACAA"
      transform="translate(55,72)"
    />
    <path
      d="M0,0 L5,0 L8,7 L10,13 L5,13 L3,9 L-1,8 L-1,11 L-3,11 L-3,6 L3,6 L0,5 Z "
      fill="#48484A"
      transform="translate(113,89)"
    />
    <path
      d="M0,0 L4,1 L1,1 L1,4 L5,6 L6,0 L11,0 L8,4 L11,5 L8,5 L8,8 L12,8 L12,10 L-1,10 L-1,8 L3,8 L3,6 L-1,4 Z "
      fill="#BE6066"
      transform="translate(94,73)"
    />
    <path
      d="M0,0 L4,0 L4,3 L6,3 L6,7 L4,7 L4,10 L0,10 L-3,7 L-3,3 L0,3 Z "
      fill="#FCECF3"
      transform="translate(110,29)"
    />
    <path
      d="M0,0 L5,1 L10,5 L15,5 L17,13 L12,11 L0,1 Z "
      fill="#E9E4E0"
      transform="translate(39,115)"
    />
    <path
      d="M0,0 L3,0 L3,8 L8,8 L8,11 L-1,11 L-2,8 L-4,6 L0,6 Z "
      fill="#9FA0A2"
      transform="translate(45,104)"
    />
    <path
      d="M0,0 L12,0 L12,3 L3,3 L2,2 L1,14 L12,13 L12,15 L0,15 Z "
      fill="#1F2121"
      transform="translate(94,86)"
    />
    <path
      d="M0,0 L3,4 L2,8 L0,8 L-1,19 L-4,20 L-4,11 L-1,1 Z "
      fill="#B43645"
      transform="translate(11,69)"
    />
    <path
      d="M0,0 L6,0 L9,4 L8,10 L6,12 L0,12 L-3,9 L-3,3 Z M1,1 L1,4 L-2,4 L-2,8 L1,11 L5,11 L5,8 L7,8 L7,4 L5,4 L5,1 Z "
      fill="#B44443"
      transform="translate(109,28)"
    />
    <path
      d="M0,0 L13,2 L13,5 L15,5 L19,15 L20,26 L18,23 L15,12 L11,5 L11,3 L2,2 Z "
      fill="#CAAA9D"
      transform="translate(109,22)"
    />
    <path
      d="M0,0 L3,1 L2,7 L-3,15 L-10,16 L-7,13 L-3,11 Z "
      fill="#BB4625"
      transform="translate(34,64)"
    />
    <path
      d="M0,0 L5,0 L6,1 L6,7 L5,8 L0,8 L-1,1 Z "
      fill="#D95723"
      transform="translate(43,38)"
    />
    <path
      d="M0,0 L1,0 L1,9 L-1,8 L-1,7 L-6,8 L-7,7 L-7,2 Z "
      fill="#E4E4E4"
      transform="translate(83,105)"
    />
    <path
      d="M0,0 L4,2 L4,7 L2,9 L-6,9 L-6,7 L-1,6 L-1,3 L-5,1 Z "
      fill="#313134"
      transform="translate(87,87)"
    />
    <path
      d="M0,0 L2,1 L-1,2 L-2,5 L-6,6 L-24,6 L-25,3 L-22,4 L-12,4 L-3,1 Z "
      fill="#FAD1CC"
      transform="translate(86,135)"
    />
    <path
      d="M0,0 L8,0 L10,3 L10,10 L9,7 L-1,7 L-1,4 L7,4 L7,1 L0,1 Z "
      fill="#E2E2E3"
      transform="translate(98,91)"
    />
    <path
      d="M0,0 L5,0 L6,5 L6,10 L3,7 L2,10 L0,10 Z "
      fill="#C9615F"
      transform="translate(46,73)"
    />
    <path
      d="M0,0 L1,4 L-4,8 L-7,8 L-8,12 L-16,12 L-11,8 L-2,2 Z "
      fill="#FDE8D6"
      transform="translate(141,105)"
    />
    <path
      d="M0,0 L13,4 L19,6 L19,8 L5,7 L7,5 L0,2 Z "
      fill="#AD2522"
      transform="translate(73,60)"
    />
    <path
      d="M0,0 L2,1 L0,3 L-10,7 L-18,9 L-17,7 L-10,4 L-9,1 Z "
      fill="#D8B8B5"
      transform="translate(98,123)"
    />
    <path
      d="M0,0 L1,2 L3,2 L3,10 L-1,11 L-2,10 L-2,2 L0,2 Z "
      fill="#F7F8F9"
      transform="translate(67,87)"
    />
    <path
      d="M0,0 L8,0 L8,2 L2,2 L2,5 L7,5 L7,6 L2,6 L2,9 L8,9 L8,10 L0,10 Z "
      fill="#585A57"
      transform="translate(54,104)"
    />
    <path
      d="M0,0 L5,0 L6,6 L3,6 L3,10 L0,10 Z "
      fill="#D77F7E"
      transform="translate(67,73)"
    />
    <path
      d="M0,0 L4,1 L4,8 L-1,8 L-3,4 Z "
      fill="#EF9772"
      transform="translate(42,50)"
    />
    <path
      d="M0,0 L8,0 L8,2 L2,2 L2,5 L7,5 L7,6 L2,6 L2,9 L7,9 L7,10 L0,10 Z "
      fill="#5D5C5D"
      transform="translate(96,104)"
    />
    <path
      d="M0,0 L1,4 L-4,12 L-6,14 L-6,9 L-4,9 L-4,6 L-8,7 L-6,2 L-1,3 Z "
      fill="#BD4655"
      transform="translate(25,48)"
    />
    <path
      d="M0,0 L1,0 L2,9 L4,13 L-3,12 L-3,6 Z "
      fill="#F8DFE3"
      transform="translate(6,81)"
    />
    <path
      d="M0,0 L25,3 L25,4 L4,4 L0,2 Z "
      fill="#F9D2CB"
      transform="translate(66,65)"
    />
    <path
      d="M0,0 L3,0 L5,10 L0,8 L0,10 L-2,10 Z "
      fill="#C95A60"
      transform="translate(56,73)"
    />
    <path
      d="M0,0 L4,0 L3,3 L-7,6 L-10,7 L-11,4 L-2,1 Z "
      fill="#922027"
      transform="translate(98,38)"
    />
    <path
      d="M0,0 L2,0 L0,6 L-4,17 L-6,21 L-6,14 L-4,6 L-2,6 L-2,2 L0,2 Z "
      fill="#FBE1D4"
      transform="translate(37,54)"
    />
    <path
      d="M0,0 L2,0 L2,2 L6,1 L7,2 L7,12 L6,12 L6,5 L1,5 L1,12 L0,12 Z "
      fill="#CDD0D1"
      transform="translate(64,84)"
    />
    <path
      d="M0,0 L1,3 L-2,9 L-4,9 L-6,14 L-9,21 L-10,17 Z "
      fill="#C7898F"
      transform="translate(39,23)"
    />
    <path
      d="M0,0 L1,0 L2,7 L6,8 L7,0 L9,0 L9,10 L1,9 L0,8 Z "
      fill="#676767"
      transform="translate(86,105)"
    />
    <path
      d="M0,0 L2,0 L4,6 L5,14 L6,15 L6,24 L5,24 L2,9 Z "
      fill="#C27A56"
      transform="translate(128,42)"
    />
    <path
      d="M0,0 L5,0 L2,1 L2,4 L5,4 L5,6 L3,6 L3,10 L0,10 Z "
      fill="#CA6D72"
      transform="translate(74,73)"
    />
    <path
      d="M0,0 L2,0 L3,9 L-1,10 L-2,5 L0,5 Z "
      fill="#D69896"
      transform="translate(114,73)"
    />
    <path
      d="M0,0 L1,2 L9,2 L9,5 L1,5 L0,8 Z "
      fill="#2A2A2C"
      transform="translate(96,90)"
    />
    <path
      d="M0,0 L2,0 L-1,10 L-4,14 L-5,5 L-2,5 Z "
      fill="#FDE0E3"
      transform="translate(14,56)"
    />
    <path
      d="M0,0 L7,6 L10,10 L4,9 L1,5 Z "
      fill="#FDE6DF"
      transform="translate(10,95)"
    />
    <path
      d="M0,0 L3,1 L-4,11 L-6,13 L-6,8 L-4,8 L-4,5 L-2,5 L-1,1 Z "
      fill="#FCD4D7"
      transform="translate(37,15)"
    />
    <path
      d="M0,0 L2,1 L-4,12 L-6,9 L-6,7 L-4,7 L-3,3 L0,2 Z "
      fill="#FDDCDD"
      transform="translate(27,32)"
    />
    <path
      d="M0,0 L1,3 L6,4 L7,0 L9,0 L9,4 L7,4 L7,7 L3,7 L0,4 Z "
      fill="#FDD2D1"
      transform="translate(107,32)"
    />
    <path
      d="M0,0 L6,0 L9,4 L9,8 L7,8 L7,4 L5,4 L5,1 L1,1 L0,4 L-2,2 Z "
      fill="#D68A89"
      transform="translate(109,28)"
    />
    <path
      d="M0,0 L5,0 L6,4 L0,5 Z "
      fill="#CECED0"
      transform="translate(113,89)"
    />
    <path
      d="M0,0 L2,0 L1,6 L-6,9 L-4,5 Z "
      fill="#DED9D3"
      transform="translate(46,16)"
    />
    <path
      d="M0,0 L1,4 L-1,9 L-3,9 L-4,14 L-5,10 Z "
      fill="#C99FA0"
      transform="translate(20,60)"
    />
    <path
      d="M0,0 L6,1 L7,8 L0,2 Z "
      fill="#A10914"
      transform="translate(14,97)"
    />
    <path
      d="M0,0 L3,1 L4,5 L3,5 L2,10 L0,10 Z "
      fill="#CA5F61"
      transform="translate(108,73)"
    />
    <path
      d="M0,0 L2,1 L-3,11 L-4,8 L-3,3 Z "
      fill="#FCDFDF"
      transform="translate(20,45)"
    />
    <path
      d="M0,0 L10,0 L7,2 L-3,4 L-3,2 Z "
      fill="#FBD6D5"
      transform="translate(69,45)"
    />
    <path
      d="M0,0 L2,0 L0,8 L-4,10 L-2,4 Z "
      fill="#E7DEDC"
      transform="translate(35,32)"
    />
    <path
      d="M0,0 L2,0 L3,3 L-5,5 L-9,4 Z "
      fill="#FBD0C9"
      transform="translate(97,130)"
    />
    <path
      d="M0,0 L2,1 L-4,6 L-7,5 L-5,1 Z "
      fill="#E0D7D5"
      transform="translate(126,107)"
    />
    <path
      d="M0,0 L5,1 L4,5 L0,5 Z "
      fill="#D8D8DB"
      transform="translate(82,89)"
    />
    <path
      d="M0,0 L2,0 L0,5 L-5,7 L-8,6 L-1,2 Z "
      fill="#A33430"
      transform="translate(32,73)"
    />
    <path
      d="M0,0 L2,0 L1,6 L-2,6 L-3,8 L-3,1 Z "
      fill="#F8BEC1"
      transform="translate(86,73)"
    />
    <path
      d="M0,0 L5,1 L9,7 L5,5 Z "
      fill="#C4938B"
      transform="translate(39,115)"
    />
    <path
      d="M0,0 L2,0 L0,4 L-9,2 L-9,1 Z "
      fill="#FBE2DE"
      transform="translate(90,62)"
    />
    <path
      d="M0,0 L4,1 L8,5 L5,5 Z "
      fill="#CB8F91"
      transform="translate(48,122)"
    />
    <path
      d="M0,0 L3,0 L2,5 L-2,3 L-7,2 L-7,1 Z "
      fill="#D4D6D2"
      transform="translate(63,107)"
    />
  </svg>
)
export default SwitzerlandLeague
