import * as React from 'react'
const Panama = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#D01F37"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L48,0 L48,48 L0,48 Z "
      fill="#015391"
      transform="translate(0,48)"
    />
    <path
      d="M0,0 L48,0 L48,48 L0,48 Z "
      fill="#FDFDFD"
      transform="translate(48,48)"
    />
    <path
      d="M0,0 L48,0 L48,48 L0,48 Z "
      fill="#FDFDFE"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L2,3 L2,6 L9,6 L9,8 L5,10 L6,16 L9,16 L9,27 L16,27 L20,29 L-27,29 L-27,28 L-7,27 L5,27 L5,21 L-2,21 L-2,19 L-6,19 L-6,12 L-6,9 L-8,8 L-8,6 L-2,5 Z M7,25 Z "
      fill="#EDF8FC"
      transform="translate(27,19)"
    />
    <path
      d="M0,0 L3,1 L4,5 L11,6 L13,11 L13,17 L10,18 L10,16 L8,16 L8,19 L5,20 L3,18 L-1,19 L-1,17 L-5,17 L-3,10 L-7,8 L-7,7 L-1,7 Z "
      fill="#FEF0F3"
      transform="translate(68,60)"
    />
    <path
      d="M0,0 L2,3 L2,6 L9,6 L9,8 L5,10 L6,17 L0,14 L-5,17 L-4,12 L-3,10 L-8,8 L-8,6 L-2,5 Z "
      fill="#07528F"
      transform="translate(27,19)"
    />
    <path
      d="M0,0 L2,3 L3,6 L8,7 L6,10 L6,17 L2,15 L-3,16 L-5,17 L-3,10 L-7,8 L-7,7 L-1,7 Z "
      fill="#BD3850"
      transform="translate(68,60)"
    />
    <path
      d="M0,0 L2,2 L1,8 L-4,9 L-1,11 L-2,19 L-4,19 L-4,17 L-6,17 L-7,11 L-11,9 L-9,5 L-4,6 L-2,4 Z "
      fill="#FDF2F3"
      transform="translate(66,59)"
    />
    <path
      d="M0,0 L2,3 L2,6 L9,6 L9,8 L3,9 L-1,8 L-8,8 L-8,6 L-2,5 Z "
      fill="#386991"
      transform="translate(27,19)"
    />
    <path
      d="M0,0 L2,2 L3,8 L-3,8 L-3,5 L-7,3 L-7,2 Z "
      fill="#C72040"
      transform="translate(68,65)"
    />
    <path
      d="M0,0 L2,0 L3,4 L10,5 L11,10 L7,10 L7,6 L2,6 Z "
      fill="#F8D3DA"
      transform="translate(69,61)"
    />
    <path
      d="M0,0 L8,1 L9,6 L3,3 L-2,6 L-1,1 Z "
      fill="#2D6088"
      transform="translate(24,30)"
    />
    <path
      d="M0,0 L2,2 L1,8 L-6,9 L-6,7 L-2,4 Z "
      fill="#F5CDD0"
      transform="translate(66,59)"
    />
  </svg>
)
export default Panama
