import * as React from 'react'
const HandCashIcon = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9993 27.804C11.5017 27.8037 9.05092 27.126 6.90817 25.8426C4.76539 24.5595 3.01085 22.7191 1.83146 20.5176C0.652086 18.3158 0.0920342 15.8356 0.210981 13.3408C0.329953 10.846 1.12345 8.43025 2.50694 6.35085C3.97657 4.14696 6.04708 2.41045 8.4732 1.34701L9.52384 5.61467L9.54681 5.60366C7.66758 6.60724 6.17544 8.20724 5.3053 10.1519C4.43516 12.0965 4.23643 14.2752 4.74036 16.3452C5.24432 18.4152 6.42231 20.2587 8.08897 21.5858C9.75562 22.9126 11.8162 23.6479 13.9465 23.6752L14.9452 27.7722C14.6306 27.793 14.3126 27.804 13.9993 27.804ZM19.6048 26.6184L18.6112 22.5489C20.1209 21.7388 21.3861 20.5389 22.2751 19.0742C23.164 17.6095 23.6443 15.9334 23.6662 14.2202L18.6801 15.438C18.446 16.2341 18.0102 16.9562 17.4152 17.5348C16.8027 18.1272 16.0408 18.5422 15.2109 18.7352C14.8353 18.8278 14.4499 18.8747 14.0631 18.8749C13.3504 18.8757 12.6465 18.7175 12.0025 18.4124C11.3586 18.1069 10.7907 17.662 10.3402 17.1097C9.88973 16.5574 9.56794 15.9118 9.39818 15.2196C9.22845 14.5275 9.21503 13.8061 9.35892 13.1081C9.50282 12.4101 9.80039 11.7529 10.23 11.1843C10.6597 10.6157 11.2106 10.15 11.8427 9.8209C12.4749 9.49182 13.1725 9.30773 13.8847 9.28195C14.5969 9.25619 15.3059 9.38939 15.9602 9.67193C16.5515 9.92692 17.0858 10.2976 17.5315 10.7623L22.5175 9.54445C21.7037 8.0301 20.4981 6.76209 19.0269 5.8728C17.5555 4.98354 15.8724 4.50562 14.1533 4.48902L14.1765 4.47802L13.1297 0.223242C13.4197 0.205317 13.7123 0.196289 13.9993 0.196289C16.4932 0.196705 18.9406 0.872634 21.0812 2.15232C23.2218 3.43201 24.9758 5.26764 26.1567 7.46419C27.3378 9.66077 27.9019 12.1362 27.7889 14.6276C27.676 17.1191 26.8903 19.5334 25.5154 21.6139C24.0608 23.8099 22.0107 25.546 19.605 26.6187L19.6048 26.6184Z"
      fill="#1CF567"
    />
  </svg>
)
export default HandCashIcon
