import * as React from 'react'
const Bolivia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#F0D339"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,32 L0,32 Z "
      fill="#3A7E40"
      transform="translate(0,64)"
    />
    <path
      d="M0,0 L96,0 L96,31 L0,31 Z "
      fill="#B92232"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L31,0 L31,21 L-8,21 L-5,19 L-3,19 L-3,13 L-2,9 L-3,4 Z "
      fill="#F1D535"
      transform="translate(65,42)"
    />
    <path
      d="M0,0 L15,1 L18,7 L19,11 L20,8 L25,10 L25,18 L23,23 L16,24 L15,23 L8,23 L5,24 L2,24 L2,26 L7,25 L7,28 L60,29 L60,30 L-36,30 L-36,29 L4,29 L4,27 L-1,26 L-1,22 L-5,22 L-4,17 L-2,17 L-4,11 L-5,8 L-4,4 L0,3 Z "
      fill="#E9D746"
      transform="translate(36,34)"
    />
    <path
      d="M0,0 L5,1 L8,1 L8,5 L11,9 L11,14 L10,17 L12,18 L12,5 L14,4 L18,6 L18,14 L16,19 L9,20 L8,19 L1,19 L-2,20 L-6,18 L-8,14 L-7,6 L-3,4 L-2,7 L-3,14 L-2,18 L2,17 L3,14 L5,15 L6,12 L8,12 L7,8 L1,8 L1,13 L-1,14 L-1,8 L0,4 Z "
      fill="#5B8051"
      transform="translate(43,38)"
    />
    <path
      d="M0,0 L7,0 L10,6 L12,18 L11,21 L8,20 L9,10 L6,9 L7,6 L5,5 L3,5 L0,4 L0,10 L-1,11 L-1,16 L0,11 L6,10 L7,15 L4,18 L2,18 L-1,21 L-3,21 L-5,17 L-4,8 L-7,9 L-9,13 L-10,7 L-5,5 L-3,6 L-3,2 Z "
      fill="#D2BB53"
      transform="translate(44,35)"
    />
    <path
      d="M0,0 L5,2 L5,10 L3,15 L-4,16 L-5,13 L-1,14 L-1,1 Z "
      fill="#A04821"
      transform="translate(56,42)"
    />
    <path
      d="M0,0 L2,1 L1,10 L2,14 L6,13 L4,16 L-2,14 L-4,10 L-3,2 Z "
      fill="#9B4019"
      transform="translate(39,42)"
    />
    <path
      d="M0,0 L5,1 L5,5 L2,8 L0,8 L-3,11 L-4,9 L-2,1 Z "
      fill="#98AF5D"
      transform="translate(46,45)"
    />
    <path
      d="M0,0 L2,3 L4,15 L3,18 L0,17 L1,7 L-2,6 Z "
      fill="#C6C370"
      transform="translate(52,38)"
    />
    <path
      d="M0,0 L7,1 L8,0 L15,0 L16,2 L5,4 L2,4 Z "
      fill="#B8A340"
      transform="translate(36,56)"
    />
    <path
      d="M0,0 L5,1 L5,4 L-1,4 Z "
      fill="#D6DD8E"
      transform="translate(46,45)"
    />
    <path
      d="M0,0 L2,1 L2,5 L-3,6 L-4,4 L-2,4 Z "
      fill="#2C3A18"
      transform="translate(49,38)"
    />
  </svg>
)
export default Bolivia
