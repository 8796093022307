import React from 'react'
const Denmark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#CCBDC1"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L2,0 L2,20 L28,20 L28,25 L2,25 L2,46 L-4,44 L-5,26 L-18,25 L-18,20 L-5,19 L-4,2 Z "
      fill="#FDFCFC"
      transform="translate(20,2)"
    />
    <path
      d="M0,0 L1,2 L-2,10 L10,10 L10,11 L-3,11 L-3,16 L10,16 L11,17 L11,35 L15,36 L25,36 L33,32 L38,27 L43,17 L44,17 L44,24 L38,32 L32,37 L27,39 L13,39 L5,35 L-3,26 L-5,21 L-5,7 L-2,1 Z "
      fill="#BDC8CD"
      transform="translate(5,11)"
    />
    <path
      d="M0,0 L25,0 L24,7 L19,14 L13,18 L8,20 L0,20 Z "
      fill="#EC1A21"
      transform="translate(22,28)"
    />
    <path
      d="M0,0 L8,0 L16,4 L23,11 L25,15 L25,19 L0,19 Z "
      fill="#ED1A20"
      transform="translate(22,2)"
    />
    <path
      d="M0,0 L12,0 L12,17 L8,15 L3,10 L0,4 Z "
      fill="#ED151B"
      transform="translate(3,28)"
    />
    <path
      d="M0,0 L2,0 L2,16 L-10,16 L-9,10 L-2,2 Z "
      fill="#ED151A"
      transform="translate(13,5)"
    />
    <path
      d="M0,0 L1,0 L1,18 L3,19 L3,22 L-5,18 L-7,15 L-1,17 Z "
      fill="#D2ADB1"
      transform="translate(15,28)"
    />
    <path
      d="M0,0 L6,2 L10,6 L13,17 L11,16 L7,8 L0,1 Z "
      fill="#B2D2D6"
      transform="translate(36,4)"
    />
    <path
      d="M0,0 L3,4 L6,10 L11,15 L13,18 L9,16 L4,13 L0,3 Z "
      fill="#B1D7DB"
      transform="translate(1,28)"
    />
  </svg>
)
export default Denmark
