import * as React from 'react'
const Wallet = (props) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 14.5H16.51M3 5.5V19.5C3 20.6046 3.89543 21.5 5 21.5H19C20.1046 21.5 21 20.6046 21 19.5V9.5C21 8.39543 20.1046 7.5 19 7.5L5 7.5C3.89543 7.5 3 6.60457 3 5.5ZM3 5.5C3 4.39543 3.89543 3.5 5 3.5H17M17 14.5C17 14.7761 16.7761 15 16.5 15C16.2239 15 16 14.7761 16 14.5C16 14.2239 16.2239 14 16.5 14C16.7761 14 17 14.2239 17 14.5Z"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Wallet
