import React from 'react'
const GolfBall = (props) => (
  <svg
    height="800px"
    width="800px"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 442.143 442.143"
    xmlSpace="preserve"
    {...props}
  >
    <g transform="translate(0 -1020.36)">
      <g
        shapeRendering="auto"
        imageRendering="auto"
        colorRendering="auto"
        colorInterpolation="sRGB"
      >
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M49.656,1028.986L49.656,1028.986H0l78.281,144.844l14.812-7.969l15.094,28.063l-14.781,7.938 l23.5,43.469c3.903,7.229,11.604,11.031,19.719,11.656v1.844c-0.008,17.057,11.264,32.121,27.625,37.438l32.875,10.721 l2.219-8.063c6.458-24.191,28.197-41.01,53.25-41.031c30.316,0.014,54.851,24.27,55.25,54.563l0.128,6.875l14.688,1.873 l70.188-12.469h-0.469c12.585-1.52,24.404-6.789,33.906-15.063c4.234-3.4,7.502-7.869,9.438-12.938l0.25-0.336l0.096-0.496 c10.34-40.32,7.438-82.766-8.375-121.344l-0.128-0.16l-0.813-1.969c-5.844-14.834-20.304-24.604-36.375-24.846 c-3.058,0-6.116,0.561-8.969,1.656l-182.656,71.906c-10.634,4.189-22.77,0.672-29.25-8.406l-117.5-164.469l-2.344-3.374 L49.656,1028.986z"
        />
        <path
          style={{
            fill: '#FFFFFF',
          }}
          d="M252.602,1258.038c-30.617,0-55.251,24.635-55.251,55.252c0,30.617,24.635,55.252,55.251,55.252 c30.617-0.002,55.251-24.635,55.251-55.252C307.853,1282.675,283.218,1258.038,252.602,1258.038z"
        />
        <path
          style={{
            fill: '#EBFEFF',
          }}
          d="M252.586,1269.388c24.328,0,43.917,19.561,43.917,43.887c0,18.621-11.5,34.365-27.788,40.771 c3.147-6.061,5.12-12.84,5.12-20.17c0-24.326-19.59-43.918-43.917-43.918c-5.728,0-11.153,1.168-16.161,3.146 c7.298-14.066,21.824-23.719,38.829-23.719V1269.388z"
        />
      </g>
      <g>
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M213.148,1297.484c-0.001,4.361,3.534,7.896,7.894,7.896c0,0,0.001,0,0.001,0 c4.36,0,7.895-3.535,7.895-7.895c0-0.002,0-0.002,0-0.002c0-4.359-3.535-7.895-7.895-7.895 C216.683,1289.589,213.148,1293.124,213.148,1297.484z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M244.729,1281.695c-0.001,4.361,3.534,7.896,7.894,7.896c0,0,0.001,0,0.001,0 c4.36,0,7.895-3.535,7.895-7.895c0-0.002,0-0.002,0-0.002c0.001-4.361-3.534-7.896-7.894-7.896c0,0-0.001,0-0.001,0 c-4.36,0-7.895,3.535-7.895,7.895C244.729,1281.695,244.729,1281.695,244.729,1281.695z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M276.31,1297.484c-0.001,4.361,3.534,7.896,7.894,7.896c0,0,0.001,0,0.001,0 c4.36,0,7.895-3.535,7.895-7.895c0-0.002,0-0.002,0-0.002c0-4.359-3.535-7.895-7.895-7.895 C279.845,1289.589,276.31,1293.124,276.31,1297.484z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M299.995,1313.275c-0.001,4.361,3.534,7.896,7.894,7.896c0,0,0.001,0,0.001,0 c4.36,0,7.895-3.535,7.895-7.895c0-0.002,0-0.002,0-0.002c0-4.359-3.535-7.895-7.895-7.895 C303.531,1305.38,299.996,1308.915,299.995,1313.275z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M213.148,1329.068c0,4.359,3.535,7.895,7.895,7.895c4.36,0,7.895-3.535,7.895-7.895 c0.001-4.361-3.534-7.896-7.894-7.896c0,0-0.001,0-0.001,0c-4.36,0-7.895,3.535-7.895,7.895 C213.148,1329.068,213.148,1329.068,213.148,1329.068z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M244.729,1313.275c-0.001,4.361,3.534,7.896,7.894,7.896c0,0,0.001,0,0.001,0 c4.36,0,7.895-3.535,7.895-7.895c0-0.002,0-0.002,0-0.002c0-4.359-3.535-7.895-7.895-7.895 C248.264,1305.38,244.729,1308.915,244.729,1313.275z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M276.31,1329.068c0,4.359,3.535,7.895,7.895,7.895c4.36,0,7.895-3.535,7.895-7.895 c0.001-4.361-3.534-7.896-7.894-7.896c0,0-0.001,0-0.001,0c-4.36,0-7.895,3.535-7.895,7.895 C276.31,1329.068,276.31,1329.068,276.31,1329.068z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M244.729,1344.855c-0.001,4.361,3.534,7.896,7.894,7.896c0,0,0.001,0,0.001,0 c4.36,0,7.895-3.535,7.895-7.895c0-0.002,0-0.002,0-0.002c0-4.359-3.535-7.895-7.895-7.895 C248.264,1336.96,244.729,1340.495,244.729,1344.855z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M276.31,1360.648c0,4.359,3.535,7.895,7.895,7.895c4.36,0,7.895-3.535,7.895-7.895 c0.001-4.361-3.534-7.896-7.894-7.896c0,0-0.001,0-0.001,0c-4.36,0-7.895,3.535-7.895,7.895 C276.31,1360.648,276.31,1360.648,276.31,1360.648z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M213.148,1360.648c0,4.359,3.535,7.895,7.895,7.895c4.36,0,7.895-3.535,7.895-7.895 c0.001-4.361-3.534-7.896-7.894-7.896c0,0-0.001,0-0.001,0c-4.36,0-7.895,3.535-7.895,7.895 C213.148,1360.648,213.148,1360.648,213.148,1360.648z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M213.148,1265.904c0,4.359,3.535,7.895,7.895,7.895c4.36,0,7.895-3.535,7.895-7.895 c0.001-4.361-3.534-7.896-7.894-7.896c0,0-0.001,0-0.001,0c-4.36,0-7.895,3.535-7.895,7.895 C213.148,1265.904,213.148,1265.904,213.148,1265.904z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M276.31,1265.904c0,4.359,3.535,7.895,7.895,7.895c4.36,0,7.895-3.535,7.895-7.895 c0.001-4.361-3.534-7.896-7.894-7.896c0,0-0.001,0-0.001,0c-4.36,0-7.895,3.535-7.895,7.895 C276.31,1265.904,276.31,1265.904,276.31,1265.904z"
        />
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M189.462,1313.275c-0.001,4.361,3.534,7.896,7.894,7.896c0,0,0.001,0,0.001,0 c4.36,0,7.895-3.535,7.895-7.895c0-0.002,0-0.002,0-0.002c0-4.359-3.535-7.895-7.895-7.895 C192.997,1305.38,189.462,1308.915,189.462,1313.275z"
        />
        <path
          shapeRendering="auto"
          imageRendering="auto"
          colorRendering="auto"
          colorInterpolation="sRGB"
          style={{
            fill: '#5A3392',
          }}
          d=" M252.59,1240.783c14.984,0,28.885,4.717,40.375,12.689h-80.688C223.74,1245.499,237.605,1240.783,252.59,1240.783z  M294.84,1368.47c-1.784,1.328-3.564,2.65-5.469,3.811c-6.78,10.348-15.292,27.41-15.781,28.404l-7.469,53.279H239.09 l-7.469-53.498c-0.632-1.217-9.23-17.488-15.969-28.281c-1.845-1.137-3.582-2.432-5.313-3.721h84.5L294.84,1368.47z"
        />
      </g>
      <g
        shapeRendering="auto"
        imageRendering="auto"
        colorRendering="auto"
        colorInterpolation="sRGB"
      >
        <g>
          <path
            style={{
              fill: '#29A3EC',
            }}
            d="M265.504,1381.531c-3.869,7.107-6.785,12.768-6.785,12.768l-0.585,1.137l-5.829,41.172 l-5.705-40.803l-0.585-1.057c0,0-2.708-5.824-6.6-13.199c4.2,0.783,8.504,1.297,12.922,1.297c4.513,0,8.889-0.545,13.169-1.328 L265.504,1381.531z"
          />
          <path
            style={{
              fill: '#3CDEF6',
            }}
            d="M265.504,1381.531c-4.28,0.783-8.656,1.328-13.169,1.328c-0.032,0-0.059,0-0.096,0v53.322 l0.059,0.416l5.829-41.172l0.585-1.137c0,0,2.916-5.658,6.785-12.768L265.504,1381.531z"
          />
          <path
            style={{
              fill: '#FB9761',
            }}
            d="M41.505,1044.843H26.468l104.325,193.031c1.393,2.576,4.199,4.316,7.343,4.316h14.337v16.668 c-0.005,10.145,6.612,19.113,16.669,22.379l22.963,7.461c11.242-25.305,32.134-45.424,60.497-45.447 c35.908,0.014,63.32,26.898,67.957,61.65l1.749,0.24l67.957-12.006l0.23-0.16c9.467-1.137,18.324-5.02,25.411-11.189l0.23-0.16 l0.122-0.16c1.998-1.553,3.46-3.684,4.43-5.943c9.381-36.889,6.751-75.66-7.693-110.969v-0.16l-0.933-2.098v-0.16 c-3.44-8.73-12.037-14.629-21.797-14.803c-1.079-0.006-2.152,0.176-3.147,0.607l-182.539,71.92 c-17.298,6.814-37.164,1.072-47.908-13.988L41.503,1044.665L41.505,1044.843z"
          />
          <path
            style={{
              fill: '#FFFFFF',
            }}
            d="M397.724,1192.88c3.795,0.012,7.059,2.691,7.81,6.412c0,0,2.693,11.773,3.38,19.232h-0.122 c0.744,7.996,1.176,16.297,0.351,24.713c-1.032,10.506-5.712,27.16-5.712,27.16c-0.73,4.355-4.851,7.293-9.206,6.564 c-4.355-0.73-7.293-4.852-6.564-9.207c0.082-0.49,0.21-0.975,0.383-1.441c0,0,4.436-17.539,5.129-24.594 c0.685-6.975,0.454-14.281-0.23-21.682c-0.448-4.846-3.03-17.367-3.03-17.367c-0.992-4.303,1.691-8.594,5.993-9.586 C396.502,1192.947,397.112,1192.878,397.724,1192.88L397.724,1192.88z"
          />
        </g>
        <path
          style={{
            fill: '#5A3392',
          }}
          d="M250.259,1242.888c25.409-0.848,50.212,12.15,63.5,35.344 c17.717,30.924,9.456,70.385-19.188,91.594c-28.643,21.207-68.783,17.6-93.188-8.375c-3.17-3.078-3.245-8.143-0.167-11.314 c3.078-3.17,8.143-3.244,11.314-0.166c0.178,0.174,0.348,0.354,0.509,0.543c18.938,20.154,49.774,22.924,72,6.469 c22.226-16.457,28.592-46.783,14.844-70.781c-13.748-23.998-43.126-33.854-68.563-23s-38.653,38.873-30.844,65.404 c1.404,4.189-0.855,8.725-5.044,10.129c-4.19,1.402-8.724-0.855-10.128-5.045c-0.064-0.193-0.122-0.387-0.171-0.584 c-10.064-34.189,7.126-70.639,39.906-84.625c8.195-3.496,16.749-5.313,25.219-5.594V1242.888z"
        />
      </g>
    </g>
  </svg>
)
export default GolfBall
