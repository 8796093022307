import React from 'react'
const FranceLigue2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={171}
    viewBox="0 0 150 171"
    {...props}
  >
    <path
      d="M0 0 C49.5 0 99 0 150 0 C150 56.43 150 112.86 150 171 C100.5 171 51 171 0 171 C0 114.57 0 58.14 0 0 Z "
      fill="#091D3E"
      transform="translate(0,0)"
    />
    <path
      d="M0 0 C-0.7 3.8 -2.37 6.83 -4.25 10.19 C-5.12 11.79 -5.12 11.79 -6.02 13.42 C-8 16 -8 16 -10.31 16.88 C-13.49 17.02 -16.63 16.94 -19.81 16.81 C-25.98 16.77 -29.98 17.44 -35 21 C-36.05 21.7 -37.1 22.4 -38.19 23.12 C-42.76 27.23 -45.52 32.77 -46.41 38.81 C-46.65 46.63 -45.01 52.56 -40.19 58.69 C-39.47 59.78 -38.74 60.87 -38 62 C-39.1 65.87 -41.18 69.3 -43.12 72.81 C-43.66 73.79 -44.2 74.76 -44.76 75.77 C-45.17 76.51 -45.58 77.24 -46 78 C-51.95 76.95 -55.26 72.39 -58.68 67.7 C-65.7 56.75 -67.97 44.62 -65.34 31.79 C-61.7 19.57 -53.97 9.91 -43.14 3.24 C-29.3 -4.17 -14.83 -4.9 0 0 Z "
      fill="#1AFBD2"
      transform="translate(97,23)"
    />
    <path
      d="M0 0 C7.36 3.04 12.95 10.87 16.25 17.92 C20.73 30.3 20.34 42.93 15 55 C12.95 58.61 10.65 61.81 8 65 C7.48 65.65 6.95 66.29 6.41 66.96 C-1.36 75.64 -13.74 80.26 -25.16 81.28 C-32.83 81.5 -39.67 80.22 -47 78 C-42.93 65.25 -42.93 65.25 -38 61 C-33.08 59.82 -27.9 60.59 -22.9 60.94 C-16.78 61.14 -11.91 57.74 -7.54 53.75 C-2.28 47.77 -0.64 40.76 -0.71 32.99 C-1.4 25.84 -4.44 20.42 -9 15 C-7.69 12.69 -6.38 10.37 -5.06 8.06 C-4.69 7.4 -4.32 6.74 -3.93 6.06 C-1.11 1.11 -1.11 1.11 0 0 Z "
      fill="#1CFBD3"
      transform="translate(103,36)"
    />
    <path
      d="M0 0 C0.5 1.48 0.5 1.48 1 3 C2.32 3 3.64 3 5 3 C5 4.65 5 6.3 5 8 C6.3 7.94 6.3 7.94 7.62 7.88 C11.27 8.01 13.73 8.37 17 10 C18.25 13.06 18.25 13.06 19 16 C19.66 16.33 20.32 16.66 21 17 C21 16.34 21 15.68 21 15 C21.66 15 22.32 15 23 15 C23 15.66 23 16.32 23 17 C24.98 17 26.96 17 29 17 C29 18.32 29 19.64 29 21 C28.34 21 27.68 21 27 21 C26.19 24.07 26 26.03 26.56 29.19 C26.71 30.12 26.85 31.04 27 32 C26.01 32.99 26.01 32.99 25 34 C24.85 33.45 24.71 32.91 24.55 32.35 C21.87 23.32 17.03 16.23 10 10 C9.34 10 8.68 10 8 10 C5.36 14.62 2.72 19.24 0 24 C0.95 25.36 1.9 26.72 2.88 28.12 C7.6 35.29 8.85 41.45 8 50 C6.98 52.84 6.98 52.84 6 55 C5.67 54.34 5.34 53.68 5 53 C4.34 53 3.68 53 3 53 C2.67 54.98 2.34 56.96 2 59 C0.56 59.17 -0.87 59.33 -2.31 59.5 C-3.11 59.59 -3.91 59.69 -4.74 59.78 C-7.89 60.09 -9.96 60.01 -13 59 C-13.69 56.94 -13.69 56.94 -14 55 C-14.58 55.05 -15.16 55.09 -15.76 55.14 C-21.4 55.4 -24.59 54.88 -29 51 C-32.53 46.76 -32.27 43.29 -32 38 C-34.17 38.83 -34.17 38.83 -35 41 C-35.66 40.67 -36.32 40.34 -37 40 C-37.33 40.66 -37.66 41.32 -38 42 C-40 40.62 -40 40.62 -42 39 C-42 38.34 -42 37.68 -42 37 C-41.01 37 -40.02 37 -39 37 C-38.67 33.37 -38.34 29.74 -38 26 C-37.34 26 -36.68 26 -36 26 C-36.16 24.78 -36.33 23.57 -36.5 22.31 C-36.52 20.11 -36.52 20.11 -36 18 C-32.84 14.96 -29.11 13.37 -25 12 C-22.9 12.17 -20.79 12.34 -18.69 12.52 C-14.71 12.79 -10.89 13.05 -7 12 C-3.38 8.47 -1.63 4.75 0 0 Z "
      fill="#091F3D"
      transform="translate(95,27)"
    />
    <path
      d="M0 0 C4.01 2.04 6.62 4.2 9 8 C9.79 12.34 10.01 16.96 8.02 20.98 C5.25 24.44 2.23 26.65 -2.15 27.34 C-7.6 27.43 -10.75 26.74 -15 23 C-18.16 19.31 -18.52 15.92 -18.36 11.21 C-17.67 6.93 -15.32 4.18 -12.16 1.36 C-8.35 -1.04 -4.35 -0.63 0 0 Z "
      fill="#F4F8F9"
      transform="translate(81,55)"
    />
    <path
      d="M0 0 C0.5 0.99 0.5 0.99 1 2 C-0.49 4.99 -2.79 5.59 -5.75 7.06 C-6.8 7.59 -7.84 8.12 -8.92 8.66 C-11.67 9.86 -14.05 10.56 -17 11 C-17 11.99 -17 12.98 -17 14 C-16.34 14 -15.68 14 -15 14 C-15 14.66 -15 15.32 -15 16 C-19.14 17.38 -23.31 17.32 -27.64 17.43 C-29.87 17.5 -29.87 17.5 -33 18 C-33.66 18.99 -34.32 19.98 -35 21 C-34.21 21.05 -33.43 21.1 -32.62 21.15 C-22.99 21.83 -22.99 21.83 -19.06 23.12 C-15.68 24.09 -14.36 23.78 -11 23 C-9.06 22.64 -7.13 22.29 -5.19 21.94 C-3.72 21.67 -3.72 21.67 -2.23 21.4 C-1.13 21.2 -1.13 21.2 0 21 C0 20.34 0 19.68 0 19 C-0.66 18.67 -1.32 18.34 -2 18 C0.31 17.67 2.62 17.34 5 17 C5.33 15.68 5.66 14.36 6 13 C7.32 13 8.64 13 10 13 C10.33 11.35 10.66 9.7 11 8 C13.47 8.99 13.47 8.99 16 10 C8.28 20.15 -1.58 24.6 -14 27 C-22.78 27.91 -30.6 26.54 -39 24 C-34.93 11.25 -34.93 11.25 -30 7 C-25.08 5.82 -19.91 6.6 -14.91 6.95 C-8.92 7.11 -4.3 3.9 0 0 Z "
      fill="#26EFCF"
      transform="translate(95,90)"
    />
    <path
      d="M0 0 C0 0.66 0 1.32 0 2 C1.32 2 2.64 2 4 2 C4.07 2.96 4.07 2.96 4.15 3.93 C4.22 4.76 4.3 5.59 4.38 6.44 C4.44 7.26 4.51 8.08 4.59 8.93 C4.72 9.62 4.86 10.3 5 11 C5.66 11.33 6.32 11.66 7 12 C6.34 12 5.68 12 5 12 C4.67 14.31 4.34 16.62 4 19 C3.01 19 2.02 19 1 19 C1.19 19.64 1.37 20.28 1.56 20.94 C1.71 21.62 1.85 22.3 2 23 C1 24 1 24 -1.5 24.1 C-2.51 24.09 -3.52 24.07 -4.56 24.06 C-5.57 24.05 -6.59 24.04 -7.63 24.04 C-8.8 24.02 -8.8 24.02 -10 24 C-10 24.66 -10 25.32 -10 26 C-7.36 26 -4.72 26 -2 26 C-2 27.32 -2 28.64 -2 30 C-5.96 30 -9.92 30 -14 30 C-14 24.39 -14 18.78 -14 13 C-10.04 13 -6.08 13 -2 13 C-2.33 12.34 -2.66 11.68 -3 11 C-5.13 10.53 -5.13 10.53 -7.56 10.38 C-8.39 10.3 -9.22 10.23 -10.07 10.15 C-10.7 10.1 -11.34 10.05 -12 10 C-10.68 9.67 -9.36 9.34 -8 9 C-8.06 8.07 -8.12 7.14 -8.19 6.19 C-8 3 -8 3 -6.81 1.12 C-4.44 -0.35 -2.74 -0.22 0 0 Z "
      fill="#081D3C"
      transform="translate(104,118)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5 3.63 5 7.26 5 11 C7.64 11 10.28 11 13 11 C13.33 7.37 13.66 3.74 14 0 C15.65 0 17.3 0 19 0 C19 6.6 19 13.2 19 20 C17.02 19.67 15.04 19.34 13 19 C11.54 18.94 10.08 18.91 8.62 18.94 C6.83 18.97 6.83 18.97 5 19 C6.32 18.34 7.64 17.68 9 17 C6.03 16.67 3.06 16.34 0 16 C0 10.72 0 5.44 0 0 Z "
      fill="#4A516F"
      transform="translate(31,132)"
    />
    <path
      d="M0 0 C0 1.32 0 2.64 0 4 C-2.64 4.33 -5.28 4.66 -8 5 C-8 6.65 -8 8.3 -8 10 C-5.52 10.99 -5.52 10.99 -3 12 C-3 10.35 -3 8.7 -3 7 C-1.68 7 -0.36 7 1 7 C1.19 10.38 1.19 10.38 1 14 C-2.51 16.34 -3.87 16.41 -8 16 C-12.63 13.74 -12.63 13.74 -14 11 C-14.35 7.27 -14.49 4.86 -12.62 1.56 C-8.58 -0.84 -4.59 -0.32 0 0 Z "
      fill="#D9DEE2"
      transform="translate(67,132)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5.33 3.63 5.66 7.26 6 11 C7.32 10.67 8.64 10.34 10 10 C10.33 6.7 10.66 3.4 11 0 C12.32 0 13.64 0 15 0 C15.77 10.94 15.77 10.94 12.94 14.81 C9.1 16.36 6.95 16.17 3 15 C0.72 12.72 0.05 11.44 -0.29 8.2 C-0.26 7.23 -0.22 6.25 -0.19 5.25 C-0.16 4.27 -0.13 3.28 -0.11 2.27 C-0.07 1.52 -0.04 0.77 0 0 Z "
      fill="#DFE3EA"
      transform="translate(71,132)"
    />
    <path
      d="M0 0 C0 3.62 -0.89 5.11 -3 8 C-3.99 8 -4.98 8 -6 8 C-6 8.99 -6 9.98 -6 11 C-6.66 11 -7.32 11 -8 11 C-8.66 13.97 -9.32 16.94 -10 20 C-10.66 20 -11.32 20 -12 20 C-14.99 30.79 -14.04 42.91 -9.29 52.95 C-8.16 55.13 -8.16 55.13 -6 56 C-5.27 57.98 -4.6 59.98 -4 62 C-4.99 62 -5.98 62 -7 62 C-14.5 53 -16.64 41.9 -16.34 30.49 C-15.14 18.25 -9 8.18 0 0 Z "
      fill="#37D2C3"
      transform="translate(47,31)"
    />
    <path
      d="M0 0 C1.2 -0.02 1.2 -0.02 2.43 -0.04 C4.56 0.19 4.56 0.19 6.56 2.19 C6.98 5.38 6.83 6.77 5.06 9.5 C4.57 10.06 4.07 10.61 3.56 11.19 C4.88 11.52 6.2 11.85 7.56 12.19 C7.56 13.51 7.56 14.83 7.56 16.19 C3.27 16.19 -1.02 16.19 -5.44 16.19 C-3.44 12.19 -3.44 12.19 -1.38 9.62 C0.73 7.27 0.73 7.27 1.56 4.19 C-0.42 4.52 -2.4 4.85 -4.44 5.19 C-5 3.25 -5 3.25 -5.44 1.19 C-3.85 -0.4 -2.19 0.02 0 0 Z "
      fill="#DCE3E9"
      transform="translate(114.4375,131.8125)"
    />
    <path
      d="M0 0 C3.63 0 7.26 0 11 0 C11 0.99 11 1.98 11 3 C8.69 3 6.38 3 4 3 C4 3.99 4 4.98 4 6 C5.98 6 7.96 6 10 6 C10 6.99 10 7.98 10 9 C8.02 9 6.04 9 4 9 C4 9.99 4 10.98 4 12 C6.64 12 9.28 12 12 12 C12 13.32 12 14.64 12 16 C8.04 16 4.08 16 0 16 C0 10.72 0 5.44 0 0 Z "
      fill="#F5F7FC"
      transform="translate(90,132)"
    />
    <path
      d="M0 0 C-0.57 2.87 -0.86 3.86 -3 6 C-3.5 3.52 -3.5 3.52 -4 1 C-12.35 -0.07 -21.01 -0.62 -29.22 1.6 C-31 2 -31 2 -33 1 C-33 2.32 -33 3.64 -33 5 C-31.68 5.33 -30.36 5.66 -29 6 C-30.65 6.33 -32.3 6.66 -34 7 C-34.33 5.35 -34.66 3.7 -35 2 C-35.99 2 -36.98 2 -38 2 C-38 2.66 -38 3.32 -38 4 C-39.26 4.7 -40.54 5.38 -41.81 6.06 C-42.52 6.45 -43.23 6.83 -43.96 7.22 C-46 8 -46 8 -49 7 C-33.52 -2.99 -17.78 -5.88 0 0 Z "
      fill="#2ADAC3"
      transform="translate(97,23)"
    />
    <path
      d="M0 0 C2.29 3.44 2.18 4.99 2 9 C1.01 9 0.02 9 -1 9 C-0.81 9.64 -0.63 10.28 -0.44 10.94 C-0.29 11.62 -0.15 12.3 0 13 C-1 14 -1 14 -3.5 14.1 C-5.02 14.08 -5.02 14.08 -6.56 14.06 C-7.57 14.05 -8.59 14.04 -9.63 14.04 C-10.8 14.02 -10.8 14.02 -12 14 C-12 13.67 -12 13.34 -12 13 C-10.02 13 -8.04 13 -6 13 C-6 12.01 -6 11.02 -6 10 C-7.98 10 -9.96 10 -12 10 C-12 9.01 -12 8.02 -12 7 C-9.69 7 -7.38 7 -5 7 C-5 6.01 -5 5.02 -5 4 C-8.63 4 -12.26 4 -16 4 C-16 3.67 -16 3.34 -16 3 C-12.04 3 -8.08 3 -4 3 C-4 2.34 -4 1.68 -4 1 C-2.68 0.67 -1.36 0.34 0 0 Z "
      fill="#0D1A3F"
      transform="translate(106,128)"
    />
    <path
      d="M0 0 C5.94 7.91 5.67 16.45 5 26 C4.25 29.77 3.27 33.37 2 37 C1.01 33.75 0.95 30.97 1.12 27.58 C1.17 26.55 1.22 25.53 1.27 24.47 C1.32 23.41 1.38 22.35 1.44 21.25 C1.52 19.63 1.52 19.63 1.6 17.98 C1.73 15.32 1.86 12.66 2 10 C1.34 10 0.68 10 0 10 C0 6.7 0 3.4 0 0 Z "
      fill="#2DD8C2"
      transform="translate(117,52)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C4 3.96 4 7.92 4 12 C6.31 12 8.62 12 11 12 C11 12.99 11 13.98 11 15 C7.37 15 3.74 15 0 15 C0 10.05 0 5.1 0 0 Z "
      fill="#F3F4FA"
      transform="translate(32,132)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C0.53 4.18 -1.07 7.65 -4 11 C-7.01 11.73 -9.87 11.52 -12.94 11.31 C-14.53 11.24 -16.12 11.18 -17.71 11.11 C-18.83 11.06 -18.83 11.06 -19.96 11.01 C-22.41 11 -24.64 11.37 -27 12 C-25.61 10.02 -25.61 10.02 -23 8 C-19.74 7.49 -16.54 7.62 -13.25 7.75 C-12.36 7.77 -11.47 7.79 -10.56 7.8 C-8.37 7.85 -6.19 7.92 -4 8 C-3.71 7.05 -3.42 6.1 -3.12 5.12 C-2 2 -2 2 0 0 Z "
      fill="#39D6C4"
      transform="translate(92,29)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C4 5.28 4 10.56 4 16 C2.68 16 1.36 16 0 16 C0 10.72 0 5.44 0 0 Z "
      fill="#E9EBEE"
      transform="translate(46,132)"
    />
    <path
      d="M0 0 C1.98 -0.03 3.96 -0.05 5.94 -0.06 C7.04 -0.07 8.14 -0.09 9.28 -0.1 C12 0 12 0 13 1 C13.32 4.85 12.6 7.51 11 11 C8.69 11 6.38 11 4 11 C4 10.67 4 10.34 4 10 C5.98 10 7.96 10 10 10 C10 9.01 10 8.02 10 7 C8.02 7 6.04 7 4 7 C4 6.01 4 5.02 4 4 C6.31 4 8.62 4 11 4 C11 3.01 11 2.02 11 1 C7.37 1 3.74 1 0 1 C0 0.67 0 0.34 0 0 Z "
      fill="#3A3D55"
      transform="translate(90,131)"
    />
    <path
      d="M0 0 C0.33 0.66 0.66 1.32 1 2 C-1.02 6.04 -5.87 7.87 -9.94 9.44 C-16.65 11.26 -24.13 10.39 -31 10 C-30.67 9.01 -30.34 8.02 -30 7 C-28.87 7.03 -27.73 7.07 -26.56 7.11 C-16.09 7.33 -8.24 7.47 0 0 Z "
      fill="#34CFBF"
      transform="translate(95,90)"
    />
    <path
      d="M0 0 C0.33 0.99 0.66 1.98 1 3 C3.64 3 6.28 3 9 3 C9 4.32 9 5.64 9 7 C5.04 7 1.08 7 -3 7 C-2.01 6.67 -1.02 6.34 0 6 C0 4.02 0 2.04 0 0 Z "
      fill="#D2D6D9"
      transform="translate(93,141)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C0.26 7.74 0.26 7.74 -3 11 C-3.66 11 -4.32 11 -5 11 C-5 11.66 -5 12.32 -5 13 C-4.01 13.33 -3.02 13.66 -2 14 C-2.99 14.5 -2.99 14.5 -4 15 C-4.99 14.67 -5.98 14.34 -7 14 C-5.56 8.76 -3.18 4.37 0 0 Z "
      fill="#32D4C4"
      transform="translate(63,100)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1 5.28 1 10.56 1 16 C4.3 16.33 7.6 16.66 11 17 C11 17.33 11 17.66 11 18 C10.09 18.14 9.19 18.29 8.25 18.44 C5.15 18.92 5.15 18.92 2.75 19.62 C1 20 1 20 -1 19 C-0.67 12.73 -0.34 6.46 0 0 Z "
      fill="#28324F"
      transform="translate(89,132)"
    />
    <path
      d="M0 0 C1.65 1.65 3.3 3.3 5 5 C3.24 9.97 0.61 14.43 -2 19 C-3.1 15.71 -2.8 14.29 -2 11 C-1.01 11 -0.02 11 1 11 C1 10.01 1 9.02 1 8 C1.66 8 2.32 8 3 8 C2.51 7.05 2.01 6.1 1.5 5.12 C0 2 0 2 0 0 Z "
      fill="#3ACFC1"
      transform="translate(54,80)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C3.12 7.62 3.12 7.62 2 11 C1.34 11 0.68 11 0 11 C0 7.37 0 3.74 0 0 Z "
      fill="#F1F7FC"
      transform="translate(82,132)"
    />
    <path
      d="M0 0 C0.33 0.66 0.66 1.32 1 2 C0.03 3.5 -0.96 5 -1.94 6.5 C-2.76 7.75 -2.76 7.75 -3.59 9.03 C-4.06 9.68 -4.52 10.33 -5 11 C-5.33 11 -5.66 11 -6 11 C-6.38 7.75 -6.38 7.75 -6 4 C-3 1.56 -3 1.56 0 0 Z "
      fill="#33DDC5"
      transform="translate(117,89)"
    />
    <path
      d="M0 0 C7.62 0.88 7.62 0.88 11 2 C10.43 4.87 10.14 5.86 8 8 C7.67 6.35 7.34 4.7 7 3 C4.69 2.67 2.38 2.34 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#44CBC3"
      transform="translate(86,21)"
    />
    <path
      d="M0 0 C2.31 0 4.62 0 7 0 C7 1.32 7 2.64 7 4 C4.69 3.67 2.38 3.34 0 3 C0 2.01 0 1.02 0 0 Z "
      fill="#F0F3F9"
      transform="translate(60,132)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C3.33 1.65 3.66 3.3 4 5 C2.35 5.33 0.7 5.66 -1 6 C-1.04 4.33 -1.04 2.67 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z "
      fill="#EDF2F8"
      transform="translate(54,137)"
    />
    <path
      d="M0 0 C2.97 0.5 2.97 0.5 6 1 C6 2.32 6 3.64 6 5 C6.99 5 7.98 5 9 5 C9 5.66 9 6.32 9 7 C6.62 6.82 5.3 6.3 3.62 4.6 C2.38 3.09 1.19 1.55 0 0 Z "
      fill="#34D1C1"
      transform="translate(42,94)"
    />
    <path
      d="M0 0 C-2.97 2.47 -2.97 2.47 -6 5 C-6.33 4.01 -6.66 3.02 -7 2 C-6 0 -6 0 -4.06 -0.69 C-2 -1 -2 -1 0 0 Z "
      fill="#40D9C8"
      transform="translate(108,104)"
    />
    <path
      d="M0 0 C3.83 1.45 5.12 3.43 7 7 C6.01 8.48 6.01 8.48 5 10 C3.33 6.67 1.67 3.33 0 0 Z "
      fill="#40DACA"
      transform="translate(96,53)"
    />
    <path
      d="M0 0 C5.57 5.14 5.57 5.14 6 9 C3 8 3 8 1.31 5.06 C0 2 0 2 0 0 Z "
      fill="#3ECEC0"
      transform="translate(111,43)"
    />
  </svg>
)
export default FranceLigue2
