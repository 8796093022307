import * as React from 'react'
const TropyColor = (props) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="trophy-01">
      <path
        id="Icon"
        d="M12 15.5C8.68629 15.5 6 12.8137 6 9.5V3.94444C6 3.5306 6 3.32367 6.06031 3.15798C6.16141 2.88021 6.38021 2.66141 6.65798 2.56031C6.82367 2.5 7.0306 2.5 7.44444 2.5H16.5556C16.9694 2.5 17.1763 2.5 17.342 2.56031C17.6198 2.66141 17.8386 2.88021 17.9397 3.15798C18 3.32367 18 3.5306 18 3.94444V9.5C18 12.8137 15.3137 15.5 12 15.5ZM12 15.5V18.5M18 4.5H20.5C20.9659 4.5 21.1989 4.5 21.3827 4.57612C21.6277 4.67761 21.8224 4.87229 21.9239 5.11732C22 5.30109 22 5.53406 22 6V6.5C22 7.42997 22 7.89496 21.8978 8.27646C21.6204 9.31173 20.8117 10.1204 19.7765 10.3978C19.395 10.5 18.93 10.5 18 10.5M6 4.5H3.5C3.03406 4.5 2.80109 4.5 2.61732 4.57612C2.37229 4.67761 2.17761 4.87229 2.07612 5.11732C2 5.30109 2 5.53406 2 6V6.5C2 7.42997 2 7.89496 2.10222 8.27646C2.37962 9.31173 3.18827 10.1204 4.22354 10.3978C4.60504 10.5 5.07003 10.5 6 10.5M7.44444 22.5H16.5556C16.801 22.5 17 22.301 17 22.0556C17 20.0919 15.4081 18.5 13.4444 18.5H10.5556C8.59188 18.5 7 20.0919 7 22.0556C7 22.301 7.19898 22.5 7.44444 22.5Z"
        stroke="#F7941D"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
export default TropyColor
