import React from 'react'
const Belgium = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#FDD905"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L2,0 L2,42 L-3,40 L-8,35 L-11,31 L-13,26 L-13,16 L-9,8 L-2,1 Z "
      fill="#221F20"
      transform="translate(15,4)"
    />
    <path
      d="M0,0 L5,2 L12,9 L14,14 L14,28 L10,36 L2,42 L0,42 Z "
      fill="#ED1C22"
      transform="translate(33,4)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M2,2 L-6,6 L-13,13 L-16,20 L-16,30 L-12,38 L-7,43 L-3,46 L2,48 L11,48 L20,44 L25,40 L29,32 L29,18 L26,12 L19,5 L11,2 Z "
      fill="#B9C7D0"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L7,6 L11,10 L6,8 L1,5 Z "
      fill="#CCD8DD"
      transform="translate(5,37)"
    />
  </svg>
)
export default Belgium
