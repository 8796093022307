import * as React from 'react'
const Greece = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L16,0 L26,6 L32,15 L33,17 L33,33 L27,43 L18,49 L16,50 L0,50 L-10,44 L-16,35 L-17,33 L-17,17 L-11,7 L-2,1 Z "
      fill="#F1F3F8"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L42,0 L39,5 L29,11 L13,11 L3,5 Z "
      fill="#F8F9FB"
      transform="translate(4,39)"
    />
    <path
      d="M0,0 L48,0 L46,6 L2,6 Z "
      fill="#1461B0"
      transform="translate(1,33)"
    />
    <path
      d="M0,0 L10,0 L10,5 L33,5 L33,11 L0,11 Z "
      fill="#125EAF"
      transform="translate(17,17)"
    />
    <path
      d="M0,0 L16,0 L24,5 L24,6 L10,6 L10,11 L0,11 Z "
      fill="#125FAF"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L32,0 L28,4 L24,6 L8,6 L0,1 Z "
      fill="#2066B2"
      transform="translate(9,44)"
    />
    <path
      d="M0,0 L11,0 L11,11 L0,11 Z "
      fill="#0D5CAE"
      transform="translate(0,17)"
    />
    <path
      d="M0,0 L19,0 L21,6 L0,6 Z "
      fill="#1461B0"
      transform="translate(28,11)"
    />
    <path
      d="M0,0 L1,0 L1,7 L-6,7 L-3,2 Z "
      fill="#0754AC"
      transform="translate(10,4)"
    />
  </svg>
)
export default Greece
