import React from 'react'
const Turkey1League = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={139}
    viewBox="0 0 150 139"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,139 L0,139 Z "
      fill="#FDFDFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L13,0 L20,5 L24,14 L24,22 L19,28 L18,31 L16,31 L17,33 L16,42 L12,49 L10,55 L7,55 L9,49 L12,43 L3,46 L-10,46 L-18,41 L-24,33 L-25,31 L-25,18 L-21,10 L-15,5 L-9,3 L-10,10 L-12,10 L-11,13 L-9,6 Z "
      fill="#ECDBDD"
      transform="translate(75,5)"
    />
    <path
      d="M0,0 L2,1 L-1,5 L-2,8 L-2,18 L1,25 L6,30 L11,33 L22,33 L27,32 L25,36 L19,41 L14,43 L1,43 L-7,38 L-13,30 L-14,28 L-14,15 L-10,7 L-4,2 Z "
      fill="#E9071F"
      transform="translate(64,8)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,8 L-1,8 L0,12 L5,12 L9,11 L8,14 L12,16 L12,0 L22,0 L25,2 L25,14 L23,14 L22,3 L16,3 L15,15 L17,17 L17,23 L20,23 L20,36 L24,36 L24,38 L17,38 L17,24 L15,24 L14,30 L12,30 L11,37 L7,37 L9,27 L10,22 L8,22 L7,15 L-1,13 L-3,11 L-3,3 Z "
      fill="#E7D3D4"
      transform="translate(48,65)"
    />
    <path
      d="M0,0 L13,0 L20,5 L24,14 L24,22 L17,30 L15,30 L15,32 L9,34 L-2,33 L-2,30 L-6,28 L-6,25 L0,25 L2,29 L11,29 L12,25 L13,23 L19,24 L19,15 L17,13 L15,6 L8,5 L0,4 L1,7 L-3,9 L-5,14 L-9,16 L-10,11 L-9,6 Z "
      fill="#C3C3C1"
      transform="translate(75,5)"
    />
    <path
      d="M0,0 L13,0 L17,3 L19,10 L20,11 L19,20 L13,20 L10,16 L11,10 L6,9 L2,10 L3,16 L-1,16 L-1,19 L-6,20 L-7,14 L-3,9 L-4,5 L0,2 Z "
      fill="#F8F5F5"
      transform="translate(75,9)"
    />
    <path
      d="M0,0 L8,0 L8,1 L-1,3 L3,5 L-7,10 L-11,15 L-13,20 L-13,33 L-7,42 L1,47 L1,48 L-6,48 L-12,44 L-18,34 L-19,30 L-19,19 L-14,10 L-9,5 L-3,1 Z "
      fill="#F7E3E2"
      transform="translate(64,3)"
    />
    <path
      d="M0,0 L2,0 L2,19 L-9,19 L-12,16 L-12,8 L-9,5 L-2,5 L-1,6 Z "
      fill="#1F1F1F"
      transform="translate(88,60)"
    />
    <path
      d="M0,0 L6,0 L10,3 L10,11 L6,14 L0,14 L-4,10 L-4,4 Z "
      fill="#F0F0F0"
      transform="translate(111,65)"
    />
    <path
      d="M0,0 L1,0 L1,8 L-6,16 L-8,16 L-8,18 L-14,20 L-25,19 L-25,16 L-29,14 L-29,11 L-23,11 L-21,15 L-12,15 L-11,11 L-10,9 L-4,10 L-3,2 Z "
      fill="#CBCBCA"
      transform="translate(98,19)"
    />
    <path
      d="M0,0 L4,1 L12,1 L12,4 L11,3 L4,4 L4,11 L5,12 L11,12 L13,9 L9,8 L14,8 L13,13 L10,15 L5,15 L2,12 L2,10 L0,10 L-1,15 L-4,15 L-4,1 Z "
      fill="#636363"
      transform="translate(79,88)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,8 L-1,8 L0,12 L5,12 L9,11 L7,14 L1,14 L-3,11 L-3,3 Z "
      fill="#2D2D2D"
      transform="translate(48,65)"
    />
    <path
      d="M0,0 L5,0 L9,0 L9,11 L6,11 L7,9 L5,11 L-1,11 L-3,9 L-3,2 Z "
      fill="#E9E9E9"
      transform="translate(85,90)"
    />
    <path
      d="M0,0 L8,0 L8,1 L-3,4 L-10,9 L-15,17 L-16,21 L-16,31 L-13,39 L-13,42 L-18,34 L-19,30 L-19,19 L-14,10 L-9,5 L-3,1 Z "
      fill="#A0A09F"
      transform="translate(64,3)"
    />
    <path
      d="M0,0 L4,1 L4,4 L0,4 L1,7 L-3,9 L-5,14 L-9,16 L-10,11 L-9,6 Z "
      fill="#CBD0CD"
      transform="translate(75,5)"
    />
    <path
      d="M0,0 L5,2 L6,3 L6,9 L4,11 L-2,11 L-4,9 L-4,3 Z "
      fill="#F2F2F2"
      transform="translate(82,66)"
    />
    <path
      d="M0,0 L5,0 L13,17 L17,22 L16,24 L8,15 L1,4 Z "
      fill="#949494"
      transform="translate(82,109)"
    />
    <path
      d="M0,0 L7,0 L6,7 L3,18 L-1,18 L1,8 L2,3 L0,3 Z "
      fill="#D80F1C"
      transform="translate(56,84)"
    />
    <path
      d="M0,0 L5,1 L9,2 L10,7 L8,9 L-1,9 L-2,5 L0,2 Z "
      fill="#FBFAFA"
      transform="translate(78,26)"
    />
    <path
      d="M0,0 L6,0 L10,3 L10,11 L6,14 L0,14 L-4,10 L-4,4 Z M0,2 L-2,4 L-2,10 L0,12 L6,12 L9,9 L8,4 L6,2 Z "
      fill="#2D2D2D"
      transform="translate(111,65)"
    />
    <path
      d="M0,0 L3,0 L4,15 L1,14 L0,11 L-6,12 L-9,9 L-9,7 L2,7 Z "
      fill="#E8E8E8"
      transform="translate(56,66)"
    />
    <path
      d="M0,0 L9,0 L12,2 L12,14 L10,14 L9,3 L3,3 L2,14 L0,14 Z "
      fill="#131313"
      transform="translate(61,65)"
    />
    <path
      d="M0,0 L2,0 L2,7 L1,12 L0,14 L-9,14 L-9,7 L-7,7 L-8,13 L-4,13 L-3,6 L-1,6 Z "
      fill="#F8D9DA"
      transform="translate(63,89)"
    />
    <path
      d="M0,0 L2,0 L5,9 L7,10 L10,1 L13,1 L7,15 L6,19 L3,19 L4,12 L0,2 Z "
      fill="#232323"
      transform="translate(93,65)"
    />
    <path
      d="M0,0 L2,0 L2,5 L6,5 L6,7 L2,7 L2,17 L7,17 L7,19 L1,19 L0,18 L0,7 L-3,7 L-3,5 L0,5 Z "
      fill="#111111"
      transform="translate(24,60)"
    />
    <path
      d="M0,0 L3,0 L3,19 L0,19 Z "
      fill="#575757"
      transform="translate(124,60)"
    />
    <path
      d="M0,0 L4,0 L3,8 L-1,15 L-3,21 L-6,21 L-4,15 L-1,9 L-3,8 L2,2 L0,2 Z "
      fill="#F6D5D4"
      transform="translate(88,39)"
    />
    <path
      d="M0,0 L9,0 L8,3 L3,4 L2,14 L0,14 Z "
      fill="#171717"
      transform="translate(34,65)"
    />
    <path
      d="M0,0 L2,0 L0,4 L-2,4 L-5,5 L-17,5 L-18,3 L-5,2 Z "
      fill="#FED2D0"
      transform="translate(83,48)"
    />
    <path
      d="M0,0 L2,0 L2,13 L6,13 L6,15 L0,15 Z "
      fill="#5B5B5B"
      transform="translate(66,88)"
    />
    <path
      d="M0,0 L3,4 L6,13 L2,12 L1,18 L0,18 Z "
      fill="#C8C8C8"
      transform="translate(90,61)"
    />
    <path
      d="M0,0 L3,1 L0,4 L1,11 L6,11 L10,10 L8,13 L2,13 L-2,10 L-2,2 Z "
      fill="#161616"
      transform="translate(47,66)"
    />
    <path
      d="M0,0 L5,1 L6,5 L-3,5 L-2,1 Z "
      fill="#E1E1E1"
      transform="translate(50,66)"
    />
    <path
      d="M0,0 L5,4 L3,8 L-2,9 L-4,3 L-1,2 Z "
      fill="#C22E41"
      transform="translate(81,16)"
    />
    <path
      d="M0,0 L6,1 L8,3 L8,11 L4,14 L-1,14 L3,12 L6,9 L5,4 Z "
      fill="#141414"
      transform="translate(113,65)"
    />
    <path
      d="M0,0 L2,3 L2,13 L-5,11 L-2,10 L-1,4 Z "
      fill="#ECEBEB"
      transform="translate(86,54)"
    />
    <path
      d="M0,0 L7,0 L6,4 L1,5 L-2,2 Z "
      fill="#131515"
      transform="translate(79,5)"
    />
    <path
      d="M0,0 L6,0 L7,6 L2,5 L0,3 Z "
      fill="#121110"
      transform="translate(69,30)"
    />
    <path
      d="M0,0 L6,0 L5,4 L2,6 L-1,6 Z "
      fill="#131312"
      transform="translate(88,30)"
    />
    <path
      d="M0,0 L3,1 L4,7 L0,7 L-2,4 Z "
      fill="#11100F"
      transform="translate(94,13)"
    />
    <path
      d="M0,0 L3,0 L4,5 L1,8 L-2,7 L-1,2 Z "
      fill="#0F100E"
      transform="translate(67,13)"
    />
    <path
      d="M0,0 L1,0 L2,11 L6,19 L4,19 L-1,13 Z "
      fill="#FCC7C8"
      transform="translate(49,25)"
    />
    <path
      d="M0,0 L6,0 L4,1 L4,4 L0,4 L0,12 L-1,12 L-1,1 Z "
      fill="#E2E2E2"
      transform="translate(64,67)"
    />
    <path
      d="M0,0 L3,2 L5,10 L0,11 Z "
      fill="#D4D4D4"
      transform="translate(73,68)"
    />
    <path
      d="M0,0 L1,2 L-3,12 L-6,12 L-4,6 Z "
      fill="#ADAAAA"
      transform="translate(88,48)"
    />
    <path
      d="M0,0 L13,1 L13,3 L3,3 L3,1 Z "
      fill="#FEF7FB"
      transform="translate(72,38)"
    />
    <path
      d="M0,0 L2,4 L0,4 L2,9 L0,10 L-1,15 L-2,15 L-2,5 Z "
      fill="#FDC8CC"
      transform="translate(63,11)"
    />
    <path
      d="M0,0 L3,0 L1,5 L-4,11 L-4,8 Z "
      fill="#969696"
      transform="translate(65,109)"
    />
    <path
      d="M0,0 L1,0 L1,10 L6,10 L6,12 L0,12 L-1,9 Z "
      fill="#333333"
      transform="translate(25,67)"
    />
    <path
      d="M0,0 L3,1 L4,9 L1,8 Z "
      fill="#D9D9D7"
      transform="translate(64,20)"
    />
    <path
      d="M0,0 L2,1 L-1,5 L-3,8 L-4,6 L-7,4 Z "
      fill="#D41927"
      transform="translate(64,8)"
    />
    <path
      d="M0,0 L2,4 L2,11 L0,11 L-1,6 L-3,7 L-1,1 Z "
      fill="#D0D0D0"
      transform="translate(106,65)"
    />
    <path
      d="M0,0 L0,3 L-3,5 L-3,3 L-14,3 L-14,2 Z "
      fill="#C81F26"
      transform="translate(90,40)"
    />
    <path
      d="M0,0 L5,2 L6,5 L-1,4 Z "
      fill="#D3D3D4"
      transform="translate(86,5)"
    />
    <path
      d="M0,0 L1,0 L1,8 L-2,8 L-1,1 Z "
      fill="#DEDDDE"
      transform="translate(98,19)"
    />
    <path
      d="M0,0 L3,4 L2,7 L-1,6 Z "
      fill="#DA172F"
      transform="translate(81,16)"
    />
  </svg>
)
export default Turkey1League
