import React from 'react'
const Slovenia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#BAC8CD"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L6,0 L9,2 L9,8 L32,8 L34,11 L34,21 L32,24 L-10,24 L-12,21 L-12,11 L-10,8 L-3,8 L-3,1 Z "
      fill="#055BAD"
      transform="translate(14,9)"
    />
    <path
      d="M0,0 L42,0 L40,5 L35,10 L31,13 L26,15 L16,15 L8,11 L1,4 Z "
      fill="#EB1D23"
      transform="translate(4,33)"
    />
    <path
      d="M0,0 L9,0 L19,5 L26,13 L26,14 L4,14 L4,9 L-6,8 L-9,9 L-10,14 L-16,14 L-13,9 L-5,2 Z "
      fill="#FCFCFC"
      transform="translate(20,2)"
    />
    <path
      d="M0,0 L6,0 L8,1 L8,9 L5,14 L-2,13 L-4,8 L-3,1 Z "
      fill="#425A94"
      transform="translate(14,9)"
    />
    <path
      d="M0,0 L1,0 L1,6 L24,6 L24,8 L1,8 L-1,12 L-3,14 L-8,13 L-9,11 L-3,11 L-1,7 Z "
      fill="#343F89"
      transform="translate(22,11)"
    />
    <path
      d="M0,0 L5,2 L4,7 L-1,8 L-4,5 L-2,1 Z "
      fill="#DAE2F0"
      transform="translate(16,15)"
    />
    <path
      d="M0,0 L3,0 L3,8 L-5,5 L-5,2 L-3,2 L-3,4 L-1,4 Z "
      fill="#084EB0"
      transform="translate(19,10)"
    />
    <path
      d="M0,0 L8,0 L6,3 L0,2 Z "
      fill="#8DB7DD"
      transform="translate(13,20)"
    />
  </svg>
)
export default Slovenia
