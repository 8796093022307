import React from 'react'
const IrelandLeague = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    viewBox="0 0 150 150"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,150 L0,150 Z "
      fill="#FEFEFE"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L9,1 L8,4 L2,6 L-8,7 L-6,12 L3,7 L7,7 L7,9 L13,10 L12,4 L16,5 L20,8 L24,16 L27,18 L27,21 L25,21 L23,33 L21,38 L23,38 L22,40 L18,40 L19,36 L13,34 L13,40 L16,41 L9,44 L8,48 L10,49 L9,51 L2,52 L-1,52 L-9,52 L-9,57 L-6,57 L-5,62 L-2,61 L-1,64 L-1,61 L1,60 L1,58 L16,57 L21,56 L23,58 L29,56 L30,58 L34,59 L35,72 L31,75 L34,77 L33,92 L31,92 L25,85 L23,84 L23,91 L21,91 L21,78 L24,77 L20,76 L19,74 L13,76 L7,76 L6,80 L5,81 L0,81 L-1,78 L0,76 L-4,75 L-4,88 L3,88 L4,86 L6,86 L5,83 L7,83 L8,78 L10,78 L10,76 L14,78 L16,81 L15,83 L17,84 L19,93 L-9,93 L-9,77 L-6,76 L-12,76 L-12,80 L-19,80 L-19,84 L-15,84 L-15,86 L-19,86 L-19,89 L-12,89 L-12,92 L-23,92 L-25,89 L-27,93 L-40,93 L-41,95 L-41,93 L-45,93 L-45,77 L-38,77 L-39,76 L-45,75 L-45,60 L-41,60 L-41,71 L-33,71 L-32,74 L-30,74 L-30,60 L-18,60 L-19,65 L-20,69 L-16,68 L-15,69 L-13,63 L-11,63 L-11,54 L-14,54 L-14,56 L-16,55 L-17,50 L-15,50 L-15,44 L-18,39 L-19,37 L-21,37 L-21,34 L-23,34 L-23,32 L-25,32 L-25,21 L-19,18 L-19,13 L-20,11 L-12,6 L-11,2 Z "
      fill="#F1F8ED"
      transform="translate(73,24)"
    />
    <path
      d="M0,0 L4,0 L4,11 L12,11 L13,14 L15,14 L15,0 L27,0 L26,5 L25,9 L29,8 L32,10 L37,0 L40,4 L40,6 L43,6 L43,9 L45,10 L44,12 L46,12 L46,4 L50,0 L58,0 L58,3 L52,3 L51,4 L51,10 L56,11 L57,9 L54,9 L54,6 L60,6 L60,15 L58,16 L52,16 L51,20 L50,21 L45,21 L44,18 L45,16 L41,15 L41,28 L48,28 L49,26 L51,26 L50,23 L52,23 L53,18 L55,18 L55,16 L59,18 L61,21 L60,23 L62,24 L64,33 L36,33 L36,17 L39,16 L33,16 L33,20 L26,20 L26,24 L30,24 L30,26 L26,26 L26,29 L33,29 L33,32 L22,32 L20,29 L18,33 L5,33 L4,35 L4,33 L0,33 L0,17 L7,17 L6,16 L0,15 Z "
      fill="#B7D2AA"
      transform="translate(28,84)"
    />
    <path
      d="M0,0 L4,1 L8,4 L12,12 L15,14 L15,17 L13,17 L11,29 L9,34 L11,34 L10,36 L6,36 L7,32 L1,30 L1,36 L4,37 L-3,40 L-6,44 L-11,44 L-9,41 L-9,34 L-15,29 L-22,29 L-23,30 L-23,36 L-20,40 L-25,39 L-28,33 L-28,27 L-24,22 L-16,21 L-18,17 L-17,10 L-12,5 L-9,3 L-5,3 L-5,5 L1,6 Z "
      fill="#E5EEE0"
      transform="translate(85,28)"
    />
    <path
      d="M0,0 L5,1 L7,1 L13,0 L13,2 L17,3 L18,16 L14,19 L17,21 L16,36 L14,36 L8,29 L6,28 L6,35 L4,35 L4,22 L7,21 L3,20 L2,18 L-2,18 L-2,10 L-8,10 L-8,13 L-5,13 L-6,16 L-11,15 L-12,14 L-12,8 L-10,6 L-4,6 L-4,5 L-12,5 L-15,8 L-16,16 L-18,16 L-19,10 L-22,10 L-25,5 L-30,16 L-31,16 L-31,10 L-30,7 L-28,7 L-28,4 L-26,4 L-25,1 L-23,1 L-22,6 L-19,5 L-18,8 L-18,5 L-16,4 L-16,2 L-1,1 Z "
      fill="#F4FBED"
      transform="translate(90,80)"
    />
    <path
      d="M0,0 L4,0 L4,11 L12,11 L13,14 L15,14 L15,0 L27,0 L26,5 L25,9 L29,8 L31,9 L31,16 L30,17 L22,17 L22,29 L20,29 L18,33 L5,33 L4,35 L4,33 L0,33 L0,17 L7,17 L6,16 L0,15 Z "
      fill="#EEF9EA"
      transform="translate(28,84)"
    />
    <path
      d="M0,0 L11,0 L11,5 L9,5 L10,11 L13,12 L14,16 L13,19 L15,19 L15,21 L12,21 L12,30 L11,33 L-2,33 L-2,18 L-1,1 Z "
      fill="#E4F2D9"
      transform="translate(111,83)"
    />
    <path
      d="M0,0 L2,1 L0,5 L1,12 L6,16 L14,15 L17,13 L24,13 L24,16 L18,16 L13,22 L12,24 L12,30 L15,32 L11,34 L8,38 L3,38 L5,35 L5,28 L-1,23 L-8,23 L-9,24 L-9,30 L-6,34 L-11,33 L-14,27 L-14,21 L-10,16 L-2,15 L-4,11 L-3,4 Z "
      fill="#104F25"
      transform="translate(71,34)"
    />
    <path
      d="M0,0 L9,1 L8,4 L2,6 L-8,7 L-6,12 L-4,12 L-5,21 L-3,26 L-12,27 L-15,31 L-16,36 L-17,36 L-18,31 L-22,27 L-22,24 L-20,19 L-19,18 L-19,13 L-20,11 L-12,6 L-11,2 Z "
      fill="#D3E4DA"
      transform="translate(73,24)"
    />
    <path
      d="M0,0 L15,0 L18,3 L18,9 L18,13 L18,16 L5,16 L4,18 L4,16 L0,16 Z "
      fill="#F9FEFB"
      transform="translate(28,101)"
    />
    <path
      d="M0,0 L3,0 L4,10 L9,11 L9,0 L12,0 L12,11 L11,15 L14,17 L13,32 L11,32 L5,25 L3,24 L3,31 L1,31 L1,18 L4,17 L0,16 Z "
      fill="#D5E5D4"
      transform="translate(93,84)"
    />
    <path
      d="M0,0 L4,0 L4,2 L7,3 L0,5 L0,6 L7,6 L10,9 L10,16 L6,20 L-1,19 L-6,13 L-5,6 L-2,1 Z "
      fill="#E4EBD6"
      transform="translate(76,31)"
    />
    <path
      d="M0,0 L4,2 L6,5 L5,7 L7,8 L9,17 L-19,17 L-19,1 L-18,1 L-18,16 L-8,16 L-7,12 L-6,10 L-4,10 L-5,7 L-3,7 L-2,2 L0,2 Z "
      fill="#DBEDE5"
      transform="translate(83,100)"
    />
    <path
      d="M0,0 L4,1 L8,4 L10,11 L7,10 L7,12 L11,13 L11,15 L6,13 L7,17 L10,19 L2,20 L-2,22 L0,19 L0,12 L-2,10 L-11,10 L-8,6 L1,6 Z "
      fill="#A8C5A8"
      transform="translate(85,28)"
    />
    <path
      d="M0,0 L2,1 L1,14 L3,14 L3,7 L7,9 L11,13 L11,15 L13,15 L13,0 L15,0 L14,11 L16,11 L16,15 L27,14 L29,16 L13,17 L12,19 L4,19 L0,19 L-1,17 Z "
      fill="#F7FDF9"
      transform="translate(93,101)"
    />
    <path
      d="M0,0 L8,0 L11,3 L11,10 L8,13 L0,13 Z "
      fill="#0B3918"
      transform="translate(110,102)"
    />
    <path
      d="M0,0 L9,1 L8,4 L2,6 L-8,7 L-7,8 L-7,15 L-12,23 L-15,25 L-19,24 L-13,20 L-11,20 L-9,8 L-14,8 L-12,6 L-11,2 Z "
      fill="#D1E1D1"
      transform="translate(73,24)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,9 L10,13 L10,15 L7,15 L3,11 L3,15 L0,15 Z "
      fill="#2F5D45"
      transform="translate(36,101)"
    />
    <path
      d="M0,0 L8,0 L14,4 L16,7 L16,15 L15,15 L13,8 L8,4 L1,4 L0,5 L0,11 L3,15 L-2,14 L-5,8 L-3,2 Z "
      fill="#75AA62"
      transform="translate(62,53)"
    />
    <path
      d="M0,0 L3,0 L4,10 L9,11 L9,0 L12,0 L12,11 L10,14 L3,14 L0,12 Z "
      fill="#81AE61"
      transform="translate(93,84)"
    />
    <path
      d="M0,0 L3,4 L6,11 L6,14 L-6,14 L-3,5 Z "
      fill="#7FAE5D"
      transform="translate(65,84)"
    />
    <path
      d="M0,0 L7,0 L7,8 L4,12 L3,10 L-1,11 L-4,13 L-3,5 L-4,3 Z "
      fill="#C7D8BE"
      transform="translate(57,32)"
    />
    <path
      d="M0,0 L3,4 L6,11 L6,14 L4,14 L4,12 L-3,12 L-3,14 L-6,14 L-3,5 Z "
      fill="#1F4C33"
      transform="translate(84,102)"
    />
    <path
      d="M0,0 L10,0 L10,2 L3,2 L3,7 L7,7 L7,9 L3,9 L3,12 L10,12 L10,14 L0,14 Z "
      fill="#79A757"
      transform="translate(111,84)"
    />
    <path
      d="M0,0 L10,0 L10,2 L3,2 L3,7 L7,7 L7,9 L3,9 L3,12 L10,12 L10,14 L0,14 Z "
      fill="#79A755"
      transform="translate(44,84)"
    />
    <path
      d="M0,0 L7,0 L12,5 L13,6 L13,14 L9,15 L6,15 L8,13 L8,6 L6,4 L-3,4 Z "
      fill="#B0B161"
      transform="translate(77,34)"
    />
    <path
      d="M0,0 L4,0 L4,11 L11,11 L11,14 L0,14 Z "
      fill="#90B871"
      transform="translate(28,84)"
    />
    <path
      d="M0,0 L7,6 L9,7 L9,0 L11,0 L11,13 L4,7 L2,6 L2,13 L0,13 Z "
      fill="#073715"
      transform="translate(94,102)"
    />
    <path
      d="M0,0 L9,0 L9,2 L3,2 L3,6 L7,6 L7,8 L3,8 L3,11 L9,11 L9,13 L0,13 Z "
      fill="#1E472E"
      transform="translate(51,102)"
    />
    <path
      d="M0,0 L1,0 L1,14 L11,14 L8,18 L0,18 L0,31 L7,31 L7,32 L-1,32 L-1,17 Z "
      fill="#C3DAC1"
      transform="translate(110,84)"
    />
    <path
      d="M0,0 L4,0 L6,2 L9,3 L8,9 L0,9 L-1,1 Z "
      fill="#FCFEFB"
      transform="translate(64,57)"
    />
    <path
      d="M0,0 L1,0 L1,5 L-5,5 L-5,8 L-2,8 L-3,11 L-8,10 L-9,9 L-9,3 L-7,1 L-1,1 Z "
      fill="#E6F6D7"
      transform="translate(87,85)"
    />
    <path
      d="M0,0 L3,0 L3,12 L9,12 L9,15 L0,15 Z "
      fill="#305942"
      transform="translate(65,101)"
    />
    <path
      d="M0,0 L6,0 L7,4 L6,2 L-1,4 L-3,9 L-3,15 L0,16 L-3,17 L-7,15 L-7,9 L-3,3 Z "
      fill="#94BC8C"
      transform="translate(89,49)"
    />
    <path
      d="M0,0 L4,0 L4,15 L0,15 Z "
      fill="#55795D"
      transform="translate(28,101)"
    />
    <path
      d="M0,0 L2,1 L1,14 L3,14 L3,7 L7,9 L11,14 L5,16 L0,15 Z "
      fill="#C3D5CA"
      transform="translate(93,101)"
    />
    <path
      d="M0,0 L1,4 L-3,5 L-1,10 L1,11 L0,14 L0,12 L-7,12 L-7,9 L-3,9 L-3,7 L-7,7 L-7,2 L0,2 Z "
      fill="#D4EBC2"
      transform="translate(54,84)"
    />
    <path
      d="M0,0 L5,1 L6,6 L4,9 L0,9 Z "
      fill="#DDEDE5"
      transform="translate(113,104)"
    />
    <path
      d="M0,0 L6,0 L7,1 L7,8 L2,16 L-1,18 L-5,17 L1,13 L3,13 L5,1 L0,1 Z "
      fill="#3E5647"
      transform="translate(59,31)"
    />
    <path
      d="M0,0 L1,2 L1,6 L3,8 L1,10 L0,8 L-4,9 L-7,11 L-5,3 Z "
      fill="#93B57B"
      transform="translate(60,34)"
    />
    <path
      d="M0,0 L1,2 L2,0 L9,0 L9,5 L0,5 Z "
      fill="#96A79F"
      transform="translate(75,41)"
    />
    <path
      d="M0,0 L2,2 L3,7 L-6,8 L-4,1 L-3,3 L-1,3 Z "
      fill="#9BC47D"
      transform="translate(65,90)"
    />
    <path
      d="M0,0 L2,0 L2,9 L5,9 L6,7 L6,11 L1,11 L0,10 Z "
      fill="#E7F7D7"
      transform="translate(96,84)"
    />
    <path
      d="M0,0 L3,2 L3,10 L-1,11 L-4,11 L-1,8 Z "
      fill="#6E954F"
      transform="translate(87,38)"
    />
    <path
      d="M0,0 L3,0 L5,7 L2,6 L1,8 L-3,3 L-2,1 Z "
      fill="#789B6C"
      transform="translate(90,32)"
    />
    <path
      d="M0,0 L1,3 L3,3 L4,8 L0,9 L0,7 L-2,7 Z "
      fill="#658F76"
      transform="translate(82,104)"
    />
    <path
      d="M0,0 L3,3 L5,8 L8,10 L1,9 L-2,5 L0,5 Z "
      fill="#CDE0D4"
      transform="translate(74,41)"
    />
    <path
      d="M0,0 L2,1 L-5,4 L-14,4 L-14,1 L-2,1 Z "
      fill="#4D6655"
      transform="translate(80,26)"
    />
    <path
      d="M0,0 L13,0 L16,2 L15,4 L2,2 Z "
      fill="#C3CDC4"
      transform="translate(67,71)"
    />
    <path
      d="M0,0 L6,0 L6,7 L2,5 L3,3 L0,3 Z "
      fill="#8DB572"
      transform="translate(82,90)"
    />
    <path
      d="M0,0 L4,1 L2,5 L-4,5 Z "
      fill="#F0FCED"
      transform="translate(57,41)"
    />
    <path
      d="M0,0 L2,0 L2,7 L-2,7 L-1,1 Z "
      fill="#EDF9EA"
      transform="translate(62,33)"
    />
    <path
      d="M0,0 L1,0 L1,9 L5,8 L6,6 L9,7 L5,11 L0,10 Z "
      fill="#3D634C"
      transform="translate(112,104)"
    />
    <path
      d="M0,0 L2,0 L2,2 L6,3 L-1,5 L-4,9 L-3,3 Z "
      fill="#225929"
      transform="translate(61,50)"
    />
    <path
      d="M0,0 L9,1 L5,4 L0,4 Z "
      fill="#A8C3A3"
      transform="translate(73,24)"
    />
    <path
      d="M0,0 L4,2 L4,4 L7,5 L-3,5 L0,4 Z "
      fill="#E1F2EB"
      transform="translate(39,112)"
    />
    <path
      d="M0,0 L4,0 L4,5 L0,6 Z "
      fill="#CCE7DC"
      transform="translate(39,104)"
    />
    <path
      d="M0,0 L6,0 L5,4 L0,4 Z "
      fill="#C2D0B2"
      transform="translate(73,101)"
    />
    <path
      d="M0,0 L2,1 L-1,6 L-6,5 L-5,2 Z "
      fill="#E2EEE7"
      transform="translate(87,65)"
    />
    <path
      d="M0,0 L4,1 L6,4 L3,6 L0,3 Z "
      fill="#94AD96"
      transform="translate(85,28)"
    />
    <path
      d="M0,0 L7,0 L9,2 L9,7 L4,2 Z "
      fill="#0C371B"
      transform="translate(37,102)"
    />
    <path
      d="M0,0 L2,4 L3,7 L-2,7 L-1,1 Z "
      fill="#F4FEF9"
      transform="translate(65,42)"
    />
    <path
      d="M0,0 L1,0 L2,10 L0,12 L-2,7 Z "
      fill="#CFE3D9"
      transform="translate(46,104)"
    />
    <path
      d="M0,0 L2,2 L5,3 L5,7 L1,5 L-2,1 Z "
      fill="#CEE4D6"
      transform="translate(97,101)"
    />
    <path
      d="M0,0 L2,1 L-1,10 L-3,7 L-2,2 Z "
      fill="#1A4127"
      transform="translate(71,34)"
    />
    <path
      d="M0,0 L1,0 L2,7 L1,10 L-3,8 L-1,6 Z "
      fill="#ADC5B7"
      transform="translate(121,106)"
    />
    <path
      d="M0,0 L3,1 L3,3 L5,3 L4,6 L0,4 Z "
      fill="#EFF9F0"
      transform="translate(91,41)"
    />
    <path
      d="M0,0 L6,0 L4,4 L0,2 Z "
      fill="#4E9355"
      transform="translate(77,53)"
    />
  </svg>
)
export default IrelandLeague
