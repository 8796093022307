import React from 'react'
const Switzerland = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#ED2126"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M2,2 L-6,6 L-13,13 L-16,20 L-16,30 L-12,38 L-7,43 L-1,47 L2,48 L12,48 L20,44 L25,40 L29,32 L29,17 L25,11 L17,4 L12,2 Z "
      fill="#B4CDD2"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L8,0 L8,10 L18,10 L18,18 L8,18 L8,28 L0,28 L0,18 L-10,18 L-10,10 L0,10 Z "
      fill="#FFFEFE"
      transform="translate(21,11)"
    />
    <path
      d="M0,0 L9,0 L9,9 L7,7 L7,3 L2,3 Z "
      fill="#EA0F13"
      transform="translate(11,30)"
    />
    <path
      d="M0,0 L2,1 L-1,6 L-5,9 L-7,9 L-5,5 Z "
      fill="#B9C0C4"
      transform="translate(11,3)"
    />
  </svg>
)
export default Switzerland
