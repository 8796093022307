import * as React from 'react'
const PuertoRico = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L16,0 L26,6 L32,15 L33,17 L33,33 L27,43 L18,49 L16,50 L0,50 L-10,44 L-16,35 L-17,33 L-17,17 L-11,7 L-2,1 Z "
      fill="#FBF3F5"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L4,1 L27,14 L30,17 L20,23 L3,33 L1,33 L3,37 L-1,35 L-7,25 L-7,9 L-3,2 Z "
      fill="#3372F3"
      transform="translate(7,8)"
    />
    <path
      d="M0,0 L35,0 L33,4 L23,10 L7,10 L0,6 L-2,1 Z "
      fill="#EC0D0E"
      transform="translate(10,40)"
    />
    <path
      d="M0,0 L16,0 L26,6 L28,9 L-10,9 L-12,10 L-10,6 Z "
      fill="#F13737"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L19,0 L19,9 L1,9 L5,4 L0,1 Z "
      fill="#EF3436"
      transform="translate(31,20)"
    />
    <path
      d="M0,0 L1,4 L-1,9 L5,9 L6,4 L8,5 L8,9 L14,9 L12,12 L10,12 L11,18 L6,16 L2,18 L3,12 L0,11 L1,21 L3,27 L0,24 L-2,20 L-2,4 Z "
      fill="#DBDFFA"
      transform="translate(2,13)"
    />
    <path
      d="M0,0 L4,2 L3,8 L8,9 L17,12 L14,15 L9,18 L7,18 L9,22 L5,20 L1,11 L0,7 Z "
      fill="#1D5BEF"
      transform="translate(1,23)"
    />
    <path
      d="M0,0 L16,0 L25,6 L26,8 L-8,8 L-8,7 L20,6 L16,3 L12,2 L-2,2 Z "
      fill="#EE0605"
      transform="translate(19,1)"
    />
    <path
      d="M0,0 L19,0 L19,9 L1,9 L1,8 L16,7 L17,1 L0,1 Z "
      fill="#EF0203"
      transform="translate(31,20)"
    />
    <path
      d="M0,0 L2,1 L1,5 L-6,7 L-4,3 Z "
      fill="#E87986"
      transform="translate(11,3)"
    />
  </svg>
)
export default PuertoRico
