import React from 'react'

const Nba = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={98}
    viewBox="0 0 150 98"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,98 L0,98 Z "
      fill="#FCFCFC"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L17,0 L22,5 L22,18 L20,22 L23,26 L23,37 L21,44 L17,47 L0,47 Z "
      fill="#234280"
      transform="translate(78,25)"
    />
    <path
      d="M0,0 L5,0 L8,3 L8,24 L4,24 L2,24 L3,29 L8,30 L8,49 L7,51 L4,52 L-9,52 L-13,48 L-13,4 L-10,1 L-2,1 L-1,2 Z "
      fill="#F1EEF1"
      transform="translate(39,22)"
    />
    <path
      d="M0,0 L17,0 L19,18 L21,47 L12,47 L12,40 L5,40 L5,47 L-3,47 L-3,32 Z "
      fill="#254381"
      transform="translate(107,25)"
    />
    <path
      d="M0,0 L9,0 L12,12 L12,20 L14,20 L14,0 L22,0 L22,46 L13,46 L9,25 L8,46 L0,46 Z "
      fill="#1D3D7E"
      transform="translate(51,26)"
    />
    <path
      d="M0,0 L1,0 L2,7 L10,17 L14,20 L15,24 L15,31 L2,31 L0,29 Z "
      fill="#1C3E8A"
      transform="translate(27,42)"
    />
    <path
      d="M0,0 L5,0 L7,2 L7,23 L5,22 L2,10 L-1,8 Z "
      fill="#CE0B1C"
      transform="translate(40,23)"
    />
    <path
      d="M0,0 L1,0 L3,15 L3,25 L-2,25 L-1,5 Z "
      fill="#D0D5DF"
      transform="translate(115,33)"
    />
    <path
      d="M0,0 L8,0 L6,7 L2,8 L-1,14 L-2,14 L-2,2 Z "
      fill="#21408C"
      transform="translate(29,23)"
    />
    <path
      d="M0,0 L6,0 L6,19 L4,20 L1,5 Z "
      fill="#CE0E1B"
      transform="translate(41,52)"
    />
    <path
      d="M0,0 L4,0 L5,2 L5,13 L0,14 Z "
      fill="#EDEFF1"
      transform="translate(87,50)"
    />
    <path
      d="M0,0 L2,2 L3,12 L-2,13 L-4,9 L-3,4 L0,4 Z "
      fill="#E7B3B8"
      transform="translate(42,33)"
    />
    <path
      d="M0,0 L4,0 L4,11 L0,11 Z "
      fill="#ECEEF1"
      transform="translate(87,32)"
    />
    <path
      d="M0,0 L1,0 L3,11 L0,12 L-13,12 L-13,11 L-1,11 L-1,4 Z "
      fill="#E5DDE4"
      transform="translate(43,62)"
    />
    <path
      d="M0,0 L1,0 L3,7 L3,17 L11,17 L11,18 L2,18 L1,9 L-1,11 Z "
      fill="#CACFD9"
      transform="translate(101,55)"
    />
    <path
      d="M0,0 L2,0 L4,7 L1,7 Z "
      fill="#CD111D"
      transform="translate(38,38)"
    />
    <path
      d="M0,0 L1,0 L1,7 L-1,10 L-3,4 L0,4 Z "
      fill="#EFCFD2"
      transform="translate(42,33)"
    />
  </svg>
)
export default Nba
