import * as React from 'react'
const ClubFriendlies = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={171}
    viewBox="0 0 200 171"
    {...props}
  >
    <path
      d="M0 0 C8.52 7.2 13.02 15.35 16.38 25.87 C17.03 28.19 17.03 28.19 19.24 29.02 C19.97 31.56 20.6 34.06 21.18 36.64 C22.62 43.09 24.64 48.67 27.49 54.64 C28.39 57.5 27.91 59.15 27.24 62.02 C24.97 62.69 22.7 63.36 20.43 64.02 C19.16 64.39 17.9 64.76 16.59 65.14 C13.24 66.02 13.24 66.02 10.24 66.02 C10.15 67.66 10.15 67.66 10.05 69.33 C9.24 73.02 9.24 73.02 7.05 74.83 C4.15 76.06 1.23 77.03 -1.76 78.02 C-4.2 78.87 -6.64 79.72 -9.07 80.58 C-10.92 81.23 -10.92 81.23 -12.81 81.9 C-15.61 82.73 -15.61 82.73 -16.76 84.02 C-23.68 85.72 -29.19 84.45 -35.76 82.02 C-35.76 81.36 -35.76 80.7 -35.76 80.02 C-39.06 79.36 -42.36 78.7 -45.76 78.02 C-46.09 76.7 -46.42 75.38 -46.76 74.02 C-47.88 73.93 -47.88 73.93 -49.01 73.83 C-51.76 73.02 -51.76 73.02 -53.7 70.46 C-54.38 69.65 -55.06 68.85 -55.76 68.02 C-58.85 67.82 -58.85 67.82 -61.76 68.02 C-62.61 65.48 -63.44 62.94 -64.26 60.39 C-64.5 59.68 -64.74 58.97 -64.98 58.23 C-66.35 53.97 -66.81 50.53 -66.76 46.02 C-67.58 43.46 -67.58 43.46 -68.76 41.14 C-69.13 40.36 -69.5 39.58 -69.89 38.78 C-70.18 38.2 -70.46 37.62 -70.76 37.02 C-69.96 36.81 -69.15 36.6 -68.32 36.39 C-67.48 35.94 -66.63 35.48 -65.76 35.02 C-64.93 32.33 -64.93 32.33 -64.82 29.14 C-63.44 17.51 -57.75 7.65 -48.63 0.3 C-33.82 -10.23 -14.95 -10.72 0 0 Z "
      fill="#F2F3F3"
      transform="translate(123.76171875,20.98046875)"
    />
    <path
      d="M0 0 C8.52 7.2 13.02 15.35 16.38 25.87 C17.03 28.19 17.03 28.19 19.24 29.02 C19.24 30.01 19.24 31 19.24 32.02 C9.65 34.31 3.43 33.27 -5.76 30.02 C-2.76 29.02 -2.76 29.02 0.3 29.14 C1.75 29.08 1.75 29.08 3.24 29.02 C5.52 26.2 5.52 26.2 5.24 23.02 C3.62 23.26 3.62 23.26 1.96 23.5 C-1.47 23.94 -4.84 24.11 -8.3 24.16 C-18.85 24.33 -27.94 24.62 -37.75 28.76 C-40.74 30.01 -43.6 30.8 -46.76 31.52 C-53.64 33.13 -60.15 35.55 -66.76 38.02 C-66.76 40 -66.76 41.98 -66.76 44.02 C-68.65 41.63 -69.79 39.95 -70.76 37.02 C-69.96 36.81 -69.15 36.6 -68.32 36.39 C-67.48 35.94 -66.63 35.48 -65.76 35.02 C-64.93 32.33 -64.93 32.33 -64.82 29.14 C-63.44 17.51 -57.75 7.65 -48.63 0.3 C-33.82 -10.23 -14.95 -10.72 0 0 Z "
      fill="#14181B"
      transform="translate(123.76171875,20.98046875)"
    />
    <path
      d="M0 0 C0.7 0.35 1.41 0.7 2.13 1.05 C7.66 3.62 11.71 4.33 17.81 3.75 C19.06 3.64 20.3 3.53 21.59 3.41 C22.53 3.32 23.47 3.22 24.44 3.12 C24.69 3.98 24.95 4.84 25.21 5.72 C27.33 13 27.33 13 30.19 20 C31.62 23.59 31.53 26.3 31.44 30.12 C30.33 30.46 29.22 30.8 28.08 31.15 C26.61 31.6 25.15 32.05 23.69 32.5 C22.59 32.83 22.59 32.83 21.48 33.17 C17.97 34.25 14.65 35.32 11.44 37.12 C9.98 35.21 8.52 33.29 7.06 31.38 C6.65 30.84 6.24 30.3 5.81 29.74 C3.72 26.98 1.84 24.3 0.44 21.12 C0.77 20.8 1.1 20.47 1.44 20.12 C0.89 19.67 0.35 19.21 -0.21 18.73 C-0.92 18.12 -1.64 17.51 -2.38 16.88 C-3.08 16.27 -3.79 15.67 -4.52 15.05 C-6.5 13.19 -8.08 11.39 -9.56 9.12 C-9.56 8.47 -9.56 7.8 -9.56 7.12 C-13.03 8.61 -13.03 8.61 -16.56 10.12 C-16.56 9.13 -16.56 8.14 -16.56 7.12 C-17.55 7.45 -18.54 7.78 -19.56 8.12 C-20.55 7.8 -21.54 7.47 -22.56 7.12 C-23.22 7.78 -23.88 8.45 -24.56 9.12 C-24.41 6.83 -24.41 6.83 -23.56 4.12 C-16.02 -1.36 -8.69 -3.81 0 0 Z "
      fill="#F3F4F3"
      transform="translate(118.5625,49.875)"
    />
    <path
      d="M0 0 C2.21 0 4.42 -0.02 6.62 -0.11 C16.17 -0.38 16.17 -0.38 20 3 C24.93 7.67 24.93 7.67 25.12 11.5 C23.6 14.89 21.92 17.69 19 20 C15.86 20.95 12.89 21 9.62 20.94 C8.35 20.93 8.35 20.93 7.04 20.93 C2.91 20.73 0.61 20.44 -2.8 17.97 C-4.53 15.71 -5.86 13.6 -7 11 C-11.5 12.07 -15.75 13.14 -20 15 C-20.26 12.76 -20.26 12.76 -20 10 C-15.07 4.02 -8.03 -2.32 0 0 Z "
      fill="#808487"
      transform="translate(90,19)"
    />
    <path
      d="M0 0 C2.64 0 5.28 0 8 0 C7.67 5.94 7.34 11.88 7 18 C8.98 18 10.96 18 13 18 C13 12.06 13 6.12 13 0 C15.64 0 18.28 0 21 0 C21.5 8.42 21.5 8.42 22 17 C22.66 17 23.32 17 24 17 C24.33 11.39 24.66 5.78 25 0 C27.31 0 29.62 0 32 0 C32.09 3.27 32.14 6.54 32.19 9.81 C32.21 10.74 32.24 11.67 32.26 12.63 C32.27 13.52 32.28 14.41 32.29 15.33 C32.31 16.15 32.32 16.98 32.34 17.82 C31.92 20.51 31.13 21.36 29 23 C24.64 24.29 21.07 24.5 16.94 22.56 C16.3 22.05 15.66 21.53 15 21 C14.67 21.99 14.34 22.98 14 24 C9.38 24 4.76 24 0 24 C0 16.08 0 8.16 0 0 Z "
      fill="#C8C10D"
      transform="translate(86,112)"
    />
    <path
      d="M0 0 C2.45 2.4 4.52 4.89 6 8 C5.79 9.95 5.79 9.95 5 12 C4.85 12.84 4.71 13.68 4.55 14.55 C1.95 18.65 -2.35 19.07 -6.88 20.25 C-7.79 20.51 -8.7 20.76 -9.64 21.02 C-13.41 22.07 -17.2 23.06 -21 24 C-21.48 16.67 -20.37 10.59 -17 4 C-16.01 3.34 -15.02 2.68 -14 2 C-14 3.65 -14 5.3 -14 7 C-13.42 6.05 -12.84 5.1 -12.25 4.12 C-8.71 -0.8 -5.9 -2.12 0 0 Z "
      fill="#51595C"
      transform="translate(83,31)"
    />
    <path
      d="M0 0 C10.55 -0.49 10.55 -0.49 14 0 C16.47 2.32 16.47 2.32 18 5 C17.69 8.19 17.69 8.19 17 11 C17.33 11.66 17.66 12.32 18 13 C18.46 19.34 18.46 19.34 16.69 21.88 C11.31 25.46 7.16 24 0 24 C0 16.08 0 8.16 0 0 Z M7 6 C7 6.99 7 7.98 7 9 C8.32 8.34 9.64 7.68 11 7 C9.68 6.67 8.36 6.34 7 6 Z M7 14 C7 15.32 7 16.64 7 18 C8.32 17.67 9.64 17.34 11 17 C10.67 16.01 10.34 15.02 10 14 C9.01 14 8.02 14 7 14 Z "
      fill="#CFC80B"
      transform="translate(120,112)"
    />
    <path
      d="M0 0 C2.62 1.62 2.62 1.62 4 4 C4.25 7.25 4.25 7.25 4 10 C1.69 10 -0.62 10 -3 10 C-3.33 9.01 -3.66 8.02 -4 7 C-4.99 6.67 -5.98 6.34 -7 6 C-6.67 9.63 -6.34 13.26 -6 17 C-5.01 17 -4.02 17 -3 17 C-3 15.68 -3 14.36 -3 13 C-0.69 13 1.62 13 4 13 C4.49 18.4 4.49 18.4 2.12 21.31 C-1.65 24.31 -4.18 24.48 -9 24 C-13.4 21.92 -13.4 21.92 -15 19 C-16.03 12.82 -16.21 7.17 -13.12 1.62 C-8.69 -0.68 -4.89 -0.88 0 0 Z "
      fill="#CDC70E"
      transform="translate(81,112)"
    />
    <path
      d="M0 0 C2.64 1.32 5.28 2.64 8 4 C8 4.66 8 5.32 8 6 C9.32 6.66 10.64 7.32 12 8 C10.68 8.33 9.36 8.66 8 9 C11.3 13.62 14.6 18.24 18 23 C19.32 22.34 20.64 21.68 22 21 C24.18 20.26 26.36 19.55 28.56 18.88 C29.7 18.52 30.83 18.17 32 17.8 C35 17 35 17 38 17 C38.33 15.68 38.66 14.36 39 13 C39.5 15.97 39.5 15.97 40 19 C34.06 21.42 28.31 22.9 22 24 C21.91 25.64 21.91 25.64 21.81 27.31 C21 31 21 31 18.81 32.81 C15.91 34.04 12.99 35.01 10 36 C7.56 36.85 5.12 37.7 2.69 38.56 C1.45 39 0.22 39.43 -1.05 39.88 C-3.85 40.71 -3.85 40.71 -5 42 C-11.92 43.7 -17.43 42.43 -24 40 C-24 39.34 -24 38.68 -24 38 C-20.64 37.92 -17.58 38.06 -14.25 38.56 C-11.12 39.29 -11.12 39.29 -9 38 C-9 38.66 -9 39.32 -9 40 C-7.02 39.5 -7.02 39.5 -5 39 C-5 38.34 -5 37.68 -5 37 C-2.53 36.5 -2.53 36.5 0 36 C-1.67 32.06 -4.23 29.21 -7 26 C-4.43 27.1 -3.07 27.91 -1.31 30.12 C2.19 32.97 5.65 32.33 10 32 C10 31.34 10 30.68 10 30 C11.67 29.88 11.67 29.88 13.38 29.75 C17.06 29.42 17.06 29.42 18.44 26.94 C18.72 25.98 18.72 25.98 19 25 C18.01 24.67 17.02 24.34 16 24 C14.2 21.85 14.2 21.85 12.25 19.06 C11.55 18.07 10.86 17.09 10.14 16.07 C9.43 15.05 8.73 14.04 8 13 C6.99 11.59 6.99 11.59 5.96 10.15 C1.13 3.4 1.13 3.4 0 0 Z "
      fill="#1B1D20"
      transform="translate(112,63)"
    />
    <path
      d="M0 0 C1.37 0.02 1.37 0.02 2.78 0.04 C3.7 0.04 4.62 0.05 5.56 0.06 C6.27 0.07 6.98 0.09 7.71 0.1 C7.71 0.76 7.71 1.42 7.71 2.1 C8.7 1.44 9.69 0.78 10.71 0.1 C15.94 -0.45 15.94 -0.45 18.59 1.41 C19.71 3.1 19.71 3.1 19.71 6.1 C18.39 6.1 17.07 6.1 15.71 6.1 C15.38 5.44 15.05 4.78 14.71 4.1 C13.72 3.77 12.73 3.44 11.71 3.1 C11.71 4.09 11.71 5.08 11.71 6.1 C14.02 6.76 16.33 7.42 18.71 8.1 C19.04 10.08 19.37 12.06 19.71 14.1 C18.09 15.66 18.09 15.66 15.71 17.1 C12.59 16.79 12.59 16.79 9.71 16.1 C9.05 16.1 8.39 16.1 7.71 16.1 C4.05 16.1 0.38 16.1 -3.29 16.1 C-3.31 13.62 -3.33 11.14 -3.35 8.66 C-3.36 7.6 -3.36 7.6 -3.37 6.52 C-3.38 4.71 -3.34 2.9 -3.29 1.1 C-2.29 0.1 -2.29 0.1 0 0 Z M0.71 3.1 C0.71 4.42 0.71 5.74 0.71 7.1 C2.69 7.1 4.67 7.1 6.71 7.1 C6.71 8.09 6.71 9.08 6.71 10.1 C4.73 10.1 2.75 10.1 0.71 10.1 C0.71 11.09 0.71 12.08 0.71 13.1 C3.02 13.43 5.33 13.76 7.71 14.1 C7.71 12.78 7.71 11.46 7.71 10.1 C10.71 11.1 10.71 11.1 12.71 14.1 C13.7 13.77 14.69 13.44 15.71 13.1 C15.71 12.11 15.71 11.12 15.71 10.1 C14.6 9.69 13.49 9.27 12.34 8.85 C9.72 7.74 8.88 7.35 7.28 4.91 C7.09 4.31 6.91 3.71 6.71 3.1 C4.73 3.1 2.75 3.1 0.71 3.1 Z "
      fill="#B2AD17"
      transform="translate(134.28515625,136.90234375)"
    />
    <path
      d="M0 0 C3.47 1.12 5.63 3.17 8.16 5.7 C7.83 4.05 7.5 2.4 7.16 0.7 C7.82 0.7 8.48 0.7 9.16 0.7 C10 2.56 10.83 4.44 11.66 6.32 C12.12 7.36 12.58 8.41 13.06 9.48 C14.18 12.78 14.36 15.25 14.16 18.7 C13.17 18.37 12.18 18.04 11.16 17.7 C10.83 18.69 10.5 19.68 10.16 20.7 C8.51 20.37 6.86 20.04 5.16 19.7 C5.49 19.18 5.82 18.66 6.16 18.13 C7.48 14.92 7.29 12.14 7.16 8.7 C6.31 9.02 5.46 9.35 4.59 9.69 C0.95 10.75 -1.95 10.9 -5.72 10.82 C-6.87 10.8 -8.02 10.78 -9.21 10.77 C-10.08 10.74 -10.95 10.72 -11.84 10.7 C-12.04 6.92 -11.87 5.75 -9.91 2.32 C-6.89 -1.02 -4.23 -0.78 0 0 Z "
      fill="#51595B"
      transform="translate(122.84375,32.3046875)"
    />
    <path
      d="M0 0 C2.92 -0.11 5.83 -0.19 8.75 -0.25 C9.99 -0.3 9.99 -0.3 11.25 -0.35 C17.37 -0.45 17.37 -0.45 20.59 1.85 C22 4 22 4 21.81 6.62 C21 9 21 9 20 11 C20.89 13.61 20.89 13.61 22 16 C20.68 16 19.36 16 18 16 C16.25 13.5 16.25 13.5 15 11 C14.67 12.65 14.34 14.3 14 16 C12.68 16 11.36 16 10 16 C9.67 11.71 9.34 7.42 9 3 C8.67 3.33 8.34 3.66 8 4 C6.33 4.04 4.67 4.04 3 4 C3 4.99 3 5.98 3 7 C4.98 7 6.96 7 9 7 C9 7.99 9 8.98 9 10 C7.02 10 5.04 10 3 10 C3 11.98 3 13.96 3 16 C2.01 16 1.02 16 0 16 C0 10.72 0 5.44 0 0 Z M14 3 C14 4.32 14 5.64 14 7 C15.32 7 16.64 7 18 7 C18 6.01 18 5.02 18 4 C16.68 3.67 15.36 3.34 14 3 Z "
      fill="#B1AC1E"
      transform="translate(51,137)"
    />
    <path
      d="M0 0 C1.74 0.15 1.74 0.15 3.52 0.31 C4.4 0.39 5.28 0.48 6.19 0.56 C5.86 1.22 5.53 1.88 5.19 2.56 C-0.75 2.56 -6.69 2.56 -12.81 2.56 C-12.32 3.55 -12.32 3.55 -11.81 4.56 C-7.19 4.56 -2.57 4.56 2.19 4.56 C2.19 4.89 2.19 5.22 2.19 5.56 C1.44 5.62 0.69 5.68 -0.08 5.75 C-1.56 5.87 -1.56 5.87 -3.06 6 C-4.52 6.12 -4.52 6.12 -6.02 6.25 C-8.38 6.51 -10.53 6.92 -12.81 7.56 C-13.31 6.57 -13.31 6.57 -13.81 5.56 C-20.52 5.91 -25.64 9.76 -30.54 14.09 C-32.28 16.1 -32.46 17.97 -32.81 20.56 C-32.15 20.89 -31.49 21.22 -30.81 21.56 C-32.13 22.88 -33.45 24.2 -34.81 25.56 C-34.81 23.91 -34.81 22.26 -34.81 20.56 C-37.92 25.97 -39.29 30.13 -39.64 36.36 C-39.81 38.56 -39.81 38.56 -40.81 41.56 C-39.82 41.89 -38.83 42.22 -37.81 42.56 C-40.45 43.55 -43.09 44.54 -45.81 45.56 C-45.81 47.54 -45.81 49.52 -45.81 51.56 C-47.7 49.18 -48.84 47.49 -49.81 44.56 C-49.01 44.35 -48.2 44.14 -47.37 43.93 C-46.53 43.48 -45.68 43.03 -44.81 42.56 C-43.99 39.87 -43.99 39.87 -43.88 36.69 C-42.49 25.05 -36.8 15.19 -27.68 7.85 C-22.71 4.31 -17.72 2.06 -11.81 0.56 C-10.65 0.26 -10.65 0.26 -9.47 -0.04 C-6.21 -0.53 -3.28 -0.3 0 0 Z "
      fill="#15191D"
      transform="translate(102.8125,13.4375)"
    />
    <path
      d="M0 0 C2.4 -0.11 4.79 -0.19 7.19 -0.25 C7.86 -0.28 8.53 -0.32 9.23 -0.35 C14.25 -0.45 16.32 0.73 20 4 C23.24 3.86 23.24 3.86 26 3 C25.67 3.99 25.34 4.98 25 6 C24.01 6 23.02 6 22 6 C22.33 6.99 22.66 7.98 23 9 C25.02 9.73 25.02 9.73 27 10 C27 9.34 27 8.68 27 8 C29.71 6.65 32.01 6.93 35 7 C35 7.99 35 8.98 35 10 C34.44 9.75 33.89 9.5 33.31 9.25 C30.76 8.72 30.76 8.72 28.25 10.94 C27.51 11.62 26.77 12.3 26 13 C26.33 14.32 26.66 15.64 27 17 C23.7 16.34 20.4 15.68 17 15 C16.67 13.68 16.34 12.36 16 11 C15.26 10.94 14.52 10.88 13.75 10.81 C11 10 11 10 9.06 7.44 C7.18 4.64 7.18 4.64 3.75 4.69 C2.39 4.84 2.39 4.84 1 5 C0.67 3.35 0.34 1.7 0 0 Z "
      fill="#727475"
      transform="translate(61,84)"
    />
    <path
      d="M0 0 C0.8 0.3 1.6 0.59 2.42 0.9 C3.03 1.14 3.63 1.38 4.25 1.62 C4.58 0.63 4.91 -0.36 5.25 -1.38 C8.07 -0.34 9.11 0.39 10.66 3.02 C11.06 3.96 11.46 4.9 11.88 5.88 C12.51 7.35 12.51 7.35 13.15 8.85 C13.99 10.96 14.73 13.1 15.44 15.25 C16 17.79 16 17.79 18.25 18.62 C18.25 19.61 18.25 20.61 18.25 21.62 C8.67 23.92 2.44 22.87 -6.75 19.62 C-3.75 18.62 -3.75 18.62 -0.69 18.75 C0.28 18.71 1.25 18.67 2.25 18.62 C4.53 15.81 4.53 15.81 4.25 12.62 C-1.36 12.62 -6.97 12.62 -12.75 12.62 C-12.75 12.3 -12.75 11.97 -12.75 11.62 C-11.95 11.56 -11.16 11.5 -10.34 11.44 C-2.51 10.83 -2.51 10.83 5.25 9.62 C6.48 16.03 6.48 16.03 5.31 19.06 C4.79 19.84 4.79 19.84 4.25 20.62 C5.57 20.95 6.89 21.28 8.25 21.62 C8.25 18.98 8.25 16.34 8.25 13.62 C8.58 13.62 8.91 13.62 9.25 13.62 C9.37 14.43 9.5 15.23 9.62 16.06 C9.83 16.91 10.04 17.75 10.25 18.62 C10.91 18.95 11.57 19.28 12.25 19.62 C10.08 10.53 10.08 10.53 7.25 1.62 C6.59 1.62 5.93 1.62 5.25 1.62 C5.77 2.72 6.28 3.81 6.81 4.94 C8.25 8.62 8.25 8.62 7.25 11.62 C6.95 11.07 6.64 10.52 6.33 9.95 C3.78 5.4 3.78 5.4 -0.19 2.19 C-3.03 1.56 -5.86 1.03 -8.75 0.62 C-5.34 -1.55 -3.73 -1.43 0 0 Z "
      fill="#13171B"
      transform="translate(124.75,31.375)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C6 1 6 1 6.1 4.6 C6.09 6.09 6.08 7.57 6.06 9.06 C6.06 9.82 6.05 10.58 6.05 11.36 C6.04 13.24 6.02 15.12 6 17 C7.98 17 9.96 17 12 17 C12 18.65 12 20.3 12 22 C8.04 22 4.08 22 0 22 C0 14.74 0 7.48 0 0 Z "
      fill="#E8DF03"
      transform="translate(87,113)"
    />
    <path
      d="M0 0 C-4.95 2.48 -4.95 2.48 -10 5 C-8.88 6.32 -8.88 6.32 -6 6.44 C-5.01 6.62 -4.02 6.81 -3 7 C-2.67 7.66 -2.34 8.32 -2 9 C-0.58 8.26 -0.58 8.26 0.88 7.5 C4 6 4 6 6 6 C5.69 6.87 5.69 6.87 5.38 7.75 C4.91 10.52 5.57 11.65 7 14 C6.46 13.48 5.93 12.97 5.38 12.44 C3.04 10.76 3.04 10.76 0.06 10.94 C-3.27 11.8 -3.27 11.8 -5.5 15 C-8 18 -8 18 -11.19 18.94 C-14 19 -14 19 -16 18 C-14.01 5.64 -14.01 5.64 -9.62 2.06 C-8.76 1.71 -7.89 1.36 -7 1 C-6.11 0.59 -5.23 0.17 -4.31 -0.25 C-2 -1 -2 -1 0 0 Z "
      fill="#C2C3C4"
      transform="translate(105,50)"
    />
    <path
      d="M0 0 C1.16 0.19 2.31 0.37 3.5 0.56 C4.33 0.71 5.15 0.85 6 1 C6.31 3.81 6.31 3.81 6 7 C3.94 8.94 3.94 8.94 1 10 C-3.88 9.73 -8.36 8.42 -13 7 C-13 3 -13 3 -11 0.69 C-7.06 -1.53 -4.22 -0.91 0 0 Z "
      fill="#B9BABA"
      transform="translate(100,93)"
    />
    <path
      d="M0 0 C8 0 8 0 10.48 1.15 C12.57 3.69 12.47 5.31 12.38 8.56 C12.36 9.53 12.35 10.5 12.34 11.5 C12 14 12 14 10 16 C7.62 16.2 7.62 16.2 4.88 16.12 C3.27 16.08 1.66 16.04 0 16 C0 10.72 0 5.44 0 0 Z M4 4 C4 6.97 4 9.94 4 13 C5.32 13 6.64 13 8 13 C8 10.03 8 7.06 8 4 C6.68 4 5.36 4 4 4 Z "
      fill="#AAA618"
      transform="translate(104,137)"
    />
    <path
      d="M0 0 C1.32 0.33 2.64 0.66 4 1 C4 4.63 4 8.26 4 12 C5.65 12.33 7.3 12.66 9 13 C9 8.71 9 4.42 9 0 C10.32 0 11.64 0 13 0 C13 5.28 13 10.56 13 16 C8.71 16 4.42 16 0 16 C0 10.72 0 5.44 0 0 Z "
      fill="#646536"
      transform="translate(117,137)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C5.32 2.31 6.64 4.62 8 7 C8.33 4.69 8.66 2.38 9 0 C9.99 0 10.98 0 12 0 C12 5.28 12 10.56 12 16 C10.35 15.67 8.7 15.34 7 15 C5.19 11.94 5.19 11.94 4 9 C3.67 11.31 3.34 13.62 3 16 C2.01 16 1.02 16 0 16 C0 10.72 0 5.44 0 0 Z "
      fill="#C5BF15"
      transform="translate(90,137)"
    />
    <path
      d="M0 0 C7.07 0.18 12.34 4.79 17.56 9.12 C19 11 19 11 19 15 C12.99 14.06 7.54 12.96 3.69 8.06 C3.13 7.05 2.57 6.04 2 5 C1.01 3.66 0.02 2.32 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z "
      fill="#7E8185"
      transform="translate(110,18)"
    />
    <path
      d="M0 0 C1.37 0.02 1.37 0.02 2.78 0.04 C3.7 0.04 4.62 0.05 5.56 0.06 C6.27 0.07 6.98 0.09 7.71 0.1 C8.04 1.09 8.37 2.08 8.71 3.1 C6.4 3.43 4.09 3.76 1.71 4.1 C1.38 5.09 1.05 6.08 0.71 7.1 C2.69 7.1 4.67 7.1 6.71 7.1 C6.71 8.09 6.71 9.08 6.71 10.1 C4.73 10.1 2.75 10.1 0.71 10.1 C0.71 11.09 0.71 12.08 0.71 13.1 C2.69 13.1 4.67 13.1 6.71 13.1 C7.04 14.09 7.37 15.08 7.71 16.1 C4.08 16.1 0.45 16.1 -3.29 16.1 C-3.31 13.62 -3.33 11.14 -3.35 8.66 C-3.36 7.6 -3.36 7.6 -3.37 6.52 C-3.38 4.71 -3.34 2.9 -3.29 1.1 C-2.29 0.1 -2.29 0.1 0 0 Z "
      fill="#7E7C2A"
      transform="translate(134.28515625,136.90234375)"
    />
    <path
      d="M0 0 C3.3 0 6.6 0 10 0 C9.67 1.32 9.34 2.64 9 4 C7.35 4 5.7 4 4 4 C3.67 4.99 3.34 5.98 3 7 C5.31 7 7.62 7 10 7 C9.67 7.99 9.34 8.98 9 10 C7.02 10 5.04 10 3 10 C3 10.99 3 11.98 3 13 C5.31 13 7.62 13 10 13 C10 13.99 10 14.98 10 16 C6.7 16 3.4 16 0 16 C0 10.72 0 5.44 0 0 Z "
      fill="#939125"
      transform="translate(79,137)"
    />
    <path
      d="M0 0 C-0.75 4.75 -0.75 4.75 -3 7 C-5.79 7.01 -5.79 7.01 -9.06 6.69 C-10.69 6.54 -10.69 6.54 -12.35 6.39 C-15 6 -15 6 -16 5 C-17.52 4.93 -19.04 4.92 -20.56 4.94 C-21.8 4.95 -21.8 4.95 -23.07 4.96 C-24.02 4.98 -24.02 4.98 -25 5 C-21.25 1.07 -17.95 0.59 -12.69 -0.12 C-12.02 -0.22 -11.36 -0.32 -10.67 -0.43 C-6.86 -0.95 -3.72 -1.02 0 0 Z "
      fill="#ECECEC"
      transform="translate(130,44)"
    />
    <path
      d="M0 0 C1.32 0.33 2.64 0.66 4 1 C3.34 1 2.68 1 2 1 C2 2.98 2 4.96 2 7 C3.95 6.81 3.95 6.81 6 6 C7.28 4.02 7.28 4.02 8 2 C7.34 1.67 6.68 1.34 6 1 C7.32 1 8.64 1 10 1 C11.26 5.52 11.26 5.52 9.94 8 C8.68 10.08 8.68 10.08 9.88 12.75 C10.25 13.49 10.62 14.24 11 15 C9.68 15 8.36 15 7 15 C5.25 12.5 5.25 12.5 4 10 C3.67 11.65 3.34 13.3 3 15 C2.01 15 1.02 15 0 15 C0 10.05 0 5.1 0 0 Z "
      fill="#C4BE13"
      transform="translate(62,138)"
    />
    <path
      d="M0 0 C3.63 0.33 7.26 0.66 11 1 C10.67 2.65 10.34 4.3 10 6 C11.28 6.23 12.56 6.45 13.88 6.69 C16.09 7.22 16.09 7.22 18 8 C19.5 10.62 19.5 10.62 20 13 C21.65 13 23.3 13 25 13 C25.66 14.32 26.32 15.64 27 17 C23.7 16.34 20.4 15.68 17 15 C16.67 13.68 16.34 12.36 16 11 C15.26 10.94 14.52 10.88 13.75 10.81 C11 10 11 10 9.06 7.44 C7.18 4.64 7.18 4.64 3.75 4.69 C2.39 4.84 2.39 4.84 1 5 C0.67 3.35 0.34 1.7 0 0 Z "
      fill="#191B1D"
      transform="translate(61,84)"
    />
    <path
      d="M0 0 C0.69 -0 1.38 -0.01 2.09 -0.01 C7.25 -0 7.25 -0 9.5 1.12 C9.5 1.78 9.5 2.45 9.5 3.12 C8.49 3.25 7.47 3.37 6.43 3.49 C5.08 3.66 3.73 3.83 2.38 4 C1.71 4.08 1.04 4.16 0.36 4.24 C-3.8 4.77 -7.57 5.67 -11.5 7.12 C-10.9 5.1 -10.23 3.1 -9.5 1.12 C-6.47 -0.39 -3.32 -0.02 0 0 Z "
      fill="#777A7E"
      transform="translate(99.5,39.875)"
    />
    <path
      d="M0 0 C1.32 0.33 2.64 0.66 4 1 C4 5.95 4 10.9 4 16 C2.68 16 1.36 16 0 16 C0 10.72 0 5.44 0 0 Z "
      fill="#9A951B"
      transform="translate(74,137)"
    />
    <path
      d="M0 0 C0 1.98 0 3.96 0 6 C-3.1 7.03 -4.06 7.18 -7 6 C-7.33 5.01 -7.66 4.02 -8 3 C-8.99 3 -9.98 3 -11 3 C-8.59 -1.81 -4.66 -1.06 0 0 Z "
      fill="#969798"
      transform="translate(106,94)"
    />
    <path
      d="M0 0 C3.96 1.98 3.96 1.98 8 4 C8 4.66 8 5.32 8 6 C9.32 6.66 10.64 7.32 12 8 C10.68 8.33 9.36 8.66 8 9 C12.95 15.93 12.95 15.93 18 23 C17.67 23.66 17.34 24.32 17 25 C1.53 4.58 1.53 4.58 0 0 Z "
      fill="#4A4B4D"
      transform="translate(112,63)"
    />
    <path
      d="M0 0 C-0.31 0.87 -0.31 0.87 -0.62 1.75 C-1.09 4.52 -0.43 5.65 1 8 C0.46 7.48 -0.07 6.97 -0.62 6.44 C-2.96 4.76 -2.96 4.76 -5.94 4.94 C-9.27 5.8 -9.27 5.8 -11.5 9 C-14 12 -14 12 -17.31 12.88 C-18.64 12.94 -18.64 12.94 -20 13 C-18.34 11.89 -16.67 10.81 -14.94 9.81 C-12.2 7.26 -11.81 4.58 -11 1 C-10.34 1 -9.68 1 -9 1 C-8.67 1.66 -8.34 2.32 -8 3 C-7.24 2.51 -6.47 2.01 -5.69 1.5 C-3 0 -3 0 0 0 Z "
      fill="#838586"
      transform="translate(111,56)"
    />
    <path
      d="M0 0 C2.64 0 5.28 0 8 0 C8 0.33 8 0.66 8 1 C6.02 1 4.04 1 2 1 C2 2.65 2 4.3 2 6 C4.31 6 6.62 6 9 6 C8.67 6.99 8.34 7.98 8 9 C6.02 9 4.04 9 2 9 C2 10.65 2 12.3 2 14 C1.34 14 0.68 14 0 14 C0 9.38 0 4.76 0 0 Z "
      fill="#C4BF11"
      transform="translate(80,138)"
    />
    <path
      d="M0 0 C1.2 5.45 0.91 8.22 -2 13 C-5.3 12.01 -8.6 11.02 -12 10 C-9 9 -9 9 -5.94 9.12 C-4.97 9.08 -4 9.04 -3 9 C-0.72 6.18 -0.72 6.18 -1 3 C-6.61 3 -12.22 3 -18 3 C-18 2.67 -18 2.34 -18 2 C-17.16 1.94 -16.31 1.88 -15.45 1.82 C-14.35 1.73 -13.25 1.65 -12.12 1.56 C-11.03 1.48 -9.94 1.4 -8.82 1.32 C-5.36 0.93 -3.65 0 0 0 Z "
      fill="#2A2C2E"
      transform="translate(130,41)"
    />
    <path
      d="M0 0 C1.91 0.23 1.91 0.23 3.91 2.23 C2.92 3.72 2.92 3.72 1.91 5.23 C0.92 4.9 -0.07 4.57 -1.09 4.23 C-1.42 5.22 -1.75 6.21 -2.09 7.23 C-1.1 7.56 -0.11 7.89 0.91 8.23 C-0.41 8.23 -1.73 8.23 -3.09 8.23 C-3.09 6.25 -3.09 4.27 -3.09 2.23 C-3.75 1.9 -4.41 1.57 -5.09 1.23 C-5.09 6.18 -5.09 11.13 -5.09 16.23 C-5.42 16.23 -5.75 16.23 -6.09 16.23 C-6.28 13.75 -6.47 11.27 -6.65 8.79 C-6.71 8.09 -6.76 7.38 -6.82 6.65 C-6.95 4.85 -7.03 3.04 -7.09 1.23 C-5.19 -0.66 -2.52 0.05 0 0 Z "
      fill="#5F6135"
      transform="translate(67.08984375,136.76953125)"
    />
    <path
      d="M0 0 C0.69 1.62 0.69 1.62 1 4 C0.02 6.01 -0.98 8.01 -2 10 C-2.17 11.24 -2.33 12.48 -2.5 13.75 C-2.67 14.82 -2.83 15.89 -3 17 C-3.99 17.66 -4.98 18.32 -6 19 C-5.72 12.24 -5.08 5.08 0 0 Z "
      fill="#707377"
      transform="translate(68,33)"
    />
    <path
      d="M0 0 C0.99 0 1.98 0 3 0 C3 4.95 3 9.9 3 15 C2.01 15 1.02 15 0 15 C0 10.05 0 5.1 0 0 Z "
      fill="#D5CE0C"
      transform="translate(127,138)"
    />
    <path
      d="M0 0 C0.99 0.33 1.98 0.66 3 1 C2.67 5.29 2.34 9.58 2 14 C3.98 14 5.96 14 8 14 C8 14.66 8 15.32 8 16 C5.36 16 2.72 16 0 16 C0 10.72 0 5.44 0 0 Z "
      fill="#CEC710"
      transform="translate(117,137)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2.85 1.87 3.68 3.75 4.5 5.62 C4.96 6.67 5.43 7.71 5.91 8.79 C7.03 12.08 7.21 14.55 7 18 C6.01 17.67 5.02 17.34 4 17 C3.82 15.99 3.82 15.99 3.63 14.95 C3.47 14.06 3.3 13.17 3.12 12.25 C2.96 11.37 2.8 10.49 2.63 9.58 C2.1 7.01 2.1 7.01 0.93 4.86 C0 3 0 3 0 0 Z "
      fill="#727578"
      transform="translate(130,33)"
    />
    <path
      d="M0 0 C2.79 -0.05 5.58 -0.09 8.38 -0.12 C9.17 -0.14 9.96 -0.16 10.78 -0.18 C12.85 -0.19 14.93 -0.1 17 0 C17.66 0.66 18.32 1.32 19 2 C17.02 2.99 17.02 2.99 15 4 C15 3.34 15 2.68 15 2 C10.38 2.33 5.76 2.66 1 3 C0.67 2.01 0.34 1.02 0 0 Z "
      fill="#4A5254"
      transform="translate(90,16)"
    />
    <path
      d="M0 0 C3.36 -0.08 6.42 0.06 9.75 0.56 C12.88 1.29 12.88 1.29 15 0 C15 0.66 15 1.32 15 2 C15.66 2 16.32 2 17 2 C17 2.66 17 3.32 17 4 C10.64 5.52 6.08 4.17 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#0F1113"
      transform="translate(88,101)"
    />
    <path
      d="M0 0 C2.88 -0.12 2.88 -0.12 6 0 C6.66 0.66 7.32 1.32 8 2 C7.62 4.62 7.62 4.62 7 7 C4.69 6.01 2.38 5.02 0 4 C0 2.68 0 1.36 0 0 Z "
      fill="#CECFCF"
      transform="translate(71,86)"
    />
    <path
      d="M0 0 C1.08 3.25 0.99 5.18 0.69 8.56 C0.61 9.53 0.53 10.5 0.45 11.5 C0 14 0 14 -2 16 C-2.99 16 -3.98 16 -5 16 C-4.71 15.38 -4.42 14.76 -4.12 14.12 C-2.82 10.49 -2.14 6.9 -1.46 3.11 C-1 1 -1 1 0 0 Z "
      fill="#373C3F"
      transform="translate(67,39)"
    />
    <path
      d="M0 0 C0.8 0.3 1.6 0.59 2.42 0.9 C3.03 1.14 3.63 1.38 4.25 1.62 C4.58 0.63 4.91 -0.36 5.25 -1.38 C6.35 2.95 7.43 7.24 8.25 11.62 C7.86 11.07 7.47 10.51 7.06 9.94 C2.26 3.81 -1.14 1.74 -8.75 0.62 C-5.34 -1.55 -3.73 -1.43 0 0 Z "
      fill="#262A2D"
      transform="translate(124.75,31.375)"
    />
    <path
      d="M0 0 C1.23 0.01 1.23 0.01 2.48 0.03 C3.42 0.04 3.42 0.04 4.38 0.06 C2.89 2.04 2.89 2.04 1.38 4.06 C2.03 4.39 2.69 4.72 3.38 5.06 C2.53 4.82 2.53 4.82 1.67 4.57 C-0.76 4.03 -2.89 3.96 -5.38 4 C-6.57 4.01 -6.57 4.01 -7.8 4.03 C-8.7 4.04 -8.7 4.04 -9.62 4.06 C-6.41 0.7 -4.65 -0.07 0 0 Z "
      fill="#818283"
      transform="translate(114.625,44.9375)"
    />
    <path
      d="M0 0 C3.32 0.57 4.74 1.54 7 4 C7.99 4.66 8.98 5.32 10 6 C4.67 7.33 4.67 7.33 1.62 5.81 C0 4 0 4 0 0 Z "
      fill="#9B981C"
      transform="translate(142,147)"
    />
    <path
      d="M0 0 C2.64 0 5.28 0 8 0 C8 0.33 8 0.66 8 1 C6.02 1 4.04 1 2 1 C2 2.65 2 4.3 2 6 C3.65 6 5.3 6 7 6 C7 6.33 7 6.66 7 7 C5.35 7.33 3.7 7.66 2 8 C1.67 9.98 1.34 11.96 1 14 C0.67 14 0.34 14 0 14 C0 9.38 0 4.76 0 0 Z "
      fill="#E0D80A"
      transform="translate(133,138)"
    />
    <path
      d="M0 0 C2.62 0.38 2.62 0.38 5 1 C5 1.99 5 2.98 5 4 C4.34 4 3.68 4 3 4 C3 4.66 3 5.32 3 6 C1.35 5.67 -0.3 5.34 -2 5 C-2 2 -2 2 0 0 Z "
      fill="#CDCECF"
      transform="translate(85,88)"
    />
    <path
      d="M0 0 C0.33 0.99 0.66 1.98 1 3 C-1 5 -1 5 -4.62 5.12 C-5.74 5.08 -6.85 5.04 -8 5 C-8.33 5.66 -8.66 6.32 -9 7 C-9 6.01 -9 5.02 -9 4 C-9.66 4 -10.32 4 -11 4 C-10.67 3.34 -10.34 2.68 -10 2 C-9.22 2.02 -8.43 2.04 -7.62 2.06 C-5.02 2.19 -5.02 2.19 -3 1 C-2.01 1.33 -1.02 1.66 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#B0B1B1"
      transform="translate(130,88)"
    />
    <path
      d="M0 0 C0.66 0.66 1.32 1.32 2 2 C1.62 4.63 1.62 4.63 1 7 C-1.31 7.33 -3.62 7.66 -6 8 C-6.33 7.01 -6.66 6.02 -7 5 C-3.53 4.5 -3.53 4.5 0 4 C0 2.68 0 1.36 0 0 Z "
      fill="#202325"
      transform="translate(150,76)"
    />
    <path
      d="M0 0 C0 0.33 0 0.66 0 1 C-0.72 1.05 -1.45 1.1 -2.2 1.15 C-7.41 1.55 -12.1 2.01 -17 4 C-17 3.01 -17 2.02 -17 1 C-11.02 -1.21 -6.26 -0.93 0 0 Z "
      fill="#2D2F31"
      transform="translate(108,44)"
    />
    <path
      d="M0 0 C0.33 1.32 0.66 2.64 1 4 C2.32 4.33 3.64 4.66 5 5 C3 7 3 7 0.38 7.12 C-0.41 7.08 -1.19 7.04 -2 7 C-2 3 -2 3 0 0 Z "
      fill="#E7E7E7"
      transform="translate(98,96)"
    />
    <path
      d="M0 0 C-0.33 1.65 -0.66 3.3 -1 5 C-1.33 4.34 -1.66 3.68 -2 3 C-3.67 2.66 -5.33 2.33 -7 2 C-7.66 1.34 -8.32 0.68 -9 0 C-5.67 -1.11 -3.38 -0.84 0 0 Z "
      fill="#AFB0B1"
      transform="translate(130,44)"
    />
    <path
      d="M0 0 C1.33 2.67 2.67 5.33 4 8 C2.35 7.67 0.7 7.34 -1 7 C-1.62 4.62 -1.62 4.62 -2 2 C-1.34 1.34 -0.68 0.68 0 0 Z "
      fill="#E5E5E5"
      transform="translate(81,90)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2 0.66 2 1.32 2 2 C2.99 1.67 3.98 1.34 5 1 C5.62 3.88 5.62 3.88 6 7 C5.34 7.66 4.68 8.32 4 9 C0 2.25 0 2.25 0 0 Z "
      fill="#9E991B"
      transform="translate(92,141)"
    />
    <path
      d="M0 0 C2.31 0 4.62 0 7 0 C5.51 2.47 5.51 2.47 4 5 C3.34 4.67 2.68 4.34 2 4 C1.34 4 0.68 4 0 4 C-1.34 3.69 -2.67 3.35 -4 3 C-3.01 3 -2.02 3 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
      fill="#B4B6B6"
      transform="translate(125,68)"
    />
    <path
      d="M0 0 C-0.33 0.99 -0.66 1.98 -1 3 C-2.65 3 -4.3 3 -6 3 C-6 3.66 -6 4.32 -6 5 C-7.32 5.33 -8.64 5.66 -10 6 C-9.67 5.01 -9.34 4.02 -9 3 C-8.34 3 -7.68 3 -7 3 C-7 2.34 -7 1.68 -7 1 C-2.25 0 -2.25 0 0 0 Z "
      fill="#D6D7D7"
      transform="translate(113,97)"
    />
    <path
      d="M0 0 C1.65 1.65 3.3 3.3 5 5 C4.34 6.32 3.68 7.64 3 9 C0.94 6.19 0.94 6.19 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
      fill="#C8CACA"
      transform="translate(111,64)"
    />
    <path
      d="M0 0 C-0.66 1.32 -1.32 2.64 -2 4 C-2.99 3.67 -3.98 3.34 -5 3 C-5.33 3.99 -5.66 4.98 -6 6 C-5.01 6.33 -4.02 6.66 -3 7 C-4.32 7 -5.64 7 -7 7 C-7 5.02 -7 3.04 -7 1 C-4.54 -0.23 -2.72 -0.07 0 0 Z "
      fill="#8D8926"
      transform="translate(71,138)"
    />
    <path
      d="M0 0 C0.43 0.45 0.87 0.91 1.31 1.38 C3.11 3.2 3.11 3.2 6 5 C3.69 5 1.38 5 -1 5 C-1 1 -1 1 0 0 Z "
      fill="#E8E9E9"
      transform="translate(88,95)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2 1.98 2 3.96 2 6 C1.01 6.33 0.02 6.66 -1 7 C-1.99 6.34 -2.98 5.68 -4 5 C-3.34 4.36 -2.68 3.72 -2 3.06 C0.12 1.36 0.12 1.36 0 0 Z "
      fill="#6C6D6E"
      transform="translate(104,94)"
    />
    <path
      d="M0 0 C3.96 1.98 3.96 1.98 8 4 C7.67 4.99 7.34 5.98 7 7 C6.01 7 5.02 7 4 7 C0 2.66 0 2.66 0 0 Z "
      fill="#383A3B"
      transform="translate(112,63)"
    />
    <path
      d="M0 0 C2.47 0.5 2.47 0.5 5 1 C4.69 2.88 4.69 2.88 4 5 C3.01 5.66 2.02 6.32 1 7 C1.33 5.02 1.66 3.04 2 1 C1.34 0.67 0.68 0.34 0 0 Z "
      fill="#53575A"
      transform="translate(85,18)"
    />
    <path
      d="M0 0 C0.99 1.32 1.98 2.64 3 4 C0.36 3.67 -2.28 3.34 -5 3 C-5 2.34 -5 1.68 -5 1 C-3.35 0.67 -1.7 0.34 0 0 Z "
      fill="#08090B"
      transform="translate(85,97)"
    />
  </svg>
)
export default ClubFriendlies
