import React from 'react'
const Germany = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#ED1B25"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M2,2 L-6,6 L-13,13 L-16,20 L-16,30 L-14,33 L28,33 L30,30 L30,20 L26,12 L19,5 L12,2 Z "
      fill="#FCCB0B"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L10,0 L18,4 L25,11 L26,15 L-16,15 L-14,10 L-7,3 Z "
      fill="#221E1F"
      transform="translate(20,2)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M2,2 L-6,6 L-13,13 L-16,20 L-16,30 L-12,38 L-5,45 L2,48 L11,48 L20,44 L25,39 L28,35 L30,30 L30,20 L26,12 L19,5 L12,2 Z "
      fill="#B8C9D3"
      transform="translate(18,0)"
    />
  </svg>
)
export default Germany
