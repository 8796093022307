import * as React from 'react'
const OlympicsParis = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={163}
    viewBox="0 0 200 163"
    {...props}
  >
    <path
      d="M0,0 L13,0 L24,4 L33,10 L40,18 L44,27 L45,31 L45,47 L41,58 L34,67 L26,73 L16,77 L17,74 L24,68 L28,59 L22,63 L21,60 L24,56 L25,53 L25,42 L21,34 L10,24 L7,18 L6,8 L-1,16 L-5,25 L-6,29 L-6,44 L-4,52 L-9,50 L-15,43 L-16,47 L-16,54 L-13,64 L-9,70 L-3,75 L-4,77 L-16,71 L-23,65 L-29,55 L-32,46 L-32,32 L-29,22 L-21,11 L-13,5 L-4,1 Z "
      fill="#D5C278"
      transform="translate(96,13)"
    />
    <path
      d="M0,0 L7,0 L12,4 L13,8 L15,8 L16,4 L21,0 L28,0 L33,3 L35,7 L40,1 L49,0 L54,3 L56,6 L56,15 L49,21 L46,22 L42,28 L39,30 L32,30 L27,27 L24,24 L19,29 L17,30 L11,30 L5,26 L4,21 L-2,20 L-7,14 L-7,7 L-4,2 Z M0,3 L-4,7 L-4,14 L1,18 L4,18 L7,12 L11,10 L9,5 L6,3 Z M22,3 L17,8 L17,10 L21,12 L24,18 L27,14 L30,11 L32,10 L30,5 L27,3 Z M43,3 L39,6 L38,10 L43,13 L46,18 L50,17 L53,14 L53,7 L49,3 Z M13,12 L10,18 L6,21 L9,26 L11,27 L17,27 L21,23 L21,20 L16,16 Z M35,12 L31,18 L28,20 L29,24 L33,27 L40,26 L43,21 L38,17 L36,12 Z M9,13 L9,16 Z M18,13 L19,16 L19,13 Z M31,13 Z M39,13 L41,17 L41,14 Z M30,14 Z M29,15 Z M7,16 Z M20,16 Z M28,16 Z "
      fill="#25A964"
      transform="translate(78,122)"
    />
    <path
      d="M0,0 L6,0 L11,3 L13,6 L13,15 L6,21 L-3,20 L-7,15 L-7,11 L-5,11 L-2,16 L0,17 L6,17 L9,16 L9,5 L0,4 L-3,6 L-5,10 L-7,10 L-6,4 L-3,1 Z "
      fill="#E65467"
      transform="translate(121,122)"
    />
    <path
      d="M0,0 L7,0 L12,4 L14,9 L12,17 L8,20 L6,20 L8,16 L10,13 L9,6 L6,4 L0,4 L-3,7 L-3,14 L1,17 L4,18 L4,21 L-2,20 L-7,14 L-7,7 L-4,2 Z "
      fill="#2A91CC"
      transform="translate(78,122)"
    />
    <path
      d="M0,0 L7,0 L12,3 L15,9 L21,11 L25,17 L22,18 L17,13 L17,11 L14,14 L9,20 L6,20 L8,16 L11,12 L10,8 L6,4 L-1,5 L-4,10 L-7,9 L-5,4 Z "
      fill="#424242"
      transform="translate(99,122)"
    />
    <path
      d="M0,0 L2,0 L2,3 L-1,6 L-2,11 L0,15 L2,16 L8,16 L11,13 L12,10 L15,10 L14,15 L10,19 L8,20 L2,20 L-4,16 L-5,14 L-5,5 Z "
      fill="#F7B746"
      transform="translate(87,132)"
    />
    <path
      d="M0,0 L7,1 L9,3 L9,10 L6,13 L0,14 L-4,10 L-5,6 L-2,1 Z M1,3 L-1,5 L0,10 L3,11 L6,9 L5,4 Z "
      fill="#434344"
      transform="translate(127,99)"
    />
    <path
      d="M0,0 L6,0 L9,2 L9,9 L10,13 L6,13 L6,10 L4,11 L3,14 L0,14 Z M3,3 L3,7 L6,6 L5,3 Z "
      fill="#4B4B4C"
      transform="translate(70,99)"
    />
    <path
      d="M0,0 L5,0 L7,2 L7,14 L4,14 L2,10 L0,14 L-2,14 L-2,2 Z M1,3 L1,6 L4,7 L3,3 Z "
      fill="#373737"
      transform="translate(58,99)"
    />
    <path
      d="M0,0 L6,1 L7,5 L4,10 L7,11 L7,14 L-3,14 L-1,8 L-3,3 Z M1,3 L2,7 L3,3 Z M0,7 Z M3,10 Z "
      fill="#575758"
      transform="translate(143,99)"
    />
    <path
      d="M0,0 L5,1 L7,5 L4,10 L7,11 L7,14 L-3,14 L-1,10 L0,8 L-2,8 L-3,3 Z M1,3 L0,5 L2,7 L3,3 Z M0,7 Z "
      fill="#424344"
      transform="translate(112,99)"
    />
    <path
      d="M0,0 L7,0 L10,3 L9,8 L4,11 L3,14 L0,14 Z M3,3 L3,7 L6,6 L6,3 Z "
      fill="#4B4B4C"
      transform="translate(42,99)"
    />
    <path
      d="M0,0 L6,1 L7,5 L3,4 L1,3 L1,5 L6,7 L7,11 L4,14 L-1,13 L-2,9 L3,10 L-2,6 L-1,1 Z "
      fill="#414142"
      transform="translate(94,99)"
    />
    <path
      d="M0,0 L4,0 L7,5 L14,6 L17,4 L19,0 L22,1 L19,7 L15,10 L8,10 L3,7 Z "
      fill="#40B67A"
      transform="translate(102,142)"
    />
    <path
      d="M0,0 L7,0 L11,1 L9,5 L6,7 L-1,6 L-4,3 L-4,1 Z "
      fill="#D4C47B"
      transform="translate(99,78)"
    />
    <path
      d="M0,0 L3,0 L3,6 L5,6 L6,3 L9,3 L9,14 L6,14 L6,10 L1,8 L0,7 Z "
      fill="#343435"
      transform="translate(154,99)"
    />
    <path
      d="M0,0 L4,0 L4,13 L0,14 Z "
      fill="#767677"
      transform="translate(84,99)"
    />
    <path
      d="M0,0 L6,1 L7,5 L6,7 L4,7 L3,3 L1,3 L0,8 L-3,5 L-1,1 Z "
      fill="#464647"
      transform="translate(143,99)"
    />
    <path
      d="M0,0 L2,2 L8,4 L12,10 L9,11 L4,6 L4,4 L0,7 Z "
      fill="#31A56C"
      transform="translate(112,129)"
    />
    <path
      d="M0,0 L8,2 L11,6 L11,10 L8,9 L6,5 L0,3 Z "
      fill="#EDAF47"
      transform="translate(91,131)"
    />
    <path
      d="M0,0 L2,0 L2,11 L-2,15 L-5,15 L-3,11 L0,7 Z "
      fill="#2D2C2D"
      transform="translate(110,127)"
    />
    <path
      d="M0,0 L2,0 L2,2 L6,1 L7,5 L-3,5 L-1,1 Z "
      fill="#59595A"
      transform="translate(112,108)"
    />
    <path
      d="M0,0 L4,1 L8,6 L11,6 L11,8 L5,8 L0,2 Z "
      fill="#2B2D34"
      transform="translate(92,134)"
    />
    <path
      d="M0,0 L5,0 L4,3 L2,3 L3,10 L2,12 L0,12 Z "
      fill="#030303"
      transform="translate(70,99)"
    />
    <path
      d="M0,0 L5,0 L5,2 L2,2 L1,11 L0,11 Z "
      fill="#080909"
      transform="translate(42,99)"
    />
    <path
      d="M0,0 L3,0 L3,8 L0,7 Z "
      fill="#212222"
      transform="translate(154,99)"
    />
    <path
      d="M0,0 L1,0 L1,12 L-2,12 Z "
      fill="#343435"
      transform="translate(44,101)"
    />
    <path
      d="M0,0 L4,2 L4,7 L2,7 L-1,2 Z "
      fill="#2E2E2F"
      transform="translate(75,99)"
    />
    <path
      d="M0,0 L6,1 L7,5 L3,4 L0,1 Z "
      fill="#4E4E50"
      transform="translate(94,99)"
    />
    <path
      d="M0,0 L1,3 L-3,6 L-5,5 L-4,2 Z "
      fill="#3DB176"
      transform="translate(110,131)"
    />
    <path
      d="M0,0 L2,1 L3,6 L0,7 L-1,2 Z "
      fill="#565656"
      transform="translate(110,100)"
    />
    <path
      d="M0,0 L6,0 L4,4 L0,3 Z "
      fill="#D5C787"
      transform="translate(130,86)"
    />
    <path
      d="M0,0 L3,1 L3,6 L-1,5 Z "
      fill="#323233"
      transform="translate(93,100)"
    />
    <path
      d="M0,0 L5,0 L5,3 L3,4 L-1,1 Z "
      fill="#464647"
      transform="translate(58,99)"
    />
  </svg>
)
export default OlympicsParis
