import * as React from 'react'
const Iceland = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#DB192E"
    />
    <path
      d="M48 63h51l-3 9-6 10-8 8-10 6-11 4H48Zm0-63h13l11 4 10 6 8 8 6 10 3 9H48Z"
      fill="#05539C"
    />
    <path
      d="M0 56h29v40l-8-4-10-9-6-9-4-9ZM28 4h1v40H0l1-9 7-14 9-10 9-6Z"
      fill="#FAEDEE"
    />
    <path d="M41 56h59l-1 7H48v37h-7Zm0-56h7v37h51l1 7H41Z" fill="#FAF3F4" />
    <path
      d="M1 63h21v28l-5-2-7-7-6-10ZM20 9h2v28H1l3-9 6-10 8-8Z"
      fill="#11589F"
    />
  </svg>
)
export default Iceland
