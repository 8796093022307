import { firebaseAuth, firebaseDb } from '@/firebase/init'
// import { v4 as uuidv4 } from 'uuid'
import {
  setAuthenticated,
  setResetAuth,
  setUserData,
  setUserPending,
} from '@/redux/slices/auth'
import store from '@/redux/store'
import { randomId } from '@/utils/randomId'
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getIdToken,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
  updateProfile,
  verifyBeforeUpdateEmail,
} from 'firebase/auth'
import {
  get,
  getDatabase,
  limitToLast,
  orderByChild,
  ref as realTimeRef,
  update,
} from 'firebase/database'
import {
  Timestamp,
  arrayUnion,
  collection,
  deleteField,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

// import { getPhrases } from '@/constants/phrases'
import apiConfig from '@/config/relysiaApi'
import { DEFAULT_WALLET, SERVER_ENDPOINT } from '@/constants/environment'
import {
  ADMIN_CONFIGURATION,
  BETS_COLLECTION_NAME,
  CASINO_COLLECTION_NAME,
  GAMES_COLLECTION_NAME,
  REFERRER_COLLECTION_NAME,
  SIDEBAR_COLLECTION_NAME,
} from '@/constants/firestore'
import {
  changeOddsDisplayType,
  clearBetSlips,
  getPlacedBets,
  getPlacedLiveGames,
} from '@/redux/slices/betSlips'
import { resetBets } from '@/redux/slices/bets'
import { getSideBarData } from '@/redux/slices/sidebar'
import {
  getMnemonic,
  handleConnectModal,
  resetWalletDetails,
  updateWalletData,
} from '@/redux/slices/wallet'
import { getAddressFromApi, walletSetup } from '@/services/relysia-queries'
import {
  calculateWinPriceMultiBet,
  calculateWinPriceSingle,
} from '@/utils/bets'
import { errorMsg } from '@/utils/toast'
import axios from 'axios'
import Cookies from 'js-cookie'
import { sortBy } from 'lodash'
import { gamesConverter } from './converter'
import { FireabseORM } from './orm'

const returnMessages = {
  'auth/invalid-email': 'Invalid email address / password',
  'auth/user-disabled': 'User disabled.',
  'auth/user-not-found': 'Invalid email address / password',
  'auth/wrong-password': 'Invalid email address / password',
  'auth/email-already-in-use': 'Email already in use.',
  'auth/timeout': 'Timeout.',
  'auth/too-many-requests': 'Too many login attempts.',
}

const firebaseGetUserInfoFromDb = async (id) => {
  try {
    const docRef = doc(firebaseDb, 'users', id)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  } catch (error) {
    console.error(error)
  }
}

const firebaseGetUsername = async (ref) => {
  const userSnap = await getDoc(ref)
  const hideLeaderboard = userSnap.get('hideLeaderboard')
  if (hideLeaderboard) return 'Hidden'
  const username = userSnap.get('username')
  return username
}

const firebaseGetPredictionGame = async (ref) => {
  try {
    const gameSnap = await getDoc(ref)
    return gameSnap.data()
  } catch (error) {
    console.error(error)
    return null
  }
}

const checkWalletStatus = async (userInfoFromDb, user) => {
  if (userInfoFromDb?.wallet?.paymail) {
    store.dispatch(updateWalletData(userInfoFromDb.wallet))
  } else {
    const walletData = await getAddressFromApi(user.accessToken)
    if (!walletData.error) {
      store.dispatch(updateWalletData(walletData.data))
      const walletAddressObj = {
        wallet: {
          paymail: walletData.data.paymail,
          address: walletData.data.address,
          id: '00000000-0000-0000-0000-000000000000',
        },
      }
      setDoc(doc(firebaseDb, 'users', user.uid), walletAddressObj, {
        merge: true,
      })
    }
  }
  store.dispatch(getMnemonic())
}

const setupWallet = async (user) => {
  const wallName = user.email.split('@')[0]
  const wallet = await walletSetup(wallName, user.accessToken).then(
    async () => {
      const walletData = await getAddressFromApi(user.accessToken)
      if (!walletData.error) {
        store.dispatch(updateWalletData(walletData.data))
        return walletData.data
      }
    },
  )
  return wallet
}

const firebaseGetOwnPlinkoHistory = async (id) => {
  try {
    const docRef = doc(
      firebaseDb,
      CASINO_COLLECTION_NAME,
      `plinko-history/history/${id}`,
    )
    const docSnap = await getDoc(docRef)
    const data = docSnap.data()

    return data
  } catch (error) {
    console.error(error)
  }
}

const firebaseGetLatestPlinkoGamesSortedByDate = async (num) => {
  try {
    const dbRef = getDatabase()
    const queryRef = query(
      realTimeRef(dbRef, 'live-plinko/plinko/games'),
      orderByChild('date'),
      limitToLast(num),
    )

    const snapshot = await get(queryRef)

    if (snapshot.exists()) {
      const data = snapshot.val()
      const dataArray = Object.values(data).reverse()
      return dataArray
    } else {
      console.log('No data available')
      return false
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

const firebaseGetLatestPlinkoGamesSortedByEarn = async () => {
  try {
    const docRef = doc(firebaseDb, CASINO_COLLECTION_NAME, 'plinko-big-wins')
    const docSnap = await getDoc(docRef)
    const data = docSnap.data()?.bigWinsArray
    if (data) {
      const sortedGames = Object.values(data).sort((a, b) => b.earn - a.earn)
      return sortedGames
    } else {
      console.log('No data available')
      return []
    }
  } catch (error) {
    console.error(error)
    return []
  }
}

const firebaseGetPlinkoStats = async () => {
  try {
    const docRef = doc(firebaseDb, CASINO_COLLECTION_NAME, 'plinko-stats')

    const docSnap = await getDoc(docRef)
    const data = docSnap.data()
    return data
  } catch (error) {
    console.error(error)
  }
}

const firebaseUpdateLiveGames = async (id, isPaused) => {
  try {
    const docRef = doc(firebaseDb, GAMES_COLLECTION_NAME, id)

    await updateDoc(docRef, {
      isPaused,
    })

    return true
  } catch (error) {
    console.error(error)
  }
}

const firebaseGetPlinkoFailPayments = async () => {
  try {
    const docRef = doc(
      firebaseDb,
      CASINO_COLLECTION_NAME,
      'plinko-payment-errors',
    )

    const docSnap = await getDoc(docRef)
    const data = docSnap.data()
    return data
  } catch (error) {
    console.error(error)
  }
}

const firebaseLogin = async ({ email, password }) => {
  try {
    const authCredentials = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    )
      .then(async (result) => {
        const user = result?.user
        const userInfoFromDb = await firebaseGetUserInfoFromDb(user.uid)
        if (!userInfoFromDb)
          return {
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
            photoPATH: null,
            createdAt: user.metadata.creationTime,
            accessToken: user.accessToken,
          }
        else {
          checkWalletStatus(userInfoFromDb, user)
          if (user.email !== userInfoFromDb.email) {
            await setDoc(
              doc(firebaseDb, 'users', user.uid),
              { email: user.email },
              {
                merge: true,
              },
            )
          }
          return {
            bets: {
              loseAmount: 0,
              placedBetAmount: 0,
              winAmount: 0,
            },
            blockedUsers: {},
            chatOptions: {
              nameColor: 'white',
              textColor: 'gray',
            },
            ...userInfoFromDb,
            isEmailVerified: user.emailVerified,
            accessToken: user.accessToken,
            email: user.email,
          }
        }
      })
      .catch((error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          return {
            error: error,
          }
        } else if (error.code === 'auth/invalid-credential') {
          return {
            error:
              'Your information does not match. Please make sure you have written your email or password correctly.',
          }
        } else {
          const messageID = returnMessages[error?.code] || 'error.unknown'
          return {
            error: returnMessages[error?.code],
          }
        }
      })
    return authCredentials
  } catch (error) {
    return { error: 'Incorrect email or password.' }
  }
}

const firebaseRegister = async ({
  name,
  username,
  email,
  password,
  refPaymail,
}) => {
  try {
    const userInfos = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    ).then(async (result) => {
      const user = result.user
      const userInfoFromDb = await firebaseGetUserInfoFromDb(user.uid)
      if (!userInfoFromDb) {
        const wallet = await setupWallet(user)
        const infos = {
          displayName: name,
          username,
          email: user.email,
          uid: user.uid,
          photoPATH: null,
          createdAt: user.metadata.creationTime,
          hideLeaderboard: true,
          profileStatus: 'private',
          bets: {
            loseAmount: 0,
            placedBetAmount: 0,
            winAmount: 0,
          },
          blockedUsers: {},
          chatOptions: {
            nameColor: 'white',
            textColor: 'gray',
          },
          activeWallet: DEFAULT_WALLET,
          wallet: {
            address: wallet.address,
            id: '00000000-0000-0000-0000-000000000000',
            paymail: wallet.paymail,
          },
        }
        if (refPaymail) {
          const completePaymail = refPaymail + '@lilb.it'
          infos['referedBy'] = completePaymail
          await setDoc(doc(firebaseDb, REFERRER_COLLECTION_NAME, user.uid), {
            commission: 0,
            referrer: completePaymail,
            wagerAmount: 0,
            username,
          })
        }
        await setDoc(doc(firebaseDb, 'users', user.uid), infos)
        await setDoc(doc(firebaseDb, 'settings', user.uid), {
          notifications: {
            inApp: {
              activity: true,
              // matches: false,
              blog: true,
            },
            email: {
              activity: true,
              // matches: false,
              blog: true,
            },
          },
        })

        await registerNovuSubscriber({ ...infos, username, name })
        setTimeout(() => {
          store.dispatch(handleConnectModal(true))
        }, 500)
        return infos
      }
    })
    return userInfos
  } catch (error) {
    console.log(error)
    return { error: 'Email has already been taken.' }
  }
}

const firebaseLogout = async () => {
  await firebaseAuth.signOut()
  store.dispatch(setResetAuth())
  Cookies.remove('auth')
  Cookies.remove('user')
  Cookies.remove('visited-leagues')

  store.dispatch(resetWalletDetails())
  store.dispatch(resetBets())
  store.dispatch(clearBetSlips())
}

const firebaseGetUserbyEmail = async (email) => {
  const queryRef = query(
    collection(firebaseDb, 'users'),
    where('email', '==', email),
  )
  const querySnapshot = await getDocs(queryRef)
  return querySnapshot?.docs?.length ? querySnapshot?.docs[0]?.data() : null
}

const firebaseUpdatebyEmail = async ({ uid, data }) => {
  try {
    const userRef = doc(firebaseDb, 'users', uid)
    await updateDoc(userRef, {
      ...data,
    })
    return { message: 'success', error: null, data }
  } catch (error) {
    return { message: 'error', error }
  }
}

const firebaseGetPlacedBets = async ({ type, uid }) => {
  try {
    const collectionRef = collection(firebaseDb, BETS_COLLECTION_NAME)
    let userBetsQuery
    const order = orderBy('placedAt', 'desc')
    if (uid) {
      userBetsQuery = query(collectionRef, where('uid', '==', uid), order)
    } else if (type === 'admin') {
      userBetsQuery = query(collectionRef, order, limit(100))
    } else {
      userBetsQuery = query(collectionRef, order, limit(40))
    }
    const snapshot = await getDocs(userBetsQuery)
    const bets = snapshot.docs.map((doc) => doc.data())
    return bets
  } catch (error) {
    return { message: 'error', error }
  }
}

const firebasaGetBetsWithPaginate = async (
  lastVisible = null,
  uid = null,
  isSuccess = null,
  isFailed = null,
) => {
  try {
    const collectionRef = collection(firebaseDb, BETS_COLLECTION_NAME)

    let queryConstraints = [orderBy('placedAt', 'desc')]

    if (uid) {
      queryConstraints.push(where('uid', '==', uid))
    }

    if (isFailed) {
      queryConstraints.push(where('status', '==', 'failed'))
    }

    if (isSuccess) {
      queryConstraints.push(where('status', '==', 'completed'))
    }

    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible))
    }

    queryConstraints.push(limit(20))
    const userBetsQuery = query(collectionRef, ...queryConstraints)
    const snapshot = await getDocs(userBetsQuery)

    const bets = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    const lastDoc = snapshot.docs[snapshot.docs.length - 1] || null

    return { bets, lastDoc }
  } catch (error) {
    console.error('Error fetching paginated bets:', error)
    return { message: 'error', error }
  }
}

const firebaseGetCasinoHistories = async (casino, uid = null) => {
  try {
    const collectionRef = collection(
      firebaseDb,
      `${CASINO_COLLECTION_NAME}/${casino}/history`,
    )

    const queryConstraints = [orderBy('date', 'desc'), limit(50)]

    if (uid) {
      queryConstraints.push(where('uid', '==', uid))
    }

    const userBetsQuery = query(collectionRef, ...queryConstraints)

    const snapshot = await getDocs(userBetsQuery)

    const bets = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    return bets
  } catch (error) {
    console.error('Error fetching casino histories:', error)

    return { message: 'Failed to fetch casino histories', error }
  }
}

const firebaseIsUsernameExist = async (username) => {
  const querySnapshot = await getDocs(collection(firebaseDb, 'users'))
  let isExist

  querySnapshot.forEach((doc) => {
    if (doc.data().username === username) {
      isExist = true
    }
  })

  return isExist
}

const firebaseGetFavourites = async (uid) => {
  try {
    const favouritesRef = doc(firebaseDb, 'favourite-matches', uid)

    const favouritesSnapshot = await getDoc(favouritesRef)

    if (favouritesSnapshot.exists()) {
      const favouritesData = favouritesSnapshot.data()
      const favouritesArray = []

      // Convert the document data into an array
      Object.keys(favouritesData).forEach((key) => {
        const favourite = `${key}`
        favouritesArray.push(favourite)
      })

      return { favourites: favouritesArray, error: null }
    } else {
      return { favourites: [], error: null }
    }
  } catch (error) {
    return { message: 'error', error }
  }
}

const firebaseAddFavourites = async ({ uid, matchId }) => {
  try {
    const favouritesRef = doc(firebaseDb, 'favourite-matches', uid)

    await setDoc(
      favouritesRef,
      {
        [matchId]: true,
      },
      {
        merge: true,
      },
    )

    return { id: matchId, error: null }
  } catch (error) {
    return { message: 'error', error }
  }
}

const firebaseRemoveFavourites = async ({ uid, matchId }) => {
  try {
    const favouritesRef = doc(firebaseDb, 'favourite-matches', uid)
    await updateDoc(favouritesRef, {
      [matchId]: deleteField(),
    })

    return { id: matchId, error: null }
  } catch (error) {
    return { message: 'error', error }
  }
}

const firebaseRemoveExtrasFavourites = async ({ uid, matchIds }) => {
  try {
    const batch = writeBatch(firebaseDb)
    const favouritesRef = doc(firebaseDb, 'favourite-matches', uid)

    matchIds?.forEach((matchId) => {
      batch.update(favouritesRef, {
        [matchId]: deleteField(),
      })
    })

    await batch.commit()

    return { favourites: matchIds, error: null }
  } catch (error) {
    return { message: 'error', error }
  }
}

const firebaseGetGames = async () => {
  try {
    const queryRef = collection(firebaseDb, 'games')
    const querySnapshot = await getDocs(queryRef)
    const games = {}
    querySnapshot?.docs?.forEach((doc) => {
      if (Object.keys(doc.data() || {}).length) games[doc.id] = doc.data()
    })

    // store.dispatch(addSidebarItem(games))
    return games
  } catch (error) {
    return { message: 'error', error }
  }
}
const firebaseGetAllTeamsBets = async () => {
  try {
    const queryRef = collection(firebaseDb, BETS_COLLECTION_NAME)

    const querySnapshot = await getDocs(queryRef)
    const games = {}

    querySnapshot?.forEach((doc) => {
      const data = doc.data()
      if (data && data.teamName && data?.match?.sport) {
        const key = `${data.teamName}-${data?.match?.sport}`
        if (!games[key]) {
          games[key] = {
            sport: data?.match?.sport,
            teamName: data.teamName,
            totalbets: 1,
            betPrice: parseFloat(data.betPrice),
          }
        } else {
          games[key].totalbets += 1
          games[key].betPrice += parseFloat(data.betPrice)
        }
      }
    })

    return games
  } catch (error) {
    return { message: 'error', error }
  }
}

const firebaseGetAllGamesBets = async () => {
  try {
    const queryRef = collection(firebaseDb, BETS_COLLECTION_NAME)
    const querySnapshot = await getDocs(queryRef)
    const bets = []
    querySnapshot?.forEach((snapshot) => {
      if (!snapshot.exists()) {
        return
      }
      const data = snapshot.data()
      bets.push(data)
    })

    return { message: 'success', bets }
  } catch (error) {
    return { message: 'error', error }
  }
}

const firebaseGetCountriesLeagues = async () => {
  try {
    const queryRef = collection(firebaseDb, 'leagues')
    const querySnapshot = await getDocs(queryRef)
    const countriesLeagues = {}
    querySnapshot?.docs?.forEach((doc) => {
      if (Object.keys(doc.data() || {}).length)
        countriesLeagues[doc.id] = doc.data()
    })

    return countriesLeagues
  } catch (error) {
    return { message: 'error', error }
  }
}

const firebaseGetAuthorizedUser = () => {
  store.dispatch(getSideBarData())
  const fn = firebaseAuth.onAuthStateChanged(async (userResponse) => {
    if (userResponse) {
      apiConfig.defaults.headers.common['authToken'] = userResponse.accessToken
      const userInfoFromDb = await firebaseGetUserInfoFromDb(userResponse.uid)
      if (userInfoFromDb) {
        const defaultChatOptions = userInfoFromDb?.chatOptions || []
        const data = {
          docRef: userInfoFromDb.docRef,
          displayName: userInfoFromDb?.displayName,
          username: userInfoFromDb?.username,
          email: userResponse?.email,
          uid: userResponse?.uid,
          photoPATH: userResponse?.photoURL,
          isEmailVerified: userResponse?.emailVerified,
          createdAt: userResponse?.metadata?.creationTime,
          accessToken: userResponse?.accessToken,
          roleType: userInfoFromDb?.roleType ?? '',
          referrers: userInfoFromDb?.referrers ?? [],
          activeWallet: userInfoFromDb?.activeWallet,
          bets: userInfoFromDb?.bets ?? {
            loseAmount: 0,
            placedBetAmount: 0,
            winAmount: 0,
          },
          blockedUsers: userInfoFromDb?.blockedUsers || {},
          chatOptions: {
            nameColor: 'white',
            textColor: 'gray',
            ...defaultChatOptions,
          },
        }
        checkWalletStatus(userInfoFromDb, userResponse)
        store.dispatch(setUserData(data))
        if (userInfoFromDb?.oddsDisplayType)
          store.dispatch(changeOddsDisplayType(userInfoFromDb.oddsDisplayType))

        store.dispatch(getPlacedBets({ type: 'user', uid: userResponse?.uid }))
      } else {
        store.dispatch(getPlacedBets({ type: 'user', uid: userResponse?.uid }))
      }
      store.dispatch(getPlacedLiveGames({ type: 'guest', uid: null }))
      store.dispatch(setUserPending(false))
      store.dispatch(setAuthenticated(true))
    } else {
      // console.log('not auth')
      store.dispatch(getPlacedLiveGames({ type: 'guest', uid: null }))
      store.dispatch(setAuthenticated(false))
      store.dispatch(setUserPending(false))
    }
  })
  return fn
}

const firebaseOnIdTokenChange = async () => {
  try {
    firebaseAuth.onIdTokenChanged(async (user) => {
      if (user) {
        apiConfig.defaults.headers.common['authToken'] = user.accessToken
      }
    })
  } catch (error) {
    console.log(error)
  }
}

const firebaseVerifyEmail = async (oobCode, setter) => {
  return await applyActionCode(firebaseAuth, oobCode)
    .then(async () => {
      setter(() => ({
        isVerify: true,
        msg: 'Email verification process completed successfully.',
      }))
      return true
    })
    .catch(() => {
      return false
    })
}

const firebaseSendVerifyEmail = async () => {
  return await sendEmailVerification(firebaseAuth.currentUser)
    .then(() => {
      return true
    })
    .catch((err) => {
      return false
    })
}

const firebaseUpdateUser = async (data, user) => {
  const profileUpdates = {}

  if (data?.photoPATH) profileUpdates.photoURL = data.photoPATH
  if (data?.displayName) profileUpdates.displayName = data.displayName

  if (Object.keys(profileUpdates).length > 0) {
    await updateProfile(firebaseAuth.currentUser, profileUpdates)
  }

  await setDoc(doc(firebaseDb, 'users', firebaseAuth.currentUser.uid), data, {
    merge: true,
  }).then(() => {
    store.dispatch(setUserData({ ...user, ...data }))
  })
}

function generateRandomUsername() {
  const words = [
    'lilSkyline',
    'lilRaven',
    'lilQuantum',
    'lilTwilight',
    'lilAlpha',
    'lilNebula',
    'lilDigital',
    'lilCosmic',
    'lilCelestial',
    'lilStarlight',
  ]
  const suffixes = [
    'Auth',
    'User',
    'Verify',
    'Key',
    'Gate',
    'Lock',
    'Code',
    'Login',
    'Pass',
    'Secure',
  ]

  const randomWord = words[Math.floor(Math.random() * words.length)]
  const randomSuffix = suffixes[Math.floor(Math.random() * suffixes.length)]
  const randomNumber = Math.floor(Math.random() * 1000)

  let username = `${randomWord}${randomNumber}${randomSuffix}`

  // Truncate the username to 18 characters if it's too long
  if (username.length > 18) {
    username = username.substring(0, 18)
  }

  return username
}

const refreshAuthTokenHandler = async (user) => {
  const { currentUser } = firebaseAuth
  const tokenAccess = await getIdToken(currentUser, true)
  store.dispatch(setUserData({ ...user, accessToken: tokenAccess }))
  return tokenAccess
}

const firebaseLoginWithGoogle = async (refPaymail = null) => {
  try {
    const firebaseGoogleProvider = new GoogleAuthProvider()
    const userInfo = await signInWithPopup(firebaseAuth, firebaseGoogleProvider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential?.accessToken
        // The signed-in user info.
        const user = result.user
        const userInfoFromDb = await firebaseGetUserInfoFromDb(user.uid)
        if (!userInfoFromDb || !userInfoFromDb?.uid) {
          const wallet = await setupWallet(user)
          const username = generateRandomUsername()
          const infos = {
            displayName: user.displayName,
            username,
            email: user.email,
            uid: user.uid,
            photoPATH: user.photoURL,
            createdAt: user.metadata.creationTime,
            isEmailVerified: true,
            hideLeaderboard: true,
            bets: {
              loseAmount: 0,
              placedBetAmount: 0,
              winAmount: 0,
            },
            blockedUsers: {},
            chatOptions: {
              nameColor: 'white',
              textColor: 'gray',
            },
            activeWallet: DEFAULT_WALLET,
            wallet: {
              address: wallet.address,
              id: '00000000-0000-0000-0000-000000000000',
              paymail: wallet.paymail,
            },
          }

          if (refPaymail) {
            const completePaymail = refPaymail + '@lilb.it'
            infos['referedBy'] = completePaymail
            await setDoc(
              doc(firebaseDb, REFERRER_COLLECTION_NAME, user.uid),
              {
                commission: 0,
                referrer: completePaymail,
                wagerAmount: 0,
                username,
              },
              {
                merge: true,
              },
            )
          }
          await setDoc(doc(firebaseDb, 'users', user.uid), infos, {
            merge: true,
          })
          store.dispatch(
            setUserData({ ...infos, accessToken: user.accessToken }),
          )

          await setDoc(
            doc(firebaseDb, 'settings', user.uid),
            {
              notifications: {
                inApp: {
                  activity: true,
                  // matches: false,
                  blog: true,
                },
                email: {
                  activity: true,
                  // matches: false,
                  blog: true,
                },
              },
            },
            {
              merge: true,
            },
          )

          await registerNovuSubscriber({
            ...infos,
            username,
            name: user.displayName,
          })

          setTimeout(() => {
            store.dispatch(handleConnectModal(true))
          }, 500)
          return { credential, token, user, infos }
        } else {
          checkWalletStatus(userInfoFromDb, user)
        }
        store.dispatch(
          setUserData({ ...userInfoFromDb, accessToken: user.accessToken }),
        )
        return { credential, token, user, infos: userInfoFromDb }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.email
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error)
        if (errorCode === 'auth/multi-factor-auth-required') {
          return {
            error: error,
          }
        }
        console.error({ errorCode, errorMessage, email, credential })

        return {
          error: error,
        }
      })
    return userInfo
  } catch (error) {
    console.error(error)
  }
}

const updateUserEmail = async (currentPassword, newEmail) => {
  const user = firebaseAuth.currentUser

  if (user) {
    try {
      // Step 1: Re-authenticate the user
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword,
      )

      await reauthenticateWithCredential(user, credential)
      await verifyBeforeUpdateEmail(user, newEmail)
      return {
        success: true,
        msg: 'Verification email sent to new address. Please confirm to complete the update.',
      }
    } catch (error) {
      console.log(error)
      if (error.code === 'auth/requires-recent-login') {
        return {
          success: false,
          msg: 'User needs to re-login for this action.',
        }
      } else if (error.code === 'auth/missing-password') {
        return { success: false, msg: 'Password is incorrect.' }
      } else if (error.code === 'auth/invalid-credential') {
        return {
          success: false,
          msg: 'The information you have entered does not match.',
        }
      } else if (error.code === 'auth/operation-not-allowed') {
        return {
          success: false,
          msg: 'Make sure you enter a usable email address.',
        }
      } else {
        return { success: false, msg: 'Operation failed.' }
      }
    }
  } else {
    return {
      sucess: false,
      msg: 'You must be logged in to perform this operation.',
    }
  }
}

const firebaseReauthenticateUser = async (email, oldPassword) => {
  const user = firebaseAuth.currentUser
  const credential = EmailAuthProvider.credential(email, oldPassword)

  try {
    await reauthenticateWithCredential(user, credential)
    const authToken = await user.getIdToken()
    return { authToken, error: false }
  } catch (err) {
    return { msg: 'Old password is incorrect.', error: true }
  }
}

const setNotificationsSetting = async (data) => {
  try {
    await FireabseORM.set('settings', firebaseAuth.currentUser.uid, data)
  } catch (e) {
    console.log(e, '====> Error add notification')
  }
}

const getReferralStats = async (uid) => {
  const data = doc(firebaseDb, REFERRER_COLLECTION_NAME, uid)
  const querySnapshot = await getDoc(data)
  const referredStats = querySnapshot.data()

  return referredStats
}

const firebaseUpdatePassword = async ({ uid, data, email, authToken }) => {
  try {
    if (!uid || !data || !email || !authToken) {
      return { error: true, msg: 'Missing information!' }
    }

    const currentUser = firebaseAuth.currentUser
    if (!currentUser || currentUser.uid !== uid) {
      return { error: true, msg: 'Unauthorized: User not authenticated' }
    }

    const providerData = currentUser.providerData.map(
      (provider) => provider.providerId,
    )
    const isEmailPasswordUser = providerData.includes('password')

    if (isEmailPasswordUser) {
      const newPassword = data.newPassword

      if (!newPassword) {
        return { error: true, msg: 'Password is missing!' }
      }

      await updatePassword(currentUser, newPassword)

      return { error: false, msg: 'Password updated successfully' }
    } else {
      return {
        error: true,
        msg: 'Password reset is not available for Google Auth users.',
      }
    }
  } catch (error) {
    console.log(error)
    return {
      error: true,
      msg: 'An error occurred while updating the password.',
    }
  }
}

const updateReferralStats = async (uid, data) => {
  try {
    await FireabseORM.update(REFERRER_COLLECTION_NAME, uid, data)
  } catch (e) {
    console.log(e, '====> Error update ReferralMatrics')
  }
}

const updateNotificationsSetting = async (data) => {
  try {
    await FireabseORM.update('settings', firebaseAuth.currentUser.uid, data)
  } catch (e) {
    console.log(e, '====> Error update notification')
  }
}

const getNotificationsSetting = async () => {
  try {
    const docSnap = await FireabseORM.readSingleDoc(
      'settings',
      firebaseAuth.currentUser.uid,
    )
    if (docSnap.exists()) {
      const data = docSnap.data()
      if (data.notifications) {
        return data.notifications
      }
      return null
    }
    return null
  } catch (e) {
    console.log(e, '====> Error get notifications')
  }
}

const getProfileSetting = async (uid = 0) => {
  try {
    if (uid !== 0) {
      const docSnap = await FireabseORM.readSingleDoc('users', uid)
      if (docSnap.exists()) {
        return docSnap.data()
      }
      return null
    } else {
      if (firebaseAuth.currentUser && firebaseAuth.currentUser.uid) {
        const docSnap = await FireabseORM.readSingleDoc(
          'users',
          firebaseAuth.currentUser.uid,
        )
        if (docSnap.exists()) {
          return docSnap.data()
        }
      }
      return null
    }
  } catch (e) {
    console.log(e, 'error')
  }
}

const updateProfileSetting = async (data) => {
  return await FireabseORM.update('users', firebaseAuth.currentUser.uid, data)
}

const uploadFile = async (file) => {
  if (file) {
    const storage = getStorage()
    const extension = '.' + file.name.split('.').pop()
    const filename = file.name.split('.').slice(0, -1).join('.')
    const name = filename + new Date().getTime() + extension
    const storageRef = ref(storage, 'profiles/' + name)

    const upload = await uploadBytes(storageRef, file)
    let downloadURL = await getDownloadURL(upload.ref)
    return { name, downloadURL }
  }
  return null
}

const fetchEnquiries = async () => {
  try {
    return await FireabseORM.readCollection('users-enquiry')
  } catch (e) {
    errorMsg(e.code ? e.code : e.message)
    console.log(e.code, e.message, 'error')
  }
}

const fetchEvents = async () => {
  try {
    const predictionMarketsCollection = collection(
      firebaseDb,
      'prediction-markets',
    )
    const currentTimestamp = new Date()

    const queryMarket = query(
      predictionMarketsCollection,
      where('approved', '==', true),
      // where('decision', '==', 'undecided'),
      // where('expiryDate', '>=', currentTimestamp.toISOString())
    )

    const querySnapshot = await getDocs(queryMarket)
    const eventData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    return eventData
  } catch (e) {
    errorMsg(e.code ? e.code : e.message)
    console.log(e.code, e.message, 'error')
    return []
  }
}

const firebaseGetEventGame = async (id) => {
  const docRef = doc(firebaseDb, `prediction-markets/${id}`)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return { data: docSnap.data(), ref: docRef }
  } else {
    console.error(`Market ${id} not found`)
    return null
  }
}

const registerNovuSubscriber = async (data) => {
  const payload = {
    email: data.email,
    firstName: data.name,
    lastName: '',
    phone: null,
    avatar: data.photoPATH,
    uid: data.uid,
  }
  await axios.post(SERVER_ENDPOINT + 'notification/register', payload)
}

const updateNovuSubscriber = async (data) => {
  const payload = {
    email: data.email,
    firstName: data.displayName,
    lastName: '',
    phone: '+' + data.countryCode + ' ' + data.phone,
    avatar: data.photoPATH,
    uid: data.uid,
  }
  await axios.post(SERVER_ENDPOINT + 'notification/update', payload)
}

const firebaseGetPlinkoGames = async () => {
  const gamesRef = doc(firebaseDb, CASINO_COLLECTION_NAME, 'plinko-count')

  const gamesSnapshot = await getDoc(gamesRef).then((doc) => {
    return doc.data()
  })
  return gamesSnapshot
}

const firebaseGetDailyTracks = async (game) => {
  const trackRef = doc(firebaseDb, 'casino-prod', game)

  const trackData = await getDoc(trackRef).then((doc) => {
    return doc.data()
  })
  return trackData
}

const firebaseGetPokerCurrentGame = async (uid) => {
  const gamesRef = doc(
    firebaseDb,
    'casino-prod',
    'video-poker-games',
    'games',
    uid,
  )

  const gamesSnapshot = await getDoc(gamesRef).then((doc) => {
    return doc.data()
  })
  return gamesSnapshot
}

const firebaseGetSideBarData = async (doc = 'sidebar') => {
  try {
    const data = (
      await FireabseORM.readSingleDoc(SIDEBAR_COLLECTION_NAME, doc)
    ).data()
    const sidebar = {}
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((sport) => {
        sidebar[sport] = data[sport]
      })
    }
    return sidebar
  } catch (error) {
    return { message: 'error', error }
  }
}

const fetchUsers = async () => {
  try {
    return await FireabseORM.readCollection('users')
  } catch (e) {
    errorMsg(e.code ? e.code : e.message)
    console.log(e.code, e.message, 'error')
  }
}

const firebaseGetAdminConfigurations = async (doc = 'affiliate-configs') => {
  try {
    const config = await FireabseORM.readSingleDoc('admin-configurations', doc)
    return { message: 'success', config: config.data() }
  } catch (error) {
    return { message: 'error', error, config: {} }
  }
}
const firebaseReadReferrers = async (paymail) => {
  const queryRef = collection(firebaseDb, REFERRER_COLLECTION_NAME)
  const userReferreds = query(queryRef, where('referrer', '==', paymail))
  const querySnapshot = await getDocs(userReferreds)
  const referred = []
  querySnapshot.forEach((doc) => {
    const data = doc.data()
    referred.push(data)
  })
  return referred
}

const firebaseUpdateAdminConfigurations = async (
  data,
  forUser = false,
  docName = 'affiliate-configs',
) => {
  try {
    if (forUser) {
      const affiliateRef = doc(
        firebaseDb,
        'admin-configurations',
        'affiliate-configs',
      )

      await setDoc(
        affiliateRef,
        {
          ...data,
        },
        { merge: true },
      )
      return { message: 'success', config: data }
    } else {
      const resp = await FireabseORM.update(
        'admin-configurations',
        docName,
        data,
      )
      return { message: 'success', resp }
    }
  } catch (error) {
    return { message: 'error', error, config: {} }
  }
}

const firebaseGetFavouritesMatches = async (favouriteIds) => {
  try {
    if (favouriteIds.length) {
      const collectionRef = collection(
        firebaseDb,
        GAMES_COLLECTION_NAME,
      ).withConverter(gamesConverter)
      const favouritesQuery = query(
        collectionRef,
        where(documentId(), 'in', favouriteIds),
      )
      const favouritesSnapshots = await getDocs(favouritesQuery)
      const favourites = []
      favouritesSnapshots.forEach((snapshot) => {
        if (!snapshot.exists()) {
          return
        }
        favourites.push(snapshot.data())
      })

      return { message: 'success', favouriteMatches: favourites }
    }
    return { message: 'success', favouriteMatches: [] }
  } catch (error) {
    return { message: 'error', error, favouriteMatches: [] }
  }
}

const firebaseGetChatSettings = async () => {
  const warningsRef = doc(firebaseDb, 'chat', 'warnings')

  try {
    const warningsData = await getDoc(warningsRef)
    if (warningsData.exists()) {
      const collectionArray = warningsData.data()?.collectionList
      const subcollectionDataPromises = collectionArray.map(
        async (collectionName) => {
          const subcollectionRef = collection(warningsRef, collectionName)
          const subcollectionSnapshot = await getDocs(subcollectionRef)

          const subcollectionData = []
          subcollectionSnapshot.forEach((doc) => {
            subcollectionData.push(doc.data())
          })

          return subcollectionData
        },
      )

      const subcollectionData = await Promise.all(subcollectionDataPromises)
      return subcollectionData.flat(Infinity)
    } else {
      console.log('Document does not exist')
      return []
    }
  } catch (error) {
    console.error('Error getting chat settings:', error)
    throw error
  }
}

const getLeaderboardData = async (isAdmin = false, start, end) => {
  const betsRef = collection(firebaseDb, BETS_COLLECTION_NAME)
  const usersRef = collection(firebaseDb, 'users')
  const currentWeekQuery = query(
    betsRef,
    where('gradedAt', '>=', start),
    where('gradedAt', '<', end),
  )
  const betsSnapshot = await getDocs(currentWeekQuery)

  if (betsSnapshot.size === 0) return []

  const usersInfo = new Map()
  let userData

  betsSnapshot.forEach((doc) => {
    const bet = doc.data()
    if (bet.grade === 'refund') return
    const uid = bet.uid
    if (!usersInfo.has(uid)) {
      usersInfo.set(uid, {
        uid: doc.data().uid,
        player_name: null,
        profileUrl: null,
        bestBet: { winAmount: 0, bet: undefined },
        won: 0,
        lose: 0,
        totalWonAmount: 0,
        totalBetsAmount: 0,
        hideLeaderboard: false,
        returnPercent: 0,
      })
    }
    userData = usersInfo.get(uid)

    const possibleWinPrice =
      bet.betType === 'single'
        ? calculateWinPriceSingle(bet.betPrice, bet.price) +
          Number(bet.betPrice)
        : calculateWinPriceMultiBet(bet.betSlips, bet.price) + Number(bet.price)

    userData['totalBetsAmount'] +=
      bet.betType === 'single' ? Number(bet.betPrice) : Number(bet.price)
    if (bet.grade === 'win') {
      userData['won'] += 1
      userData['totalWonAmount'] += Number(possibleWinPrice)
      if (userData['bestBet'].winAmount < Number(possibleWinPrice)) {
        userData['bestBet'] = {
          winAmount: Number(possibleWinPrice),
          bet: bet,
        }
      }
    } else {
      userData['lose'] += 1
    }
  })

  usersInfo.forEach((userData, _key) => {
    userData['returnPercent'] =
      (Number(
        userData['totalWonAmount'] - Number(userData['totalBetsAmount']),
      ) /
        Number(userData['totalBetsAmount'])) *
      100
  })

  let leaderboardList = sortBy([...usersInfo.values()], ['returnPercent'])
    .reverse()
    .slice(0, 10)

  const usersQuery = query(
    usersRef,
    where(
      'uid',
      'in',
      leaderboardList.map((item) => item.uid),
    ),
  )

  const usersSnapshot = await getDocs(usersQuery)
  const usersData = usersSnapshot.docs.map((doc) => doc.data())

  leaderboardList = leaderboardList.map((item) => {
    const userData = usersData.find((user) => user.uid === item.uid)
    return {
      ...item,
      player_name: isAdmin
        ? userData?.username
        : userData?.hideLeaderboard
        ? 'Hidden'
        : userData?.username,
      profileUrl: userData?.photoPATH,
      hideLeaderboard: !isAdmin && userData?.hideLeaderboard,
    }
  })

  return leaderboardList.map(
    ({ player_name, profileUrl, bestBet, returnPercent, won, lose }) => ({
      player_name,
      profileUrl,
      bestBet,
      returnPercent,
      won,
      lose,
    }),
  )
}

const firebaseGetErrorsBet = async (id) => {
  try {
    const collectionRef = collection(firebaseDb, 'errorlogs')
    const errorLogsQuery = query(collectionRef, where('betID', '==', id))
    const errorLogsSnapshot = await getDocs(errorLogsQuery)
    let errorLogs = []
    errorLogsSnapshot.forEach((snapshot) => {
      if (!snapshot.exists()) {
        return
      }
      errorLogs.push(snapshot.data())
    })
    console.log(
      '🚀 ~ file: utils.js:1354 ~ firebaseGetErrorsBet ~ errorLogs:',
      errorLogs,
    )
    return errorLogs
  } catch (error) {
    return { message: 'error', error }
  }
}
const firebaseGetBetById = async (betID) => {
  let bet = {}
  try {
    const docRef = doc(firebaseDb, BETS_COLLECTION_NAME, betID)
    const docSnapshot = await getDoc(docRef)

    if (docSnapshot.exists()) {
      bet = docSnapshot.data()
    }
    return { message: 'success', bet }
  } catch (error) {
    return { message: 'error', bet }
  }
}

const firebaseGetGameOdssNumber = async (gameId) => {
  const queryRef = collection(firebaseDb, GAMES_COLLECTION_NAME, gameId, 'odds')

  const querySnapshot = await getDocs(queryRef)
  const successBets = []

  querySnapshot.forEach((doc) => {
    const data = doc.data()
    successBets.push(data)
  })
  return successBets
}

const setDocWithRandomId = async (docQuery, messages) => {
  await setDoc(
    docQuery,
    { [randomId(7)]: messages },
    {
      merge: true,
    },
  )
}

const firebaseGetHappyHourStatus = async () => {
  try {
    const happyHourRef = doc(firebaseDb, ADMIN_CONFIGURATION, 'happy-hour')
    const docSnapshot = await getDoc(happyHourRef)

    if (docSnapshot.exists()) {
      return docSnapshot.data()
    } else {
      false
    }
  } catch (error) {
    return { error: true, error }
  }
}

const firebaseHappyHourStatusChange = async (status) => {
  try {
    const data = { isActive: status }
    return await FireabseORM.update(ADMIN_CONFIGURATION, 'happy-hour', data)
  } catch (error) {
    return { error: true, error }
  }
}

const firebaseSendtoAdmin = async (messages) => {
  try {
    const chatTags = doc(firebaseDb, 'admin-configurations', 'chat-tags')

    await setDocWithRandomId(chatTags, messages)

    return true
  } catch (error) {
    return { error: true, error }
  }
}

const firebasaSendComplaints = async (messages) => {
  try {
    const complaints = doc(firebaseDb, 'chat', 'complaints')

    await setDocWithRandomId(complaints, messages)

    return true
  } catch (error) {
    console.log(error)
    return { error: true, error }
  }
}

const firebasaSendWarning = async (messages) => {
  try {
    const userWarningsCollectionRef = collection(
      firebaseDb,
      `chat/warnings/${messages.uid}`,
    )
    const querySnapshot = await getDocs(query(userWarningsCollectionRef))
    const randomDocId = randomId(7)
    if (querySnapshot.empty) {
      const collectionListRef = doc(firebaseDb, 'chat', 'warnings')
      const randomDocId = randomId(7)
      await updateDoc(collectionListRef, {
        collectionList: arrayUnion(messages.uid),
      })
      await setDoc(doc(userWarningsCollectionRef, randomDocId), {
        messagesId: randomDocId,
        isRead: false,
        type: 'warning',
        warningDate: Timestamp.now(),
        ...messages,
      })
    } else {
      const newDocumentRef = doc(userWarningsCollectionRef, randomDocId)
      await setDoc(newDocumentRef, {
        messagesId: randomDocId,
        isRead: false,
        type: 'warning',
        warningDate: Timestamp.now(),
        ...messages,
      })
    }

    return true
  } catch (error) {
    console.log(error)
    return { error: true, error }
  }
}

const firebaseBanOrUnban = async (messages, isBan = true) => {
  try {
    const userWarningsCollectionRef = collection(
      firebaseDb,
      `chat/warnings/${messages.uid}`,
    )
    const querySnapshot = await getDocs(query(userWarningsCollectionRef))

    if (querySnapshot.empty) {
      const collectionListRef = doc(firebaseDb, 'chat', 'warnings')
      await updateDoc(collectionListRef, {
        collectionList: arrayUnion(messages.uid),
      })
      await setDoc(doc(userWarningsCollectionRef, 'status'), {
        isBan,
        isRead: true,
        answerUser: '',
        answerAdmin: '',
        type: 'ban',
        bannedDate: Timestamp.now(),
        ...messages,
      })
    } else {
      const newDocumentRef = doc(userWarningsCollectionRef, 'status')
      if (isBan) {
        await setDoc(newDocumentRef, {
          isBan,
          isRead: true,
          answerUser: '',
          answerAdmin: '',
          type: 'ban',
          bannedDate: Timestamp.now(),
          ...messages,
        })
      } else {
        await updateDoc(newDocumentRef, {
          answerUser: messages.answerUser,
        })
      }
    }
  } catch (error) {
    console.log(error)
    return { error: true, error }
  }
}

const firebaseWarningConfirmation = async (messages) => {
  try {
    const userWarningsCollectionRef = collection(
      firebaseDb,
      `chat/warnings/${messages.uid}`,
    )
    const newDocumentRef = doc(userWarningsCollectionRef, messages.messagesId)
    await updateDoc(newDocumentRef, {
      isRead: true,
    })
  } catch (error) {
    console.log(error)
    return { error: true, error }
  }
}

const firebaseRemoveText = async (messages) => {
  try {
    const dbRef = getDatabase()
    const chatRef = realTimeRef(dbRef, `chat/${messages.id}`)
    update(chatRef, { ...messages, text: '', isRemove: true })
  } catch (error) {
    console.log(error)
    return { error: true, error }
  }
}

const firebaseCheckUserChatStatus = async (uid) => {
  try {
    const userWarningsCollectionRef = collection(
      firebaseDb,
      `chat/warnings/${uid}`,
    )
    const querySnapshot = await getDocs(query(userWarningsCollectionRef))
    if (querySnapshot.empty) {
      return false
    } else {
      const datas = querySnapshot.docs.map((docsData) => {
        return docsData.data()
      })
      return datas
    }
  } catch (error) {
    console.log(error)
    return { error: true, error }
  }
}

const firebaseUpdateUserOptions = async (uid, options, type) => {
  const userInfoFromDb = await firebaseGetUserInfoFromDb(uid)
  const userRef = doc(firebaseDb, 'users', uid)

  const defaultOptions = userInfoFromDb?.[type] || []
  const res = await updateDoc(userRef, {
    ...userInfoFromDb,
    [type]: {
      ...defaultOptions,
      ...options,
    },
  })
    .then(() => {
      return {
        ...defaultOptions,
        ...options,
      }
    })
    .catch((err) => {
      return { error: true, err }
    })
  return res
}

const firebaseRemoveUserOptions = async (userUid, bannedUserUid) => {
  const userInfoFromDb = await firebaseGetUserInfoFromDb(userUid)
  const userRef = doc(firebaseDb, 'users', userUid)

  const defaultOptions = userInfoFromDb?.blockedUsers || {}
  delete defaultOptions[bannedUserUid]

  const res = await updateDoc(userRef, {
    ...userInfoFromDb,
    blockedUsers: {
      ...defaultOptions,
    },
  })
    .then(() => defaultOptions)
    .catch((err) => {
      return { error: true, err }
    })
  return res
}

const firebaseResetGame = async (uid, docId) => {
  const gamesRef = doc(firebaseDb, 'casino-prod', docId, 'games', uid)

  await setDoc(gamesRef, {})
}

const firebaseUpdateOddsDisplayType = async (uid, oddsDisplayType) => {
  const oddType = {
    oddsDisplayType,
  }
  setDoc(doc(firebaseDb, 'users', uid), oddType, {
    merge: true,
  }).catch((err) => {
    console.log(err)
  })
}

const getFavouriteMarkets = async (userId) => {
  try {
    const usersCollection = collection(firebaseDb, 'users')
    const userRef = doc(usersCollection, userId)
    const userSnapshot = await getDoc(userRef)
    if (!userSnapshot.exists()) {
      return []
    }

    // Get the user's reference
    const userReference = userRef

    // Filter prediction markets based on the criteria
    const favoritesCollection = collection(firebaseDb, 'favourite')
    const currentTimestamp = new Date()

    const relatedEvents = await getDocs(
      query(favoritesCollection, where('user', '==', userReference)),
    )

    // Extract market references from the user's favorites
    const marketRefs = relatedEvents.docs.map((doc) => doc.get('market').id)

    // Fetch prediction markets using the extracted market references
    const predictionMarketsCollection = collection(
      firebaseDb,
      'prediction-markets',
    )
    const predictionMarketsSnapshot = await getDocs(
      query(
        predictionMarketsCollection,
        where('approved', '==', true),
        where('expiryDate', '>=', currentTimestamp.toISOString()),
      ),
    )

    const eventData = predictionMarketsSnapshot.docs
      .filter((doc) => marketRefs.includes(doc.id))
      .map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))

    console.log('eventData  ' + Object.entries(eventData))
    return eventData
  } catch (e) {
    errorMsg(e.code ? e.code : e.message)
    console.log(e.code, e.message, 'error')
    return []
  }
}

const fetchMarketsReleted = async (category, eventId) => {
  try {
    const predictionMarketsCollection = collection(
      firebaseDb,
      'prediction-markets',
    )
    const querySnapshot = await getDocs(
      query(
        predictionMarketsCollection,
        where('approved', '==', true),
        where('category', '==', category),
        where('status', '==', 'active'),
      ),
    )

    const eventData = []

    querySnapshot.forEach((doc) => {
      if (doc.id !== eventId) {
        eventData.push({
          ...doc.data(),
          id: doc.id,
        })
      }
    })

    return eventData
  } catch (e) {
    errorMsg(e.code ? e.code : e.message)
    console.log(e.code, e.message, 'error')
    return []
  }
}

const getPredictionBetById = async (betID) => {
  try {
    const docRef = doc(firebaseDb, 'prediction-markets-bets', betID)
    const docSnapshot = await getDoc(docRef)

    if (docSnapshot.exists()) {
      return docSnapshot.data()
    } else return null
  } catch (error) {
    return error
  }
}

const getPredictionBets = async (uid = null, marketRef = null) => {
  try {
    let queryConstraints = [orderBy('createdAt', 'desc')]
    if (uid) {
      const userRef = doc(firebaseDb, 'users', uid)
      queryConstraints.push(where('user', '==', userRef))
    } else {
      queryConstraints.push(where('market', '==', marketRef)),
        queryConstraints.push(limit(50))
    }
    const betsQuery = query(
      collection(firebaseDb, 'prediction-markets-bets'),
      ...queryConstraints,
    )
    const docSnapshot = await getDocs(betsQuery)
    const bets = docSnapshot.docs.map((doc) => doc.data())
    return bets
  } catch (error) {
    return error
  }
}

const updateCurrentWalletType = async (uid, walletType) => {
  await setDoc(
    doc(firebaseDb, 'users', uid),
    { activeWallet: walletType },
    {
      merge: true,
    },
  ).catch((err) => {
    console.log(err)
  })
}

const firebaseSendResetLink = async (email) => {
  return await sendPasswordResetEmail(firebaseAuth, email)
    .then(() => {
      return { msg: 'success' }
    })
    .catch((error) => {
      const errorMessage = error.message
      return { msg: errorMessage }
    })
}

const firebaseResetPassword = async (actionCode, newPassword) => {
  return await confirmPasswordReset(firebaseAuth, actionCode, newPassword)
    .then(() => {
      return {
        success: true,
        msg: 'Password change operation completed successfully.',
      }
    })
    .catch(() => {
      return { success: false, msg: 'Connection timed out, please try again.' }
    })
}
export {
  fetchEnquiries,
  fetchEvents,
  fetchMarketsReleted,
  fetchUsers,
  firebasaGetBetsWithPaginate,
  firebasaSendComplaints,
  firebasaSendWarning,
  firebaseAddFavourites,
  firebaseBanOrUnban,
  firebaseCheckUserChatStatus,
  firebaseGetAdminConfigurations,
  firebaseGetAllGamesBets,
  firebaseGetAllTeamsBets,
  firebaseGetAuthorizedUser,
  firebaseGetBetById,
  firebaseGetCasinoHistories,
  firebaseGetChatSettings,
  firebaseGetCountriesLeagues,
  firebaseGetDailyTracks,
  firebaseGetErrorsBet,
  firebaseGetEventGame,
  firebaseGetFavourites,
  firebaseGetFavouritesMatches,
  firebaseGetGameOdssNumber,
  firebaseGetGames,
  firebaseGetHappyHourStatus,
  firebaseGetLatestPlinkoGamesSortedByDate,
  firebaseGetLatestPlinkoGamesSortedByEarn,
  firebaseGetOwnPlinkoHistory,
  firebaseGetPlacedBets,
  firebaseGetPlinkoFailPayments,
  firebaseGetPlinkoGames,
  firebaseGetPlinkoStats,
  firebaseGetPokerCurrentGame,
  firebaseGetPredictionGame,
  firebaseGetSideBarData,
  firebaseGetUserInfoFromDb,
  firebaseGetUserbyEmail,
  firebaseGetUsername,
  firebaseHappyHourStatusChange,
  firebaseIsUsernameExist,
  firebaseLogin,
  firebaseLoginWithGoogle,
  firebaseLogout,
  firebaseOnIdTokenChange,
  firebaseReadReferrers,
  firebaseReauthenticateUser,
  firebaseRegister,
  firebaseRemoveExtrasFavourites,
  firebaseRemoveFavourites,
  firebaseRemoveText,
  firebaseRemoveUserOptions,
  firebaseResetGame,
  firebaseResetPassword,
  firebaseSendResetLink,
  firebaseSendVerifyEmail,
  firebaseSendtoAdmin,
  firebaseUpdateAdminConfigurations,
  firebaseUpdateLiveGames,
  firebaseUpdateOddsDisplayType,
  firebaseUpdatePassword,
  firebaseUpdateUser,
  firebaseUpdateUserOptions,
  firebaseUpdatebyEmail,
  firebaseVerifyEmail,
  firebaseWarningConfirmation,
  getFavouriteMarkets,
  getLeaderboardData,
  getNotificationsSetting,
  getPredictionBetById,
  getPredictionBets,
  getProfileSetting,
  getReferralStats,
  refreshAuthTokenHandler,
  setNotificationsSetting,
  updateCurrentWalletType,
  updateNotificationsSetting,
  updateNovuSubscriber,
  updateProfileSetting,
  updateReferralStats,
  updateUserEmail,
  uploadFile,
}
