import * as React from 'react'
const Kazakhstan = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M0,0 L22,0 L33,4 L43,10 L51,18 L57,28 L61,39 L61,61 L57,72 L51,82 L43,90 L33,96 L22,100 L0,100 L-11,96 L-21,90 L-29,82 L-35,72 L-39,61 L-39,39 L-35,28 L-29,18 L-21,10 L-11,4 Z "
      fill="#02AFC9"
      transform="translate(39,0)"
    />
    <path
      d="M0,0 L2,0 L2,6 L-7,7 L-7,9 L-5,8 L6,7 L3,6 L4,1 L6,1 L7,3 L9,2 L10,5 L13,5 L14,6 L16,6 L15,9 L18,9 L17,12 L20,14 L19,15 L21,16 L22,23 L21,26 L19,27 L20,29 L17,30 L18,34 L14,36 L13,35 L12,37 L10,37 L8,40 L7,39 L6,41 L4,41 L3,36 L8,35 L7,34 L-5,34 L-8,32 L-7,33 L-7,35 L-1,36 L-2,41 L-4,41 L-5,39 L-7,40 L-8,37 L-11,37 L-11,35 L-13,35 L-13,33 L-16,33 L-15,30 L-18,28 L-17,27 L-19,26 L-20,20 L-17,19 L-19,18 L-19,16 L-17,15 L-18,13 L-15,12 L-15,9 L-13,9 L-13,7 L-11,7 L-10,3 L-8,5 L-6,2 L-5,3 L-4,1 Z "
      fill="#FAC410"
      transform="translate(49,21)"
    />
    <path
      d="M0,0 L2,0 L3,2 L5,2 L9,12 L15,17 L22,20 L28,22 L37,19 L45,14 L50,6 L51,2 L53,2 L54,0 L56,0 L56,4 L58,4 L59,9 L57,15 L55,16 L54,19 L52,19 L52,21 L48,22 L48,24 L44,26 L40,25 L45,24 L47,20 L51,15 L51,12 L44,18 L36,23 L29,24 L25,26 L25,29 L35,29 L37,27 L45,27 L45,29 L39,30 L39,32 L32,33 L24,33 L24,31 L19,30 L24,25 L26,25 L25,23 L18,22 L9,15 L3,10 L4,16 L6,16 L8,21 L10,21 L10,23 L17,25 L14,26 L7,23 L7,21 L3,20 L-2,11 L-3,7 L-2,4 L0,4 Z "
      fill="#D7BF53"
      transform="translate(22,44)"
    />
    <path
      d="M0,0 L2,0 L3,2 L5,1 L6,4 L9,4 L10,5 L12,5 L11,8 L14,8 L13,11 L16,13 L15,14 L17,15 L18,22 L17,25 L15,26 L16,28 L13,29 L14,33 L10,35 L9,34 L8,36 L6,36 L4,39 L3,38 L2,40 L0,40 L-1,35 L4,34 L4,31 L9,26 L9,14 L4,9 L1,7 L2,6 L-1,5 Z "
      fill="#5FB4A4"
      transform="translate(53,22)"
    />
    <path
      d="M0,0 L2,0 L2,6 L-7,7 L-7,9 L-9,9 L-10,14 L-11,16 L-11,25 L-8,31 L-9,33 L-7,33 L-7,35 L-1,36 L-2,41 L-4,41 L-5,39 L-7,40 L-8,37 L-11,37 L-11,35 L-13,35 L-13,33 L-16,33 L-15,30 L-18,28 L-17,27 L-19,26 L-20,20 L-17,19 L-19,18 L-19,16 L-17,15 L-18,13 L-15,12 L-15,9 L-13,9 L-13,7 L-11,7 L-10,3 L-8,5 L-6,2 L-5,3 L-4,1 Z "
      fill="#63B4A3"
      transform="translate(49,21)"
    />
    <path
      d="M0,0 L5,1 L4,4 L7,5 L6,7 L9,6 L10,9 L12,8 L13,11 L15,10 L15,13 L17,13 L18,8 L12,7 L12,5 L10,5 L11,3 L14,5 L27,5 L27,7 L22,8 L23,13 L21,15 L20,18 L19,16 L10,15 L2,9 L-2,3 Z "
      fill="#17AFC6"
      transform="translate(30,49)"
    />
    <path
      d="M0,0 L2,0 L2,6 L-7,7 L-7,9 L-9,9 L-9,11 L-11,11 L-11,13 L-13,13 L-13,16 L-18,14 L-15,12 L-15,9 L-13,9 L-13,7 L-11,7 L-10,3 L-8,5 L-6,2 L-5,3 L-4,1 Z "
      fill="#C4BD66"
      transform="translate(49,21)"
    />
    <path
      d="M0,0 L2,0 L3,2 L5,1 L6,4 L9,4 L10,5 L12,5 L11,8 L14,8 L13,11 L16,13 L11,15 L11,12 L9,12 L7,9 L1,8 L2,6 L-1,5 Z "
      fill="#B2BB77"
      transform="translate(53,22)"
    />
    <path
      d="M0,0 L4,2 L17,2 L17,4 L12,5 L13,10 L7,11 L8,5 L2,4 L2,2 L0,2 Z M6,11 L11,12 L10,15 L9,13 L4,13 Z "
      fill="#27B0C2"
      transform="translate(40,52)"
    />
    <path
      d="M0,0 L5,0 L6,2 L5,4 L7,4 L7,6 L13,7 L12,12 L10,12 L9,10 L7,11 L6,8 L3,8 L3,6 L1,6 L1,4 L-2,4 Z "
      fill="#BFBC6B"
      transform="translate(35,50)"
    />
    <path
      d="M0,0 L3,0 L3,2 L7,0 L7,4 L5,5 L4,8 L2,8 L2,10 L-2,11 L-2,13 L-6,15 L-10,14 L-5,13 L-3,9 L1,4 Z "
      fill="#C2BD6B"
      transform="translate(72,55)"
    />
    <path
      d="M0,0 L2,0 L2,4 L4,4 L5,9 L3,11 L-1,9 L-3,5 L-3,2 L-1,2 Z "
      fill="#D1BF5A"
      transform="translate(76,44)"
    />
    <path
      d="M0,0 L6,0 L7,3 L5,3 L4,5 L2,5 L0,8 L-1,7 L-2,9 L-4,9 L-5,4 L0,3 Z "
      fill="#BFBD6A"
      transform="translate(57,53)"
    />
    <path
      d="M0,0 L8,0 L8,2 L2,3 L2,5 L-6,6 L-8,4 L-2,2 Z "
      fill="#E5C138"
      transform="translate(59,71)"
    />
    <path
      d="M0,0 L4,0 L6,5 L8,5 L8,7 L15,9 L12,10 L5,7 L5,5 L1,4 Z "
      fill="#AFBA7F"
      transform="translate(24,60)"
    />
    <path
      d="M0,0 L4,2 L13,3 L11,5 L11,9 L9,9 L8,5 L2,4 L2,2 L0,2 Z "
      fill="#68B59F"
      transform="translate(40,52)"
    />
    <path
      d="M0,0 L5,1 L5,3 L3,4 L4,6 L-1,5 Z "
      fill="#C5BD69"
      transform="translate(65,44)"
    />
    <path
      d="M0,0 L3,0 L4,5 L-1,6 L0,4 L-2,3 L-2,1 Z "
      fill="#C4BD68"
      transform="translate(32,44)"
    />
    <path
      d="M0,0 L2,4 L6,6 L2,8 L2,6 L-1,5 Z "
      fill="#F9C40F"
      transform="translate(73,49)"
    />
    <path
      d="M0,0 L4,5 L3,9 L1,8 L-1,1 Z "
      fill="#B8BB79"
      transform="translate(20,50)"
    />
    <path
      d="M0,0 L4,2 L3,7 L-2,5 L1,3 Z "
      fill="#D3BF59"
      transform="translate(33,30)"
    />
    <path
      d="M0,0 L0,3 L3,5 L-2,7 L-3,2 Z "
      fill="#D4BF58"
      transform="translate(66,30)"
    />
    <path
      d="M0,0 L2,0 L2,6 L-1,6 L-2,1 Z "
      fill="#88B88B"
      transform="translate(49,21)"
    />
    <path
      d="M0,0 L6,1 L4,2 L4,4 L2,4 L1,6 Z "
      fill="#AFBA7F"
      transform="translate(36,28)"
    />
  </svg>
)
export default Kazakhstan
