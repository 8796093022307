import React from 'react'

const Mlb = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={150}
      height={84}
      viewBox="0 0 150 84"
      {...props}
    >
      <path
        d="M0 0 C1.19 -0.04 2.38 -0.08 3.6 -0.12 C9.05 -0.23 14.5 -0.33 19.96 -0.39 C22.83 -0.43 25.7 -0.5 28.57 -0.6 C53.68 -1.45 53.68 -1.45 59.2 2.92 C61.86 5.74 63.54 8.93 65.22 12.42 C66.17 13.66 67.16 14.87 68.2 16.04 C69.29 17.43 69.29 17.43 70.41 18.85 C71.54 20.28 71.54 20.28 72.7 21.73 C74.22 24.42 74.22 24.42 73.96 27.27 C73.71 27.98 73.47 28.69 73.22 29.42 C71.73 28.92 71.73 28.92 70.22 28.42 C66.86 28.15 63.51 27.97 60.15 27.8 C57.22 27.42 57.22 27.42 55.22 25.42 C55.22 24.1 55.22 22.78 55.22 21.42 C55.88 21.42 56.54 21.42 57.22 21.42 C55.87 14.53 55.87 14.53 51.59 9.29 C47.35 8.19 43.56 7.68 39.22 8.42 C35.3 11.03 34.63 14.08 33.22 18.42 C32.23 19.08 31.24 19.74 30.22 20.42 C31.87 20.75 33.52 21.08 35.22 21.42 C34.88 22.56 34.88 22.56 34.53 23.73 C34.43 24.62 34.32 25.5 34.22 26.42 C35.2 27.44 36.2 28.44 37.22 29.42 C37.22 31.29 37.22 31.29 36.22 33.42 C35.04 34.41 35.04 34.41 33.84 35.42 C29.98 39.83 29.33 44.76 28.22 50.42 C29.87 50.42 31.52 50.42 33.22 50.42 C33.88 52.07 34.54 53.72 35.22 55.42 C29.45 55.49 23.69 55.55 17.92 55.58 C15.96 55.6 14 55.62 12.04 55.64 C9.22 55.68 6.4 55.7 3.58 55.71 C2.7 55.73 1.83 55.74 0.92 55.76 C-2.59 55.76 -5.11 55.74 -8.3 54.21 C-11.85 49.91 -10.94 43.66 -10.95 38.35 C-10.95 37.49 -10.96 36.62 -10.96 35.72 C-10.96 33.89 -10.97 32.06 -10.97 30.23 C-10.97 27.43 -10.99 24.63 -11.01 21.83 C-11.01 20.05 -11.01 18.26 -11.01 16.48 C-11.02 15.65 -11.03 14.81 -11.03 13.95 C-11.02 9.71 -10.94 6.2 -8.78 2.42 C-5.38 0.15 -4.01 0.12 0 0 Z M1.22 38.42 C0.56 40.07 -0.1 41.72 -0.78 43.42 C-0.12 44.08 0.54 44.74 1.22 45.42 C3.82 45.1 3.82 45.1 6.22 44.42 C6.22 42.77 6.22 41.12 6.22 39.42 C4.57 39.09 2.92 38.76 1.22 38.42 Z "
        fill="#061F43"
        transform="translate(29.781494140625,12.58251953125)"
      />
      <path
        d="M0 0 C4.06 -0.07 8.11 -0.13 12.17 -0.16 C13.55 -0.18 14.93 -0.2 16.31 -0.23 C18.29 -0.26 20.28 -0.28 22.27 -0.29 C23.46 -0.31 24.65 -0.32 25.88 -0.34 C29.37 0.04 30.51 0.59 33 3 C33.51 5.2 33.51 5.2 33.52 7.79 C33.53 8.76 33.54 9.73 33.56 10.73 C33.55 11.78 33.54 12.83 33.53 13.91 C33.54 14.98 33.54 16.06 33.54 17.17 C33.55 19.44 33.54 21.72 33.52 24 C33.5 27.49 33.52 30.97 33.55 34.46 C33.54 36.67 33.54 38.88 33.53 41.09 C33.54 42.14 33.55 43.19 33.56 44.27 C33.54 45.24 33.53 46.21 33.52 47.21 C33.51 48.49 33.51 48.49 33.51 49.8 C33 52 33 52 31.2 53.78 C27.97 55.57 24.84 55.32 21.25 55.19 C20.55 55.17 19.86 55.16 19.14 55.15 C17.42 55.11 15.71 55.06 14 55 C14 53.35 14 51.7 14 50 C15.14 49.94 15.14 49.94 16.3 49.89 C17.29 49.82 18.29 49.76 19.31 49.69 C20.3 49.63 21.28 49.57 22.3 49.51 C25.26 48.95 26.29 48.44 28 46 C27.82 43.51 27.82 43.51 27 41 C26.92 40.28 26.84 39.56 26.75 38.81 C26.11 36.7 26.11 36.7 23.44 35.75 C22.63 35.5 21.83 35.26 21 35 C21 33.68 21 32.36 21 31 C20.34 31 19.68 31 19 31 C19 30.34 19 29.68 19 29 C18.34 29 17.68 29 17 29 C17 28.01 17 27.02 17 26 C16.01 25.67 15.02 25.34 14 25 C12.37 22.81 12.37 22.81 10.64 19.94 C10.02 18.92 9.4 17.89 8.76 16.83 C8.12 15.75 7.47 14.67 6.81 13.56 C5.83 11.95 5.83 11.95 4.83 10.3 C0 2.28 0 2.28 0 0 Z "
        fill="#C01342"
        transform="translate(93,13)"
      />
      <path
        d="M0 0 C1.78 -0.06 1.78 -0.06 3.6 -0.12 C9.07 -0.23 14.53 -0.33 20 -0.39 C22.87 -0.43 25.75 -0.5 28.63 -0.6 C53.79 -1.45 53.79 -1.45 59.32 2.92 C61.98 5.74 63.66 8.93 65.33 12.42 C66.29 13.66 67.27 14.87 68.31 16.04 C69.4 17.43 69.4 17.43 70.52 18.85 C71.65 20.28 71.65 20.28 72.81 21.73 C74.33 24.42 74.33 24.42 74.07 27.27 C73.83 27.98 73.58 28.69 73.33 29.42 C71.85 28.92 71.85 28.92 70.33 28.42 C66.98 28.15 63.63 27.97 60.27 27.8 C57.33 27.42 57.33 27.42 55.33 25.42 C55.33 24.1 55.33 22.78 55.33 21.42 C55.99 21.42 56.65 21.42 57.33 21.42 C56 14.45 56 14.45 51.52 9.35 C48.45 8.45 45.51 7.8 42.33 7.42 C43.32 7.09 44.31 6.76 45.33 6.42 C45.33 5.43 45.33 4.44 45.33 3.42 C27.84 3.09 10.35 2.76 -7.67 2.42 C-5.02 -0.23 -3.7 0.11 0 0 Z "
        fill="#233754"
        transform="translate(29.66796875,12.58251953125)"
      />
      <path
        d="M0 0 C0.33 0 0.66 0 1 0 C1.33 15.84 1.66 31.68 2 48 C5.27 49.64 8.91 49.24 12.51 49.32 C13.77 49.35 13.77 49.35 15.06 49.38 C17.75 49.44 20.43 49.5 23.12 49.56 C24.95 49.61 26.77 49.65 28.59 49.69 C33.06 49.8 37.53 49.9 42 50 C42 49.34 42 48.68 42 48 C40.35 48 38.7 48 37 48 C37.33 47.01 37.66 46.02 38 45 C38 45.66 38 46.32 38 47 C39.65 47 41.3 47 43 47 C43.66 48.65 44.32 50.3 45 52 C39.23 52.07 33.47 52.13 27.7 52.16 C25.74 52.18 23.78 52.2 21.82 52.23 C19 52.26 16.18 52.28 13.36 52.29 C12.48 52.31 11.61 52.32 10.7 52.34 C7.2 52.34 4.67 52.32 1.48 50.79 C-2.08 46.47 -1.15 40.23 -1.13 34.9 C-1.13 34.03 -1.13 33.16 -1.14 32.26 C-1.14 30.43 -1.13 28.59 -1.13 26.75 C-1.13 23.94 -1.13 21.14 -1.14 18.33 C-1.14 16.54 -1.13 14.75 -1.13 12.96 C-1.13 12.13 -1.14 11.29 -1.14 10.42 C-1.14 9.64 -1.13 8.85 -1.13 8.05 C-1.13 7.36 -1.13 6.67 -1.13 5.97 C-0.99 3.88 -0.57 2.01 0 0 Z "
        fill="#34445E"
        transform="translate(20,16)"
      />
      <path
        d="M0 0 C2 1 2 1 2 1 Z "
        fill="#FFFFFF"
        transform="translate(16,18)"
      />
    </svg>
  )
}

export default Mlb
