import React from 'react'
const ConmebolSudamericana = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    viewBox="0 0 150 150"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,150 L0,150 Z "
      fill="#F6F6F6"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L8,0 L15,3 L20,7 L24,15 L24,25 L23,33 L29,33 L29,45 L39,45 L36,46 L36,51 L41,51 L41,58 L35,58 L35,70 L41,70 L42,64 L48,64 L48,70 L54,70 L54,64 L60,64 L60,58 L66,58 L66,52 L60,52 L60,46 L54,45 L54,39 L60,39 L60,33 L66,33 L66,26 L61,26 L61,21 L66,20 L67,15 L72,15 L72,20 L79,20 L79,133 L16,133 L16,127 L10,127 L10,121 L16,120 L15,114 L13,109 L16,109 L16,105 L12,105 L12,116 L10,116 L10,120 L4,120 L4,116 L-3,116 L-3,120 L-8,120 L-9,115 L-13,115 L-14,111 L-15,120 L-21,120 L-21,116 L-23,116 L-23,114 L-28,114 L-28,120 L-33,120 L-33,115 L-39,117 L-40,120 L-58,120 L-59,114 L-62,116 L-68,116 L-69,113 L-62,113 L-63,111 L-68,109 L-69,104 L-67,102 L-61,102 L-59,104 L-58,102 L-54,102 L-54,113 L-49,113 L-49,102 L-24,102 L-20,113 L-19,102 L-21,102 L-22,92 L-26,92 L-27,83 L-21,83 L-21,77 L-10,76 L-5,68 L-4,64 L-4,53 L-10,38 L-15,27 L-16,24 L-16,15 L-12,7 L-4,1 Z "
      fill="#F5F5F5"
      transform="translate(71,17)"
    />
    <path
      d="M0,0 L8,0 L15,3 L20,7 L24,15 L24,25 L14,47 L12,53 L12,65 L16,73 L18,76 L13,76 L11,74 L11,85 L12,86 L23,86 L23,83 L29,83 L29,89 L27,89 L27,94 L30,94 L30,90 L34,90 L34,92 L31,92 L29,102 L36,103 L36,108 L40,108 L42,103 L46,103 L50,113 L51,102 L48,102 L48,96 L54,96 L54,102 L64,102 L65,114 L69,103 L73,103 L77,113 L77,116 L74,116 L72,107 L70,107 L72,113 L68,113 L68,116 L60,116 L57,111 L57,109 L55,109 L55,116 L48,116 L45,107 L43,107 L43,111 L45,111 L45,113 L41,113 L41,116 L29,116 L25,114 L24,116 L21,116 L20,108 L18,112 L19,116 L16,116 L13,111 L13,109 L16,109 L16,105 L12,105 L12,116 L10,116 L10,120 L4,120 L4,116 L-3,116 L-3,120 L-8,120 L-9,115 L-13,115 L-14,111 L-15,120 L-21,120 L-21,116 L-23,116 L-23,114 L-28,114 L-28,120 L-33,120 L-33,115 L-39,117 L-40,120 L-58,120 L-59,114 L-62,116 L-68,116 L-69,113 L-62,113 L-63,111 L-68,109 L-69,104 L-67,102 L-61,102 L-59,104 L-58,102 L-54,102 L-54,113 L-49,113 L-49,102 L-24,102 L-20,113 L-19,102 L-21,102 L-22,92 L-26,92 L-27,83 L-21,83 L-21,77 L-10,76 L-5,68 L-4,64 L-4,53 L-10,38 L-15,27 L-16,24 L-16,15 L-12,7 L-4,1 Z "
      fill="#F2F2F2"
      transform="translate(71,17)"
    />
    <path
      d="M0,0 L8,0 L15,3 L20,7 L24,15 L24,25 L14,47 L12,53 L12,65 L16,73 L18,76 L13,76 L8,66 L6,57 L3,56 L1,44 L-3,37 L-2,35 L3,41 L3,45 L5,45 L6,39 L11,35 L9,40 L7,44 L5,52 L9,52 L10,45 L19,25 L19,14 L15,8 L10,5 L-2,5 L-8,9 L-11,14 L-11,25 L-7,35 L-2,45 L0,52 L0,66 L-5,77 L-8,77 L-8,86 L-6,86 L-5,89 L-3,87 L-3,95 L-5,93 L-6,96 L-8,96 L-9,101 L-4,102 L-4,116 L-7,116 L-8,110 L-10,114 L-13,114 L-14,111 L-15,120 L-21,120 L-21,116 L-23,116 L-23,114 L-28,114 L-28,120 L-33,120 L-33,115 L-39,117 L-40,120 L-58,120 L-59,114 L-62,116 L-68,116 L-69,113 L-62,113 L-63,111 L-68,109 L-69,104 L-67,102 L-61,102 L-59,104 L-58,102 L-54,102 L-54,113 L-49,113 L-49,102 L-24,102 L-20,113 L-19,102 L-21,102 L-22,92 L-26,92 L-27,83 L-21,83 L-21,77 L-10,76 L-5,68 L-4,64 L-4,53 L-10,38 L-15,27 L-16,24 L-16,15 L-12,7 L-4,1 Z "
      fill="#AAABAC"
      transform="translate(71,17)"
    />
    <path
      d="M0,0 L6,0 L8,3 L19,3 L19,0 L25,0 L25,6 L23,6 L23,11 L26,11 L26,7 L30,7 L30,9 L27,9 L25,19 L32,20 L32,25 L36,25 L38,20 L42,20 L46,30 L47,19 L44,19 L44,13 L50,13 L50,19 L60,19 L61,31 L65,20 L69,20 L73,30 L73,33 L70,33 L68,24 L66,24 L68,30 L64,30 L64,33 L56,33 L53,28 L53,26 L51,26 L51,33 L44,33 L41,24 L39,24 L39,28 L41,28 L41,30 L37,30 L37,33 L25,33 L21,31 L20,33 L17,33 L16,25 L14,29 L15,33 L12,33 L9,28 L9,26 L12,26 L12,22 L8,22 L8,33 L6,33 L6,37 L0,37 L0,33 L-6,33 L-6,4 L-3,4 L-1,6 Z "
      fill="#EDEDED"
      transform="translate(75,100)"
    />
    <path
      d="M0,0 L6,0 L8,2 L9,0 L13,0 L13,11 L18,11 L18,0 L43,0 L47,11 L47,14 L44,14 L44,12 L39,12 L39,18 L34,18 L34,13 L28,15 L27,18 L9,18 L8,12 L5,14 L-1,14 L-2,11 L5,11 L4,9 L-1,7 L-2,2 Z "
      fill="#E8E8E8"
      transform="translate(4,119)"
    />
    <path
      d="M0,0 L8,0 L14,4 L17,9 L16,18 L11,24 L9,25 L-1,25 L-7,21 L-9,16 L-9,9 L-5,3 Z "
      fill="#9D977F"
      transform="translate(71,24)"
    />
    <path
      d="M0,0 L12,0 L12,11 L8,11 L8,18 L12,18 L12,24 L17,25 L17,39 L14,39 L13,33 L11,37 L8,37 L7,34 L6,43 L0,43 L2,25 L0,25 L-1,15 L-5,15 L-6,6 L0,6 Z "
      fill="#F2F2F2"
      transform="translate(50,94)"
    />
    <path
      d="M0,0 L6,0 L6,6 L16,6 L17,18 L21,7 L25,7 L29,17 L29,20 L26,20 L24,11 L22,11 L24,17 L20,17 L20,20 L12,20 L9,15 L9,13 L7,13 L7,20 L0,20 L-3,11 L-5,11 L-5,15 L-3,15 L-3,17 L-7,17 L-7,20 L-19,20 L-22,16 L-22,9 L-19,6 L-12,7 L-12,12 L-8,12 L-6,7 L-2,7 L2,17 L3,6 L0,6 Z "
      fill="#BEBEBE"
      transform="translate(119,113)"
    />
    <path
      d="M0,0 L8,0 L14,4 L17,9 L16,18 L11,24 L9,25 L-1,25 L-6,20 L8,20 L9,18 L14,16 L14,11 L12,5 L9,3 L-1,3 L-4,6 L-4,9 L3,8 L3,11 L1,12 L1,18 L-1,15 L-2,12 L-6,11 L-5,18 L-9,16 L-9,9 L-5,3 Z "
      fill="#4A4A4B"
      transform="translate(71,24)"
    />
    <path
      d="M0,0 L3,0 L5,2 L6,0 L8,0 L8,9 L6,9 L6,15 L9,15 L8,23 L3,23 L3,26 L9,26 L9,29 L0,29 Z "
      fill="#E5E5E5"
      transform="translate(69,104)"
    />
    <path
      d="M0,0 L3,1 L3,12 L-2,23 L-5,23 L-5,32 L-3,32 L-2,35 L0,33 L0,41 L-2,39 L-3,42 L-7,42 L-11,41 L-11,34 L-7,33 L-7,21 L-3,16 L-1,10 Z "
      fill="#888888"
      transform="translate(68,71)"
    />
    <path
      d="M0,0 L15,0 L15,14 L12,14 L11,6 L9,10 L10,14 L7,14 L4,9 L4,7 L7,7 L7,3 L3,3 L3,14 L0,14 Z "
      fill="#242424"
      transform="translate(80,119)"
    />
    <path
      d="M0,0 L7,1 L7,6 L11,7 L10,12 L9,14 L0,14 L-3,10 L-3,3 Z "
      fill="#EFEFEF"
      transform="translate(100,119)"
    />
    <path
      d="M0,0 L9,0 L11,2 L11,10 L7,13 L0,13 Z "
      fill="#1A1A1A"
      transform="translate(27,120)"
    />
    <path
      d="M0,0 L8,0 L15,3 L20,7 L23,12 L23,14 L21,14 L17,8 L12,6 L10,5 L-2,5 L-9,9 L-14,11 L-10,5 L-4,1 Z "
      fill="#BEBEBF"
      transform="translate(71,17)"
    />
    <path
      d="M0,0 L1,2 L4,2 L7,16 L9,20 L-2,20 L-2,18 L-4,17 L-1,10 Z "
      fill="#E6E6E6"
      transform="translate(71,73)"
    />
    <path
      d="M0,0 L4,0 L4,14 L1,14 L0,8 L-2,12 L-5,12 L-6,6 L-8,6 L-8,14 L-10,14 L-10,1 L-6,2 L-4,9 L-2,9 Z "
      fill="#2B2B2C"
      transform="translate(63,119)"
    />
    <path
      d="M0,0 L3,3 L2,12 L-3,18 L-5,19 L-15,19 L-20,14 L-6,14 L-5,12 L0,10 L0,5 L-1,2 Z "
      fill="#4A4B4C"
      transform="translate(85,30)"
    />
    <path
      d="M0,0 L5,5 L5,7 L8,8 L8,18 L0,35 L-2,36 L-1,31 L3,18 L3,7 L0,2 Z "
      fill="#777879"
      transform="translate(87,24)"
    />
    <path
      d="M0,0 L6,0 L7,3 L1,3 L2,5 L8,8 L7,13 L5,14 L-1,14 L-2,11 L5,11 L4,9 L-1,7 L-2,2 Z "
      fill="#343434"
      transform="translate(4,119)"
    />
    <path
      d="M0,0 L5,1 L6,8 L10,8 L10,14 L5,14 L4,9 L-1,8 L-1,1 Z "
      fill="#EDEDED"
      transform="translate(52,104)"
    />
    <path
      d="M0,0 L1,2 L-1,6 L-1,23 L0,27 L-3,24 L-6,16 L-6,7 L-4,3 Z "
      fill="#8C8D8E"
      transform="translate(61,25)"
    />
    <path
      d="M0,0 L5,1 L6,7 L14,7 L15,10 L-1,10 L-2,6 L0,6 Z "
      fill="#7D7E7F"
      transform="translate(65,35)"
    />
    <path
      d="M0,0 L3,0 L3,10 L-1,14 L-6,14 L-8,12 L-8,1 L-6,1 L-5,10 L0,11 Z "
      fill="#1B1B1B"
      transform="translate(22,119)"
    />
    <path
      d="M0,0 L4,1 L7,6 L7,8 L9,8 L9,0 L11,0 L11,12 L7,11 L4,6 L2,5 L2,12 L0,12 Z "
      fill="#040404"
      transform="translate(123,120)"
    />
    <path
      d="M0,0 L1,0 L2,12 L6,20 L8,23 L3,23 L-2,13 L-2,1 Z "
      fill="#505152"
      transform="translate(81,70)"
    />
    <path
      d="M0,0 L3,0 L7,10 L7,13 L4,13 L2,4 L0,4 L2,10 L-2,10 L-2,13 L-5,13 L-3,5 Z "
      fill="#1F1F1F"
      transform="translate(44,120)"
    />
    <path
      d="M0,0 L6,0 L6,6 L4,6 L4,11 L7,11 L7,7 L11,7 L11,9 L8,9 L7,13 L2,13 L0,5 L-3,3 L0,3 Z "
      fill="#EBEBEB"
      transform="translate(94,100)"
    />
    <path
      d="M0,0 L3,1 L3,12 L-2,22 L-7,22 L-2,14 Z "
      fill="#48484B"
      transform="translate(68,71)"
    />
    <path
      d="M0,0 L4,0 L8,10 L8,13 L5,12 L3,4 L1,4 L1,8 L3,8 L3,10 L-1,10 L-1,13 L-4,13 L-1,3 Z "
      fill="#1B1B1B"
      transform="translate(113,120)"
    />
    <path
      d="M0,0 L7,1 L7,3 L1,4 L1,10 L7,11 L6,14 L0,14 L-3,10 L-3,3 Z "
      fill="#171717"
      transform="translate(100,119)"
    />
    <path
      d="M0,0 L4,0 L8,10 L8,13 L5,13 L3,4 L1,4 L3,10 L-1,10 L-1,12 L-4,11 Z "
      fill="#141414"
      transform="translate(140,120)"
    />
    <path
      d="M0,0 L8,0 L8,2 L3,2 L3,10 L9,10 L9,13 L0,13 Z "
      fill="#252525"
      transform="translate(69,120)"
    />
    <path
      d="M0,0 L15,0 L15,14 L12,14 L10,2 L0,1 Z "
      fill="#585858"
      transform="translate(80,119)"
    />
    <path
      d="M0,0 L5,0 L6,1 L6,10 L0,10 Z "
      fill="#D9D9D9"
      transform="translate(78,103)"
    />
    <path
      d="M0,0 L2,0 L5,8 L5,13 L-1,13 L-2,6 L2,6 L2,4 L0,4 Z "
      fill="#EFEFEF"
      transform="translate(114,124)"
    />
    <path
      d="M0,0 L4,0 L4,10 L3,10 L3,1 L1,1 L1,11 L-1,11 L-2,8 L-8,5 L-8,3 L-1,2 Z "
      fill="#C9C9CA"
      transform="translate(13,119)"
    />
    <path
      d="M0,0 L5,5 L6,10 L8,10 L9,4 L14,0 L12,5 L7,16 L5,14 L2,5 Z "
      fill="#78797A"
      transform="translate(68,52)"
    />
    <path
      d="M0,0 L4,1 L1,1 L1,14 L3,14 L3,6 L5,6 L4,18 L-2,18 Z "
      fill="#CFCFCF"
      transform="translate(52,119)"
    />
    <path
      d="M0,0 L8,0 L14,4 L15,6 L13,8 L12,5 L9,3 L-1,3 L-6,8 L-7,5 L-2,1 Z "
      fill="#6D6D6E"
      transform="translate(71,24)"
    />
    <path
      d="M0,0 L2,0 L6,10 L-4,10 Z "
      fill="#7B7B7C"
      transform="translate(74,83)"
    />
    <path
      d="M0,0 L4,1 L7,3 L6,8 L1,7 L-1,6 L-1,1 Z "
      fill="#EAEAEA"
      transform="translate(77,26)"
    />
    <path
      d="M0,0 L6,0 L6,7 L5,9 L1,9 Z "
      fill="#DEDEDE"
      transform="translate(44,100)"
    />
    <path
      d="M0,0 L4,1 L4,6 L1,8 L-4,7 L-3,3 Z "
      fill="#BBBBBC"
      transform="translate(70,26)"
    />
    <path
      d="M0,0 L6,0 L6,3 L8,4 L6,4 L4,8 L3,4 L0,4 L0,13 L-2,12 L-2,3 L0,3 Z "
      fill="#D5D5D5"
      transform="translate(69,100)"
    />
    <path
      d="M0,0 L8,0 L8,1 L1,1 L1,14 L6,14 L5,18 L-1,18 Z "
      fill="#D6D6D6"
      transform="translate(26,119)"
    />
    <path
      d="M0,0 L4,1 L5,2 L5,10 L1,13 L-6,13 L-6,12 L0,11 L2,8 L1,3 L-3,1 Z "
      fill="#191919"
      transform="translate(33,120)"
    />
    <path
      d="M0,0 L8,0 L4,2 L6,6 L1,8 L-4,2 Z "
      fill="#D4D4D4"
      transform="translate(71,49)"
    />
    <path
      d="M0,0 L5,0 L5,5 L1,8 L-2,7 L-1,1 Z "
      fill="#BCBDBD"
      transform="translate(81,35)"
    />
    <path
      d="M0,0 L5,1 L6,6 L4,8 L0,8 Z "
      fill="#E6E6E6"
      transform="translate(30,122)"
    />
    <path
      d="M0,0 L4,1 L4,8 L0,9 L-1,8 L-1,1 Z "
      fill="#6F6F6F"
      transform="translate(58,104)"
    />
    <path
      d="M0,0 L4,1 L4,8 L0,9 L-1,8 L-1,1 Z "
      fill="#6B6B6C"
      transform="translate(91,104)"
    />
    <path
      d="M0,0 L2,0 L4,2 L5,0 L7,0 L7,9 L5,9 L3,5 L1,5 L0,8 Z "
      fill="#4F5050"
      transform="translate(70,104)"
    />
    <path
      d="M0,0 L6,2 L8,1 L9,4 L8,7 L1,7 Z "
      fill="#8F7B58"
      transform="translate(71,35)"
    />
    <path
      d="M0,0 L4,1 L5,6 L2,5 L2,13 L0,13 Z "
      fill="#060606"
      transform="translate(53,120)"
    />
    <path
      d="M0,0 L1,4 L0,6 L3,6 L3,17 L2,17 L1,11 L-2,10 L-1,3 Z "
      fill="#CBCBCB"
      transform="translate(76,63)"
    />
    <path
      d="M0,0 L2,0 L2,7 L5,7 L5,3 L9,3 L9,5 L6,5 L5,9 L0,9 Z "
      fill="#727273"
      transform="translate(96,104)"
    />
    <path
      d="M0,0 L1,0 L3,14 L4,18 L1,15 L-1,10 L-1,2 Z "
      fill="#4B4C4E"
      transform="translate(57,34)"
    />
    <path
      d="M0,0 L9,0 L8,8 L3,8 L3,3 L8,3 L8,1 L0,1 Z "
      fill="#909191"
      transform="translate(69,119)"
    />
    <path
      d="M0,0 L6,1 L2,2 L1,8 L-2,14 L-3,14 L-2,7 L0,7 Z "
      fill="#D7D7D7"
      transform="translate(138,118)"
    />
    <path
      d="M0,0 L6,0 L7,3 L1,3 L0,5 L-2,6 L-1,1 Z "
      fill="#383839"
      transform="translate(4,119)"
    />
    <path
      d="M0,0 L4,1 L2,1 L2,4 L4,5 L2,5 L2,7 L5,7 L5,9 L0,9 Z "
      fill="#484849"
      transform="translate(78,104)"
    />
    <path
      d="M0,0 L4,1 L4,3 L2,3 L1,7 L4,6 L3,9 L-1,8 L-1,1 Z "
      fill="#545454"
      transform="translate(52,104)"
    />
    <path
      d="M0,0 L4,1 L3,6 L2,8 L-1,7 Z "
      fill="#D7D7D7"
      transform="translate(107,125)"
    />
    <path
      d="M0,0 L4,0 L4,4 L1,4 L3,9 L0,9 Z "
      fill="#DEDEDF"
      transform="translate(83,122)"
    />
    <path
      d="M0,0 L4,0 L4,8 L3,5 L1,5 L0,8 Z "
      fill="#3A3A3A"
      transform="translate(84,104)"
    />
    <path
      d="M0,0 L8,0 L9,3 L-1,3 Z "
      fill="#BDBDBD"
      transform="translate(71,42)"
    />
    <path
      d="M0,0 L5,0 L5,5 L0,5 Z "
      fill="#FFFFFF"
      transform="translate(138,138)"
    />
    <path
      d="M0,0 L5,1 L5,7 L3,9 Z "
      fill="#D8D8D8"
      transform="translate(145,124)"
    />
    <path
      d="M0,0 L1,2 L5,2 L6,0 L8,4 L-2,4 Z "
      fill="#ACADAE"
      transform="translate(72,89)"
    />
    <path
      d="M0,0 L3,1 L3,12 L2,12 L2,7 L0,7 Z "
      fill="#727374"
      transform="translate(68,71)"
    />
    <path
      d="M0,0 L7,0 L8,7 L6,7 L3,2 Z "
      fill="#D7D7D7"
      transform="translate(123,119)"
    />
    <path
      d="M0,0 L2,0 L2,8 L1,10 L-1,10 L-1,3 Z "
      fill="#59595B"
      transform="translate(68,78)"
    />
    <path
      d="M0,0 L1,0 L1,8 L0,8 L-1,13 L-2,13 L-2,1 Z "
      fill="#777778"
      transform="translate(81,70)"
    />
    <path
      d="M0,0 L2,0 L5,9 L3,9 L3,7 L-2,7 L2,6 L2,4 L0,4 Z "
      fill="#CECECF"
      transform="translate(114,124)"
    />
    <path
      d="M0,0 L1,2 L8,4 L7,7 L0,4 Z "
      fill="#050505"
      transform="translate(3,122)"
    />
    <path
      d="M0,0 L7,1 L7,3 L2,3 L0,3 L-3,5 L-1,1 Z "
      fill="#292A2A"
      transform="translate(100,119)"
    />
    <path
      d="M0,0 L2,3 L3,8 L0,7 L-1,9 L-2,4 Z "
      fill="#E4E4E4"
      transform="translate(78,80)"
    />
    <path
      d="M0,0 L3,0 L1,6 L-2,7 L-1,1 Z "
      fill="#ECECEC"
      transform="translate(81,35)"
    />
    <path
      d="M0,0 L3,2 L4,9 L0,7 Z "
      fill="#6B6B6C"
      transform="translate(62,33)"
    />
    <path
      d="M0,0 L2,0 L2,4 L-1,6 L-4,6 L-2,2 Z "
      fill="#F3F3F3"
      transform="translate(72,28)"
    />
    <path
      d="M0,0 L2,0 L4,7 L-2,7 L1,5 Z "
      fill="#C3C3C4"
      transform="translate(44,124)"
    />
    <path
      d="M0,0 L4,1 L5,7 L3,7 L0,2 Z "
      fill="#CFCFCF"
      transform="translate(66,35)"
    />
    <path
      d="M0,0 L1,0 L0,10 L-1,6 L-5,5 L-2,4 Z "
      fill="#D3D3D3"
      transform="translate(63,126)"
    />
    <path
      d="M0,0 L2,0 L4,4 L3,7 L3,5 L1,5 L0,8 Z "
      fill="#313131"
      transform="translate(70,104)"
    />
    <path
      d="M0,0 L2,2 L2,6 L-2,7 Z "
      fill="#999A9B"
      transform="translate(81,64)"
    />
    <path
      d="M0,0 L5,5 L5,9 L1,4 Z "
      fill="#979798"
      transform="translate(68,52)"
    />
  </svg>
)
export default ConmebolSudamericana
