import * as React from 'react'
const Morocco = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L16,0 L26,6 L32,15 L33,17 L33,33 L27,43 L18,49 L16,50 L0,50 L-10,44 L-16,35 L-17,33 L-17,17 L-11,7 L-2,1 Z "
      fill="#C0272D"
      transform="translate(17,0)"
    />
    <path
      d="M0,0 L2,0 L3,3 L8,4 L8,6 L5,9 L4,13 L-1,12 L-4,14 L-3,8 L-6,6 L-6,4 L-1,3 Z "
      fill="#565431"
      transform="translate(24,18)"
    />
  </svg>
)
export default Morocco
