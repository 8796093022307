import * as React from 'react'
const Kosovo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={100}
    height={100}
    {...props}
  >
    <path
      d="M39 0h22l11 4 10 6 8 8 6 10 4 11v22l-4 11-6 10-8 8-10 6-11 4H39l-11-4-10-6-8-8-6-10-4-11V39l4-11 6-10 8-8 10-6Z"
      fill="#244AA4"
    />
    <path
      d="m48 30 3 1v3l9 3v3h2l1 4 5 2-1 5 6 1 6 2-1 5h-2l-2 7h-3l2 5-7 1-1 5h-4l-1-3-4 1-4 2h-4v8l-1 1h-5v-7l-3-8-7-3-4-8-1-1v-5h-3v-4l7-1 8-4v-3l5-1v-5l-1-3Z"
      fill="#CCA452"
    />
    <path d="m29 17 2 2 4 1v2l-2 1v5l-7-1-2-6h4Z" fill="#E6E8F1" />
    <path d="M15 22h2v3h5v2l-2 1v4l-5-1-2 1v-5h-2v-2h4Z" fill="#D8DBE9" />
    <path d="m83 22 2 1v2h4v2h-2v5l-4-1-3 1v-4l-2-1v-2h5Z" fill="#D9DDEA" />
    <path
      d="M56 15h2v3h4v2h-2l1 5-5-1-3 1 1-5h-2v-2h4Zm-14 0h2v3h4v2h-2l1 5-5-1h-3l1-4h-2v-2h4Z"
      fill="#DCDFEC"
    />
    <path d="m70 17 2 1v3l4 1-2 5h-5l-2 1v-5l-1-2h4Z" fill="#DFE1ED" />
    <path d="m29 17 2 2 4 1-1 2h-5l-1 5h-2l-2-6h4Z" fill="#C0C6DE" />
    <path d="M47 79h1v6l-1 1h-5l1-3h3Z" fill="#AE906C" />
    <path d="M24 50h7l-4 4h-3Z" fill="#B99762" />
  </svg>
)
export default Kosovo
