import React from 'react'
const Championship = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={166}
    viewBox="0 0 150 166"
    {...props}
  >
    <path
      d="M0,0 L150,0 L150,166 L0,166 Z "
      fill="#FEFEFD"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L93,0 L93,80 L90,93 L83,104 L73,114 L63,119 L51,122 L39,122 L27,118 L16,111 L7,100 L2,90 L0,82 Z "
      fill="#FCFDFB"
      transform="translate(29,22)"
    />
    <path
      d="M0,0 L87,0 L87,79 L83,91 L76,102 L68,109 L59,112 L57,112 L57,110 L59,110 L61,105 L59,105 L59,103 L53,102 L53,93 L54,91 L46,92 L45,88 L51,86 L54,83 L56,83 L56,80 L58,80 L60,76 L60,63 L58,63 L57,60 L54,57 L51,57 L50,54 L40,52 L42,50 L52,50 L52,47 L81,46 L56,45 L55,43 L65,42 L78,42 L78,39 L81,38 L81,33 L74,32 L78,31 L79,30 L72,31 L67,33 L56,34 L55,38 L53,35 L50,35 L50,32 L47,33 L40,33 L41,29 L83,29 L82,4 L80,3 L51,3 L51,4 L41,6 L39,11 L33,12 L33,17 L30,17 L27,12 L23,13 L21,16 L19,16 L19,7 L16,8 L15,15 L13,14 L14,11 L3,11 L4,24 L18,23 L19,18 L26,24 L27,28 L33,27 L33,29 L35,30 L33,32 L28,31 L28,29 L26,29 L25,27 L22,26 L22,30 L26,32 L22,33 L14,34 L14,37 L12,37 L12,34 L5,35 L5,42 L3,42 L2,45 L1,80 L0,80 Z "
      fill="#FDFCF8"
      transform="translate(32,25)"
    />
    <path
      d="M0,0 L32,0 L35,2 L35,27 L-7,27 L-7,30 L2,30 L3,32 L6,33 L7,35 L8,32 L17,30 L23,29 L24,28 L31,28 L30,30 L33,31 L34,36 L30,37 L30,40 L28,41 L15,41 L8,42 L33,43 L34,45 L-45,45 L-45,43 L-22,42 L-26,41 L-26,39 L-29,39 L-29,41 L-34,40 L-34,37 L-36,37 L-36,41 L-43,41 L-44,39 L-44,33 L-43,32 L-36,32 L-36,35 L-34,35 L-34,32 L-26,30 L-23,30 L-26,28 L-26,24 L-22,25 L-22,27 L-20,27 L-19,29 L-14,28 L-15,25 L-21,26 L-22,23 L-27,18 L-28,17 L-30,22 L-44,22 L-45,20 L-45,9 L-34,9 L-33,13 L-33,6 L-29,5 L-29,14 L-27,14 L-25,10 L-21,9 L-17,15 L-16,15 L-16,9 L-9,9 L-8,4 L-5,2 L0,1 Z "
      fill="#F8F2EB"
      transform="translate(80,27)"
    />
    <path
      d="M0,0 L93,0 L93,80 L90,93 L83,104 L73,114 L63,119 L51,122 L39,122 L27,118 L16,111 L7,100 L2,90 L0,82 Z M3,3 L3,83 L7,94 L14,105 L22,112 L34,118 L39,119 L52,119 L61,117 L71,112 L79,105 L86,94 L90,82 L90,3 Z "
      fill="#B2A465"
      transform="translate(29,22)"
    />
    <path
      d="M0,0 L8,0 L12,2 L13,4 L16,5 L16,7 L19,8 L19,11 L21,11 L22,15 L22,24 L19,28 L17,28 L17,31 L15,31 L14,34 L7,37 L2,38 L-7,33 L-7,31 L-9,30 L-6,30 L-7,29 L-11,30 L-12,25 L-14,25 L-14,18 L-7,20 L-6,20 L-6,18 L-14,17 L-13,10 L-10,5 Z "
      fill="#F1F4F1"
      transform="translate(71,77)"
    />
    <path
      d="M0,0 L40,0 L40,22 L0,22 Z "
      fill="#E4171A"
      transform="translate(73,31)"
    />
    <path
      d="M0,0 L7,0 L6,2 L9,3 L10,8 L6,9 L6,12 L4,13 L-9,13 L-16,14 L9,15 L10,17 L-69,17 L-69,15 L-46,14 L-50,13 L-50,11 L-55,12 L-53,4 L-50,4 L-49,7 L-48,4 L-43,6 L-42,4 L-40,4 L-40,14 L-26,14 L-33,13 L-33,10 L-36,10 L-36,12 L-38,12 L-38,4 L-29,4 L-30,11 L-28,11 L-28,5 L-23,4 L-18,5 L-17,7 L-16,4 L-7,2 L-1,1 Z "
      fill="#E4DEC0"
      transform="translate(104,55)"
    />
    <path
      d="M0,0 L3,0 L3,2 L9,2 L15,5 L15,8 L18,8 L20,6 L22,7 L22,14 L14,16 L13,13 L13,10 L10,12 L10,15 L13,16 L14,23 L13,27 L9,26 L8,19 L3,21 L0,21 L4,21 L6,21 L8,29 L6,30 L3,28 L3,26 L1,25 L4,25 L3,24 L-1,25 L-2,20 L-4,20 L-4,13 L3,15 L4,15 L4,13 L-4,12 L-3,5 Z "
      fill="#FBF9EA"
      transform="translate(61,82)"
    />
    <path
      d="M0,0 L29,0 L31,4 L31,7 L27,7 L27,14 L31,14 L31,16 L26,16 L24,15 L15,16 L12,13 L12,7 L14,5 L21,5 L23,6 L23,1 L8,2 L3,1 L3,3 L8,3 L11,8 L11,13 L8,16 L-1,16 L-1,1 Z "
      fill="#F07270"
      transform="translate(78,32)"
    />
    <path
      d="M0,0 L7,0 L6,12 L10,12 L10,15 L1,15 L0,11 L-4,11 L-4,15 L-9,14 L-10,15 L-18,15 L-19,14 L-19,1 L-10,1 L-8,1 Z "
      fill="#B7C5E3"
      transform="translate(79,116)"
    />
    <path
      d="M0,0 L3,0 L3,9 L5,9 L7,5 L11,4 L15,10 L17,7 L18,5 L21,6 L14,20 L12,20 L13,13 L9,8 L8,12 L10,16 L6,15 L4,12 L2,17 L-12,17 L-13,15 L-13,4 L-2,4 L-1,8 L-1,1 Z "
      fill="#D2DCF8"
      transform="translate(48,32)"
    />
    <path
      d="M0,0 L8,0 L12,2 L13,4 L16,5 L16,7 L19,8 L18,12 L16,16 L12,15 L12,12 L8,14 L4,13 L4,11 L-1,8 L-7,7 L-8,4 Z "
      fill="#DED2A5"
      transform="translate(71,77)"
    />
    <path
      d="M0,0 L32,0 L35,2 L35,27 L-7,27 L-7,26 L33,26 L33,4 L-7,4 L-5,2 L0,1 Z "
      fill="#FED0D0"
      transform="translate(80,27)"
    />
    <path
      d="M0,0 L4,1 L5,3 L8,4 L9,11 L8,15 L4,14 L3,7 L-2,9 L-5,9 L-1,9 L1,9 L3,17 L1,18 L-2,16 L-2,14 L-4,13 L-1,13 L-2,12 L-6,13 L-7,8 L-9,8 L-9,1 L-2,3 L-1,4 Z "
      fill="#8B9AC0"
      transform="translate(66,94)"
    />
    <path
      d="M0,0 L3,0 L3,9 L5,9 L7,5 L11,4 L15,10 L17,7 L18,5 L21,6 L14,20 L12,20 L13,13 L9,8 L8,12 L10,16 L6,15 L4,12 L3,16 L0,16 Z "
      fill="#353C92"
      transform="translate(48,32)"
    />
    <path
      d="M0,0 L7,0 L6,12 L10,12 L10,15 L1,15 L0,11 L-4,11 L-4,15 L-7,14 L-5,14 L-5,9 L-1,9 L-1,7 L-5,7 L-5,4 L0,4 Z "
      fill="#BAC8E0"
      transform="translate(79,116)"
    />
    <path
      d="M0,0 L3,0 L5,5 L3,10 L1,10 L1,13 L-1,13 L-2,16 L-8,17 L-8,14 L-6,14 L-6,9 L-6,7 L-10,8 L-10,4 L-2,3 L-2,1 Z "
      fill="#8A97C2"
      transform="translate(87,95)"
    />
    <path
      d="M0,0 L1,0 L2,8 L5,8 L6,5 L10,4 L11,9 L10,11 L12,13 L4,15 L0,13 L-2,3 L-8,5 L-8,3 L-5,3 L-4,1 Z "
      fill="#B9CBEB"
      transform="translate(69,100)"
    />
    <path
      d="M0,0 L1,0 L2,81 L3,89 L0,86 Z "
      fill="#FBF3D0"
      transform="translate(28,23)"
    />
    <path
      d="M0,0 L7,0 L7,3 L9,3 L9,0 L11,0 L11,8 L9,8 L9,5 L7,5 L7,9 L0,9 L-1,7 L-1,1 Z "
      fill="#D2CDA7"
      transform="translate(37,59)"
    />
    <path
      d="M0,0 L7,0 L9,3 L9,6 L1,6 L2,8 L8,8 L7,11 L1,11 L-2,8 L-2,2 Z "
      fill="#F29991"
      transform="translate(92,37)"
    />
    <path
      d="M0,0 L8,0 L7,5 L5,9 L-2,8 L-1,1 Z "
      fill="#B9CFF1"
      transform="translate(59,86)"
    />
    <path
      d="M0,0 L7,0 L7,2 L1,2 L1,4 L6,4 L8,6 L8,10 L6,11 L-1,11 L-1,8 L4,7 L-1,5 Z "
      fill="#101D6D"
      transform="translate(38,37)"
    />
    <path
      d="M0,0 L9,0 L10,4 L6,5 L6,8 L0,9 Z "
      fill="#D2CA9E"
      transform="translate(104,59)"
    />
    <path
      d="M0,0 L8,0 L8,3 L3,3 L3,6 L7,6 L7,8 L3,8 L3,13 L0,13 Z "
      fill="#263F85"
      transform="translate(71,117)"
    />
    <path
      d="M0,0 L3,0 L7,5 L9,0 L12,1 L5,15 L3,15 L4,8 L0,2 Z "
      fill="#142579"
      transform="translate(57,37)"
    />
    <path
      d="M0,0 L7,0 L7,2 L3,2 L3,5 L7,5 L7,7 L3,7 L3,10 L7,10 L7,12 L0,12 Z "
      fill="#142E7C"
      transform="translate(61,118)"
    />
    <path
      d="M0,0 L3,0 L3,2 L9,2 L7,3 L7,7 L11,7 L10,12 L8,13 L5,12 L4,15 L3,12 L6,4 L-1,3 Z "
      fill="#CFD6E2"
      transform="translate(61,82)"
    />
    <path
      d="M0,0 L2,0 L2,2 L6,2 L6,5 L2,5 L2,12 L6,12 L6,14 L1,14 L-1,12 L-1,1 Z "
      fill="#F9B0B0"
      transform="translate(103,34)"
    />
    <path
      d="M0,0 L3,3 L5,6 L4,10 L3,13 L7,10 L8,13 L2,14 L1,11 L-1,9 L-1,6 L-2,2 Z "
      fill="#D5DFF4"
      transform="translate(57,39)"
    />
    <path
      d="M0,0 L9,0 L9,8 L6,8 L5,4 L1,5 L0,8 Z "
      fill="#BAB076"
      transform="translate(66,59)"
    />
    <path
      d="M0,0 L5,1 L5,8 L0,8 L-1,7 L-1,1 Z "
      fill="#C7BF91"
      transform="translate(77,59)"
    />
    <path
      d="M0,0 L1,0 L2,8 L4,10 L3,13 L-1,9 L-2,3 L-8,5 L-8,3 L-5,3 L-4,1 Z "
      fill="#DCE9F9"
      transform="translate(69,100)"
    />
    <path
      d="M0,0 L2,0 L2,10 L7,10 L7,13 L0,13 Z "
      fill="#0F287D"
      transform="translate(82,118)"
    />
    <path
      d="M0,0 L5,1 L5,6 L0,7 L-1,6 L-1,1 Z "
      fill="#DC1B18"
      transform="translate(81,39)"
    />
    <path
      d="M0,0 L2,0 L2,3 L4,3 L4,0 L6,0 L6,8 L4,8 L4,5 L2,5 L2,8 L0,8 Z "
      fill="#BCAF85"
      transform="translate(97,59)"
    />
    <path
      d="M0,0 L2,0 L2,3 L4,3 L4,0 L6,0 L6,8 L4,8 L4,5 L2,5 L2,8 L0,8 Z "
      fill="#BAB081"
      transform="translate(42,59)"
    />
    <path
      d="M0,0 L3,1 L3,3 L8,2 L10,4 L8,6 L8,11 L6,10 L5,6 L0,6 Z "
      fill="#C4D9F5"
      transform="translate(74,99)"
    />
    <path
      d="M0,0 L1,2 L7,2 L7,3 L2,3 L3,5 L9,5 L8,8 L2,8 L0,7 Z "
      fill="#FDC3C7"
      transform="translate(91,40)"
    />
    <path
      d="M0,0 L4,2 L5,0 L7,0 L7,8 L5,5 L2,5 L0,8 Z "
      fill="#ACA365"
      transform="translate(57,59)"
    />
    <path
      d="M0,0 L3,0 L4,8 L-2,8 Z "
      fill="#BBAD7B"
      transform="translate(51,59)"
    />
    <path
      d="M0,0 L7,2 L7,4 L3,4 L2,7 L0,7 Z "
      fill="#6879A2"
      transform="translate(57,95)"
    />
    <path
      d="M0,0 L3,1 L3,10 L1,11 L0,4 L-2,3 Z "
      fill="#BABDD6"
      transform="translate(90,91)"
    />
    <path
      d="M0,0 L2,1 L2,3 L10,4 L11,7 L9,8 L8,6 L4,5 L2,4 L1,7 Z "
      fill="#D6E0F8"
      transform="translate(55,91)"
    />
    <path
      d="M0,0 L2,0 L4,4 L2,5 L-5,5 L-5,2 L0,1 Z "
      fill="#393F90"
      transform="translate(42,43)"
    />
    <path
      d="M0,0 L5,1 L8,3 L6,4 L4,7 L4,4 L0,4 Z "
      fill="#DDDEDB"
      transform="translate(68,85)"
    />
    <path
      d="M0,0 L5,0 L4,4 L1,3 L1,5 L-2,5 L-3,2 Z "
      fill="#D5D2A7"
      transform="translate(66,79)"
    />
    <path
      d="M0,0 L5,1 L4,5 L1,5 L0,8 Z "
      fill="#B4AC7C"
      transform="translate(109,59)"
    />
    <path
      d="M0,0 L7,6 L11,9 L6,10 L4,6 L0,2 Z "
      fill="#F7F0D3"
      transform="translate(42,129)"
    />
    <path
      d="M0,0 L11,0 L10,3 L10,1 L3,2 L3,7 L1,7 Z "
      fill="#B0BAE1"
      transform="translate(35,36)"
    />
    <path
      d="M0,0 L7,0 L7,2 L3,4 L-1,3 Z "
      fill="#344889"
      transform="translate(77,99)"
    />
    <path
      d="M0,0 L3,3 L1,4 L1,7 L-3,6 L-3,1 Z "
      fill="#D4DFF1"
      transform="translate(64,105)"
    />
    <path
      d="M0,0 L3,1 L1,1 L4,5 L3,8 L-1,7 L2,7 L2,5 L-1,4 Z "
      fill="#B1A878"
      transform="translate(92,59)"
    />
    <path
      d="M0,0 L3,0 L2,4 L-1,5 L-5,3 L-5,1 L0,3 Z "
      fill="#C0C9DA"
      transform="translate(74,110)"
    />
    <path
      d="M0,0 L8,0 L8,2 L1,3 Z "
      fill="#D13537"
      transform="translate(93,43)"
    />
    <path
      d="M0,0 L4,0 L4,4 L-1,4 Z "
      fill="#B29D5C"
      transform="translate(79,92)"
    />
    <path
      d="M0,0 L2,0 L2,8 L0,7 L-2,1 Z "
      fill="#F6EDC1"
      transform="translate(95,59)"
    />
    <path
      d="M0,0 L5,0 L5,3 L-2,3 Z "
      fill="#3C4A90"
      transform="translate(84,128)"
    />
    <path
      d="M0,0 L1,2 L5,4 L6,7 L2,6 L0,3 Z "
      fill="#13206C"
      transform="translate(52,41)"
    />
    <path
      d="M0,0 L2,0 L2,3 L0,3 L0,5 L-3,5 L-3,2 Z "
      fill="#525D92"
      transform="translate(86,105)"
    />
    <path
      d="M0,0 L4,1 L3,4 L-1,3 Z "
      fill="#B0A363"
      transform="translate(76,87)"
    />
    <path
      d="M0,0 L4,0 L3,4 L0,4 Z "
      fill="#2F4087"
      transform="translate(71,105)"
    />
    <path
      d="M0,0 L4,1 L4,4 L0,4 Z "
      fill="#1B3375"
      transform="translate(71,99)"
    />
    <path
      d="M0,0 L4,0 L4,4 L0,3 Z "
      fill="#20307C"
      transform="translate(68,89)"
    />
    <path
      d="M0,0 L3,1 L3,4 L-2,5 L-3,3 Z "
      fill="#EFE3C1"
      transform="translate(80,88)"
    />
    <path
      d="M0,0 L3,0 L3,4 L-1,3 Z "
      fill="#A79A59"
      transform="translate(84,89)"
    />
    <path
      d="M0,0 L3,0 L3,4 L-1,3 Z "
      fill="#24387F"
      transform="translate(64,86)"
    />
    <path
      d="M0,0 L4,0 L3,4 L0,3 Z "
      fill="#ABA15E"
      transform="translate(80,85)"
    />
    <path
      d="M0,0 L4,4 L1,6 L0,5 Z "
      fill="#DFE7F8"
      transform="translate(51,43)"
    />
    <path
      d="M0,0 L4,1 L3,4 L0,3 Z "
      fill="#293E84"
      transform="translate(66,94)"
    />
    <path
      d="M0,0 L1,0 L1,5 L-4,4 L-4,2 L-1,2 Z "
      fill="#B3A167"
      transform="translate(71,80)"
    />
    <path
      d="M0,0 L3,1 L2,4 L-1,3 Z "
      fill="#2A3E83"
      transform="translate(77,104)"
    />
    <path
      d="M0,0 L3,1 L2,4 L-1,3 Z "
      fill="#1E387C"
      transform="translate(62,91)"
    />
    <path
      d="M0,0 L3,1 L2,4 L-1,3 Z "
      fill="#3F4F8F"
      transform="translate(59,86)"
    />
    <path
      d="M0,0 L3,1 L2,4 L-1,3 Z "
      fill="#B4A968"
      transform="translate(84,81)"
    />
    <path
      d="M0,0 L3,1 L3,4 L0,4 Z "
      fill="#FEFAF1"
      transform="translate(38,61)"
    />
    <path
      d="M0,0 L1,0 L1,5 L-2,6 Z "
      fill="#CED6F2"
      transform="translate(68,38)"
    />
    <path
      d="M0,0 L3,0 L3,3 L-1,2 Z "
      fill="#A29F5C"
      transform="translate(87,85)"
    />
    <path
      d="M0,0 L3,0 L4,3 L0,3 Z "
      fill="#F4E9C1"
      transform="translate(80,82)"
    />
    <path
      d="M0,0 L1,3 L-3,4 Z "
      fill="#EFE8C3"
      transform="translate(107,130)"
    />
    <path
      d="M0,0 L3,0 L3,3 L0,3 Z "
      fill="#232D7D"
      transform="translate(74,110)"
    />
    <path
      d="M0,0 L3,0 L3,3 L0,3 Z "
      fill="#263C77"
      transform="translate(57,91)"
    />
    <path
      d="M0,0 L3,0 L3,3 L0,3 Z "
      fill="#A69A58"
      transform="translate(73,84)"
    />
    <path
      d="M0,0 L3,0 L3,3 L0,3 Z "
      fill="#A79C52"
      transform="translate(77,82)"
    />
    <path
      d="M0,0 L3,0 L3,3 L0,3 Z "
      fill="#A9A070"
      transform="translate(80,79)"
    />
    <path
      d="M0,0 L4,0 L4,3 L0,1 Z "
      fill="#CBE0EF"
      transform="translate(43,40)"
    />
    <path
      d="M0,0 L3,0 L2,3 L0,3 Z "
      fill="#324078"
      transform="translate(65,99)"
    />
    <path
      d="M0,0 L3,1 L3,3 L0,3 Z "
      fill="#1D3975"
      transform="translate(61,96)"
    />
    <path d="M0,0 L4,0 L3,3 Z " fill="#F6F1CE" transform="translate(58,59)" />
    <path
      d="M0,0 L2,0 L2,3 L-1,2 Z "
      fill="#1C2D6A"
      transform="translate(79,109)"
    />
    <path
      d="M0,0 L2,0 L2,3 L-1,2 Z "
      fill="#1B276D"
      transform="translate(82,103)"
    />
    <path d="M0,0 L3,1 L1,3 Z " fill="#F5F0D2" transform="translate(53,138)" />
  </svg>
)
export default Championship
