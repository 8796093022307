import * as React from 'react'
const Uruguay = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width={96}
    height={96}
    {...props}
  >
    <path
      d="M0,0 L96,0 L96,96 L0,96 Z "
      fill="#FAFCFC"
      transform="translate(0,0)"
    />
    <path
      d="M0,0 L96,0 L96,41 L0,41 Z "
      fill="#F7FCFE"
      transform="translate(0,55)"
    />
    <path
      d="M0,0 L96,0 L96,14 L0,14 Z "
      fill="#3C78B8"
      transform="translate(0,55)"
    />
    <path
      d="M0,0 L96,0 L96,13 L0,13 Z "
      fill="#3977B9"
      transform="translate(0,83)"
    />
    <path
      d="M0,0 L5,0 L5,4 L54,4 L54,17 L-42,17 L-42,12 L-17,11 L-16,8 L-8,8 L-6,3 Z "
      fill="#F8FDFD"
      transform="translate(42,38)"
    />
    <path
      d="M0,0 L3,2 L6,2 L6,6 L10,4 L8,9 L13,8 L9,13 L14,13 L14,14 L9,14 L10,16 L14,18 L14,23 L10,24 L9,29 L4,30 L0,29 L-1,33 L-26,33 L-26,15 L-19,15 L-15,16 L-14,14 L-10,12 L-12,9 L-7,8 L-9,4 L-5,6 L-6,1 L-4,1 L0,5 Z "
      fill="#FCFCF9"
      transform="translate(26,17)"
    />
    <path
      d="M0,0 L48,0 L48,14 L0,14 Z "
      fill="#3D76B7"
      transform="translate(48,27)"
    />
    <path
      d="M0,0 L48,0 L48,13 L0,13 Z "
      fill="#3A78B8"
      transform="translate(48,0)"
    />
    <path
      d="M0,0 L3,2 L6,2 L6,6 L10,4 L8,9 L13,8 L9,13 L14,13 L14,14 L9,14 L10,16 L14,18 L12,19 L8,18 L10,23 L6,22 L7,26 L3,23 L0,27 L0,22 L-2,22 L-3,26 L-4,26 L-5,21 L-9,23 L-8,19 L-11,18 L-9,16 L-13,13 L-10,12 L-12,9 L-7,8 L-9,4 L-5,6 L-6,1 L-4,1 L0,5 Z "
      fill="#A69444"
      transform="translate(26,17)"
    />
    <path
      d="M0,0 L6,0 L5,4 L8,2 L9,7 L10,4 L12,4 L11,8 L7,9 L7,7 L2,7 L2,5 L-1,4 Z "
      fill="#EDEBDD"
      transform="translate(14,35)"
    />
    <path
      d="M0,0 L5,0 L4,3 L7,2 L8,9 L6,9 L6,7 L2,8 L3,6 L1,5 L0,7 L-1,2 L2,3 L2,1 Z "
      fill="#D2B233"
      transform="translate(24,27)"
    />
    <path
      d="M0,0 L1,0 L2,7 L6,11 L8,17 L5,17 L4,13 L2,13 L2,11 L-2,10 L-2,6 L0,6 Z "
      fill="#9D8322"
      transform="translate(26,17)"
    />
    <path
      d="M0,0 L2,0 L6,5 L4,5 L3,10 L2,8 L-2,6 L-3,3 L1,5 Z "
      fill="#AFA156"
      transform="translate(20,18)"
    />
    <path
      d="M0,0 L5,0 L5,4 L7,4 L6,6 L4,7 L-1,5 L2,3 Z "
      fill="#AEA46A"
      transform="translate(14,26)"
    />
  </svg>
)
export default Uruguay
