import { Dialog } from '@headlessui/react'
import { useState } from 'react'

const SiteDownDialog = () => {
  let [isOpen, setIsOpen] = useState(true)

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="max-w-lg space-y-4 border bg-black/90 p-12 rounded-2xl">
            <Dialog.Title className="font-bold">
              Notice: Login Maintenance Update
            </Dialog.Title>
            <Dialog.Description>
              We are currently performing maintenance on email password logins.
              Our team is working diligently to resolve the issue. Please check
              back later as we work to restore full functionality. Thank you for
              your patience and understanding.
            </Dialog.Description>

            <div className="flex gap-4">
              <button
                onClick={() => setIsOpen(false)}
                className="bg-[#008000] text-white px-4 py-2 rounded-lg"
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  )
}

export default SiteDownDialog
