import * as React from 'react'
const GamesHeader = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2943_11926)">
      <path
        d="M12 0C5.3835 0 0 5.3835 0 12C0 18.6165 5.3835 24 12 24C18.6165 24 24 18.6165 24 12C24 5.3835 18.6165 0 12 0ZM12.762 3.8985L15.8475 2.2395C17.556 2.916 19.047 4.0245 20.181 5.4345L19.446 8.8275L17.0925 9.981L12.762 6.8265V3.8985ZM8.178 2.2305L11.2635 3.8955V6.8265L6.936 9.981L4.5705 8.826L3.8355 5.412C4.9725 4.005 6.4665 2.9025 8.178 2.2305ZM3.345 17.931C2.3655 16.506 1.728 14.8305 1.5525 13.02L4.092 10.2615L6.408 11.394L8.1525 16.3485L6.678 18.1155L3.345 17.931ZM14.6745 22.1415C13.818 22.368 12.9255 22.5 12 22.5C10.881 22.5 9.804 22.32 8.7915 21.9945L7.848 19.0575L9.3525 17.25H14.6535L16.131 18.999L14.6745 22.1415ZM17.301 18.0645L15.8505 16.347L17.619 11.394L19.9245 10.2645L22.449 13.0215C22.2975 14.583 21.795 16.0395 21.0315 17.328L17.301 18.0645Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2943_11926">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default GamesHeader
