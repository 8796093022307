import * as React from 'react'
const China = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={49}
    viewBox="0 0 50 49"
    {...props}
  >
    <path
      d="M 20.500 4 C 20.101 4.646, 20.224 5.624, 20.774 6.174 C 21.441 6.841, 22.016 6.783, 22.500 6 C 22.899 5.354, 22.776 4.376, 22.226 3.826 C 21.559 3.159, 20.984 3.217, 20.500 4 M 8 7.465 C 8 8.270, 7.071 9.427, 5.935 10.035 C 4.449 10.830, 4.157 11.484, 4.894 12.372 C 5.456 13.050, 5.644 14.644, 5.312 15.916 C 4.761 18.021, 4.899 18.124, 6.854 17.078 C 8.470 16.213, 9.530 16.213, 11.146 17.078 C 13.109 18.129, 13.239 18.025, 12.668 15.863 C 12.209 14.126, 12.634 13.042, 14.272 11.772 L 16.500 10.044 13.656 10.022 C 11.665 10.007, 10.656 9.401, 10.290 8 C 9.685 5.689, 8 5.295, 8 7.465 M 24.500 9 C 24.160 9.550, 24.148 10.430, 24.473 10.956 C 25.296 12.287, 28 11.089, 28 9.393 C 28 7.794, 25.424 7.505, 24.500 9 M 25.294 15.818 C 24.527 16.292, 24.426 17.072, 25.011 18.018 C 25.732 19.185, 26.170 19.230, 27.158 18.242 C 28.650 16.750, 27.083 14.713, 25.294 15.818 M 20.452 20.816 C 19.929 21.192, 19.401 22.021, 19.281 22.657 C 19.008 24.099, 22.259 23.722, 22.749 22.254 C 23.208 20.877, 21.690 19.927, 20.452 20.816"
      stroke="none"
      fill="#fce006"
      fillRule="evenodd"
    />
    <path
      d="M 14.263 2.458 C 9.536 4.659, 9.109 5.120, 9.876 7.198 C 10.406 8.635, 11.810 9.703, 13.613 10.041 C 16.498 10.583, 16.499 10.584, 14.241 11.831 C 12.467 12.812, 12.120 13.632, 12.626 15.648 C 13.222 18.020, 13.108 18.128, 11.135 17.072 C 9.534 16.215, 8.466 16.215, 6.865 17.072 C 4.894 18.127, 4.779 18.018, 5.368 15.669 C 6.084 12.819, 4.277 10.938, 2.921 13.122 C 2.450 13.880, 1.586 16.724, 1.002 19.443 C -2.272 34.661, 9.474 49, 25.211 49 C 28.430 49, 32.575 48.369, 34.422 47.597 C 42.220 44.339, 48.692 35.606, 50.495 25.908 C 51.913 18.283, 43.229 5.083, 34.355 1.375 C 29.089 -0.825, 20.296 -0.351, 14.263 2.458 M 20.500 4 C 20.101 4.646, 20.224 5.624, 20.774 6.174 C 21.441 6.841, 22.016 6.783, 22.500 6 C 22.899 5.354, 22.776 4.376, 22.226 3.826 C 21.559 3.159, 20.984 3.217, 20.500 4 M 6.020 8.476 C 5.231 9.426, 5.155 10, 5.819 10 C 7.027 10, 8.436 8.103, 7.743 7.409 C 7.491 7.158, 6.716 7.638, 6.020 8.476 M 24.500 9 C 24.160 9.550, 24.148 10.430, 24.473 10.956 C 25.296 12.287, 28 11.089, 28 9.393 C 28 7.794, 25.424 7.505, 24.500 9 M 25.294 15.818 C 24.527 16.292, 24.426 17.072, 25.011 18.018 C 25.732 19.185, 26.170 19.230, 27.158 18.242 C 28.650 16.750, 27.083 14.713, 25.294 15.818 M 20.452 20.816 C 19.929 21.192, 19.401 22.021, 19.281 22.657 C 19.008 24.099, 22.259 23.722, 22.749 22.254 C 23.208 20.877, 21.690 19.927, 20.452 20.816 M 0.195 24.500 C 0.215 26.150, 0.439 26.704, 0.693 25.731 C 0.947 24.758, 0.930 23.408, 0.656 22.731 C 0.382 22.054, 0.175 22.850, 0.195 24.500"
      stroke="none"
      fill="#fc1414"
      fillRule="evenodd"
    />
  </svg>
)
export default China
