import * as React from 'react'
const CardBox = (props) => (
  <svg
    viewBox="0 0 1298 1600"
    width={1280}
    height={1280}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="translate(648)"
      d="m0 0h24l14 8 40 20 11 5 23 12 224 112 17 9 14 7 19 10 17 8 14 8 19 9 19 10 184 92 9 6 1 3-16 32-5 11h-3l-9 19-9 17-5 10-3 1-2 7-3 3-2 7-3 3-3 9-5 11-2 1-5 12-3 8-5 8-6 13h-3l-1 7-4 5-2 4-1 8-6 9-4 9-4 10-5 10h-4l-1 7-11 22-3 3-6 15-4 5-3 6-3 9-5 7-10 23-9 17-10 20-3 5-2 3-2 6-5 6-4 9-2 3-1 7-4 5-5 10-3 8-3 5-4 8-3 7-6 11-6 12-7 15-3 5-7 14-3 7-5 9-5 11-3 5-5 11-6 11-4 8-3 7-3 5-4 7-3 8-3 5-4 5-2 9-4 3-3 9-2 3-2 6-3 2-5 13-2 6-4 1-1 7-4 8-1 5-5 1 2 10-8 15-2 3h-2v7l-5 10-5 6-6 15-9 17-5 10-3 3-1 5-4 8-5 5-9 19-8 14-7 13-3 7-10 18-4 7-3 5-4 11-2 5-2 7-5 10-6 10-15 30-5 8-7 16-6 10-44 88-6 9-3 5-4 9-63 126-3 7-6 9-4 8-3 9-12 25-2 3h-37l-19-10-128-64-17-9-16-8-12-7-6-2-12-6-11-6-19-10-19-9-19-10-208-104-10-6-7-3-19-10-54-27-24-13-4-5-2-5v-16l11-23 8-16 12-26 16-32 9-17 10-21 12-22 24-48 9-19 6-11 9-17 11-23 16-32 12-23 49-98 5-8 5-12 29-58 6-10 7-16 6-10 56-112 9-17 8-16 12-23 11-23 10-19 30-60 9-17 11-23 16-32 9-17 10-21 10-19 14-28 6-11 16-32 9-17 9-19 6-11 83-166 6-10 8-16z"
      fill="#2D3739"
    />
    <path
      transform="translate(280,743)"
      d="m0 0 1 2-19 37h-2l-2 6-2 3-3 9-18 36-10 19-60 120-2 6 5-3 6-11 6-8 7-16 4-5 7-14 7-8h2l1-7 4-1 2 2 13-2v2l3-1 10 2 3 1 3 6 8 4 1 4 3 1-1 5 1 5 4 1-2 5-4 1 4 1v2l6 1 7 1v2l4 2-1 3 3 2 7 1 7 10 1 6-5 8-4 4-7 9h-2l-2 4-5 9-1 2h-2v5l7-2v-4l5-5 4-5h2l2-4 9-9 7-8 5-3 7-7 1-2 5-3 3-4 4-2 1-2 4-2 1-2 7-4 1-2 7-4 1-2 8-4 17-11 14-8 6-2v-2l19-9 21-8 18-5 20-4 18-2h36l26 4 21 6 19 8 17 9 12 8 14 11 13 12 9 10 3 6 11 15 3 5 4 4 13 25 8 20 7 23 6 25 4 26 1 21-1 37h-2v11l-4 27-7 35-3 11h-2v7l-3 6-1 5h-2l1 5 2 1 3-9 2-3v-11l5-6 1-9 6-12 2-12 7-5 5-2 1-1 9-1 6-2v-2l6-1 8-4 9-3 8 1 5 5v2h6l4 4h3v7l5 1h5l1 4h3v3h3l3 5 1 2 9 2 4 1 3 4v6l1 1 7 1-8 16-10 19-13 26-10 19-38 76-6 9-3 5-4 9-63 126-3 7-6 9-4 8-3 9-12 25-2 3h-37l-19-10-128-64-17-9-16-8-12-7-6-2-12-6-11-6-19-10-19-9-19-10-208-104-10-6-7-3-19-10-54-27-24-13-4-5-2-5v-16l11-23 8-16 12-26 16-32 9-17 10-21 12-22 24-48 9-19 6-11 9-17 11-23 16-32 12-23 49-98 5-8 5-12 29-58 6-10 4-6 3-2v3l3-1z"
      fill="#651016"
    />
    <path
      transform="translate(543,297)"
      d="m0 0h12l10 4 60 30 19 10 104 52 19 10 92 46 45 23 66 33 19 10 106 53 12 7 5 5 4 5 2 6v15l-9 20-67 134-10 19-72 144-4 10-6-1-27-14-64-32-19-10-112-56-19-10-54-27-19-10-102-51-19-10-108-54-15-8-30-15-1-3 8-16 14-29 10-19 8-15 11-23 10-19 8-17 8-16 12-23 8-16 12-22 8-16 9-19 8-16 20-39 8-15 8-10 8-4z"
      fill="#BDBFBE"
    />
    <path
      transform="translate(632,94)"
      d="m0 0 5 1 20 10 19 10 154 77 19 10 86 43 19 10 74 37 19 10 34 17 19 10 23 11 23 12 58 29 19 10 4 3-9 17-11 23-12 24-10 19-8 15-10 22-10 19-37 74h-1v-16h-2l-4-9-5-5-9-6-112-56-19-10-92-46-19-10-108-54-19-10-88-44-19-10-64-32-4-1h-12l-10 4-9 6 2-6 14-26 23-47 12-22 54-108z"
      fill="#2D3739"
    />
    <path
      transform="translate(304,695)"
      d="m0 0 19 9 40 20 19 10 100 50 41 21 46 23 29 15 102 51 19 10 96 48 19 10 108 54v2h3l1-2-1 6-3 4h-2v7l-5 10-5 6-6 15-9 17-5 10-3 3-1 5-4 8-5 5-9 19-8 14-7 13-3 7-10 18-4 7-3 5-4 11-2 5-2 7-5 10-4 6h-8l-1-6-3-6h-3l-10-2-2-5-1-3h-3v-3h-3l-3-3h-7l-2-4v-4l-3-1-3-3-6-1v-2l-3-1-3-3-8-1-8 3-11 5-3-1v2l-6 3-9 1-2 2-7 3-3 2-2 12-6 13-2 10-4 2v13l-6 12-3-1v-5h2l1-8 2-3 1-7h2l1-8 6-28 4-21 3-27h2v-44l-2-20-4-25-8-31-8-23-13-27-5-8v-2l-3-1-8-12-8-11-3-5-14-15-11-9-15-11-18-10-15-7-18-6-17-4-20-3h-36l-24 3-26 6-20 7-24 11h-2v2l-25 14-17 11h-3l-1 3-4 3h-3l-2 4-3 2h-3l-2 4h-3l-2 4h-3l-2 4-3 3h-3l-2 4-8 8-5 3-12 13-4 5-5 5-4 5h-2v4l-5 3-3-1 1-5h2l2-6 6-9h2l2-4 4-5 3-4h2l2-5 2-4-1-5-6-9-7-1-4-3v-4l-2-1v-2l-5 1-12-5v-3l4-1 1-3-3-1-2-5 1-5-4-1 1-4-4-1-5-3-3-6-9-1-7-2-12 2-3-2h-3l-1 7-5 5-4 5-8 15-3 5-8 16-6 8-4 8-7 4 1-5 8-17 10-19 52-104 10-19 11-22 2-7 4-7h2l2-6 16-31 3-9 17-33z"
      fill="#040809"
    />
    <path
      transform="translate(280,743)"
      d="m0 0 1 2-19 37h-2l-2 6-2 3-3 9-18 36-10 19-60 120-2 6 5-3 6-11 6-8 7-16 4-5 7-14 7-8h2l1-7 4-1 2 2 13-2v2l3-1 10 2 3 1 3 6 8 4 1 4 3 1-1 5 1 5 4 1-2 5-4 1 4 1v2l6 1 7 1v2l4 2-1 3 3 2 7 1 7 10 1 6-5 8-4 4-7 9h-2l-2 4-5 9-1 2h-2v5l7-2v-4l5-5 4-5h2l2-4 9-9 7-8 5-3 7-7 1-2 5-3 3-4 4-2 1-2 4-2 1-2 7-4 1-2 7-4 1-2 8-4 17-11 14-8 6-2v-2l19-9 21-8 18-5 20-4 18-2h36l26 4 21 6 19 8 17 9 12 8 14 11 13 12 9 10 3 6 11 15 3 5 4 4 13 25 8 20 7 23 6 25 4 26 1 21-1 37h-2v11l-4 27-5 24h-2v4h-2v-14l4-17 2-11 1-12v-36l-2-12-19-10-64-32-33-17-68-34-17-9-9-3-3-3-21-10-19-10-40-20-4-1v-2l-6-2-16-8-31-16-5 2-14 9-10 7-7 5-1 3-11 8-2 4-5 3-7 7-1 2-6 3-7 7-2 4 5 5 2 3v3l-13 1-3-1-4 3v2l4 2 7 11-1 1h-18l1-8 5-6-5-1-8 4-1 9-7 10-3 7-3 5h-2l-2 5-3 5-3 9-4 2-3 10-4 10-4 5-2 7-3 2-27-13-14-8-22-11-21-10-2-2h-3l-1 2 6 2 27 13 19 10 17 9 16 8 7 4-3 10-2 4-2 7-3 1v15l2 1-6 2-5-1v2l-2 1-3 12-2 3 3 1-5 2-2 2v8l3 7-3 1-7 1-4 5v5l4 6-2 2-10 2-3 9-1 8-5 3-2 6v5l6 4-4 2-7 2-6 12 7 2 3 4-2 3 1 2-7-1-4-1-2 3 1 4-5-2-4-2v-4l-4-2-5-3h-2l-1-4 3-1 16 8 1-6-5-1-39-20-46-23-12-3 7-16 8-15 11-23 16-32 15-28 10-21 6-11 16-32 9-17 11-23 16-32 9-17 10-21 10-19 37-74 5-8 5-12 29-58 6-10 4-6 3-2v3l3-1z"
      fill="#2D3739"
    />
    <path
      transform="translate(632,94)"
      d="m0 0 5 1 20 10 19 10 154 77 19 10 86 43 19 10 74 37 19 10 34 17 19 10 23 11 23 12 58 29 19 10 4 3-9 17-11 23-12 24-10 19-8 15-10 22-10 19-37 74h-1v-16h-2l-4-9-5-5-9-6-112-56-19-10-92-46-19-10-108-54-19-10-88-44-19-10-64-32-4-1h-12l-10 4-9 6 2-6 14-26 23-47 12-22 54-108zm-23 88-5 11-6 11-9 15-7 14-5 8-8 19-2 9v4h-12l-8 4-9 10 3 1 4-4 3-1 2 6 8 4 8 2 3 3 13 6 11 5 7 5 9 2 12 6 9 3 9 5h2l1 3 5 3 10 8 16 7 5 3 6 1 1 2 10 6 10 2 6 2 1 2h2l1 3 14 5 4 4 6-1 7 1v2l5 2v5l4 1 1-2h3l1 3 7 3h3v2l9 6 11 3 4 2v2l6 1v2l10 4 5 1v2l5 1 1 3 8 4 6-1 5-1 13 8 7 6 4 6 9 4h3v2l9 4 1 2h4v2l24 12 11 6 10 6 16 7 12 5 8 4 8 5 7 4 17 9 12 7 9 4 13 5 5 3h2v2l13 5 8 5 12 5 8 2 6 3v4h3l1-2 15 10 5 3 11 1v5l3-2v-14l-3-5v-2l6-1 7-17 6-10 7-16 3-4v-2h2l3-5 3-2 6-16 3-7 1-8-8-8-14-8-29-15-41-21-23-11-19-10-76-38-19-10-60-30-19-10-82-41-41-21-50-25-19-10-52-26z"
      fill="#040809"
    />
    <path
      transform="translate(425,1110)"
      d="m0 0h29l12 2 14 4 16 8 10 6 6 4v2l4 2 13 13 10 14 8 16 5 15 3 15v26l-3 18-7 19-5 9-6 9-3 5h-2l-2 4-14 14-13 9-10 6-18 7-16 3-7 1h-19l-15-2-19-6-16-8-14-10-12-11-9-11-9-15-7-16-4-16-2-12v-19l3-17 4-13 5-11 5-10 9-12 16-16 11-8 14-8 15-6z"
      fill="#651016"
    />
    <path
      transform="translate(764,1185)"
      d="m0 0 9 1 5 5v2h6l4 4h3v7l5 1h5l1 4h3v3h3l3 5 1 2 9 2 4 1 3 4v6l1 1 7 1-8 16-10 19-13 26-10 19-38 76-6 9-3 5-4 9-63 126-3 7-5-2-36-18-42-22-17-8 1-7 5-12 8-14 6-11 5-8 4-8 2-7 2-2 2-8 8-11 8-17 3-3 6-16 3-3 2-8h2l2-6 5-8 6-13 15-30 5-9 7-21h-6v-1h7l1-6 4-2 1-3 1 5 2 1 3-9 2-3v-11l5-6 1-9 6-12 2-12 7-5 5-2 1-1 9-1 6-2v-2l6-1 8-4z"
      fill="#2D3739"
    />
    <path
      transform="translate(31,1223)"
      d="m0 0 12 2 58 29 29 15 3 1-1 6-6-2-10-5-2 1v3l3-1 8 6v3l-5-1-7-6-3-7v-3l-6-2-17-9h-2l-1 4v14l4 9 5 5 9 6 16 8 15 8 8 3 18 10 9 4 11 6 7 3 27 14 64 32 11 6 7 3 8 4 19 10 32 16 5 3 5 2 19 10 14 7v2l33 17 32 16 19 10 21 10 14 7 19 10 44 22 19 9 3 1h9l10-4 4-2 2-4 5-5 7 1 22 11 15 8-7 14-3 9-12 25-2 3h-37l-19-10-128-64-17-9-16-8-12-7-6-2-12-6-11-6-19-10-19-9-19-10-208-104-10-6-7-3-19-10-54-27-24-13-4-5-2-5v-16l11-23 8-16z"
      fill="#2D3739"
    />
    <path
      transform="translate(492,904)"
      d="m0 0h10l4 1h13l24 4 17 5 21 8 19 10 17 12 10 8 15 14 12 14 10 14 11 18 8 16 10 25 7 25 5 25 1 8v9l-5-2-33-16-60-30-19-10-17-8-19-10-96-48-19-10-16-8-39-20-12-7-6-3 1-2-2-5 25-13h4 2l1-2 13-3 6-3 7-2 19-5 7-1 9-3 27-4z"
      fill="#BDBFBE"
    />
    <path
      transform="translate(543,297)"
      d="m0 0h12v1l-6 1-6 7-6 9-5 8h-2l-1 5-4 8-5 12-6 10h-2v3h-2l-1 4-4 12-7 15-6 12h-2l-2 5-9 16-8 19-4 9-8 16-3 7-5 8-7 13-4 10-2 1-4 10-4 7-3 8-10 19-3 8-5 7-6 12-4 6-1 4-2 1-5 13-8 16-5 12h-2l1 5 16 7v2l6 2 12 7 24 11 32 16 11 6 17 9 5 3 2 2 5 1 14 7 10 4 16 8 13 7 17 9v3l6 2 11 3 16 8 9 5 5 4 6 2 8 4 2 4 7 1 11 6 3 1v2l7 2 16 8 17 8 19 10 21 10 9 5 7 1 12 5 11 7 5 2v2l6 1 9 3 6 3v2l4 1 16 7 16 9 56 28 15 8 14 7 11 7 4 1 4-8 5-12 2-6 4-4 10-21 7-12 4-10h2l1-4 5-11h2l1-4 7-16 4-9 6-10h2l2-5 5-12 1-7 6-11 7-10 8-17 5-9 7-13 4-9h2l1-5 4-11 4-8 3-7 5-10 6-10 7-14 1-3h2l2-6h2l1-4 6-14 6-11 3-5 1-7 1-2h4l4 9v15l-9 20-67 134-10 19-72 144-4 10-6-1-27-14-64-32-19-10-112-56-19-10-54-27-19-10-102-51-19-10-108-54-15-8-30-15-1-3 8-16 14-29 10-19 8-15 11-23 10-19 8-17 8-16 12-23 8-16 12-22 8-16 9-19 8-16 20-39 8-15 8-10 8-4z"
      fill="#ACAEAE"
    />
    <path
      transform="translate(228,1084)"
      d="m0 0h10l8 11v3l-13 1-1 1 16 1 6 9-1 4-1 1-7 1-6 2 2 2v-2l32-2 12-2 17-1 1-1 36-2 1-1h18l7 11v2h3l2 4h-19l-7 2-29 2-6 2-16 1 2 4 4 7-1 4-6 1h-13l-1-2-1 2-4 1h-13l-2-1v3l-3 1h-15v-2h-2l-1 2h-14v2l-4 1h21l6-2 25-2h7l2 1 1-2 3-1 16-1 3-1 17-1 1-2h11v1l7 2 2 5-12 1 1 1h5l1 4v2l5 1-2 6-5 10-5 12-3 12-2-2h-15l-2 2-17 1-1 1h-20v2h-19l-2 2-16 1h-3l-2 1h-16l-2-1v2l-3 1-23 2h-13l-4-5v-5l5-6 7-1 2-1-3-6v-8l5-5 2-5 2-10 3-1v-2h6l3-2-1-7v-8l4-2 2-7 3-9 1-2 11-1v-2h-7l-1-4 6-9 7-14 1-4z"
      fill="#651016"
    />
    <path
      transform="translate(492,904)"
      d="m0 0h10l4 1h13l24 4 17 5 21 8 19 10 17 12 10 8 15 14 12 14 10 14 11 18 8 16 10 25 7 25 5 25 1 8v9l-5-2-4-4v-3h-2l-4-12-4-21-4-14-6-22-1-4h-2l-3-6-5-6v-2h-2l-10-13-3-5-4-5-5-6-5-5-4-3-1-3-2-1v-2l-5-2-5-4-4-2-1-3-10-7-22-11-8-1-6-1-11-5-5-1v2l-10-2-1 2-7-2-14 5-19 10-44 22-2-1 10-6 21-10 15-8 20-10 1-2-18 8-17 9-8 4h-3v2l-24 11-2-1 20-10-9-2 3 3-6 1 2-1v-2l-4 1 1 4-5 3h-3v-2l-6 1-8 4-11-6-2 1 4 3 2 2-5-1-4-2h-6l-31-16-12-7-6-3 1-2-2-5 25-13h4 2l1-2 13-3 6-3 7-2 19-5 7-1 9-3 27-4z"
      fill="#ACAEAE"
    />
    <path
      transform="translate(85,1252)"
      d="m0 0 5 2 20 10 4 10 8 6 116 58 40 21 23 11 54 27 19 10 94 47 19 10 58 29 19 10 16 8 10 4h11l4-1 23 11-1 5-9 8-8 3h-13l-12-5-27-14-64-32-19-10-110-55-2-2-5-2-54-27-7-4-5-3-10-4-27-14-64-32-8-5-10-4-6-4-10-4-16-9-9-4-17-9-17-8-8-6-5-7-2-6v-14z"
      fill="#F8F8F8"
    />
    <path
      transform="translate(552,274)"
      d="m0 0 3 1-2 1h9l13 4 31 16 16 8 33 17 116 58 15 8 108 54 19 10 68 34 19 10 17 8 19 10 36 18 19 10 28 14 6 7 2 5 2-3v14l-3 2v-5l-11-1-5-3-15-10-1 2h-3v-4l-8-4-8-2-14-6-6-4-11-4v-2l-5-2-11-5-12-4-9-6-14-7-10-6-14-8-8-4-10-4-16-7-15-9-30-15v-2h-4l-3-3-7-3v-2l-5-1-8-4-5-7-8-6-10-6-5 1-6 1-8-4-1-3-5-1v-2l-5-1-10-4v-2l-6-1v-2l-4-2-13-4-7-5v-2l-7-1-4-3v-2h-3l-2 2-3-1v-5l-5-2v-2l-8-1-5 1-4-4-14-5-1-3h-2l-2-3-7-2-10-2-6-4-3-1v-2l-8-2-9-5-10-4-10-8-6-4v-2l-5-2-11-5-7-3-9-4-9-2-8-6-15-6-9-5-4-3-8-2-6-3-2-6-5 3-2 2-3-1 9-10z"
      fill="#2C3638"
    />
    <path
      transform="translate(490,885)"
      d="m0 0h36l26 4 21 6 19 8 17 9 12 8 14 11 13 12 9 10 3 6 11 15 3 5 4 4 13 25 8 20 7 23 6 25 4 26 1 21-1 37h-2v11l-4 27-5 24h-2v4h-2v-14l4-17 2-11 1-12v-36l-2-12-19-10-64-32-33-17-68-34-17-9-9-3-3-3-21-10-19-10-40-20-4-1v-2l-6-2-16-8-31-16-5 2-14 9-10 7-7 5-1 3-11 8-2 4-5 3-7 7-1 2-4 1v-2h2l2-4 8-7 6-7 7-6 9-9 4-2 2-3 3-1 2-3 3-1 4-7 7-4 1-2 8-4 17-11 14-8 6-2v-2l19-9 21-8 18-5 20-4zm2 19-20 3-15 2-13 4-11 2-16 4-8 4-13 3-1 2h-4l-6 2-21 11 2 5-1 2 28 15 35 18 16 8 39 20 74 37 17 9 23 11 35 18 72 36h2v-9l-4-25-8-30-9-24-8-16-9-16-11-16-11-13-9-10-13-12-18-13-15-9-17-8-26-9-21-4-8-1h-14l-6-1z"
      fill="#070B0C"
    />
    <path
      transform="translate(330,1019)"
      d="m0 0h17l3 5v2h2l3 4 1 2 20-2 4 2-2 1-10 1-1 1h-7l6 9 1 4 7 1h12l1-2 22-1-1 2-29 3-2 1-10 1h-10l-6 2 7 13 18-1 18-2 4-1 2 2-5 2 5 5 4 6-1 3 4-1 18-1h12v1l-11 2-26 2-9 1-5 1 9 12v2l-15 1-4-3-1 4-16 1-4-5-5-9h8v-2h-10v-2h-2v2h-10v2h-8l-1 1-10 1h-10l-1 1-9 1h11 2l18-1 8 13v1l-6 1h-10l-3-1v3l-4 1h-14l-1 1-26 3h-13l-5-10-2-2v-3l7-1h9l2 1v-3h10l1-1h-9l-5-2v2l-18 1-8-12v-3l-10-2v2l-4-1 5-7h2l1-6 3-4h11v-2h-9l2-4 3-5h2l2-6h2l1-3 6-1-3-3 2-5 1 2-1 4 18-1-7-11-4-4 1-2 4-1 2 2h14l5 5 5 7 1 2v-2l-6-10v-2l2-1h15l4 6v2l3 1 2 5 1-2-2-3-5-8-1-2 3-1h14l4 5 3 3v-3l-3-4z"
      fill="#4F0C0F"
    />
    <path
      transform="translate(305,681)"
      d="m0 0 6 2 46 23 19 10 112 56 19 10 120 60 19 10 108 54 19 10 82 41 19 10 56 28 20 11-6 11-6-2-122-61-19-10-96-48-19-10-102-51-19-10-64-32-19-10-114-57-19-10-40-20-3 4-10 21-10 19-5 10-3-1-1-2-3 2 1-4 3-3 14-25 6-11 3-9z"
      fill="#787D7F"
    />
    <path
      transform="translate(425,1110)"
      d="m0 0h29l12 2 14 4 16 8 10 6 6 4v2l4 2 13 13 10 14 8 16 5 15 3 15v26l-3 18-7 19-5 9-6 9-3 5h-2l-2 4-14 14-13 9-10 6-18 7-16 3-7 1h-19l-15-2-19-6-16-8-14-10-12-11-9-11-9-15-7-16-4-16-2-12v-19l3-17 4-13 5-11 5-10 9-12 16-16 11-8 14-8 15-6zm3 12-17 3-17 7-16 9-20 20-10 15-7 17-4 16v31l4 17 5 13 8 14 12 14 11 10 13 8 11 5 17 5 6 2h24l17-3 15-5 12-6 12-8 12-11 8-10 7-12 5-11 4-13 3-23-2-20-4-15-7-16-7-11-7-9h-2l-2-4-11-9-11-7-15-7-13-4-10-2z"
      fill="#EFEFEF"
    />
    <path
      transform="translate(354,946)"
      d="m0 0 6 2 33 17 16 8v2l6 1 42 21 15 8 23 11 1 3 6 1 19 9 19 10 74 37 14 8 23 11 16 8 15 8 23 11 2 2 1 5v15l-8-2-62-31-19-10-64-32-19-10-52-26-19-10-126-63-1-4z"
      fill="#F7F7F7"
    />
    <path
      transform="translate(577,1365)"
      d="m0 0 4 4 10 2h6l1-3 2 4 7 1 5-1h8l5 5 2 5h-3v4l4 1-13 1h-5l2 2 15-1 5-1-8 16-9 14-2 3-2 7-2 3-3 10-6 10-5 8-9 17-4 8-2 5-9-3-10-5-3-3-9-6-9-4-5-5v-2l-4-2-3-6v-2l-3-1-1-4-1-10 4-3 4 1 3-1v-6l-2-5 4-4 6-1v-6l5-5 7-1v-5l-5-2 2-5 6-1h10l-1-4-4-2-1-5h-6l-8-2 1-4 30-3v-2h-2l-1-4z"
      fill="#5D1922"
    />
    <path
      transform="translate(543,297)"
      d="m0 0h12v1l-6 1-6 7-6 9-5 8h-2l-1 5-4 8-5 12-6 10h-2v3h-2l-1 4-4 12-7 15-6 12h-2l-2 5-9 16-8 19-4 9-8 16-3 7-5 8-7 13-4 10-2 1-4 10-4 7-3 8-10 19-3 8-5 7-6 12-4 6-1 4-2 1-5 13-8 16-5 12h-2l1 5 16 7v2l6 2 12 7 24 11 32 16 11 6 17 9 5 3 2 2 5 1 14 7 10 4 16 8 13 7 17 9v3l6 2 11 3 16 8 9 5 5 4 6 2 8 4 2 4 7 1 11 6 3 1v2l7 2 16 8 17 8 19 10 21 10 19 10 17 9 3 3 6 2 4 3 5 3-1 2-6-2-60-30-19-10-54-27-19-10-102-51-19-10-108-54-15-8-30-15-1-3 8-16 14-29 10-19 8-15 11-23 10-19 8-17 8-16 12-23 8-16 12-22 8-16 9-19 8-16 20-39 8-15 8-10 8-4z"
      fill="#999B9C"
    />
    <path
      transform="translate(228,1084)"
      d="m0 0h10l8 11v3l-13 1-1 1 16 1 6 9-1 4-1 1-7 1-6 2 2 2v-2l32-2 12-2 17-1 1-1 36-2 1-1h18l7 11v2h3l2 4h-19l-7 2-29 2-6 2-16 1 2 4 4 7-1 4-6 1h-13l-1-2-1 2-4 1h-13l-2-1v3l-3 1h-15v-2h-2l-1 2h-14v2l-4 1h21l1-1h18l3 6 5 6-4 1-2 1h-11l-2-1v3l-17 2h-2l-1 1-18 1-1-4h-2l1 4-1 1h-16l-4-7v-6l2-4h6l3-2-1-7v-8l4-2 2-7 3-9 1-2 11-1v-2h-7l-1-4 6-9 7-14 1-4z"
      fill="#651016"
    />
    <path
      transform="translate(548,298)"
      d="m0 0h11l50 25v3l-4 2-12-5-10-4-8-4-6-1v-2h-2l-2-5h-3l-2 6-10 3-4 5-4 6-1 7-3 6-1 9-8 14-6 9h-2l-1 5-9 14-3 6h-2l-2 6-4 5-2 5h-2l-2 5-1 2h-2l-2 9-7 15-2 2-2 1-1 7-2 1-3 9-4 13-7 11-2 5-4 4-2 6-2 7-4 13-3 4-1 9-4 4-5 4-10 19-2 5-1 4 7 4-3 1-4-2h-3l-1 3 7 2-4 1-2 11 5 2 2 2-5-1-4-2-7 1-2 6-3 8h-2l-2 5-4 5-7 14-2 8 5 1 20 9 15 6 16 8 25 13 21 12 29 14 21 11 32 16 41 21 15 8 19 9 19 10 40 20 23 11 8 4v2h3v2l5 1v2l5 2 4 4-5-1-16-8-17-9-44-22h-3v-2l-7-2v-2l-3 1-3-3-10-5-5-1v-2l-3-1-5-3-3-2-7-1v-3l-4-2-19-9-5-3-11-3-6-3v-3l-6-2-13-7-19-10-14-7-10-4-10-5-5-1-2-3-19-9-8-5-17-8-19-10-24-11-12-7-6-3v-2l-5-1-12-6-1-2 1-3h2l1-5 8-16 5-11 4-10h2l1-5 7-13 7-11 4-10 9-17 6-14 4-7 2-5h2l2-9 6-10 5-9 4-7 9-19 5-12 7-16 12-21h2l2-5 9-21 4-10 3-7h2v-3l4-4 7-15 4-9 3-7h2l2-5 6-8 4-7z"
      fill="#ACAEAE"
    />
    <path
      transform="translate(437,1159)"
      d="m0 0h18l11 3 5 3 7 3 10 9v2h2l4 8 3 9v20l-5 12-7 9-5 6-14 8-22 4-8 4-8 7h-2l-1 3-6 5-5-1-2-1v-7l3-8 7-8 9-8h2l2-4 3-5 10-6 8-1h-7l-9 3-4 2-2-7-8-4v-3l3-2 10-1 4-1 3-9 13-1 3 8 7 11-3-10-2-4v-11l4-12-4-2-6-6h-5l-5 3-4 2 1 4 3 2 1-5h2v-2h7l3 3v10l-4 6-3 2h-7l-14-4-7-5v-10l4-9v-4l-3 6-2 7h-2l1 10 2 3 9 4 8 2 1 5-4 5-2 1h-10l-10-4v-2l-4-1-10-5-2-3 1-6 8-11 7-9 8-7z"
      fill="#F9F9F9"
    />
    <path
      transform="translate(655)"
      d="m0 0h17l14 8 40 20 11 5 23 12 224 112 17 9 14 7 19 10 17 8 14 8 19 9 19 10 184 92 9 6 1 3-3 5-1-2-6-2-23-11-19-10-26-13-19-10-84-42-19-10-16-8-41-20-27-14-112-56-19-10-34-17-19-10-122-61-39-20-13-7z"
      fill="#727879"
    />
    <path
      transform="translate(636,75)"
      d="m0 0 5 1 35 17 19 10 46 23 19 10 100 50 19 10 84 42 19 10 54 27 19 10 160 80 19 10 4 2v5l-4 1-52-26-19-10-66-33-19-10-158-79-19-10-74-37-19-10-118-59-19-10-36-18z"
      fill="#777C7E"
    />
    <path
      transform="translate(492,904)"
      d="m0 0h10l4 1h13l24 4 17 5 21 8 15 8-2 2-12-3-9-2-6-2-15-2-9-3h-6v-2l-6 1h-10l-4 1h-8l-10 1-8 2h-2v2l-6 3-3-1-1 1-4 1-5-1v2l-6 1v2l-6 1-4-1-2 2-7 1-1 2h-4l-2 3h-5v3h-5l2 2-8-1 2 4-5-1-1 3h-5v2h-5l-1 3h-5v2l5 2-2 1-6-1 7 5-2 1-9-2-21-11-9-5 1-2-2-5 25-13h4 2l1-2 13-3 6-3 7-2 19-5 7-1 9-3 27-4z"
      fill="#787D7F"
    />
    <path
      transform="translate(425,1110)"
      d="m0 0h29l12 2 14 4 16 8 10 6 6 4v2l4 2 13 13 10 14 8 16 5 15 3 15v26l-3 18-7 19-5 9-6 9-3 5h-2l-2 4-14 14-13 9-10 6-18 7-16 3-7 1h-19l-15-2-19-6-16-8-14-10-12-11-9-11-9-15-7-16-4-16-2-12v-19l3-17 4-13 5-11 5-10 9-12 16-16 11-8 14-8 15-6zm12 5-20 2-20 6-14 7-13 9-12 11-10 13-9 16-6 16-3 14v31l3 15 6 16 6 11 10 14 13 13 11 8 10 6 10 4h3v2l15 4 5 1h35l13-3 9-3 16-8 11-7 16-15 10-13 5-9 6-13 4-13 2-14v-23l-3-16-5-15-9-17-8-10-9-10-9-8-18-11-14-6-17-4-8-1z"
      fill="#DFDFDE"
    />
    <path
      transform="translate(490,885)"
      d="m0 0h36l26 4 21 6 19 8 17 9 12 8 14 11 13 12 9 10 3 6 11 15 3 5 4 4 13 25 8 20 7 23 6 25 4 26 1 21-1 37h-2v11l-4 27-5 24h-1l1-17 4-25 2-24v-25l-4-40-6-31-6-20-11-27-11-21-10-15-8-10-18-20-8-7-11-9-5-5-11-7-10-4-15-7-21-7-26-5-9-1h-35l-24 3-23 5-27 9-25 11-21 11-9 3 4-4 15-9 12-7 6-2v-2l19-9 21-8 18-5 20-4z"
      fill="#364042"
    />
    <path
      transform="translate(521,918)"
      d="m0 0h16v2l6-1 9 3 15 2 8 3 16 3 9 2 17 12 10 8 15 14 12 14 10 14 11 18 4 7v2l-4-1-6-12-7-10-5-4-6-7v-2l-3-1v-2l-2-1-6-7v-2l-5-2-11-8-9-7-6-3v-2h-5v-2l-9-3-11-4-9-2-11-2h-8l-3-1h-24l-10 4h-2v-2l3-1 1-2-18 8-3-1 8-5h3v-2l-16 7-12 6-3-1 6-2v-2l5-1v-2l-4 1-14 7-13 6v2l-5-1-14-6-1 1 16 8-3 1-4-2h-5l10 5-3 1-8-4-3 1 9 5-2 1-8-4-4 1 4 3-10-2-1 2-5 2-3 1-7-3v2l16 8-3 1-6-3h-6l-15-8-1-2 7 1-6-4-1-2 8 1-4-2v-2l5-1 1-2h5v-2l8-4h1v-2h6l-1-2 6 1v-3l5-1 3-3 3 1 1-3 6-1 3-2 10 1v-2l6-1v-2h7l-2-2 5-1 1 2 8-2v-2l10-3 18-1z"
      fill="#938D89"
    />
    <path
      transform="translate(251,1045)"
      d="m0 0h6l6 9 3 4v2l-2 1 4 1 4 8 4 5v2l-12 1-2 1 18 1v-1l7-1h12l1-2h18l6 9v2h2l-5-10v-2l3-1h13l8 11v3h-9v2h-8l-1 1-10 1h-10l-1 1-9 1h11 2l18-1 8 13v1l-6 1h-10l-3-1v3l-4 1h-14l-1 1-26 3h-13l-5-10-2-2v-3l7-1h9l2 1v-3h10l1-1h-9l-5-2v2l-18 1-8-12v-3l-10-2v2l-4-1 5-7h2l1-6 3-4h11v-2h-9l2-4 3-5h2l2-6h2z"
      fill="#580E12"
    />
    <path
      transform="translate(144,1272)"
      d="m0 0h4v2l15 2 1-2 5 5 10 5 13 4 8 7 15 5 7 3 5 5 5 9 7 6 3 1 1 8 8 1 2 4h2v3h3l5 5 9 3 4 4 3 2 15 3 5 2 3 3 9 5v2h3v2l6 2 10 6 6 3 2 2 1 5-6-2-36-18-23-11-23-12-39-20-23-11-19-10-36-18-2-6 2-3 8 1h2l2-4z"
      fill="#430C0D"
    />
    <path
      transform="translate(430,1168)"
      d="m0 0h1l-1 8-3 6-1 10 10 5 11 3h7l5-5 1-2v-10l-2-2-7-1v2h-2l-1 5-4-2-1-4 9-5 1-1h5l8 8 2 1-3 12v11l5 12-1 3-7-10-3-7v-3l-3 1h-10l-1 6-4 4-2 1-10 1-3 1 1 3 8 4 1 7 5-4 8-2h9l-2 2-10 2-8 5-5 8-10 8-8 9-3 9h-8l-4-4-2 1h-7l-3-4-1-22-2-5v-15l5-6 5-1 7 4v-3l-5-2-7-6-2-3v-7l4-6 16-12 3-1-2 5-6 8-2 5v6l5 1 12 6v2l6 1 4 2h10l5-5v-5l-10-2-8-4-2-3v-10h2l2-10z"
      fill="#661218"
    />
    <path
      transform="translate(316,1057)"
      d="m0 0h11l9 13-1 2 5 1 18-2 1-1 20-2h16l5 5 4 6-1 3 4-1 18-1h12v1l-11 2-26 2-9 1-5 1 9 12v2l-15 1-4-3-1 4-16 1-4-5-5-9h8v-2h-10v-2h-2l-1 2-4-7-4-6h-16l5 9v3h-2l-6-9v-2h-18l-1 3h-12l-6 1-2 1-18-1 3-3h12l-8-12v-2h2v-2l8-1h10l1-1 7-1h12 2z"
      fill="#640F15"
    />
    <path
      transform="translate(537,299)"
      d="m0 0 2 1-4 7-7 10-7 14-8 15-5 12-5 10-18 34-12 24-10 18-6 14-10 19-8 16-8 15-8 16-11 23-11 22-10 19-8 16-10 21-6 12-5-2-1-3 8-16 14-29 10-19 8-15 11-23 10-19 8-17 8-16 12-23 8-16 12-22 8-16 9-19 8-16 20-39 8-15 8-10z"
      fill="#787D7F"
    />
    <path
      transform="translate(14,1265)"
      d="m0 0 1 4 2 12 3 6 9 6 106 53 23 12 102 51 19 10 108 54 17 9 72 36 19 10 34 17 5 3 5 2 32 16 19 10 19 9 16 8 6 2 10-1 8-5 3-1-2 4-7 5-6 2h-9l-18-8-19-10-42-21-19-10-66-33-17-9-21-10-19-10-80-40-15-8-92-46-15-8-16-8-19-10-16-8-19-9-16-8-33-17-56-28-10-7-6-8-1-4v-11z"
      fill="#B5B7B6"
    />
    <path
      transform="translate(555,274)"
      d="m0 0h14l20 9 19 10 102 51 38 20 19 9 19 10 102 51 19 10 56 28 47 24 15 8 23 11 25 13 23 11 24 13 6 7 2 4v8l-6-12-5-4-32-16-39-20-22-11-19-10-23-11-19-10-68-34-19-10-84-42-19-10-17-8-19-10-106-53-15-8-16-8-25-13-13-4h-11z"
      fill="#909393"
    />
    <path
      transform="translate(429,1112)"
      d="m0 0h21l17 3 17 6 14 7 11 7 15 14 13 18 9 19 4 16 1 5v37l-6 21-5 12-10 16-14 15-8 7-14 9-8 4-14 5-20 4-6 1h-12l-19-3-18-5-17-9-13-9-12-11-11-15-8-16-5-13-4-19v-27l4-18 6-17 8-14 10-13 11-11 10-7 14-8 11-5 15-4zm8 3-20 2-20 6-14 7-13 9-12 11-10 13-9 16-6 16-3 14v31l3 15 6 16 6 11 10 14 13 13 11 8 10 6 10 4h3v2l15 4 5 1h35l13-3 9-3 16-8 11-7 16-15 10-13 5-9 6-13 4-13 2-14v-23l-3-16-5-15-9-17-8-10-9-10-9-8-18-11-14-6-17-4-8-1z"
      fill="#651016"
    />
    <path
      transform="translate(490,885)"
      d="m0 0h36l26 4 21 6 19 8 17 9 4 4-7-2v2l-8 2-21-10-21-7-26-5-9-1h-35l-24 3-23 5-27 9-25 11-21 11-9 3 4-4 15-9 12-7 6-2v-2l19-9 21-8 18-5 20-4z"
      fill="#303A3C"
    />
    <path
      transform="translate(1063,199)"
      d="m0 0 9 3 15 8 200 100 9 6 1 3-3 5-1-2-6-2-23-11-19-10-26-13-19-10-84-42-19-10-14-7-1-3 1-1-11-6-2-1v-2l-5-2-2-1z"
      fill="#737779"
    />
    <path
      transform="translate(1117,591)"
      d="m0 0h1v15l-9 20-67 134-10 19-72 144-4 10-6-1-2-2h2v-2h2l2-6 8-16 7-16 8-16 12-23 4-7 6-12 11-23 18-36 9-19 12-21 11-23 10-18 1-3h2l1-5 5-12 8-14 8-16 9-17 5-11 7-15z"
      fill="#787D7F"
    />
    <path
      transform="translate(530,926)"
      d="m0 0h13l12 2 17 2 16 5h9l10 6v2h4l3 3 4 2v2l5 2 7 6h4l10 10 9 11 10 13 12 20 4 7v2l-4-1-6-12-7-10-5-4-6-7v-2l-3-1v-2l-2-1-6-7v-2l-5-2-11-8-9-7-6-3v-2h-5v-2l-9-3-11-4-9-2-11-2h-8l-3-1h-24l-10 4h-2v-2l3-1 1-2-18 8-3-1 8-5h3v-2l-16 7-12 6-3-1 6-2v-2l5-1v-2l-4 1-14 7-13 6v2l-5-1-5-4-2-2-4-1v-2l10-1 13-5 8-4 5-2 6-2v3l10-4 5-3h4v2h-4l-1 3h-4v2l14-6h5l-1 2z"
      fill="#9A9C9D"
    />
    <path
      transform="translate(1116,588)"
      d="m0 0h1v11l-9 19-4 9-11 20-8 17-7 12-5 13h-2l-1 5-9 16-16 32-9 16-11 23-21 42-7 15-6 12-12 22-8 17-7 15-12 24h-2v2l-4-1h2l1-7 9-20 3-7 6-10 9-19 8-15 11-23 9-16 8-16 4-10 11-20h2l1-5 5-10 10-22 3-6 4-6 5-12 12-23 29-58 9-17 6-12 2-6z"
      fill="#999998"
    />
    <path
      transform="translate(330,1019)"
      d="m0 0h17l3 5v2h2l3 4v2h-2v2l4 1 5 9 3 5-1 1h-18v2h-20v2h-18l-4-5-4-6 1-3-3-1-8-13v-2l2-1h15l4 6v2l3 1 2 5 1-2-2-3-5-8-1-2 3-1h14l4 5 3 3v-3l-3-4z"
      fill="#540D11"
    />
    <path
      transform="translate(222,1139)"
      d="m0 0h7l7 11 1 3h-14v2l-4 1h21l1-1h18l3 6 5 6-4 1-2 1h-11l-2-1v3l-17 2h-2l-1 1-18 1-1-4h-2l1 4-1 1h-16l-4-7v-6l2-4h6l3-2-1-7v-8l11-1 4 3v-4z"
      fill="#601015"
    />
    <path
      transform="translate(437,1115)"
      d="m0 0h12l18 3 15 5 15 8 11 8 9 8 1 4 7 9-1 2-8-8-2 1-5-6-17-12-17-8-13-4-10-2h-24l-17 3-17 7-16 9-19 19-10 15-7 17-4 16-1 30h-1v-28l-5-2 1-10 5-15 8-16 9-13 11-12 13-10 14-8 21-7 10-2z"
      fill="#948E89"
    />
    <path
      transform="translate(308,980)"
      d="m0 0 1 2 18-1 1 3h2l7 12h18l1-4 5 2 1 3h-3v2l-20 2v4l5 8v2l-6 1h-11l-8-12v3l6 9-2 2h-16l-8-13-2 1 8 12-2 2h-15l-5-6-2-3v-4l11-3-6-1 5-5h2l1 2h2l-2-6 9-9h3z"
      fill="#610F14"
    />
    <path
      transform="translate(492,904)"
      d="m0 0h10l4 1h13l14 2v2h-23l-6 1-17 1-7 2h-8l-17 5-6 2-11 5-15 5-1 3h-4v-2l-3 1 2 2-7 1-7 2-6 4-6 3h-2l-1 3-4 3h-2l-1 4-3 1-13-7 1-2-2-5 25-13h4 2l1-2 13-3 6-3 7-2 19-5 7-1 9-3 27-4z"
      fill="#6A7071"
    />
    <path
      transform="translate(660,1124)"
      d="m0 0 5 2 3-1 34 17 6 2v-8h1v9l-3 1 1 3-3 6v3l1 7 2 1-6 1-13-4 3 5 5 8-1 3-8-12-2-2v-2-3l-4-2-10-6-8-3h-4l1 5-3-3-5-8-15-8-4-2 2-1-1-3 1-1h8l8 4 6 1-4-6z"
      fill="#8C292E"
    />
    <path
      transform="translate(251,1045)"
      d="m0 0h6l6 9 3 4v2l-2 1 4 1 4 8 4 5v2l-12 1-2 1 3 2h-4l7 11-1 4-18 1-8-12v-3l-10-2v2l-4-1 5-7h2l1-6 3-4h11v-2h-9l2-4 3-5h2l2-6h2z"
      fill="#590E12"
    />
    <path
      transform="translate(321,1145)"
      d="m0 0h11v1l7 2 2 5-12 1 1 1h5l1 4v2l-7 1-1 1-3 1-16 1-1 1-19 1-1 1-18 1h-2l-5 1h-14l1-2h11l5-1-4-5-2-2-1-4-13-1v-1l25-2h7l2 1 1-2 3-1 16-1 3-1 17-1z"
      fill="#640F15"
    />
    <path
      transform="translate(228,1084)"
      d="m0 0h10l8 11v3l-13 1-1 1 16 1 6 9-1 4-1 1-7 1-6 2 3 7 5 9-4 1h-13l-1-3h-2l-6-9-4-4h-6l-1-4 6-9 7-14 1-4z"
      fill="#5A0E13"
    />
    <path
      transform="translate(316,1057)"
      d="m0 0h11l9 13-1 2 6 1 6 11-2 4-4-7-4-6h-16l5 9v3h-2l-6-9v-2h-18l-1 3h-12l-6 1-2 1-18-1 3-3h12l-8-12v-2h2v-2l8-1h10l1-1 7-1h12 2z"
      fill="#500C0F"
    />
    <path
      transform="translate(531,302)"
      d="m0 0h2l-2 4-5 8-5 10-4 7-6 12h-2l-1 5h-2l-1 6-8 18-8 14-9 19-5 9-8 16-10 21-16 32-12 23-8 16-7 16-10 19-8 14-17 34h-2v3h-2l-2 7-4 10-3 4-3-1 8-16 12-25 10-19 8-15 11-23 10-19 8-17 8-16 12-23 8-16 12-22 8-16 9-19 8-16 20-39 8-15z"
      fill="#6B7172"
    />
    <path
      transform="translate(308,1198)"
      d="m0 0 1 2 16-1v3l-13 1-1 3v-2l-28 2-31 3-19 2-20 1 5 10 2 5 17-1h12v1l-46 5-1 1-19 1-1 3v-2h-4v-2h2l-8-12-3-5 2-2 19-2 19-1-1-4 3 2h18l1-2 3 1 15-1 1-3 1 1 19 1v-2h20l1-2 17-1z"
      fill="#641016"
    />
    <path
      transform="translate(396,1441)"
      d="m0 0 5 1 86 43 19 10 88 44 3 1h13l8-3 8-7 2-5-6-2-17-8-5-2-10-6-14-8 2-4 29 14 22 12 44 22 4 3-1 3-6-2-16-8-19-9-5 1-6 7-8 5-6 2h-9l-12-5-68-34-16-9-10-4-17-9-19-9-17-9-36-18-10-6z"
      fill="#0F1516"
    />
    <path
      transform="translate(232,1174)"
      d="m0 0h17l2 5-4 2 6 1-1 3-2-1-1 2h-3v2l-6-1-4-7v-2h-2l5 9v3l-3 1-15 1-2-2-1 2-4 1h-14l-4 2h-15l-4-5v-8l7-3 15-2h11l4 5-2-6 6-1h13z"
      fill="#631016"
    />
    <path
      transform="translate(390,1477)"
      d="m0 0 6 1 17 8 19 10 44 22 19 10 34 17 5 3 5 2 32 16 19 10 19 9 16 8 6 2 10-1 8-5 3-1-2 4-7 5-6 2h-9l-18-8-19-10-42-21-19-10-66-33-17-9-21-10-19-10-17-9z"
      fill="#D8D8D8"
    />
    <path
      transform="translate(362,941)"
      d="m0 0 3 1 3 7 14 7 42 22 16 8 45 23 72 36 17 9 23 11 35 18 72 36-1 2-6-2-16-8-19-9-15-8-19-9-22-12-84-42-17-9-9-3-3-3-21-10-19-10-40-20-4-1v-2l-6-2-16-8-31-16 2-4z"
      fill="#333C3E"
    />
    <path
      transform="translate(1035,754)"
      d="m0 0 1 4-11 23-21 42-7 15-6 12-12 22-8 17-7 15-12 24h-2v2l-4-1h2l1-7 9-20 3-7 6-10 9-19 8-15 11-23 9-16 8-16 4-10 11-20h2l1-5 3-5z"
      fill="#9A9B9B"
    />
    <path
      transform="translate(258,1025)"
      d="m0 0 6 1-6 9-2 6 3 2-7 2-1 2h-2l-1 4-3 3-3 5-2 3h9v2l-11 1-3 5-1 4-4 4-2 3 3 1v-2l8 1v2l-12 1-4 10-9 17-3 4v3l6 1 1-2v2l2 1-2 2h-10v-2l-5-2-15-8-72-36 1-3 4 1 52 26 14 8 19 9 3-3 2-7 4-4 7-19 1-2h3l2-9 6-10 3-3 3-5 3-7 5-8 1-9 7-3z"
      fill="#050809"
    />
    <path
      transform="translate(218,1121)"
      d="m0 0 4 2 5 7v2h2l1 2 18 1 1-2 16 1-1 2h-12l7 11-1 5-3 1h-15v-2l-5-1-6-9v-2l-7 1h-10v4l-4-2h-10l3-2 2-7 3-9 1-2z"
      fill="#4D0D10"
    />
    <path
      transform="translate(392,1211)"
      d="m0 0 4 1 11 7 4 3-1 3-7 1-1 1 2 4v23h-2l-1 6-3 4h-7l-3-4-1-22-2-5v-15l5-6z"
      fill="#F8F8F8"
    />
    <path
      transform="translate(914,450)"
      d="m0 0 11 3 38 19 47 24 15 8 23 11 25 13 23 11 24 13 6 7 2 4v8l-6-12-5-4-32-16-39-20-22-11-19-10-23-11-19-10-48-24z"
      fill="#969999"
    />
    <path
      transform="translate(340,1109)"
      d="m0 0h18l7 11v2l-7 2-20 2h-10l-1-5h-2v2 3l-5 1h-11l-8-12 2-3 36-2z"
      fill="#651016"
    />
    <path
      transform="translate(239,1081)"
      d="m0 0h6l-4 1 5 9 3 4v2l4-1h14v-2l8 1h10l-4 2-3 1-8-1v3h-18l4 7 3 7h13l18-2h8l1-2h18v-3h13l5-1-7-11v-2l-6 1h-12l-1 2-1-1h-17v-1l15-2 2-1h19l1-2 8 1v-2h10v-2h2v2h10v2h-8l-1 2h-18l8 13 18 1 1-2 27-1v1l-8 2-13 1-4 1-20 1-1 2-36 2-1 1-17 1-12 2-12 1h-20v2l-4-2 8-3 7-1 1-4-5-8-17-1 2-3h13l-4-7-4-5v-2l-10 1-3 3 2-5h12z"
      fill="#4E0C0F"
    />
    <path
      transform="translate(491,938)"
      d="m0 0h5l-3 2h-2v2l-5 1v2l-5 2-4 4-6 2v2l9-3 6-3 3 1-18 9 4 2v2l-9-1 1 3h-5l3 3-6 1 2-1v-2l-4 1 1 4-5 3h-3v-2l-6 1-8 4-11-6-4 2-9-5v-3l7 2h3l5-2 3-2 5 1-2-2 6-1 5 2-6-3 2-2 5 1 7 3-9-5v-1h6l4 1-15-7 1-2 9 3 10 4 3-3 16-7z"
      fill="#AAACAC"
    />
    <path
      transform="translate(302,986)"
      d="m0 0 5 1 7 11v2l-7 1-1 1 10 1 9 13-2 2h-16l-8-13-2 1 8 12-2 2h-15l-5-6-2-3v-4l11-3-6-1 5-5h2l1 2h2l-2-6z"
      fill="#9F3134"
    />
    <path
      transform="translate(514,343)"
      d="m0 0 1 4-5 10-6 15-3 5-7 13-4 11-4 4-5 10-9 17-16 32-9 19-10 18-12 25-8 15-7 14-10 21-10 19-10 21-7 15-2 6 6 2 3 3-2 1-11-5 2-7 4-8 11-23 8-15 16-32 11-23 10-19 8-16 8-15 10-21 13-26 7-12 4-9 9-17 8-16 7-13 4-8 5-12z"
      fill="#948E89"
    />
    <path
      transform="translate(243,1191)"
      d="m0 0h15l1 4-7 2-7-1 4 10-1 2-16 1h-3l-2 1h-16l-4-5-5-9 1-1 17-1 2 1v-2l8-1h12z"
      fill="#630F15"
    />
    <path
      transform="translate(210,1387)"
      d="m0 0 10 2 40 20 19 10 114 57-3 1-1 2-62-31-15-8-92-46-10-6z"
      fill="#AFB1B0"
    />
    <path
      transform="translate(751,366)"
      d="m0 0 6 2 35 18 96 48 19 10 14 7-3 1h-3l-6-2-41-21-76-38-19-10-17-8-4-4z"
      fill="#8B8D8D"
    />
    <path
      transform="translate(425,1110)"
      d="m0 0h29l12 2 14 4 15 7 1 4-25-10-14-3-7-1h-21l-18 3-15 5-17 9-14 10-11 11-11 16-7 14-5 15-3 15-1 24h-1l-1-21-1-1 1-11 4-15 6-13 5-10 9-12 16-16 11-8 14-8 15-6z"
      fill="#B7B7B6"
    />
    <path
      transform="translate(539,1270)"
      d="m0 0 3 3-8 14-7 9-9 10-10 9-14 9-8 4-14 5-20 4-6 1h-12l-19-3-18-5-17-9-13-9-11-10 4-2 8 8 11 8 10 6 10 4 3 1v2l20 4h35l13-3 9-3 16-8 11-7 15-14 10-13 5-9z"
      fill="#74262A"
    />
    <path
      transform="translate(31,1223)"
      d="m0 0 12 2 58 29 29 15 3 1-1 6-6-2-10-5-2 1v3l3-1 8 6v3l-5-1-7-6-3-7v-3l-6-2-17-9-4 1-1-3-23-12-28-14z"
      fill="#101617"
    />
    <path
      transform="translate(409,1175)"
      d="m0 0h2l-2 5-6 8-2 5 1 6 4 4 9 5 9 3 2 4-5 6h-7l-7-4v-2l-5-2-9-6-5-6v-7l4-6 16-12z"
      fill="#F5F5F5"
    />
    <path
      transform="translate(188,1196)"
      d="m0 0h12l9 13v2l-3 1-23 2h-13l-4-5v-5l5-6 7-1 5 5 2 1-3-6z"
      fill="#661218"
    />
    <path
      transform="translate(430,1168)"
      d="m0 0h1l-1 8-3 6-1 10 10 5 11 3h7l5-5 1-2v-10l-2-2-7-1v2h-2l-1 5-4-2-1-4 9-5 1-1h5l8 8 2 1-3 12v11l5 12-1 3-7-10-3-7v-3l-3 1h-10l-1 6-4 4-2 1-10 1-4 1-1-4-10-4-11-6-1-3 5 2 11 5v2l6 1 4 2h10l5-5v-5l-10-2-8-4-2-3v-10h2l2-10z"
      fill="#69181E"
    />
    <path
      transform="translate(485,902)"
      d="m0 0h21l24 3 18 4v1l-10-1-19-3h-14l-6-1h-7l-20 3-15 2-13 4-11 2-16 4-8 4-13 3-5 3v-2l-16 8-2-1 17-9 27-11 20-6 20-4z"
      fill="#2D3739"
    />
    <path
      transform="translate(476,1119)"
      d="m0 0 6 1 16 8 11 7 15 14 13 18 9 19 4 16 1 5v37l-6 21-3 7-2-1 1-5 5-16 2-14v-23l-3-16-5-15-9-17-8-10-9-10-11-9-15-9-14-6z"
      fill="#6B1A1F"
    />
    <path
      transform="translate(493,1124)"
      d="m0 0 5 1 9 6 5 3v2l4 2 13 13 10 14 8 16 5 15 3 15v26l-3 18-7 19-3-1 1-5 6-20 1-4v-37l-4-18-7-16-7-12-11-14-12-11-7-5-6-3z"
      fill="#E3E3E2"
    />
    <path
      transform="translate(293,995)"
      d="m0 0 3 3 1 3-4-1v-2l-6 4 6 1-1 2-11 2 3 7 4 5h15v-3l-7-10 3-2 8 12v2l38-2 1-2 3 2v2l-19 1 5 9-1 2-4-2-4-6h-14l-2 1 4 5 4 6 13 1v1h-14-3l-1-4-3-3-3-5h-17l8 14-6 1 3-1-8-12-2-1h-16l1-4 4 1h11l-4-7-1-3-3-1 4-6 7-7 5-1z"
      fill="#480B0D"
    />
    <path
      transform="translate(364,1144)"
      d="m0 0 2 1-8 9-1 2 8-5h2l-2 4-9 10-8 13-6 15-4 16-1 30h-1v-28l-5-2 1-10 5-15 8-16 9-13z"
      fill="#B5B6B4"
    />
    <path
      transform="translate(415,973)"
      d="m0 0 7 1 13 5 26 13 19 10 15 8 16 8 9 5 16 9v3l-19-9-60-30-19-10-16-8-7-4z"
      fill="#A8AAAA"
    />
    <path
      transform="translate(396,1441)"
      d="m0 0 5 1 86 43 19 10 72 36 1 3-6-2-52-26-16-9-10-4-17-9-19-9-17-9-36-18-10-6z"
      fill="#0E1415"
    />
    <path
      transform="translate(543,297)"
      d="m0 0h12v1l-6 1-6 7-6 9-5 8h-2l-1 5-4 8-5 12-6 10h-2v3h-2l-1 4h-2l3-10 4-8 2-9 10-18 4-8 7-9 2-5z"
      fill="#9B9D9E"
    />
    <path
      transform="translate(281,1151)"
      d="m0 0 3 1v2l5 7v2h3v2l4 1-7 1-1 1-18 1h-2l-5 1h-14l1-2h11l5-1-4-5-2-2-1-4-13-1v-1l25-2h7l2 1z"
      fill="#630F15"
    />
    <path
      transform="translate(580,1302)"
      d="m0 0 1 2h2v4l6 2-1 4 1 2 8 1v1l-4 1h15l5 4 3 5v-4l9-1 1 3-6 2 1 4h5v1l-8 1-17 1 18 1 1 2h-19l-1-1-9-1v-1l6-1-1-6-2-5h5v-2h2v3l6-1v-3l-12 1-2 2-1 2-2-3h-8l-3-3h7l-1-6-3-4v-2l-3-1z"
      fill="#5D1A23"
    />
    <path
      transform="translate(1031,182)"
      d="m0 0 20 9 13 7-1 3 5 2 2 1v2l5 2 10 5-1 2-3 1-41-20-18-10 4-1 4 1h2z"
      fill="#7A7E7F"
    />
    <path
      transform="translate(280,743)"
      d="m0 0 1 2-19 37h-2l-2 6-5 9-3-1-1-8 12-24 6-10 4-6 3-2v3l3-1z"
      fill="#303A3C"
    />
    <path
      transform="translate(308,1198)"
      d="m0 0 1 2 16-1v3l-13 1-1 3v-2l-28 2-31 3-19 2-20 1 5 10v3l-8-11-17 1-2 1h-17l1 5 5 8-1 2-10-14 2-3 19-2 19-1-1-4 3 2h18l1-2 3 1 15-1 1-3 1 1 19 1v-2h20l1-2 17-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(554,1045)"
      d="m0 0 5 1 15 8 23 11 35 18 72 36-1 2-6-2-16-8-19-9-15-8-19-9-22-12-52-26z"
      fill="#2A3436"
    />
    <path
      transform="translate(327,1160)"
      d="m0 0 2 1 12 1v1l-10 2-19 2-2 1h-9l-63 6h-6l-1 3v-2l-19 1 3 6-1 2-4-6h-11l-15 2-5 1 3-4 2-5 2-10h3l-1 1v6l3 5v2l16-1v-4h2l1 3 18-1 2-2 2 1 16-1 1-3v2l19-1 1-2 4 1 15-1 1-1 19-1 4-1 13-1 2-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(579,1494)"
      d="m0 0 6 1 90 45 4 4-1 2-5-2-36-18-42-22-16-8z"
      fill="#8C8D8C"
    />
    <path
      transform="translate(511,943)"
      d="m0 0h4v2h-3v2l-10 5-3 3-16 8-3 2h-3v2l-24 11-2-1 20-10-4-1-3-4 5-1 6 2v-2l-5-1 3-3 19-9h3v3z"
      fill="#B6B8B7"
    />
    <path
      transform="translate(491,938)"
      d="m0 0h5l-3 2h-2v2l-5 1v2l-5 2-4 4-6 2v2l9-3 6-3 3 1-18 9 4 2v2l-9-1 1 3h-5l3 3-6 1 2-1v-2h-2v-2l-3-1v-3-2l-5-2-13-6 1-2 9 3 10 4 3-3 16-7z"
      fill="#A7A9A8"
    />
    <path
      transform="translate(475,1122)"
      d="m0 0 7 1 15 8 11 8 9 8 1 4 7 9-1 2-8-8-2 1-5-6-17-12-17-8-1-2 5-1h2l-6-3z"
      fill="#BABBBA"
    />
    <path
      transform="translate(429,513)"
      d="m0 0 1 3-9 17-11 22-10 21-10 19-10 21-7 15-2 6 6 2 3 3-2 1-11-5 2-7 4-8 11-23 8-15 16-32 11-23 8-15z"
      fill="#948E89"
    />
    <path
      transform="translate(555,274)"
      d="m0 0h14l20 9 19 10 102 51 1 2-6-2-22-11-23-11-17-9-19-9-19-10-10-5-7-1-16-8-10-3h-11z"
      fill="#74797B"
    />
    <path
      transform="translate(39,1222)"
      d="m0 0 6 1 23 11 50 25 16 9-1 3-17-8-19-10-58-29z"
      fill="#7A7C7C"
    />
    <path
      transform="translate(14,1265)"
      d="m0 0 1 4 2 12 3 6 9 6 70 35v2l-7-1-62-31-10-7-6-8-1-4v-11z"
      fill="#B6B7B5"
    />
    <path
      transform="translate(298,1182)"
      d="m0 0 17 1v1l-14 1-1 2h-9l-9 1-2 1h-19l-1 2-17 1-2 1h-19v2h-10l-9 1 5 9-1 2-6-8-1-2h-18l4 4-1 3-5-5-1-7 4 2h16l1-2h18l1-3 3 2 17-1 1-2h18l-1-4 3 2h15l7-1 16-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(164,1217)"
      d="m0 0h6l10 14v2l-4 1-22 1 2-9 3-7z"
      fill="#661319"
    />
    <path
      transform="translate(390,1477)"
      d="m0 0 6 1 17 8 19 10 44 22 1 2-5 1-17-9-21-10-19-10-24-12z"
      fill="#B7B8B7"
    />
    <path
      transform="translate(557,1563)"
      d="m0 0 5 1 13 6 6 1 16 8 19 10 11 5 10 1 10-4 3-3h2l-2 4-7 5-6 2h-9l-18-8-19-10-34-17z"
      fill="#C2C4C4"
    />
    <path
      transform="translate(425,1110)"
      d="m0 0h29l12 2 14 4 15 7 1 4-25-10-14-3-7-1h-21l-18 3-15 5-11 5-4-1 16-8 16-5z"
      fill="#9E9B98"
    />
    <path
      transform="translate(329,1201)"
      d="m0 0h3l-1 8v31l3 15 6 16 6 11 10 14 3 3v2h-4l-11-15-8-16-5-13-4-19v-27z"
      fill="#691218"
    />
    <path
      transform="translate(299,693)"
      d="m0 0h4l1 3-12 25-10 19-5 10-3-1-1-2-3 2 1-4 3-3 14-25 6-11 3-9z"
      fill="#6A7071"
    />
    <path
      transform="translate(307,1237)"
      d="m0 0 5 1v1l-23 3-14 1-6 1-25 2-25 3-26 2-39 4-1-2 19-3 18-1 1-2h18l19-2 3-1h18v-2l20-1 2-1h18l1-2z"
      fill="#430B0C"
    />
    <path
      transform="translate(563,1339)"
      d="m0 0 10 1h7v2l3 1 5 6-1 5-3 1h-11l-3-1-8-11z"
      fill="#641016"
    />
    <path
      transform="translate(554,1329)"
      d="m0 0 5 5 3 4 12-1 4 1-3-6v-2l3 1 2 7h20v1h-17l-1 2-2 1v-2l-9 1-8-2 1 6 6 7v2h14l5-2 1 5-5 2-2-1-1 2h-7l1 4-2 2v-2h-6v-6l-3-1 3-1-2-4-6-9-5-2 4-1-5-9z"
      fill="#5D1A23"
    />
    <path
      transform="translate(434,951)"
      d="m0 0h5l9 3 8 4-3 1-4-2h-5l10 5-3 1-8-4-3 1 9 5-2 1-8-4-4 1 4 3-10-2-1 2-7 2-5-3 4-4-5-2-2-3h7 3 6l1 2h2v-2l-4-2 3-1h3z"
      fill="#9C9E9E"
    />
    <path
      transform="translate(462,1294)"
      d="m0 0h2l1 5 3-2h6l1 1v7l-3 1v7l-2 2h-6l-2-2v-4l-6 1-2-3 1-6 6-1-1-5z"
      fill="#F1F1F1"
    />
    <path
      transform="translate(358,1242)"
      d="m0 0 7 1 5 5v3l-2 2v6l-4 2-7-1-8-4 2-6 4-6z"
      fill="#F3F3F3"
    />
    <path
      transform="translate(914,450)"
      d="m0 0 11 3 38 19 23 12-3 1-1 2-19-10-48-24z"
      fill="#959899"
    />
    <path
      transform="translate(362,941)"
      d="m0 0 3 1 3 7 14 7 42 22 14 7-1 3-24-12-4-1v-2l-6-2-16-8-31-16 2-4z"
      fill="#3A4345"
    />
    <path
      transform="translate(409,1133)"
      d="m0 0 9 6 4 4 1 7-3 2-7 2-5 1-3-3v-10l3-8z"
      fill="#AAABAB"
    />
    <path
      transform="translate(239,1081)"
      d="m0 0h6l-4 1 5 9 3 4v2l4-1h14v-2l8 1h10l-4 2-3 1-8-1v3h-18l4 7v3h-3l-5-8-17-1 2-3h13l-4-7-4-5v-2l-10 1-3 3 2-5h12z"
      fill="#530F13"
    />
    <path
      transform="translate(230,1120)"
      d="m0 0h9l8 13-4 2h-13l-1-3h-2l-6-9-1-2z"
      fill="#9D3034"
    />
    <path
      transform="translate(269,1043)"
      d="m0 0h8l8 12v3h-17l-7-10-1-4z"
      fill="#9F3134"
    />
    <path
      transform="translate(276,1097)"
      d="m0 0h12l9 13v1l-8 1h-10l-7-10-1-4z"
      fill="#9D3034"
    />
    <path
      transform="translate(292,1023)"
      d="m0 0h15l4 6v2l3 1 1 4-1 1-16 1-7-10-1-4z"
      fill="#9E3134"
    />
    <path
      transform="translate(279,1024)"
      d="m0 0h6l5 5 5 7v2l-3 1h-14l-8-11-1-3z"
      fill="#9F3134"
    />
    <path
      transform="translate(325 1e3)"
      d="m0 0h11l8 12v2l-6 1h-11l-8-12v-2z"
      fill="#9F3134"
    />
    <path
      transform="translate(291,1059)"
      d="m0 0h16l8 11v3l-17 1-8-13z"
      fill="#9F3134"
    />
    <path
      transform="translate(308 1e3)"
      d="m0 0h8l9 13-2 2h-16l-8-13v-1z"
      fill="#9F3235"
    />
    <path
      transform="translate(321,984)"
      d="m0 0h5l9 13-3 2h-15l-9-13v-1z"
      fill="#9F3134"
    />
    <path
      transform="translate(250,1063)"
      d="m0 0h18l6 9 2 5-17 1-8-12z"
      fill="#9F3134"
    />
    <path
      transform="translate(317,1144)"
      d="m0 0 4 2-1 2-17 1-3 1-19 1-1 3-2-1-16 1-21 1-1 2h-26v-1h5l1-2 3 1v-2h14l1-3 2 1v2l18-1v-3h19l1-3 1 3 19-1 1-2h18z"
      fill="#430B0C"
    />
    <path
      transform="translate(331,1055)"
      d="m0 0h16l8 12v2l-16 1-7-9-2-5z"
      fill="#9E3134"
    />
    <path
      transform="translate(352,999)"
      d="m0 0h5l7 12-1 3h-16l-7-11 1-3z"
      fill="#9F3134"
    />
    <path
      transform="translate(250,1118)"
      d="m0 0h8l4 5 5 7v2l-2 1h-15l-8-12v-2z"
      fill="#9E3134"
    />
    <path
      transform="translate(241,1082)"
      d="m0 0h17l6 9 2 5-17 1-8-12z"
      fill="#9F3134"
    />
    <path
      transform="translate(330,1019)"
      d="m0 0h17l3 5v2h2l3 4v2l-2 1-15 1-8-12z"
      fill="#9E3134"
    />
    <path
      transform="translate(265,1080)"
      d="m0 0h13l8 12v2l-16 1-4-4-5-8v-2z"
      fill="#9E3134"
    />
    <path
      transform="translate(308,1076)"
      d="m0 0h10l8 13v1l-17 1-4-5-4-7v-2z"
      fill="#9F3134"
    />
    <path
      transform="translate(349,1072)"
      d="m0 0h8l8 12v2l-6 1h-11l-7-11v-3z"
      fill="#9D3034"
    />
    <path
      transform="translate(321,1038)"
      d="m0 0h16l8 12v2h-18l-7-11z"
      fill="#9E3134"
    />
    <path
      transform="translate(264,1135)"
      d="m0 0h5l7 11 1 3-4 1h-13l-8-12v-2z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(287,1078)"
      d="m0 0h11l7 11v3l-16 1-6-9-3-5z"
      fill="#9E3134"
    />
    <path
      transform="translate(298,1095)"
      d="m0 0h9l7 9 2 5-7 1h-9l-5-6-4-7v-1z"
      fill="#9C3033"
    />
    <path
      transform="translate(322,1093)"
      d="m0 0h6l8 13v1l-6 1h-10l-4-4-5-8v-2z"
      fill="#9D3034"
    />
    <path
      transform="translate(316,1057)"
      d="m0 0h11l9 13v1l-17 1-6-8-3-6z"
      fill="#9E3134"
    />
    <path
      transform="translate(281,1042)"
      d="m0 0h16l8 11v2l-2 1-12 1-5-3-6-11z"
      fill="#9F3134"
    />
    <path
      transform="translate(592,1356)"
      d="m0 0 2 1 4 9-1 5-9 1-8-3-5-3-1-7h8l6-2z"
      fill="#641016"
    />
    <path
      transform="translate(341,1091)"
      d="m0 0h6l8 11 1 3-5 1h-12l-6-9-2-5z"
      fill="#9E3134"
    />
    <path
      transform="translate(324,1074)"
      d="m0 0h13l8 11v3l-16 1-7-10-1-4z"
      fill="#9E3134"
    />
    <path
      transform="translate(301,1040)"
      d="m0 0h16l7 10 1 3-5 1h-12l-4-5-4-6z"
      fill="#9F3134"
    />
    <path
      transform="translate(259,1099)"
      d="m0 0h9l8 12v2l-5 1h-11l-8-11v-3z"
      fill="#9E3134"
    />
    <path
      transform="translate(381,1032)"
      d="m0 0h15l8 11v3h-17l-7-10-1-3z"
      fill="#9E3134"
    />
    <path
      transform="translate(282,1115)"
      d="m0 0h17l7 11v2l-2 1-12 1-5-4-6-10z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(343,1036)"
      d="m0 0h14l8 12-1 2h-17l-6-10-1-3z"
      fill="#9F3134"
    />
    <path
      transform="translate(358,1016)"
      d="m0 0h7l9 12v2l-5 1h-12l-7-11v-3z"
      fill="#9F3134"
    />
    <path
      transform="translate(203,1159)"
      d="m0 0h17l3 6 4 5v2l-6 1h-11l-3-4-3-5-2-4z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(364,1144)"
      d="m0 0 2 1-8 9-1 2 8-5h2l-2 4-9 10-8 13-2 5-5-2 2-10 8-12 11-13z"
      fill="#ABACAB"
    />
    <path
      transform="translate(273,1116)"
      d="m0 0h5l8 12v2l-3 1h-13l-8-11v-3z"
      fill="#9D3034"
    />
    <path
      transform="translate(358,1089)"
      d="m0 0h9l6 9 2 5-16 1-4-5-5-9z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(382,1014)"
      d="m0 0 5 1 7 11v2l-5 1h-12l-7-11v-2z"
      fill="#9E3134"
    />
    <path
      transform="translate(273,1061)"
      d="m0 0h14l8 11v3l-15 1-5-6-4-6v-2z"
      fill="#9E3134"
    />
    <path
      transform="translate(404,1030)"
      d="m0 0h13l7 11v3h-17l-7-11v-2z"
      fill="#9E3134"
    />
    <path
      transform="translate(516,1190)"
      d="m0 0 7 1 8 3-1 4-6 9-8-1-6-3 1-6z"
      fill="#F3F3F3"
    />
    <path
      transform="translate(222,1139)"
      d="m0 0h7l7 11 1 3-16 1-4-4-5-8v-2z"
      fill="#9E3134"
    />
    <path
      transform="translate(236,1101)"
      d="m0 0h12l6 9-1 4-1 1-12 1-9-12v-2z"
      fill="#9E3134"
    />
    <path
      transform="translate(354,1053)"
      d="m0 0h12l8 10 1 4h-17l-4-5-4-8z"
      fill="#9F3134"
    />
    <path
      transform="translate(312,1021)"
      d="m0 0h14l4 5 5 8-3 1h-14l-4-5-4-6-1-2z"
      fill="#9F3134"
    />
    <path
      transform="translate(217,1103)"
      d="m0 0h11l9 12v2l-4 1h-13l-5-6 1-6z"
      fill="#9E3134"
    />
    <path
      transform="translate(377,1051)"
      d="m0 0h9l8 11v2l-2 1h-15l-8-12v-1z"
      fill="#9E3134"
    />
    <path
      transform="translate(367,1034)"
      d="m0 0h9l8 10 1 3-1 1-13 1-5-3-6-9v-2z"
      fill="#9E3134"
    />
    <path
      transform="translate(233,1138)"
      d="m0 0h16l8 11v2l-3 1h-14l-7-11z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(302,1113)"
      d="m0 0h16l7 10v3l-5 1h-11l-8-12z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(388,1068)"
      d="m0 0h7l5 5 4 6-1 3-14 1-5-6-4-6v-2z"
      fill="#9D3134"
    />
    <path
      transform="translate(592,288)"
      d="m0 0 5 1 21 11 16 8 19 9 32 16v3l-6-2-48-24-15-8-24-12z"
      fill="#939393"
    />
    <path
      transform="translate(272,1134)"
      d="m0 0h17l7 11v2l-3 1h-12l-7-8z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(405,1066)"
      d="m0 0h11l8 11-1 3h-15l-5-6-3-5v-2z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(239,1065)"
      d="m0 0h10l6 10 1 4-17 1-4-7v-5z"
      fill="#9E3134"
    />
    <path
      transform="translate(304,1131)"
      d="m0 0 4 1 8 11v2l-7 1h-10l-5-9-2-4 1-1z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(380,1087)"
      d="m0 0h6l9 12v2l-15 1-5-5-4-6v-3z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(242,1155)"
      d="m0 0h17l3 6 5 6-4 1-2 1h-11l-6-9z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(367,1070)"
      d="m0 0h8l4 4v2h2l4 7-1 1h-16l-8-11v-2z"
      fill="#9E3134"
    />
    <path
      transform="translate(341,1109)"
      d="m0 0h17l7 11v2l-10 1-7-1-7-11z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(438,1045)"
      d="m0 0h8l8 12-1 2-13 1-5-4-5-8v-2z"
      fill="#9D3134"
    />
    <path
      transform="translate(400,1049)"
      d="m0 0h6l8 11v3h-16l-8-11v-2z"
      fill="#9D3034"
    />
    <path
      transform="translate(223,1157)"
      d="m0 0h16l7 10-1 3-3 1h-11l-3-3-5-9z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(983,485)"
      d="m0 0 7 1 56 28-4 2-6-2-16-8-17-9-19-9z"
      fill="#97999A"
    />
    <path
      transform="translate(401,1085)"
      d="m0 0h5l8 11v3l-15 1-8-11v-3z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(418,1047)"
      d="m0 0h7l8 10 1 4h-16l-3-3-4-7-2-3z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(228,1084)"
      d="m0 0h10l8 11v3l-17 1-4-7 1-5z"
      fill="#9F3134"
    />
    <path
      transform="translate(204,1141)"
      d="m0 0 5 1 8 11v2l-7 1h-10l-3-6v-8z"
      fill="#9D3134"
    />
    <path
      transform="translate(313,1130)"
      d="m0 0h15l4 5v2l2 1v2h-2v2l-5 2h-1-7l-3-5v-3h-2l-2-5z"
      fill="#8D292E"
    />
    <path
      transform="translate(359,998)"
      d="m0 0h8l12 6 6 5v2l-13 2-5-1-6-9z"
      fill="#9F3134"
    />
    <path
      transform="translate(375,1030)"
      d="m0 0 5 1-2 2-10 1-1 1h-7l6 9 1 4 7 1h12l1-2 22-1-1 2-29 3-2 1-10 1-20-2v-1l17-1-4-7-3-4v-3h-11v-1l10-2z"
      fill="#4B0C0E"
    />
    <path
      transform="translate(330,982)"
      d="m0 0 10 2 12 7 3 5-7 1h-11l-8-13z"
      fill="#9E3134"
    />
    <path
      transform="translate(141,1260)"
      d="m0 0 3 4 8 2 5 1 1-2v4l4 1 4 3-1 4-2-1h-10l-5-2v-2h-8l-3-2 2-6z"
      fill="#5C1922"
    />
    <path
      transform="translate(251,1045)"
      d="m0 0h6l6 9 3 4v2h-18l-3-3 2-6 2-4h2z"
      fill="#9E3638"
    />
    <path
      transform="translate(411,1084)"
      d="m0 0h16l5 8 2 4-6 1-9 1-3-3-1-6h-2v-2h-2z"
      fill="#8D292E"
    />
    <path
      transform="translate(481 1e3)"
      d="m0 0 17 8 18 10 17 9 4 4-2 1-54-27v-3z"
      fill="#A9ABAA"
    />
    <path
      transform="translate(346,1086)"
      d="m0 0h2v2h10v2h-8l-1 2h-18l6 10-2 4-7-11v-2l-6 1h-12l-1 2-1-1h-17v-1l15-2 2-1h19l1-2 8 1v-2h10z"
      fill="#550D11"
    />
    <path
      transform="translate(390,1013)"
      d="m0 0 9 1 12 7 4 5-6 1h-12l-7-10-1-3z"
      fill="#9D3134"
    />
    <path
      transform="translate(349,1054)"
      d="m0 0 4 4 5 10 18-1 18-2 4-1 2 2-2 1-39 4-1 1-20 2-13-2v-1l10-1v-3l4 2h16l-7-11v-3z"
      fill="#540D11"
    />
    <path
      transform="translate(421,1065)"
      d="m0 0h14l4 4 3 5 1 3-3 1h-13l-6-10z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(279,1258)"
      d="m0 0 7 1v1l-23 3-21 2-28 2-12 2-23 2h-9v-1l42-5 21-2h6l1-3 3 1 16-1 2-1 3 1z"
      fill="#430B0C"
    />
    <path
      transform="translate(302,986)"
      d="m0 0 5 1 7 11v2l-7 1h-10l-3-5 2-4z"
      fill="#9E3235"
    />
    <path
      transform="translate(183,1180)"
      d="m0 0h7l3 6-2 1-2-1v2h5l3 4h-5l-1 2-10 1-4-5v-8z"
      fill="#8A272C"
    />
    <path
      transform="translate(189,1161)"
      d="m0 0h10l1 1v5h3l3 5-5 3h-10l-4-6v-6z"
      fill="#8D292E"
    />
    <path
      transform="translate(543,1272)"
      d="m0 0 1 4-7 12-6 9h-2l-2 4-14 14-10 7v-3l5-5 12-11 9-10 8-13 3-6z"
      fill="#EBEBEB"
    />
    <path
      transform="translate(442,1063)"
      d="m0 0h14l3 6v2h2l1 4h-5l-3 1-7-1-6-9-1-2z"
      fill="#8D292E"
    />
    <path
      transform="translate(420,1029)"
      d="m0 0h8l12 7 4 3v2l-4 1h-13l-7-11z"
      fill="#9D3134"
    />
    <path
      transform="translate(323,1118)"
      d="m0 0 2 1v2h2l1 5 34-3 6-1 2 4h-19l-7 2-29 2-6 2-14-1v-1l11-1 1-3h18l-2-5z"
      fill="#520D10"
    />
    <path
      transform="translate(547,1200)"
      d="m0 0h2l2 7v37l-6 21-3 7-2-1 1-5 5-16 2-14v-23z"
      fill="#6A1419"
    />
    <path
      transform="translate(287,1128)"
      d="m0 0 1 2 6 1-1 4 3 3 3 6-1 4h-5l3-1-7-12-17-1 6 10 3 3 11 1v1h-13l-6-6-4-7v-2l-2-1 1-1 19-1z"
      fill="#4E0C0F"
    />
    <path
      transform="translate(209,1123)"
      d="m0 0h10v8l5 5-2 1h-12l-4-7 1-5z"
      fill="#A03235"
    />
    <path
      transform="translate(883,895)"
      d="m0 0 5 1 16 8 9 5 15 6 6 5-3 2-50-25z"
      fill="#9B9D9E"
    />
    <path
      transform="translate(346,1455)"
      d="m0 0 7 1 40 20-3 1-1 2-40-20-1-3z"
      fill="#B3B4B4"
    />
    <path
      transform="translate(449,1044)"
      d="m0 0 9 1 4 2v3h6l7 5v1l-17 1-5-5-4-6z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(396,1441)"
      d="m0 0 5 1 64 32-1 2-6-2-19-9-42-22z"
      fill="#0C1112"
    />
    <path
      transform="translate(257,1035)"
      d="m0 0 1 2-1 4h16l-1 2-3 1h-9l8 13 17 1-5-9-3-6 4 1 7 11 3 2-5 2-20 1-4-6-4-5-1-3-5-1v-1l6-1-3-3z"
      fill="#620F15"
    />
    <path
      transform="translate(236,1174)"
      d="m0 0h13l2 5-4 2 6 1-1 3-2-1-1 2h-3v2l-6-1-4-7v-2h-2l-1-3z"
      fill="#8D292E"
    />
    <path
      transform="translate(475,1122)"
      d="m0 0 7 1 15 8v3l6 4v2l-8-1-17-9-4-2 3-2h4l-6-3z"
      fill="#A9AAA9"
    />
    <path
      transform="translate(471,941)"
      d="m0 0 2 2-8 4-5 2 6-1 10-4 2 1-18 9v2l-5-1-5-4-2-2-4-1v-2l10-1 7-2 4 1z"
      fill="#999"
    />
    <path
      transform="translate(265,1027)"
      d="m0 0 4 2 7 11-1 1h-18l1-6z"
      fill="#9F3235"
    />
    <path
      transform="translate(520,1544)"
      d="m0 0 5 1 2-1 50 25v2l-9-3-7-4-8-2-27-14-6-3z"
      fill="#D8D9D9"
    />
    <path
      transform="translate(485,932)"
      d="m0 0 1 2-1 3h-3l3 2-1 2-6 4-9 3-5 2h-6l4-3 9-4-8 2-2-2 9-5 10-4z"
      fill="#9B9C9D"
    />
    <path
      transform="translate(308,980)"
      d="m0 0 1 2 18-1 2 5-8-1-12 1 7 10 1 4h6l-4 1-1 3-13-1 2-3h7l-8-13-3-2h3z"
      fill="#540D11"
    />
    <path
      transform="translate(579,1494)"
      d="m0 0 6 1 60 30 3 3-9-3-19-10-10-5-9-3-21-10z"
      fill="#9A9B9A"
    />
    <path
      transform="translate(344,1127)"
      d="m0 0 5 3-1 3-3 1 2 8h-7l-9-12v-2z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(362,1107)"
      d="m0 0h16l2 5-5 2 1 3-3 1v2l3 1h-6l-6-8-3-3z"
      fill="#8D292E"
    />
    <path
      transform="translate(267,760)"
      d="m0 0 1 4-2 5-2 4-2 1-2 10-7 13-3-1-1-8 4-4 11-20 3-1z"
      fill="#696F70"
    />
    <path
      transform="translate(878,430)"
      d="m0 0 6 2 27 14 10 5-3 1h-3l-6-2-33-17 2-1z"
      fill="#8C8F90"
    />
    <path
      transform="translate(383,1125)"
      d="m0 0 2 1-12 8-10 8-9 9-11 16-2 2-2-1 2-5 8-11 16-16 11-8z"
      fill="#B0B0AE"
    />
    <path
      transform="translate(470,1060)"
      d="m0 0h6l4 7-1 3-5-2v-2l-3 1-1 2 4 4v1l-7-1-2-4-3-3-2-4 1-1z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(645,1091)"
      d="m0 0 5 1 54 27-1 2-6-2-16-8-19-9-16-9z"
      fill="#252E30"
    />
    <path
      transform="translate(99,1329)"
      d="m0 0 6 2 15 8 19 9-2 1v2l-6-2-16-8-19-10z"
      fill="#ADAFAF"
    />
    <path
      transform="translate(274,1153)"
      d="m0 0 5 1 2 5h-8l2 2 4 1-4 1v4h-5l-7-10v-3z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(387,1104)"
      d="m0 0h9l5 6-2 1h-7l1 5-4 2-9-11v-2z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(39,1222)"
      d="m0 0 6 1 23 11 19 10-2 1-12-4-34-17z"
      fill="#838687"
    />
    <path
      transform="translate(508,935)"
      d="m0 0h3v2l-14 7 3 3-4 1h-2l-10 3-8 4h-5v-2l16-8 15-7z"
      fill="#ADAFAF"
    />
    <path
      transform="translate(322,1147)"
      d="m0 0h16l3 6-12 1 1 1h5l1 4v2h-6l-6-9z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(504,1317)"
      d="m0 0m-2 1 2 1-2 4-12 7-18 7-8 1v-4l17-5 11-5z"
      fill="#E0E0E0"
    />
    <path
      transform="translate(539,1270)"
      d="m0 0 3 3-8 14-7 9-9 10-8 7-2-1 13-14 10-13 5-9z"
      fill="#702226"
    />
    <path
      transform="translate(413,960)"
      d="m0 0 9 2-4 2-1 1 6 3-3 1-7-3v2l16 8-3 1-6-3h-6l-15-8-1-2 8 2v-4l6 1z"
      fill="#8E8F8E"
    />
    <path
      transform="translate(327,1160)"
      d="m0 0 2 1 12 1v1l-10 2-19 2-2 1h-9l-30 3h-10l2-2h5l1-2 4 1 15-1 1-1 19-1 4-1 13-1 2-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(425,1080)"
      d="m0 0h12v1l-11 2-26 2-9 1-4 1-7 1-12 1-5-1 3-1 1-3h19l19-2 2-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(513,1306)"
      d="m0 0m-2 1 2 1-4 5-5 5-13 8-19 7h-5v-2l9-3 16-7 11-7z"
      fill="#772125"
    />
    <path
      transform="translate(284,1151)"
      d="m0 0h14l3 5-8 2 3 3 1 4h-5v-2h-3l-7-11z"
      fill="#8D292E"
    />
    <path
      transform="translate(289,1059)"
      d="m0 0 2 1 6 11 1 2 6 1v1l-17 2h-11l-8-12v-2h2v-2l3 1h-2l7 11 2 2h15l-4-7-4-6-9-1v-1h10z"
      fill="#640F15"
    />
    <path
      transform="translate(650,1349)"
      d="m0 0v3l-5 9h-7l-1 2-5-9v-2l14-2z"
      fill="#5A1820"
    />
    <path
      transform="translate(434,951)"
      d="m0 0h5l9 3 8 4-3 1-4-2h-5l10 5-3 1-8-4-3 1 9 5-2 1-12-6v-2h-3v-2l-4-2 3-1h3z"
      fill="#999898"
    />
    <path
      transform="translate(268,1022)"
      d="m0 0 3 2h-2l9 14h20l4 2-13 2-8 1h-9l3-4-8-11-3-3 1-2z"
      fill="#490B0D"
    />
    <path
      transform="translate(425,1110)"
      d="m0 0h7l-3 3-18 3-15 5-11 5-4-1 16-8 16-5z"
      fill="#A4A3A1"
    />
    <path
      transform="translate(303,682)"
      d="m0 0 2 1-9 19-3 7-12 23-8 13-1-3 9-16z"
      fill="#3D4648"
    />
    <path
      transform="translate(1096,542)"
      d="m0 0h6l18 10 6 7 2 4v8l-6-12-5-4-16-8z"
      fill="#9EA0A0"
    />
    <path
      transform="translate(464,1475)"
      d="m0 0 5 1 21 11 26 13v2l-6-2-10-5-21-10-15-8z"
      fill="#0E1314"
    />
    <path
      transform="translate(235,1226)"
      d="m0 0h14v1l-46 5-1 1-19 1-1 3v-2h-4v-2l9-2h13l1-2 19-1 1-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(386,599)"
      d="m0 0 1 3-8 15-4 9-4 11 6 2 3 3-2 1-11-5 2-7 4-8 10-21z"
      fill="#948E89"
    />
    <path
      transform="translate(636,77)"
      d="m0 0 5 3 3 2v2l5 1 13 5v2l3-1v2h5l5 3 2 4-2 1-40-20z"
      fill="#6B7172"
    />
    <path
      transform="translate(504,1019)"
      d="m0 0 19 9 19 10 13 6-1 2-6-2-26-13-17-9-3-2z"
      fill="#343D3F"
    />
    <path
      transform="translate(304,1074)"
      d="m0 0h9v2h-13l-1 3h-12l-6 1-2 1-18-1 3-3 23-1z"
      fill="#520D11"
    />
    <path
      transform="translate(402,1254)"
      d="m0 0 10 4 2 1-1 6-1 2h-8l-4-4v-3z"
      fill="#9F9C99"
    />
    <path
      transform="translate(200,1157)"
      d="m0 0h13v1h-10l1 5 3 3 1 9-1 1h-16v-1l10-1 4-3-2-4h-3l-1-5-10-1v-2h6z"
      fill="#430D10"
    />
    <path
      transform="translate(461,1332)"
      d="m0 0h6v2l-15 3-6 1h-12l-7-2 1-2 29-1z"
      fill="#722A2E"
    />
    <path
      transform="translate(271,1152)"
      d="m0 0h7l-4 2h-11l6 11 5 2-1 2-4-1-6 2h-14l1-2h11l5-1-4-5-2-2-1-4-13-1v-1z"
      fill="#641016"
    />
    <path
      transform="translate(335,1265)"
      d="m0 0 4 3 7 14 10 14 3 3v2h-4l-11-15-8-16z"
      fill="#501316"
    />
    <path
      transform="translate(553,1239)"
      d="m0 0h1l-1 11-5 17-3 7-3-1 1-5 6-20 3-8z"
      fill="#DBDBDA"
    />
    <path
      transform="translate(606,914)"
      d="m0 0 5 1 3 3-1 3 7 3 2 3-1 4-7-4-14-9 4-2h2z"
      fill="#696F70"
    />
    <path
      transform="translate(205,1126)"
      d="m0 0 5 10v2h13l-1 2h-10v4l-4-2h-10l3-2 2-7z"
      fill="#490D0F"
    />
    <path
      transform="translate(298,1218)"
      d="m0 0 2 1 22-1-1 3-19 1-19 2-1 1-28 1v-1l22-3 5-1h17z"
      fill="#430B0C"
    />
    <path
      transform="translate(555,274)"
      d="m0 0h14l18 8 2 4-8-2-14-6-9-1h-7z"
      fill="#868887"
    />
    <path
      transform="translate(218,1121)"
      d="m0 0 4 2 2 4 4 10v2l-18-1v-1l12-1 1-1-5-4 1-8-10 1-3 2 1-4z"
      fill="#631016"
    />
    <path
      transform="translate(914,450)"
      d="m0 0 11 3 22 11-4 2-6-2-22-11z"
      fill="#919393"
    />
    <path
      transform="translate(543,297)"
      d="m0 0 3 1-5 2-5 10h-2l-1 4-7 12-5 9h-2l-2 5-1-2 10-18 4-8 7-9 2-5z"
      fill="#928D88"
    />
    <path
      transform="translate(668,1312)"
      d="m0 0 1 2-8 16h-1v-6h-3l-2-5 5-4 1-2z"
      fill="#5D1922"
    />
    <path
      transform="translate(300,1076)"
      d="m0 0h8v1h-7l7 12 1 1 16-1 2-4 2 3 7 1v1h-8l-1 1-10 1h-10l-1 1-17 1v-1l16-2-7-11z"
      fill="#5E0F14"
    />
    <path
      transform="translate(519,935)"
      d="m0 0h2l-1 3h-3v2l-5 2-3 3-12 6h-2l-1-4 2-1v2l3-2-3-2 9-3z"
      fill="#A9ABAB"
    />
    <path
      transform="translate(179,1192)"
      d="m0 0 4 2h16l1-2 12 1v2l-9 1 5 9-1 2-6-8-1-2h-18l4 4-1 3-5-5z"
      fill="#430B0C"
    />
    <path
      transform="translate(441,1048)"
      d="m0 0 9 5 4 4-1 2h-11l-3-3h-2l-1-4 4-2z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(599,1295)"
      d="m0 0 6 1 1 2 7 2-4 2-6 1 4 1 2 5-2 2-2-4h-3l-1-2-4-1v-3l-5 1v-3h6z"
      fill="#5D1922"
    />
    <path
      transform="translate(402,1199)"
      d="m0 0 5 2 11 5v2l6 1 4 2h10l4-2 1 3-4 2-10 1-4 1-1-4-10-4-11-6z"
      fill="#72292D"
    />
    <path
      transform="translate(334,1110)"
      d="m0 0 5 2 2 5-16 1-3-4v-3z"
      fill="#9B2F33"
    />
    <path
      transform="translate(323,963)"
      d="m0 0 2 1-3 2zm-2 3v3l-2 4-11 8-2 4-5 3-7 7-1 2-4 1v-2h2l2-4 8-7 6-7 7-6 5-5z"
      fill="#0A0A0B"
    />
    <path
      transform="translate(637,1370)"
      d="m0 0 3 1-5 12-2 2-8-9v-2l6-2v-1z"
      fill="#5D1A23"
    />
    <path
      transform="translate(231,1145)"
      d="m0 0h2l4 6v2l-16 1-4-4 2-2 8-1z"
      fill="#9E3134"
    />
    <path
      transform="translate(796,851)"
      d="m0 0 6 2 26 13 3 3-3 1-34-17z"
      fill="#969899"
    />
    <path
      transform="translate(850,418)"
      d="m0 0h6l22 11v3l-6-1-22-11z"
      fill="#919394"
    />
    <path
      transform="translate(167,1203)"
      d="m0 0 4 1 1 2-2 1h15l2 4v1l-16 1-4-6z"
      fill="#8D292E"
    />
    <path
      transform="translate(379,1068)"
      d="m0 0h9v1h-8l7 11 2 2 6 1v1l-9 1h-19l-1-2h18l-3-5v-2h-2l-2-5-7-1v-1z"
      fill="#651016"
    />
    <path
      transform="translate(703,1222)"
      d="m0 0h1l-1 11-3 11h-2v7l-3 6-1 5h-2l1-9 3-9v-8l3 1 1-8 1-3h2z"
      fill="#3E373B"
    />
    <path
      transform="translate(187,1161)"
      d="m0 0 2 1-1 1v6l3 5v2h8v2l-15 2-5 1 3-4 2-5 2-10z"
      fill="#4D0F12"
    />
    <path
      transform="translate(336,958)"
      d="m0 0v3l-9 6-2 1 1 4 6 5v2l4 2h-5l-3-3-11-4 2-1 1-4 11-8z"
      fill="#440B0C"
    />
    <path
      transform="translate(825,405)"
      d="m0 0 7 1 22 11-4 2-6-2-19-10z"
      fill="#8B8E8E"
    />
    <path
      transform="translate(377,1077)"
      d="m0 0 5 1 3 5-1 1h-16l-2-3 1-3h8z"
      fill="#9E3134"
    />
    <path
      transform="translate(580,1302)"
      d="m0 0 1 2h2v4l6 2-1 4 1 2 8 1v1l-4 1h14v1l-12 1-2 2-1 2-2-3h-8l-3-3h7l-1-6-3-4v-2l-3-1z"
      fill="#5D1A23"
    />
    <path
      transform="translate(206,1211)"
      d="m0 0m-16 1h16v2l-13 1-2 1h-17l1 5 5 8-1 2-10-14 2-3z"
      fill="#3F0B0C"
    />
    <path
      transform="translate(254,1109)"
      d="m0 0 3 1 2 4 13 1v1l-9 1-2 1h-20v2l-4-2 8-3 7-1z"
      fill="#4D0D10"
    />
    <path
      transform="translate(637,1109)"
      d="m0 0 4 1 6 2 12 5 11 6 1 3 3 1-2 1-34-17z"
      fill="#B3B4B2"
    />
    <path
      transform="translate(452,1080)"
      d="m0 0h14l3 4-1 2h-7l-1 2-6-2-3-3z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(362,941)"
      d="m0 0 3 1 3 7 8 4 3 3-1 2-22-11 2-4z"
      fill="#303A3B"
    />
    <path
      transform="translate(91,1286)"
      d="m0 0 4 1 17 9 16 8 2 2-4 1-15-8-11-6-9-6z"
      fill="#141A1B"
    />
    <path
      transform="translate(705,1145)"
      d="m0 0h3l1 3v24h-1l-1-6-5-5 2-5-1-2 3-6z"
      fill="#5C0F13"
    />
    <path
      transform="translate(204,1147)"
      d="m0 0h9l4 6v2l-13 1 1-4h-2l-1-4z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(358,1089)"
      d="m0 0h9l3 4-1 2h-8l-1 1h-5l-5-5v-1z"
      fill="#9D3134"
    />
    <path
      transform="translate(277,1012)"
      d="m0 0h3l5 7v3l-13 1-2-2 3-5z"
      fill="#9D3336"
    />
    <path
      transform="translate(1076,530)"
      d="m0 0 6 2 18 9-3 3-6-2-16-8 2-1z"
      fill="#8D8F90"
    />
    <path
      transform="translate(700,1243)"
      d="m0 0 1 2v11l-6 12-3-1v-5h2l1-8 2-3 1-7z"
      fill="#070C0D"
    />
    <path
      transform="translate(285,1114)"
      d="m0 0h16l7 10-2 6-2 2h-10l-6-2-1-4 6 3 13-1-7-11v-2h-14z"
      fill="#630F15"
    />
    <path
      transform="translate(245,1053)"
      d="m0 0 1 2-1 2 3 1v2h16l4 3h-18v2l-11 1-2 1 1-3h11v-2h-9l2-4z"
      fill="#4D171B"
    />
    <path
      transform="translate(554,1045)"
      d="m0 0 5 1 15 8 21 10-1 2-6-2-34-17z"
      fill="#2E383A"
    />
    <path
      transform="translate(449,1507)"
      d="m0 0 7 1 20 10 1 2-5 1-17-9-6-3z"
      fill="#BCBEBE"
    />
    <path
      transform="translate(340,1118)"
      d="m0 0 4 2 1 4-15 1-4-4v-2z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(270,1097)"
      d="m0 0h6v1h-5l8 13h10l9-1v2l-26 3h-13l-2-5 3 3h16l-7-11z"
      fill="#651016"
    />
    <path
      transform="translate(650,1588)"
      d="m0 0h2l-2 4-7 5-6 2h-9l-7-3 2-3 8 2 10-1 8-5z"
      fill="#BEC0C0"
    />
    <path
      transform="translate(272,1134)"
      d="m0 0h17l1 4-7 2h-9z"
      fill="#9F3134"
    />
    <path
      transform="translate(1020,502)"
      d="m0 0 5 2 21 10-4 2-6-2-16-8-2-2h2z"
      fill="#8F9293"
    />
    <path
      transform="translate(599,1326)"
      d="m0 0 15 2 3 3v2h-17l-2-5z"
      fill="#651016"
    />
    <path
      transform="translate(592,288)"
      d="m0 0 5 1 21 11 13 6v2l-5-1-19-9-15-8z"
      fill="#929292"
    />
    <path
      transform="translate(706,1190)"
      d="m0 0h1v10l-3 22-1 4h-2v-14z"
      fill="#370A0B"
    />
    <path
      transform="translate(607,1509)"
      d="m0 0 5 1 16 8 15 8 6 1v4l-6-2-29-15-7-4z"
      fill="#797D7E"
    />
    <path
      transform="translate(552,1209)"
      d="m0 0h2l1 2v26l-3 3z"
      fill="#E6E6E6"
    />
    <path
      transform="translate(548,1192)"
      d="m0 0 3 1 3 11v5h-2v31h-1l-1-33-2-9z"
      fill="#C6C5C3"
    />
    <path
      transform="translate(430,1168)"
      d="m0 0h1l-1 8-3 6-1 10 4 2 2 2-3 1-4-2-2-3v-10h2l2-10z"
      fill="#893639"
    />
    <path
      transform="translate(237,1150)"
      d="m0 0 3 1v2h6v2h-5l-1 2h-26v-1h5l1-2 3 1v-2h14z"
      fill="#490B0D"
    />
    <path
      transform="translate(303,1037)"
      d="m0 0h11v1h7l1 4 5 8-1 4h-6l4-2-7-11-15-1-4-2z"
      fill="#600F14"
    />
    <path
      transform="translate(337,950)"
      d="m0 0 2 1-2 3-3 1-2 3-3 1-3 4-3 1-10 10-6 5-2-1 9-9 1-2 5-3 3-4 4-2 1-2 4-2 1-2z"
      fill="#535A5C"
    />
    <path
      transform="translate(189,1161)"
      d="m0 0h10l1 1v5h-5l-7 3-1-1v-6z"
      fill="#9D3034"
    />
    <path
      transform="translate(267,1094)"
      d="m0 0 8 1h10l-4 2-3 1-8-1v3l-18-1 1-3h14z"
      fill="#490B0D"
    />
    <path
      transform="translate(883,895)"
      d="m0 0 5 1 16 8 5 3-4 2-24-12z"
      fill="#979A9B"
    />
    <path
      transform="translate(14,1265)"
      d="m0 0 1 4 2 12 3 6 9 6 3 3-3 1-9-6-6-8-1-4v-11z"
      fill="#D1D2D1"
    />
    <path
      transform="translate(189,1197)"
      d="m0 0h8l4 3-1 3h-14l-2-5z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(415,973)"
      d="m0 0 7 1 13 5 10 5-3 1-5-2-4 1-18-10z"
      fill="#A2A3A3"
    />
    <path
      transform="translate(356,992)"
      d="m0 0 5 2 1 3h-3v2l-20 2-3 1v-2l-2-1 2-1 19-1z"
      fill="#450B0C"
    />
    <path
      transform="translate(273,1170)"
      d="m0 0h16l-1 5-6 1-7-1-2-2z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(302,1113)"
      d="m0 0h16l1 5-10 1-7-3z"
      fill="#9F3134"
    />
    <path
      transform="translate(999,829)"
      d="m0 0 1 3-8 16-12 22-1-3 6-12 10-19z"
      fill="#948E89"
    />
    <path
      transform="translate(203,1159)"
      d="m0 0h17v4l-4 1h-12l-2-4z"
      fill="#9E3134"
    />
    <path
      transform="translate(282,1115)"
      d="m0 0h17l2 5h-14l-6-3z"
      fill="#9E3134"
    />
    <path
      transform="translate(491,938)"
      d="m0 0h5l-3 2h-2v2l-5 1v2l-11 5-4 2h-6l1-2 18-8z"
      fill="#AEB0AF"
    />
    <path
      transform="translate(539,1175)"
      d="m0 0h2l6 14 2 11h-3l-8-24z"
      fill="#731D22"
    />
    <path
      transform="translate(251,1136)"
      d="m0 0 4 5 4 6-1 5-3 1h-15v-1l17-1-7-10-1-2-14-1v-1h15z"
      fill="#600F14"
    />
    <path
      transform="translate(418,1047)"
      d="m0 0h7l4 5v3l-4-2-3-1v-2l-6 3h-4l-3-5z"
      fill="#9E3134"
    />
    <path
      transform="translate(135,1309)"
      d="m0 0 7 2 23 12 2 3-6-2-16-7-10-6z"
      fill="#101516"
    />
    <path
      transform="translate(430,1082)"
      d="m0 0h16l2 4-10 2h-5z"
      fill="#8F2A2F"
    />
    <path
      transform="translate(425,1496)"
      d="m0 0 7 2 2-1 18 9-3 2-9-3-15-8z"
      fill="#AEB0B0"
    />
    <path
      transform="translate(682,1276)"
      d="m0 0h2l-1 8-3 7-2-3-2-5-3-2v-2z"
      fill="#5B1821"
    />
    <path
      transform="translate(396,1073)"
      d="m0 0h4l4 6-1 3-11 1 2-5-3-3z"
      fill="#902A2F"
    />
    <path
      transform="translate(354,1420)"
      d="m0 0 9 2 8 5 24 12v2l-4-1-11-6-21-10-5-3z"
      fill="#121719"
    />
    <path
      transform="translate(287,1057)"
      d="m0 0h14v1l-5 1h-7l-1 2h-18v2l-16-1v-1l13-2 19-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(557,1563)"
      d="m0 0 5 1 13 6 6 1 8 4-2 3-6-2-24-12z"
      fill="#B4B7B6"
    />
    <path
      transform="translate(224,1088)"
      d="m0 0 5 10v4h-10l1-5 3-5z"
      fill="#420D0F"
    />
    <path
      transform="translate(397,1327)"
      d="m0 0 25 6 6 1 2 3-10-1-23-6z"
      fill="#6A1318"
    />
    <path
      transform="translate(233,1138)"
      d="m0 0h16l2 4-16 1-2-2z"
      fill="#9F3134"
    />
    <path
      transform="translate(302,1094)"
      d="m0 0h6 3l7 12-1 4-4 1h-14l-2-4 3 2h16l-8-12-6-2z"
      fill="#600F14"
    />
    <path
      transform="translate(605,1091)"
      d="m0 0 12 5 11 6 4 2 1 3-7-2-19-10-4-3z"
      fill="#B7B8B7"
    />
    <path
      transform="translate(375,1030)"
      d="m0 0 5 1-2 2-10 1-1 1h-8l-1 2-1-1-11-1v-1l10-2z"
      fill="#460B0C"
    />
    <path
      transform="translate(316,974)"
      d="m0 0 5 1 1 4h2v2l-15 1 1-4z"
      fill="#9B393C"
    />
    <path
      transform="translate(260,1172)"
      d="m0 0h9l2 5h-15l-4-4z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(1066,205)"
      d="m0 0 6 1 11 6 2 1-1 2-3 1-15-7z"
      fill="#898785"
    />
    <path
      transform="translate(231,1183)"
      d="m0 0 1 2 2 1-3 4h-9l-4-5 2-1h10z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(341,1109)"
      d="m0 0h16l1 4-7 1-9-1z"
      fill="#9D3034"
    />
    <path
      transform="translate(376,1103)"
      d="m0 0h9v1l-8 2-13 1-4 1h-16v-1l13-1 1-2z"
      fill="#430B0C"
    />
    <path
      transform="translate(967,475)"
      d="m0 0 6 2 13 7-3 1-1 2-17-9 2-1z"
      fill="#8C9091"
    />
    <path
      transform="translate(808,395)"
      d="m0 0 6 2 14 7-3 1-1 2-16-8z"
      fill="#898A8A"
    />
    <path
      transform="translate(685,334)"
      d="m0 0 6 2 18 9v2l-6-1-18-9z"
      fill="#949595"
    />
    <path
      transform="translate(599,1065)"
      d="m0 0 5 1 21 10-3 2-6-2-17-9z"
      fill="#A8AAAB"
    />
    <path
      transform="translate(293,995)"
      d="m0 0 3 3 1 3-4-1v-2l-6 4 6 1-1 2-11 2v4h-3l2-5 8-8 5-1z"
      fill="#330B0D"
    />
    <path
      transform="translate(291,998)"
      d="m0 0h2l1 2 12 1 2 3-16 1v-1l-6-1z"
      fill="#662D2F"
    />
    <path
      transform="translate(31,1295)"
      d="m0 0 6 2 14 7v3l-5-1-16-8z"
      fill="#C8C9C8"
    />
    <path
      transform="translate(242,1155)"
      d="m0 0h16l-2 4-3 1h-9z"
      fill="#9E3134"
    />
    <path
      transform="translate(302,1149)"
      d="m0 0h15v2h2v2l-6-1-1 2-1 1-5 1-4-5z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(251,1191)"
      d="m0 0h7l1 4-7 2-7-1-1-4z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(792,387)"
      d="m0 0 6 2 10 5v3l2 1-6-1-13-7z"
      fill="#8C8E8E"
    />
    <path
      transform="translate(587,283)"
      d="m0 0 5 2 19 10 9 4-4 1-21-11-7-1-3-3h3z"
      fill="#787C7C"
    />
    <path
      transform="translate(430,1196)"
      d="m0 0 11 2 10 3 1 2h-6l-1 6-3 2 1-10-10-2z"
      fill="#671419"
    />
    <path
      transform="translate(447,1096)"
      d="m0 0h10v1l-16 2-4 1-17 1-3 1-6-1v-1h5l1-2h9z"
      fill="#430B0C"
    />
    <path
      transform="translate(193,1178)"
      d="m0 0h14l2 1-1 3-7 1h-5l-3-2z"
      fill="#8F2A2F"
    />
    <path
      transform="translate(299,1040)"
      d="m0 0 2 1 2 5 5 7 9 1v1l-14 1 2-1-8-12-8-1v-1z"
      fill="#640F15"
    />
    <path
      transform="translate(345,1014)"
      d="m0 0 4 2v2l-20 2-2 1-12-1v-1l11-2 19-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(298 1e3)"
      d="m0 0 4 4 5 8v2h15v1l-19 1 1-2-8-12z"
      fill="#651016"
    />
    <path
      transform="translate(901,442)"
      d="m0 0 6 2 14 7-3 1h-3l-6-2-10-5 2-1z"
      fill="#868888"
    />
    <path
      transform="translate(754,43)"
      d="m0 0 6 2 28 14v2l-7-2-12-7-15-7z"
      fill="#656A6A"
    />
    <path
      transform="translate(405,1114)"
      d="m0 0 2 1-2 3-13 5-7 3-4-1 16-8z"
      fill="#999490"
    />
    <path
      transform="translate(319,1402)"
      d="m0 0 5 1 28 14v1h-5l-19-10-9-5z"
      fill="#101517"
    />
    <path
      transform="translate(383,1125)"
      d="m0 0 2 1-12 8-10 8-2-2 9-8 9-6z"
      fill="#AEAEAC"
    />
    <path
      transform="translate(367,1122)"
      d="m0 0 3 2-1 2h-18l-5 1h-15v-1l16-2 15-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(692,1255)"
      d="m0 0 1 4-2 6-4 2v-5l-4-2-1-3z"
      fill="#400F10"
    />
    <path
      transform="translate(453,1175)"
      d="m0 0h5l-4 3 2 2h-5v2h-2l-1 5-4-2-1-4 9-5z"
      fill="#71262A"
    />
    <path
      transform="translate(214,1177)"
      d="m0 0h13l2 4-6 1h-6l-3-2z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(396,1064)"
      d="m0 0 4 2-2 1-28 3h-11l4-2 31-3z"
      fill="#430B0C"
    />
    <path
      transform="translate(437,982)"
      d="m0 0 22 11v2l-5-1-19-10z"
      fill="#B5B7B7"
    />
    <path
      transform="translate(234,1070)"
      d="m0 0 2 3 3 6v2l-8-1v2l-4-1 5-7h2z"
      fill="#3C1518"
    />
    <path
      transform="translate(396,1441)"
      d="m0 0 5 1 22 11-1 2-6-2-19-10z"
      fill="#0D1214"
    />
    <path
      transform="translate(80,1320)"
      d="m0 0 5 1 14 7v2l-7-1-10-5z"
      fill="#CFD0D0"
    />
    <path
      transform="translate(183,1180)"
      d="m0 0h7v4l-8 3-4-1v-5z"
      fill="#9E3134"
    />
    <path
      transform="translate(304,1131)"
      d="m0 0h3v5l-7 1-7-1v-4z"
      fill="#9E3134"
    />
    <path
      transform="translate(250,1097)"
      d="m0 0 9 2v1h-7l4 7v3h-3l-5-8-10-1v-1h12z"
      fill="#580E12"
    />
    <path
      transform="translate(631,307)"
      d="m0 0 5 2 17 8v3l-6-2-17-9z"
      fill="#8C8C8B"
    />
    <path
      transform="translate(304,1168)"
      d="m0 0 4 1 1 3-1 2h-10l-1-4-3-1z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(432,1110)"
      d="m0 0h22l6 2v1l-29-1z"
      fill="#A6A6A4"
    />
    <path
      transform="translate(258,1149)"
      d="m0 0 1 2 21-1v2l-18 2h-16v-1l12-1z"
      fill="#350A0B"
    />
    <path
      transform="translate(371,1088)"
      d="m0 0h13v3l-9 3-4-3z"
      fill="#9D3134"
    />
    <path
      transform="translate(257,1035)"
      d="m0 0 1 2-1 4h16l-1 2-3 1h-10v2l-7-1v-1l6-1-3-3z"
      fill="#550F13"
    />
    <path
      transform="translate(137,1349)"
      d="m0 0 5 1 16 8 1 2-11-3-10-5z"
      fill="#CFD0D0"
    />
    <path
      transform="translate(412,1176)"
      d="m0 0 1 3-7 9-3 5v6h-2l-1-6 4-8 6-8z"
      fill="#761F24"
    />
    <path
      transform="translate(241,1118)"
      d="m0 0h9v1h-8l5 8 1 8h-5l3-2-7-11v-2h2z"
      fill="#580E13"
    />
    <path
      transform="translate(459,1335)"
      d="m0 0h6l-2 4-14 1-3-3z"
      fill="#D3D3D2"
    />
    <path
      transform="translate(325,1202)"
      d="m0 0 3 1-1 32h-1l-1-21-1-1z"
      fill="#B1B1AF"
    />
    <path
      transform="translate(248,1133)"
      d="m0 0 17 1-1 2h-13l-1 2-12-1 2-2h8z"
      fill="#4A0C0E"
    />
    <path
      transform="translate(634,1082)"
      d="m0 0 11 4 9 5-4 2-18-9z"
      fill="#AAABAB"
    />
    <path
      transform="translate(308,1022)"
      d="m0 0 5 4 5 8 13 1v1h-14-3l-1-4-3-3-3-6z"
      fill="#651016"
    />
    <path
      transform="translate(1096,542)"
      d="m0 0h6l11 6-2 3-6-2-8-5z"
      fill="#969899"
    />
    <path
      transform="translate(464,1475)"
      d="m0 0 5 1 16 8-1 2-5-1-15-8z"
      fill="#12191A"
    />
    <path
      transform="translate(79,1242)"
      d="m0 0 11 3 4 2v3l4 1 4 2-1 2-22-11z"
      fill="#737879"
    />
    <path
      transform="translate(271,1134)"
      d="m0 0 3 4 7 9 11 1v1h-13l-6-6-4-7z"
      fill="#630F15"
    />
    <path
      transform="translate(304,1074)"
      d="m0 0h9v2h-13l-1 3-14-1 2-2z"
      fill="#480B0D"
    />
    <path
      transform="translate(649,1531)"
      d="m0 0 7 1v2l5 1 14 7v3l-26-13z"
      fill="#7A7D7E"
    />
    <path
      transform="translate(929,457)"
      d="m0 0 10 3 8 4-4 2-6-2-8-4z"
      fill="#8E9090"
    />
    <path
      transform="translate(1169,254)"
      d="m0 0 5 1 22 11v2l-9-3-18-10z"
      fill="#8C8986"
    />
    <path
      transform="translate(651,1302)"
      d="m0 0h12l-1 3-2 1v4h-4v-2h-2l-3-4z"
      fill="#5F161E"
    />
    <path
      transform="translate(547,1200)"
      d="m0 0h2l2 7v18h-1l-3-18z"
      fill="#641016"
    />
    <path
      transform="translate(291,1132)"
      d="m0 0 2 1 2 4 4 7-1 4h-5l3-1-6-10z"
      fill="#5F0F14"
    />
    <path
      transform="translate(346,1086)"
      d="m0 0h2v2h10v2h-8l-1 2-13-1 1-2 8-1z"
      fill="#480C0E"
    />
    <path
      transform="translate(366,1084)"
      d="m0 0 18 1-4 3-12 1-5-1 3-1z"
      fill="#480B0D"
    />
    <path
      transform="translate(467,996)"
      d="m0 0 14 7 2 4-6-1-5-3v-3h-3v-2h-2z"
      fill="#B1B2B2"
    />
    <path
      transform="translate(623,1358)"
      d="m0 0 5 2 4 1 2-2 2 4-5 2-9-1z"
      fill="#5D1922"
    />
    <path
      transform="translate(539,1270)"
      d="m0 0 3 3-8 14-4 1 2-5 6-11z"
      fill="#6F2126"
    />
    <path
      transform="translate(214,1109)"
      d="m0 0 3 4 2 3-1 3h-7l-1-4z"
      fill="#460C0E"
    />
    <path
      transform="translate(402,1055)"
      d="m0 0 5 1 1 3 4 2 2 2h-11l-1-3 3-1-3-1z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(268,1022)"
      d="m0 0 3 2h-2l8 13-2 2-8-11-3-3 1-2z"
      fill="#420D10"
    />
    <path
      transform="translate(308,980)"
      d="m0 0 1 2 18-1 2 5-3-1-20-1z"
      fill="#470C0D"
    />
    <path
      transform="translate(976,156)"
      d="m0 0 9 3 18 10-2 1-25-12z"
      fill="#928D89"
    />
    <path
      transform="translate(588,1576)"
      d="m0 0 9 3 5 3-1 2-2 1-12-6z"
      fill="#C0C2C1"
    />
    <path
      transform="translate(685,1283)"
      d="m0 0h2v9l-5 5h-2l1-5z"
      fill="#040809"
    />
    <path
      transform="translate(277,1146)"
      d="m0 0 2 1v2h11v1l-9 1-1 3-2-2h2v-2l-2 1h-19l1-2h17z"
      fill="#500C0F"
    />
    <path
      transform="translate(330,1073)"
      d="m0 0h11l6 11-2 4-4-7-4-6-7-1z"
      fill="#5D0E13"
    />
    <path
      transform="translate(286,719)"
      d="m0 0 1 2-13 23-2 1 1-5 8-14 4-6z"
      fill="#363E40"
    />
    <path
      transform="translate(1020,178)"
      d="m0 0 6 1 5 3 1 4 4 2-6-1-9-6z"
      fill="#717778"
    />
    <path
      transform="translate(554,1559)"
      d="m0 0 4 1 19 9v2l-9-3-7-4-7-3z"
      fill="#E1E1E1"
    />
    <path
      transform="translate(340,1167)"
      d="m0 0 1 4-5 10-2 3-2-1 3-9 3-6z"
      fill="#B8B9B7"
    />
    <path
      transform="translate(296,1058)"
      d="m0 0h12l3 1 6 9-1 5-2-3-7-10-11-1z"
      fill="#5D0E13"
    />
    <path
      transform="translate(372,628)"
      d="m0 0 1 3-2 6 6 2 3 3-2 1-11-5 2-7z"
      fill="#908B87"
    />
    <path
      transform="translate(204,1164)"
      d="m0 0 3 2 1 9-1 1h-16v-1l10-1 4-3-2-4h2z"
      fill="#611117"
    />
    <path
      transform="translate(252,1136)"
      d="m0 0h14l-2 4-7 1-5-3z"
      fill="#A03235"
    />
    <path
      transform="translate(311,1130)"
      d="m0 0 2 1 1 5h2l1 7-3-1-7-11z"
      fill="#600F14"
    />
    <path
      transform="translate(219,1117)"
      d="m0 0 18 1 2 2-9 1h-11z"
      fill="#530E12"
    />
    <path
      transform="translate(262,765)"
      d="m0 0 1 2-11 20-2 1 2-6 8-16z"
      fill="#364042"
    />
    <path transform="translate(555,274)" d="m0 0h14l3 3h-21z" fill="#848584" />
    <path
      transform="translate(510,1537)"
      d="m0 0 5 1 10 5 1 3-10-3-6-3z"
      fill="#D6D7D7"
    />
    <path
      transform="translate(641,1317)"
      d="m0 0h2v3l3 1h8l1 4h-10l-2-1v-2h-2z"
      fill="#5D1922"
    />
    <path
      transform="translate(112,1270)"
      d="m0 0h2v3l3-1 8 6v3l-5-1-7-6z"
      fill="#101516"
    />
    <path
      transform="translate(479,1118)"
      d="m0 0 8 1 8 4 1 4-5-2-12-5z"
      fill="#B1B1B0"
    />
    <path
      transform="translate(320,1111)"
      d="m0 0h2l4 7-1 3-1-2h-3l-3-5-15-1v-1z"
      fill="#640F15"
    />
    <path
      transform="translate(574,1075)"
      d="m0 0 2 1v2l5 1 5 2v2l6 1v2l6 2 1 2-6-2-20-10z"
      fill="#AFAFAD"
    />
    <path
      transform="translate(277,1043)"
      d="m0 0 4 1 7 11 3 2-3 1-5-4-6-10z"
      fill="#600F14"
    />
    <path
      transform="translate(329,1019)"
      d="m0 0 3 4 3 5-1 2-4-2-4-6-11-1v-1h13z"
      fill="#630F15"
    />
    <path
      transform="translate(628,1082)"
      d="m0 0 6 2 12 6v3l-5-2-14-7z"
      fill="#323A3B"
    />
    <path
      transform="translate(315,999)"
      d="m0 0 8 1-4 1-1 3-13-1 2-3z"
      fill="#470B0E"
    />
    <path
      transform="translate(467,965)"
      d="m0 0 10 1-4 3-20 9-2-1 20-10z"
      fill="#AAACAC"
    />
    <path
      transform="translate(633,961)"
      d="m0 0 5 1 7 8-4 1-4-3v-2h-2z"
      fill="#8E8A86"
    />
    <path
      transform="translate(423,960)"
      d="m0 0h5l-2 2 2 4-7 2-5-3 2-2h4z"
      fill="#9C9D9C"
    />
    <path
      transform="translate(546,1253)"
      d="m0 0h1l-1 9-4 10-2-1 1-5 4-12z"
      fill="#570E12"
    />
    <path
      transform="translate(231,1138)"
      d="m0 0h2l6 11-1 2-3-1-6-9 1-2z"
      fill="#5C0E13"
    />
    <path
      transform="translate(409,1133)"
      d="m0 0 7 4-4 2-2 4-3 1-1-6z"
      fill="#97938E"
    />
    <path
      transform="translate(347,1091)"
      d="m0 0 5 1 5 10v4h-6l4-2-8-12z"
      fill="#620F15"
    />
    <path
      transform="translate(553,1356)"
      d="m0 0h7l2 2-3 2-11 1v-4z"
      fill="#5D1A23"
    />
    <path
      transform="translate(113,1298)"
      d="m0 0 9 3 8 4-1 2-5-1-11-6z"
      fill="#0B1011"
    />
    <path
      transform="translate(698,1240)"
      d="m0 0 1 4-2 9-3 9h-2l1-9z"
      fill="#52595B"
    />
    <path
      transform="translate(177,1229)"
      d="m0 0 3 2v2l-4 1-21 1v-2l8-1 11 1 1-3z"
      fill="#5D1A23"
    />
    <path
      transform="translate(443,956)"
      d="m0 0h6l8 4v2l3 1h-6l-11-6z"
      fill="#AFB1B1"
    />
    <path
      transform="translate(449,1507)"
      d="m0 0 7 1 6 3 1 3-6-1-8-4z"
      fill="#CECECE"
    />
    <path
      transform="translate(191,1282)"
      d="m0 0 5 1 1 2-13 2-4-3z"
      fill="#5D1921"
    />
    <path
      transform="translate(212,1142)"
      d="m0 0 4 5 3 4v2l4 2-8 1 2-1-6-9z"
      fill="#610F14"
    />
    <path
      transform="translate(400,1067)"
      d="m0 0h11v2h-2l-1 3h-6z"
      fill="#9F3134"
    />
    <path
      transform="translate(245,1057)"
      d="m0 0 3 1v2h16l4 3h-18v-2h-3z"
      fill="#651016"
    />
    <path
      transform="translate(346,1050)"
      d="m0 0 15 1-4 2-8 1-1 2-6-2 4-2z"
      fill="#460B0C"
    />
    <path
      transform="translate(359,1035)"
      d="m0 0 4 4 3 5v6l-2-1-4-7-3-4 1-2z"
      fill="#5C0E13"
    />
    <path
      transform="translate(289,1023)"
      d="m0 0 3 1h-2l8 14-6 1 3-1-8-12z"
      fill="#651016"
    />
    <path
      transform="translate(579,1494)"
      d="m0 0 6 1 8 4-3 3-11-6z"
      fill="#91908F"
    />
    <path
      transform="translate(402,1199)"
      d="m0 0 5 2 11 5-1 3-5-2-9-5z"
      fill="#9D5657"
    />
    <path
      transform="translate(253,1199)"
      d="m0 0 10 1-1 4h-5l-3 1v-2h-2z"
      fill="#8C282D"
    />
    <path
      transform="translate(204,1196)"
      d="m0 0h11l2 2h-2v2l-6 1-5-3z"
      fill="#8C282D"
    />
    <path
      transform="translate(222,1144)"
      d="m0 0h7l2 2-4 2-10 1-2-3z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(688,1112)"
      d="m0 0 9 3 7 4-1 2-6-2-10-5z"
      fill="#293133"
    />
    <path
      transform="translate(330,1092)"
      d="m0 0 4 5 3 5-2 4-7-11v-2z"
      fill="#5D0E13"
    />
    <path
      transform="translate(378,1074)"
      d="m0 0 1 2 2 1-6 2h-8l-2 1-2-5 5 1z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(355,937)"
      d="m0 0 2 1-5 5-3 2h-3v2l-5 3-2-1 2-3 7-4 1-2z"
      fill="#555C5E"
    />
    <path
      transform="translate(704,1206)"
      d="m0 0h1v11l-2 9h-2v-7z"
      fill="#12181A"
    />
    <path
      transform="translate(280,1115)"
      d="m0 0 2 1 5 8v4h-2l-7-11z"
      fill="#5A0E12"
    />
    <path transform="translate(233,1098)" d="m0 0h17v2l-19 1z" fill="#430C0E" />
    <path
      transform="translate(326,1085)"
      d="m0 0 3 3 7 1v1h-8l-1 1-10 1h-9v-3l1 1 16-1z"
      fill="#640F15"
    />
    <path
      transform="translate(367,1088)"
      d="m0 0h4l4 7 1 6-3-1-6-10z"
      fill="#5F0F14"
    />
    <path
      transform="translate(780,381)"
      d="m0 0 6 2 6 3v3l-6-1-7-4z"
      fill="#898B8B"
    />
    <path
      transform="translate(448,1467)"
      d="m0 0 5 1 12 6-1 2-6-2-10-5z"
      fill="#0D1213"
    />
    <path
      transform="translate(391,1086)"
      d="m0 0h11v2h-2v2l-4 1-5-2z"
      fill="#9D3034"
    />
    <path
      transform="translate(358,946)"
      d="m0 0 6 2 8 4 4 1 3 3-1 2-20-10z"
      fill="#111618"
    />
    <path
      transform="translate(462,1294)"
      d="m0 0h2l2 8-4 1h-7v-2l6-1-1-5z"
      fill="#C9CAC8"
    />
    <path
      transform="translate(179,1192)"
      d="m0 0 4 2 5 1v2h-6l4 4-1 3-5-5z"
      fill="#4B0D10"
    />
    <path
      transform="translate(376,954)"
      d="m0 0 6 2 9 5v2l-6-1-6-3v-3l-3-1z"
      fill="#293032"
    />
    <path
      transform="translate(652,951)"
      d="m0 0 6 5 6 8-1 2-9-9-3-4z"
      fill="#6B7172"
    />
    <path
      transform="translate(480,948)"
      d="m0 0 4 1v2l-8 4h-5v-2z"
      fill="#B1B3B3"
    />
    <path
      transform="translate(796,851)"
      d="m0 0 6 2 7 4v2l-5-1-10-5z"
      fill="#989A9B"
    />
    <path
      transform="translate(943,465)"
      d="m0 0 7 1 7 3-3 1-1 2-10-5z"
      fill="#929495"
    />
    <path
      transform="translate(592,288)"
      d="m0 0 5 1 11 6 1 2-5-1-12-6z"
      fill="#939494"
    />
    <path
      transform="translate(652,1335)"
      d="m0 0 1 3 4 1-1 2h-9l-2-3 5-2 1 1z"
      fill="#5E1821"
    />
    <path
      transform="translate(674,1295)"
      d="m0 0h4l-2 6-1 1h-8v-3h7l-1-1z"
      fill="#4E191E"
    />
    <path
      transform="translate(352,1126)"
      d="m0 0h8l1 3-1 2-4 1-4-4z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(277,1011)"
      d="m0 0h5l6 8 9 1v1h-10l-2 1-4-7-1-3z"
      fill="#5F1015"
    />
    <path
      transform="translate(412,965)"
      d="m0 0 7 2 6 2-4 4-9-5z"
      fill="#A7A9AA"
    />
    <path
      transform="translate(471,943)"
      d="m0 0h4v2l-11 5h-6l4-3z"
      fill="#ACAEAE"
    />
    <path
      transform="translate(1064,526)"
      d="m0 0 9 2 3 1 1 4-5-1-8-4z"
      fill="#9E9F9F"
    />
    <path
      transform="translate(983,485)"
      d="m0 0 4 1 13 7-3 1-14-7z"
      fill="#9FA1A0"
    />
    <path
      transform="translate(878,430)"
      d="m0 0 6 2 5 3-3 2-6-2-4-3h2z"
      fill="#8A8C8D"
    />
    <path
      transform="translate(1022,185)"
      d="m0 0h7l3 3 5 4 4 3-5-1-14-8z"
      fill="#888684"
    />
    <path
      transform="translate(709,1171)"
      d="m0 0h1v9l-2 14-2-4 2-18z"
      fill="#040809"
    />
    <path
      transform="translate(307,1126)"
      d="m0 0h2v2h6v2l-6 2-14-1v-1l11-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(307,1091)"
      d="m0 0 8 1-4 2-2 2-1-1h-17v-1l15-2z"
      fill="#430B0C"
    />
    <path
      transform="translate(329,1055)"
      d="m0 0 2 1 2 5 3 4-1 3-5-5-3-4z"
      fill="#580E12"
    />
    <path
      transform="translate(316,1035)"
      d="m0 0 15 1v1l-12 2h-10v-1l7-1z"
      fill="#430B0C"
    />
    <path
      transform="translate(269,1021)"
      d="m0 0 5 1h11l-1 2-5 1h-10z"
      fill="#5E0F14"
    />
    <path
      transform="translate(332,998)"
      d="m0 0h2v2l2 1-17 1-2-3z"
      fill="#651016"
    />
    <path
      transform="translate(480,938)"
      d="m0 0h5l-1 3-6 4h-3l1-5z"
      fill="#A0A2A2"
    />
    <path
      transform="translate(1034,509)"
      d="m0 0 6 2 6 3-4 2-6-2-2-1z"
      fill="#8E9191"
    />
    <path
      transform="translate(418,1491)"
      d="m0 0 6 1 8 4 1 3-10-3z"
      fill="#DBDCDC"
    />
    <path
      transform="translate(365,1245)"
      d="m0 0 5 3v3l-4 2-1-2-4-1 1-3h3z"
      fill="#C0C0BF"
    />
    <path
      transform="translate(707,1172)"
      d="m0 0h1v12l-2 6-4-2 1-4h3z"
      fill="#460B0D"
    />
    <path
      transform="translate(303,1037)"
      d="m0 0h11l3 3h-15l-4-2z"
      fill="#651016"
    />
    <path
      transform="translate(72,1315)"
      d="m0 0 9 4 1 5-11-6z"
      fill="#AFB2B1"
    />
    <path
      transform="translate(410,1259)"
      d="m0 0h2l-1 6h-6l-1-5z"
      fill="#9F3739"
    />
    <path
      transform="translate(402,1213)"
      d="m0 0 5 2v2l6 2 1 2-4 1-8-5z"
      fill="#94494B"
    />
    <path
      transform="translate(171,1199)"
      d="m0 0h7l3 3-1 3-5-1-1-2-3-1z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(224,1194)"
      d="m0 0h10l-2 1v4h-5l-3-3z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(696,1180)"
      d="m0 0 3 1 4 7 2 2-1 4-4-5-4-6z"
      fill="#9C3336"
    />
    <path
      transform="translate(226,1169)"
      d="m0 0h2l1 4-1 1-18 1v-3h17z"
      fill="#651016"
    />
    <path
      transform="translate(462,1116)"
      d="m0 0h9l5 2v2l-9-1-5-1z"
      fill="#571014"
    />
    <path
      transform="translate(320,1074)"
      d="m0 0 4 1h-3l5 9v3h-2l-5-7z"
      fill="#590E12"
    />
    <path
      transform="translate(461,995)"
      d="m0 0h6v3h2v2h3l-1 3-10-5z"
      fill="#ABADAC"
    />
    <path
      transform="translate(986,485)"
      d="m0 0 6 2 14 7-4 2-4-2 1-1-13-7z"
      fill="#898A8A"
    />
    <path
      transform="translate(650,1588)"
      d="m0 0h2l-2 4-7 5-6-1 4-2 8-5z"
      fill="#979A9A"
    />
    <path
      transform="translate(410,1486)"
      d="m0 0 4 1 6 3 1 4-6-2-6-3z"
      fill="#AFB1B1"
    />
    <path
      transform="translate(405,1117)"
      d="m0 0 4 1-9 5-4-1v-2z"
      fill="#590E12"
    />
    <path
      transform="translate(592,1356)"
      d="m0 0 2 1 4 9-2 2-6-10z"
      fill="#5D1922"
    />
    <path
      transform="translate(826,1242)"
      d="m0 0 4 1-1 5-4 2-2-2 1-4h2z"
      fill="#070C0D"
    />
    <path
      transform="translate(630,1135)"
      d="m0 0h6l5 4-5 1-5-2z"
      fill="#430B0C"
    />
    <path
      transform="translate(451,1117)"
      d="m0 0h11l10 3v1h-7l-6-2-8-1z"
      fill="#A7A7A6"
    />
    <path
      transform="translate(244,1116)"
      d="m0 0h10l3 2h-16v2l-4-2z"
      fill="#280A0B"
    />
    <path
      transform="translate(481 1e3)"
      d="m0 0 10 5-1 2-7-1-2-2z"
      fill="#AAADAD"
    />
    <path
      transform="translate(336,998)"
      d="m0 0h11l5 1v1l-13 1-3 1v-2l-2-1z"
      fill="#4C0C0E"
    />
    <path
      transform="translate(592,1500)"
      d="m0 0 5 1 6 3v3l-6-2-6-3z"
      fill="#959898"
    />
    <path
      transform="translate(364,1144)"
      d="m0 0 2 1-8 9-1 4h-4l2-4z"
      fill="#A2A19E"
    />
    <path
      transform="translate(223,1093)"
      d="m0 0 3 3 1 4h-7l1-5z"
      fill="#5F0F14"
    />
    <path
      transform="translate(264,1077)"
      d="m0 0h12l-1 2-6 1h-8z"
      fill="#4E0C0F"
    />
    <path
      transform="translate(653,319)"
      d="m0 0 5 1 8 4 1 3-6-2-8-4z"
      fill="#929291"
    />
    <path
      transform="translate(520,1544)"
      d="m0 0 5 1 2-1 6 3-1 3-9-3z"
      fill="#C5C6C6"
    />
    <path
      transform="translate(649,1528)"
      d="m0 0 6 2 10 5-2 2-7-3v-2l-7-1z"
      fill="#949494"
    />
    <path
      transform="translate(643,1279)"
      d="m0 0 5 1 5 6v1l-8-1 2-1-1-4z"
      fill="#5D1922"
    />
    <path
      transform="translate(160,1234)"
      d="m0 0h17v1l-18 2-4-2z"
      fill="#430B0C"
    />
    <path
      transform="translate(182,1175)"
      d="m0 0 8 1-1 3-6 2-4-1 3-3z"
      fill="#270A0C"
    />
    <path
      transform="translate(251,1136)"
      d="m0 0 3 3-1 4-4-4-14-1v-1h15z"
      fill="#5A0F14"
    />
    <path
      transform="translate(253,1096)"
      d="m0 0h11l-1 3h-11z"
      fill="#4D0C0F"
    />
    <path
      transform="translate(365,1085)"
      d="m0 0 2 4h-9l-10-1v-1z"
      fill="#651016"
    />
    <path
      transform="translate(475,1007)"
      d="m0 0 6 2 10 5-1 2-5-2-10-5z"
      fill="#B2B3B2"
    />
    <path
      transform="translate(808,395)"
      d="m0 0 6 2 4 3h-2v2l-6-2-2-2z"
      fill="#878989"
    />
    <path
      transform="translate(566,1526)"
      d="m0 0 6 2 6 3 1 3-6-2-7-4z"
      fill="#0B1112"
    />
    <path
      transform="translate(532,1286)"
      d="m0 0 1 2-4 6-4 2-3 2 2-4 5-6z"
      fill="#763033"
    />
    <path
      transform="translate(14,1265)"
      d="m0 0 1 4 1 12-2 2-1-4v-11z"
      fill="#919495"
    />
    <path
      transform="translate(126,1264)"
      d="m0 0 8 4-1 3-9-4v-2z"
      fill="#7A7F80"
    />
    <path
      transform="translate(368,1231)"
      d="m0 0 4 2 1 4-5-2-6-1v-2z"
      fill="#430B0C"
    />
    <path
      transform="translate(465,1208)"
      d="m0 0 2 3 3 8-1 3-6-9h2z"
      fill="#A37979"
    />
    <path
      transform="translate(270,1097)"
      d="m0 0h6v1h-5l4 6v4h-2l-4-6z"
      fill="#5D0E13"
    />
    <path
      transform="translate(615,1076)"
      d="m0 0 5 1 8 4v2l-5-1-8-4z"
      fill="#2B3234"
    />
    <path
      transform="translate(289,1059)"
      d="m0 0 2 1 2 4-1 3-5-5-9-1v-1h10z"
      fill="#600F14"
    />
    <path
      transform="translate(582,1058)"
      d="m0 0 13 6-1 2-6-2-7-4z"
      fill="#333C3E"
    />
    <path
      transform="translate(672,1011)"
      d="m0 0 3 1 4 7v2l-4-1-3-5z"
      fill="#979999"
    />
    <path
      transform="translate(306,984)"
      d="m0 0h15v1l-12 1 2 4-4-1-1-2-3-2z"
      fill="#5A0E13"
    />
    <path
      transform="translate(299,986)"
      d="m0 0 1 3-7 8-4 1v-2h2l2-4z"
      fill="#100809"
    />
    <path
      transform="translate(439,948)"
      d="m0 0 9 3 2 1-1 3-11-5z"
      fill="#9FA1A2"
    />
    <path
      transform="translate(447,1201)"
      d="m0 0 5 1v1h-6l-1 6-3 2 1-9z"
      fill="#651118"
    />
    <path
      transform="translate(298,1110)"
      d="m0 0 5 1-1 3h-12l1-2h7z"
      fill="#440B0C"
    />
    <path
      transform="translate(350,1100)"
      d="m0 0 4 3v2l-3 1h-8l4-3h3z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(229,1099)"
      d="m0 0 3 1 4 2h-5l1 3-14-2v-1h11z"
      fill="#560E12"
    />
    <path
      transform="translate(341,1040)"
      d="m0 0 3 3 3 5-2 4-5-8z"
      fill="#5A0E13"
    />
    <path
      transform="translate(948,1008)"
      d="m0 0h2l-1 6-3 4-4-1h2l2-5z"
      fill="#1D2527"
    />
    <path
      transform="translate(709,346)"
      d="m0 0 6 1 4 3-6 1-6-3z"
      fill="#888A89"
    />
    <path
      transform="translate(338,1411)"
      d="m0 0 6 2 8 4v1h-5l-9-5z"
      fill="#13191B"
    />
    <path
      transform="translate(172,1216)"
      d="m0 0 2 3 6 10-1 2-9-13z"
      fill="#430F10"
    />
    <path
      transform="translate(685,1165)"
      d="m0 0 4 1 3 3 4 7-1 3-8-12z"
      fill="#9C3033"
    />
    <path
      transform="translate(284,1131)"
      d="m0 0m-16 1h16v1l-15 2-2-2z"
      fill="#430B0C"
    />
    <path
      transform="translate(393,1120)"
      d="m0 0 2 1-10 5-4-1 7-4z"
      fill="#A09F9C"
    />
    <path
      transform="translate(261,1117)"
      d="m0 0 4 5 1 4h-3l-5-8z"
      fill="#630F15"
    />
    <path
      transform="translate(315,968)"
      d="m0 0 2 1-8 9-4 1 6-7z"
      fill="#4B5355"
    />
    <path
      transform="translate(821,863)"
      d="m0 0 10 5-1 2-6-2-4-2z"
      fill="#989A9B"
    />
    <path
      transform="translate(868,427)"
      d="m0 0 10 2v3l-6-1-4-2z"
      fill="#959797"
    />
    <path
      transform="translate(548,1556)"
      d="m0 0 5 1 2 2-1 2 3 1-2 1-7-4z"
      fill="#C2C3C3"
    />
    <path
      transform="translate(669,1279)"
      d="m0 0 2 1-1 4-4 1-1 3-3-4 1-2h6z"
      fill="#5D1A23"
    />
    <path
      transform="translate(703,1222)"
      d="m0 0h1l-1 11-2 4-1-4 1-7h2z"
      fill="#5B6162"
    />
    <path
      transform="translate(242,1129)"
      d="m0 0 5 5-4 1h-8l1-2 6-1z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(565,1049)"
      d="m0 0 7 1 5 3-1 2-5-2-6-3z"
      fill="#A1A3A3"
    />
    <path
      transform="translate(303,1049)"
      d="m0 0h2l3 4 9 1v1l-14 1 2-1-2-3z"
      fill="#651016"
    />
    <path
      transform="translate(308,1022)"
      d="m0 0 5 4 1 5h-3l-4-8z"
      fill="#610F14"
    />
    <path
      transform="translate(427,980)"
      d="m0 0 11 5-1 3-11-6z"
      fill="#384143"
    />
    <path
      transform="translate(263,772)"
      d="m0 0h3l-2 6-3 4-1-5z"
      fill="#333D3F"
    />
    <path
      transform="translate(1031,182)"
      d="m0 0 11 5v2l-5-1-5-2z"
      fill="#868888"
    />
    <path
      transform="translate(626,1356)"
      d="m0 0 5 1 2 4-4 1-3-4z"
      fill="#430B0C"
    />
    <path
      transform="translate(397,1327)"
      d="m0 0 12 3-2 3-10-3z"
      fill="#6F1A1F"
    />
    <path
      transform="translate(682,1284)"
      d="m0 0 2 1-1 5-3 7h-2l2-8z"
      fill="#3B4446"
    />
    <path
      transform="translate(453,1175)"
      d="m0 0h5l-4 3 2 2h-5l-1 2v-2l-4-1 6-3z"
      fill="#965859"
    />
    <path
      transform="translate(360,1141)"
      d="m0 0h2l-2 4-7 6v-3z"
      fill="#B3B4B2"
    />
    <path
      transform="translate(293,995)"
      d="m0 0 3 3 1 3-4-1v-2l-7 4 2-4 5-1z"
      fill="#490C0F"
    />
    <path
      transform="translate(1056,520)"
      d="m0 0 13 6-3 1-6-1v-3l-4-1z"
      fill="#909191"
    />
    <path
      transform="translate(864,423)"
      d="m0 0 6 2 4 3h-8l-4-3h2z"
      fill="#87898A"
    />
    <path
      transform="translate(484,1485)"
      d="m0 0 4 1 6 3v2l-5-1-5-3z"
      fill="#0F1415"
    />
    <path
      transform="translate(430,1168)"
      d="m0 0h1l-1 8-2 2-2-1 2-7z"
      fill="#965F61"
    />
    <path
      transform="translate(223,1157)"
      d="m0 0h7l1 4-3 1-5-3z"
      fill="#9D3134"
    />
    <path
      transform="translate(271,1134)"
      d="m0 0 3 4 2 6-3-1-4-7z"
      fill="#5B0E13"
    />
    <path
      transform="translate(218,1131)"
      d="m0 0 6 4-2 2h-5v-5z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(330,1098)"
      d="m0 0 2 3-7 1-6-1 1-2z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(317,1091)"
      d="m0 0h8l-3 1v2h-11l4-2z"
      fill="#510D10"
    />
    <path
      transform="translate(599,1065)"
      d="m0 0 5 1 6 3v2l-5-1-6-3z"
      fill="#B1B3B3"
    />
    <path
      transform="translate(328,1068)"
      d="m0 0 5 1 3 2-13 1 2-3z"
      fill="#8E2A2F"
    />
    <path
      transform="translate(259,1044)"
      d="m0 0 4 5 2 5-4-1-3-4z"
      fill="#691218"
    />
    <path
      transform="translate(338,982)"
      d="m0 0 7 1 3 4-6-1-4-2z"
      fill="#4D0C0F"
    />
    <path
      transform="translate(323,963)"
      d="m0 0 2 1-3 2zm-2 3v3l-2 4-5 2 2-4z"
      fill="#0F0C0D"
    />
    <path
      transform="translate(1026,507)"
      d="m0 0h6l2 1v4l-6-2-2-1z"
      fill="#949797"
    />
  </svg>
)
export default CardBox
