import React from 'react'

const LaLiga = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={169}
    viewBox="0 0 150 169"
    {...props}
  >
    <path
      d="M0 0 C49.5 0 99 0 150 0 C150 55.77 150 111.54 150 169 C100.5 169 51 169 0 169 C0 113.23 0 57.46 0 0 Z "
      fill="#FCFCFC"
      transform="translate(0,0)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C2.7 3.32 3.36 5.66 4 8 C4.34 8.64 4.69 9.27 5.04 9.93 C6.83 13.78 6.53 17.85 6 22 C3.67 26.85 0.88 29.73 -4 32 C-8.94 32.8 -13.74 33.21 -18.26 30.79 C-22.11 27.61 -24.64 24.49 -25.37 19.45 C-25.51 13.76 -25.28 9.77 -21.46 5.37 C-17.13 1.32 -13.51 0.85 -7.75 0.88 C-6.71 0.91 -5.68 0.95 -4.61 0.99 C-2.02 1.2 -2.02 1.2 0 0 Z "
      fill="#EAEAEB"
      transform="translate(84,44)"
    />
    <path
      d="M0 0 C6.35 2.43 9.07 9.4 12.12 15.08 C14.67 20.86 14.28 26.8 14 33 C10.85 33.03 7.71 33.05 4.56 33.06 C3.66 33.07 2.77 33.08 1.84 33.09 C0.56 33.09 0.56 33.09 -0.75 33.1 C-1.54 33.1 -2.33 33.11 -3.14 33.11 C-5 33 -5 33 -6 32 C-6.3 30.25 -6.56 28.5 -6.81 26.75 C-7.61 22.03 -8.82 19.62 -12 16 C-11.47 11.49 -8.45 8.89 -5.44 5.75 C-4.92 5.2 -4.4 4.64 -3.86 4.07 C-2.58 2.71 -1.29 1.35 0 0 Z "
      fill="#EB8023"
      transform="translate(108,35)"
    />
    <path
      d="M0 0 C1.35 1.99 2.68 3.99 4 6 C6.82 8.54 9.54 10.46 13 12 C12.39 18.68 10.22 24.7 8 31 C-1.42 29.54 -9.03 23.14 -15 16 C-19 10.23 -19 10.23 -19 7 C-16.4 5.83 -13.79 4.66 -11.19 3.5 C-10.45 3.17 -9.71 2.83 -8.95 2.49 C-8.24 2.17 -7.53 1.86 -6.79 1.53 C-6.14 1.24 -5.48 0.94 -4.81 0.64 C-3 0 -3 0 0 0 Z "
      fill="#4784C0"
      transform="translate(47,68)"
    />
    <path
      d="M0 0 C8.41 3.96 8.41 3.96 17 8 C16.67 9.65 16.34 11.3 16 13 C15.93 15.78 15.91 18.53 15.94 21.31 C15.94 22.05 15.95 22.79 15.95 23.56 C15.96 25.37 15.98 27.19 16 29 C13.4 30.03 10.8 31.05 8.19 32.06 C7.45 32.36 6.71 32.65 5.95 32.96 C0.34 35.11 0.34 35.11 -3 34 C-6.78 23.87 -6 12.9 -1.97 3.01 C-1 1 -1 1 0 0 Z "
      fill="#2E529C"
      transform="translate(34,34)"
    />
    <path
      d="M0 0 C6.6 0.33 13.2 0.66 20 1 C20 3.97 20 6.94 20 10 C19.34 10 18.68 10 18 10 C17.92 10.66 17.84 11.32 17.75 12 C15.68 20.29 10.81 25.9 5 32 C1.67 31.25 0.17 30.71 -2 28 C-4.21 24.45 -6.19 20.77 -8 17 C-7.01 15.52 -7.01 15.52 -6 14 C-5.34 14 -4.68 14 -4 14 C-4 12.68 -4 11.36 -4 10 C-3.34 10 -2.68 10 -2 10 C-1.34 6.7 -0.68 3.4 0 0 Z "
      fill="#FCE512"
      transform="translate(96,70)"
    />
    <path
      d="M0 0 C0.5 2.6 1 5.21 1.5 7.81 C1.64 8.55 1.79 9.29 1.93 10.05 C2.51 13.08 3 15.91 3 19 C2.34 19.29 1.67 19.58 0.99 19.88 C-4.73 22.54 -8.38 24.81 -12 30 C-16.04 28.51 -19.61 26.57 -23.31 24.38 C-24.38 23.74 -25.45 23.11 -26.55 22.46 C-27.36 21.98 -28.17 21.5 -29 21 C-27.87 14.77 -23.22 10.65 -18.3 7 C-12.37 3.12 -7.25 0 0 0 Z "
      fill="#911F7F"
      transform="translate(71,13)"
    />
    <path
      d="M0 0 C1.67 2.22 3.19 4.42 4.69 6.75 C5.12 7.38 5.54 8.02 5.99 8.67 C9.07 13.43 9.07 13.43 8.75 16.14 C8 18 8 18 5.86 19.23 C4.98 19.55 4.09 19.86 3.19 20.19 C2.31 20.51 1.44 20.83 0.54 21.17 C-3.06 22.35 -6.23 23.13 -10.04 23.13 C-11.16 23.13 -11.16 23.13 -12.3 23.14 C-13.07 23.13 -13.83 23.13 -14.62 23.12 C-15.4 23.13 -16.18 23.13 -16.98 23.14 C-22.75 23.13 -22.75 23.13 -25 22 C-22.97 6.97 -22.97 6.97 -19 3 C-16.86 2.91 -16.86 2.91 -14.31 3.06 C-8.92 3.2 -4.92 0 0 0 Z "
      fill="#23A237"
      transform="translate(82,84)"
    />
    <path
      d="M0 0 C12.53 -0.78 23.08 0.5 33 9 C31.58 13.32 29.46 16 26.38 19.31 C25.56 20.2 24.74 21.08 23.9 21.99 C22.96 22.98 22.96 22.98 22 24 C19.29 23.38 16.81 22.56 14.25 21.5 C10.16 19.92 6.36 19.39 2 19 C1.66 16.96 1.33 14.92 1 12.88 C0.81 11.74 0.63 10.6 0.44 9.43 C0.03 6.24 -0.08 3.21 0 0 Z "
      fill="#DF2520"
      transform="translate(76,18)"
    />
    <path
      d="M0 0 C0.99 0.66 1.98 1.32 3 2 C3 1.34 3 0.68 3 0 C4.65 0 6.3 0 8 0 C8.08 3.06 8.14 6.12 8.19 9.19 C8.21 10.05 8.24 10.92 8.26 11.81 C8.32 16.66 8.32 20.15 5 24 C2.06 26.04 -0.05 26.22 -3.56 25.81 C-4.33 25.74 -5.09 25.66 -5.88 25.58 C-8.39 24.89 -9.42 24.04 -11 22 C-11 21.34 -11 20.68 -11 20 C-7.04 20 -3.08 20 1 20 C1.33 19.01 1.66 18.02 2 17 C1.34 17.33 0.68 17.66 0 18 C-3.74 18.35 -6.12 18.53 -9.38 16.56 C-11.74 12.84 -12.3 9.39 -12 5 C-9.23 -0.9 -5.89 -0.65 0 0 Z "
      fill="#111111"
      transform="translate(98,127)"
    />
    <path
      d="M0 0 C1.48 0.01 2.95 0.04 4.43 0.07 C7.03 0.11 9.63 0.15 12.3 0.2 C12.3 6.47 12.3 12.74 12.3 19.2 C8.47 19.32 8.47 19.32 4.55 19.45 C3.76 19.48 2.96 19.52 2.13 19.56 C-1.32 19.61 -3.32 19.47 -6.15 17.43 C-8.46 14.09 -8.92 11.21 -8.7 7.2 C-6.62 2.66 -5.14 0.27 0 0 Z "
      fill="#161616"
      transform="translate(117.6953125,126.8046875)"
    />
    <path
      d="M0 0 C0 6.27 0 12.54 0 19 C-3.59 19.12 -3.59 19.12 -7.25 19.25 C-8 19.29 -8.74 19.32 -9.51 19.36 C-12.95 19.42 -14.68 19.25 -17.42 17.11 C-20.28 13.29 -20.24 10.9 -19.61 6.27 C-18.84 3.4 -17.59 1.48 -15.16 -0.27 C-10.27 -1.92 -4.99 -0.66 0 0 Z "
      fill="#080808"
      transform="translate(56,127)"
    />
    <path
      d="M0 0 C0.66 1.65 1.32 3.3 2 5 C1.17 5.49 0.35 5.99 -0.5 6.5 C-3.15 8.66 -3.15 8.66 -3.33 11.05 C-3.31 13.03 -3.17 15.02 -3 17 C-0.36 17 2.28 17 5 17 C5.66 14.36 6.32 11.72 7 9 C7.76 9.31 8.53 9.62 9.31 9.94 C11.19 10.68 13.09 11.36 15 12 C15 13.65 15 15.3 15 17 C14.22 17.12 13.43 17.25 12.62 17.38 C9.86 17.79 9.86 17.79 8 20 C8 20.99 8 21.98 8 23 C7.01 23.33 6.02 23.66 5 24 C5 24.66 5 25.32 5 26 C-1.48 26.37 -1.48 26.37 -3.94 24.62 C-5 23 -5 23 -5 21 C-6.18 20.78 -6.18 20.78 -7.38 20.56 C-10 20 -10 20 -12 19 C-12.88 15.69 -12.88 15.69 -13 12 C-11.06 9.88 -11.06 9.88 -9 8 C-9 7.15 -9 6.31 -9 5.44 C-9 3 -9 3 -7.31 1.19 C-4.66 -0.18 -2.94 -0.25 0 0 Z "
      fill="#F0F1F1"
      transform="translate(73,49)"
    />
    <path
      d="M0 0 C3.94 0.44 3.94 0.44 6.94 2.44 C6.94 1.78 6.94 1.12 6.94 0.44 C8.59 0.44 10.24 0.44 11.94 0.44 C10.95 3.08 9.96 5.72 8.94 8.44 C7.95 8.11 6.96 7.78 5.94 7.44 C5.61 6.78 5.28 6.12 4.94 5.44 C2.96 5.44 0.98 5.44 -1.06 5.44 C-1.8 9.42 -1.8 9.42 -2.06 13.44 C0.58 13.77 3.22 14.1 5.94 14.44 C3.96 15.43 3.96 15.43 1.94 16.44 C1.94 17.1 1.94 17.76 1.94 18.44 C-1.38 18.31 -1.38 18.31 -5.06 17.44 C-7.86 13.55 -8.77 10.18 -8.06 5.44 C-5.99 1.46 -4.4 0.12 0 0 Z "
      fill="#090909"
      transform="translate(94.0625,126.5625)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5 6.27 5 12.54 5 19 C7.64 19 10.28 19 13 19 C13 20.65 13 22.3 13 24 C8.71 24 4.42 24 0 24 C0 16.08 0 8.16 0 0 Z "
      fill="#000000"
      transform="translate(21,122)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5 6.27 5 12.54 5 19 C7.64 19 10.28 19 13 19 C13 20.65 13 22.3 13 24 C8.71 24 4.42 24 0 24 C0 16.08 0 8.16 0 0 Z "
      fill="#000000"
      transform="translate(61,122)"
    />
    <path
      d="M0 0 C0.99 0.49 0.99 0.49 2 1 C2 1.66 2 2.32 2 3 C2.6 2.98 3.2 2.97 3.82 2.95 C6.57 2.89 9.31 2.85 12.06 2.81 C13.01 2.79 13.95 2.76 14.92 2.74 C20.77 2.68 24.81 3.26 30 6 C30.33 5.34 30.66 4.68 31 4 C31.66 4.66 32.32 5.32 33 6 C32.34 6 31.68 6 31 6 C31 6.66 31 7.32 31 8 C31.66 8 32.32 8 33 8 C33 8.66 33 9.32 33 10 C32.4 9.8 31.79 9.59 31.17 9.38 C30.37 9.11 29.57 8.84 28.75 8.56 C27.96 8.3 27.17 8.03 26.36 7.75 C21.64 6.25 17.19 5.72 12.25 5.44 C11.55 5.39 10.86 5.35 10.14 5.31 C8.42 5.2 6.71 5.1 5 5 C5.14 5.95 5.29 6.91 5.44 7.89 C5.62 9.14 5.81 10.4 6 11.69 C6.19 12.93 6.37 14.17 6.56 15.45 C6.92 18.36 7.09 21.08 7 24 C9.64 24 12.28 24 15 24 C15 24.33 15 24.66 15 25 C12.03 25.33 9.06 25.66 6 26 C5.71 24.91 5.42 23.81 5.12 22.69 C4.18 18.96 4.18 18.96 2 16 C1.37 13.48 1.37 13.48 0.88 10.62 C0.71 9.69 0.54 8.75 0.37 7.79 C0.02 5.15 -0.07 2.66 0 0 Z "
      fill="#FAD5D9"
      transform="translate(71,13)"
    />
    <path
      d="M0 0 C1 0.02 2 0.05 3.02 0.07 C4.53 0.1 4.53 0.1 6.06 0.12 C6.83 0.15 7.6 0.17 8.4 0.2 C8.4 0.86 8.4 1.52 8.4 2.2 C5.57 4.81 3.19 5.67 -0.6 6.2 C-1.29 8.55 -1.29 8.55 -1.6 11.2 C0.07 14.25 0.07 14.25 2.46 15.2 C4.4 16.2 4.4 16.2 5.4 19.2 C-1.82 19.68 -1.82 19.68 -5.04 17.7 C-7.18 14.27 -7.83 11.23 -7.6 7.2 C-5.9 3.48 -4.32 0.32 0 0 Z "
      fill="#070707"
      transform="translate(116.6015625,126.8046875)"
    />
    <path
      d="M0 0 C2.31 0 4.62 0 7 0 C7 6.27 7 12.54 7 19 C8.65 19 10.3 19 12 19 C12 17.02 12 15.04 12 13 C13.32 13 14.64 13 16 13 C16.27 14.24 16.54 15.47 16.81 16.75 C17.64 20.1 18.63 22.41 21 25 C18.72 26.14 17.43 26.13 14.9 26.13 C14.1 26.13 13.3 26.14 12.47 26.14 C11.64 26.13 10.8 26.13 9.94 26.12 C9.1 26.13 8.26 26.13 7.39 26.14 C1.13 26.13 1.13 26.13 0 25 C-0.09 23.15 -0.11 21.29 -0.1 19.43 C-0.09 18.31 -0.09 17.18 -0.09 16.03 C-0.08 14.84 -0.07 13.66 -0.06 12.44 C-0.06 11.25 -0.05 10.06 -0.05 8.84 C-0.04 5.89 -0.02 2.95 0 0 Z M1 1 C1 8.92 1 16.84 1 25 C5.29 25 9.58 25 14 25 C14 23.35 14 21.7 14 20 C11.36 20 8.72 20 6 20 C6 13.73 6 7.46 6 1 C4.35 1 2.7 1 1 1 Z "
      fill="#C9C9C9"
      transform="translate(20,121)"
    />
    <path
      d="M0 0 C1.98 1.32 3.96 2.64 6 4 C5.12 9.75 5.12 9.75 4 12 C1.36 12 -1.28 12 -4 12 C-5.15 6.72 -5.15 6.72 -5 4 C-2.56 1.5 -2.56 1.5 0 0 Z "
      fill="#070707"
      transform="translate(74,54)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5 6.27 5 12.54 5 19 C3.35 19 1.7 19 0 19 C0 12.73 0 6.46 0 0 Z "
      fill="#020202"
      transform="translate(78,127)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2 1.32 2 2.64 2 4 C2.66 4 3.32 4 4 4 C4.66 7.3 5.32 10.6 6 14 C4.68 12.68 3.36 11.36 2 10 C-1.88 11.55 -4.03 14 -6.75 17.12 C-7.55 18.04 -8.35 18.95 -9.17 19.88 C-9.78 20.58 -10.38 21.28 -11 22 C-12 19 -12 19 -12 16 C-10.89 14.82 -9.76 13.64 -8.58 12.53 C-6.7 10.71 -4.94 8.81 -3.19 6.88 C-2.58 6.21 -1.98 5.55 -1.36 4.87 C0.29 2.98 0.29 2.98 0 0 Z "
      fill="#FDE5D9"
      transform="translate(108,26)"
    />
    <path
      d="M0 0 C5.57 0.57 5.57 0.57 7 2 C7.25 5.44 7.25 5.44 7 9 C4 11 4 11 1.44 10.81 C-1 10 -1 10 -3 7 C-2.68 3.68 -2.39 2.39 0 0 Z "
      fill="#E3E3E3"
      transform="translate(117,131)"
    />
    <path
      d="M0 0 C3 -0.38 3 -0.38 6 0 C8 3 8 3 7.75 5.56 C7 8 7 8 5 10 C1.88 9.62 1.88 9.62 -1 9 C-1.62 6.19 -1.62 6.19 -2 3 C-1.34 2.01 -0.68 1.02 0 0 Z "
      fill="#E6E6E6"
      transform="translate(93,131)"
    />
    <path
      d="M0 0 C3.5 0.12 3.5 0.12 4.5 1.12 C4.74 6.65 4.74 6.65 3.5 9.12 C0.12 9.31 0.12 9.31 -3.5 9.12 C-5.5 6.12 -5.5 6.12 -5.19 3.5 C-4.12 -0.2 -4.1 0.15 0 0 Z "
      fill="#EDEDED"
      transform="translate(46.5,131.875)"
    />
    <path
      d="M0 0 C6.64 1.5 6.64 1.5 8.5 4.06 C9.98 6.41 9.98 6.41 13.69 6.75 C14.78 6.83 15.87 6.92 17 7 C17 6.34 17 5.68 17 5 C18.88 2.39 19.98 2.01 23.19 1.31 C24.58 1.16 24.58 1.16 26 1 C24.93 4.2 23.92 5.42 21.07 7.19 C16.5 8.98 11.77 8.87 7 8 C4.13 6.43 1.94 4.64 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#383939"
      transform="translate(61,68)"
    />
    <path
      d="M0 0 C4.91 4.75 4.91 4.75 5.17 8.28 C5.17 9.16 5.18 10.03 5.19 10.94 C5.2 11.81 5.22 12.69 5.23 13.59 C5 16 5 16 3 19 C1.05 19.51 1.05 19.51 -1.12 19.69 C-2.4 19.79 -3.68 19.89 -5 20 C-5 19.01 -5 18.02 -5 17 C-4.34 17 -3.68 17 -3 17 C-2.01 16.34 -1.02 15.68 0 15 C0.99 15.33 1.98 15.66 3 16 C2.45 11.41 1.49 7.36 0 3 C0 2.01 0 1.02 0 0 Z "
      fill="#BFBFBE"
      transform="translate(85,50)"
    />
    <path
      d="M0 0 C1.47 1.08 2.93 2.19 4.38 3.31 C5.71 4.31 7.04 5.31 8.38 6.31 C7.39 7.63 6.39 8.95 5.38 10.31 C5.38 9.32 5.38 8.33 5.38 7.31 C4.59 7.09 3.81 6.86 3 6.62 C-1.75 4.91 -6.15 2.63 -10.62 0.31 C-10.62 -0.02 -10.62 -0.35 -10.62 -0.69 C-6.12 -3.23 -4.09 -2.75 0 0 Z "
      fill="#E7E2F4"
      transform="translate(45.625,34.6875)"
    />
    <path
      d="M0 0 C0.33 0.66 0.66 1.32 1 2 C5.45 1.5 5.45 1.5 10 1 C10.99 2.98 11.98 4.96 13 7 C12.01 8.49 12.01 8.49 11 10 C10.67 9.34 10.34 8.68 10 8 C9.67 12.29 9.34 16.58 9 21 C8.67 21 8.34 21 8 21 C8 15.06 8 9.12 8 3 C6.35 3 4.7 3 3 3 C3 3.66 3 4.32 3 5 C-1.45 4.01 -1.45 4.01 -6 3 C-6 2.67 -6 2.34 -6 2 C-4.02 2 -2.04 2 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#ECECEC"
      transform="translate(98,124)"
    />
    <path
      d="M0 0 C1.32 2.63 0.75 3.8 0.06 6.62 C-0.13 7.44 -0.33 8.26 -0.53 9.1 C-0.68 9.73 -0.84 10.35 -1 11 C-1.66 11 -2.32 11 -3 11 C-3 11.66 -3 12.32 -3 13 C-4.98 13.99 -4.98 13.99 -7 15 C-6.34 15.33 -5.68 15.66 -5 16 C-5 16.66 -5 17.32 -5 18 C-5.72 17.98 -6.44 17.96 -7.19 17.94 C-9.92 18 -12.34 18.39 -15 19 C-14.32 18.53 -13.64 18.05 -12.94 17.56 C-7.35 12.69 -2.96 6.8 0 0 Z "
      fill="#FDFACB"
      transform="translate(113,84)"
    />
    <path
      d="M0 0 C1.98 0.66 3.96 1.32 6 2 C6 3.65 6 5.3 6 7 C5.22 7.12 4.43 7.25 3.62 7.38 C0.86 7.79 0.86 7.79 -1 10 C-1.66 8.68 -2.32 7.36 -3 6 C-2.01 6 -1.02 6 0 6 C0 5.34 0 4.68 0 4 C-0.66 3.67 -1.32 3.34 -2 3 C-1.34 2.01 -0.68 1.02 0 0 Z "
      fill="#FDFDFC"
      transform="translate(82,59)"
    />
    <path
      d="M0 0 C0.47 0.5 0.95 0.99 1.44 1.5 C5.2 3.7 8.73 3.25 13 3 C12.67 4.32 12.34 5.64 12 7 C7.71 7 3.42 7 -1 7 C-1 6.01 -1 5.02 -1 4 C-1.33 3.34 -1.66 2.68 -2 2 C-1.34 2 -0.68 2 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#DADADA"
      transform="translate(87,141)"
    />
    <path
      d="M0 0 C1.53 0.09 3.05 0.25 4.56 0.44 C5.8 0.59 5.8 0.59 7.07 0.75 C7.7 0.83 8.34 0.91 9 1 C9 2.32 9 3.64 9 5 C6.29 6.35 3.99 6.07 1 6 C0.34 4.35 -0.32 2.7 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z "
      fill="#1E1E1E"
      transform="translate(43,126)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.34 1.66 0.68 2.32 0 3 C-1.32 7.65 -2.22 12.23 -3 17 C-3.33 17.99 -3.66 18.98 -4 20 C-5.98 16.03 -5.56 11.31 -5 7 C-3.62 4.18 -2.11 2.37 0 0 Z "
      fill="#2C2D2E"
      transform="translate(64,48)"
    />
    <path
      d="M0 0 C6.27 0.33 12.54 0.66 19 1 C19 1.33 19 1.66 19 2 C18.29 2.05 17.58 2.1 16.85 2.15 C15.47 2.26 15.47 2.26 14.06 2.38 C13.15 2.44 12.23 2.51 11.29 2.59 C10.15 2.79 10.15 2.79 9 3 C8.67 3.66 8.34 4.32 8 5 C5.24 4.48 2.67 3.89 0 3 C0 2.01 0 1.02 0 0 Z "
      fill="#D7F7DA"
      transform="translate(57,106)"
    />
    <path
      d="M0 0 C2.06 0.44 2.06 0.44 4 1 C4 2.65 4 4.3 4 6 C2.12 6.62 2.12 6.62 0 7 C-0.66 6.34 -1.32 5.68 -2 5 C-1.46 1.46 -1.46 1.46 0 0 Z "
      fill="#101010"
      transform="translate(79,118)"
    />
    <path
      d="M0 0 C0.77 0.49 0.77 0.49 1.56 1 C-3.98 2.96 -7.65 3.02 -13.44 2 C-11.13 -2.61 -4.14 -1.7 0 0 Z "
      fill="#262624"
      transform="translate(79.4375,46)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1.12 1.07 1.25 2.15 1.38 3.25 C1.91 6.44 2.52 8.23 4 11 C4 11.99 4 12.98 4 14 C3.01 14 2.02 14 1 14 C1 15.65 1 17.3 1 19 C0.67 19 0.34 19 0 19 C0 12.73 0 6.46 0 0 Z "
      fill="#E4E4E4"
      transform="translate(83,127)"
    />
    <path
      d="M0 0 C4.94 4.78 4.94 4.78 5.1 7.95 C5.07 9.96 5.03 11.98 5 14 C1.53 8.8 0.6 6.13 0 0 Z "
      fill="#222222"
      transform="translate(85,50)"
    />
    <path
      d="M0 0 C2.88 -0.12 2.88 -0.12 6 0 C6.66 0.66 7.32 1.32 8 2 C7.67 2.66 7.34 3.32 7 4 C5.35 4 3.7 4 2 4 C1.34 2.68 0.68 1.36 0 0 Z "
      fill="#040404"
      transform="translate(87,147)"
    />
    <path
      d="M0 0 C0.66 0 1.32 0 2 0 C2 0.99 2 1.98 2 3 C2.66 3 3.32 3 4 3 C4 3.66 4 4.32 4 5 C5.98 5 7.96 5 10 5 C9.01 6.48 9.01 6.48 8 8 C4.74 7.44 3.04 6.63 1 4 C0.31 1.81 0.31 1.81 0 0 Z "
      fill="#F7E638"
      transform="translate(93,94)"
    />
    <path
      d="M0 0 C-1.25 3.75 -2.57 4.24 -6 6 C-6.66 6 -7.32 6 -8 6 C-7.19 3.56 -7.19 3.56 -6 1 C-3 0 -3 0 0 0 Z "
      fill="#0E0E0F"
      transform="translate(87,69)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C2.62 3.06 2.62 3.06 3 5 C1.35 5.33 -0.3 5.66 -2 6 C-2.33 4.68 -2.66 3.36 -3 2 C-2.01 1.34 -1.02 0.68 0 0 Z "
      fill="#C7C7C8"
      transform="translate(84,44)"
    />
    <path
      d="M0 0 C3.88 1.75 3.88 1.75 5 4 C5.07 5.87 5.08 7.75 5.06 9.62 C5.05 10.63 5.04 11.63 5.04 12.66 C5.02 13.43 5.01 14.21 5 15 C4.67 15 4.34 15 4 15 C3.88 14.25 3.76 13.5 3.63 12.73 C3.47 11.75 3.3 10.76 3.12 9.75 C2.96 8.78 2.8 7.8 2.63 6.8 C2.05 4.23 1.29 2.28 0 0 Z "
      fill="#E29044"
      transform="translate(98,52)"
    />
    <path
      d="M0 0 C0.33 0.66 0.66 1.32 1 2 C3.31 2 5.62 2 8 2 C8 2.33 8 2.66 8 3 C6.35 3 4.7 3 3 3 C3 3.66 3 4.32 3 5 C-1.45 4.01 -1.45 4.01 -6 3 C-6 2.67 -6 2.34 -6 2 C-4.02 2 -2.04 2 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="#B6B6B6"
      transform="translate(98,124)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1.66 2.64 2.32 5.28 3 8 C1.35 7.67 -0.3 7.34 -2 7 C-2.33 6.01 -2.66 5.02 -3 4 C-2.01 4 -1.02 4 0 4 C0 2.68 0 1.36 0 0 Z "
      fill="#CACACA"
      transform="translate(85,53)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1.33 3.3 1.66 6.6 2 10 C0.68 9.67 -0.64 9.34 -2 9 C-1.34 6.03 -0.68 3.06 0 0 Z "
      fill="#E0E0E0"
      transform="translate(58,58)"
    />
  </svg>
)
export default LaLiga
