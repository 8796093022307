import * as React from 'react'
const Egypt = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L10,0 L18,4 L24,11 L26,16 L26,26 L22,34 L15,40 L10,42 L0,42 L-8,38 L-14,31 L-16,26 L-16,16 L-12,8 L-5,2 Z "
      fill="#F7F2F2"
      transform="translate(20,4)"
    />
    <path
      d="M0,0 L38,0 L36,5 L29,11 L24,13 L14,13 L6,9 L0,2 Z "
      fill="#070707"
      transform="translate(6,33)"
    />
    <path
      d="M0,0 L10,0 L18,4 L24,11 L24,13 L-14,13 L-12,8 L-5,2 Z "
      fill="#9D0814"
      transform="translate(20,4)"
    />
    <path
      d="M0,0 L18,0 L18,2 L16,3 L16,12 L17,14 L0,14 L-1,12 L-1,2 Z "
      fill="#FEFEFE"
      transform="translate(5,18)"
    />
    <path
      d="M0,0 L3,1 L3,4 L5,4 L5,1 L9,0 L9,8 L8,9 L23,10 L23,11 L-16,11 L-16,10 L0,9 L-1,8 Z "
      fill="#DBC9AE"
      transform="translate(21,22)"
    />
    <path
      d="M0,0 L3,1 L-4,9 L-8,10 L-8,6 L-2,3 Z "
      fill="#212121"
      transform="translate(41,34)"
    />
    <path
      d="M0,0 L2,0 L5,5 L3,5 L3,8 L1,8 L1,5 L-1,5 L-3,7 L-2,2 L0,2 Z "
      fill="#F9EBD7"
      transform="translate(23,18)"
    />
    <path
      d="M0,0 L3,1 L2,6 L-1,7 Z "
      fill="#F0C881"
      transform="translate(21,22)"
    />
  </svg>
)
export default Egypt
