import React from 'react'

const AddPlayer = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={122.21}
      height={122.21}
      viewBox="0 0 122.21 122.21"
      {...props}
    >
      <defs>
        <linearGradient
          id="linear-add-gradient"
          x1={17.9}
          y1={17.9}
          x2={103.53}
          y2={103.53}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#eaeaea" />
          <stop offset={0.24} stopColor="#5e5e5e" />
          <stop offset={0.24} stopColor="#606060" />
          <stop offset={0.28} stopColor="#909090" />
          <stop offset={0.33} stopColor="#b7b7b7" />
          <stop offset={0.37} stopColor="#d3d3d3" />
          <stop offset={0.4} stopColor="#e4e4e4" />
          <stop offset={0.43} stopColor="#eaeaea" />
          <stop offset={0.63} stopColor="#eaeaea" />
          <stop offset={0.65} stopColor="#e3e3e3" />
          <stop offset={0.68} stopColor="#d2d2d2" />
          <stop offset={0.72} stopColor="#b5b5b5" />
          <stop offset={0.76} stopColor="#8e8e8e" />
          <stop offset={0.78} stopColor="#767676" />
          <stop offset={0.87} stopColor="#454545" />
          <stop offset={1} stopColor="#eaeaea" />
        </linearGradient>
        <linearGradient
          id="linear-add-gradient-2"
          x1={0.48}
          y1={117.83}
          x2={142.71}
          y2={-15.25}
          xlinkHref="#linear-add-gradient"
        />
        <linearGradient
          id="linear-add-gradient-3"
          x1={26.37}
          y1={26.37}
          x2={96.41}
          y2={96.41}
          xlinkHref="#linear-add-gradient"
        />
        <radialGradient
          id="radial-add-gradient"
          cx={22.01}
          cy={19.84}
          fx={22.01}
          fy={19.84}
          r={95.96}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#008e2f" />
          <stop offset={0.48} stopColor="#00631b" />
          <stop offset={0.87} stopColor="#00450e" />
        </radialGradient>
        <linearGradient
          id="linear-add-gradient-4"
          x1={138.5}
          y1={-16.59}
          x2={-5.57}
          y2={186.99}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.09} stopColor="#f8f8f8" />
          <stop offset={0.22} stopColor="#e6e6e6" />
          <stop offset={0.36} stopColor="#c8c8c8" />
          <stop offset={0.53} stopColor="#9e9e9e" />
          <stop offset={0.71} stopColor="#696969" />
          <stop offset={0.89} stopColor="#282828" />
          <stop offset={1} stopColor="#000" />
        </linearGradient>
        <radialGradient
          id="radial-add-gradient-2"
          cx={48.88}
          cy={58.94}
          fx={48.88}
          fy={58.94}
          r={51.81}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.29} stopColor="#fcfcfc" />
          <stop offset={0.48} stopColor="#f3f3f3" />
          <stop offset={0.64} stopColor="#e4e4e4" />
          <stop offset={0.78} stopColor="#cfcfcf" />
          <stop offset={0.91} stopColor="#b4b4b4" />
          <stop offset={1} stopColor="#9e9e9e" />
        </radialGradient>
      </defs>
      <g style={{ isolation: 'isolate' }} className="cls-9">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <rect
                className="cls-1"
                fill="url(#linear-add-gradient)"
                strokeWidth={0}
                x={0}
                y={0}
                width={122.21}
                height={122.21}
                rx={61.11}
                ry={61.11}
              />
              <path
                className="cls-4"
                fill="url(#linear-add-gradient-2)"
                strokeWidth={0}
                d="M61.11,1.57h0c32.86,0,59.53,26.68,59.53,59.53h0c0,32.86-26.68,59.53-59.53,59.53h0C28.25,120.64,1.57,93.96,1.57,61.11h0C1.57,28.25,28.25,1.57,61.11,1.57Z"
              />
              <rect
                className="cls-5"
                fill="url(#linear-add-gradient-3)"
                strokeWidth={0}
                x={12.57}
                y={12.57}
                width={97.06}
                height={97.06}
                rx={48.53}
                ry={48.53}
              />
              <path
                className="cls-2"
                fill="url(#radial-add-gradient)"
                strokeWidth={0}
                d="M61.11,14.02h0c25.99,0,47.09,21.1,47.09,47.09h0c0,25.99-21.1,47.09-47.09,47.09h0c-25.99,0-47.09-21.1-47.09-47.09h0c0-25.99,21.1-47.09,47.09-47.09Z"
              />
              <path
                className="cls-6"
                opacity={0.5}
                style={{ mixBlendMode: 'multiply' }}
                fill="url(#linear-add-gradient-4)"
                strokeWidth={0}
                d="M108.2,61.11c0,13.01-5.27,24.77-13.79,33.3s-20.3,13.79-33.3,13.79c-8.78,0-16.99-2.41-24.03-6.59,5.54,2.3,11.61,3.56,17.97,3.56,12.99,0,24.77-5.27,33.3-13.79,8.52-8.52,13.79-20.29,13.79-33.3s-5.27-24.79-13.79-33.31c-2.77-2.77-5.9-5.21-9.3-7.22,5.78,2.39,10.99,5.89,15.35,10.25,8.52,8.52,13.79,20.3,13.79,33.31Z"
              />
              <path
                className="cls-3"
                style={{ mixBlendMode: 'color-burn' }}
                fill="url(#radial-add-gradient-2)"
                strokeWidth={0}
                d="M73.13,15.57c-26.03,18.09-42.77,49.62-46.39,77.72-7.89-8.42-12.72-19.73-12.72-32.17s5.27-24.79,13.79-33.31c8.52-8.52,20.29-13.79,33.29-13.79,4.15,0,8.18.55,12.02,1.56Z"
              />
              <g opacity={0.8} className="cls-8">
                <path
                  className="cls-7"
                  fill="#fff"
                  strokeWidth={0}
                  d="M78.97,65.87h-13.17v13.4h-12.56v-13.4h-13.17v-11.95h13.17v-13.48h12.56v13.48h13.17v11.95Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AddPlayer
