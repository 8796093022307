import React from 'react'
const Crotia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z "
      fill="#EC2627"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L14,0 L22,4 L30,13 L32,18 L32,32 L28,40 L21,47 L14,50 L0,50 L-8,46 L-16,37 L-18,32 L-18,18 L-14,10 L-7,3 Z M2,2 L-6,6 L-13,13 L-14,17 L-2,17 L-2,21 L1,21 L1,25 L-2,25 L-2,29 L1,29 L1,33 L-14,33 L-12,38 L-7,43 L-1,47 L2,48 L12,48 L20,44 L25,40 L28,35 L28,33 L13,33 L13,29 L16,29 L16,25 L13,25 L13,21 L16,21 L16,17 L28,17 L26,12 L19,5 L12,2 Z "
      fill="#BAC9CC"
      transform="translate(18,0)"
    />
    <path
      d="M0,0 L13,0 L18,4 L24,4 L29,0 L42,0 L40,5 L35,10 L31,13 L26,15 L16,15 L8,11 L3,6 L0,2 Z "
      fill="#18479C"
      transform="translate(4,33)"
    />
    <path
      d="M0,0 L4,0 L4,4 L8,4 L8,0 L12,0 L12,8 L15,8 L15,12 L12,12 L12,16 L14,17 L9,21 L3,21 L-2,16 L0,16 L0,12 L-3,12 L-3,8 L0,8 Z "
      fill="#F48C8C"
      transform="translate(19,17)"
    />
    <path
      d="M0,0 L12,0 L14,3 L14,13 L12,16 L-3,16 L-3,12 L0,12 L0,8 L-3,8 L-3,4 L0,4 Z "
      fill="#FCFCFC"
      transform="translate(34,17)"
    />
    <path
      d="M0,0 L12,0 L12,4 L15,4 L15,8 L12,8 L12,12 L15,12 L15,16 L0,16 L-2,12 L-2,3 Z "
      fill="#FCFCFC"
      transform="translate(4,17)"
    />
    <path
      d="M0,0 L11,2 L13,5 L10,6 L5,4 L4,6 L10,7 L11,9 L-9,9 L-11,4 L-9,2 L-1,1 Z "
      fill="#6E6989"
      transform="translate(24,7)"
    />
    <path
      d="M0,0 L11,0 L11,4 L7,4 L7,8 L11,8 L11,12 L7,12 L7,9 L4,9 L3,11 L1,8 L3,8 L3,4 L0,4 Z "
      fill="#F05251"
      transform="translate(16,25)"
    />
    <path
      d="M0,0 L7,1 L9,4 L6,5 L1,3 L0,5 L6,6 L7,8 L-2,6 L-2,4 L-4,4 L-5,6 L-5,3 L-1,3 Z "
      fill="#2C7AC5"
      transform="translate(28,8)"
    />
    <path
      d="M0,0 L1,0 L1,15 L-3,16 L-3,12 L0,12 L0,8 L-3,8 L-3,4 L0,4 Z "
      fill="#FBD0D1"
      transform="translate(34,17)"
    />
    <path
      d="M0,0 L1,4 L4,4 L4,8 L1,8 L1,12 L4,12 L4,16 L0,15 Z "
      fill="#FBD0D0"
      transform="translate(15,17)"
    />
    <path
      d="M0,0 L3,1 L3,7 L0,7 L-2,2 Z "
      fill="#3C9ED1"
      transform="translate(15,9)"
    />
    <path
      d="M0,0 L4,1 L2,4 L3,7 L-1,6 L-2,2 Z "
      fill="#E92329"
      transform="translate(12,8)"
    />
    <path
      d="M0,0 L3,0 L3,3 L7,3 L7,0 L10,0 L8,4 L2,4 L0,3 Z "
      fill="#EDDADC"
      transform="translate(20,34)"
    />
    <path
      d="M0,0 L5,1 L7,4 L3,5 L-2,1 Z "
      fill="#E82B30"
      transform="translate(15,4)"
    />
  </svg>
)
export default Crotia
