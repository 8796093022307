import * as React from 'react'
const Chip01 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={306}
    height={309}
    viewBox="0 0 306 309"
    {...props}
  >
    <path
      d="M0,0 L35,0 L52,3 L58,5 L58,7 L65,8 L85,16 L99,24 L113,34 L123,43 L125,45 L130,47 L139,58 L149,72 L156,84 L165,106 L167,115 L168,124 L170,139 L170,169 L167,188 L165,202 L157,221 L152,231 L142,246 L133,257 L129,262 L124,264 L117,271 L102,282 L87,291 L65,300 L57,303 L52,305 L34,308 L1,308 L-17,305 L-22,303 L-30,300 L-50,292 L-64,284 L-78,274 L-88,265 L-93,263 L-102,253 L-115,235 L-123,219 L-129,205 L-131,199 L-132,189 L-135,171 L-135,137 L-132,119 L-131,109 L-124,90 L-115,73 L-103,56 L-98,50 L-94,46 L-87,42 L-75,31 L-59,21 L-43,13 L-27,7 L-23,6 L-22,4 L-8,1 Z "
      fill="#BE151B"
      transform="translate(136,1)"
    />
    <path
      d="M0,0 L21,0 L36,4 L52,12 L63,20 L70,28 L78,39 L84,52 L87,65 L87,90 L83,105 L79,115 L70,128 L66,133 L55,142 L41,150 L30,154 L21,156 L1,156 L-15,153 L-32,145 L-44,136 L-44,134 L-47,133 L-55,123 L-63,109 L-67,97 L-69,84 L-69,68 L-65,52 L-57,36 L-49,25 L-38,15 L-25,7 L-15,3 Z "
      fill="#F3F2F3"
      transform="translate(144,77)"
    />
    <path
      d="M0,0 L4,1 L13,12 L23,26 L30,38 L39,60 L41,69 L39,73 L38,69 L36,68 L38,74 L40,92 L29,91 L20,89 L-1,87 L-6,86 L-4,98 L-4,115 L-6,130 L-13,151 L-22,167 L-30,177 L-42,189 L-52,196 L-62,202 L-81,209 L-94,212 L-122,212 L-140,208 L-157,201 L-171,192 L-182,183 L-189,175 L-198,163 L-207,144 L-211,129 L-213,117 L-213,100 L-210,81 L-206,69 L-198,53 L-189,41 L-180,31 L-167,21 L-153,13 L-141,8 L-123,4 L-94,4 L-76,8 L-60,15 L-46,24 L-41,28 L-32,29 L-24,31 L-23,24 L-16,16 L-2,2 L0,2 Z M-118,30 L-136,34 L-150,41 L-159,48 L-168,56 L-175,66 L-183,82 L-187,98 L-187,114 L-184,130 L-179,143 L-170,157 L-164,164 L-162,164 L-162,166 L-148,176 L-133,183 L-117,186 L-97,186 L-82,182 L-66,174 L-57,167 L-52,163 L-42,150 L-36,138 L-32,125 L-31,120 L-31,95 L-34,82 L-42,66 L-49,57 L-55,50 L-66,42 L-82,34 L-97,30 Z "
      fill="#F6F1F1"
      transform="translate(262,47)"
    />
    <path
      d="M0,0 L3,1 L6,10 L8,25 L8,55 L5,74 L3,88 L-5,107 L-10,117 L-20,132 L-29,143 L-33,148 L-38,150 L-45,157 L-60,168 L-75,177 L-97,186 L-105,189 L-110,191 L-128,194 L-161,194 L-179,191 L-184,189 L-192,186 L-212,178 L-226,170 L-240,160 L-250,151 L-254,150 L-253,146 L-238,132 L-232,125 L-231,122 L-231,114 L-241,100 L-249,85 L-253,77 L-257,74 L-267,74 L-277,76 L-275,71 L-268,60 L-266,56 L-262,48 L-258,41 L-255,35 L-251,29 L-255,32 L-253,28 L-249,24 L-247,21 L-248,32 L-248,49 L-244,69 L-240,81 L-231,98 L-223,108 L-218,113 L-218,115 L-214,117 L-206,124 L-191,133 L-176,139 L-158,143 L-130,143 L-111,138 L-96,132 L-85,125 L-75,117 L-64,106 L-54,91 L-47,75 L-43,62 L-41,47 L-41,30 L-42,18 L-16,20 L4,24 L2,15 Z M-250,27 Z M-256,32 Z M-257,37 Z M-258,38 Z M-259,39 Z M-269,60 Z "
      fill="#DB1B22"
      transform="translate(298,115)"
    />
    <path
      d="M0,0 L19,0 L35,3 L47,8 L58,14 L69,23 L76,30 L86,45 L92,60 L95,72 L95,96 L91,112 L86,124 L80,134 L71,145 L62,153 L49,161 L37,166 L21,170 L4,170 L-10,168 L-24,163 L-40,154 L-53,142 L-61,132 L-69,117 L-73,104 L-74,98 L-74,72 L-70,56 L-62,40 L-54,29 L-44,19 L-34,12 L-20,5 L-7,1 Z M1,7 L-17,11 L-31,18 L-40,25 L-49,33 L-56,43 L-64,59 L-68,75 L-68,91 L-65,107 L-60,120 L-51,134 L-45,141 L-43,141 L-43,143 L-29,153 L-14,160 L2,163 L22,163 L37,159 L53,151 L62,144 L67,140 L77,127 L83,115 L87,102 L88,97 L88,72 L85,59 L77,43 L70,34 L64,27 L53,19 L37,11 L22,7 Z "
      fill="#E1232A"
      transform="translate(143,70)"
    />
    <path
      d="M0,0 L10,0 L32,6 L36,7 L35,15 L27,34 L22,44 L12,59 L3,70 L-1,75 L-4,75 L-9,70 L-26,53 L-27,50 L-27,41 L-18,28 L-12,18 L-7,6 Z "
      fill="#FCFBFB"
      transform="translate(266,188)"
    />
    <path
      d="M0,0 L10,0 L16,5 L25,24 L35,38 L37,41 L37,49 L34,55 L20,69 L14,74 L12,76 L3,66 L-10,48 L-18,32 L-24,18 L-26,12 L-26,7 L-15,4 Z "
      fill="#F1F1F1"
      transform="translate(31,188)"
    />
    <path
      d="M0,0 L4,1 L13,12 L23,26 L30,38 L39,60 L41,69 L39,73 L38,69 L27,71 L14,75 L4,75 L-3,69 L-10,53 L-18,41 L-22,36 L-23,33 L-23,24 L-16,16 L-2,2 L0,2 Z "
      fill="#F7F7F7"
      transform="translate(262,47)"
    />
    <path
      d="M0,0 L3,0 L8,5 L24,21 L26,26 L26,33 L19,44 L13,53 L5,70 L-1,75 L-11,75 L-35,68 L-37,67 L-36,60 L-30,44 L-21,27 L-9,10 L-4,4 Z "
      fill="#EAEAEB"
      transform="translate(42,47)"
    />
    <path
      d="M0,0 L49,0 L55,5 L58,13 L62,31 L63,35 L65,36 L63,38 L49,41 L41,42 L8,42 L-10,39 L-15,37 L-16,35 L-14,35 L-10,18 L-8,9 L-4,3 Z "
      fill="#F9F8F8"
      transform="translate(129,267)"
    />
    <path
      d="M0,0 L35,0 L52,3 L57,4 L50,32 L47,38 L44,41 L41,42 L-7,42 L-13,37 L-16,30 L-21,9 L-21,7 L-23,6 L-22,4 L-8,1 Z "
      fill="#ECECED"
      transform="translate(136,1)"
    />
    <path
      d="M0,0 L2,3 L-5,12 L-13,27 L-14,32 L-7,34 L-2,34 L0,29 L6,27 L0,43 L-2,53 L-2,79 L2,95 L9,111 L5,112 L-2,98 L-10,99 L-13,100 L-12,106 L-3,123 L0,127 L-2,129 L-9,119 L-17,102 L-21,87 L-23,75 L-23,58 L-20,39 L-16,27 L-8,11 Z "
      fill="#F2EDED"
      transform="translate(72,89)"
    />
    <path
      d="M0,0 L1,0 L3,9 L12,21 L20,37 L23,43 L28,47 L38,47 L59,41 L61,43 L64,63 L64,65 L53,64 L44,62 L23,60 L18,59 L17,61 L13,45 L5,29 L-2,18 L-11,8 L-16,2 L-8,2 L0,4 Z "
      fill="#E17D80"
      transform="translate(238,74)"
    />
    <path
      d="M0,0 L13,0 L19,4 L24,10 L26,16 L26,25 L22,34 L16,39 L14,40 L2,40 L-4,36 L-9,30 L-11,25 L-11,15 L-9,9 L-2,1 Z "
      fill="#EC8F92"
      transform="translate(123,149)"
    />
    <path
      d="M0,0 L2,1 L6,11 L14,6 L15,9 L14,17 L23,14 L20,23 L18,30 L17,38 L-16,38 L-18,25 L-22,14 L-16,16 L-12,18 L-14,6 L-17,5 L-17,1 L-12,2 L-11,7 L-5,12 Z "
      fill="#DC7477"
      transform="translate(152,96)"
    />
    <path
      d="M0,0 L19,0 L35,3 L47,8 L58,14 L69,23 L76,30 L86,45 L92,60 L92,63 L85,64 L82,54 L76,43 L69,34 L67,30 L64,29 L64,27 L60,25 L50,18 L35,11 L22,8 L1,8 L-18,12 L-18,10 L-9,5 L-4,4 L-7,1 Z M-7,4 Z "
      fill="#C53136"
      transform="translate(143,70)"
    />
    <path
      d="M0,0 L3,0 L7,9 L7,18 L3,27 L-3,32 L-5,33 L-17,33 L-23,29 L-28,23 L-29,16 L-19,11 L-17,22 L-9,22 L-6,17 L-6,3 Z "
      fill="#D31C22"
      transform="translate(142,156)"
    />
    <path
      d="M0,0 L9,0 L10,1 L10,40 L0,40 L-1,38 L-1,14 L-6,16 L-10,11 L-11,8 Z "
      fill="#BC191F"
      transform="translate(178,149)"
    />
    <path
      d="M0,0 L7,0 L7,3 L2,4 L-5,8 L-7,8 L-8,11 L-20,17 L-29,24 L-37,31 L-44,40 L-47,40 L-49,46 L-56,53 L-61,71 L-62,71 L-61,60 L-56,47 L-50,36 L-42,26 L-33,17 L-23,10 L-9,3 Z M4,3 Z "
      fill="#C72026"
      transform="translate(132,72)"
    />
    <path
      d="M0,0 L3,1 L-5,17 L-9,33 L-9,49 L-6,65 L-1,78 L0,83 L-5,87 L-12,72 L-16,56 L-16,30 L-14,27 L-10,13 L-3,6 L-1,2 Z "
      fill="#CA363B"
      transform="translate(85,112)"
    />
    <path
      d="M0,0 L5,0 L6,8 L6,28 L5,35 L-6,34 L-9,33 L-9,2 Z "
      fill="#C91B21"
      transform="translate(248,137)"
    />
    <path
      d="M0,0 L4,1 L11,11 L19,26 L20,31 L11,34 L6,33 L-2,17 L-8,9 L-6,5 Z "
      fill="#DD7D80"
      transform="translate(228,90)"
    />
    <path
      d="M0,0 L5,1 L13,4 L10,13 L2,27 L-4,34 L-8,33 L-15,27 L-11,20 L-5,10 Z "
      fill="#D41B22"
      transform="translate(235,185)"
    />
    <path
      d="M0,0 L13,2 L13,34 L-1,35 L-2,26 L-2,7 Z "
      fill="#C8373C"
      transform="translate(53,137)"
    />
    <path
      d="M0,0 L4,1 L11,7 L9,12 L2,22 L-4,34 L-12,33 L-16,31 L-15,24 L-6,8 Z "
      fill="#B0171C"
      transform="translate(74,90)"
    />
    <path
      d="M0,0 L33,0 L35,7 L35,12 L25,14 L7,14 L-1,12 L-1,5 Z "
      fill="#DD242B"
      transform="translate(136,242)"
    />
    <path
      d="M0,0 L4,1 L10,8 L10,10 L0,17 L-11,23 L-17,26 L-22,26 L-25,18 L-25,14 L-9,6 Z "
      fill="#DE1E25"
      transform="translate(208,223)"
    />
    <path
      d="M0,0 L3,0 L7,10 L7,12 L-9,20 L-18,26 L-22,27 L-29,18 L-24,13 L-11,5 Z "
      fill="#AF151A"
      transform="translate(116,60)"
    />
    <path
      d="M0,0 L5,0 L14,17 L20,25 L18,29 L11,34 L6,29 L2,24 L-6,9 L-7,3 Z "
      fill="#D26468"
      transform="translate(65,186)"
    />
    <path
      d="M0,0 L9,3 L21,9 L32,17 L29,22 L25,27 L21,26 L11,19 L-4,12 L-1,2 Z "
      fill="#C73F43"
      transform="translate(186,60)"
    />
    <path
      d="M0,0 L20,0 L28,1 L28,9 L27,13 L-6,13 L-8,1 Z "
      fill="#B31419"
      transform="translate(142,54)"
    />
    <path
      d="M0,0 L4,1 L18,10 L29,15 L26,26 L19,25 L3,17 L-6,10 L-5,6 Z "
      fill="#D01C23"
      transform="translate(94,223)"
    />
    <path
      d="M0,0 L1,0 L3,9 L12,21 L17,30 L18,36 L12,35 L7,32 L-2,18 L-11,8 L-16,2 L-8,2 L0,4 Z "
      fill="#DB6367"
      transform="translate(238,74)"
    />
    <path
      d="M0,0 L1,0 L5,17 L10,29 L17,43 L12,48 L8,46 L-1,29 L-5,20 L-3,20 L0,10 L-1,5 Z "
      fill="#D65054"
      transform="translate(50,171)"
    />
    <path
      d="M0,0 L2,1 L6,11 L14,6 L15,9 L13,14 L-1,14 L-4,15 L-6,18 L-9,19 L-11,23 L-13,23 L-14,28 L-17,27 L-22,14 L-16,16 L-12,18 L-14,6 L-17,5 L-17,1 L-12,2 L-11,7 L-5,12 Z "
      fill="#C65F63"
      transform="translate(152,96)"
    />
    <path
      d="M0,0 L1,0 L1,23 L-2,38 L-8,55 L-15,68 L-24,80 L-35,91 L-49,101 L-63,108 L-77,113 L-82,114 L-90,114 L-90,113 L-71,108 L-56,102 L-45,95 L-35,87 L-24,76 L-14,61 L-7,45 L-3,32 L-1,17 Z "
      fill="#711516"
      transform="translate(258,145)"
    />
    <path
      d="M0,0 L5,5 L12,15 L18,27 L20,36 L13,37 L10,27 L4,16 L-3,7 L-5,3 L-8,2 L-6,1 L0,2 Z "
      fill="#E4777B"
      transform="translate(215,97)"
    />
    <path
      d="M0,0 L4,1 L13,12 L23,26 L30,38 L39,60 L41,69 L39,73 L31,50 L23,34 L13,18 L1,4 L-2,2 L0,2 Z "
      fill="#D7D4D5"
      transform="translate(262,47)"
    />
    <path
      d="M0,0 L3,0 L10,20 L18,36 L25,47 L36,61 L40,65 L38,69 L29,59 L16,41 L8,25 L2,11 L0,5 Z "
      fill="#D8D6D6"
      transform="translate(5,195)"
    />
    <path
      d="M0,0 L2,1 L1,9 L-7,28 L-12,38 L-22,53 L-31,64 L-35,69 L-38,69 L-37,65 L-26,52 L-16,37 L-8,21 L-1,1 Z "
      fill="#9F9D9F"
      transform="translate(300,194)"
    />
    <path
      d="M0,0 L1,2 L7,3 L23,5 L54,5 L69,3 L76,2 L77,0 L77,2 L79,3 L77,5 L63,8 L55,9 L22,9 L4,6 L-1,4 L-2,2 L0,2 Z "
      fill="#9F9C9E"
      transform="translate(115,300)"
    />
    <path
      d="M0,0 L6,0 L9,3 L9,14 L6,19 L5,20 L-1,20 L-4,17 L-4,4 Z "
      fill="#F6F5F5"
      transform="translate(128,159)"
    />
    <path
      d="M0,0 L3,0 L1,4 L-6,11 L-21,22 L-36,31 L-58,40 L-67,41 L-67,39 L-45,31 L-26,21 L-12,11 Z "
      fill="#9C2B2C"
      transform="translate(259,261)"
    />
    <path
      d="M0,0 L4,1 L11,11 L12,16 L5,17 L0,16 L1,20 L-3,16 L-8,9 L-6,5 Z "
      fill="#D16367"
      transform="translate(228,90)"
    />
    <path
      d="M0,0 L10,2 L11,5 L9,8 L7,15 L2,21 L1,30 L-1,31 L-2,26 L-2,7 Z M2,22 Z "
      fill="#B12A2E"
      transform="translate(53,137)"
    />
    <path
      d="M0,0 L10,5 L21,14 L24,17 L24,20 L18,20 L12,16 L2,9 L-11,3 L-7,2 L0,2 Z "
      fill="#D75559"
      transform="translate(191,79)"
    />
    <path
      d="M0,0 L7,0 L7,3 L-4,4 L-16,9 L-29,17 L-41,28 L-51,42 L-56,53 L-61,71 L-62,71 L-61,60 L-56,47 L-50,36 L-42,26 L-33,17 L-23,10 L-9,3 Z M4,3 Z M0,4 Z "
      fill="#952021"
      transform="translate(132,72)"
    />
    <path
      d="M0,0 L1,0 L4,16 L9,29 L10,34 L5,38 L-2,23 L-3,19 L-1,11 Z "
      fill="#DE7074"
      transform="translate(75,161)"
    />
    <path
      d="M0,0 L1,0 L3,9 L7,14 L7,17 L-1,17 L-9,10 L-16,3 L-14,2 L-2,3 L0,4 Z "
      fill="#D54B50"
      transform="translate(238,74)"
    />
    <path
      d="M0,0 L9,3 L17,7 L17,11 L16,12 L-4,12 L-1,2 Z "
      fill="#BA282D"
      transform="translate(186,60)"
    />
    <path
      d="M0,0 L9,3 L25,12 L39,22 L49,31 L52,34 L52,36 L48,34 L35,23 L20,13 L0,3 Z "
      fill="#C43A3D"
      transform="translate(210,13)"
    />
    <path
      d="M0,0 L7,1 L10,5 L9,11 L6,14 L-1,14 L-5,9 L-4,3 Z "
      fill="#FBF4F4"
      transform="translate(86,260)"
    />
    <path
      d="M0,0 L6,0 L10,4 L10,10 L6,14 L0,14 L-4,10 L-4,4 Z "
      fill="#FCF7F8"
      transform="translate(280,148)"
    />
    <path
      d="M0,0 L7,0 L11,4 L10,11 L7,14 L1,14 L-3,10 L-3,3 Z "
      fill="#FBF7F7"
      transform="translate(20,148)"
    />
    <path
      d="M0,0 L8,1 L10,4 L10,11 L7,14 L0,14 L-3,11 L-4,5 Z "
      fill="#FDF7F7"
      transform="translate(215,260)"
    />
    <path
      d="M0,0 L5,5 L8,9 L6,13 L-1,18 L-6,13 L-8,11 L-7,7 Z "
      fill="#C71A20"
      transform="translate(77,202)"
    />
    <path
      d="M0,0 L7,0 L10,3 L10,10 L7,13 L1,14 L-4,9 L-3,2 Z "
      fill="#FBF6F6"
      transform="translate(215,36)"
    />
    <path
      d="M0,0 L7,0 L11,5 L10,11 L6,14 L-1,13 L-3,10 L-3,3 Z "
      fill="#FAF6F6"
      transform="translate(85,36)"
    />
    <path
      d="M0,0 L6,0 L10,4 L10,10 L8,12 L1,12 L-3,8 L-3,3 Z "
      fill="#D71D23"
      transform="translate(155,177)"
    />
    <path
      d="M0,0 L3,4 L9,16 L4,21 L0,19 L-4,11 L-1,6 Z "
      fill="#DE6469"
      transform="translate(58,198)"
    />
    <path
      d="M0,0 L1,0 L1,23 L-1,31 L-3,28 L-5,17 L-4,6 L-2,3 L0,3 Z "
      fill="#D45054"
      transform="translate(74,149)"
    />
    <path
      d="M0,0 L2,1 L1,3 L-3,4 L-8,9 L-18,17 L-28,26 L-31,28 L-32,24 L-22,15 L-10,6 Z "
      fill="#E52128"
      transform="translate(77,22)"
    />
    <path
      d="M0,0 L2,2 L7,15 L7,18 L0,19 L-3,9 L-6,2 L-1,2 Z "
      fill="#EB9598"
      transform="translate(228,115)"
    />
    <path
      d="M0,0 L3,3 L3,33 L1,38 L-1,39 Z "
      fill="#9D1A1C"
      transform="translate(303,137)"
    />
    <path
      d="M0,0 L2,1 L1,9 L-7,19 L-13,29 L-17,38 L-22,46 L-26,49 L-34,50 L-34,48 L-25,46 L-21,41 L-13,24 L-2,8 Z "
      fill="#8D1819"
      transform="translate(68,74)"
    />
    <path
      d="M0,0 L3,0 L4,5 L1,19 L-1,38 L-3,39 L-3,24 L0,6 Z "
      fill="#EA3D44"
      transform="translate(4,114)"
    />
    <path
      d="M0,0 L3,1 L3,3 L-13,11 L-25,19 L-27,17 L-15,8 Z "
      fill="#E5D9D9"
      transform="translate(113,58)"
    />
    <path
      d="M0,0 L1,0 L2,11 L3,11 L8,37 L4,36 L1,22 L0,14 Z "
      fill="#E6555A"
      transform="translate(1,158)"
    />
    <path
      d="M0,0 L2,3 L-5,12 L-13,27 L-14,32 L-18,34 L-15,25 L-8,11 Z "
      fill="#E3D0D1"
      transform="translate(72,89)"
    />
    <path
      d="M0,0 L1,0 L1,9 L-3,16 L-15,27 L-19,33 L-23,33 L-21,29 L-9,18 L-2,10 Z "
      fill="#921517"
      transform="translate(68,230)"
    />
    <path
      d="M0,0 L7,2 L11,5 L11,7 L14,8 L15,10 L20,13 L19,17 L8,8 L3,3 L0,3 Z "
      fill="#C83B3F"
      transform="translate(44,262)"
    />
    <path
      d="M0,0 L2,2 L2,11 L-2,20 L-8,25 L-10,26 L-21,26 L-21,25 L-10,23 L-5,21 L-3,17 L-1,12 Z "
      fill="#271A1A"
      transform="translate(147,163)"
    />
    <path
      d="M0,0 L2,1 L7,17 L6,22 L4,18 L1,11 L-1,1 Z "
      fill="#C8C6C7"
      transform="translate(296,98)"
    />
    <path
      d="M0,0 L2,1 L1,3 L-3,4 L-8,9 L-12,12 L-18,13 L-13,8 Z "
      fill="#EE595F"
      transform="translate(77,22)"
    />
    <path
      d="M0,0 L6,2 L15,7 L15,9 L10,8 L-2,2 Z "
      fill="#C34346"
      transform="translate(81,287)"
    />
    <path
      d="M0,0 L2,1 L3,4 L3,11 L-3,12 Z "
      fill="#D3555A"
      transform="translate(152,96)"
    />
    <path
      d="M0,0 L1,2 L0,6 L-24,6 L-24,5 L-4,4 L-3,1 Z "
      fill="#C94D51"
      transform="translate(169,61)"
    />
    <path
      d="M0,0 L1,0 L4,15 L0,14 L-1,10 L-1,1 Z "
      fill="#DD3339"
      transform="translate(5,180)"
    />
    <path
      d="M0,0 L3,1 L5,6 L5,13 L3,13 L2,15 Z "
      fill="#A21719"
      transform="translate(298,115)"
    />
    <path
      d="M0,0 L7,1 L7,2 L-1,3 L-3,15 L-4,15 L-5,6 Z "
      fill="#A29999"
      transform="translate(127,157)"
    />
    <path
      d="M0,0 L9,2 L10,4 L13,6 L6,5 L-2,2 Z "
      fill="#C04D4F"
      transform="translate(100,296)"
    />
    <path
      d="M0,0 L3,0 L4,5 L3,10 L1,11 L-1,14 Z "
      fill="#E6555A"
      transform="translate(4,114)"
    />
    <path
      d="M0,0 L2,2 L3,10 L2,7 L-4,6 L-4,4 L-2,3 L-2,1 Z "
      fill="#D45256"
      transform="translate(297,115)"
    />
    <path
      d="M0,0 L5,2 L9,5 L8,7 L3,5 L-2,1 Z "
      fill="#C2373A"
      transform="translate(71,281)"
    />
    <path
      d="M0,0 L1,0 L2,24 L-1,24 Z "
      fill="#D6676A"
      transform="translate(64,147)"
    />
    <path
      d="M0,0 L1,0 L1,7 L-2,10 L-8,11 L-9,9 L-3,7 Z "
      fill="#9B1B1D"
      transform="translate(96,265)"
    />
    <path
      d="M0,0 L5,1 L4,8 L3,5 L0,4 Z "
      fill="#BC6D6F"
      transform="translate(135,97)"
    />
    <path
      d="M0,0 L2,1 L-9,12 L-10,9 Z "
      fill="#F1666C"
      transform="translate(55,37)"
    />
    <path
      d="M0,0 L2,1 L2,7 L0,9 L-6,9 L-3,7 L-1,6 Z "
      fill="#2C1011"
      transform="translate(163,180)"
    />
    <path
      d="M0,0 L6,1 L9,3 L9,5 L4,4 Z "
      fill="#B82C2F"
      transform="translate(87,291)"
    />
    <path
      d="M0,0 L1,0 L0,11 L-3,8 L-2,2 Z "
      fill="#AC4546"
      transform="translate(304,174)"
    />
    <path
      d="M0,0 L9,3 L7,5 L2,4 L0,3 Z "
      fill="#D04F52"
      transform="translate(210,13)"
    />
    <path
      d="M0,0 L4,1 L6,3 L-4,4 Z "
      fill="#A8A6A8"
      transform="translate(177,304)"
    />
    <path
      d="M0,0 L4,1 L8,5 L8,7 L4,5 L0,2 Z "
      fill="#B83033"
      transform="translate(254,42)"
    />
    <path
      d="M0,0 L2,1 L3,6 L1,10 L-1,5 Z "
      fill="#BE9C9D"
      transform="translate(300,110)"
    />
    <path
      d="M0,0 L4,1 L2,1 L2,3 L-4,4 L-6,2 Z "
      fill="#E75257"
      transform="translate(109,8)"
    />
    <path
      d="M0,0 L4,2 L3,5 L-1,4 Z "
      fill="#C87679"
      transform="translate(176,105)"
    />
    <path
      d="M0,0 L4,2 L3,5 L-1,4 Z "
      fill="#BD5A5D"
      transform="translate(127,105)"
    />
    <path
      d="M0,0 L4,1 L4,5 L0,4 Z "
      fill="#BF6164"
      transform="translate(166,97)"
    />
    <path
      d="M0,0 L5,1 L5,4 L0,3 Z "
      fill="#B54C4F"
      transform="translate(150,90)"
    />
    <path
      d="M0,0 L1,2 L8,3 L6,5 L0,4 Z "
      fill="#D35256"
      transform="translate(193,6)"
    />
    <path
      d="M0,0 L4,2 L-1,5 L-3,4 L-2,1 Z "
      fill="#AB4A4A"
      transform="translate(224,287)"
    />
    <path
      d="M0,0 L4,1 L2,5 L-1,4 Z "
      fill="#A63034"
      transform="translate(53,137)"
    />
  </svg>
)
export default Chip01
